<template>
  <section class="show_receipt card_style">
    <div class="all-head">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>{{ $t("reservation_flight_offline") }}</h4>
      </div>
      <div class="btn_group bbbb">
        <!-- <button class="btn SecondButton">
          <i class="fa-solid fa-print"></i>
          {{ $t("print") }}
        </button> -->
        <router-link
          :to="{ name: 'flightBooking' }"
          class="PrimaryButton btn res"
          ><i class="fa-solid fa-plus"></i>
          {{ $t("new_reservation") }}</router-link
        >
      </div>
    </div>

    <div class="row options">
      <form class="col-lg-4 col-md-6 col-12">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('search by name')"
          v-model="titleReserve"
          @keyup="fetchReservation"
        />
      </form>

      <div class="col-lg-3 col-md-6 col-12 d-flex justify-content-end">
        <Multiselect
          v-model="flightType"
          :options="flights.map((flight) => flight.id)"
          :custom-label="(opt) => flights.find((x) => x.id == opt).title"
          :clear-on-select="true"
          :placeholder="$t('trip_kind')"
          select-label=""
          :hide-selected="true"
          @update:model-value="fetchReservation"
        >
        </Multiselect>
      </div>

      <div class="col-lg-3 col-md-6 col-12 d-flex justify-content-end">
        <Multiselect
          v-model="reservationCase"
          :options="cabin_codes.map((cabin_code) => cabin_code.id)"
          :custom-label="(opt) => cabin_codes.find((x) => x.id == opt).title"
          :clear-on-select="true"
          :placeholder="$t('reservton_type')"
          select-label=""
          :hide-selected="true"
          @update:model-value="fetchReservation"
        >
        </Multiselect>
      </div>

      <div class="col-lg-2 col-md-6 col-12 d-flex justify-content-end">
        <Multiselect
          v-model="reservarion_type"
          :options="reservarion_types.map((type) => type.id)"
          :custom-label="
            (opt) => reservarion_types.find((x) => x.id == opt).title
          "
          :clear-on-select="true"
          :placeholder="$t('reservation_status')"
          select-label=""
          :hide-selected="true"
          @update:model-value="fetchReservation"
        >
        </Multiselect>
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table nasaa">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("trip number") }}</th>
              <th>{{ $t("trip_type") }}</th>
              <th>{{ $t("reservton_type") }}</th>
              <th>{{ $t("reservation_number") }}</th>
              <th>{{ $t("client") }}</th>
              <th>{{ $t("seller") }}</th>
              <th>{{ $t("suppliers") }}</th>
              <th>{{ $t("main_seller") }}</th>
              <th>{{ $t("Launch") }}</th>
              <th>{{ $t("Interface") }}</th>
              <th>{{ $t("no_passenger") }}</th>
              <th>{{ $t("status") }}</th>
              <th>{{ $t("actions") }}</th>
              <th></th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 13" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr
              v-for="(item, index) in reserve.data"
              :key="index"
              :class="getTableRowClass(item.reservation_status)"
            >
              <td>{{ item.id }}</td>
              <td>{{ item.trip_num }}</td>
              <td>
                {{
                  item.flight_type == "one_way" ? $t("one_way") : $t("two_way")
                }}
              </td>
              <td>
                {{ item?.flight_status ? item?.flight_status : "-" }}
              </td>
              <td>{{ item.reservation_num }}</td>
              <td>{{ item.client[0]?.first_Name }}</td>
              <td>{{ item.seller }}</td>
              <td>{{ item.supplier.name }}</td>
              <td>{{ item.main_seller }}</td>
              <!-- <div v-if="item.flight_type == 'two_way'">
                  {{ item.trips[0]?.airport_departure_name?.title }} <br />
                </div>
                <div v-else>{{ item.trips[0]?.airport_departure_name?.title }}</div> -->

              <td :data-label="$t('reservation_date')">
                <div v-for="(trip, tripIndex) in item.trips" :key="tripIndex">
                  {{ trip?.airport_departure_name?.title }}
                </div>
              </td>

              <!-- <td>
                <div v-if="item.flight_type == 'two_way'">
                  {{ item.trips[0]?.airport_arrival_name?.title }} <br />
                </div>
                <div v-else>{{ item.trips[0]?.airport_arrival_name?.title }}</div>
              </td> -->

              <td :data-label="$t('reservation_date')">
                <div v-for="(trip, tripIndex) in item.trips" :key="tripIndex">
                  {{ trip?.airport_arrival_name?.title }}
                </div>
              </td>

              <td>{{ item.pax_num }}</td>
              <td>
                <span
                  class="badge"
                  :class="getBadgeClass(item.reservation_status)"
                >
                  {{ getStatusText(item.reservation_status) }}
                </span>
              </td>

              <td>
                <button
                  @click="changePsotedStatus(item.id)"
                  class="btn btn-outline-info fs-12 d-flex align-items-center justify-content-center mx-2"
                >
                  <i class="fa-solid fa-signs-post"></i>
                  <span> {{ $t("post") }} </span>
                </button>
              </td>

              <td>
                <button
                  v-if="item.flight_status == 'online'"
                  class="btn btn-danger text-white mx-1"
                  @click="cancelReservartion(item.trip_num, item.id)"
                  :title="$t('cancel_reservartion')"
                >
                  <i class="fa-solid fa-ban"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="reserve"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchReservation"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import pagination from "laravel-vue-pagination";
import Multiselect from "vue-multiselect";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";

export default {
  name: "show-revnue",
  components: {
    pagination,
    Multiselect,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      reserve: [],
      titleReserve: "",
      reservationCase: "",
      loading: true,
      cabin_codes: [
        {
          id: 1,
          title: this.$t("bookings_online"),
        },
        {
          id: 2,
          title: this.$t("Offline Book"),
        },
      ],
      cabin_code: "",
      reservarion_type: "",
      reservarion_types: [
        {
          id: "basically",
          title: this.$t("initial"),
        },
        {
          id: "confirmed",
          title: this.$t("confirmed"),
        },
        {
          id: "accepted",
          title: this.$t("accepted"),
        },
        {
          id: "updated",
          title: this.$t("updated"),
        },
        {
          id: "canceled",
          title: this.$t("canceled"),
        },
      ],
      flightType: "",
      flights: [
        {
          id: "one_way",
          title: this.$t("go_only"),
        },
        {
          id: "two_way",
          title: this.$t("go_back"),
        },
      ],
    };
  },

  methods: {
    changePsotedStatus(flight_id) {
      axios
        .post("/changeFlightLockStatus", {
          flight_id: flight_id,
        })
        .then((data) => {
          this.$emit("roomAdded");
          Swal.fire({
            icon: "success",
            text: data.data.message,
            timer: 2000,
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
            timer: 2000,
          });
        });
    },
    fetchReservation(page = 1) {
      const search = {
        count: 10,
        book_person_type: 1,
      };
      if (this.titleReserve != "") {
        search.reservation_num = this.titleReserve;
      }
      if (this.flightType != "") {
        search.flight_type = this.flightType;
      }
      if (this.reservationCase != "") {
        search.flight_status = this.reservationCase == 1 ? "online" : "offline";
      }
      if (this.reservarion_type != "") {
        search.reservation_status = this.reservarion_type;
      }
      axios
        .post(`/flight_reservation?page=${page}`, search)
        .then(({ data }) => {
          this.reserve = data.data;
          this.loading = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },

    cancelReservartion(order_id, flight_id) {
      let delteData = {
        order_id: order_id, //trip_num
        flight_id: flight_id,
      };
      axios
        .post("/flight/babylon/cancel", delteData)
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
          } else if (data.status == false) {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "error",
            });
          }
          // console.log(data.data)
          this.fetchReservation();
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    //delete revenue

    // fetchReservation(page = 1) {
    //   const newLocal = this.$i18n.locale;
    //   axios.defaults.headers.common["Accept-Language"] = newLocal;
    //   axios.get("/flight?page=" + page).then(({ data }) => {
    //     this.reserve = data.data;
    //     console.log(this.reserve);
    //   });
    // },
    getTableRowClass(reservationStatus) {
      switch (reservationStatus) {
        case "basically":
          return "bg-warning-2";
        case "confirmed":
          return "bg-primary-2";
        case "accepted":
          return "bg-success-2";
        case "updated":
          return "bg-info-2 text-dark";
        case "canceled":
          return "bg-danger-2";
        default:
          return "";
      }
    },
    getBadgeClass(reservationStatus) {
      switch (reservationStatus) {
        case "basically":
          return "bg-warning";
        case "confirmed":
          return "bg-primary";
        case "accepted":
          return "bg-success";
        case "updated":
          return "bg-info text-dark";
        case "canceled":
          return "bg-danger";
        default:
          return "";
      }
    },
    getStatusText(reservationStatus) {
      switch (reservationStatus) {
        case "basically":
          return this.$t("initial");
        case "confirmed":
          return this.$t("confirmed");
        case "accepted":
          return this.$t("accepted");
        case "updated":
          return this.$t("updated");
        case "canceled":
          return this.$t("canceled");
        default:
          return "";
      }
    },
    // filter reservation case
    reservationCaseFilter() {
      this.loading = true; //the loading begin
      const filter = {
        count: 10,
        reservationCase: "",
      };

      axios
        .post("/flight_reservation", filter)
        .then(({ data }) => {
          this.hotels = data.data;
          this.loading = false; //the loading ended
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },

    // filter reservation type
    reservationStutes() {
      this.loading = true; //the loading begin
      const filter = {
        reservation_status: this.reservarion_type,
        count: 10,
      };
      axios
        .post("/flight_reservation", { params: filter })
        .then(({ data }) => {
          this.reserve = data.data;
          this.loading = false; //the loading ended
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },

    // filter flight type
    flightTypeFilter() {
      this.loading = true; //the loading begin
      const filter = {
        flight_type: this.flightType,
        count: 10,
      };

      axios
        .post("/flight_reservation", { params: filter })
        .then(({ data }) => {
          this.reserve = data.data;
          this.loading = false; //the loading ended
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
  },
  mounted() {
    this.fetchReservation();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_reservation.scss";
.options {
  .form-control {
    padding-inline-start: 3rem !important;
  }
}
table {
  tbody {
    tr.bg-warning-2 {
      td {
        background-color: rgba($color: #c57016, $alpha: 0.04);
        text-align: center;
      }
    }
    tr.bg-primary-2 {
      td {
        background-color: rgba($color: #1664b8, $alpha: 0.04);
        text-align: center;
      }
    }
    tr.bg-success-2 {
      td {
        background-color: rgba($color: #88c64b, $alpha: 0.04);
        text-align: center;
      }
    }
    tr.bg-info-2 {
      td {
        background-color: rgba($color: #0a909b, $alpha: 0.04);
        text-align: center;
      }
    }
    tr.bg-danger-2 {
      td {
        background-color: rgba($color: #e23535, $alpha: 0.4);
        text-align: center;
      }
    }
  }
}
</style>
