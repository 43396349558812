<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4 class="mb-0">
        {{ $t("Airlines") }}
      </h4>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-between">
      <div class="search-visa w-50 my-3">
        <form>
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            class="form-control"
            :placeholder="$t('search...')"
            v-model="flightText"
            @keyup="fetchFlightCompanies((page = 1))"
          />
        </form>
      </div>
      <div class="main_header_style">
        <div>
          <router-link to="/flights-companies" class="PrimaryButton btn w-100"
            ><i class="fa-solid fa-plus"></i
            >{{ $t("add airline") }}</router-link
          >
        </div>
      </div>
    </div>

    <div class="row">
      <table class="table table-striped" id="companyTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("company name") }}</th>
            <th>{{ $t("Take-off airport") }}</th>
            <th>{{ $t("Arrival airport") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="company in 10" :key="company">
            <td v-for="company in 5" :key="company">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(company, index) in allCompanies.data" :key="index">
            <td>{{ company.id }}</td>
            <td>{{ company.name }}</td>
            <td>
              <div
                class="airport"
                v-for="(route, routeIndex) in company.routes"
                :key="routeIndex"
              >
                {{ route.from_airport ? route.from_airport.title : null }}
              </div>
            </td>
            <td>
              <div
                class="airport"
                v-for="(route, routeIndex) in company.routes"
                :key="routeIndex"
              >
                {{ route.to_airport ? route.to_airport.title : null }}
              </div>
            </td>

            <td>
              <router-link
                :to="`/flights-companies/routes/${company.id}`"
                class="btn btn-info mx-1 w-auto"
              >
                <i class="fa-solid fa-info"></i>
              </router-link>

              <div
                class="btn btn-danger mx-1 w-auto"
                @click="deleteAllRoutes(company.id, index)"
              >
                <i class="fa-solid fa-trash"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="allCompanies"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchFlightCompanies"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  name: "flightCompaniesIndex",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      allCompanies: [],
      flightText: "",
      loading: true,
    };
  },
  methods: {
    fetchFlightCompanies(page = 1) {
      axios
        .post(`/fetch_flight_company_routes?page=${page}`, {
          word: this.flightText,
        })
        .then(({ data }) => {
          // console.log(data.data);
          this.allCompanies = data.data;
          this.loading = false;
          // console.log(this.allCompanies);
        });
    },
    //delete visa
    deleteAllRoutes(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.allCompanies[index].id;
          const fromClick = true;
          axios.delete(`/flight_company_routes/${id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.allCompanies.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.fetchFlightCompanies();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
td {
  .airport {
    padding: 10px;
    border-bottom: 1px solid #dcdcdc;
  }
}
</style>
