<template>
  <div
    class="container-fluid d-flex flex-column bg-white pt-5"
    style="min-height: 100vh"
  >
    <form>
      <div class="d-flex flex-column">
        <label class="mx-3 mb-2">{{ $t("email") }}</label>
        <InputText
          type="email"
          v-model="email"
          class="mx-3 w-50 mb-3"
          :placeholder="$t('email')"
        />
      </div>
      <div class="d-flex flex-column my-2">
        <label class="mx-3 mb-2">{{ $t("password") }}</label>
        <InputText
          type="password"
          v-model="password"
          class="mx-3 w-50 mb-3"
          :placeholder="$t('password')"
        />
      </div>
      <Button
        :label="$t('save')"
        class="m-3 PrimaryButton"
        style="width: 10%"
        :disabled="!email || !password"
        @click="sendEmail"
      />
    </form>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      email: null,
      password: null,
    };
  },
  methods: {
    sendEmail() {
      axios
        .post("/mail_sender_setting", {
          email_setting: this.email,
          email_sender_password: this.password,
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: res.data.message,
            timer: 1500,
          });
          this.loadEmailSettings();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: error.response?.data?.message || "An error occurred",
          });
        });
    },
    loadEmailSettings() {
      axios
        .get("/show_sender_setting")
        .then((res) => {
          this.email = res.data.data;
        })
        .catch((error) => {
          console.error("Failed to load email settings:", error);
        });
    },
  },
  components: {
    InputText,
    Button,
  },
  mounted() {
    this.loadEmailSettings();
  },
};
</script>
