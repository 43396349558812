<template>
  <section class="all_emp card_style">
    <div class="row options mb-2">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
        <h4>
          {{ $t("clients") }}
        </h4>
      </div>

      <div class="col-lg-8 col-md-12 col-12">
        <div class="form-group">
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            class="form-control w-50"
            :placeholder="$t('search by name')"
            v-model="clientText"
            @keyup="FetchClients((page = 1))"
          />
        </div>
      </div>

      <div class="col-lg-2 col-md-12 col-12">
        <div class="cli">
          <router-link
            :to="{ name: 'addClients', params: { page: 2 } }"
            class="PrimaryButton btn"
            ><i class="fa-solid fa-plus"></i>
            {{ $t("add_clients") }}</router-link
          >
        </div>
      </div>
      <div class="col-lg-2 col-md-12 col-12">
        <div class="cli">
          <button
            class="SecondButton btn action d-flex align-items-center mx-2"
            @click="exportToExcel"
          >
            <i class="fa-solid fa-file-excel"></i>
            {{ $t("export_to_excel") }}
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table clients" id="RoomTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("name") }}</th>
              <th>{{ $t("email") }}</th>
              <th>{{ $t("phone") }}</th>
              <th>{{ $t("client type") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="client in 10" :key="client">
              <td v-for="client in 6" :key="client">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(client, index) in clients.data" :key="index">
              <td>{{ client.id }}</td>
              <td>{{ client.name }}</td>
              <td>{{ client.email }}</td>
              <td>{{ client.phone }}</td>
              <!-- <td>
                {{ client.client_type_name ? client.client_type_name : "-" }}
              </td> -->
              <td>
                {{
                  client.client_type == 1
                    ? $t("person")
                    : client.client_type == 2
                      ? $t("company")
                      : "-"
                }}
              </td>
              <td>
                <div class="dropdown btn btn-info p-0">
                  <button
                    class="btn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    v-tooltip.top="$t('Control')"
                  >
                    <i class="fa-solid fa-bars"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <button
                        class="btn w-100"
                        type="button"
                        data-bs-toggle="modal"
                        :data-bs-target="`#exampleModal${client.id}`"
                      >
                        <i class="fa-regular fa-envelope"></i>
                        <span>{{ $t("Send Email") }}</span>
                      </button>
                    </li>

                    <li>
                      <button
                        type="button"
                        v-if="client.active == 'active'"
                        class="btn w-100"
                        @click="BlockHotel(client.id)"
                      >
                        <i class="fa-solid fa-ban"></i>
                        {{ $t("block") }}
                        <!-- {{ client.status === 0 ? $t("block") : $t("unblock") }} -->
                      </button>
                      <button
                        type="button"
                        v-else-if="client.active == 'inActive'"
                        class="btn w-100"
                        @click="BlockHotel(client.id)"
                      >
                        <i class="fa-solid fa-ban"></i>
                        {{ $t("unblock") }}
                        <!-- {{ client.status === 0 ? $t("block") : $t("unblock") }} -->
                      </button>
                    </li>
                  </ul>
                </div>
                <router-link
                  class="btn btn-info text-white mx-1"
                  :to="`/clients/details/${client.id}`"
                  v-tooltip.top="$t('client Details')"
                >
                  <i class="fa-solid fa-circle-exclamation"></i>
                </router-link>
                <router-link
                  class="btn btn-success text-white mx-1"
                  :to="`clients/edit/${client.id}`"
                  v-tooltip.top="$t('edit')"
                >
                  <i class="fa-solid fa-pen"></i>
                </router-link>
                <button
                  v-if="client.action == 0"
                  type="button"
                  class="btn btn-danger text-white mx-1"
                  v-tooltip.top="$t('delete')"
                  @click="deleteClients(client.id, index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
                <button
                  class="btn btn-whats text-white"
                  type="button"
                  data-bs-toggle="modal"
                  :data-bs-target="`#exampleModalWattsap${client.id}`"
                  v-tooltip.top="$t('send_whatsapp')"
                >
                  <i class="fa-brands fa-whatsapp"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-success mx-1 w-auto"
                  data-bs-toggle="modal"
                  :data-bs-target="`#Downpayment${client.id}`"
                  v-tooltip.top="$t('Downpayment')"
                >
                  <i class="fa fa-dollar"></i>
                </button>

                <modal
                  :clienId="client.id"
                  :organizationAccounts="organizationAccounts"
                  :organizationSafe="organizationSafe"
                />
              </td>
              <!-- watssap modal -->
              <div
                class="modal fade"
                :id="`exampleModalWattsap${client.id}`"
                tabindex="-1"
                :aria-labelledby="`exampleModalWattsap${client.id}`"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5
                        class="modal-title"
                        :id="`exampleModalWattsap${client.id}`"
                      >
                        {{ $t("send_whatsapp") }}
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <form @submit.prevent="sendWhatsapp()">
                        <div class="form-group">
                          <label for="phone">{{ $t("phone") }}</label>
                          <input
                            type="tel"
                            id="phone"
                            v-model="watts.phone"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group">
                          <label for="message">{{ $t("message_title") }}</label>
                          <textarea
                            id="message"
                            v-model="watts.message"
                            class="form-control"
                          ></textarea>
                        </div>
                        <button type="submit" class="btn btn-primary">
                          {{ $t("send") }}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!-- watssap modal -->
              <!-- email modal -->
              <div
                class="modal fade"
                :id="`exampleModal${client.id}`"
                tabindex="-1"
                :aria-labelledby="`exampleModalLabel${client.id}`"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5
                        class="modal-title"
                        :id="`exampleModalLabel${client.id}`"
                      >
                        {{ $t("send_email_to") }}
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <form @submit.prevent="sendMail(client)">
                        <div class="form-group">
                          <label for="phone">{{ $t("email") }}</label>
                          <input
                            type="tel"
                            id="phone"
                            v-model="client.email"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group">
                          <label for="message">{{ $t("message_title") }}</label>
                          <textarea
                            id="message"
                            v-model="client.title"
                            class="form-control"
                          ></textarea>
                        </div>
                        <div class="form-group">
                          <label for="message">{{
                            $t("message_content")
                          }}</label>
                          <textarea
                            id="message"
                            v-model="client.content"
                            class="form-control"
                          ></textarea>
                        </div>
                        <button
                          type="submit"
                          class="btn btn-primary"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {{ $t("send") }}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!-- email modal -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="clients"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchClients"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import modal from "./modal.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
import * as XLSX from "xlsx/xlsx.mjs";
export default {
  name: "all-clients",
  components: {
    pagination,
    modal,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      clients: [],
      clientText: "",
      watts: {
        phone: "",
        message: "",
      },
      loading: true,
      organizationAccounts: [],
      organizationSafe: [],
    };
  },
  computed: {
    client_id() {
      // You can adjust this depending on your actual data structure
      if (this.clients.length > 0) {
        return this.clients[0].id;
      }
      // Default value if the tree is empty
      return null;
    },
  },
  methods: {
    async exportToExcel() {
      try {
        const response = await axios.post("/fetch_consumer_by_role", {
          // type: 3,
          role: 1,
          word: this.clientText,
          // per_page: 10,
        });
        const responseData = response.data.data.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "Clients.xlsx");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed, e.g., show a message to the user
      }
    },
    // fetch suppliers data
    FetchClients(page = 1) {
      axios
        .post(`/fetch_consumer_by_role?page=${page}`, {
          role: 1,
          word: this.clientText,
          per_page: 10,
        })
        .then(({ data }) => {
          this.clients = data.data;
          this.loading = false;
        });
    },
    //delete suppliers
    deleteClients(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.clients[index].id;
          const fromClick = true;
          axios
            .delete(`/consumers/${id}`, { fromClick })
            .then(({ data }) => {
              //   console.log(data)
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.clients.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            })
            .catch((error) => {
              if (error.response.status != 403) {
                Swal.fire("", error.response.data.message, "error");
              }
            });
        }
      });
    },
    //send watsapp
    sendWhatsapp() {
      let id = this.$route.params.id;
      const formData = new FormData();
      formData.append("id", id);
      formData.append("phone", this.phone);
      formData.append("message", this.message);

      axios
        .post("/watsapp", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          // console.log(data)
          this.$router.push("/clients");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    sendMail(client) {
      const formData = new FormData();
      formData.append("receiver_email", client.email);
      formData.append("subject", client.title);
      formData.append("body", client.content);
      axios
        .post("send_email", formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: res.data.message,
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: err.message,
          });
        });
    },

    BlockHotel(clientId) {
      if (
        this.clients.data.find((client) => client.id === clientId).status === 1
      ) {
        // The client is currently blocked, so we need to unblock
        this.showConfirmationAndUnblock(clientId);
      } else {
        // The client is not blocked, so we need to block
        this.showConfirmationAndBlock(clientId);
      }

      window.reload;
    },
    async showConfirmationAndBlock(clientId) {
      const result = await Swal.fire({
        title: this.$t("check_block_client"),
        text: this.$t("are_you_sure_block"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_block"),
      });

      if (result.isConfirmed) {
        const data = { consumer_id: clientId };
        axios.post("/block_consumer", data).then(({ data }) => {
          // console.log(data.data);
          // this.consumer = data.data;
          window.location.reload();
          if (data.status === true) {
            Swal.fire("", data.message, "success");
          } else {
            Swal.fire("", data.message, "error");
          }
        });
      }
    },
    async showConfirmationAndUnblock(clientId) {
      const result = await Swal.fire({
        title: this.$t("check_unblock_client"),
        text: this.$t("are_you_sure_unblock"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_unblock"),
      });

      if (result.isConfirmed) {
        const data = { consumer_id: clientId };
        axios.post("/block_consumer", data).then(({ data }) => {
          // console.log(data.data);
          // this.consumer = data.data;
          window.location.reload();

          if (data.status === true) {
            Swal.fire("", data.message, "success");
          } else {
            Swal.fire("", data.message, "error");
          }
        });
      }
    },
    fetchOrganizationAccounts() {
      axios
        .post("/fetch_my_organization_accounts", { type: 1 })
        .then(({ data }) => {
          this.organizationAccounts = data.data.data;
          //   console.log(this.organizationAccounts);
        });
    },
    fetchOrganizationSafe() {
      axios
        .post("/fetch_my_organization_accounts", { type: 2 })
        .then(({ data }) => {
          this.organizationSafe = data.data.data;
          // console.log(this.organizationSafe);
        });
    },
  },
  mounted() {
    this.FetchClients();
    this.fetchOrganizationAccounts();
    this.fetchOrganizationSafe();
  },
};
</script>

<style lang="scss" scoped>
@import "./_clients";
.options form .form-control {
  padding-inline-start: 3rem !important;
}
.btn-whats {
  background-color: #25d366;
}
</style>
