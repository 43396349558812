<template>
  <add-client />
</template>

<script>
import addClient from "@/components/clients/addClient.vue";
export default {
  name: "add-Client",
  components: { addClient },
};
</script>

<style></style>
