<template>
  <div v-if="loading" class="loading w-100">
    <Loading />
  </div>
  <section class="all_emp card_style">
    <div class="row options">
      <div class="d-flex align-items-center justify-content-between">
        <div class="w-25">
          <div class="main_header_style">
            <h4>{{ $t("The Cities") }}</h4>
          </div>
        </div>
        <div class="w-25">
          <router-link :to="{ name: 'addCities' }" class="PrimaryButton btn w-50"
            ><i class="fa-solid fa-plus"></i> {{ $t("New City") }}</router-link
          >
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between my-2">
        <form class="w-50">
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            class="form-control"
            :placeholder="$t('search by name')"
            v-model="title_city"
            @keyup="fetchCities"
          />
        </form>
        <div class="form-group w-25">
          <Multiselect
            v-model="country_id"
            :options="countries"
            :placeholder="$t('country')"
            label="title"
            track-by="id"
            :clear-on-select="true"
            select-label=""
            :hide-selected="true"
            @update:model-value="fetchCities"
          >
          </Multiselect>
        </div>
      </div>
    </div>

    <div class="row">
      <table class="table" id="countryTable">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("Title") }} ID</th>
            <th>{{ $t("Title") }} EN</th>
            <th>{{ $t("Title") }} AR</th>
            <th>{{ $t("provider") }}</th>
            <th>{{ $t("country") }}</th>
            <th>{{ $t("number_of_hotels") }}</th>
            <th>{{ $t("Hotel") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(city, index) in cities.data" :key="index">
            <td>{{ city.id }}</td>
            <td v-for="(title, item) in city.titles" :key="item">{{ title.title }}</td>
            <td> <span v-for="provider in city.provider" :key="provider.id">{{ provider?.name }}, </span></td>
            <td>{{ city.country_title }}</td>
            <td>{{ city.number_of_hotels }}</td>
            <td>{{ city.is_hotel }}</td>
            <td>
              <div
                class="btn btn-primary text-white mx-1"
                @click="
                  callHotels(
                    city.id,
                    city.title,
                    city.country_title,
                    city.country_code
                  )
                "
                :title="$tc('call_hotels')"
              >
                <i class="fa-solid fa-hotel"></i>
              </div>

              <router-link
                :to="`/cities/edit/${city.id}`"
                class="btn btn-success mx-1 text-white"
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </router-link>

              <button
                class="btn btn-danger text-white mx-1"
                @click="deleteCity(city.id, index)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
              <button
                :class="`btn ${
                  city.is_favorite == 1 ? 'btn-warning' : 'btn-secondary'
                }`"
                @click="changeStatus(city.id)"
              >
                <i class="fa-solid fa-star text-white"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="cities"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchCities"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Loading from "@/components/LottieFiles/loading.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "allCities",
  data() {
    return {
      cities: {
        data: []
      },
      country_id: "",
      countries: [],
      title_city: "",
      loading: false,
    };
  },
  components: {
    pagination,
    Loading,
    Multiselect
  },
  methods: {
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    changeStatus(cityId) {
      this.loading = true;
      axios
        .post(`/change_city_favorite_status`, { city_id: cityId })
        .then(() => {
          this.fetchCities();
          Swal.fire(this.$t("change status"), "", "success");
          this.loading = false;
        });
    },
    fetchCities(page = 1) {
      this.loading = true;
      const formData = new FormData();
      if(this.title_city) {
        formData.append("word", this.title_city);
      }
      if(this.country_id) {
        formData.append("country_id", this.country_id.id);
      }
      axios.post(`/fetch_cities?page=${page}`, formData).then(({ data }) => {
        this.cities = data.data;
        this.loading = false;
        // console.log(this.titleCity)
      });
    },
    deleteCity(cityId, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios.delete(`/cities/${cityId}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.cities.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
          });
        }
      });
    },

    callHotels(cityId, cityTitle, country_title, country_code) {
      this.loading = true; // Start loading

      // Get the current date
      var today = new Date();

      // Add one day to the current date
      var tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      // Get the components of the date (year, month, day)
      var year = tomorrow.getFullYear();
      var month = String(tomorrow.getMonth() + 1).padStart(2, "0"); // Month is zero-based
      var day = String(tomorrow.getDate()).padStart(2, "0");

      // Form the "YYYY-MM-DD" string
      var tomorrowFormatted = `${year}-${month}-${day}`;

      // Add two days to the current date
      var afterTwoDays = new Date();
      afterTwoDays.setDate(today.getDate() + 2);

      // Get the components of the date (year, month, day)
      var yearTwoDays = afterTwoDays.getFullYear();
      var monthTwoDays = String(afterTwoDays.getMonth() + 1).padStart(2, "0"); // Month is zero-based
      var dayTwoDays = String(afterTwoDays.getDate()).padStart(2, "0");

      // Form the "YYYY-MM-DD" string
      var afterTwoDaysFormatted = `${yearTwoDays}-${monthTwoDays}-${dayTwoDays}`;

      let data = {
        city_id: cityId,
        city_name: cityTitle,
        country_name: country_title,
        country_code: country_code,
        nationality_code: "EG",
        no_of_rooms: 1,
        passenger_country_of_residence_code: "EG",
        converter_status: 1,
        rooms_guests: [
          {
            rate_basis: "1",
            adults_count: 2,
            children_count: 0,
            children_ages: [],
          },
        ],
        check_in: tomorrowFormatted,
        check_out: afterTwoDaysFormatted,
        meta_data: {
          credential_id: 2,
          provider_id: 2,
        },
      };
      axios
        .post(`/fetch_final_hotels_with_details`, data)
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.loading = false; //the loading ended
          } else {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "error",
            });
            this.loading = false; //the loading ended
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
  },
  mounted() {
    this.fetchCities();
    this.fetch_country()
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
.options {
  .form-control {
    padding-inline-start: 3rem !important;
  }
}
</style>
