<template>
  <div class="loader py-5">
    <h2 class="fw-bold px-3">Loader</h2>
    <form @submit.prevent="addLoader()">
      <div
        class="d-flex align-items-center flex-wrap flex-md-row flex-column mt-3"
      >
        <div
          v-for="(loaderType, index) in loaderTypes"
          :key="index"
          class="mb-3 w-50 px-3"
        >
          <label :for="`formFile_${index}`" class="form-label fw-bold fs-3">{{
            `Loader ${loaderType}`
          }}</label>
          <input
            :id="`formFile_${index}`"
            class="form-control"
            type="file"
            accept="application/JSON"
            @change="onFileChange(loaderType, $event)"
          />
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton mt-5">
        {{ $t("save") }}
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "dynamicLoader",
  data() {
    return {
      loaderFiles: {}, // Object to store selected files
      loaderTypes: ["General", "Hotel", "Flight", "Package", "Offer"],
    };
  },
  methods: {
    onFileChange(loaderType, event) {
      const file = event.target.files[0];
      if (!this.loaderFiles) {
        this.loaderFiles = {};
      }
      this.loaderFiles[loaderType.toLowerCase()] = file; // Convert loader type to lowercase
      // console.log(`Selected File for ${loaderType}:`, file);
    },
    addLoader() {
      // console.log(this.loaderFiles);

      // Create FormData object
      const formData = new FormData();
      Object.keys(this.loaderFiles).forEach((key) => {
        formData.append(`${key.toLowerCase()}_loader`, this.loaderFiles[key]);
      });

      // Send formData to your server or perform other actions
      axios
        .post("store_loader", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
};
</script>
