<template>
  <div class="form-group d-flex align-items-center gap-2">
    <InputSwitch
      v-model="areInputsDisabled"
      onIcon="pi pi-check"
      offIcon="pi pi-times"
    />
    <label class="form-label">{{
      areInputsDisabled ? $t("Price in details") : $t("Price at cost")
    }}</label>
  </div>
  <div class="rooms_container col-12">
    <div class="double-room d-flex flex-column justify-content-center bg-white">
      <div v-for="(room, index) in selectedRooms" :key="index">
        <div
          class="header d-flex align-items-center justify-content-between px-2 py-2"
        >
          <h3 class="w-50">{{ room.title }}</h3>
          <div class="inputs d-flex align-items-center">
            <div class="px-2">
              <label class="typo__label custom_label profit">{{
                $t("Adult profit")
              }}</label>
              <input
                type="text"
                placeholder="0"
                :value="formatNumber(room.adult.penifit)"
                numirc
                class="form-control"
                :disabled="areInputsDisabled"
              />
            </div>
            <div class="px-2">
              <label class="typo__label custom_label profit">{{
                $t("Child profit")
              }}</label>
              <input
                type="text"
                placeholder="0"
                :value="formatNumber(room.child.penifit)"
                numirc
                class="form-control"
                :disabled="areInputsDisabled"
              />
            </div>
          </div>
        </div>
        <div
          class="info d-flex align-items-center position-relative pb-2 justify-content-between"
        >
          <div class="adults w-100">
            <h3 class="text-center py-2">{{ $t("adult") }}</h3>
            <div class="px-2">
              <label class="typo__label custom_label">{{ $t("cost") }}</label>
              <input
                type="text"
                placeholder="0"
                :value="formatNumber(room.adult.total_adult_cost)"
                numirc
                class="form-control"
                :disabled="areInputsDisabled"
              />
            </div>
            <div class="px-2">
              <label class="typo__label custom_label">{{
                $t("selling")
              }}</label>
              <input
                type="text"
                placeholder="0"
                :value="formatNumber(room.adult.total_adult_selling)"
                numirc
                class="form-control"
                :disabled="areInputsDisabled"
              />
            </div>
          </div>
          <div class="childrens w-100">
            <h3 class="text-center py-2">{{ $t("child") }}</h3>
            <div class="px-2">
              <label class="typo__label custom_label">{{ $t("cost") }}</label>
              <input
                type="text"
                placeholder="0"
                :value="formatNumber(room.child.total_child_cost)"
                numirc
                class="form-control"
                :disabled="areInputsDisabled"
              />
            </div>
            <div class="px-2">
              <label class="typo__label custom_label">{{
                $t("selling")
              }}</label>
              <input
                type="text"
                placeholder="0"
                :value="formatNumber(room.child.total_child_selling)"
                numirc
                class="form-control"
                :disabled="areInputsDisabled"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="btn-action gap-3 d-flex align-items-center justify-content-between p-2"
    >
      <button
        class="SecondButton gap-2 btn action d-flex align-items-center justify-content-center w-100"
        type="submit"
        @click="saveButton(0)"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="loading"></i>
        <div class="d-flex gap-2 align-items-center" v-else>
          <img src="@/assets/media/image/document-cloud.svg" alt="" />
          <span>{{ $t("Save as draft") }}</span>
        </div>
      </button>
      <button
        class="SecondButton gap-2 btn d-flex align-items-center justify-content-center w-100"
        type="submit"
        @click="saveButton(1)"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="loading"></i>
        <div class="d-flex gap-2 align-items-center" v-else>
          <img src="@/assets/media/image/tick-circle.svg" alt="" />
          <span>{{ $t("Save and publish") }}</span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
// import InputNumber from "primevue/inputnumber";
// import axios from "axios";
// import Swal from "sweetalert2";
import InputSwitch from "primevue/inputswitch";

export default {
  props: ["selectedRooms", "basePrice", "fetchedBasePrice"],
  emits: ["saveBtn", "total"],
  data() {
    return {
      areInputsDisabled: false,
      loading: false,
    };
  },
  methods: {
    formatNumber(value) {
      return parseFloat(value).toFixed(1);
    },
    // updateNumber(event, target, key) {
    //   const value = event.target.value;
    //   if (!isNaN(value) && value !== "") {
    //     // target[key] = parseFloat(value).toFixed(2);
    //   } else {
    //     target[key] = "0";
    //   }
    // },
    saveButton(param) {
      this.loading = true;
      this.$emit("saveBtn", param);
      let totalPriceArray = [];
      totalPriceArray.push(
        ...this.selectedRooms.map((room) => {
          return {
            room_id: room.room_id,
            adult: {
              cost: room.adult.total_adult_cost,
              selling: room.adult.total_adult_selling,
            },
            child: {
              cost: room.child.total_child_cost,
              selling: room.child.total_child_selling,
            },
          };
        })
      );
      const total = {
        price_type: this.areInputsDisabled ? 1 : 2,
        total_price: totalPriceArray,
      };
      console.log(total);
      this.$emit("total", total);
      this.loading = false;
    },
  },
  components: {
    InputSwitch,
  },
};
</script>

<style scoped lang="scss">
@import "./_doubleRoom.scss";
</style>
