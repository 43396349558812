import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  state() {
    return {
      user: null,
      name: localStorage.getItem("name"),
      email: localStorage.getItem("email"),
      phone: localStorage.getItem("phone"),
      salary: localStorage.getItem("salary"),
      status: localStorage.getItem("status"),
      token: localStorage.getItem("token"),
      stage: localStorage.getItem("stage"),
      email_company: localStorage.getItem("email_company")
    };
  },
  mutations,
  actions,
  getters,
  namespaced: false
};