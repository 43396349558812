<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <h4>{{ $t("nationalities_packages") }}</h4>

      <div>
        <router-link
          :to="`/nationality_pacakge_price/${$route.params.id}`"
          class="PrimaryButton btn w-100"
        >
          {{ $t("add_new_nationality") }}
        </router-link>
      </div>
    </div>
    <div class="row">
      <table class="table" id="userTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("title") }}</th>
            <th>{{ $t("available") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 4" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) in allPackages" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item?.nationality_title }}</td>
            <td>{{ $t(item?.is_available) }}</td>
            <td>
              <!-- <a
                title="View Package"
                class="btn btn-info mx-1 w-auto"
                target="_blank"
                :href="`${$websiteLink}/umrah/${item.id}`"
                ><i class="fa-solid fa-circle-info"></i>
              </a> -->

              <!-- <router-link :to="`/nationality_pacakge_price/${item.id}`">
                <button
                  class="btn btn-secondary mx-1 w-auto"
                  :title="$t('nationality_price')"
                >
                  <i class="fa-solid fa-dollar-sign"></i>
                </button>
              </router-link> -->

              <router-link
                :to="`/nationality_pacakge_price/${$route.params.id}/edit/${item.id}`"
                class="btn btn-success mx-1 w-auto"
                title="Edit Package"
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </router-link>

              <button
                class="btn btn-danger mx-1 w-auto"
                @click="deletePackage(item.id, index)"
                title="Delete Package"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div class="row">
      <pagination
        :data="allPackages"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_all_package"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div> -->
  </section>
</template>

<script>
import axios from "axios";
// import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
// import Loading from "@/components/LottieFiles/loading.vue";
import Skeleton from "primevue/skeleton";
export default {
  data() {
    return {
      allPackages: [],
      package_search: "",
      loading: true,
      //   defaultImage: require("@/assets/media/image/placeholder.png"), // Replace with your default image URL,
    };
  },
  methods: {
    // handleImageError(event) {
    //   // When the image fails to load, replace it with the default image
    //   event.target.src = this.defaultImage;
    // },
    fetch_all_package() {
      axios
        .post(`/fetch_specific_package_nationalities`, {
          package_id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.allPackages = data.data;
          this.loading = false;
          //   console.log(this.allPackages);
        }).catch((error)=>{
          // Swal.fire({
          //   title: "",
          //   text: error.response.data.message,
          //   icon: "error",
          // });
          if(error){
            this.allPackages = [];
            this.loading = false;
          }
        })
    },
    // delete package
    deletePackage(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          //   let delete_id = this.allPackages.data[index].id;
          //   const newLocal = this.$i18n.locale;
          // //   axios.defaults.headers.common["Accept-Language"] = newLocal;
          axios
            .post(`/delete_package_nationalities`, {
              package_nationality_id: id,
            })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.allPackages.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
    change_status(id) {
      axios
        .post("/change_package_status", {
          package_id: id,
        })
        .then(({ data }) => {
          this.fetch_all_package();
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetch_all_package();
  },
  //   components: {
  //     pagination,
  //     Loading,
  //   },
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_user.scss";

.main_header_style {
  align-items: center;

  h4 {
    margin-bottom: 0;
  }
}

.search-user {
  form {
    position: relative;

    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }

    input {
      padding-right: 40px !important;
    }
  }
}

.btn_trip {
  border: 1px solid #0a909b;
  outline: none;
  padding: 0.5rem 0.8rem;
  color: #0a909b;
  border-radius: 4px;
  background-color: #fff;
  margin: 0 0.2rem;
  font-family: "reqular";
}

.coupon_status {
  display: block;
  padding: 10px;
  border-radius: 10px;
  width: 85px;
  text-align: center;

  &.active {
    background-color: #00800026;
  }

  &.disactive {
    background-color: #ff000024;
  }
}
</style>
