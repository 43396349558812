<template>
  <Loading v-if="loading" class="loading w-100" />
  <section class="show_receipt card_style">
    <div class="main_header_style">
      <h4>{{ $t("airport") }}</h4>
    </div>
    <div class="row options">
      <form class="col-lg-10 col-md-6 col-12">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          class="sear form-control w-50"
          type="text"
          :placeholder="$t('search by name')"
          v-model="titleReserve"
          @keyup="FetchAirports"
        />
      </form>
      <div class="col-lg-2 mb-2">
        <router-link :to="{ name: 'airportAdd' }" class="PrimaryButton btn"
          ><i class="fa-solid fa-plus"></i> {{ $t("add_airport") }}</router-link
        >
      </div>
    </div>
    <div class="row">
      <table class="table" id="revenueTable">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("name_en") }}</th>
            <th>{{ $t("country") }}</th>
            <th>{{ $t("city") }}</th>
            <th>{{ $t("Code") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in airports.data" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.country }}</td>
            <td>{{ item.city_title }}</td>
            <td>{{ item.code }}</td>

            <td>
              <router-link
                class="btn btn-success text-white mx-1"
                :to="`/airport/edit/${item.id}`"
                :title="$t('edit')"
              >
                <i class="fa-solid fa-pen"></i>
              </router-link>

              <button
                class="btn btn-danger text-white mx-1 remove_btn"
                @click="DeleteAlert(index)"
              >
                <i class="fa-solid fa-trash" :title="$t('delete')"></i>
              </button>
              <button
                @click="cahngeFavorite(item.id)"
                :class="`btn ${
                  item.is_favorite == 1 ? 'btn-warning' : 'btn-secondary'
                }`"
              >
                <i class="fa-solid fa-star text-white"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="airports"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchAirports"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import pagination from "laravel-vue-pagination";
import Loading from "@/components/LottieFiles/loading.vue";
export default {
  name: "show-airports",
  components: {
    pagination,
    Loading,
  },
  data() {
    return {
      airports: [],
      titleReserve: "",
      loading: false,
    };
  },
  methods: {
    // toggle menu

    // fetch airport data
    // FetchAirports(page = 1) {
    //   const newLocal = this.$i18n.locale;
    //   axios.defaults.headers.common["Accept-Language"] = newLocal;
    //   axios.get("/airports?page=" + page).then(({ data }) => {
    //     this.airports = data.data;
    //     // console.log(this.airports);
    //   });
    // },

    // FetchAirports() {
    //   const search = { word: this.titleReserve };
    //   axios.post(`/custom_airports`, { params: search }).then(({ data }) => {
    //     this.airports = data.data;
    //     // console.log(this.reserve);
    //   });
    // },
    cahngeFavorite(id) {
      this.loading = true;
      axios
        .post("/change_airport_favorite_status", { airport_id: id })
        .then(() => {
          this.FetchAirports();
          Swal.fire(this.$t("change status"), "", "success");
          this.loading = false;
        });
    },
    FetchAirports(page = 1) {
      const search = { word: this.titleReserve };
      axios.post(`/custom_airports?page=${page}`, search).then(({ data }) => {
        this.airports = data.data;
        // console.log(this.hotels);
      });
    },
    //delete revenue
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.airports.data[index].id;
          const fromClick = true;
          axios.delete("/airports/" + `${delete_id}`, { fromClick }).then(({ data }) => {
            // console.log(result);
            // console.log(delete_id);
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.airports.data.splice(index, 1); // Remove the deleted item from the array
              // console.log(data);
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.FetchAirports();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_flight";
</style>
