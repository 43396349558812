<template>
  <!-- <div v-if="loading" class="loading">
    <Loading />
  </div> -->
  <section class="card_style">
    <skeltonTitles v-if="loading && $route.params.id" />
    <form class="form_topic" @submit.prevent="editCategory()" v-else>
      <div class="main_header_style">
        <h4>{{ $t("Edit Car Category") }}</h4>
      </div>
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("name_en") }}</label>

            <div class="form-group">
              <TabView>
                <TabPanel
                  v-for="(car, index) in car_cat_name"
                  :key="index"
                  :class="[car.title !== '' ? 'text-success' : '']"
                  :header="$t('cat_name') + ' - ' + car.locale"
                >
                  <div class="form-group mb-0">
                    <input
                      type="text"
                      :placeholder="car.title"
                      class="form-control"
                      :id="`name${index}`"
                      v-model="car.title"
                    />
                    <!-- Add any additional content specific to each tab here -->
                  </div>
                </TabPanel>
              </TabView>
            </div>
            <span class="input-errors" v-if="v$.car_cat_name.$error">{{
              v$.car_cat_name.$errors[0].$message
            }}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("edit") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// import Loading from "@/components/LottieFiles/loading.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import skeltonTitles from "@/components/skelton/skeltonTitles.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
export default {
  name: "edit-category",
  data() {
    return {
      v$: useVuelidate(),
      cat_id: this.$route.params.id,
      car_cat_name: [],
      loading: true,
    };
  },
  components: {
    // Loading,
    skeltonTitles,
    TabView,
    TabPanel,
  },
  validations() {
    return {
      car_cat_name: this.car_cat_name.map(() => {
        return {
          title: { required },
        };
      }),
    };
  },
  methods: {
    fetchcatData() {
      axios.get(`car_categories/${this.cat_id}`).then(({ data }) => {
        // console.log(data);
        this.car_cat_name = data.data?.title;
        // console.log(this.car_cat_name);
        this.car_cat_name &&
          this.car_cat_name?.length > 0 &&
          this.car_cat_name?.map((car) => {
            return {
              locale: car.locale,
              title: car.title,
            };
          });
        this.loading = false;
      });
    },
    // add expness
    async editCategory() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const params = new URLSearchParams();
        this.car_cat_name &&
          this.car_cat_name?.length > 0 &&
          this.car_cat_name.forEach((car) => {
            params.append(`title_${car.locale}`, car.title);
          });

        // Start loading
        axios
          .patch(`/car_categories/${this.cat_id}`, params)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.loading = false; // end loading
            this.$router.push("/car_cat");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            // end loading
          });
      }
    },
  },
  mounted() {
    this.fetchcatData();
  },
};
</script>
<style lang="scss" scoped>
@import "./_cat.scss";
</style>
