<template>
  <div class="row">
    <h4>
      {{ $t("company_branch") }} <span>({{ organization.length }})</span>
    </h4>
    <table class="table" id="employTable">
      <thead>
        <tr>
          <th>{{ $t("ID") }}</th>
          <th>{{ $t("branch_adress") }}</th>
          <th>{{ $t("phone") }}</th>
          <th>{{ $t("responsibe") }}</th>
          <th>{{ $t("email") }}</th>

          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in organization" :key="index">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.email }}</td>

          <td>
            <button
              data-bs-toggle="modal"
              :data-bs-target="`#exampleModal${item.id}`"
              class="btn btn-secondary text-white"
              :title="$t('recipt_detials')"
            >
              <i class="fa-solid fa-circle-info"></i>
            </button>

            <router-link
              class="btn btn-success text-white mx-1"
              :to="`receipt/edit/${item.id}`"
              :title="$t('edit')"
            >
              <i class="fa-solid fa-pen"></i>
            </router-link>

            <button
              class="btn btn-danger text-white mx-1 remove_btn"
              @click="DeleteAlert(index)"
            >
              <i class="fa-solid fa-trash" :title="$t('delete')"></i>
            </button>
          </td>

          <!-- Modal -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
export default {
  name: "company-branch",
  components: {},
  data() {
    return {
      organization: [],
    };
  },
  methods: {
    // fetch organization branch data
    FetchOrganization() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/organization_branches").then(({ data }) => {
        this.organization = data.data;
        //  console.log(this.recipt)
      });
    },

    //delete recipt
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.recipt.data[index].id;
          const fromClick = true;
          axios.delete("/receipt/" + `${delete_id}`, { fromClick }).then(({ data }) => {
            // console.log(result);
            // console.log(delete_id);
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.recipt.data.splice(index, 1); // Remove the deleted item from the array
              // console.log(data);
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.FetchOrganization();
  },

  updated() {
    // axios.get("API_Goes_Here").then((response) => {
    //   this.products = response.data;
    // });
    $("#employTable").DataTable({
      bPaginate: false,
      retrieve: true,
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("employ"),
        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("from") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("employ"),
      },
    });
  },
};
</script>
<style lang="scss" scoped>
@import "../../view/dashboard/organization/_organization.scss";
</style>
