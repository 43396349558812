<template>
  <div
    class="tab-pane fade"
    id="address-tab-pane"
    role="tabpanel"
    aria-labelledby="address-tab"
    tabindex="0"
  >
    <div class="address_hotel">
      <div class="data">
        <h4 class="title">{{ $t("details_address") }}</h4>
        <p class="description">{{ description }}</p>
        <a
          class="show_map"
          :href="`https://www.google.com/maps?q=${lat},${long}&z=${this.zoom}`"
          target="_blank"
          >{{ $t("show_map") }}</a
        >
      </div>
      <div class="map">
        <GoogleMap
          :center="{ lat: parseFloat(lat), lng: parseFloat(long) }"
          :zoom="zoom"
          :api-key="googleMapsApiKey"
        >
          <Marker
            :position="{ lat: parseFloat(lat), lng: parseFloat(long) }"
            :icon="customIcon"
          />
        </GoogleMap>
      </div>
    </div>
  </div>
</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";

export default {
  name: "address-hotel",
  props: {
    description: String,
    lat: Number,
    long: Number,
  },
  components: {
    GoogleMap,
    Marker,
  },
  data() {
    return {
      customIcon: {
        url: "https://cdn-icons-png.flaticon.com/512/11083/11083698.png",
        scaledSize: {
          width: 50,
          height: 50,
        },
      },
      zoom: 15, // Default zoom level,
    };
  },
  computed: {
    googleMapsApiKey() {
      return process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/view/dashboard/hotels/_hotels.scss";
</style>
