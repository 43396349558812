<template>
  <section class="all_emp card_style mt-0">
    <div class="row options mb-2">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4 class="mb-0">
          {{ $t("The Safe") }}
        </h4>
      </div>
    </div>
    <div class="row mb-2">
      <form class="col-lg-8 col-md-12 col-12">
        <input
          class="sear form-control w-50"
          type="text"
          :placeholder="$t('search...')"
          v-model="safeText"
          @keyup="FetchSafes((page = 1))"
        />
      </form>
      <button
        class="SecondButton btn action d-flex align-items-center mx-0 justify-content-center col-lg-2 col-md-12 col-12"
        @click="exportToExcel"
      >
        <i class="fa-solid fa-file-excel"></i>
        {{ $t("export_to_excel") }}
      </button>
      <div class="col-lg-2 col-md-12 col-12">
        <router-link :to="{ name: 'AddSafes' }" class="PrimaryButton btn safe"
          ><i class="fa-solid fa-plus"></i> {{ $t("New Safe") }}</router-link
        >
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table safe" id="safeTable">
          <thead>
            <tr>
              <th>{{ $t("Safe Name") }}</th>
              <th>{{ $t("Value") }}</th>
              <!-- <th>{{ $t("Branch") }}</th> -->
              <th>{{ $t("Date") }}</th>
              <th>{{ $t("Created by") }}</th>
              <th>{{ $t("Status") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="safe in 10" :key="safe">
              <td v-for="safe in 6" :key="safe">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr
              v-for="(safe, index) in safes.data"
              :key="index"
              :class="{ main: safe.is_main == 1, not_main: safe.is_main == 0 }"
            >
              <td>{{ safe.title }}</td>
              <td>{{ safe.money }} {{ $t("EGP") }}</td>
              <!-- <td>{{ safe.branch }}</td> -->
              <td>{{ safe.created_at }}</td>
              <td>{{ safe.text }}</td>
              <td>
                <span
                  class="status"
                  :class="{
                    active: safe.active == 1,
                    not_active: safe.active == 0,
                  }"
                  >{{
                    safe.active == 0 ? $t("Not Active") : $t("Active")
                  }}</span
                >
              </td>
              <td>
                <router-link
                  :to="`/safe/details/${safe.id}`"
                  class="btn btn-primary mx-1 text-white"
                  :title="$t('Safe\'s Details')"
                >
                  <i class="fa-solid fa-circle-exclamation"></i>
                </router-link>
                <router-link
                  :to="`/safe/edit/${safe.id}`"
                  class="btn btn-success mx-1 text-white"
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </router-link>
                <div
                  class="btn btn-warning mx-1"
                  @click="disableSafe(index, safe.active)"
                >
                  <i class="fa-solid fa-ban"></i>
                  <span>{{
                    safe.active == 1 ? $t("Disable") : $t("Activate")
                  }}</span>
                </div>
                <button
                  class="btn btn-danger text-white mx-1"
                  @click="deleteSafe(index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
                <button
                  class="btn btn-info"
                  @click="mainSafe(index)"
                  :title="$t('Main')"
                >
                  <i class="fa-solid fa-list-check"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="safes"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchSafes"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import Skeleton from "primevue/skeleton";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
// import $ from "jquery";
export default {
  name: "all-safes",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      safes: [],
      safeText: "",
      loading: true,
    };
  },
  methods: {
    async exportToExcel() {
      try {
        const response = await axios.post("/fetch_my_organization_accounts", {
          type: 2,
        });
        const responseData = response.data.data.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "my_organization_accounts.xlsx");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed, e.g., show a message to the user
      }
    },
    // fetch safes data
    FetchSafes(page = 1) {
      axios
        .post(`/fetch_my_organization_accounts?page=${page}`, {
          type: 2,
          word: this.safeText,
          per_page: 10,
        })
        .then(({ data }) => {
          // ?page=" + page
          this.safes = data.data;
          this.loading = false;
          // console.log(this.safes)
        });
    },
    //delete safe
    deleteSafe(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.safes.data[index].id;
          const fromClick = true;
          axios
            .delete(`/organization_accounts/${delete_id}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.safes.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
    // Main Safe
    mainSafe(index) {
      Swal.fire({
        title: this.$t("check_main"),
        text: this.$t("are_you_sure_get-it-main"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_main"),
      }).then((result) => {
        if (result.isConfirmed) {
          let organization_account_id = {
            organization_account_id: this.safes.data[index].id,
          };
          axios
            .post("/make_organization_account_main", organization_account_id)
            .then(({ data }) => {
              console.log(data);
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.FetchSafes();
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
    //disable safe
    disableSafe(index, active) {
      Swal.fire({
        title:
          active == 1 ? this.$t("check_disable") : this.$t("check_activate"),
        text:
          active == 1
            ? this.$t("are_you_sure_disable")
            : this.$t("are_you_sure_activate"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText:
          active == 1
            ? this.$t("confirm_disable")
            : this.$t("confirm_activate"),
      }).then((result) => {
        if (result.isConfirmed) {
          let organization_account_id = {
            organization_account_id: this.safes.data[index].id,
          };
          const newLocal = this.$i18n.locale;
          axios.defaults.headers.common["Accept-Language"] = newLocal;
          axios
            .post(
              "/change_organization_account_status",
              organization_account_id
            )
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.FetchSafes();
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.FetchSafes();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "./_safe";
</style>
