<template>
  <section class="card_style">
    <skeltonTitle_image v-if="loading && $route.params.id" />
    <form @submit.prevent="addService()" v-else>
      <div class="main_header_style">
        <h4>
          <span v-if="service_id">{{ $t("edit service") }}</span>
          <span v-else>{{ $t("add service") }}</span>
        </h4>
      </div>
      <div class="row">
        <div class="col-lg-12 col-12">
          <div class="image_upload">
            <label for="imageEntry" class="upload_img" v-if="imageEntry == ''">
              <img :src="Form.image ? Form.image : defaultImage" alt="user" />
            </label>
            <img
              :src="imageEntry"
              v-else-if="imageEntry"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntry"
              class="form-control"
              @change="onFileChange"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
          <span class="input-errors" v-if="v$.imageEntry.$error">{{
            v$.imageEntry.$errors[0].$message
          }}</span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.Form.title.$error">{{
              v$.Form.title.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("describtion") }}</label>
            <div class="accordion" id="accordionExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("describtion") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_content, index) in Form.subtitle"
                      :key="index"
                    >
                      <label class="mt-3"
                        >{{ $t("describtion") }} ({{
                          blog_content.lang
                        }})</label
                      >
                      <Editor
                        v-model="blog_content.name"
                        editorStyle="height: 120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.Form.subtitle.$error">{{
              v$.Form.subtitle.$errors[0].$message
            }}</span>
          </div>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Editor from "primevue/editor";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import skeltonTitle_image from "@/components/skelton/skeltonTitle_image.vue";

export default {
  name: "add-service",
  data() {
    return {
      loading: true,
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      options: {
        placeholder: this.$t("describtion"),
        theme: "snow",
      },
      Form: {
        title: [],
        subtitle: [],
      },
      imageEntry: "",
      defaultImage: require("@/assets/media/image/user.png"),
      service_id: undefined,
    };
  },
  components: {
    Editor,
    skeltonTitle_image,
  },
  validations() {
    return {
      Form: {
        title: this.Form.title.map(() => {
          return {
            name: { required },
          };
        }),
        subtitle: this.Form.subtitle.map(() => {
          return {
            name: { required },
          };
        }),
      },
      imageEntry: this.$route.params.id != undefined ? "" : { required },
    };
  },
  methods: {
    setBlogTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          lang: language,
          name: "",
        });
        this.Form.subtitle.push({
          lang: language,
          name: "",
        });
      });
    },
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    addService() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        this.Form.title.forEach((ele) => {
          formData.append(`title_${ele.lang}`, ele.name);
        });
        this.Form.subtitle.forEach((ele) => {
          formData.append(`subtitle_${ele.lang}`, ele.name);
        });

        if (this.$route.params.id != undefined) {
          if (this.fileData instanceof File) {
            formData.append("image", this.fileData);
          }

          formData.append("service_id", this.service_id);

          axios
            .post("/website/services/update", formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/services_website");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          if (this.fileData != undefined) {
            formData.append("image", this.fileData);
          }
          axios
            .post(`/website/services`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/services_website");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
    showService() {
      if (this.$route.params.id != undefined) {
        this.service_id = this.$route.params.id;
        axios.get(`/fetch_service/${this.service_id}`).then(({ data }) => {
          this.Form.title = data.data.title.map((ele) => {
            return {
              lang: ele.locale,
              name: ele.title,
            };
          });
          this.Form.subtitle = data.data.subtitle.map((ele) => {
            return {
              lang: ele.locale,
              name: ele.subtitle,
            };
          });
          this.Form.image = data.data.image;
          this.loading = false;
        });
      }
    },
  },
  created() {
    this.setBlogTitle();
    this.showService();
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
</style>
