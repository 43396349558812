<template>
  <div class="room_card">
    <div class="head_room">
      <p class="title_room">{{ room.roomType }}</p>
      <p class="cancel_policy">{{ $t("Cancellation Policies") }}</p>
    </div>
    <div class="actions_room">
      <p class="price">{{ room.price }} / {{room.currency}}</p>
      <button
        type="button"
        class="btn SecondButton"
        @click="appendObject(index, room.type)"
      >
        <!-- v-if="roomsSelected.hotel_rooms.length == 0" -->
        {{ $t("Add") }}
      </button>
    </div>
    <!-- <ul class="details">
      <li><i class="fa-solid fa-crop-simple"></i> {{ room.distance }}</li>
      <li><i class="fa-solid fa-bed"></i> {{ room.room }}</li>
    </ul> -->
    <!-- <ul class="services">
      <li v-for="(service, index) in room.services" :key="index">
        <i class="fa-solid fa-check"></i> {{ service.title }}
      </li>
    </ul> -->
  </div>
</template>

<script>
// import { elements } from 'chart.js';
export default {
  props: {
    room: Object,
    rooms: Array,
    index: Number,
    hotel_id: Number,
    session_id: String,
    hotel_code: Number,
    hotel_name: String,
    guest_nationality: String,
    resultIndex: Number,
    offer: Object,
    element_position: Number,
  },
  data() {
    return {
      roomsSelected: {
        hotel_rooms: []
      }
    };
  },
  methods: {
    appendObject(index, room_type) {
      // let element_id = localStorage.getItem("element_id")
      // let element = document.getElementById(`book${element_id}`)
      // let y = element.offsetTop
      window.scrollTo({
        top: this.element_position,
        behavior: 'smooth'
      })
      
      if (room_type == "online") {
        let roomObject = {
          type: "online",
          guest_nationality: JSON.parse(localStorage.getItem("bookDetails")).guest_nationality,
          session_id: this.session_id,
          result_index: this.resultIndex,
          response_time: 0,
          hotel_code: this.hotel_code,
          hotel_name: this.hotel_name,
          no_of_rooms: "1",
  
          hotel_rooms: [
            {
              room_index: this.rooms[index].roomNumber,
              room_type_name: this.rooms[index].roomType,
              room_type_code: this.rooms[index].meta_data.room_type_code,
              rate_plan_code: this.rooms[index].meta_data.rate_plan_code,
              quantity: 1,
              room_rate: {
                price: this.rooms[index].meta_data.room_rate.total_fare,
                room_fare: this.rooms[index].meta_data.room_rate.room_fare_without_precentage,
                currency: this.rooms[index].meta_data.room_rate.currency,
                agent_mark_up: this.rooms[index].meta_data.room_rate.agent_mark_up,
                room_tax: this.rooms[index].meta_data.room_rate.room_tax_without_precentage,
                total_fare: this.rooms[index].meta_data.room_rate.total_fare_without_precentage,
              },
              
              supplements: []
            }
          ],
        };
  
        for (let i = 0; i < this.rooms[index].meta_data.supplements.length; i++) {
          let supplement = {
            supplement_id: this.rooms[index].meta_data.supplements[i].supplement_id,
            supplement_charge_type: this.rooms[index].meta_data.supplements[i].supplement_charge_type,
            price: this.rooms[index].meta_data.supplements[i].price,
            supplement_is_selected: this.rooms[index].meta_data.supplements[i].mandatory,
          };
  
          roomObject.hotel_rooms[0].supplements.push(supplement);
          // roomObject.supplements.push(supplement);
        }
  
        // Initialize hotel_rooms array if it doesn't exist
        if (!localStorage.getItem("bookData")) {
          localStorage.setItem("bookData", JSON.stringify([]));
        }
        // // Get the current bookData array from localStorage
        this.roomsSelected = roomObject;
        
  
        // Update the bookData array in localStorage
        localStorage.setItem("bookData", JSON.stringify(roomObject));
  
        this.$emit('rooms-selected', this.roomsSelected);
        this.$emit('hotel-selected', this.hotel_id);
      }
      if (room_type == "contract") {
        let roomObject = {
          type: "contract",
          guest_nationality: JSON.parse(localStorage.getItem("bookDetails")).guest_nationality,
          contract_id: this.offer.id,
          hotel_rooms: [
            {
              room_index: this.rooms[index].roomNumber,
              room_type_name: this.rooms[index].roomType,
              room_id: this.room.roomNumber,
              count: 1,
              room_rate: {
                currency: this.room.currency,
                total_fare: this.room.price,
              },
            }
          ]
        };
  
        // Initialize hotel_rooms array if it doesn't exist
        if (!localStorage.getItem("bookData")) {
          localStorage.setItem("bookData", JSON.stringify([]));
        }
        // // Get the current bookData array from localStorage
        this.roomsSelected = roomObject;
        
  
        // Update the bookData array in localStorage
        localStorage.setItem("bookData", JSON.stringify(roomObject));
  
        this.$emit('rooms-selected', this.roomsSelected);
        this.$emit('hotel-selected', this.hotel_id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../view/dashboard/hotels/_hotels.scss";
</style>
