<template>
    <div class="amounts">
        <div class="amount">
          <h6 class="title">Net Accommodation Charge : </h6>
          <p class="text">{{ total_selling_price_with_out_tax }}</p>
        </div>
        <div class="amount">
          <h6 class="title">VAT Charge : </h6>
          <p class="text">{{ selling_tax }}</p>
        </div>
        <div class="amount">
          <h6 class="title">Total With Vat : </h6>
          <p class="text">{{ total_selling_price }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'total-price-en',
    props: {
        total_selling_price_with_out_tax: {
            type: String
        },
        selling_tax: {
            type: String
        },
        total_selling_price: {
            type: String
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../_pill.scss";
</style>