<template>
  <div class="border-round border-1 surface-border p-4 surface-card">
    <ul class="m-0 p-0 list-none" v-for="i in 5" :key="i">
      <li class="mb-3">
        <div class="d-flex gap-2 w-100">
          <div class="d-flex flex-column">
            <Skeleton size="6rem" class="mr-2"></Skeleton>
            <Skeleton width="100%" class="my-3"></Skeleton>
          </div>
          <div class="w-25 d-flex gap-3 justify-content-center flex-column">
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
            <div class="w-100 d-flex justify-content-center align-items-center">
              <Skeleton width="50%" height="2rem"></Skeleton>
            </div>
          </div>
          <Skeleton width="80%" height="150px"></Skeleton>
          <div
            class="w-25 px-3 border-start d-flex gap-3 justify-content-center flex-column"
          >
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
            <div class="w-100 d-flex justify-content-center align-items-center">
              <Skeleton width="50%" height="2rem"></Skeleton>
            </div>
          </div>
          <div class="w-25 d-flex gap-3 justify-content-center flex-column">
            <Skeleton></Skeleton>
            <div class="w-100 d-flex justify-content-center align-items-center">
              <Skeleton width="50%" height="2rem"></Skeleton>
            </div>
            <Skeleton></Skeleton>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup></script>

<script>
import Skeleton from "primevue/skeleton";

export default {
  components: {
    Skeleton,
  },
};
</script>

<style></style>
