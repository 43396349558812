<template>
  <section class="login">
    <div class="container">
      <form class="login-card" @submit.prevent="login">
        <h6>{{$t("welcome_to")}}</h6>
        <h3>{{$t("login")}}</h3>
        <div class="form-group">
          <label for="exampleInputEmail1">{{$t("email")}}</label>
          <input type="text" class="form-control" v-model="form.email" :placeholder="$t('email')" required>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">{{$t("password")}}</label>
          <input type="password" class="form-control" v-model="form.password" :placeholder="$t('password')">
        </div>
        <router-link class="forget_password" to="/forget">{{$t("forget_password")}}</router-link>
        <button type="submit" class="btn submit_btn">{{$t("login")}}</button>
    </form>
    </div>
  </section>
</template>

<script>
// import i18n from "@/plugins/i18n";
import Swal from "sweetalert2";

export default {
  name: "app",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      languages: [
        { flag: "us", language: "en", title: this.$i18n.t("english") },
        { flag: "eg", language: "ar", title: this.$i18n.t("arabic") },
        { flag: "id", language: "id", title: this.$i18n.t("indonosia") },
        // { flag: "tr", language: "tr", title: "Turkish" },
      ],
      error: "",
      lang: localStorage.getItem("lang"),
      //   auth: false,
    };
  },
  methods: {
    // start login auth
    async login() {
      try {
        let response = await this.$store.dispatch("Login", this.form);
          // console.log(response);
        if (this.form.email === "" || this.form.password === "") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: this.$t("please_fill_data"),
            timer: 1500,
          });
        } else if (response.data.status === true && response.data.data.status === 0) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: response.data.message,
            timer: 1500,
          });
          // Convert the password to base64
          localStorage.setItem("portalAuth", btoa(this.form.password));
          this.$router.go("/welcome");
          this.error = null;
        } else if(response.data.status === true && response.data.data.status === 1) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: response.data.message,
            timer: 1500,
          });
          // Convert the password to base64
          localStorage.setItem("portalAuth", btoa(this.form.password));
          this.$router.go("/dashboard");
          this.error = null;
        } else if (response.data.status === false) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: response.data.message,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: response.data.message,
            timer: 1500,
          });
        }
      } catch (err) {
        // console.log(JSON.stringify(err));
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: this.$t("There is no employee with this email"),
          timer: 1500,
        });
      }
    },
    // end login auth
  },
  created() {
    // Website name
    const titleEl = document.querySelector("head title");
    titleEl.textContent = `Dashboard`;

    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", this.lang ? this.lang : navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0]);

    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
  },
};
</script>

<style lang="scss" scoped>
  @import "./_auth.scss";
  .lang {
    margin: 0 1%;
    .dropdown-toggle {
      margin: 0 auto;
      display: block;
      &:focus {
        box-shadow: unset;
      }
    }
    .dropdown-menu {
      margin: 0;
      padding: 0;
      right: 0;
      .dropdown-item {
        text-align: start;
        margin: 0.5rem 0;
        padding: 0.5rem 0.7rem;
        cursor: pointer;
        font-family: "bold";
      }
    }
  }
</style>