<template>
    <div class="hotel_offers">
        <offerCard v-for="(offer, index) in offers" :key="index" :index="index" :hotel_id="hotel_id" :guest_nationality="guest_nationality" :hotel_name="hotel_name" :hotel_code="hotel_code" :resultIndex="resultIndex" :session_id="session_id" :offer="offer" :offers="offers" @rooms-selected="receiveRooms" @hotel-selected="receiveHotelId" />
    </div>
</template>

<script>
import offerCard from './offerCard.vue'

export default {
    name: "hotel-offers",
    props: {
        offers: Array,
        hotel_id: Number,
        session_id: String,
        hotel_code: Number,
        hotel_name: String,
        guest_nationality: String,
        resultIndex: Number
    },
    data() {
        return {
            roomsSelected: []
        }
    },
    components: {
        offerCard
    },
    methods: {
        receiveRooms(data) {
            this.roomsSelected = data;
            // console.log(this.roomsSelected)
            this.$emit('rooms-selected', this.roomsSelected);
        },
        receiveHotelId(data) {
            this.hotelId = data;
            this.$emit('hotel-selected', this.hotelId);
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../view/dashboard/hotels/_hotels.scss";
</style>