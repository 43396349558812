<template>
  <section class="show_receipt card_style">
    <div class="row options mb-2">
      <div class="col-2">
        <div class="main_header_style">
          <h4>{{ $t("nationalities") }}</h4>
        </div>
      </div>
      <form class="col-4">
        <!-- <i class="fa-solid fa-magnifying-glass"></i> -->
        <input
          class="sear"
          type="text"
          :placeholder="$t('search by name')"
          v-model="titlenation"
          @keyup="FetchNation"
        />
      </form>

      <div class="col-6">
        <router-link
          :to="{ name: 'nationalityAdd' }"
          class="PrimaryButton btn w-50"
          ><i class="fa-solid fa-plus"></i> {{ $t("add_nation") }}</router-link
        >
      </div>
    </div>
    <div class="row">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("name_en") }}</th>
            <!-- <th>{{ $t("number") }}</th> -->

            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in nationalities.data" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.title }}</td>

            <td>
              <router-link
                class="btn btn-success text-white mx-1"
                :to="`/nationality/edit/${item.id}`"
                :title="$t('edit')"
              >
                <i class="fa-solid fa-pen"></i>
              </router-link>

              <button
                class="btn btn-danger text-white mx-1 remove_btn"
                @click="DeleteAlert(index)"
              >
                <i class="fa-solid fa-trash" :title="$t('delete')"></i>
                {{ $t("delete") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="nationalities"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchNation"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
export default {
  name: "show-nation",
  components: {
    pagination,
  },
  data() {
    return {
      nationalities: [],
      titlenation: "",
    };
  },
  methods: {
    // fetch nationalities data

    FetchNation(page = 1) {
      const search = { word: this.titlenation };
      axios
        .post(`/fetch_nationalities?page=${page}`, search)
        .then(({ data }) => {
          this.nationalities = data.data;
          //  console.log(this.empolyment)
        });
    },
    //delete expness
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.nationalities.data[index].id;
          const fromClick = true;
          axios.delete("/nationalities/" + `${delete_id}`, { fromClick }).then(({ data }) => {
            // console.log(result);
            // console.log(delete_id);
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.nationalities.data.splice(index, 1); // Remove the deleted item from the array
              // console.log(data);
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.FetchNation();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style lang="scss" scoped>
@import "./_nationality.scss";
</style>
