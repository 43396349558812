<template>
  <div class="offcanvas offcanvasBottomTax offcanvas-bottom" tabindex="-1" id="offcanvasBottomTax" aria-labelledby="offcanvasBottomTaxLabel">
        <div class="offcanvas-header">
            <div class="texts">
                <h5 class="offcanvas-title title_setup" id="offcanvasBottomTaxLabel">{{$t("customize_tax")}}</h5>
                <p class="text_setup">{{$t("can_customize_tax_following_inputs")}}</p>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body small">
            <form @submit.prevent="submitTaxSetup()">
                <div class="form-group">
                  <label for="">{{$t("taxes_imposed")}}</label>
                  <input type="text" name="" id="" class="form-control" v-model="tax" :placeholder="$t('taxes_imposed')">
                </div>
                <button type="submit" class="btn PrimaryButton">{{$t("save")}}</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: "tax-sheet",
    data() {
        return {
            tax: ""
        }
    },
    methods: {
        fetchOrganizationTax() {
            axios.get(`/fetch_organization_tax`).then(({ data }) => {
                this.tax = data.data.tax
                // console.log(this.tax)
            });
        },
        submitTaxSetup() {
            let tax = {tax: this.tax}
            axios
            .post("/update_organization_tax", tax)
            .then((response) => {
                Swal.fire({
                    title: "",
                    text: response.data.message,
                    icon: "success",
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                });
            })
            .finally(); // set loading to false when request finish
        }
    },
    created() {
        this.fetchOrganizationTax()
    }
}
</script>

<style lang="scss" scoped>
  @import "@/view/setup/_setup.scss";
  .offcanvasBottomTax {
    height: 50vh !important;
  }
  .PrimaryButton {
    margin-top: 1rem;
  }
</style>