<template>
    <section class="card_style">
    <div class="main_header_style">
      <h4><span v-if="service_id">{{ $t("edit status") }}</span> <span v-else>{{ $t("add status") }}</span></h4>
    </div>

    <div class="row">
      <form @submit.prevent="addService()">
        <div class="image_upload">
          <label for="imageEntry" class="upload_img" v-if="imageEntry == ''">
            <img :src="Form.image ? Form.image : defaultImage" alt="user" />
          </label>
          <img
            :src="imageEntry"
            v-else-if="imageEntry"
            class="entry_image"
            alt="entry"
          />
          <input
            type="file"
            name=""
            id="imageEntry"
            class="form-control"
            @change="onFileChange"
            hidden
            accept="image/png, image/gif, image/jpeg, image/jpg"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("email") }}</label>
            <input
              type="email"
              name=""
              id=""
              class="form-control"
              v-model="Form.email"
              :placeholder="$t('email')"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("phone") }}</label>
            <input type="number" :placeholder="$t('phone')" v-model="Form.phone"  class="form-control"/>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("location") }}</label>
            <input type="text" :placeholder="$t('location')" v-model="Form.location" class="form-control"/>
          </div>
        </div>

        <button type="submit" class="add btn PrimaryButton">
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "add-service",
  data() {
    return {
      options: {
        placeholder: this.$t("describtion"),
        theme: "snow",
      },
      Form: {
        email: "",
        phone: "",
        location: ""
      },
      imageEntry: "",
      defaultImage: require("@/assets/media/image/user.png"),
      service_id: this.$route.params.id,
    };
  },
  methods: {
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },

    addService() {
      const formData = new FormData();

      formData.append("phone", this.Form.phone);
      formData.append("email", this.Form.email);
      formData.append("location", this.Form.location);
      
      if (this.$route.params.id != undefined){
        
        if (this.fileData instanceof File) {
          formData.append("image", this.fileData);
          // console.log(this.fileData)
          // myVariable is a file
        } else {
          // myVariable is not a file
        }
        formData.append("web_status_id", this.service_id);

        axios.post("/website/web_statuses/update" , formData).then(({data})=>{
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/web_statuses");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
      }
      else{
        if (this.fileData != undefined) {
          formData.append("image", this.fileData);
        }
        axios
        .post(`/website/web_statuses`, formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/web_statuses");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
      }
    },
    showService() {
      if (this.$route.params.id != undefined) {
        this.service_id = this.$route.params.id;
        axios.get(`/web_statuses/${this.service_id}`).then(({ data }) => {
          this.Form.email = data.data.email;
          this.Form.phone = data.data.phone;
          this.Form.location = data.data.location;
          this.Form.image = data.data.image;
        });
      }
    },
  },
  created() {
    this.showService();
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
</style>