<template>
  <Loading v-if="loading" />
  <section class="account_statement">
    <div class="container-fluid">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="d-flex align-items-center gap-3">
          <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />

          <h4 class="mb-0">
            {{ $t("account_statement_to") }} {{ branch.branch_name }}
          </h4>
        </div>
        <div id="print-button" class="print_button mx-2">
          <button class="PrimaryButton btn" v-print="'#printStatments'">
            {{ $t("print") }}
          </button>
        </div>
      </div>
      <div class="filter">
        <div class="item">
          <div class="form-group">
            <label>{{ $t("type_account_statement") }}</label>
            <Multiselect
              v-model="typeValue"
              :options="types"
              track-by="id"
              label="title"
              :clear-on-select="true"
              :placeholder="$t('type_account_statement')"
              select-label=""
              :hide-selected="true"
              @update:model-value="fetchBranchType($event, 1)"
            >
            </Multiselect>
          </div>
        </div>
        <div class="item">
          <div class="form-group">
            <label>{{ typeValue.title }}</label>
            <Multiselect
              v-model="branch"
              track-by="branch_id"
              label="branch_name"
              :options="branches"
              :clear-on-select="true"
              :placeholder="$t('type')"
              select-label=""
              :hide-selected="true"
              @keyup="fetchBranchType($event, 2)"
            >
            </Multiselect>
          </div>
        </div>
        <div class="item">
          <div class="form-group">
            <label for="checkin" class="form-label">{{ $t("checkin") }}</label>
            <Datepicker
              v-model="from_date"
              class="w-100"
              lang="ar"
              :placeholder="$t('checkin')"
            />
          </div>
        </div>
        <div class="item">
          <div class="form-group">
            <label for="checkout" class="form-label">{{
              $t("checkout")
            }}</label>
            <Datepicker
              v-model="to_date"
              class="w-100"
              lang="ar"
              :disabled-dates="{ to: from_date }"
              :placeholder="$t('checkout')"
            />
          </div>
        </div>
        <div class="item">
          <div class="form-group">
            <label>{{ $t("typeTransaction") }}</label>
            <Multiselect
              v-model="typeTransactionValue"
              track-by="id"
              label="title"
              :options="typeTransaction"
              :clear-on-select="true"
              :placeholder="$t('typeTransaction')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <div class="submitButtons">
          <button class="btn PrimaryButton" type="button" v-if="loadingspin">
            <i class="fa-solid fa-spinner fa-spin-pulse"></i>
          </button>
          <button
            v-else
            class="btn PrimaryButton"
            type="button"
            @click="fetchAccountStatments"
          >
            {{ $t("submit") }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="table-responsive" id="printStatments">
          <HeaderPdf
            :title="$t('account_statement_to')"
            :fromDate="from_date"
            :toDate="to_date"
            :typeTransactionValue="typeTransactionValue"
            :typeValue="typeValue"
            :branch="branch"
          />
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{ $t("book_number") }}</th>
                <th>{{ $t("main_serial_number") }}</th>
                <th>{{ $t("serial_number") }}</th>
                <th>{{ $t("date") }}</th>
                <th>{{ $t("Cost_center") }}</th>
                <th>{{ $t("creditor") }}</th>
                <th>{{ $t("debtor") }}</th>
                <th>{{ $t("balance") }}</th>
                <th class="w-25">{{ $t("the statement") }}</th>
                <!-- <th>{{ $t("clerck") }}</th> -->
              </tr>
            </thead>
            <tbody v-if="loading" class="text-center">
              <tr v-for="statment in 10" :key="statment">
                <td v-for="statment in 8" :key="statment">
                  <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
                </td>
              </tr>
            </tbody>

            <tbody v-else>
              <tr v-for="(statment, index) in statments.data" :key="index">
                <td>
                  <a
                    :href="`/hotels/book_offline/${statment.morph_data.id}`"
                    target="_blank"
                    >{{ statment.morph_data.book_number || "-" }}</a
                  >
                </td>
                <td>
                  <span
                    @click="getTransferItem(statment.daily_restrictions_id)"
                    data-bs-toggle="offcanvas"
                    :data-bs-target="`#offcanvasBottomDetails${item.id}`"
                    aria-controls="offcanvasBottom"
                    :title="$t('details')"
                    class="cursor-pointer"
                  >
                    {{ statment.main_serial_number }}
                  </span>
                </td>
                <td>
                  {{
                    statment.exchange_serial_number
                      ? statment.exchange_serial_number
                      : statment.receipt_serial_number
                        ? statment.receipt_serial_number
                        : ""
                  }}
                </td>
                <td>{{ statment.daily_restriction_date }}</td>
                <td>
                  {{ statment.cost_center?.title }}
                </td>
                <td>{{ statment.creditor }}</td>
                <td>{{ statment.debtor }}</td>
                <td>
                  {{
                    statment.balance >= 0
                      ? statment.balance
                      : "- (" + Math.abs(statment.balance) + ")"
                  }}
                </td>
                <td style="max-width: 150px">{{ statment.description }}</td>
                <!--            <td>-->
                <!--              <span v-if="client.type === 1"> {{ $t("debtor") }}</span>-->
                <!--              <span v-if="client.type === 2"> {{ $t("creditor") }}</span>-->
                <!--            </td>-->
                <!-- <td>{{ statment.employee }}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row mt-3">
        <pagination
          :data="statments"
          class="mx-auto d-flex align-items-center justify-content-center pagination"
          @pagination-change-page="fetchAccountStatments"
        >
          <template v-slot:prev-nav>&lt;</template>
          <template v-slot:next-nav>&gt;</template>
        </pagination>
      </div>
    </div>
  </section>
  <detailsOffCanvas :item="item" />
</template>

<script>
import axios from "axios";
import HeaderPdf from "@/components/pdf/headerPdf.vue";

import Multiselect from "vue-multiselect";
import VueDatepickerUi from "vuejs3-datepicker";
import Loading from "@/components/LottieFiles/loading.vue";
import pagination from "laravel-vue-pagination";
import Skeleton from "primevue/skeleton";
import detailsOffCanvas from "@/components/dailyConstruct/detailsOffCanvas.vue";
import moment from "moment";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  data() {
    return {
      loadingspin: false,
      types: [
        {
          id: 1,
          title: this.$t("client_indvidual"),
        },
        {
          id: 2,
          title: this.$t("client_company"),
        },
        {
          id: 3,
          title: this.$t("banks"),
        },
        {
          id: 4,
          title: this.$t("safe_box"),
        },
        {
          id: 5,
          title: this.$t("suppliers"),
        },
        {
          id: 6,
          title: this.$t("hotels"),
        },
        {
          id: 7,
          title: this.$t("tree_branches"),
        },
      ],
      typeValue: {
        id: 1,
        title: this.$t("client_indvidual"),
      },
      typeTransaction: [
        {
          id: 2,
          title: this.$t("store"),
        },
        {
          id: 1,
          title: this.$t("transfer"),
        },
      ],
      typeTransactionValue: "",
      from_date: "",
      to_date: "",
      loading: false,
      statments: [],
      branch: {
        branch_id: "",
        branch_name: "",
      },
      item: {},
      branches: [],
      treeBraches: [],
    };
  },
  components: {
    Multiselect,
    Loading,
    Datepicker: VueDatepickerUi,
    pagination,
    Skeleton,
    detailsOffCanvas,
    HeaderPdf,
    AddFavDailog,
  },
  methods: {
    fetchAccountStatments() {
      this.loadingspin = true;
      const formData = new FormData();
      formData.append("branch_id", this.branch.branch_id);
      if (this.from_date) {
        formData.append("from", moment(this.from_date).format("YYYY-MM-DD"));
      }
      if (this.to_date) {
        formData.append("to", moment(this.to_date).format("YYYY-MM-DD"));
      }
      if (this.typeTransactionValue) {
        formData.append("type", this.typeTransactionValue.id);
      }
      axios.post("fetch_branch_statements", formData).then(({ data }) => {
        this.statments = data.data;
        this.loadingspin = false;
        // console.log(this.statments);
      });
    },
    fetchBranchType(event, type) {
      const formData = new FormData();
      formData.append("type", this.typeValue.id); //this.branchId);
      if (event != undefined && type == 2) {
        formData.append("word", event.target.value);
        // console.log(event.target.value);
      }
      axios.post("/fetch_branch_type", formData).then(({ data }) => {
        this.branches = data.data;
        // console.log(this.statments);
      });
    },
    getTransferItem(daily_restrictions_id) {
      axios
        .get(`/daily_restriction/${daily_restrictions_id}`)
        .then(({ data }) => {
          this.item = data.data;
          // console.log(this.item);
        });
    },
  },
  mounted() {
    // this.fetchAccountStatments();
    this.fetchBranchType();
  },
};
</script>

<style lang="scss" scoped>
.filter {
  display: flex;
  align-items: center;
  border: 1px solid #377373;
  border-radius: 10px;
  margin-bottom: 1rem;
  .item {
    width: 18%;
    padding: 0 1rem;
  }
  .submitButtons {
    width: 10%;
  }
}
</style>
