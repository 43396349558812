<template>
  <div class="row">
    <div class="col-lg-2 col-12 d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h6 class="position-relative">
        {{ $t("service_offline_booking") }}
        <img
          src="@/assets/media/image/posted.png"
          alt=""
          srcset=""
          class="mx-3"
          v-if="posted === 1"
          style="
            position: absolute;
            opacity: 0.4;
            width: 30%;
            left: -14%;
            top: -145%;
            /* transform: translate(-50px, -50px); */
            width: 85%;
          "
        />
      </h6>
      <!-- <img src="@/assets/media/image/icon/menu-board.svg" alt="" /> -->
    </div>
    <div class="col-lg-4 col-12 d-flex align-items-center">
      <div class="row w-100">
        <div class="col-lg-6 mb-0">
          <div class="d-flex input-group mb-0 align-items-center">
            <label for="reservation_number"
              ><i class="fa-regular fa-rectangle-list"></i>
              {{ $t("reservation_number") }}</label
            >
            <input
              type="number"
              name=""
              id="reservation_number"
              class="form-control mx-1"
              v-model="reservation_number"
              disabled
            />
          </div>
        </div>
        <div class="col-lg-6 mb-0">
          <div class="d-flex form-group align-items-center mb-0">
            <label class="form-label w-50">
              <i class="fa-solid fa-circle-info"></i>
              {{ $t("reservation_status") }}</label
            >
            <Multiselect
              v-model="statusValue"
              :options="statusOptions"
              label="title"
              track-by="status"
              :clear-on-select="true"
              :placeholder="$t('status')"
              select-label=""
              :hide-selected="true"
            ></Multiselect>
            <!--              @update:model-value="selectStatus(statusValue)"-->
            <!-- :disabled="!client_option_date" -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-lg-4 col-12">
      <div class="form-group">
        <span
          :class="[
            'badge mx-1',
            paymentStatus === 0
              ? 'bg-primary'
              : paymentStatus === 1
              ? 'bg-warning text-dark'
              : paymentStatus === 2
              ? 'bg-success'
              : '',
          ]"
        >
          {{
            paymentStatus === 0
              ? $t("initial")
              : paymentStatus === 1
              ? $t("pending")
              : paymentStatus === 2
              ? $t("completed")
              : ""
          }}
        </span>
      </div>
    </div> -->
    <div class="col-lg-6 col-12">
      <div class="d-flex justify-content-end">
        <button
          v-if="status === 2"
          @click="changePsotedStatus"
          class="btn btn-outline-info fs-12 d-flex align-items-center justify-content-center mx-2"
        >
          <i class="fa-solid fa-signs-post"></i>
          <span> {{ posted === 1 ? $t("unpost") : $t("post") }} </span>
        </button>
        <router-link
          v-if="$route.params.id"
          :to="`/${
            invoice_theme == 'IN01'
              ? 'book_table'
              : invoice_theme == 'IN02'
                ? 'invoice'
                : 'book_table'
          }/${$route.params.id}/${
            statusValue.status == 0
              ? 'option-date'
              : statusValue.status == 1
                ? 'price-offer'
                : statusValue.status == 2
                  ? 'pill-confirm'
                  : statusValue.status == 3
                    ? 'cancelation'
                    : 'price-offer'
          }`"
          title="invoice"
          class="btn btn-outline-primary fs-12 d-flex align-items-center justify-content-center"
        >
          <i class="fa-solid fa-print"></i>
        </router-link>
        <!-- v-if="hotelData.remaining_amount != 0" -->
        <!-- {{  hotelData.remaining_amount }} -->

        <!-- <router-link
          class="btn btn-outline-primary mx-1 fs-12 d-flex align-items-center justify-content-center"
          :to="`/tax-invoice-en/${base_book_id}`"
          v-tooltip="
            clientData.HasTaxInvoice == 1
              ? $t('show') + ' ' + $t('TAX INVOICE') + ' EN'
              : $t('export') + ' ' + $t('TAX INVOICE') + ' EN'
          "
          style="width: max-content !important"
          v-if="clientData.lock_status == 1"
        >
          <i class="fa-solid fa-print"></i>
        </router-link>

        <router-link
          class="btn btn-outline-primary mx-1 fs-12 d-flex align-items-center justify-content-center"
          :to="`/tax-invoice-ar/${base_book_id}`"
          v-tooltip="
            clientData.HasTaxInvoice == 1
              ? $t('show') + ' ' + $t('TAX INVOICE') + ' AR'
              : $t('export') + ' ' + $t('TAX INVOICE') + ' AR'
          "
          style="width: max-content !important"
          v-if="clientData.lock_status == 1"
        >
          <i class="fa-solid fa-print"></i>
        </router-link> -->

        <button
          v-if="this.$route.params.id && remaining_amount != 0"
          @click="
            $router.push(
              `/hotels/book_offline/${this.$route.params.id}/${reservation_number}/attachments`,
            )
          "
          :disabled="status == 3"
          class="btn SecondButton d-flex align-items-center justify-content-center gap-2"
        >
          <i class="fa-solid fa-clipboard-list"></i>
          <span class="d-flex align-items-center justify-content-center gap-2"
            >{{ $t("attachments") }}
            <span class="badge bg-danger">
              {{ attachmentsCount }}
            </span></span
          >
        </button>
        <button
          v-if="this.$route.params.id && remaining_amount != 0"
          :disabled="status == 3"
          @click="
            $router.push(
              `/hotels/book_offline/${this.base_book_id}/payment/${$route.params.id}/${status}`,
            )
          "
          class="btn SecondButton d-flex align-items-center justify-content-center gap-2"
        >
          <i class="fa-solid fa-clipboard-list"></i>
          <span>{{ $t(clientPayment) }}</span>
        </button>

        <whatsAppDialog
          :clientPhone="clientPhone"
          :guest_name="guest_name"
          :rooms="rooms"
          :clientEmail="clientEmail"
          :total_cost_price_with_out_tax="total_cost_price_with_out_tax"
          :reservation_number="reservation_number"
          :tax="tax"
          :total_room_cost_with_tax="total_room_cost_with_tax"
        />
        <emailDialog
          :guest_name="guest_name"
          :rooms="rooms"
          :clientEmail="clientEmail"
          :total_cost_price_with_out_tax="total_cost_price_with_out_tax"
          :reservation_number="reservation_number"
          :tax="tax"
          :total_room_cost_with_tax="total_room_cost_with_tax"
        />

        <button
          v-if="this.$route.params.id"
          class="btn history"
          @click="history"
          :title="$t('History')"
        >
          <i class="fa-solid fa-clock-rotate-left"></i>
        </button>
        <button
          class="sumbit-btn btn d-flex align-items-center justify-content-center gap-2"
          :disabled="posted === 1"
          @click="confirmBooking"
        >
          <i class="fa-solid fa-clipboard-list"></i>
          <span>{{ $t("save") }}</span>
        </button>
        <!-- <router-link
          to="/hotels/book_offline"
          class="SecondButton d-flex align-items-center justify-content-center gap-2"
        >
          <i class="fa-solid fa-clipboard-list"></i>
          <span>{{ $t("new_save") }}</span>
        </router-link> -->
      </div>
    </div>
  </div>
  <div
    class="hotel_book_offline mt-2 mb-3"
    v-if="loading && this.$route.params.id"
  >
    <div class="hotel-grid">
      <div class="offline-card p-3" v-for="items in 3" :key="items">
        <div class="row">
          <div class="col-6" v-for="items in 8" :key="items">
            <div class="form-group">
              <Skeleton height=".5rem" width="6rem" class="mb-1"></Skeleton>
              <Skeleton width="8rem" height="2rem" class="mb-1"></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form class="hotel_book_offline" @submit.prevent="confirmBooking" v-else>
    <div class="">
      <div class="hotel-grid gap-1 p-2">
        <div class="offline-card">
          <!--          <img-->
          <!--            src="@/assets/media/image/posted.png"-->
          <!--            alt=""-->
          <!--            srcset=""-->
          <!--            class="water-mark w-100"-->
          <!--            v-if="posted === 1"-->
          <!--          />-->
          <div class="row">
            <div class="col-lg-4 col-md-12 col-12">
              <div class="input-group">
                <div class="d-flex justify-content-between">
                  <label
                    class="form-label"
                    v-tooltip.left="$t('client_gender')"
                  >
                    {{ maxWordTruncate($t("client_gender")) }}
                  </label>
                </div>

                <Multiselect
                  v-model="clientsTypeValue"
                  :options="clientsTypes"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('gender')"
                  select-label=""
                  :hide-selected="true"
                  :custom-label="formatClientLabel"
                  @update:model-value="FetchSuppliers(clientsTypeValue)"
                >
                </Multiselect>
              </div>
            </div>
            <div class="col-lg-8 col-md-12 col-12">
              <div class="d-flex justify-content-between">
                <label
                  class="guest_label form-label"
                  v-tooltip.left="$t('client_name')"
                >
                  {{ maxWordTruncate($t("client_name")) }}
                </label>
                <clientDialog
                  @supplierAdded="FetchSuppliers(clientsTypeValue)"
                />
              </div>
              <div class="input-group">
                <Multiselect
                  v-model="supplierValue"
                  :options="suppliers"
                  label="name"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('client_name')"
                  select-label=""
                  :hide-selected="true"
                  @update:model-value="defaultNationality(supplierValue)"
                  :custom-label="formatSupplierLabel"
                >
                </Multiselect>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="form-label" v-tooltip="$t('guest_name')">
                  {{ maxWordTruncate($t("guest_name")) }}</label
                >
                <input
                  type="text"
                  name=""
                  id="guest_name"
                  class="form-control"
                  v-model="guest_name"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="form-label" v-tooltip="$t('guest_nationality')">
                  {{ maxWordTruncate($t("guest_nationality")) }}</label
                >

                <Multiselect
                  v-model="nationalityValue"
                  :options="nationalities"
                  :placeholder="$t('choose_nationality')"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  select-label=""
                  :hide-selected="true"
                  @keyup="searchNationality"
                  :custom-label="formatNationalityLabel"
                >
                </Multiselect>
              </div>
            </div>
            <div class="col-12 d-flex align-items-center">
              <button
                type="submit"
                class="sumbit-btn btn w-100"
                :disabled="posted === 1"
              >
                <i class="fa-solid fa-circle-notch fa-spin" v-if="loader"></i>

                <span v-else
                  ><i class="fa-solid fa-clipboard-list"></i>
                  {{ $t("save") }}</span
                >
              </button>
            </div>
          </div>
        </div>
        <div class="offline-card">
          <!-- <img
            src="@/assets/media/image/posted.png"
            alt=""
            srcset=""
            class="water-mark w-100"
            v-if="posted === 1"
          /> -->
          <div class="row">
            <div class="col-lg-7 col-md-12 col-12">
              <div class="input-group">
                <label
                  class="form-label"
                  v-tooltip="$t('option_date_client')"
                  >{{ $t("option_date_client") }}</label
                >
                <Datepicker
                  v-model="client_option_date"
                  lang="ar"
                  :placeholder="$t('choose')"
                  :disabledDates="disabledDates"
                  @input="upDateStatus"
                />
              </div>
            </div>
            <div class="col-lg-5 col-md-12 col-12">
              <div class="input-group">
                <label class="form-label" v-tooltip="$t('time')">
                  {{ maxWordTruncate($t("time")) }}</label
                >
                <!-- <input type="time" name="" id="time" class="form-control" v-model="client_option_time" /> -->
                <Calendar
                  class="col-lg-12 w-100"
                  v-model="client_option_time"
                  showIcon
                  iconDisplay="input"
                  timeOnly
                  hourFormat="12"
                >
                  <template #inputicon="{ clickCallback }">
                    <i class="pi pi-clock" @click="clickCallback" />
                  </template>
                </Calendar>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="input-group">
                <label
                  class="form-label"
                  v-tooltip="$t('Terms and Conditions')"
                >
                  {{ $t("Terms and Conditions") }}
                </label>
                <Multiselect
                  v-model="termsAndCondition"
                  :options="terms_and_conditions"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('Choose_Terms_an..')"
                  select-label=""
                  :hide-selected="true"
                >
                </Multiselect>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="input-group">
                <label class="form-label" v-tooltip="$t('bank_account')">
                  {{ maxWordTruncate($t("bank_account")) }}</label
                >
                <Multiselect
                  v-model="bank_account"
                  :options="bank_accounts"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('Choose_bank_acc..')"
                  select-label=""
                  :hide-selected="true"
                >
                </Multiselect>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group">
                <label class="form-label" v-tooltip="$t('notes_for_client')">
                  {{ maxWordTruncate($t("notes")) }}</label
                >
                <textarea
                  class="form-control"
                  :placeholder="$t('notes_for_client')"
                  v-model="book_notes"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="offline-card">
          <!--          <img-->
          <!--            src="@/assets/media/image/posted.png"-->
          <!--            alt=""-->
          <!--            srcset=""-->
          <!--            class="water-mark w-100"-->
          <!--            v-if="posted === 1"-->
          <!--          />-->
          <div class="row">
            <div class="col-lg-6 col-md-12 col-12">
              <div class="input-group">
                <label
                  class="form-label"
                  v-tooltip="$t('total_services_without_cost')"
                >
                  {{
                    maxWordTruncate($t("total_services_without_cost"), 40)
                  }}</label
                >
                <input
                  type="text"
                  name=""
                  id="total_service_without_cost"
                  v-model="total_service_cost_with_out_tax"
                  class="form-control"
                  disabled
                />
                <!-- <span class="icon_form">
                  <i class="fa-solid fa-wallet"></i>
                </span> -->
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="input-group">
                <label
                  class="form-label"
                  v-tooltip="$t('total_selling_room_without_tax')"
                >
                  {{
                    maxWordTruncate($t("total_selling_room_without_tax"))
                  }}</label
                >
                <input
                  type="text"
                  name=""
                  id="total_selling_room_without_tax"
                  class="form-control"
                  v-model="total_service_selling_with_out_tax"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="input-group">
                <label class="form-label" v-tooltip="$t('discount_type')">
                  {{ maxWordTruncate($t("discount_type")) }}</label
                >
                <Multiselect
                  v-model="discountValue"
                  :options="discountTypes"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('discount...')"
                  select-label=""
                  :hide-selected="true"
                >
                </Multiselect>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="input-group">
                <label class="form-label" v-tooltip="$t('discount')">
                  {{ maxWordTruncate($t("discount")) }}</label
                >
                <div class="form-group d-flex align-items-center gap-2">
                  <!-- <Multiselect
                    v-model="percentageValue"
                    :options="percentages"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('discount')"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect> -->
                  <i
                    class="fa-solid fa-percent"
                    v-if="percentageValue.id == 1"
                  ></i>
                  <i
                    class="fa-solid fa-dollar-sign"
                    v-else-if="percentageValue.id == 2"
                  ></i>
                  <i class="fa-solid fa-ban" v-else></i>

                  <input
                    type="text"
                    name=""
                    id="discount"
                    class="form-control"
                    v-model="percentage"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-3 col-12">
              <div class="input-group">
                <label
                  class="form-label"
                  v-tooltip="$t('total_without_discount')"
                >
                  {{ maxWordTruncate($t("total_without_discount"), 30) }}</label
                >
                <input
                  type="text"
                  name=""
                  id="total_without_discount"
                  class="form-control"
                  v-model="total_cost_price"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="input-group">
                <label class="form-label" v-tooltip="$t('discount_value')">
                  {{ maxWordTruncate($t("discount_value")) }}</label
                >
                <input
                  type="text"
                  name=""
                  id="discount_value"
                  class="form-control"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="input-group">
                <label class="form-label" v-tooltip="$t('tax')">
                  {{ maxWordTruncate($t("tax")) }}</label
                >
                <input
                  type="text"
                  name=""
                  id="tax"
                  class="form-control"
                  v-model="tax"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-3 col-12">
              <div class="input-group">
                <label class="form-label" v-tooltip="$t('total_with_tax')">
                  {{ maxWordTruncate($t("total_with_tax"), 30) }}</label
                >
                <input
                  type="text"
                  name=""
                  id="total_with_tax"
                  class="form-control"
                  v-model="total_cost_price_with_tax"
                  disabled
                />
              </div>
            </div>
            <div
              class="col-lg-3 col-md-3 col-12 d-flex align-items-center gap-2"
            >
              <Checkbox
                v-model="cashOnDelivery"
                inputId="cashOnDelivery"
                name="cash_on_delivery"
                :binary="true"
                value="1"
              />
              <label for="cash_on_delivery" class="form-label mb-0">
                {{ $t("cash_on_delivery") }}
              </label>
            </div>
            <!-- <div class="col-lg-6 col-md-12 col-12">
              <div class="input-group">
                <label
                  class="form-label"
                  v-tooltip="$t('responsible_employee')"
                >
                  {{ maxWordTruncate($t("responsible_employee")) }}</label
                >
                <Multiselect
                  v-model="employeeValue"
                  :options="employees"
                  label="name"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('empoly_name')"
                  select-label=""
                  disabled
                  :hide-selected="true"
                >
                </Multiselect>
                <input
                  type="text"
                  name=""
                  id="responsible_employee"
                  class="form-control"
                  v-model="employeeName"
                  disabled
                />
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Multiselect from "vue-multiselect";
// import "vue-datepicker-ui/lib/vuedatepickerui.css";
import Datepicker from "vuejs3-datepicker";
import Skeleton from "primevue/skeleton";
import Checkbox from "primevue/checkbox";
import "@vuepic/vue-datepicker/dist/main.css";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import Calendar from "primevue/calendar";
import whatsAppDialog from "@/components/Dialogs/whatsAppDialog.vue";
import emailDialog from "@/components/Dialogs/emailDialog.vue";
import clientDialog from "@/components/Dialogs/clientDialog.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  props: ["fetchData", "clientData", "base_book_id", "rooms", "is_portal"],
  emits: ["roomAdded", "fetchData"], // Declare the custom event here
  data() {
    return {
      posted: null,
      hotelsArray: [
        {
          hotel: "",
          number_of_rooms: 1,
        },
      ],
      loader: false,
      status: 0,
      loading: true,
      cashOnDelivery: false,
      statusOptions: [
        {
          status: 0,
          title: this.$t("pending"),
        },
        {
          status: 1,
          title: this.$t("Tentitive"),
        },
        {
          status: 2,
          title: this.$t("confirmed"),
        },
        {
          status: 3,
          title: this.$t("canceled"),
        },
      ],
      statusValue: {
        status: 0,
        title: this.$t("pending"),
      },
      disableStatus: true,
      bookId: "",
      visibleHotelDialog: false,
      attachmentsCount: 0,
      text: `شركة جود لاب التقنية
            عزيزي العميل،
            نرجو التكرم بسداد كامل مبلغ الحجز رقم 4365 قبل تاريخ 03/01/2024  على الساعة 17:38

            معلومات الحجز :
            التاريخ : 03/01/2024
            رقم الحجز : 4365
            المبلغ الجملي : 500.00 ريال

            الفندق : فندق أنجم مكة
            تاريخ الدخول : 03/01/2024
            تاريخ الخروج : 04/01/2024
            النزيل : test mahmoud
            آخر تاريخ للدفع : 03/01/2024 على الساعة 17:38

            الغرف :
            نوع الغرفة : غرفة لأربع أشخاص من 1/3/2024 الى 1/4/2024
            عدد الغرف : 1
            الإطلالة : مطلّة على المدينة
            الوجبة : غرفة بدون وجبة


            الخدمات :
            معلومات العميل :
            معرّف العميل : 12949
            الإسم و اللقب : sokna alhijaz
            الهاتف :
            الجوال :
            البريد الإلكتروني :`,
      clientPhone: "01111488849",
      clientsTypes: [],
      clientsTypeValue: "",
      suppliers: [],
      supplierValue: "",
      hotels: [],
      hotelValue: "",
      number_of_available_rooms: "",
      guest_name: "",
      percentages: [
        {
          id: 1,
          title: this.$t("percentages"),
        },
        {
          id: 0,
          title: this.$t("value"),
        },
      ],
      percentageValue: "",
      discountTypes: [],
      discountValue: "",
      percentage: "",
      employees: [],
      employeeValue: "",
      employeeName: JSON.parse(localStorage.getItem("user"))?.name,
      fromDate: "",
      day_number: "",
      toDate: "",
      client_option_date: "",
      client_option_time: "",
      supplier_option_date: "",
      supplier_option_time: "",
      book_notes: "",
      total_without_tax: "",
      total_with_tax: "",
      total_room_cost_with_out_tax: 0,
      total_room_cost_with_tax: "",
      total_service_cost_with_out_tax: "",
      total_service_with_tax: "",
      total_room_selling_with_out_tax: "",
      total_room_selling_with_tax: "",
      total_service_selling_with_out_tax: "",
      total_cost_price: "",
      total_cost_price_with_tax: "",
      tax: "",
      reservation_status: "",
      reservation_number: "",
      nationalities: [],
      nationalityValue: "",
      bank_accounts: [],
      bank_account: "",
      number_of_rooms: 1,
      checkInHijriDate: "",
      checkOutHijriDate: "",
      terms_and_conditions: [],
      termsAndCondition: "",
      paymentStatus: "",
      remaining_amount: "",
      clientPayment: "",
      hotel_data: {
        description: [
          {
            description: "",
          },
        ],
      },
      hotel_image: "",
      hotel_title: "",
      hotel_country: "",
      hotel_city: "",
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "ar",
      star_count: "",
      total_cost_price_with_out_tax: "",
      clientEmail: "",
      invoice_theme: JSON.parse(localStorage.getItem("user"))?.invoice_theme,
    };
  },
  components: {
    Multiselect,
    Skeleton,
    Datepicker,
    Calendar,
    whatsAppDialog,
    emailDialog,
    clientDialog,
    AddFavDailog,
    Checkbox,
  },

  methods: {
    getStatusValue(status) {
      switch (status) {
        case 0:
          return { status: 0, title: this.$t("pending") };
        case 1:
          return { status: 1, title: this.$t("Tentitive") };
        case 2:
          return { status: 2, title: this.$t("confirmed") };
        case 3:
          return { status: 3, title: this.$t("Canceled") };
        default:
          return { status: 0, title: this.$t("pending") };
      }
    },
    history() {
      this.$router.push({
        path: `/hotels/book_offline/${this.$route.params.id}/history`,
      });
    },
    addHotel() {
      this.hotelsArray.push({
        hotel: "",
        number_of_rooms: 1,
      });
      // console.log(this.hotelsArray);
    },
    removeHotel(index) {
      this.hotelsArray.splice(index, 1);
    },

    defaultNationality(value) {
      if (value.client_type == 1) {
        if (value.country) {
          this.nationalityValue = value.country;
          this.guest_name = value.name;

          // console.log(this.nationalityValue);
          this.formatNationalityLabel(this.nationalityValue);
        }
      }
      if (value.discount_type) {
        this.discountValue = value.discount_type;
        this.percentage = value.discount_type.percentage;
        if (value.discount_type.discount_type == 1) {
          this.percentageValue = {
            id: 1,
            title: this.$t("percentages"),
          };
        } else {
          this.percentageValue = {
            id: 2,
            title: this.$t("value"),
          };
        }
      }
    },
    termsAndConditions() {
      axios.post("/index_terms_and_conditions").then(({ data }) => {
        this.terms_and_conditions = data.data;
        // console.log(this.terms_and_conditions);
      });
    },
    setToDate() {
      this.client_option_date = "";
    },
    fetchEmployees() {
      axios
        .get("/fetch_organization_employees")
        .then(({ data }) => {
          this.employees = data.data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.employees = [];
        });
    },

    HotelDialog(hotel_id) {
      this.visibleHotelDialog = true;
      axios
        .get(`/offline_hotels/${hotel_id}`)
        .then(({ data }) => {
          this.hotel_data = data.data;
          this.hotel_image =
            data.data.images.length > 0 ? data.data.images[0].image : "";
          data.data.title.forEach((ele) => {
            if (ele.locale == this.lang) {
              this.hotel_title = ele.title;
            }
          });
          if (this.hotel_title == "") {
            this.hotel_title = data.data.title[0].title;
          }
          this.hotel_city = data.data.city.title;
          this.hotel_country = data.data.country.title;
          this.star_count = parseInt(data.data?.star_count);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },

    fetchClientType() {
      axios
        .post("/index_client_types", {
          is_portal: this.$route.params.id ? this.is_portal : 0,
        })
        .then(({ data }) => {
          this.clientsTypes = data.data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.clientsTypes = [];
        });
    },
    FetchSuppliers(client_type_id) {
      if (client_type_id != undefined) {
        axios
          .post("/fetch_clients_by_client_tpye_id", {
            client_type_id: client_type_id.id,
          })
          .then(({ data }) => {
            this.suppliers = data.data;
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.suppliers = [];
          });
      }
    },

    fetchDiscountType() {
      axios
        .get("/discount_types")
        .then(({ data }) => {
          this.discountTypes = data.data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.discountTypes = [];
        });
    },

    searchHotel(event) {
      const searchText = { word: event?.target?.value };
      if (event?.target?.value.length) {
        axios
          .post(`/fetch_new_home_hotels`, searchText)
          .then((res) => (this.hotels = res.data.data.hotels || []))
          .catch((err) => console.log(err));
      } else {
        axios
          .post(`/fetch_new_home_hotels`)
          .then((res) => (this.hotels = res.data.data.hotels))
          .catch((err) => console.log(err));
      }
    },
    formatHotelLabel(hotel) {
      // console.log(hotel, "hotel");
      const title = hotel.title || hotel.hotel_name || "";
      const cityTitle = hotel.city_title || "";
      const countryTitle = hotel.country_title || "";
      const id = hotel.id || hotel.hotel_id || "";

      return `${title} - ${cityTitle}, ${countryTitle} (${id})`;
    },
    formatClientLabel(client) {
      return `${client.title ? client.title : client.name}  (${client.id}) `;
    },
    formatSupplierLabel(supplier) {
      return `${supplier.name} (${supplier.id})`;
    },
    updateDayNumber() {
      // Check if at least one datepicker has a value
      if (this.fromDate && this.toDate) {
        const selectedFromDate = new Date(this.fromDate); // Ensure Date object
        const selectedToDate = new Date(this.toDate); // Ensure Date object
        const diffTime = Math.abs(selectedToDate - selectedFromDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        this.day_number = diffDays;
        this.day_number = diffDays;
      } else {
        this.day_number = 1; // Reset if both dates are empty
        // this.updateFromHijriDate(this.fromDate)
        this.updateToHijriDate(this.toDate);
      }
    },
    // selectStatus(status) {
    //   if (this.rooms == undefined || this.rooms.length == 0) {
    //     if (status.status != 0) {
    //       Swal.fire({
    //         icon: "warning",
    //         text: this.$t("please_add_rooms"),
    //       });
    //       this.statusValue = {
    //         status: 0,
    //         title: this.$t("pending"),
    //       };
    //     }
    //   }
    // },
    async confirmBooking() {
      try {
        this.loader = true;

        if (this.supplierValue && this.clientsTypeValue) {
          if (this.statusValue.status == 1 && this.client_option_date == "") {
            Swal.fire({
              icon: "warning",
              text: this.$t("please_enter_time"),
            });
            this.loader = false;
          } else if (
            this.statusValue.status == 3 &&
            this.rooms.some((room) => room.book_status !== "CANCELED")
          ) {
            Swal.fire({
              icon: "warning",
              text: this.$t("please_cancel_all_rooms"),
            });
            this.loader = false;
          } else {
            if (this.$route.params.id == undefined) {
              const formData = new FormData();

              if (this.supplier_option_date) {
                formData.append(
                  "supplier_option_date",
                  moment(this.supplier_option_date).format("YYYY-MM-DD"),
                );
              }

              if (
                this.supplier_option_time != "" &&
                this.statusValue.status == 1
              ) {
                formData.append(
                  "supplier_option_time",
                  this.supplier_option_time,
                );
              }

              if (
                this.client_option_date != "" &&
                this.statusValue.status == 1
              ) {
                formData.append(
                  "client_option_date",
                  moment(this.client_option_date).format("YYYY-MM-DD"),
                );
              }

              // Check if client_option_time is already formatted
              if (
                this.statusValue.status == 1 &&
                this.client_option_time != ""
              ) {
                // If not formatted, format it
                formData.append(
                  "client_option_time",
                  moment(this.client_option_time, "h:mm:ss").format("HH:mm:ss"),
                );
              }
              formData.append(
                "nationality_id",
                this.nationalityValue ? this.nationalityValue.id : "",
              );
              if (this.termsAndCondition) {
                formData.append(
                  "terms_and_condition_id",
                  this.termsAndCondition ? this.termsAndCondition.id : "",
                );
              }
              formData.append("guest_name", this.guest_name);
              formData.append(
                "client_type_id",
                this.clientsTypeValue ? this.clientsTypeValue.id : "",
              );
              if (this.employeeValue) {
                formData.append(
                  "employee_id",
                  this.employeeValue ? this.employeeValue.id : "",
                );
              }
              if (this.percentage != undefined) {
                formData.append("discount_value", this.percentage);
              }
              if (this.discountValue) {
                formData.append(
                  "discount_type_id",
                  this.discountValue ? this.discountValue.id : "",
                );
              }
              formData.append(
                "client_id",
                this.supplierValue ? this.supplierValue.id : "",
              );
              // formData.append(
              //   "check_in_date",
              //   moment(this.fromDate).format("YYYY-MM-DD"),
              // );
              // formData.append(
              //   "check_out_date",
              //   moment(this.toDate).format("YYYY-MM-DD"),
              // );
              // formData.append("day_number", this.day_number);
              // this.hotelsArray.forEach((hotel, index) => {
              //   formData.append(`hotels[${index}][hotel_id]`, hotel.hotel.id);
              //   formData.append(
              //     `hotels[${index}][number_of_rooms]`,
              //     hotel.number_of_rooms,
              //   );
              // });

              formData.append("book_notes", this.book_notes);
              if (this.bank_account) {
                formData.append("account_id", this.bank_account.id);
              }
              // formData.append("book_status", 0);
              // formData.append("payment_status", 0);
              formData.append("check_in_islamic_date", this.checkInHijriDate);
              formData.append("check_out_islamic_date", this.checkOutHijriDate);
              formData.append("status", this.statusValue.status);
              formData.append("payment_method", this.cashOnDelivery ? 0 : 1);
              if (this.percentageValue.id != undefined) {
                formData.append("discount_value_type", this.percentageValue.id);
              }

              //add
              axios
                .post("/book_offline_service", formData)
                .then(({ data }) => {
                  // console.log(data);
                  Swal.fire({
                    title: "",
                    text: data.message,
                    icon: "success",
                  });
                  this.loader = false;
                  this.$router.push(`/book-offline-service/${data.data.id}`);
                })
                .catch((error) => {
                  this.loader = false;

                  Swal.fire({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                  });
                });
            } else {
              const params = new URLSearchParams();
              params.append("offline_booking_hotel_id", this.$route.params.id);
              if (this.supplier_option_date != undefined) {
                params.append(
                  "supplier_option_date",
                  moment(this.supplier_option_date).format("YYYY-MM-DD"),
                );
              }

              if (
                this.supplier_option_time != "" &&
                this.statusValue.status == 1
              ) {
                params.append(
                  "supplier_option_time",
                  this.supplier_option_time,
                );
              }

              if (
                this.client_option_date != "" &&
                this.statusValue.status == 1
              ) {
                params.append(
                  "client_option_date",
                  moment(this.client_option_date).format("YYYY-MM-DD"),
                );
              }

              // Check if client_option_time is already formatted
              if (
                this.statusValue.status == 1 &&
                this.client_option_time != ""
              ) {
                // If not formatted, format it
                params.append(
                  "client_option_time",
                  moment(this.client_option_time, "h:mm:ss").format("HH:mm:ss"),
                );
              }
              params.append(
                "nationality_id",
                this.nationalityValue ? this.nationalityValue.id : "",
              );
              if (this.termsAndCondition) {
                params.append(
                  "terms_and_condition_id",
                  this.termsAndCondition ? this.termsAndCondition.id : "",
                );
              }
              params.append("guest_name", this.guest_name);
              params.append(
                "client_type_id",
                this.clientsTypeValue ? this.clientsTypeValue.id : "",
              );
              if (this.employeeValue) {
                params.append(
                  "employee_id",
                  this.employeeValue ? this.employeeValue.id : "",
                );
              }
              if (this.percentage != undefined) {
                params.append("discount_value", this.percentage);
              }
              if (this.discountValue) {
                params.append(
                  "discount_type_id",
                  this.discountValue ? this.discountValue.id : "",
                );
              }
              params.append(
                "client_id",
                this.supplierValue ? this.supplierValue.id : "",
              );
              // params.append(
              //   "check_in_date",
              //   moment(this.fromDate).format("YYYY-MM-DD"),
              // );
              // params.append(
              //   "check_out_date",
              //   moment(this.toDate).format("YYYY-MM-DD"),
              // );
              // params.append("day_number", this.day_number);
              // this.hotelsArray.forEach((hotel, index) => {
              //   params.append(`hotels[${index}][hotel_id]`, hotel.hotel.id);
              //   params.append(
              //     `hotels[${index}][number_of_rooms]`,
              //     hotel.number_of_rooms,
              //   );
              // });

              params.append("book_notes", this.book_notes);
              if (this.bank_account) {
                params.append("account_id", this.bank_account.id);
              }
              // params.append("book_status", 0);
              // params.append("payment_status", 0);
              params.append("check_in_islamic_date", this.checkInHijriDate);
              params.append("check_out_islamic_date", this.checkOutHijriDate);
              params.append("status", this.statusValue.status);
              params.append("payment_method", this.cashOnDelivery ? 0 : 1);
              if (this.percentageValue.id != undefined) {
                params.append("discount_value_type", this.percentageValue.id);
              }

              axios.put("/update_offline_service", params).then(({ data }) => {
                this.$emit("roomAdded");

                // console.log(data);
                Swal.fire({
                  title: "",
                  text: data.message,
                  icon: "success",
                });
                this.loader = false;
              });
            }
          }
        } else {
          // console.log(result);
          Swal.fire({
            title: "",
            text: this.$t("please_fill_required_fields"),
            icon: "warning",
          });
          this.loader = false;
        }
      } catch (error) {
        console.error("Error during form submission:", error);
      }
    },
    searchNationality(event) {
      const searchText = {
        word: event == undefined ? "" : event.target.value,
      };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => {
          // console.log(res.data.data.data, "searchNationality");
          this.nationalities = res.data.data.data;
        })
        .catch((err) => console.log(err));
    },
    FetchAccounts() {
      axios
        .post(`/fetch_my_organization_accounts`, {
          type: 1,
        })
        .then(({ data }) => {
          // ?page=" + page
          this.bank_accounts = data.data.data;
          // console.log(this.bank_accounts);
        });
    },
    // updateFromHijriDate(Date) {
    //   const hijriFormatter = new Intl.DateTimeFormat(
    //     "ar-SA-u-ca-islamic-umalqura",
    //     {
    //       month: "long",
    //       day: "numeric",
    //     },
    //   );
    // },
    // updateToHijriDate(Date) {
    //   const hijriFormatter = new Intl.DateTimeFormat(
    //     "ar-SA-u-ca-islamic-umalqura",
    //     {
    //       month: "long",
    //       day: "numeric",
    //     },
    //   );
    //   this.checkOutHijriDate = hijriFormatter.format(Date).replace(/\s*هـ$/);
    // },
    changePsotedStatus() {
      axios
        .post("/changeOfflineBookingHotelLockStatus", {
          offline_booking_hotel_id: this.$route.params.id,
        })
        .then((data) => {
          this.$emit("roomAdded");
          Swal.fire({
            icon: "success",
            text: data.data.message,
            timer: 2000,
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
            timer: 2000,
          });
        });
    },
    upDateStatus() {
      this.disableStatus = false;
      this.statusValue = {
        status: 1,
        title: this.$t("Tentitive"),
      };
    },
    formatNationalityLabel(nationality) {
      return `${nationality.title ? nationality.title : nationality.name} - ${nationality.code} (${nationality.id})`;
    },
    convertTime(timeString) {
      const [hours, minutes] = timeString.split(":");
      let formattedHours = parseInt(hours, 10);
      const suffix = formattedHours >= 12 ? "pm" : "am";
      formattedHours = formattedHours % 12 || 12; // Convert 0 to 12
      return `${formattedHours}:${minutes} ${suffix}`;
    },
  },

  mounted() {
    this.searchHotel();
    this.fetchEmployees();
    setTimeout(() => {
      this.fetchClientType();
    }, 1000);
    this.fetchDiscountType();
    this.searchNationality();
    this.termsAndConditions();
    this.FetchAccounts();
  },
  watch: {
    clientData(newValue) {
      if (newValue) {
        console.log(newValue.lock_status, "prePosted");
        this.bookId = newValue.id || "";
        this.posted = newValue.lock_status;
        console.log(this.posted, "posted");
        this.book_notes = newValue.book_notes || "";
        this.clientsTypeValue = newValue.client_type || "";
        this.attachmentsCount = newValue.attachment_count || 0;
        this.employeeValue = newValue.employee || "";
        this.supplierValue = newValue.client || "";
        this.guest_name = newValue.guest_name || "";
        this.total_cost_price_with_out_tax =
          newValue.total_cost_price_with_out_tax?.toFixed(2) || 0;
        this.clientEmail = newValue.client?.email || "";
        this.day_number = newValue.day_number || 0;
        this.hotelsArray = [];

        newValue.hotels?.forEach((hotelObj) => {
          const exists = this.hotelsArray.some(
            (item) => item.hotel.id === hotelObj.hotel_id,
          );

          if (!exists) {
            this.hotelsArray.push({
              hotel: {
                id: hotelObj.hotel_id,
                hotel_name: hotelObj.hotel_name,
              },
              number_of_rooms: hotelObj.number_of_rooms,
            });
          }
        });

        if (this.$route.params.id != undefined) {
          this.fromDate = newValue.check_in_date;
          this.toDate = newValue.check_out_date;
          console.log(newValue.check_out_date);
          this.client_option_date = newValue.client_option_date || null;
        } else {
          this.fromDate = null;
          this.toDate = null;
          this.updateFromHijriDate();
          this.updateToHijriDate();
          this.client_option_date = null;
          this.client_option_time = null;
          this.hotelsArray = [
            {
              hotel: "",
              number_of_rooms: 1,
            },
          ];
        }

        this.clientPayment = newValue.client_payment_status || "";
        this.discountValue = newValue.discount_type || "";
        this.percentageValue =
          newValue.discount_value_type == 1
            ? { id: 1, title: this.$t("percentages") }
            : { id: 2, title: this.$t("value") };

        this.client_option_time = newValue.client_option_time;

        this.percentage = newValue.discount_value || 0;
        this.total_without_tax = newValue.price.total_without_tax || 0;
        this.total_with_tax = newValue.price.total_with_tax || 0;
        this.total_room_cost_with_out_tax =
          newValue.price.total_room_cost_with_out_tax?.toFixed(2) || 0;
        this.total_room_cost_with_tax =
          newValue.price.total_room_cost_with_tax || 0;
        this.total_service_cost_with_out_tax =
          newValue.price.total_cost_price_with_out_tax?.toFixed(2) || 0;
        this.total_service_with_tax =
          newValue.price.total_service_with_tax || 0;
        this.total_service_with_cost =
          newValue.price.total_service_with_cost || 0;
        this.total_room_selling_with_out_tax =
          newValue.price.total_room_selling_with_out_tax?.toFixed(2) || 0;
        this.total_service_selling_with_out_tax =
          newValue.price.total_service_selling_with_out_tax?.toFixed(2) || 0;
        this.total_cost_price =
          newValue.price.total_selling_price_with_out_tax?.toFixed(2) || 0;
        this.total_cost_price_with_tax =
          newValue.price.total_selling_price || 0;
        this.tax = newValue.price.selling_tax?.toFixed(2) || 0;
        this.reservation_status = newValue.book_status || "";
        this.reservation_number = newValue.book_number || "";
        this.$store.state.reservation_number = newValue.book_number || "";
        this.number_of_rooms = newValue.number_of_available_rooms || 0;
        this.termsAndCondition = newValue.terms_and_conditions || "";
        this.bank_account = newValue.account || "";
        this.nationalityValue = newValue.Nationality || "";
        this.supplier_option_date =
          newValue.supplier_option_data_for_room || "";
        this.supplier_option_time =
          newValue.supplier_option_time_for_room || "";
        this.remaining_amount = newValue.remaining_amount;
        this.status = newValue.status || 0;
        this.statusValue = this.getStatusValue(newValue.status);
        this.paymentStatus = newValue.client_payment_status || "";
        this.cashOnDelivery = newValue.payment_method == "Cash_on_delivery";
        this.loading = false;
      }
    },
    fetchData(newValue) {
      if (newValue) {
        this.fetchOfflineBookingData();
      }
      this.$emit("fetchData", false);
    },

    fromDate() {
      if (this.fromDate instanceof Date && this.fromDate !== null) {
        const nights = parseInt(this.day_number, 10) || 1;
        const toDate = new Date(
          this.fromDate.getTime() + nights * 24 * 60 * 60 * 1000,
        );
        this.toDate = toDate;
      } else if (this.fromDate == null) {
        this.toDate = null;
        console.error("Invalid fromDate value:", this.fromDate);
      }
    },
  },
  computed: {
    disabledDates() {
      if (this.$route.params.id != undefined) {
        const fromDate = new Date();
        const toDate = new Date(this.fromDate);
        fromDate.setDate(fromDate.getDate() - 1);
        toDate.setDate(toDate.getDate() + 1);
        return {
          to: fromDate,
          from: toDate,
        };
      } else {
        const fromDate = new Date();
        const toDate = new Date(this.fromDate);
        fromDate.setDate(fromDate.getDate() - 1);
        toDate.setDate(toDate.getDate());
        return {
          to: fromDate,
          from: toDate,
        };
      }
    },
  },
  created() {
    console.log(this.is_portal, "is_portal");
  },
};
</script>

<style scoped lang="scss">
@import "@/view/dashboard/hotelsBookOffline/_hotelsBookOffline";
@import "@/components/offline_booking/availableRooms";
</style>
