<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("Credintial") }}</h4>
      <router-link
        :to="{ name: 'AddProviderCredintil' }"
        class="PrimaryButton btn w-25"
        ><i class="fa-solid fa-plus"></i>
        {{ $t("add") }} Credintial</router-link
      >
    </div>

    <div class="row">
      <table class="table" id="exchangeTable">
        <thead>
          <tr>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("created_by") }}</th>
            <th>{{ $t("the_company") }}</th>
            <th>{{ $t("status") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in credentials.data" :key="index">
            <td>{{ item.id }} {{ item.name }}</td>
            <td class="service_lists">
              <span class="service_list"> {{ item.employee.name }}</span>
            </td>
            <td v-if="item.organization.name == null">travel Soft tech</td>
            <td v-else>{{ item.organization.name }}</td>
            <td>
              <span v-if="item.active == 1" class="badge bg-success">
                {{ $t("active") }}</span
              >
              <span v-else-if="item.active == 0" class="badge bg-danger">
                {{ $t("diactive") }}</span
              >
            </td>
            <td>
              <button
                v-if="item.active === 0"
                class="btn btn-warning mx-1"
                @click="dealCredintial(item.id)"
              >
                <i class="fa-solid fa-circle-info"></i>
                Credintial {{ $t("active_cre") }}
              </button>

              <button
                v-else-if="item.active === 1"
                class="btn btn-info mx-1"
                @click="dealCredintial(item.id)"
              >
                <i class="fa-solid fa-circle-info"></i>
                {{ $t("cancel_credintal") }}
              </button>

              <button
                class="btn btn-danger text-white mx-1 remove_btn"
                @click="DeleteAlert(index, item.id)"
              >
                <i class="fa-solid fa-trash" :title="$t('delete')"></i>
                {{ $t("delete_provider") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="credentials"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="Fetchcredentials"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import Swal from "sweetalert2";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import $ from "jquery";

export default {
  name: "show-credintial",
  components: {
    pagination,
  },
  data() {
    return {
      credentials: [],
    };
  },
  methods: {
    //  active && disactive credintials
    async dealCredintial(id) {
      const formData = new FormData();

      formData.append("credential_id", id);

      try {
        const response = await axios.post(
          "/change_provider_credential_status",
          formData
        );
        Swal.fire({
          title: "",
          text: response.data.message,
          icon: "success",
        });
        this.Fetchcredentials(); // Refresh the current page
      } catch (error) {
        Swal.fire({
          title: "",
          text: error.response.data.message,
          icon: "error",
        });
      }
    },

    async Fetchcredentials(page = 1) {
      let provider_id = { provider_id: this.$route.params.id };
      await axios
        .post("/fetch_provider_credentials?page=" + page, provider_id)
        .then(({ data }) => {
          this.credentials = data.data;
          console.log(this.credentials);
        });
    },
    // fetch credintial data

    //delete provider
    DeleteAlert(index, credential_id) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const formData = new URLSearchParams();
          formData.append("credential_id", credential_id);
          // console.log(formData)
          axios
            .delete("/organization-providers/delete", { data: formData })
            .then(({ data }) => {
              // console.log(result);
              // console.log(delete_id);
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.credentials.data.splice(index, 1); // Remove the deleted item from the array
                // console.log(data);
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },

  created() {
    this.Fetchcredentials();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },

  updated() {
    // axios.get("API_Goes_Here").then((response) => {
    //   this.products = response.data;
    // });
    $("#exchangeTable").DataTable({
      bPaginate: false,
      retrieve: true,
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("Credintial"),
        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("from") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("Credintial"),
      },
    });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./provider.scss";
</style>
