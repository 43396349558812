<template>
  <div
    class="col-12 mb-2"
    v-if="hotel.prices.length > 0 || priceFetched == false"
  >
    <div class="hotel-card">
      <div class="card-image">
        <img
          :src="hotel.image ? hotel.image : defaultImageUrl"
          :class="{ 'error-image': isError }"
        />
      </div>
      <div class="card-text w-100">
        <div class="title w-100 justify-content-between">
          <div class="details_hotel_data justify-content-between">
            <h3 class="card-title">{{ hotel.title }}</h3>
            <rate :rates="hotel?.starCount || 0" />
          </div>
          <div class="price">
            <div class="loader_price" v-if="show_price && !priceFetched">
              <span class="loader"></span>
              <span class="best_price_text">{{
                $t("loading_best_price")
              }}</span>
            </div>
            <!-- v-if="show_price" -->
            <span v-if="show_price && priceFetched"
              >{{
                hotel.prices.length > 0
                  ? convertedPrice(hotel.prices[0].min_price)
                  : "--"
              }}
              {{ coinCode }} /
              {{ $t("for night") }}
            </span>
          </div>
        </div>
        <div class="option_rooms">
          <ul class="details">
            <li>
              <i class="fa-solid fa-location-dot"></i>{{ hotel.address }}
              <a
                class="show_map"
                :href="`https://www.google.com/maps?q=${lat},${long}&z=${zoom}`"
                target="_blank"
                >{{ $t("show_map") }}</a
              >
            </li>
            <li>
              <i class="fa-solid fa-shield"></i> {{ $t("Free cancellation") }}
            </li>
          </ul>
          <!-- {{ hotel.prices }} -->
          <button
            v-if="show_price && priceFetched"
            class="btn PrimaryButton"
            type="button"
            @click="showTabsAndHide"
          >
            {{ $t("avabile_rooms") }}
            <i class="fa-solid fa-chevron-bottom"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-for="bookRoom in bookedRooms" :key="bookRoom.id">
      <div
        class="booked-rooms d-flex align-items-center justify-content-between mb-3"
      >
        <div class="info d-flex flex-column">
          <h6 class="title fw-bold">{{ bookRoom.name }}</h6>
          <!-- <span class="fw-light fs-6">{{
            `${bookRoom.max_adults} - ${$t("Number Of Adults")}`
          }}</span>
          <span class="fw-light fs-6">{{
            `${bookRoom.max_children} - ${$t("Number Of Children")}`
          }}</span> -->
        </div>
        <div class="price text-wrap fs-6 fw-light">
          <span class="fw-bold fs-2">{{
            convertedPrice(bookRoom.room_rate_basis[0].selling_price_after_tax)
          }}</span
          >{{ coinCode }} / {{ $t("RATE") }}
        </div>
        <div class="book">
          <div class="d-flex">
            <div class="add_room" @click="addRoom(bookRoom)">
              <i class="fa-solid fa-plus"></i>
            </div>
            <div class="count w-100">
              {{ bookRoom.room_rate_basis[0].room_count }}
            </div>
            <div class="remove_room" @click="minusRoom(bookRoom)">
              <i class="fa-solid fa-minus"></i>
            </div>
          </div>
          <button
            class="btn PrimaryButton"
            type="button"
            @click="bookRoomNow(bookRoom, hotel.prices[0].rate_code_id)"
          >
            {{ $t("Book Now") }}/{{convertedPrice(bookRoom.room_rate_basis[0].is_contract == 1 ? bookRoom.room_rate_basis[0].total_selling_price : Math.floor(bookRoom.room_rate_basis[0].room_count * bookRoom.room_rate_basis[0].selling_price_after_tax))}}{{ coinCode }}
          </button>
        </div>
      </div>
    </div>
    <TabView v-if="showTabs">
      <TabPanel
        v-for="price in hotel.prices"
        :key="price.id"
        :header="`${price.min_price} ${coinCode} / ${$t('Start With')}`"
        :selectOnFocus="fetchAvilableRooms(price)"
      >
        <div v-if="avabileRooms.length">
          <div
            class="available-rooms d-flex align-items-center justify-content-between mb-2"
            v-for="room in avabileRooms"
            :key="room.id"
          >
            <div class="info d-flex flex-column">
              <h6 class="title fw-bold">{{ room.name }}</h6>
              <!-- <span class="fw-light fs-6">{{
                `${room.max_adults} - ${$t("Number Of Adults")}`
              }}</span>
              <span class="fw-light fs-6">{{
                `${room.max_children} - ${$t("Number Of Children")}`
              }}</span> -->
            </div>
            <div class="d-flex align-items-center gap-2">
              <div class="price text-wrap fs-6 fw-light">
                <span class="fw-bold fs-5">{{
                  convertedPrice(room.room_rate_basis[0].selling_price_after_tax)
                }}</span
                >{{ coinCode }} / {{ $t("Price") }}
              </div>
              <div class="book">
                <button
                  class="btn PrimaryButton"
                  type="button"
                  @click="bookRoom(room)"
                >
                  {{ $t("Add Now") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!avabileRooms.length">
          <Skeleton class="mb-2"></Skeleton>
          <Skeleton width="10rem" class="mb-2"></Skeleton>
          <Skeleton width="5rem" class="mb-2"></Skeleton>
        </div>
        <div v-if="avabileRooms == []">
          <p>{{ $t("No Rooms Found") }}</p>
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import axios from "axios";
import rate from "@/components/rate/rate.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import moment from "moment";

import Skeleton from "primevue/skeleton";

// import axios from "axios";
export default {
  name: "HotelCard",
  props: {
    hotel: Object,
    lat: Number,
    long: Number,
    session_id: String,
    show_price: Boolean,
    show_loader: Boolean,
    priceFetched: Boolean,
    guest_nationality: Object,
    childrens: Number,
    adults: Number,
    range: Object,
  },
  components: { rate, TabView, TabPanel, Skeleton },
  data() {
    return {
      zoom: 15,
      isError: false,
      defaultImageUrl: require("@/assets/media/image/logo.png"),
      coinCode:
        JSON.parse(localStorage.getItem("user")).currency == null
          ? "USD"
          : JSON.parse(localStorage.getItem("user")).currency?.code,
      avabileRooms: [],
      bookedRooms: [],
      showTabs: false,
      currency_title: JSON.parse(localStorage.getItem("user")).currency == null ? "USD" : JSON.parse(localStorage.getItem("user")).currency?.title,
      currency_price: JSON.parse(localStorage.getItem("user")).currency == null ? 1 : JSON.parse(localStorage.getItem("user")).currency?.price,
    };
  },
  methods: {
    bookRoomNow(room, rate_code_id) {
      // console.log(room);
      // console.log(this.hotel);
      localStorage.setItem("hotel_Details", JSON.stringify(this.hotel));
      let roomData = {
        check_in: moment(this.range[0]).format("YYYY-MM-DD"),
        check_out: moment(this.range[1]).format("YYYY-MM-DD"),
        hotel_id: this.hotel.id,
        hotel_name: this.hotel.title,
        nationality_code: this.guest_nationality.code,
        passenger_country_of_residence_code: this.guest_nationality.code,
        rooms: [],
        meta_data: {
          credential_id: 2,
          provider_id: 2,
          is_contract: room.is_contract,
          rate_code_id: room.is_contract == 1 ? rate_code_id : null,
        },
      };
      // console.log(room)

      // Iterate over the rooms_guests array
      // Create room object
      const roomObjectData = {
        room_type_name: room.meta_data.room_type_name,
        room_number: room.id,
        rate_basis: room.room_rate_basis[0].id,
        description: room.room_rate_basis[0].description,
        allocation_details: room.room_rate_basis[0].allocationDetails,
        meta_data: {
          room_index: null,
          room_type_code: null,
          room_type_name: room.meta_data.room_type_name,
          rate_plan_code: null,
          supplements: [],
          room_rate: {
            room_fare: null,
            total_fare: null,
            room_tax: null,
            currency: null,
            agent_mark_up: null,
          },
        },
        room_rate_basis: room.room_rate_basis[0],
        room_guests: {
          adults: [],
          children: [],
          extrabeds: [],
        },
      };

      // Add adult guests to the room
      for (let adultIndex = 0; adultIndex < this.adults; adultIndex++) {
        roomObjectData.room_guests.adults.push({
          salutation: "",
          first_name: "",
          last_name: "",
          age: 30, // You can set the age to the desired value
        });
      }

      // Add children guests to the room
      for (let childIndex = 0; childIndex < this.childern; childIndex++) {
        roomObjectData.room_guests.children.push({
          salutation: "",
          first_name: "",
          last_name: "",
          age: room.max_extrabeds, // Set the age to the desired value
        });
      }

      // Add the room object to the rooms array in roomData
      roomData.rooms.push(roomObjectData);
      // console.log(roomData);

      localStorage.setItem("roomData", JSON.stringify(roomData));
      this.$router.push("/new_book");
    },
    addRoom(room) {
      room.room_rate_basis[0].room_count++;
    },
    minusRoom(room) {
      if (room.room_rate_basis[0].room_count > 1) {
        room.room_rate_basis[0].room_count--;
      } else {
        // If room_count is already 0, remove the room from bookedRooms
        const indexToRemove = this.bookedRooms.findIndex(
          (item) => item.id === room.id
        );
        if (indexToRemove !== -1) {
          this.bookedRooms.splice(indexToRemove, 1);
        }
      }
    },
    bookRoom(room) {
      let exists = false;

      for (let i = 0; i < this.bookedRooms.length; i++) {
        if (this.bookedRooms[i].id === room.id) {
          this.bookedRooms[i].room_rate_basis[0].room_count += 1;
          exists = true;
          break;
        }
      }

      if (!exists) {
        this.bookedRooms.push(room);
      }
    },
    showTabsAndHide() {
      this.showTabs = !this.showTabs;
    },
    convertedPrice(price) {
      // console.log(this.currency)
      return (price * this.currency_price).toFixed(2);
    },
    handleImageError(event) {
      // When the image fails to load, replace it with the default image
      event.target.src = this.defaultImage;
    },
    // fetchAvilableRooms
    fetchAvilableRooms(prices) {
      if (prices.is_contract == 0) {
        let avabile_rooms = {
          check_in: moment(this.range[0]).format("YYYY-MM-DD"),
          check_out: moment(this.range[1]).format("YYYY-MM-DD"),
          converter_status: 1,
          hotel_id: prices.id,
          meta_data: {
            credential_id: prices.credential_id,
            provider_id: prices.provider_id,
            is_contract: prices.is_contract,
          },
          nationality_code: this.guest_nationality.code,
          passenger_country_of_residence_code: this.guest_nationality.code,
          rooms_guests: [
            {
              rate_basis: "1",
              adults_count: this.adults,
              children_count: this.childrens,
              children_ages: [],
            },
          ],
        };
        axios
          .post("/fetch_new_available_rooms", avabile_rooms)
          .then(({ data }) => {
            this.avabileRooms = data.data.data;
            // console.log(this.avabileRooms);
          });
      } else if (prices.is_contract == 1) {
        let avabile_rooms = {
          check_in: moment(this.range[0]).format("YYYY-MM-DD"),
          check_out: moment(this.range[1]).format("YYYY-MM-DD"),
          converter_status: 1,
          hotel_id: prices.id,
          meta_data: {
            rate_code_id: prices.rate_code_id,
            is_contract: prices.is_contract,
            provider_id: 2,
            credential_id: 2
          },
          nationality_code: this.guest_nationality.code,
          passenger_country_of_residence_code: this.guest_nationality.code,
          rooms_guests: [
            {
              rate_basis: "1",
              adults_count: this.adults,
              children_count: this.childrens,
              children_ages: [],
            },
          ],
        };
        axios
          .post("/fetch_new_available_rooms", avabile_rooms)
          .then(({ data }) => {
            this.avabileRooms = data.data;
            // console.log(this.avabileRooms, "avabileRooms");
          });
        // console.log(avabile_rooms, "avabile_rooms");
      }
      // console.log(this.hotel, "this.hotel");
      // console.log(this.guest_nationality, "this.nationalityValue");
    },

    setDefaultImage(event) {
      event.target.src = localStorage.getItem("DefaultImage");
      this.isError = true; // Set isError to true when an error occurs
    },
  },
  // mounted(){
  //   console.log(this.hotel.min_price)
  // }
};
</script>

<style lang="scss" scoped>
@import "../../view/dashboard/hotels/_hotels.scss";
.remove_room,
.add_room {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 5px;
}
.add_room {
  border-radius: 0 5px 5px 0;
}
.remove_room {
  border-radius: 5px 0 0 5px;
}
.count {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
}
.p-tabview.p-component {
  margin-top: -8px;
}
.hotel-card {
  border-radius: 10px 10px 0 0;
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  // margin-bottom: 10px;
  // margin-top: 0.25rem;
  .Rate__star {
    padding: 0;
    opacity: 1;
  }
  .Rate__star.filled[disabled],
  .Rate__star.hover[disabled] {
    color: #ffc635 !important;
    opacity: 1;
  }
  @media (max-width: 768px) {
    align-items: center;
  }
  .card-image {
    width: 212px;
    height: 100px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    background-color: #dee2e696;
    @media (max-width: 768px) {
      width: 130px;
      height: 130px;
    }
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      // background-color: #000;
      border-radius: 10px;
    }
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgb(0 0 0 / 31%);
      padding: 20px;
    }
    .love {
      width: 50.21px;
      height: 49.9px;
      background-color: rgb(255 255 255 / 31%);
      border-radius: 50%;
      text-align: center;
      line-height: 49px;
      font-size: 20px;
    }
  }
  .card-text {
    width: calc(100% - 260px);
    // margin-inline-start: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 20px;
    padding-left: 20px;
    @media (max-width: 768px) {
      width: calc(100% - 150px);
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .details_hotel_data {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 75%;
      }
    }
    .option_rooms {
      display: flex;
      justify-content: space-between;
      .details {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0;
        width: 70%;
        margin: 0 !important;
        li {
          width: 95%;
          margin-top: 10px;
          margin-bottom: 0 !important;
        }
      }
    }
    .rate_value {
      width: 65px;
      height: 30px;
      border-radius: 18px;
      background-color: #ffc6353b;
      font-size: 12px;
      font-family: "bold";
      text-align: center;
      line-height: 30px;
      margin-inline-end: 10px;
      i {
        color: #ffc635;
      }
    }
    .rate-number {
      color: #656b78;
    }
    .card-title {
      font-size: 1.2rem;
      width: 70%;
      font-family: "bold";
      color: #4b546a;
      margin-top: 10px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    .details {
      li {
        font-size: 12px;
        font-weight: 500;
        font-family: "bold";
        color: #656b78;
        margin-bottom: 10px;
        a {
          color: $PrimaryColor;
          text-decoration: underline;
        }
        i {
          margin-inline-end: 0.5rem;
        }
        &:nth-child(2) {
          font-family: "regular";
        }
      }
    }
    .price_div {
      flex-wrap: wrap;
    }
    .show_details {
      background-color: #4b546a;
      color: #fff;
      border-radius: 49px;
      padding: 4px 20px;
      font-size: 12px;

      @media (max-width: 768px) {
        padding: 5px 10px;
        margin-top: 20px;
      }
    }
    .price span {
      font-size: 14px;
      font-family: "bold";
      color: #4b546a;
      @media (max-width: 768px) {
        font-size: 10px;
      }
    }
  }
}
.Rate {
  pointer-events: none;
}
.error-image {
  object-fit: scale-down;
}
ul {
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .hotel-card {
    flex-direction: column;
    padding: 0;
    padding-bottom: 10px;
    .card-image {
      width: 100%;
      height: 220px;
      img {
        object-fit: cover;
      }
    }
    .card-text {
      width: 100%;
      margin-inline-start: 0;
      padding: 0 0.5rem;
      .title {
        .details_hotel_data {
          flex-direction: column !important;
          align-items: flex-start !important;
        }
      }
      .option_rooms {
        flex-direction: column;
      }
    }
  }
}

.loader_price {
  display: flex;
  align-items: center;
}
.best_price_text {
  font-size: 0.8rem;
  margin-inline-start: 0.5rem;
}
.loader {
  display: block !important;
  width: 25px !important;
  height: 25px !important;
  border: 5px solid $PrimaryColor;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.available-rooms,
.booked-rooms {
  padding: 0.5rem;
  border-radius: 10px;
  border: 1px solid #ccc;
  .info {
    .title {
      font-size: 0.8rem;
      margin-bottom: 0;
    }
  }
  .price {
    font-size: 0.8rem !important;
    span {
      font-size: 0.8rem !important;
    }
  }
  .book {
    display: flex;
    align-items: center;
    .d-flex {
      .add_room, .count, .remove_room {
        font-size: 0.7rem;
        width: 30px;
      }
    }
    .PrimaryButton {
      font-size: 0.7rem;
      margin-inline-start: 0.5rem !important;
    }
  }
}
.booked-rooms {
  background-color: rgb(255 255 255 / 31%);
}
</style>
