<template>
  <section class="card_style">
    <div class="main_header_style flex-column">
      <!-- <h4>{{ $t("add_supplirs") }}</h4> -->
    </div>
    <form class="form_topic w-100" @submit.prevent="addsupplier()">
      <div class="row">
        <div class="col-lg-6 mb-4">
          <div class="form-group">
            <label class="form-label">{{ $t("first name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('write first name')"
              v-model="traveler.first_name"
            />
            <span
              v-for="error in v$.traveler.first_name.$errors"
              class="text-danger"
              :key="error.$uid"
              >{{ $t(error.$message) }}</span
            >
          </div>
        </div>
        <div class="col-lg-6 mb-4">
          <div class="form-group">
            <label class="form-label">{{ $t("last name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('write last name')"
              v-model="traveler.last_name"
            />
            <span
              v-for="error in v$.traveler.last_name.$errors"
              class="text-danger"
              :key="error.$uid"
              >{{ $t(error.$message) }}</span
            >
          </div>
        </div>
        <div class="col-lg-6 mb-4">
          <div class="form-group">
            <label class="form-label" for="adult">{{
              $t("passenger_type")
            }}</label>
            <Multiselect
              v-model="traveler.type"
              :options="passengerType.map((passenger) => passenger.id)"
              :custom-label="
                (opt) => passengerType.find((x) => x.id == opt).title
              "
              :clear-on-select="true"
              :placeholder="$t('passenger_type')"
              select-label=""
              :hide-selected="true"
              :disabled="true"
            >
            </Multiselect>
          </div>
        </div>

        <div class="col-lg-6 mb-4">
          <div class="form-group">
            <label class="form-label" for="adult">{{ $t("nickname") }}</label>
            <Multiselect
              v-model="traveler.nickname"
              :options="
                traveler.type == 1
                  ? gendersADT.map((gender) => gender.code)
                  : gendersCHD.map((gender) => gender.code)
              "
              :custom-label="
                traveler.type == 1
                  ? (opt) => gendersADT.find((x) => x.code == opt).title
                  : (opt) => gendersCHD.find((x) => x.code == opt).title
              "
              :clear-on-select="true"
              :placeholder="$t('nickname')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span
              v-for="error in v$.traveler.nickname.$errors"
              class="text-danger"
              :key="error.$uid"
              >{{ $t(error.$message) }}</span
            >
          </div>
        </div>
        <div class="col-lg-6 mb-4">
          <div class="form-group">
            <label class="form-label" for="adult">{{ $t("phone") }}</label>
            <vue-tel-input
              v-model="traveler.phone"
              v-bind="bindProps"
              mode="international"
            ></vue-tel-input>
            <span
              v-for="error in v$.traveler.phone.$errors"
              class="text-danger"
              :key="error.$uid"
              >{{ $t(error.$message) }}</span
            >
          </div>
        </div>
        <div class="col-lg-6 mb-4">
          <div class="form-group">
            <label class="form-label" for="name">{{ $t("email") }}</label>
            <input
              type="email"
              class="form-control"
              :placeholder="$t('email')"
              id="name"
              v-model="traveler.email"
            />
            <span
              v-for="error in v$.traveler.email.$errors"
              class="text-danger"
              :key="error.$uid"
              >{{ $t(error.$message) }}</span
            >
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-label"> {{ $t("birth_day") }}</label>
            <datepicker
              v-model="traveler.birth_day"
              name="birth_day"
              :placeholder="$t('birth_day')"
            ></datepicker>
            <span
              v-for="error in v$.traveler.birth_day.$errors"
              class="text-danger"
              :key="error.$uid"
              >{{ $t(error.$message) }}</span
            >
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-label"> {{ $t("IdentityDocID") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('IdentityDocID')"
              v-model="traveler.IdentityDocID"
              required
            />
          </div>
        </div>

        <div class="col-lg-6 mb-4">
          <div class="form-group">
            <label class="form-label" for="adult">{{
              $t("IdentityDocTypeCode")
            }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('IdentityDocTypeCode')"
              v-model="traveler.IdentityDocTypeCode"
            />
          </div>
        </div>

        <div class="col-lg-6 mb-4">
          <div class="form-group">
            <label class="form-label" for="adult">{{
              $t("ContactInfoRefID")
            }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('ContactInfoRefID')"
              v-model="traveler.ContactInfoRefID"
            />
          </div>
        </div>
        <!-- <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-label"> {{ $t("Expiry date") }}</label>
            <datepicker
              v-model="traveler.Expiry_date"
              name="Expiry_date"
              :placeholder="$t('Expiry date')"
            ></datepicker>
          </div>
        </div> -->
      </div>
    </form>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs3-datepicker";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  name: "clerck-card",
  components: {
    Multiselect,
    Datepicker,
  },
  props: ["ourTraveler"],
  data() {
    return {
      v$: useVuelidate(),

      passengerType: [
        {
          id: 1,
          title: "Adult",
          code: "ADT",
        },
        {
          id: 2,
          title: "Children",
          code: "CHD",
        },
        {
          id: 3,
          title: "Baby",
          code: "INF",
        },
      ],
      gendersADT: [
        {
          id: 1,
          title: "Mr",
          code: "Mr",
        },
        {
          id: 2,
          title: "Ms",
          code: "Ms",
        },
        {
          id: 3,
          title: "Mrs",
          code: "Mrs",
        },
      ],
      gendersCHD: [
        {
          id: 1,
          title: "Mstr",
          code: "Mstr",
        },
        {
          id: 2,
          title: "Miss",
          code: "Miss",
        },
      ],
      nationality: [
        {
          id: 1,
          title: "Egypt",
        },
        {
          id: 2,
          title: "Egypt",
        },
      ],
      countries: [
        {
          id: 1,
          title: "Egypt",
        },
        {
          id: 2,
          title: "Egypt",
        },
      ],
      residencecountries: [
        {
          id: 1,
          title: "Egypt",
        },
        {
          id: 2,
          title: "Egypt",
        },
      ],
      passenferValues: "",
      nicknameValues: "",
      phone: "",
      nationalityValue: "",
      birth_day: "",
      card_id: "",
      countryValue: "",
      residencecountryValue: "",
      expire_day: "",
      traveler: this.ourTraveler,
      bindProps: {
        mode: "international",
        defaultCountry: "EG",
        valid: true,
        inputOptions: {
          maxlength: 25,
          type: "tel",
          placeholder: this.$t("phone"),
        },
        validCharactersOnly: true,
      },
    };
  },
  validations() {
    return {
      traveler: {
        first_name: { required },
        last_name: { required },
        nickname: { required },
        phone: { required },
        email: { required, email },
        birth_day: { required },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "_flight.scss";
</style>
