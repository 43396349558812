<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("Edit Hotel") }}</h4>
    </div>
    <form @submit.prevent="editHotel()">
      <div class="row" >
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The Country") }}</label>
            <Multiselect
              v-model="countryValue"
              track-by="id"
              label="title"
              :options="countries"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
              @update:model-value="FetchCityFromCountry(this.countryValue)"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The City") }}</label>

            <Multiselect
              v-model="cityValues"
              track-by="id"
              label="title"
              :options="cities"
              :clear-on-select="true"
              :placeholder="$t('enter_city')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
      </div>
      <!-- <div class="row" >
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The Country") }}</label>
            <Multiselect
              v-model="regularCountryValue"
              :options="countries.map((country) => country.id)"
              :custom-label="(opt) => countries.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
              @update:model-value="FetchCityFromCountry"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The City") }}</label>
            <Multiselect
              v-model="regularCityValues"
              :options="cities.map((city) => city.id)"
              :custom-label="(opt) => cities.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('enter_city')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="hotelName">{{ $t("Hotel Name") }}</label>
            <input
              type="text"
              class="form-control mt-2"
              id="hotelName"
              :placeholder="$t('Hotel Name')"
              v-model="title"
            />
          </div>
        </div>

        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="starCount">{{ $t("Add starCount") }}</label>
            <input
              type="number"
              class="form-control mt-2"
              id="starCount"
              :placeholder="$t('Add starCount')"
              v-model="starCount"
            />
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="lat">lat</label>
            <input
              type="text"
              class="form-control mt-2"
              id="lat"
              placeholder="lat"
              v-model="lat"
            />
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="long">long</label>
            <input
              type="text"
              class="form-control mt-2"
              id="long"
              placeholder="long"
              v-model="long"
            />
          </div>
        </div>
        <div class="col-md-12 mb-4">
          <div class="form-group">
            <label for="adress">{{ $t("adress") }}</label>
            <input
              type="text"
              class="form-control mt-2"
              id="address"
              :placeholder="$t('adress')"
              v-model="address"
            />
          </div>
        </div>
        <!-- <div class="col-md-6 mb-6">
                <input
                type="file"
                id="file"
                @change="imageUpload"
                multiple
                hidden
                />
                <label for="file" class="add_file"
                ><i class="fa-solid fa-upload"></i> {{ $t("Add image") }}</label
                >
                <p>{{ this.imageHotelName }}</p>
            </div> -->
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("edit") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  name: "editHotel",
  components: {
    Multiselect,
  },
  data() {
    return {
      hotelId: this.$route.params.id,
      title: "",
      starCount: 0,
      lat: 0,
      long: 0,
      address: "",
      countryValue: "",
      regularCountryValue: "", // For the regular country selection
      countries: [],
      cityValues: [],
      regularCityValues: [], // For the regular city selection
      cities: [],
      country_id: "",
      city_id: "",
      useCustomCitySelect: false,
      imageCompany: "",
      imageCompanyName: "",
    };
  },
  methods: {
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    FetchCityFromCountry(value) {
      let basic_country_ids = {
        country_id: [value.id],
      };
      axios
        .post("/fetch_cities_by_country_id", basic_country_ids)
        .then(({ data }) => {
          this.cities = data.data;
        });
    },
    // image
    imageUpload(event) {
      this.imageHotel = event.target.files[0];
      const name = this.imageHotel.name;

      const reader = new FileReader();
      reader.readAsDataURL(this.imageHotel);
      reader.onload = () => (this.imageHotelName = name);
    },
    fetchHotel() {
      axios.get(`/hotels/${this.hotelId}`).then(({ data }) => {
        this.title = data.data.title;
        this.starCount = data.data.starCount;
        this.long = data.data.long;
        this.lat = data.data.lat;
        this.address = data.data.address;
        this.countryValue = data.data.country;
        this.cityValues = data.data.city;
        this.regularCityValues = data.data.city; // For regular city selection
        // console.log(data.data);
      });
    },
    editHotel() {
      axios
        .put(`/hotels/${this.hotelId}`, {
          title: this.title,
          starCount: this.starCount,
          lat: this.lat,
          long: this.long,
          address: this.address,
          country_id: this.countryValue,
        })
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/allHotels");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetchHotel();
    this.fetch_country();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style></style>
