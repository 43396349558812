<template>
   <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="card_style">
    <div class="main_header_style">
      <h4>Create New Car Route</h4>
    </div>
    <form class="form_topic" @submit.prevent="addRoute()">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label">{{ $t("choose_country") }}</label>
            <div class="form-group w-100">
              <Multiselect
                  v-model="country_id"
                  :options="countries"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('choose_country')"
                  select-label=""
                  :hide-selected="true"
              >
              </Multiselect>
              <span class="input-errors" v-if="v$.country_id.id.$error">{{v$.country_id.id.$errors[0].$message}}</span>
            </div>
          </div>
        </div>
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("route_name") }} </label>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          {{$t("route_name")}}
                      </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                          <div class="form-group" v-for="(route , index) in route_name" :key="index">
                              <label for="name">{{$t("route_name")}} ({{route.lang}})</label>
                              <input type="text" :placeholder="$t('name')" class="form-control" :id="`name${index}`" v-model="route.name"/>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.route_name.$error">{{v$.route_name.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("add") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Loading from "@/components/LottieFiles/loading.vue";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  name: "add-route",
  components: {
    Multiselect,
    Loading
  },
  data() {
    return {
      v$: useVuelidate(),
      languages:["ar","en","id"],
      route_name:[],
      countries:[],
      country_id:"",
      loading: false,
    };
  },
  validations(){
    return{
      country_id: {
        id: { required }
      },
      route_name: this.route_name.map(()=>{
        return{
          name : {required}
        }
      }),
    }
  },
  methods: {
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
          this.countries = data.data;
      });
    },
    setRouteName(){
      this.languages.forEach((language)=>{
        this.route_name.push({
          lang: language,
          name: ""
        })
      })
    },
    async addRoute() {
      this.v$.$validate();
      if(!this.v$.$error){
        const formData = new FormData();
        formData.append("country_id", this.country_id.id);
        this.route_name.forEach((route)=>{
          formData.append(`text_${route.lang}`, route.name);
        })
        this.loading = true; // Start loading
        axios
          .post("/car_routes", formData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.loading = false; // end loading
            this.$router.push("/routers");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false; // end loading
          });
      }
    },
  },
  mounted(){
    this.fetch_country();
    this.setRouteName();
  }
};
</script>
<style lang="scss" scoped>
@import "./_routers.scss";
</style>
