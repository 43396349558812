<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4 class="mb-0">
        {{ $t("Faqs") }}
      </h4>
    </div>
    <div
      class="main_header_style mb-2 d-flex align-items-center justify-content-between"
    >
      <input
        type="text"
        v-model="faqText"
        @keyup="fetchFaqs((page = 1))"
        class="form-control w-50"
        :placeholder="$t('search...')"
      />
      <router-link to="/faqs/add" class="PrimaryButton mt-0 btn"
        ><i class="fa-solid fa-plus"></i> {{ $t("add_Faqs") }}
      </router-link>
    </div>

    <div class="row">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <!-- <th>{{ $t("Image") }}</th> -->
            <th>{{ $t("Title") }}</th>
            <th>{{ $t("describtion") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="faq in 10" :key="faq">
            <td v-for="faq in 4" :key="faq">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(faq, index) in faqs.data" :key="index">
            <td>{{ faq.id }}</td>
            <!-- <td><img :src="blog.image" :alt="blog.title"></td> -->
            <td>{{ faq.title }}</td>
            <td>
              <span v-html="faq.description.substring(0, 50) + ' ...'"></span>
            </td>
            <td>
              <router-link
                :to="`/faqs/edit/${faq.id}`"
                class="btn btn-success text-white"
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </router-link>

              <button
                class="btn btn-danger mx-2 text-white"
                @click="deleteFaq(faq.id, index)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="faqs"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchFaqs"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  name: "blogs-website",
  data() {
    return {
      faqs: [],
      faqText: "",
      loading: true,
    };
  },
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  methods: {
    async fetchFaqs(page = 1) {
      await axios
        .post(`/fetch_faq?page=${page}`, { word: this.faqText })
        .then(({ data }) => {
          this.faqs = data.data;
          this.loading = false;
        });
    },
    deleteFaq(faqId, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(`/website/faq/${faqId}`).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.faqs.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
          });
        }
      });
    },
  },
  mounted() {
    this.fetchFaqs();
  },
};
</script>

<style scoped>
table tbody tr td img {
  width: 100px;
  height: 100px;
}
</style>
