<template>
  <section class="card_style">
    <div class="main_header_style flex-column">
      <h4>{{ $t("paid") }}</h4>
    </div>

    <form class="form_topic" @submit.prevent="addPaid()">
      <div class="row">
        <!-- room name ar -->
        <ul class="types">
          <li v-for="(type, index) in consumer_type" :key="index">
            <div class="form-group service_type">
              <input
                type="radio"
                name="service_type"
                class="wizard-required"
                v-model="selectedServiceType"
                :value="type.value"
                :id="'default' + type.value"
              />
              <label :for="'default' + type.value">
                {{ $t(getTypeName(type.value)) }}
              </label>
            </div>
          </li>
        </ul>

        <div class="col-lg-12 mb-4">
          <label class="form-label">{{
            $t("choose_payment_method")
          }}</label>
          <Multiselect
            v-model="paidValues"
            :options="paids.map((paid) => paid.id)"
            :custom-label="(opt) => paids.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('choose_payment_method')"
            select-label=""
            :hide-selected="true"
            @update:model-value="FetchAccounts(paidValues)"
          >
          </Multiselect>
        </div>

        <!-- account -->
        <div class="col-lg-12 mb-4">
          <label>{{ $t("accounts") }}</label>
          <Multiselect
            v-model="bankValues"
            :options="accounts.map((account) => account.id)"
            :custom-label="(opt) => accounts.find((x) => x.id == opt).title_ar"
            :clear-on-select="true"
            :placeholder="$t('accounts')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
        <!-- account -->

        <div class="col-lg-12 mb-4">
          <div class="input-group">
            <label class="form-label" for="adult">{{ $t("the_value") }}</label>
            <input
              class="form-control"
              type="number"
              :placeholder="$t('the_value')"
              v-model="amount"
            />
          </div>
        </div>
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("statement") }}</label>
            <textarea
              class="form-control"
              :placeholder="$t('add_statement')"
              v-model="report"
            ></textarea>
          </div>
        </div>

        <div class="col-md-6 mb-6">
          <input type="file" id="file" @change="imageUpload" multiple hidden />

          <label for="file" class="add_file"
            ><i class="fa-solid fa-upload"></i> {{ $t("Add image") }}</label
          >
          <p>{{ this.imageHotelName }}</p>
        </div>
        <!-- finish input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("add") }}
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
export default {
  name: "add-paid",
  components: {
    Multiselect,
  },
  data() {
    return {
      amount: "",
      report: "",
      consumer_type: [{ value: 1 }, { value: 2 }],
      selectedServiceType: 1,
      paidValues: "",
      paids: [
        {
          id: 1,
          title: this.$t("treasury"),
        },
        {
          id: 2,
          title: this.$t("bank")
        },
        {
          id: 3,
          title: this.$t("ewalllets"),
        },
        {
          id: 4,
          title: this.$t("checks_under_collection"),
        },
      ],
      bankValues: "",
      accounts: [],
      safeValues: "",
      revenue: [],
      bookId: null,
      imageHotel: "",
      imageHotelName: "",
    };
  },
  created() {
    this.bookId = this.$route.params.id;
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
  methods: {
    imageUpload(event) {
      this.imageHotel = event.target.files[0];
      const name = this.imageHotel.name;

      const reader = new FileReader();
      reader.readAsDataURL(this.imageHotel);
      reader.onload = () => (this.imageHotelName = name);
      // reader.onload = () => (this.imageEntry = reader.result);
    },
    getTypeName(value) {
      // Define mappings of value to type names
      const nameMap = {
        1: "client",
        2: "suppliers",
        // ... add more mappings as needed ...
      };
      return nameMap[value];
    },
  
    FetchAccounts(account_id) {
      let type = { payment_method: account_id };
      axios.post(`/fetch_payment_method`, type).then(({ data }) => {
        // ?page=" + page
        this.accounts = data.data;
        //  console.log(this.accounts)
      });
    },
    addPaid() {
      const formData = new FormData();
      formData.append("amount", this.amount);
      formData.append("report", this.report);
      formData.append("consumer_type", this.selectedServiceType);
      formData.append("payment_way", this.paidValues);
      formData.append("booking_id", this.bookId);
      formData.append("image", this.imageHotel);
      formData.append("payment_branch_id", this.bankValues);

      axios
        .post("/pay_booking_money", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          // console.log(data)
           this.$router.push(`/book_table/${this.$route.params.id}/paid`);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    // this.FetchAccounts();
    // this.FetchSafes();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.types {
  display: flex;

  li {
    width: 50%;
    margin-inline-end: 0.4rem;

    .service_type {
      text-align: center;
      padding: 0;
      border-radius: 0px;
      width: 100%;
      display: inline-block;
      .wizard-required {
        opacity: 0;
      }

      label {
        background-color: rgba($color: #d0d2d7, $alpha: 0.3);
        border: 1px solid #d4d7da;
        font-family: "regular";
        color: #535353;
        margin-bottom: 0;
        padding: 1rem;
        width: 100%;
        cursor: pointer;
        border-radius: 0px;
      }

      input[type="radio"]:checked + label {
        background-color: rgba($color: $PrimaryColor, $alpha: 1);
        border: 1px solid rgba($color: $PrimaryColor, $alpha: 1);
        color: #fff;
      }
    }
  }
}

.form_topic {
  width: 70%;

  .input-group {
    position: relative;

    .icon_form {
      position: absolute;
      right: 0.5rem;
      top: 55%;
      z-index: 999;

      i {
        color: $SecondText;
      }
    }

    .form-label {
      width: 100%;
      font-size: 1rem;
      color: $PrimaryText;
      font-family: "bold";
      display: block;
    }

    .form-control {
      border-radius: 2px;
      background-color: transparent;
      color: $SecondText;
      font-family: "regular";
      border: 1px solid #dee2e6;
      border-radius: 4px !important;
      font-size: 0.8rem;
      padding: 0.7rem;
      padding-inline-start: 1%;

      &:focus {
        box-shadow: none !important;
      }

      &::placeholder {
        color: $SecondText;
        font-family: "light" !important;
        font-size: 0.9rem;
      }
    }

    .multiselect {
      .multiselect__tags {
        border-radius: 2px;
        padding-inline-start: 4%;
        background-color: transparent;
        color: $SecondText;
        font-family: "regular";
        border: 1px solid #dee2e6;
        border-radius: 4px !important;
      }
    }

    .xyz {
      padding-inline-start: 2rem;
    }
  }
}
</style>
