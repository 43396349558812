<template>
    <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
        <div class="offcanvas-header">
            <div class="texts">
                <h5 class="offcanvas-title title_setup" id="offcanvasBottomLabel">{{$t("customize_selling_fees_companies")}}</h5>
                <p class="text_setup">{{$t("can_customize_sales_companies_following_inputs")}}</p>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body small">
            <form @submit.prevent="submitCustomCompanySetup()">
                <ul class="types">
                    <li>
                        <div class="form-group service_type">
                            <input type="radio" name="service_type" v-model="Form.service_type" class="wizard-required" id="hotel" value="1" required hidden>
                            <label for="hotel"><i class="fa-solid fa-bell-concierge"></i> {{$t("hotels")}}</label>
                        </div>
                    </li>
                    <li>
                        <div class="form-group service_type">
                            <input type="radio" name="service_type" v-model="Form.service_type" class="wizard-required" id="flight" value="2" required hidden>
                            <label for="flight"><i class="fa-solid fa-plane"></i> {{$t("flight")}}</label>
                        </div>
                    </li>
                    <li>
                        <div class="form-group service_type">
                            <input type="radio" name="service_type" v-model="Form.service_type" class="wizard-required" id="transportation" value="3" required hidden>
                            <label for="transportation"><i class="fa-solid fa-bus"></i> {{$t("transportation")}}</label>
                        </div>
                    </li>
                    <li>
                        <div class="form-group service_type">
                            <input type="radio" name="service_type" v-model="Form.service_type" class="wizard-required" id="private_trips" value="4" required hidden>
                            <label for="private_trips"><i class="fa-solid fa-tree"></i> {{$t("private_trips")}}</label>
                        </div>
                    </li>
                </ul>
                <customizeByHotel v-if="Form.service_type == 1" @radio-selected="updateRadioCustomBy" />
                <customizeByFlight v-if="Form.service_type == 2" @radio-selected="updateRadioCustomBy" />
                <customizeByTransportation v-if="Form.service_type == 3" @radio-selected="updateRadioCustomBy" />
                <customizeByPrivateTrips v-if="Form.service_type == 4" @radio-selected="updateRadioCustomBy" />
                <Country v-if="Form.customization_type == 1" @multiselect-changed="updateCountryValue" />
                <City v-if="Form.customization_type == 2" @multiselect-changed="updateCityValue" />
                <Nationality v-if="Form.customization_type == 4" @multiselect-changed="updateNationalityValue" />
                <div class="form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-12">
                            <label for="">{{$t("time_period")}}</label>
                            <datepicker v-model="from_date" name="from_date" :placeholder="$t('from')"></datepicker>
                        </div>
                        <div class="col-lg-6 col-md-12 col-12">
                            <label for="">{{$t("time_period")}}</label>
                            <datepicker v-model="to_date" name="to_date" :placeholder="$t('to')"></datepicker>
                        </div>
                         <!-- :disabled-dates="{ to: new Date(formatDate(this.from_date)), from: new Date() }" -->
                        <div class="col-lg-12 col-md-12 col-12 mt-4">
                            <div class="form-group w-100">
                                <label for="">{{$t("supposed_profit_ratio")}}</label>
                                <input type="number" name="" id="" class="form-control" v-model="Form.percentage" :placeholder="$t('determine_profit_rate_companies')">
                                <select class="form-control" v-model="Form.type_profit">
                                    <option value="1" selected>{{$t("percentages")}}</option>
                                    <option value="2">{{$t("value")}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn PrimaryButton">{{$t("save")}}</button>
            </form>
        </div>
    </div>

    <!-- Customization Table -->
    <customizeTable ref="childComponentRef" />
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from "moment";
import Datepicker from 'vuejs3-datepicker';
import customizeByHotel from '@/components/setup/customizeBy/customizeByHotel.vue';
import customizeByFlight from '@/components/setup/customizeBy/customizeByFlight.vue';
import customizeByTransportation from '@/components/setup/customizeBy/customizeByTransportation.vue';
import customizeByPrivateTrips from '@/components/setup/customizeBy/customizeByPrivateTrips.vue';
import Country from '@/components/setup/customByChild/country.vue';
import City from '@/components/setup/customByChild/city.vue';
import Nationality from '@/components/setup/customByChild/nationality.vue';

// Customization Table
import customizeTable from '@/components/setup/tables/customizeTable.vue';

export default {
    name: "customize-company",
    data() {
        return {
            countries: [],
            Form: {
                service_type: 1,
                customization_type: 1,
                percentage: 0,
                type_profit: 1,
                type: this.$route.params.type_id,
            },
            country_ids: [],
            city_ids: [],
            nationality_ids: [],
            from_date: "",
            to_date: "",
            customizations: [],
        }
    },
    components: {
        Datepicker,
        customizeByHotel,
        customizeByFlight,
        customizeByTransportation,
        customizeByPrivateTrips,
        Country,
        City,
        Nationality,
        customizeTable
    },
    methods: {
        async submitCustomCompanySetup() {
            const formData = new FormData();
            Object.entries(this.Form).forEach(([key, value]) => {
                formData.append(key, value);
            });
            for (let index = 0; index < this.country_ids.length; index++) {
                formData.append(`country_ids[${index}]`, this.country_ids[index]);
            }
            for (let index = 0; index < this.city_ids.length; index++) {
                formData.append(`city_ids[${index}]`, this.city_ids[index]);
            }
            formData.append("from_date", moment(this.from_date).format("YYYY-MM-DD"));
            formData.append("to_date", moment(this.to_date).format("YYYY-MM-DD"));
            axios.post("/customization_settings", formData)
            .then((response) => {
                Swal.fire({
                    title: "",
                    text: response.data.message,
                    icon: "success",
                });

                // Call Customization Table
                const childComponent = this.$refs.childComponentRef;
                childComponent.FetchCustomizations();
            })
            .catch((error) => {
                Swal.fire({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                });
            });
        },

        updateRadioCustomBy(customizationTypeValue) {
            this.Form.customization_type = customizationTypeValue;
        },

        updateCountryValue(countryValues) {
            this.country_ids = countryValues;
        },

        updateCityValue(cityValues) {
            this.city_ids = cityValues;
        },

        updateNationalityValue(nationalityValues) {
            this.nationality_ids = nationalityValues;
        },
    },
}
</script>

<style lang="scss" scoped>
    @import "../_setup.scss";
</style>
