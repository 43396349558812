<template>
  <section class="card_style">
    <div class="form-group">
      <div class="w-25">
        <label class="typo__label">{{ $t("type") }}</label>
        <Multiselect
          v-model="stage_value"
          :options="options"
          label="title"
          track-by="id"
          :placeholder="$t('type')"
          @update:model-value="fetchData"
          select-label=""
          :hide-selected="true"
        >
        </Multiselect>
        <span
          class="text-danger"
          v-if="v$.stage_value.$error"
          v-text="v$.stage_value.$errors[0].$message"
        ></span>
      </div>
    </div>
    <br />
    <form @submit.prevent="addService()">
      <div class="row">
        <h4>
          <i class="fa-solid fa-caret-left"></i>
          {{ $t("first_stage") }}
        </h4>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group">
            <label>{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in stages.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="text-danger"
              v-if="v$.stages.title.$error"
              v-text="v$.stages.title.$errors[0].$message"
            ></span>
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-group">
            <label> Direct One </label>
            <input
              type="number"
              min="0"
              v-model="stages.direct1"
              class="form-control"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.stages.direct1.$error"
            v-text="v$.stages.direct1.$errors[0].$message"
          ></span>
        </div>
      </div>
      <hr />
      <div class="row my-4">
        <h4>
          <i class="fa-solid fa-caret-left"></i>
          {{ $t("second_stage") }}
        </h4>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group">
            <label>{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in stages.title2"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="text-danger"
              v-if="v$.stages.title2.$error"
              v-text="v$.stages.title2.$errors[0].$message"
            ></span>
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-group">
            <label> Direct Two </label>
            <input
              type="number"
              min="0"
              v-model="stages.direct2"
              class="form-control"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.stages.direct2.$error"
            v-text="v$.stages.direct2.$errors[0].$message"
          ></span>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-group">
            <label> InDirect One </label>
            <input
              type="number"
              v-model="stages.inDirect1"
              min="0"
              class="form-control"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.stages.inDirect1.$error"
            v-text="v$.stages.inDirect1.$errors[0].$message"
          ></span>
        </div>
      </div>

      <hr />
      <div class="row my-4">
        <h4>
          <i class="fa-solid fa-caret-left"></i>
          {{ $t("Third_stage") }}
        </h4>

        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group">
            <label>{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in stages.title3"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="text-danger"
              v-if="v$.stages.title3.$error"
              v-text="v$.stages.title3.$errors[0].$message"
            ></span>
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-group">
            <label> Direct Three </label>
            <input
              type="number"
              v-model="stages.direct3"
              min="0"
              class="form-control"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.stages.direct3.$error"
            v-text="v$.stages.direct3.$errors[0].$message"
          ></span>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-group">
            <label> InDirect Two </label>
            <input
              type="number"
              v-model="stages.inDirect2_3"
              min="0"
              class="form-control"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.stages.inDirect2_3.$error"
            v-text="v$.stages.inDirect2_3.$errors[0].$message"
          ></span>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-group">
            <label> InDirect One </label>
            <input
              type="number"
              v-model="stages.inDirect1_3"
              min="0"
              class="form-control"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.stages.inDirect2_3.$error"
            v-text="v$.stages.inDirect2_3.$errors[0].$message"
          ></span>
        </div>
      </div>

      <hr />
      <div class="row my-4">
        <h4>
          <i class="fa-solid fa-caret-left"></i>
          {{ $t("Fourth_stage") }}
        </h4>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group">
            <label>{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in stages.title4"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <span
                class="text-danger"
                v-if="v$.stages.title4.$error"
                v-text="v$.stages.title4.$errors[0].$message"
              ></span>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-group">
            <label> Direct Four </label>
            <input
              type="number"
              min="0"
              v-model="stages.direct4"
              class="form-control"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.stages.direct4.$error"
            v-text="v$.stages.direct4.$errors[0].$message"
          ></span>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-group">
            <label> InDirect Three </label>
            <input
              type="number"
              min="0"
              v-model="stages.inDirect3_4"
              class="form-control"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.stages.inDirect3_4.$error"
            v-text="v$.stages.inDirect3_4.$errors[0].$message"
          ></span>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-group">
            <label> InDirect Two </label>
            <input
              type="number"
              min="0"
              v-model="stages.inDirect2_4"
              class="form-control"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.stages.inDirect2_4.$error"
            v-text="v$.stages.inDirect2_4.$errors[0].$message"
          ></span>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-group">
            <label> InDirect One </label>
            <input
              type="number"
              min="0"
              v-model="stages.inDirect1_4"
              class="form-control"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.stages.inDirect1_4.$error"
            v-text="v$.stages.inDirect1_4.$errors[0].$message"
          ></span>
        </div>
      </div>

      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "add-service",
  data() {
    return {
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      options: [],
      fileData: null,
      // stage_value: "",
      stages: {
        title: [],
        title2: [],
        title3: [],
        title4: [],

        direct1: 0,
        direct2: 0,
        direct3: 0,
        direct4: 0,
        inDirect1: 0,
        inDirect1_3: 0,
        inDirect1_4: 0,
        inDirect2_3: 0,
        inDirect2_4: 0,
        inDirect3_4: 0,
        // value: "",
      },
      stage_value: {},

      service_id: undefined,
    };
  },
  validations() {
    return {
      stage_value: { required },
      stages: {
        title: this.stages.title.map(() => {
          return {
            name: {
              required,
            },
          };
        }),
        title2: this.stages.title2.map(() => {
          return {
            name: {
              required,
            },
          };
        }),
        title3: this.stages.title3.map(() => {
          return {
            name: {
              required,
            },
          };
        }),
        title4: this.stages.title4.map(() => {
          return {
            name: {
              required,
            },
          };
        }),
        direct1: { required },
        direct2: { required },
        direct3: { required },
        direct4: { required },
        inDirect1: { required },
        inDirect1_3: { required },
        inDirect1_4: { required },
        inDirect2_3: { required },
        inDirect2_4: { required },
        inDirect3_4: { required },
      },
    };
  },
  methods: {
    setBlogTitle() {
      this.languages.forEach((language) => {
        this.stages.title.push({
          lang: language,
          name: "",
        });
      });
    },
    setBlogTitle2() {
      this.languages.forEach((language) => {
        this.stages.title2.push({
          lang: language,
          name: "",
        });
      });
    },
    setBlogTitle3() {
      this.languages.forEach((language) => {
        this.stages.title3.push({
          lang: language,
          name: "",
        });
      });
    },
    setBlogTitle4() {
      this.languages.forEach((language) => {
        this.stages.title4.push({
          lang: language,
          name: "",
        });
      });
    },

    async addService() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let stages = [
          {
            stage_id: 0,
            stage_title_ar: this.stages.title[0].name,
            stage_title_en: this.stages.title[1].name,
            stage_title_id: this.stages.title[2].name,

            stage_order: 1,
            profits: [
              {
                related_stage_order:
                  this.stages.direct1 - this.stages.direct1 + 1,
                profit: this.stages.direct1,
              },
            ],
          },
          {
            stage_id: 0,
            stage_title_ar: this.stages.title2[0].name,
            stage_title_en: this.stages.title2[1].name,
            stage_title_id: this.stages.title2[2].name,
            stage_order: 2,
            profits: [
              {
                related_stage_order:
                  this.stages.direct2 - this.stages.direct2 + 2,
                profit: this.stages.direct2,
              },
              {
                related_stage_order:
                  this.stages.inDirect1 - this.stages.inDirect1 + 1,
                profit: this.stages.inDirect1,
              },
            ],
          },
          {
            stage_id: 11,
            stage_title_ar: this.stages.title3[0].name,
            stage_title_en: this.stages.title3[1].name,
            stage_title_id: this.stages.title3[2].name,
            stage_order: 3,
            profits: [
              {
                related_stage_order:
                  this.stages.direct3 - this.stages.direct3 + 3,
                profit: this.stages.direct3,
              },
              {
                related_stage_order:
                  this.stages.inDirect2_3 - this.stages.inDirect2_3 + 2,
                profit: this.stages.inDirect2_3,
              },
              {
                related_stage_order:
                  this.stages.inDirect1_3 - this.stages.inDirect1_3 + 1,
                profit: this.stages.inDirect1_3,
              },
            ],
          },
          {
            stage_id: 0,
            stage_title_ar: this.stages.title4[0].name,
            stage_title_en: this.stages.title4[1].name,
            stage_title_id: this.stages.title4[2].name,
            stage_order: 4,
            profits: [
              {
                related_stage_order:
                  this.stages.direct4 - this.stages.direct4 + 4,
                profit: this.stages.direct4,
              },
              {
                related_stage_order:
                  this.stages.inDirect3_4 - this.stages.inDirect3_4 + 3,
                profit: this.stages.inDirect3_4,
              },
              {
                related_stage_order:
                  this.stages.inDirect2_4 - this.stages.inDirect2_4 + 2,
                profit: this.stages.inDirect2_4,
              },
              {
                related_stage_order:
                  this.stages.inDirect1_4 - this.stages.inDirect1_4 + 1,
                profit: this.stages.inDirect1_4,
              },
            ],
          },
        ];

        // console.log(this.stage_value);
        let data = {
          category_id: this.stage_value.id,
          stages: stages,
        };
        await axios
          .post(`/stage_profits`, data)
          .then(() => {
            Swal.fire({
              title: "",
              text: this.$t("save_successfuly"),
              icon: "success",
            });
            // console.log(this.stages, "stages");
            this.$router.push("/stage_percentages");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
        // console.log(this.stages, "asd");
      }
    },
    async fetchData() {
      let affiliate_category_id = {
        affiliate_category_id: this.stage_value.id,
      };

      try {
        const { data } = await axios.post(
          "/get_stages_by_affiliate_category_id",
          affiliate_category_id
        );
        return (
          // console.log(this.stages, "stage"),
          (this.stages = data.data.data),
          (this.stages.direct1 = this.stages[0].profits[0].profit),
          (this.stages.title = this.stages[0].title.map((ele) => {
            return {
              lang: ele.locale,
              name: ele.title,
            };
          })),
          (this.stages.direct2 = this.stages[1].profits[0].profit),
          (this.stages.inDirect1 = this.stages[1].profits[1].profit),
          (this.stages.title2 = this.stages[1].title.map((ele) => {
            return {
              lang: ele.locale,
              name: ele.title,
            };
          })),
          (this.stages.direct3 = this.stages[2].profits[0].profit),
          (this.stages.inDirect2_3 = this.stages[2].profits[1].profit),
          (this.stages.inDirect1_3 = this.stages[2].profits[2].profit),
          (this.stages.title3 = this.stages[2].title.map((ele) => {
            return {
              lang: ele.locale,
              name: ele.title,
            };
          })),
          (this.stages.direct4 = this.stages[3].profits[0].profit),
          (this.stages.inDirect3_4 = this.stages[3].profits[1].profit),
          (this.stages.inDirect2_4 = this.stages[3].profits[2].profit),
          (this.stages.inDirect1_4 = this.stages[3].profits[3].profit),
          (this.stages.title4 = this.stages[3].title.map((ele) => {
            return {
              lang: ele.locale,
              name: ele.title,
            };
          }))
        );
      } catch (err) {
        console.log(err);
        // this.addService();
        // return err;
      }
    },
    affliliates() {
      axios.post(`/index_affiliate_categories`).then(({ data }) => {
        this.options = data.data.data;
        // console.log(this.options, "options");
      });
    },
  },
  components: {
    Multiselect,
  },
  created() {
    this.setBlogTitle();
    this.setBlogTitle2();
    this.setBlogTitle3();
    this.setBlogTitle4();
  },
  mounted() {
    this.affliliates();
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}

.btn-stratigy {
  background-color: #0a909b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}

input.form-control {
  padding: 0.8rem !important;
}
.fa-caret-left {
  color: #0a909b;
}
</style>
