<template>
  <div class="row my-3" v-if="loading && $route.params.id">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <Skeleton width="15%" class="mb-2"></Skeleton>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="d-flex gap-3 w-100">
        <Skeleton width="50%" height="2rem" class="mb-2"></Skeleton>
        <Skeleton width="50%" height="2rem" class="mb-2"></Skeleton>
      </div>
    </div>
    <div class="col-12 my-3">
      <Skeleton width="15%" class="mb-2"></Skeleton>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 my-3">
      <Skeleton width="45%" class="my-3" v-for="i in 4" :key="i"></Skeleton>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="d-flex gap-3 w-100">
        <Skeleton width="100%" height="150px"></Skeleton>
      </div>
    </div>
  </div>
  <section class="organize_details" v-else>
    <Details :details="details" class="bg-white" />
    <Profit
      :hotel_percentage="hotel_percentage"
      :flight_percentage="flight_percentage"
      :transport_percentage="transport_percentage"
      :trips_percentage="trips_percentage"
    />
    <Reservation
      :hotel_booking="hotel_booking"
      :flight_booking="flight_booking"
      :transport_booking="transport_booking"
      :trip_booking="trip_booking"
    />
    <!-- {{ details.hotel_booking.complete_booking }} -->

    <Tabs class="bg-white" />
  </section>
</template>

<script>
import axios from "axios";
import Details from "@/components/organization/details.vue";
import Reservation from "@/components/organization/reservation.vue";
import Tabs from "@/components/organization/tabs.vue";
import Profit from "@/components/organization/profit.vue";

import Skeleton from "primevue/skeleton";

export default {
  name: "details-organization",
  components: {
    Details,
    Reservation,
    Tabs,
    Profit,
    Skeleton,
  },
  data() {
    return {
      details: {},
      hotel_booking: {},
      trip_booking: {},
      transport_booking: {},
      flight_booking: {},
      hotel_percentage: {},
      flight_percentage: {},
      transport_percentage: {},
      trips_percentage: {},
      loading: true,
    };
  },
  methods: {
    // fetch details ORGANIZATION data
    async fetchdetailsOrganization() {
      let id = this.$route.params.id;
      const formData = new FormData();
      formData.append("organization_id", id);
      await axios
        .post("/fetch_organization_customization_details", formData)
        .then(({ data }) => {
          this.details = data.data;
          this.trip_booking = data.data.trip_booking;
          this.transport_booking = data.data.transport_booking;
          this.flight_booking = data.data.flight_booking;
          this.hotel_booking = data.data.hotel_booking;
          this.hotel_percentage = data.data.hotel_percentage;
          this.flight_percentage = data.data.flight_percentage;
          this.transport_percentage = data.data.transport_percentage;
          this.trips_percentage = data.data.trips_percentage;
          this.loading = false;
          //  console.log(this.details.hotel_booking.complete_booking);
        });
    },
  },
  created() {
    this.fetchdetailsOrganization();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_organization.scss";
</style>
