<template>
  <section class="reservation_details">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-9 col-12">
          <div class="details_title w-100" v-if="loading">
            <div class="w-100 my-2">
              <Skeleton width="40%" class="mb-2"></Skeleton>
              <div class="d-flex align-items-center gap-3 my-2">
                <Skeleton width="20%" class="mb-2"></Skeleton>
                <Skeleton width="20%" class="mb-2"></Skeleton>
              </div>
            </div>
          </div>
          <div class="details_title" v-else>
            <h3>{{ $t("reservation's details") }}</h3>
            <div class="package_date">
              <div>
                <span>{{ $t("reservation_number") }}: </span>
                {{ reservation_details.book_number }}
              </div>
              <div>
                <span>{{ $t("reservation_date") }}: </span>
                {{ reservation_details.date }}
              </div>
            </div>
          </div>

          <div class="details_title w-100" v-if="loading">
            <div class="w-100 my-2">
              <div class="d-flex w-100 align-items-center gap-3">
                <Skeleton width="50%" height="14rem" class="mb-2"></Skeleton>
                <div class="d-flex w-100 flex-column gap-3">
                  <Skeleton width="50%" class="mb-2"></Skeleton>
                  <Skeleton width="30%" class="mb-2"></Skeleton>
                  <Skeleton width="20%" class="mb-2"></Skeleton>
                  <Skeleton width="40%" class="mb-2"></Skeleton>
                  <Skeleton width="40%" class="mb-2"></Skeleton>
                </div>
              </div>
            </div>
          </div>

          <div class="package_details" v-else>
            <div class="row">
              <div class="col-lg-6 col-md-3 col-12">
                <div class="image">
                  <img :src="package_image" alt="package_image" />
                </div>
              </div>
              <div class="col-lg-6 col-md-9 col-12">
                <div class="content">
                  <h5>{{ packageData.title }}</h5>
                  <div class="d-flex align-items-center gap-3">
                    <div class="d-flex align-items-center gap-3">
                      <!-- <img
                        src="../../assets/media/image/umrah/icons/loliypop.svg"
                        alt=""
                      /> -->
                      <span>{{ $t("cairo") }}</span>
                      <!-- <span>{{packageData.launch_city.title}}</span> -->
                    </div>
                    <div
                      class="d-flex align-items-center"
                      v-for="dist in packageData.destinations"
                      :key="dist.id"
                    >
                      <img
                        src="@/assets/media/image/umrah/icons/arrow-2.svg"
                        alt=""
                        class="mx-2"
                      />
                      <span>{{ dist.city.title }}</span>
                    </div>
                  </div>
                  <!-- <div class="rating">
                    <rate :rates="package_rate" />
                    <div class="rate_div">5.9</div>
                    <div class="text">
                      ({{ packageData.reviews_count }} {{ $t("rate") }})
                    </div>
                  </div> -->
                  <ul>
                    <li>
                      <i class="fa-solid fa-users"></i>
                      {{ $t("number_of_persons") }}:
                      <span>{{ number_of_persons }}</span>
                    </li>
                    <li>
                      <i class="fa-regular fa-calendar-days"></i>
                      {{ $t("date of arrival") }}:
                      <span>{{
                        packageData.check_in == "" ? "-" : packageData.check_in
                      }}</span>
                    </li>
                    <li>
                      <i class="fa-regular fa-calendar-days"></i>
                      {{ $t("Departure Date") }}:
                      <span>{{
                        packageData.check_out == ""
                          ? "-"
                          : packageData.check_out
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="rooms_details">
            <div class="title">
              <h5>{{ $t("rooms") }}</h5>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>{{ $t("room_name") }}</th>
                    <th>{{ $t("number_of_adults") }}</th>
                    <th>{{ $t("number_of_childrens") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="room in reservation_details.package_rooms"
                    :key="room.id"
                  >
                    <td>{{ room.title }}</td>
                    <td>{{ room.adult_count }}</td>
                    <td>{{ room.child_count }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> -->

          <div
            class="rooms_details"
            v-for="hotel in packageData.hotels"
            :key="hotel.id"
          >
            <div class="title">
              <h5>{{ hotel.title }}</h5>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>{{ $t("room_name") }}</th>
                    <th>{{ $t("number_of_adults") }}</th>
                    <th>{{ $t("number_of_childrens") }}</th>
                  </tr>
                </thead>
                <tbody v-if="loading" class="text-center">
                  <tr v-for="guests in 10" :key="guests">
                    <td v-for="guests in 3" :key="guests">
                      <Skeleton
                        height="2rem"
                        width="90%"
                        class="mb-2"
                      ></Skeleton>
                    </td>
                  </tr>
                </tbody>

                <tbody v-else>
                  <tr v-for="room in hotel.rooms" :key="room.id">
                    <td v-if="room.adult_count != ''">{{ room.title }}</td>
                    <td v-if="room.adult_count != ''">
                      {{ room.adult_count }}
                    </td>
                    <td v-if="room.adult_count != ''">
                      {{ room.child_count }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="flight_details">
            <div class="title">
              <Skeleton v-if="loading" width="5rem" class="mb-2"></Skeleton>
              <h5 v-else>{{ $t("flights") }}</h5>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>{{ $t("trip_number") }}</th>
                    <th>{{ $t("launch_airport") }}</th>
                    <th>{{ $t("launch_city") }}</th>
                    <th>{{ $t("arrival_airport") }}</th>
                    <th>{{ $t("arrival_city") }}</th>
                  </tr>
                </thead>
                <tbody v-if="loading" class="text-center">
                  <tr v-for="guests in 10" :key="guests">
                    <td v-for="guests in 5" :key="guests">
                      <Skeleton
                        height="2rem"
                        width="90%"
                        class="mb-2"
                      ></Skeleton>
                    </td>
                  </tr>
                </tbody>

                <tbody v-else>
                  <tr
                    v-for="flight_obj in packageData.flight"
                    :key="flight_obj.id"
                  >
                    <td>{{ flight_obj.trip_number }}</td>
                    <td>
                      {{
                        flight_obj.launch_airport.title == undefiend
                          ? ""
                          : flight_obj.launch_airport.title
                      }}
                    </td>
                    <td>
                      {{
                        flight_obj.launch_airport.city.title == undefiend
                          ? ""
                          : flight_obj.launch_airport.city.title
                      }}
                    </td>
                    <td>
                      {{
                        flight_obj.arrival_airport.title == undefiend
                          ? ""
                          : flight_obj.arrival_airport.title
                      }}
                    </td>
                    <td>
                      {{
                        flight_obj.arrival_airport.city.title == undefiend
                          ? ""
                          : flight_obj.arrival_airport.city.title
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="guests_details">
            <div class="title">
              <Skeleton v-if="loading" width="5rem" class="mb-2"></Skeleton>
              <h5 v-else>{{ $t("guests") }}</h5>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>{{ $t("id") }}</th>
                    <th>{{ $t("gender") }}</th>
                    <th>{{ $t("name") }}</th>
                    <th>{{ $t("nationality") }}</th>
                    <th>{{ $t("date_of_birth") }}</th>
                    <th>{{ $t("passport_number") }}</th>
                    <th>{{ $t("email") }}</th>
                  </tr>
                </thead>

                <tbody v-if="loading" class="text-center">
                  <tr v-for="guests in 10" :key="guests">
                    <td v-for="guests in 7" :key="guests">
                      <Skeleton
                        height="2rem"
                        width="90%"
                        class="mb-2"
                      ></Skeleton>
                    </td>
                  </tr>
                </tbody>

                <tbody v-else>
                  <tr
                    v-for="guests in reservation_details.package_guests"
                    :key="guests.id"
                  >
                    <td>{{ guests.id }}</td>
                    <td>{{ guests.title }}</td>
                    <td>{{ guests.name }}</td>
                    <td>{{ guests.nationality }}</td>
                    <td>{{ guests.birthdate }}</td>
                    <td>{{ guests.IdentityNumber }}</td>
                    <td>{{ guests.email }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-12">
          <!-- <button type="button" class="btn PrimaryButton">
            <i class="fa-solid fa-print"></i>
            {{ $t("Print the invoice") }}
          </button> -->

          <div class="payment_details" v-if="loading">
            <ul class="text">
              <li>
                <span>
                  <Skeleton width="5rem" class="mb-2"></Skeleton>
                </span>
              </li>
              <li>
                <span>
                  <Skeleton width="5rem" class="mb-2"></Skeleton>
                </span>
              </li>
              <li>
                <span>
                  <Skeleton width="5rem" class="mb-2"></Skeleton>
                </span>
              </li>
            </ul>
            <div class="payment">
              <ul class="prices_details">
                <li>
                  <span>
                    <Skeleton width="5rem" class="mb-2"></Skeleton>
                  </span>
                </li>
                <li>
                  <span>
                    <Skeleton width="5rem" class="mb-2"></Skeleton>
                  </span>
                </li>
                <li class="total">
                  <span>
                    <Skeleton width="5rem" class="mb-2"></Skeleton>
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div class="payment_details" v-else>
            <ul class="text">
              <li>
                <span
                  ><i class="fa-solid fa-calendar-days"></i>
                  {{ $t("reservation_by") }}:
                </span>
                {{ reservation_details.book_by_name }}
              </li>
              <li>
                <span
                  ><i class="fa-solid fa-calendar-days"></i>
                  {{ $t("reservation_confirm_number") }}:
                </span>
                {{ reservation_details.book_number }}
              </li>
              <li>
                <span
                  ><i class="fa-solid fa-calendar-days"></i>
                  {{ $t("reservation_created_at") }}:
                </span>
                {{ reservation_details.date }}
              </li>
            </ul>
            <div class="payment">
              <h5>{{ $t("payment_details") }}</h5>
              <ul class="prices_details">
                <li>
                  <span>{{ $t("Total Cost for adults") }}</span>
                  <span>{{ reservation_details.total_adults_price }}</span>
                </li>
                <li>
                  <span>{{ $t("Total Cost for children") }}</span>
                  <span>{{ reservation_details.total_children_price }}</span>
                </li>
                <li class="total">
                  <span>{{ $t("total selling") }}</span>
                  <span class="price">{{
                    reservation_details.total_price
                  }}</span>
                </li>
              </ul>
            </div>
          </div>

          <div class="help_sec" v-if="loading">
            <div class="text">
              <span>
                <Skeleton width="5rem" class="mb-2"></Skeleton>
              </span>
            </div>
            <ul>
              <li>
                <span>
                  <Skeleton width="5rem" class="mb-2"></Skeleton>
                </span>
              </li>
              <li>
                <span>
                  <Skeleton width="5rem" class="mb-2"></Skeleton>
                </span>
              </li>
            </ul>
          </div>

          <div class="help_sec" v-else>
            <div class="text">
              <h5>{{ $t("do you want help ??") }}</h5>
              <p>{{ $t("DoNotHesitateToContactUs") }}</p>
            </div>
            <ul>
              <li>
                <i class="fa-solid fa-phone"></i>
                <span>01010101010</span>
              </li>
              <li>
                <i class="fa-solid fa-envelope"></i>
                <span>kanaya@gmail.com</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
// import rate from "../../components/rate.vue";
import Skeleton from "primevue/skeleton";

export default {
  name: "reservationDetails",
  data() {
    return {
      reservation_details: {},
      packageData: {},
      number_of_persons: null,
      package_rate: null,
      package_image: "",
      loading: true,
    };
  },
  // components: { rate },
  methods: {
    fetch_reservation_details() {
      axios
        .post("/fetch_book_package_details", {
          book_package_id: this.$route.params.id,
        })
        .then(({ data }) => {
          // console.log(data.data);
          this.reservation_details = data.data;
          this.packageData = this.reservation_details.package;
          this.number_of_persons =
            this.reservation_details.package_guests.length;
          this.package_rate = parseInt(this.packageData.star_count);
          this.package_image = this.packageData.images[0]?.image;
          // console.log(typeof this.package_rate);
          // console.log(this.reservation_details);
          // console.log(this.packageData);
          this.loading = false;
        });
    },
  },
  components: {
    Skeleton,
  },

  mounted() {
    this.fetch_reservation_details();
  },
};
</script>

<style lang="scss" scoped>
@import "./_bookDetails";
</style>
