<template>
  <div
    class="container-fluid d-flex flex-column bg-white pt-5"
    style="min-height: 100vh"
  >
    <Button
      :label="$t('show')"
      class="m-3 PrimaryButton"
      style="margin-top: unset !important; width: 10%"
      @click="openDialog"
    />
    <Dialog
      maximizable
      dismissableMask
      v-model:visible="visible"
      modal
      :style="{ width: '60rem', height: '70vh' }"
    >
      <div
        class="d-flex align-items-center justify-content-center w-100 h-100 text-center"
      >
        <div v-if="whatsApp" class="w-75">
          <img
            :src="whatsApp"
            alt="QR Code"
            style="width: 80%; height: 50%; object-fit: contain"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import axios from "axios";

export default {
  data() {
    return {
      visible: false,
      whatsApp: null,
      qrCodeData: null,
    };
  },
  methods: {
    openDialog() {
      this.visible = true;
      this.fetchWatsapp();
    },
    fetchWatsapp() {
      axios
        .get("/scan_qr_code", { responseType: "arraybuffer" })
        .then((response) => {
          const base64 = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          this.whatsApp = `data:image/png;base64,${base64}`;
          // console.log(this.whatsApp);
        });
    },
  },
  components: {
    Dialog,
    Button,
  },
};
</script>

<style scoped>
.img-qr {
  width: 30%;
}
</style>
