<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4>
        {{ $t("clients_online") }}
      </h4>
    </div>

    <div class="search-user my-3">
      <form>
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('search...')"
          v-model="userName"
          @keyup="fetchAlluser((page = 1))"
        />
      </form>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table" id="userTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("user_name") }}</th>
              <th>{{ $t("email") }}</th>
              <th>{{ $t("phone") }}</th>
              <th>{{ $t("date") }}</th>
              <th>{{ $t("nationality") }}</th>
              <th>{{ $t("gender") }}</th>
              <th>{{ $t("date_of_birth") }}</th>
              <th>{{ $t("booking history") }}</th>
              <!-- <th>{{ $t("Control") }}</th> -->
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="user in 10" :key="user">
              <td v-for="user in 9" :key="user">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(user, index) in alluser.data" :key="index">
              <td>{{ user.id }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>
                {{ user.phone }}
              </td>
              <td>{{ user.created_at }}</td>
              <td>{{ user.nationality.nationality_title }}</td>
              <td>{{ user.name_title }}</td>
              <td>{{ user.birth_day }}</td>
              <td>
                <router-link
                  :to="`/clientHistory/${user.id}`"
                  class="btn btn-info mx-1 w-auto text-white"
                  title="View Booking History"
                  ><i class="fa-solid fa-eye"></i
                ></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="alluser"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchAlluser"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Skeleton from "primevue/skeleton";
export default {
  name: "b2cUsers",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      alluser: [],
      userName: "",
      loading: true,
    };
  },
  methods: {
    fetchAlluser(page = 1) {
      axios
        .post(`website/fetch_organization_clients?page=${page}`, {
          word: this.userName,
        })
        .then((res) => {
          this.alluser = res.data.data;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchAlluser();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_user.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-user {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
</style>
