<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("edit_company_flight") }}</h4>
    </div>
    <p v-for="(name, index) in names" :key="index">{{name.locale == "en" ? $t("english") : name.locale == "ar" ? $t("arabic") : $t("indonsia")}}: {{name.name}} </p>
    <form @submit.prevent="editCompany()">
      <div class="row">
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The Country") }}</label>
            <Multiselect
              v-model="countryValue"
              track-by="id"
              label="title"
              :options="countries"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="adress">{{ $t("code") }}</label>
            <input
              type="text"
              class="form-control"
              id="code"
              :placeholder="$t('code')"
              v-model="code"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("write_name") }}</label>
            <input
              type="text"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('write_name')"
              v-model="name_ar"
            />
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("tax_number") }} </label>
            <input
              type="number"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('tax_number')"
              v-model="tax_number"
            />
          </div>
        </div>

        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("disntaion_number") }} </label>
            <input
              type="number"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('disntaion_number')"
              v-model="disntaion_number"
            />
          </div>
        </div>
        <div class="col-md-6 mb-6">
          <img class="image" v-if="image" :src="image" />
          <!-- <input type="file" id="file" @change="imageUpload" multiple hidden /> -->
          <input
            type="file"
            id="file"
            @change="imageUpload($event)"
            multiple
            hidden
          />
          <label for="file" class="add_file"
            ><i class="fa-solid fa-upload"></i> {{ $t("edit_image") }}</label
          >
          <p>{{ this.imageCompanyName }}</p>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("edit") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Loading from "@/components/LottieFiles/loading.vue";

export default {
  name: "editCompany",
  components: {
    Multiselect,
    Loading,
  },
  data() {
    return {
      name_en: "",
      name_ar: "",
      name_id: "",
      code: "",
      image: "",
      countryValue: "",
      regularCountryValue: "", // For the regular country selection
      countries: [],
      cityValues: [],
      names: [],
      regularCityValues: [], // For the regular city selection
      cities: [],
      country_id: "",
      city_id: "",
      useCustomCitySelect: false,
      imageCompany: "",
      imageCompanyName: "",
      image_preview: "",
      tax_number: "",
      disntaion_number: "",
      loading: false,
    };
  },
  methods: {
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    // image
    imageUpload(event) {
      this.fileData = event.target.files[0];
      const reader = new FileReader();
      const name = this.imageCompany.name;
      reader.readAsDataURL(this.fileData);
      reader.onload = () => {
        this.imageCompanyName = name;
        this.image = reader.result;
      };
    },

    fetchCompany() {
      let id = this.$route.params.id;
      axios.get("/flight_companies/" + id).then(({ data }) => {
        this.name_ar = data.data.name;
        this.name_en = data.data.names[0].name;
        this.name_id = data.data.names[2].name;
        this.names = data.data.names;
        this.code = data.data.code;
        this.countryValue = data.data.country;
        this.cityValues = data.data.city;
        this.regularCityValues = data.data.city; // For regular city selection
        this.image = data.data.image;
        this.tax_number = data.data.tax_number;
        this.disntaion_number = data.data.disntaion_number;
        // console.log(this.name_ar);
      });
    },

    async editCompany() {
      let id = this.$route.params.id;
      const formData = new FormData();
      formData.append("name_ar", this.name_ar);
      formData.append("name_en", this.name_en);
      formData.append("name_id", this.name_id);
      formData.append("code", this.code);
      if (this.countryValue) {
        formData.append("country_id", this.countryValue.id);
      }

      formData.append("_method", "PUT");
      // params.append("city_id", this.cityValues.id);

      if (this.fileData instanceof File) {
        formData.append("image", this.fileData);
        // console.log(this.fileData);
        // myVariable is a file
      } else {
        // myVariable is not a file
      }
      this.loading = true; //the loading begin
      axios
        .post(`/flight_companies/${id}`, formData)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.loading = false; //the loading ended
          this.$router.push("/flight-company");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
  },
  mounted() {
    this.fetchCompany();
    this.fetch_country();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
}
</style>
