<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <h4>{{ $t("Visa Contract Seasons") }}</h4>

      <div>
        <router-link
          :to="`/visa/contract/${this.contract_id}/seasons/add`"
          class="PrimaryButton btn w-100"
          ><i class="fa-solid fa-plus"></i
          >{{ $t("Add new season") }}</router-link
        >
      </div>
    </div>

    <div class="search-visa my-3">
      <form>
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('search...')"
        />
      </form>
    </div>

    <div class="row">
      <table class="table" id="SeasonTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("type") }}</th>
            <th>{{ $t("show_start_date") }}</th>
            <th>{{ $t("show_end_date") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="season in 10" :key="season">
            <td v-for="season in 5" :key="season">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(season, index) in allSeason.data" :key="index">
            <td>{{ season.id }}</td>
            <td>{{ season.name }}</td>
            <td>{{ season.from }}</td>
            <td>{{ season.to }}</td>
            <td :data-label="$t('actions')">
              <router-link
                class="btn btn-success text-white mx-2 edit_btn"
                :title="$t('edit')"
                :to="`/visa/contract/${this.contract_id}/seasons/edit/${season.id}`"
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </router-link>

              <router-link
                class="btn btn-info text-dark mx-2 edit_btn"
                :title="$t('rate code')"
                :to="`/visa/contract/${contract_id}/seasons/${season.id}/allRateCode`"
              >
                <i class="fa-solid fa-water"></i>
              </router-link>

              <button
                class="btn btn-danger text-white mx-2 remove_btn"
                type="button"
                :title="$t('delete')"
                @click="deleteSeason(index)"
              >
                <i class="fa-regular fa-trash-can"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="allSeason"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchSeasons"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  name: "allSeasons",
  components: {
    pagination,
    Skeleton,
  },
  data() {
    return {
      contract_id: this.$route.params.id,
      allSeason: [],
      loading: true,
    };
  },
  methods: {
    fetchSeasons(page = 1) {
      axios
        .get(`/season_contract?contract_id=${this.contract_id}?page=${page}`)
        .then(({ data }) => {
          console.log(data);
          this.allSeason = data.data;
          this.loading = false;
        });
    },
    //delete contract
    deleteSeason(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.allSeason.data[index].id;
          const fromClick = true;
          axios.delete(`/season_contract/${delete_id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.allSeason.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.fetchSeasons();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
</style>
