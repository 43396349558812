<template>
  <section class="card_style">
    <div class="main_header">
      <h4>{{ $t("purchasesBillAdd") }}</h4>
    </div>

    <form @submit.prevent="addPurshaseBill()">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group mt-3">
            <label>{{ $t("title") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('title')"
              v-model="title"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group mt-3">
            <label>{{ $t("amount") }}</label>
            <input
              type="number"
              class="form-control"
              min="0"
              v-model="amount"
              :placeholder="$t('amount')"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group mt-3">
            <label>{{ $t("supplier") }}</label>
            <Multiselect
              v-model="suplierText"
              :options="supliers"
              label="name"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('supplier')"
              select-label=""
              :hide-selected="true"
            ></Multiselect>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group mt-3">
            <label>{{ $t("description") }}</label>
            <textarea
              rows="3"
              cols="40"
              type="number"
              class="form-control"
              resize
              :placeholder="$t('description')"
              v-model="report"
            ></textarea>
          </div>
        </div>
        <div class="col-lg-7 col-md-7 col-12 my-4">
          <div class="flex align-items-center justify-content-around mx-2">
            <RadioButton v-model="selectedOption" inputId="Bank" value="Bank" />
            <label for="Bank" class="mx-2 mb-0 label">{{
              $t("bank_account")
            }}</label>
            <RadioButton
              v-model="selectedOption"
              inputId="pocket"
              value="pocket"
            />
            <label for="pocket" class="mx-2 mb-0 label">{{ $t("Safe") }}</label>
          </div>
          <div class="flex mt-2">
            <Multiselect
              v-model="accountValue"
              :options="organizationAccounts"
              class="w-50"
              :placeholder="$t('account')"
              label="title"
              track-by="id"
              :clear-on-select="true"
              select-label=""
              @update:model-value="accountVal(accountValue)"
              :hide-selected="true"
              v-if="selectedOption === 'Bank'"
            >
            </Multiselect>
            <Multiselect
              v-model="safeValue"
              :options="organizationSafe"
              :placeholder="$t('safe')"
              class="w-50"
              label="title"
              track-by="id"
              :clear-on-select="true"
              select-label=""
              :hide-selected="true"
              @update:model-value="safeVal(safeValue)"
              v-if="selectedOption === 'pocket'"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-12">
          <button class="PrimaryButton btn" type="submit" v-if="isDataComplete">
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import { Multiselect } from "vue-multiselect";
import RadioButton from "primevue/radiobutton";
import Swal from "sweetalert2";

export default {
  name: "purchasesBillAdd",
  emits: ["updateAccountValue", "updateSafeValue"], // Declare the custom event here
  data() {
    return {
      supliers: [],
      suplierText: "",
      selectedOption: "Bank",
      bankIngredient: [],
      pocketIngredient: [],
      safeValue: "",
      accountValue: "",
      organizationAccounts: [],
      organizationSafe: [],
      amount: "",
      report: "",
      title: "",
    };
  },
  methods: {
    addPurshaseBill() {
      const formData = new FormData();
      formData.append("supplier_id", this.suplierText.id);
      formData.append("amount", this.amount);
      formData.append("report", this.report);
      formData.append("title", this.title);
      if (this.selectedOption === "Bank") {
        formData.append("account_id", this.accountValue.id);
      } else {
        formData.append("account_id", this.safeValue.id);
      }

      axios
        .post(`/store_purchases_bill`, formData)
        .then(({ data }) => {
          console.log(data);
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    // fetch suppliers data
    FetchSuppliers() {
      axios.post(`/fetch_consumer_by_role`, { role: 2 }).then(({ data }) => {
        this.supliers = data.data.data;
        // console.log(data.data);
      });
    },
    accountVal(accountValue) {
      //   this.accountValue = accountValue;
      this.$emit("updateAccountValue", accountValue);
    },
    safeVal(safeValue) {
      //   this.safeValue = safeValue;
      this.$emit("updateSafeValue", safeValue);
    },
    fetchOrganizationAccounts() {
      axios
        .post("/fetch_my_organization_accounts", { type: 1 })
        .then(({ data }) => {
          this.organizationAccounts = data.data.data;
          //   console.log(this.organizationAccounts);
        });
    },
    fetchOrganizationSafe() {
      axios
        .post("/fetch_my_organization_accounts", { type: 2 })
        .then(({ data }) => {
          this.organizationSafe = data.data.data;
          // console.log(this.organizationSafe);
        });
    },
  },
  components: {
    Multiselect,
    RadioButton,
  },

  mounted() {
    this.FetchSuppliers();
    this.fetchOrganizationAccounts();
    this.fetchOrganizationSafe();
  },
  computed: {
    isDataComplete() {
      return (
        this.suplierText !== "" &&
        this.selectedOption !== "" &&
        this.amount !== "" &&
        this.report !== "" &&
        this.title !== "" &&
        ((this.selectedOption === "Bank" && this.accountValue !== "") ||
          (this.selectedOption === "pocket" && this.safeValue !== ""))
      );
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  width: 1810px;
  height: 120px;
  resize: none;
}
.label {
  font-weight: bold;
  font-size: 1.2rem;
  color: #656b78;
}
</style>
