<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("edit_expness") }}</h4>
    </div>
    <form class="form_topic" @submit.prevent="editExpness()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("expness_name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('write_expness_name')"
              v-model="title"
              required
            />
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("write_notes") }}</label>
            <textarea
              class="form-control"
              :placeholder="$t('write_notes')"
              v-model="text"
              required
            ></textarea>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("edit") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "add-expness",
  data() {
    return {
      title: "",
      text: "",
    };
  },
  methods: {
    // fetch expness data
    fetchExpnessData() {
      let id = this.$route.params.id;
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/expenses_type/" + id).then(({ data }) => {
        this.title = data.data.title;
        this.text = data.data.text;

        // console.log(data.data);
      });
    },
    // add expness
    async editExpness() {
      let id = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("title", this.title);
      params.append("text", this.text);
      axios
        .put(`/expenses_type/${id}`, params)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/expenss");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    this.fetchExpnessData();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style lang="scss" scoped>
@import "./expness.scss";
</style>
