<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("Edit Country") }}</h4>
    </div>
    <div class="row">
      <form @submit.prevent="editCountry">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(country, index) in Form.title"
                      :key="country.lang"
                    >
                      <label :for="'name' + index">
                        {{ $t("title") }} ({{ country.lang }})
                      </label>
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="'name' + index"
                        v-model="country.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="editCountryCode">{{ $t("Country code") }}</label>
              <input
                type="text"
                class="form-control mt-2"
                id="editCountryCode"
                :placeholder="$t('Country code')"
                v-model="code"
              />
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="capitalCity">{{ $t("enter_capital_city") }}</label>
              <Multiselect
                v-model="city"
                :options="cities"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('enter_capital_city')"
                select-label=""
                :hide-selected="true"
              />
            </div>
          </div>
        </div>
        <button type="submit" class="btn PrimaryButton">
          {{ $t("edit") }}
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  name: "EditCountry",
  data() {
    return {
      countryId: this.$route.params.id,
      code: "",
      cities: [],
      languages: ["ar", "en", "id"],
      Form: {
        title: [],
      },
      city: null,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    setCountryTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({ lang: language, name: "" });
      });
    },
    fetchCountryData() {
      axios
        .get(`/countries/${this.countryId}`)
        .then(({ data }) => {
          this.Form.title = data.data.titles.map((ele) => ({
            lang: ele.locale,
            name: ele.title,
          }));
          this.code = data.data.code;
          this.city = data.data.capital_city;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: "Failed to fetch country data",
            icon: error,
          });
        });
    },
    fetchCities() {
      axios
        .post("/fetch_cities_by_country_id", { country_id: [this.countryId] })
        .then(({ data }) => {
          this.cities = data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: "Failed to fetch cities",
            icon: error,
          });
        });
    },
    editCountry() {
      // const titles = {};
      // this.Form.title.forEach((ele) => {
      //   titles[`title_${ele.lang}`] = ele.name;
      // });

      const params = new URLSearchParams();

      this.Form.title.forEach((ele) => {
        params.append(`title_${ele.lang}`, ele.name);
      });
      params.append(`code`, this.code);

      // params.append(`city_id`, this.city.id);

      axios
        .put(`/countries/${this.countryId}`, params)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/countries");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.setCountryTitle();
    this.fetchCountryData();
    this.fetchCities();
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
