<template>
  <section class="card_style">
    <div class="row">
      <div
        class="d-flex justify-content-between align-items-center gap-3 mb-2 col-12"
      >
        <div class="d-flex align-items-center">
          <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
          <h5 class="title mx-2">
            {{ $t("purchase_taxes_reports") }}
          </h5>
        </div>
        <div
          class="buttons d-flex align-items-center"
          v-if="reports.data?.length > 0"
        >
          <button
            v-if="contracts"
            class="btn SecondButton mx-2"
            @click="exportToExcel"
            title="Excel"
          >
            <i class="fa-solid fa-file-excel"></i>
          </button>
          <button
            class="btn SecondButton mx-2"
            v-print="'#printMe'"
            title="PDF"
          >
            <i class="fa-solid fa-file-pdf"></i>
          </button>
        </div>
      </div>
      <form @submit.prevent="fetchReports" class="col-12 row mb-2">
        <Datepicker
          v-model="checkIn"
          name="durationFrom"
          :placeholder="$t('From')"
          @input="UpdateModelCheckout"
          class="col-4"
        ></Datepicker>

        <Datepicker
          v-model="checkOut"
          name="durationTo  "
          :placeholder="$t('To')"
          :disabled-dates="{
            to: checkIn,
          }"
          class="col-4"
        ></Datepicker>

        <button type="submit" class="btn PrimaryButton col-4">
          <i class="fa-solid fa-circle-notch fa-spin" v-if="loading"></i>
          <span v-else> {{ $t("filter") }} </span>
        </button>
      </form>
      <div
        class="table-responsive"
        id="printMe"
        v-if="reports.data?.length > 0"
      >
        <HeaderPdf
          :title="$t('purchase_taxes_reports')"
          :toDate="checkOut"
          :fromDate="checkIn"
        />
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="no-print">{{ $t("id") }}</th>
              <th>{{ $t("amount") }}</th>
              <th>{{ $t("type") }}</th>
              <th>{{ $t("branch") }}</th>
              <th>{{ $t("created_at") }}</th>
              <th>{{ $t("created_by") }}</th>
              <th class="w-25">{{ $t("description") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading">
            <tr v-for="contract in 10" :key="contract.id">
              <td class="text-center" v-for="item in 7" :key="item">
                <Skeleton width="90%" height="2rem" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="contract in reports.data" :key="contract.id">
              <td class="no-print">{{ contract.id }}</td>
              <td>{{ contract.amount }}</td>
              <td>{{ contract.dailyable_type }}</td>
              <td>{{ contract.branch }}</td>
              <td>{{ contract.date }}</td>
              <td>{{ contract.employee }}</td>
              <td class="text-center">{{ contract.description }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <div class="d-flex align-items-center justify-content-center">
          <img
            src="@/assets/media/image/no-data.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <p class="text-center my-5 fs-3">{{ $t("there_is_no_data") }}</p>
      </div>
      <div class="row">
        <pagination
          :data="reports"
          class="mx-auto d-flex align-items-center justify-content-center pagination"
          @pagination-change-page="fetchReports"
        >
          <template v-slot:prev-nav>&lt;</template>
          <template v-slot:next-nav>&gt;</template>
        </pagination>
      </div>
    </div>
  </section>
</template>

<script>
import * as XLSX from "xlsx";
import moment from "moment";
import pagination from "laravel-vue-pagination";
import Skeleton from "primevue/skeleton";
import axios from "axios";
import Datepicker from "vuejs3-datepicker";
import HeaderPdf from "@/components/pdf/headerPdf.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
export default {
  name: "purchaseReports",
  data() {
    const date = new Date();
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const GetDay = new Date(date.getFullYear(), date.getMonth(), 1);

    return {
      reports: [],
      loading: true,
      checkIn: GetDay,
      checkOut: lastDayOfMonth,
    };
  },
  components: {
    pagination,
    HeaderPdf,
    AddFavDailog,
    Skeleton,
    Datepicker,
  },
  methods: {
    async fetchReports() {
      this.loading = true;
      const formData = new FormData();
      if (this.checkIn && this.checkOut) {
        formData.append("from", moment(this.checkIn).format("YYYY-MM-DD"));
        formData.append("to", moment(this.checkOut).format("YYYY-MM-DD"));
      }
      axios
        .post("fetch_daily_branch_tax_for_purchase", formData)
        .then(({ data }) => {
          this.reports = data.data;
          // console.log(this.reports);
          this.loading = false;
        });
    },
    async exportToExcel() {
      try {
        this.loading = true;

        const response = await axios.get(
          "/new_fetch_contract_report_upon_hotel"
        );
        const responseData = response.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "contractRoom.xlsx");
        this.loading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.loading = false;
        // Handle the error as needed, e.g., show a message to the user
      }
    },
  },
  mounted() {
    this.fetchReports();
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .no-print {
    display: none !important;
  }
  .table {
    thead {
      tr {
        th {
          padding-inline: 1.5rem !important;
        }
      }
    }
    tbody {
      tr {
        td {
          padding-inline: 1.5rem !important;
        }
      }
    }
  }
}
</style>
