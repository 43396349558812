<template>
  <section class="client_detials show_receipt card_style">
    <div class="container-fluid">
      <div class="head">
        <div class="return" v-if="loading && $route.params.id">
          <Skeleton width="20%" class="mb-2"></Skeleton>
        </div>
        <div class="return" v-else>
          <router-link to="/clients">
            <i class="fa-solid fa-arrow-right"></i>
            <h6>sssssss</h6>
          </router-link>
        </div>

        <div
          class="button d-flex align-items-center justify-content-around"
          v-if="loading && $route.params.id"
        >
          <Skeleton width="30%" height="2rem"></Skeleton>

          <Skeleton width="30%" height="2rem"></Skeleton>
        </div>

        <div class="button" v-else>
          <button
            v-if="consumer.status === 0"
            @click="BlockHotel(clientId)"
            class="danger PrimaryButton"
            type="button"
          >
            {{ $t("client_block") }}
          </button>

          <button
            v-else
            style="margin-inline-end: 3%"
            @click="BlockHotel(clientId)"
            class="PrimaryButton btn"
            type="button"
          >
            {{ $t("delete_block") }}
          </button>
          <router-link
            class="PrimaryButton"
            :to="`/suppliers/edit/${clientId}`"
            >{{ $t("modyfy_data") }}</router-link
          >
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 col-12">
          <div class="data" v-if="loading && $route.params.id">
            <div class="head_title">
              <Skeleton width="30%" height="2rem"></Skeleton>
            </div>
            <!-- <span> {{ $t("passpord_number") }} : 123456</span> -->
            <ul>
              <li><Skeleton width="30%" height="2rem"></Skeleton></li>
              <li><Skeleton width="30%" height="2rem"></Skeleton></li>
              <li><Skeleton width="30%" height="2rem"></Skeleton></li>
              <li><Skeleton width="30%" height="2rem"></Skeleton></li>
              <li><Skeleton width="30%" height="2rem"></Skeleton></li>
              <li><Skeleton width="30%" height="2rem"></Skeleton></li>
            </ul>
            <!-- <img
                src="@/assets/media/image/Mask-group.png"
                alt=""
                class="pass"
              /> -->
          </div>

          <div class="data row" v-else>
            <div class="head_title col-6 row">
              <img
                class="col-4"
                src="@/assets/media/image/user.png"
                alt="user"
              />
              <div class="col-8">
                <h4>{{ consumer.name }}</h4>
                <div class="nation" v-if="consumer.country">
                  <i class="fa-regular fa-flag"></i>
                  {{ consumer.country?.title }}
                </div>
              </div>
            </div>
            <!-- <span> {{ $t("passpord_number") }} : 123456</span> -->
            <ul class="col-6 row">
              <li
                class="col-6 px-0"
                v-if="consumer.phone !== '' && consumer.phone"
              >
                <i class="fa-solid fa-phone"></i> {{ consumer.phone }}
              </li>
              <li class="col-6 px-0">
                <i class="fa-solid fa-envelope"></i> {{ consumer.email }}
              </li>
              <li class="col-6 px-0">
                <i class="fa-solid fa-building"></i>
                {{
                  consumer.client_type == 1
                    ? $t("person")
                    : consumer.client_type == 2
                    ? $t("company")
                    : ""
                }}
              </li>
              <li class="col-6 px-0">
                <i class="fa-solid fa-cash-register"></i>
                <span>{{ $t("payment_method") }} : </span>
                {{
                  consumer.payment_method == 1
                    ? $t("cash")
                    : consumer.payment_method == 2
                    ? $t("pending_payment")
                    : consumer.payment_method == 3
                    ? $t("Pre_Payment")
                    : ""
                }}
              </li>
              <li class="col-6 px-0">
                <i class="fa-solid fa-id-card"></i>
                <span>{{ $t("Registration number") }} : </span>
                {{
                  consumer.registration_number == ""
                    ? $t("doesn't exist")
                    : consumer.registration_number
                }}
              </li>
              <li class="col-6 px-0">
                <i class="fa-solid fa-money-check"></i>
                <span>{{ $t("tax_number") }} : </span>
                {{
                  consumer.tax_number == ""
                    ? $t("doesn't exist")
                    : consumer.tax_number
                }}
              </li>
            </ul>
            <!-- <img
                src="@/assets/media/image/Mask-group.png"
                alt=""
                class="pass"
              /> -->
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <div v-if="loading && $route.params.id">
            <Skeleton width="100%" height="150px"></Skeleton>
          </div>
          <div class="d-flex justify-content-between align-items-center" v-else>
            <div
              class="total-balance d-flex align-items-center justify-content-between"
            >
              <img src="@/assets/media/image/Mask-group.png" />
              <img src="@/assets/media/image/Mask-group.png" />
              <img src="@/assets/media/image/Mask-group.png" />
              <div
                class="d-flex align-items-center justify-content-around w-100"
              >
                <div class="image">
                  <img src="@/assets/media/image/wallet.png" alt="wallet" />
                </div>
                <div class="text">
                  <p>{{ $t("Debtor") }}</p>
                  <p class="price">
                    {{
                      consumer.tree_branch?.balance > 0
                        ? consumer.tree_branch?.balance
                        : 0
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="total-balance d-flex align-items-center justify-content-between"
            >
              <img src="@/assets/media/image/Mask-group.png" />
              <img src="@/assets/media/image/Mask-group.png" />
              <img src="@/assets/media/image/Mask-group.png" />
              <div
                class="d-flex align-items-center justify-content-around w-100"
              >
                <div class="image">
                  <img src="@/assets/media/image/wallet.png" alt="wallet" />
                </div>
                <div class="text">
                  <p>{{ $t("Creditor") }}</p>
                  <p class="price">
                    {{
                      consumer.tree_branch?.balance < 0
                        ? consumer.tree_branch?.balance * -1
                        : 0
                    }}
                  </p>
                </div>
              </div>
              <div>
                <!-- <button class="transfer btn">{{ $t("transfer balance") }}</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="tabs">
    <tabs :loading="loading" />
  </section>
</template>

<script>
import tabs from "@/components/consumer/tabs.vue";
import axios from "axios";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  components: { tabs, Skeleton },
  name: "supliersDetails-page",
  data() {
    return {
      consumer: {
        country: {},
      },
      loading: true,
    };
  },

  methods: {
    FetchHotel() {
      let id = this.$route.params.id;
      axios.get("/consumers/" + id).then(({ data }) => {
        // console.log(data.data);
        this.consumer = data.data;
        this.loading = false;

        // console.log(this.consumer);
      });
    },

    BlockHotel(clientId) {
      if (this.consumer.status === 1) {
        // The client is currently blocked, so we need to unblock
        this.showConfirmationAndUnblock(clientId);
      } else {
        // The client is not blocked, so we need to block
        this.showConfirmationAndBlock(clientId);
      }
    },
    async showConfirmationAndBlock(clientId) {
      const result = await Swal.fire({
        title: this.$t("check_block_client"),
        text: this.$t("are_you_sure_block"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_block"),
      });

      if (result.isConfirmed) {
        const data = { consumer_id: clientId };
        axios.post("/block_consumer", data).then(({ data }) => {
          // console.log(data.data);
          this.consumer = data.data;
          if (data.status === true) {
            Swal.fire("", data.message, "success");
          } else {
            Swal.fire("", data.message, "error");
          }
        });
      }
    },
    async showConfirmationAndUnblock(clientId) {
      const result = await Swal.fire({
        title: this.$t("check_unblock_client"),
        text: this.$t("are_you_sure_unblock"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_unblock"),
      });

      if (result.isConfirmed) {
        const data = { consumer_id: clientId };
        axios.post("/block_consumer", data).then(({ data }) => {
          // console.log(data.data);
          this.consumer = data.data;
          if (data.status === true) {
            Swal.fire("", data.message, "success");
          } else {
            Swal.fire("", data.message, "error");
          }
        });
      }
    },
  },
  computed: {
    clientId() {
      return this.$route.params.id; // to pass the id from the url
    },
  },
  created() {
    this.FetchHotel();
    // this.BlockHotel();
  },
};
</script>

<style lang="scss" scoped>
.total-balance {
  background-color: #0b1427;
  color: white;
  padding: 20px;
  height: 143px;
  border-radius: 8px;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  margin: 0 1rem;

  .total-balance > div {
    position: relative;
    z-index: 2222;
  }

  .text {
    p {
      margin-bottom: 8px;

      &.price {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }

  .charge {
    background-color: #fff;
    border: 2px solid #0b1427;
    color: #0b1427;
    margin-inline-end: 15px;
  }

  .transfer {
    background-color: #0b1427;
    border: 2px solid #fff;
    color: #fff;
  }

  img:nth-child(1) {
    position: absolute;
    top: -55px;
    right: 0px;
  }

  img:nth-child(2) {
    position: absolute;
    top: 67%;
    right: 40%;
    transform: rotate(180deg);
  }

  img:nth-child(3) {
    position: absolute;
    left: -53px;
    transform: rotate(-90deg);
  }

  .image {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background-color: #3c4352;
    margin-inline-end: 15px;
    text-align: center;
    line-height: 62px;
    position: relative;

    img {
      position: absolute;
      top: 0.5rem;
      right: 0.6rem;
    }
  }
}

.client_detials {
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    .return {
      width: 40%;

      i {
        color: #656b78;
        font-size: 1.2rem;
        margin-inline-end: 2%;
      }

      h6 {
        display: inline-block;
        margin-bottom: 0;
        font-size: 16px;
        color: #656b78;
        font-family: "bold";
      }
    }
  }
}

.button {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  .PrimaryButton.danger {
    background-color: #e23535;
    border: 1px solid #e23535;
    margin-inline-end: 2%;
    border-radius: 8px;

    &:hover {
      color: #e23535;
      background-color: #fff;
    }
  }

  .PrimaryButton {
    border-radius: 8px;
  }
}

.data {
  .head_title {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 22%;
      //   height: 60px;
      object-fit: contain;
    }

    h4 {
      font-size: 24px;
      display: inline-block;
      margin-inline-start: 2%;
    }
  }

  ul {
    list-style: none;
    margin-inline-start: 0;
    margin-inline-end: 0;
    li {
      // display: inline-block;
      font-size: 16px;
      font-family: "light";
      margin: 14px 0;
      // margin: 17px 0;

      font-family: "regular";

      i {
        color: #0a909b;
        font-size: 1.2rem;
        margin-inline-end: 5px;
      }
    }
  }
}

.nation {
  background-color: rgba($color: #c57016, $alpha: 0.2);
  color: #c57016;
  //   width: 28%;
  text-align: center;
  border-radius: 5px;
  width: 90%;
}
</style>
