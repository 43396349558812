<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <h4>{{ $t("purchasesBill") }}</h4>

      <div>
        <router-link to="/purchases-bill/add" class="PrimaryButton btn w-100"
          ><i class="fa-solid fa-plus"></i
          >{{ $t("purchasesBillAdd") }}</router-link
        >
      </div>
    </div>

    <div class="search-visa my-3">
      <form>
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('search...')"
          v-model="purchasesBill"
          @keyup="fetchpurchasesBill(page = 1)"
        />
      </form>
    </div>

    <div class="row">
      <table class="table" id="SeasonTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("title") }}</th>
            <th>{{ $t("amount") }}</th>
            <th>{{ $t("report") }}</th>
            <th>{{ $t("account") }}</th>
            <th>{{ $t("supplier") }}</th>
            <th>{{ $t("action") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in purchasesBills.data" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.report }}</td>
            <td>{{ item.account?.title }}</td>
            <td>{{ item.supplier?.name }}</td>

            <td :data-label="$t('actions')">
              <router-link
                class="btn btn-info text-white edit_btn"
                :title="$t('details')"
                :to="`/purchases-bill/show/${item.id}`"
              >
                <i class="fa-solid fa-circle-exclamation"></i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="purchasesBills"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchpurchasesBill"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";

export default {
  name: "purchasesBill",
  components: {
    pagination,
  },
  data() {
    return {
      purchasesBills: [],
      purchasesBill: "",
    };
  },
  methods: {
    fetchpurchasesBill(page = 1) {
      axios
        .post(`/index_purchases_bill?page=${page}`, {
          word: this.purchasesBill,
          per_page: 10,
        })
        .then(({ data }) => {
          this.purchasesBills = data.data;
        });
    }
  },
  mounted() {
    this.fetchpurchasesBill();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
</style>
