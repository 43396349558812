<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <h4>{{ $t("Airlines") }}</h4>

      <!-- <div>
            <router-link to="/flights-companies" class="PrimaryButton btn w-100"
            ><i class="fa-solid fa-plus"></i>{{$t("add airline")}}</router-link
            >
      </div> -->
    </div>

    <div class="search-visa my-3">
      <form>
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('search...')"
        />
      </form>
    </div>

    <div class="row">
      <table class="table table-striped" id="companyTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("Take-off airport") }}</th>
            <th>{{ $t("Arrival airport") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(route, index) in allRoutes" :key="index">
            <td>{{ route.id }}</td>
            <td>{{ route.from_airport.title }}</td>
            <td>{{ route.to_airport.title }}</td>

            <td>
              <button
                class="btn btn-danger text-white"
                :title="$t('delete')"
                @click="deleteRoute(route.id, index)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div class="row">
      <pagination
        :data="allRoutes"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchFlightCompanyRoute"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div> -->
  </section>
</template>

<script>
import axios from "axios";
// import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";

export default {
  name: "flightCompanyRoutesIndex",
  //   components: {
  //     pagination,
  //   },
  data() {
    return {
      company_id: this.$route.params.id,
      allRoutes: [],
    };
  },
  methods: {
    fetchFlightCompanyRoute() {
      axios
        .get(`/flight_company_routes/${this.company_id}`)
        .then(({ data }) => {
          this.allRoutes = data.data.routes;
          console.log(this.allRoutes);
        });
    },
    //delete visa
    deleteRoute(route_id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          //   let delete_id = this.allRoutes[index].id;
          const newLocal = this.$i18n.locale;
          axios.defaults.headers.common["Accept-Language"] = newLocal;
          axios
            .post(`/delete_flight_company_route/${route_id}`)
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.allRoutes.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.fetchFlightCompanyRoute();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
td {
  .airport {
    padding: 10px;
    border-bottom: 1px solid #dcdcdc;
  }
}
</style>
