<script setup>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Skeleton from "primevue/skeleton";
import { onMounted, ref } from "vue";
const clients = ref([]);
const client = ref(null);
const invoices = ref([]);
const loader = ref(true);
const fetchClients = async () => {
  try {
    await axios
      .post("/fetch_consumer_by_role", { role: 1 })
      .then(({ data }) => {
        clients.value = data.data.data;
      });
  } catch (error) {
    console.log(error);
  }
};
const fetchInvoices = async () => {
  try {
    await axios.post("/indexBaseBookingTax").then(({ data }) => {
      invoices.value = data.data;
    });
    loader.value = false;
  } catch (error) {
    console.log(error);
    loader.value = false;
  }
};
const getSum = (bookings, field) => {
  return bookings
    .reduce((total, booking) => total + (booking[field] || 0), 0)
    .toFixed(2);
};
onMounted(async () => {
  await fetchInvoices();
});
</script>

<template>
  <div class="card_style">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
      <h3 class="mb-0">{{ $t("collected_invoices") }}</h3>
    </div>
    <div class="row my-4">
      <div class="col-12 col-lg-8">
        <label class="form-label">{{ $t("client") }}</label>
        <Multiselect
          v-model="client"
          :options="clients"
          label="name"
          track-by="id"
          :clear-on-select="true"
          :placeholder="$t('choose')"
          select-label=""
          @open="fetchClients"
          :hide-selected="true"
        >
        </Multiselect>
      </div>
      <div class="col-4 d-flex justify-content-end align-items-end">
        <button class="btn PrimaryButton w-100" @click="fetchInvoices">
          {{ $t("submit") }}
        </button>
      </div>
      <hr class="mt-2" />
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("id") }}</th>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("date") }}</th>
            <th>{{ $t("total_selling") }}</th>
            <th>{{ $t("tax") }}</th>
            <th>{{ $t("total") }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody v-if="loader">
          <tr v-for="i in 10" :key="i">
            <td v-for="l in 7" :key="l" class="text-center">
              <Skeleton height="2rem" width="80%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="invoice in invoices" :key="invoice">
            <td>
              {{ invoice.base_booking_id }}
            </td>
            <td>{{ invoice.client.name ? invoice.client.name : "-" }}</td>
            <td>{{ invoice.invoice_date }}</td>
            <td>{{ getSum(invoice.invoice_bookings, "selling_tax") }}</td>
            <td>
              {{
                getSum(invoice.invoice_bookings, "total_room_selling_with_tax")
              }}
            </td>
            <td>
              {{
                getSum(
                  invoice.invoice_bookings,
                  "total_room_selling_with_out_tax",
                )
              }}
            </td>
            <td>
              <router-link
                class="btn btn-outline-info"
                :to="`/collected-invoice/${invoice.id}`"
              >
                <i class="fa-solid fa-file-invoice"></i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
