<template>
  <section class="show_receipt card_style">
    <div class="main_header_style">
      <h4 v-if="seasons.data.length">
        {{ $t("seasons") }} / {{ seasons.data[0].contract }} /
        {{ seasons.data[0].hotel_name }}
      </h4>

      <router-link :to="{ name: 'addSeason' }" class="PrimaryButton btn"
        ><i class="fa-solid fa-plus"></i> {{ $t("new_season") }}</router-link
      >
    </div>
    <div class="row">
      <table class="table" id="seasonTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("contract") }}</th>
            <th>{{ $t("type") }}</th>
            <th>{{ $t("show_start_date") }}</th>
            <th>{{ $t("show_end_date") }}</th>
            <th>{{ $t("settings") }}</th>
            <th>{{ $t("action") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center table_loader">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 7" :key="item">
              <Skeleton width="90%" height="2rem" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) in seasons.data" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.contract }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.start_date }}</td>
            <td>{{ item.end_date }}</td>
            <td>
              <router-link
                v-if="item.is_code_rate == 0"
                class="add_rate_code"
                :to="`/contract/${$route.params.id}/seasons/${item.id}/rate-code/`"
                >{{ $t("add_code_rate") }}</router-link
              >
              <div
                class="dropdown"
                v-if="item.is_code_rate == 1"
                @click="FetchRateCode(item.id)"
              >
                <button
                  class="PrimaryButton btn btndropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ $t("price_setting") }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <router-link
                      class="add_rate_code dropdown-item"
                      :to="`/contract/${$route.params.id}/seasons/${item.id}/rate-code/`"
                      >{{ $t("add_code_rate") }}</router-link
                    >
                  </li>
                  <li v-for="(rateCode, index) in rateCodes" :key="index">
                    <router-link
                      class="dropdown-item"
                      :to="`/contract/${$route.params.id}/seasons/${item.id}/rate-code/${rateCode.id}`"
                      >{{ rateCode.name }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </td>

            <td>
              <router-link
                class="btn btn-success text-white mx-1 edit_btn"
                :to="`/contract/${$route.params.id}/seasons/edit/${item.id}`"
                :title="$t('edit')"
              >
                <i class="fa-solid fa-pen"></i>
              </router-link>

              <button
                class="btn btn-danger text-white mx-1 remove_btn"
                @click="DeleteAlert(index)"
                :title="$t('delete_season')"
              >
                <i class="fa-solid fa-trash" :title="$t('delete')"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="seasons"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="Fetchseasons"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  name: "show-season",
  components: {
    pagination,
    Skeleton,
  },
  data() {
    return {
      seasons: {
        data: [],
      },
      codeRate: false,
      rateCodes: [],
      loading: true,
    };
  },
  methods: {
    // fetch get_season_rate_code_from_season_id
    FetchRateCode(seasonId) {
      let season_id = { season_id: seasonId };
      axios
        .post("/get_season_rate_code_from_season_id", season_id)
        .then(({ data }) => {
          this.rateCodes = data.data;
          // console.log(this.rateCodes)
        });
    },

    Fetchseasons(page = 1) {
      let id = { contract_id: this.$route.params.id };
      axios
        .post(`/get_season_from_contract?page=${page}`, id)
        .then(({ data }) => {
          this.seasons = data.data;
          this.loading = false;
          // console.log(data.data);
        });
    },
    //delete seasons
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.seasons.data[index].id;
          const fromClick = true;
          axios.delete("/seasons/" + `${delete_id}`, { fromClick }).then(({ data }) => {
            // console.log(result);
            // console.log(delete_id);
            if (data.status === true) {
              Swal.fire("", data.message, "success");
              this.seasons.data.splice(index, 1); // Remove the deleted item from the array
              // console.log(data);
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.Fetchseasons();
  },
  updated() {},
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style lang="scss" scoped>
@import "./_season.scss";
.PrimaryButton {
  padding: 0.75rem 1.5rem;
  font-size: 0.6rem;
}
.dropdown {
  display: inline-block;
  margin-inline-end: 0.5rem;
}
.table .dropdown .dropdown-item {
  font-size: 0.8rem;
}
.add_rate_code {
  display: inline-block;
  color: #0a909b;
  text-decoration: underline;
  margin-inline-end: 0.5rem;
  &.dropdown-item {
    text-decoration: none;
  }
}
.dataTables_wrapper .dataTables_filter {
  width: 50% !important;
}
</style>
