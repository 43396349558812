<template>
  <div>
    <div class="table">
      <div class="row" v-if="reports.length > 0">
        <div class="col-12 col-lg-12 d-flex justify-content-end">
          <button class="PrimaryButton btn mb-2" v-print="'#printMe'">
            {{ $t("Print") }} <i class="fa-solid fa-print ms-2"></i>
          </button>
        </div>
      </div>
      <div id="printMe">
        <HeaderPdf
          :title="this.$route.params.type"
          :fromDate="$route.params.type == 'arrivals' ? fromDay : checkIn"
          :toDate="$route.params.type == 'arrivals' ? '' : checkOut"
          :hotels="hotels"
        />
        <h4 class="text-center d-none">{{ type }}</h4>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="no-print">{{ $t("#") }}</th>
              <th>{{ $t("Reservation") }}</th>
              <th>{{ $t("H.Rsv") }}</th>
              <th>{{ $t("Hotel") }}</th>
              <th>{{ $t("Guest Name") }}</th>
              <th>{{ $t("Client Name") }}</th>
              <th>{{ $t("From") }}</th>
              <th>{{ $t("Till") }}</th>
              <th>{{ $t("status") }}</th>
              <th>{{ $t("Rooms Type") }}</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in reports" :key="index">
              <td class="text-center no-print">
                {{ index + 1 }}
              </td>
              <td class="text-center">{{ item?.book_number }}</td>
              <td class="text-center">
                {{ item?.hotel_reference ? item.hotel_reference : "-" }}
              </td>
              <td class="text-center">{{ item?.hotel?.title }}</td>
              <td class="text-center">
                {{
                  item?.guest_name != (null || undefined || "")
                    ? item.guest_name
                    : "-"
                }}
              </td>
              <td class="text-center">
                {{
                  item?.supplier != (null || undefined || "")
                    ? item.supplier?.supplier_name
                    : "-"
                }}
              </td>
              <td class="text-center">{{ item?.check_in }}</td>
              <td class="text-center">{{ item?.check_out }}</td>
              <td class="text-center">
                {{
                  item?.is_show == 0
                    ? $t("not_arrive")
                    : item?.is_show == 1
                      ? $t("arrive")
                      : $t("dont_have")
                }}
              </td>
              <td class="text-center">
                <div
                  :class="[
                    'd-flex ',
                    'align-items-center',
                    'justify-content-center',
                    'mb-1',
                    'gap-2',
                  ]"
                >
                  <p class="mb-0">{{ item?.room_name }}</p>
                  <p class="mb-0" v-tooltip.top="$t('room_number')">
                    {{ item?.room_number }}
                  </p>
                </div>
              </td>
              <td
                class="text-center action"
                v-if="item.is_show !== 1 && item.is_show !== 0"
              >
                <div
                  :class="[
                    'd-flex ',
                    'align-items-center',
                    'justify-content-center',
                    'mb-1',
                    'gap-2',
                  ]"
                >
                  <span
                    :class="['btn', 'mt-0', 'fs-6', 'btn-danger']"
                    v-tooltip.top="$t('not_arrive')"
                    @click="noShowRoom(item, 0)"
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </span>

                  <span
                    :class="['btn', 'mt-0', 'fs-6', 'btn-success']"
                    v-tooltip.top="$t('arrive')"
                    @click="noShowRoom(item, 1)"
                  >
                    <i class="fa-solid fa-check"></i>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <h4 class="text-center d-none" id="content">
        {{ this.$route.parmas.type }}
      </h4> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

import HeaderPdf from "@/components/pdf/headerPdf.vue";
export default {
  props: [
    "reports",
    "loading",
    "checkIn",
    "checkOut",
    "hotels",
    "fromDay",
    "fetchDuringPeriodFilters",
  ],
  emits: ["roomAdded"],
  name: "reportTable",
  data() {
    return {};
  },
  components: {
    HeaderPdf,
  },
  methods: {
    noShowRoom(room, status) {
      axios
        .post("/update_show_offline_booking_room", {
          offline_booking_room_id: room.id,
          is_show: status,
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data.data.is_show == 1) {
            Swal.fire({
              icon: "success",
              text: this.$t("client has been Arrived"),
              timer: 2000,
            });
            this.fetchDuringPeriodFilters();
          } else if (res.data.data.is_show == 0) {
            Swal.fire({
              icon: "error",
              text: this.$t("client has been Not Arrived"),
              timer: 2000,
            });
            this.fetchDuringPeriodFilters();
          }
          this.$emit("roomAdded");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .no-print {
    display: none !important;
  }
  .table {
    thead {
      tr {
        th {
          padding-inline: 1.5rem !important;
        }
      }
    }
    tbody {
      tr {
        td {
          padding-inline: 1.5rem !important;
        }
      }
    }
  }
}
</style>
