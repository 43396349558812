<script setup>
import Multiselect from "vue-multiselect";
import axios from "axios";
import { ref, computed, watch, defineEmits, defineProps } from "vue";
import { useRoute } from "vue-router";
import Datepicker from "vuejs3-datepicker";
import { useI18n } from "vue-i18n";
import moment from "moment";
import Swal from "sweetalert2";
const i18n = useI18n();

const emit = defineEmits(["fetchReservations"]);
const route = useRoute();
const suppliers = ref([]);
const clients = ref([]);
const clientValue = ref(null);
const supplierValue = ref(null);
const hotels = ref([]);
const hotelValue = ref(null);
const fromDate = ref(null);
const toDate = ref(null);
const translatedTitles = computed(() => {
  return [
    { title: i18n.t("pending"), id: 0 },
    { title: i18n.t("tentative"), id: 1 },
    { title: i18n.t("confirmed"), id: 2 },
    { title: i18n.t("canceled"), id: 3 },
  ];
});
const props = defineProps({
  reservationIds: {
    type: Array,
  },
});
watch(translatedTitles, async (oldValue, newValue) => {
  console.log(oldValue, "old");
  statues.value = oldValue;
  console.log(newValue, "new");
});

const statues = ref([]);
// console.log(statues.value);
const status = ref(null);
// const date = ref(null);
// const dateTo = ref(null);
// const dateFrom = ref(null);

const fetchSupplires = (role) => {
  axios.post("/fetch_consumer_by_role", { role: role }).then(({ data }) => {
    if (role === 2) {
      suppliers.value = data.data.data;
    } else if (role === 1) {
      clients.value = data.data.data;
    }
  });
};
const searchHotel = (event) => {
  const searchText = { word: event?.target?.value };
  if (event?.target?.value.length) {
    axios
      .post(`/fetch_new_home_hotels`, searchText)
      .then((res) => (hotels.value = res.data.data.hotels))
      .catch((err) => console.log(err));
  } else {
    axios
      .post(`/fetch_new_home_hotels`)
      .then((res) => (hotels.value = res.data.data.hotels))
      .catch((err) => console.log(err));
  }
};
const formatHotelLabel = (hotel) => {
  return `${hotel.title} -
              ${hotel.city_title}, ${hotel.country_title} (${hotel.id})`;
};
const fetchReservations = () => {
  const formData = new FormData();
  formData.append("client_id", clientValue.value.id);
  formData.append("hotel_id", hotelValue.value.id);
  formData.append("from", moment(fromDate.value).format("YYYY-MM-DD"));
  formData.append("to", moment(toDate.value).format("YYYY-MM-DD"));
  formData.append("supplier_id", supplierValue.value.id);
  formData.append("status", status.value.id);
  // // formData.append("date", date.value);
  // // formData.append("date_to", dateTo.value);
  // // formData.append("date_from", dateFrom.value);
  emit("fetchReservations", formData);
};
const multipleReservations = () => {
  const formData = new FormData();
  props.reservationIds.forEach((id, index) =>
    formData.append(`offline_booking_hotel_ids[${index}]`, id),
  );
  if (route.path === "/confirm-multiReservation") {
    formData.append("status", 2);
  } else {
    formData.append("status", 1);
  }
  axios
    .post("/changeAllOfflineBookingHotelLockStatus", formData)
    .then(({ data }) => {
      Swal.fire({
        title: "success",
        text: data.message,
        icon: "success",
      });
      emit("fetchReservationEndPoint");
    })
    .catch((err) => {
      Swal.fire({
        title: "error",
        text: err.response.data.message,
        icon: "error",
      });
    });
};
console.log(route.path);
</script>

<template>
  <form @submit.prevent="fetchReservations" class="row">
    <div class="col-3">
      <label for="client" class="form-label">{{ $t("client") }}</label>
      <Multiselect
        v-model="clientValue"
        :options="clients"
        label="name"
        track-by="id"
        :clear-on-select="true"
        :placeholder="$t('choose')"
        select-label=""
        :hide-selected="true"
        @open="fetchSupplires(1)"
      >
      </Multiselect>
    </div>
    <div class="col-3">
      <label for="hotels" class="form-label">{{ $t("hotels") }}</label>
      <Multiselect
        v-model="hotelValue"
        :options="hotels"
        label="title"
        track-by="id"
        :clear-on-select="true"
        :placeholder="$t('Choose_hotel')"
        select-label=""
        :hide-selected="true"
        :custom-label="formatHotelLabel"
        @keyup="searchHotel"
      >
      </Multiselect>
    </div>
    <div class="col-3">
      <label for="supplier" class="form-label">{{ $t("supplier") }}</label>
      <Multiselect
        v-model="supplierValue"
        :options="suppliers"
        label="name"
        track-by="id"
        :clear-on-select="true"
        :placeholder="$t('choose')"
        select-label=""
        :hide-selected="true"
        @open="fetchSupplires(2)"
      >
      </Multiselect>
    </div>
    <div class="col-3">
      <label for="date" class="form-label">{{ $t("status") }}</label>
      <Multiselect
        v-model="status"
        :options="statues"
        :clear-on-select="true"
        label="title"
        track-by="id"
        :placeholder="$t('choose')"
        select-label=""
        :hide-selected="true"
      >
      </Multiselect>
    </div>
    <div class="col-3">
      <label for="date" class="form-label">{{ $t("from") }}</label>
      <Datepicker
        v-model="fromDate"
        :not-after="toDate"
        name="from"
        :placeholder="$t('From')"
      />
    </div>
    <div class="col-3">
      <label for="date" class="form-label">{{ $t("to") }}</label>
      <Datepicker
        v-model="toDate"
        name="to"
        :placeholder="$t('To')"
        :from="fromDate"
      />
    </div>
    <div class="col-6 d-flex gap-2 justify-content-end align-items-end">
      <button class="btn PrimaryButton" type="submit" @click="filter">
        {{ $t("filter") }}
      </button>

      <button
        class="btn PrimaryButton"
        type="button"
        @click="multipleReservations"
      >
        {{ $t("confirm multiple reservations") }}
      </button>
    </div>
  </form>
</template>

<style scoped lang="scss"></style>
