<template>
  <div class="AddPoints p-3" style="height: 60dvh">
    <h5 class="title">{{ $t("Add Points") }}</h5>
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-12">
          <div class="form-floating mb-3 w-auto">
            <input
              type="number"
              class="form-control"
              id="floatingInput"
              placeholder="Book points"
              v-model="book_points"
              min="0"
            />
            <label for="floatingInput">{{ $t("Book Points") }}</label>
          </div>
        </div>
        <div class="col-lg-5 col-md-6 col-12">
          <div class="form-floating w-auto">
            <input
              type="number"
              class="form-control"
              id="floatingPassword"
              :placeholder="$t('expired_date')"
              v-model="expired_days"
              min="0"
            />
            <label for="floatingPassword">{{ $t("expired_date") }}</label>
          </div>
        </div>
        <div class="col-lg-2">
          <button @click="sendPoints()" class="PrimaryButton btn w-100">
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      book_points: 0,
      expired_days: 0,
    };
  },
  methods: {
    sendPoints() {
      const formData = new FormData();
      formData.append("book_points", this.book_points);
      formData.append("expired_days", this.expired_days);
      if (this.$route.params.id !== undefined) {
        axios.post("/package_book_points", formData).then(({ data }) => {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
        });
      } else {
        axios.post("/package_book_points", formData).then(({ data }) => {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
        });
      }
    },
  },
  mounted() {
    axios.get("/package_book_points").then(({ data }) => {
      this.expired_days = data.data.expired_days;
      this.book_points = data.data.book_points;
    });
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #000;
  font-size: 1.3rem;
  font-family: "bold";
}
</style>
