<template>
  <section class="show_receipt card_style">
    <div class="row options mb-2">
      <div class="d-flex align-items-center gap-3 mb-3">
        <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
        <h4>{{ $t("Private hotels") }}</h4>
      </div>

      <form class="col-lg-8">
        <input
          class="sear rounded-3 w-100 border-1"
          type="text"
          :placeholder="$t('search by name')"
          v-model="titlenation"
          @keyup="FetchNation"
        />
      </form>

      <!-- <div class="col-lg-4">
        <router-link
          :to="{ name: 'organizationHotelsAdd' }"
          class="PrimaryButton btn w-50"
          ><i class="fa-solid fa-plus"></i> {{ $t("add") }}</router-link
        >
      </div> -->
    </div>
    <div class="row">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("name_en") }}</th>
            <th>{{ $t("status") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 4" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="item in nationalities.data" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.title }}</td>
            <td>
              {{ item.is_chose == 0 ? $t("unselected") : $t("selected") }}
            </td>

            <td>
              <router-link
                :to="`hotel/details/${item.id}`"
                class="btn btn-primary mx-1"
              >
                <i class="fa fa-solid fa-eye"></i
              ></router-link>
              <button
                class="btn btn-success text-white mx-1"
                v-if="item.is_chose == 0"
                @click="cahngeStatus(item.id)"
              >
                <i class="fa-solid fa-add" :title="$t('change_status')"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="nationalities"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchNation"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
// import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
export default {
  name: "show-nation",
  components: {
    pagination,
    Skeleton,
    AddFavDailog,
  },
  data() {
    return {
      nationalities: [],
      titlenation: "",
      loading: true,
    };
  },
  methods: {
    FetchNation(page = 1) {
      const search = { word: this.titlenation };
      axios
        .post(`/fetch_organization_hotels_in_city?page=${page}`, search)
        .then(({ data }) => {
          this.nationalities = data.data;
          this.loading = false;
        });
    },
    cahngeStatus(id) {
      const organizationHotelIds = [id]; // Wrap id in an array
      // console.log(organizationHotelIds); // Log as array
      axios
        .post("/store_organization_hotels", {
          organization_hotel_ids: organizationHotelIds,
        })
        .then(() => {
          this.FetchNation();
        });
    },
  },
  mounted() {
    this.FetchNation();
  },
};
</script>
