<template>
  <section class="form_organization">
    <div class="main_header">
      <h4>{{ $t("add_new_company") }}</h4>
      <p>{{ $t("add_new_company_parg") }}</p>
    </div>
    <form @submit.prevent="submitOrganization">
      <div class="row">
        <!-- start input group -->
        <div class="col-12">
          <label>{{ $t("company logo") }}</label>
          <div class="image_upload">
            <label for="imageEntry" class="upload_img" v-if="imageEntry == ''">
              <img :src="image ? image : defaultImage" alt="user" />
            </label>
            <img
              :src="imageEntry"
              v-else-if="imageEntry"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntry"
              class="form-control"
              @change="onFileChange"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
          <span class="input-errors" v-if="v$.fileData.$error">{{
            $t("upload_error_message")
          }}</span>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("company_name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('company_name')"
              v-model="name"
              required
            />
            <span class="input-errors" v-if="v$.name.$error">{{
              v$.name.$errors[0].$message
            }}</span>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <!-- <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("company_logo_name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('company_logo_name')"
              v-model="logo_name"
              required
            />
          </div>
        </div> -->
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("phone") }}</label>
            <input
              type="tel"
              class="form-control xyz"
              :placeholder="$t('enter_phone')"
              v-model="phone"
              required
            />
            <span class="icon_form">
              <i class="fas fa-phone"></i>
            </span>
            <span class="input-errors" v-if="v$.phone.$error">{{
              v$.phone.$errors[0].$message
            }}</span>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("email") }}</label>
            <input
              type="email"
              class="form-control xyz"
              :placeholder="$t('enter_email')"
              v-model="email"
              required
            />
            <span class="icon_form">
              <i class="fa-regular fa-envelope"></i>
            </span>
            <span class="input-errors" v-if="v$.email.$error"
              >{{ v$.email.$errors[0].$message }}
            </span>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group mb-1">
            <label class="form-label"> {{ $t("country") }}</label>
            <Multiselect
              v-model="countryChoose"
              :options="countries.map((country) => country.id)"
              :custom-label="(opt) => countries.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
              @update:model-value="FetchCityFromCountry"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.countryChoose.$error">{{
              v$.countryChoose.$errors[0].$message
            }}</span>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group mb-1">
            <label class="form-label"> {{ $t("city") }}</label>
            <Multiselect
              v-model="cityChoose"
              :options="cities.map((city) => city.id)"
              :custom-label="(opt) => cities.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('enter_city')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
          <span class="input-errors" v-if="v$.cityChoose.$error"
            >{{ v$.cityChoose.$errors[0].$message }}
          </span>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group mb-1">
            <label class="form-label"> {{ $t("Allowed hotel cities") }}</label>

            <Multiselect
              v-model="multiCity"
              :options="multiCities"
              :clear-on-select="true"
              label="title"
              track-by="id"
              :placeholder="$t('enter_city')"
              select-label=""
              :multiple="true"
              :hide-selected="true"
              @search-change="search_city"
            >
            </Multiselect>
          </div>
          <span class="input-errors" v-if="v$.multiCity.$error"
            >{{ v$.multiCity.$errors[0].$message }}
          </span>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group mb-1">
            <label class="form-label" for="tax_number">
              {{ $t("tax_number") }}</label
            >
            <input
              type="number"
              class="form-control"
              id="tax_number"
              min="0"
              :placeholder="$t('tax_number')"
              v-model="tax_number"
            />
          </div>
          <span class="input-errors" v-if="v$.tax_number.$error"
            >{{ v$.tax_number.$errors[0].$message }}
          </span>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group mb-1">
            <label class="form-label" for="disntaion_number">
              {{ $t("commercial_registration_number") }}</label
            >
            <input
              type="number"
              class="form-control"
              min="0"
              id="disntaion_number"
              :placeholder="$t('commercial_registration_number')"
              v-model="commercial_registration_number"
            />
          </div>
          <span
            class="input-errors"
            v-if="v$.commercial_registration_number.$error"
            >{{ v$.commercial_registration_number.$errors[0].$message }}
          </span>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group mb-1">
            <label class="form-label" for="lat"> lat </label>
            <input
              type="number"
              class="form-control"
              id="lat"
              min="0"
              placeholder="lat"
              v-model="lat"
            />
            <span class="input-errors" v-if="v$.lat.$error"
              >{{ v$.lat.$errors[0].$message }}
            </span>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group mb-1">
            <label class="form-label" for="long"> long </label>
            <input
              type="number"
              class="form-control"
              id="long"
              min="0"
              placeholder="long"
              v-model="long"
            />
            <span class="input-errors" v-if="v$.long.$error"
              >{{ v$.long.$errors[0].$message }}
            </span>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("adress") }}</label>
            <textarea
              class="form-control"
              :placeholder="$t('enter_adress')"
              v-model="message"
            ></textarea>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group mb-1">
            <label class="form-label"> {{ $t("link") }}</label>
            <input type="url" v-model="website_link" class="form-control" />
          </div>
          <span class="input-errors" v-if="v$.website_link.$error"
            >{{ v$.website_link.$errors[0].$message }}
          </span>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("Company Business") }}</label>
            <Multiselect
              v-model="company_service_ids"
              :options="Business.map((busines) => busines.id)"
              :custom-label="(opt) => Business.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('Company Business')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.company_service_ids.$error"
              >{{ v$.company_service_ids.$errors[0].$message }}
            </span>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("apps") }}</label>
            <Multiselect
              v-model="company_app_ids"
              :options="Apps.map((app) => app.id)"
              :custom-label="(opt) => Apps.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('apps')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.company_app_ids.$error"
              >{{ v$.company_app_ids.$errors[0].$message }}
            </span>
          </div>
        </div>

        <div class="col-12 col-lg-2 mt-3">
          <div class="input-group">
            <label class="form-label"> {{ $t("only_portal") }}</label>
            <div class="">
              <Checkbox v-model="checked" :binary="true" />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-2 mt-3">
          <div class="input-group">
            <label class="form-label"> {{ $t("deal as hotel") }}</label>
            <div class="">
              <Checkbox v-model="checked_hotel" :binary="true" />
            </div>
          </div>
        </div>
        <!-- finish input group -->
        <h4 class="title">{{ $t("admin_data") }}</h4>
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("admin_name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('admin_name')"
              v-model="employee_name"
              required
            />
            <span class="input-errors" v-if="v$.employee_name.$error"
              >{{ v$.employee_name.$errors[0].$message }}
            </span>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("phone") }}</label>
            <input
              type="tel"
              class="form-control xyz"
              :placeholder="$t('enter_phone')"
              v-model="employee_phone"
              required
            />
            <span class="icon_form">
              <i class="fas fa-phone"></i>
            </span>
          </div>
          <span class="input-errors" v-if="v$.employee_phone.$error"
            >{{ v$.employee_phone.$errors[0].$message }}
          </span>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("email") }}</label>
            <input
              type="email"
              class="form-control xyz"
              :placeholder="$t('enter_email')"
              v-model="employee_email"
              required
            />
            <span class="icon_form">
              <i class="fa-regular fa-envelope"></i>
            </span>
          </div>
          <span class="input-errors" v-if="v$.employee_email.$error"
            >{{ v$.employee_email.$errors[0].$message }}
          </span>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <!-- <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("slalry") }}</label>
            <input
              type="number"
              class="form-control xyz"
              :placeholder="$t('enter_salary')"
              v-model="employee_salary"
              required
            />
            <span class="icon_form">
              <i class="fa-solid fa-money-bill"></i>
            </span>
          </div>
          <span
              class="input-errors"
              v-if="v$.employee_salary.$error"
              >{{ v$.employee_salary.$errors[0].$message }}
            </span>

        </div> -->
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("password") }}</label>
            <input
              type="password"
              class="form-control xyz"
              :placeholder="$t('password')"
              v-model="employee_password"
              required
            />
            <span class="icon_form">
              <i class="fa-solid fa-lock"></i>
            </span>
          </div>
          <span class="input-errors" v-if="v$.employee_password.$error"
            >{{ v$.employee_password.$errors[0].$message }}
          </span>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("add") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

import Checkbox from "primevue/checkbox";

export default {
  name: "organization-form",
  components: {
    Multiselect,
    Checkbox,
  },
  data() {
    return {
      checked: false,
      v$: useVuelidate(),
      name: "",
      phone: "",
      email: "",
      countryChoose: "",
      cityChoose: "",
      message: "",
      lat: 55,
      long: 55,
      website_link: "",
      employee_name: "",
      employee_phone: "",
      employee_email: "",
      employee_salary: 0,
      employee_password: "",
      countries: [],
      cities: [],
      tax_number: "",
      commercial_registration_number: "",
      fileData: null,
      imageEntry: "",
      defaultImage: require("@/assets/media/image/user.png"),

      Business: [
        {
          id: 1,
          title: this.$t("The Hotels"),
        },
        {
          id: 2,
          title: this.$t("flight"),
        },
        {
          id: 3,
          title: this.$t("transportation"),
        },
        {
          id: 4,
          title: this.$t("affiliate"),
        },
        {
          id: 5,
          title: this.$t("package"),
        },
        {
          id: 9,
          title: this.$t("services"),
        },
        {
          id: 10,
          title: this.$t("travel_package"),
        },
      ],
      Apps: [
        {
          id: 1,
          title: this.$t("SYSTEM"),
        },
        {
          id: 2,
          title: this.$t("WEBSITE"),
        },
        {
          id: 3,
          title: this.$t("APP"),
        },
      ],
      company_business: "",
      company_service_ids: [],
      company_app_ids: [],
      multiCities: [],
      multiCity: "",

      checked_hotel: false,
    };
  },
  validations() {
    return {
      // image: { required },
      name: { required },
      phone: { required },
      email: { required, email },
      countryChoose: { required },
      cityChoose: { required },
      tax_number: { required },
      commercial_registration_number: { required },
      lat: { required },
      long: { required },
      // message: { required },
      website_link: { required },
      company_service_ids: { required },
      company_app_ids: { required },
      employee_name: { required },
      employee_phone: { required },
      employee_email: { required, email },
      employee_salary: { required },
      employee_password: { required },
      fileData: { required },
      multiCity: { required },
    };
  },
  methods: {
    //fetch country
    fetch_country() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
        // console.log(this.services);
      });
    },
    //fetch city with country
    FetchCityFromCountry(value) {
      this.cities = [];
      this.cityChoose = "";
      let basic_country_ids = {
        country_id: [value],
      };
      axios
        .post("/fetch_cities_by_country_id", basic_country_ids)
        .then(({ data }) => {
          this.cities = data.data;
        });
    },
    search_city(search) {
      if (search.length > 2) {
        axios.post("/search_city", { title: search }).then(({ data }) => {
          this.multiCities = data.data.data;
        });
      }
    },

    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    submitOrganization() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("is_portal", this.checked ? 1 : 0);
        formData.append("is_hotel", this.checked_hotel ? 1 : 0);
        formData.append("phone", this.phone);
        formData.append("email", this.email);
        formData.append("address", this.message);
        formData.append("website_link", this.website_link);
        formData.append("country_id", this.countryChoose);
        formData.append("city_id", this.cityChoose);
        formData.append("lat", this.lat);
        formData.append("long", this.long);
        formData.append("employee_name", this.employee_name);
        formData.append("employee_phone", this.employee_phone);
        formData.append("employee_email", this.employee_email);
        formData.append("employee_salary", this.employee_salary);
        formData.append("employee_password", this.employee_password);
        formData.append(
          "commercial_registration_number",
          this.commercial_registration_number
        );
        formData.append("tax_number", this.tax_number);
        formData.append("image", this.fileData);

        this.company_service_ids?.forEach((company_service, index) => {
          formData.append(`service_ids[${index}]`, company_service);
        });

        this.company_app_ids?.forEach((company_app, index) => {
          formData.append(`app_ids[${index}]`, company_app);
        });

        this.multiCity.forEach((city, index) => {
          formData.append(`organization_city_ids[${index}]`, city.id);
        });

        axios
          .post("/organizations", formData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.$router.push("/organization-tree");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },
  created() {
    this.fetch_country();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./organization.scss";

.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;

  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;

    img {
      width: 100%;
      height: 150px;
    }
  }
}
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
