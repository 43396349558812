<template>
  <section class="add_recipt card_style">
    <div class="main_header_style">
      <div class="d-flex">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>{{ $t("add_new_exchange") }}</h4>
      </div>
    </div>
    <form class="" @submit.prevent="addExchange()">
      <div class="row">
        <!-- start input group -->
        <div class="col-lg-6 col-md-12 col-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("construction_date") }}</label>
            <datepicker
              v-model="created_at"
              name="from_date"
              :placeholder="$t('from')"
            ></datepicker>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-6 col-md-12 col-12">
          <div class="form-group">
            <label class="form-label"> {{ $t("value_recipt") }}</label>
            <input
              type="number"
              class="form-control"
              :placeholder="$t('enter_value_recipt')"
              v-model="amount"
              min="0"
              required
            />
          </div>
          <span class="input-errors" v-if="v$.amount.$error"
            >{{ v$.amount.$errors[0].$message }}
          </span>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-6 col-md-12 col-12">
          <div class="input-group mb-1">
            <label class="form-label">{{ $t("choose_payment_method") }}</label>
            <Multiselect
              v-model="paidValues"
              :options="paids.map((paid) => paid.id)"
              :custom-label="(opt) => paids.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose_payment_method')"
              select-label=""
              :hide-selected="true"
              @update:model-value="FetchAccounts(paidValues)"
            >
            </Multiselect>
          </div>
          <span class="input-errors" v-if="v$.paidValues.$error"
            >{{ v$.paidValues.$errors[0].$message }}
          </span>
        </div>
        <!-- finish input group -->

        <div class="col-lg-6 col-md-12 col-12">
          <label class="form-label">{{ $t("accounts") }}</label>
          <Multiselect
            v-model="accountValue"
            :options="accounts.map((account) => account.id)"
            :custom-label="(opt) => accounts.find((x) => x.id == opt).title_ar"
            :clear-on-select="true"
            :placeholder="$t('accounts')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
          <span class="input-errors" v-if="v$.accountValue.$error"
            >{{ v$.accountValue.$errors[0].$message }}
          </span>
        </div>

        <!-- start input group -->
        <div class="col-lg-6 col-md-12 col-12">
          <div class="d-flex w-100 gap-2">
            <div class="form-group mb-1 w-100">
              <div class="d-flex gap-4 align-items-center my-2">
                <label class="form-label mb-0">
                  {{ $t("exchange_money") }}</label
                >
                <div
                  class="d-flex align-items-center justify-content-center gap-3"
                >
                  <InputSwitch v-model="checked" />
                  <label class="form-label mb-0">{{
                    $t("suppliers for")
                  }}</label>
                </div>
              </div>
              <Multiselect
                v-if="checked"
                v-model="expense_type_id"
                :options="expness"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose_exchange_money')"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
              <Multiselect
                v-else
                v-model="expense_type_id"
                :options="expness"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('suppliers for')"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
            </div>
            <span class="input-errors" v-if="v$.expense_type_id.$error"
              >{{ v$.expense_type_id.$errors[0].$message }}
            </span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6" v-if="!checked">
          <div class="form-group">
            <label class="form-label" for="Passport">{{
              $t("invoices")
            }}</label>
            <Multiselect
              v-model="Invoice"
              :options="Invoices"
              label="title"
              track-by="id"
              :custom-label="invoicesLabel"
              :clear-on-select="true"
              :placeholder="$t('invoices')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6 mb-3">
            <div class="form-group">
              <label class="form-label"> {{ $t("statement") }}</label>
              <textarea
                class="form-control"
                :placeholder="$t('add_statement')"
                v-model="report"
                rows="4"
              ></textarea>
            </div>
          </div>

          <div class="col-12 col-lg-6 mb-3">
            <div class="form-group">
              <label class="form-label" for="Passport">{{
                $t("attachment")
              }}</label>
              <div class="file-upload-form">
                <label
                  for="files"
                  class="form-control d-flex align-items-center justify-content-center w-100"
                >
                  <i class="fa-solid fa-upload p-1"></i>
                  <div class="texts">
                    <p class="title mb-2">{{ $t("drag_image") }}</p>
                    {{
                      imageName == "" ? $t("upload_image_prompt") : imageName
                    }}
                  </div>
                </label>
                <input
                  type="file"
                  ref="file"
                  id="files"
                  hidden
                  @change="previewImagePassport"
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="my-2 flex justify-center align-items-center">
              <Checkbox v-model="checked_expense" :binary="true" />
              <label class="font-bold mx-2">
                {{ $t("Banking expenses") }}</label
              >
            </div>
            <div class="col-lg-6 my-2" v-if="checked_expense">
              <div class="form-group"></div>
              <label class="form-label" for="Passport">{{
                $t("amount")
              }}</label>
              <input
                type="number"
                min="0"
                class="form-control"
                v-model="expenses_amont"
              />
            </div>
          </div>
        </div>

        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("save") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import useVuelidate from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";

import Checkbox from "primevue/checkbox";

import InputSwitch from "primevue/inputswitch";

export default {
  name: "add-recipt",
  components: {
    Multiselect,
    AddFavDailog,
    Datepicker,
    InputSwitch,
    Checkbox,
  },
  data() {
    return {
      expenses_amont: "",
      checked_expense: false,
      v$: useVuelidate(),
      theremane: this.$t("the remane"),
      checked: true,
      imageName: null,
      selectedFileName: "",
      selectedValue: "2",
      accounts: [],
      expness: [],
      amount: "",
      report: "",
      accountValue: "",
      account_id: null,
      image: "",
      created_at: new Date().toISOString().substr(0, 10), // Set default date to today
      moneyAccount: "",
      expense_type_id: "",
      image_preview: "",
      hasChild: [],
      selectedBranchId: null,
      selectedBranchData: [],
      selectedExpense: null,
      selectedSave: null,
      hasSave: [],
      selectedSaveId: null,
      selectedSaveData: [],
      selectedSaveIds: null,
      paids: [
        {
          id: 1,
          title: this.$t("treasury"),
        },
        {
          id: 2,
          title: this.$t("bank"),
        },
        {
          id: 3,
          title: this.$t("ewalllets"),
        },
        {
          id: 4,
          title: this.$t("checks_under_collection"),
        },
      ],
      paidValues: "",
      Invoices: [],
      Invoice: "",
    };
  },
  validations() {
    return {
      amount: { required, minValue: minValue(0) },
      // report: { required },
      accountValue: { required },
      expense_type_id: { required },
      // imageName: { required },
      created_at: { required },
      paidValues: { required },
      // Invoice: { required },
    };
  },
  methods: {
    previewImagePassport(event) {
      const file = event.target.files[0];
      this.imageName = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.image_preview = file;
        this.image_preview = reader.result;
        // console.log(this.Form.passport_image)
      };
    },
    invoicesLabel(value) {
      return `${value.title} - (${value.serial_number_booking}) -(${this.theremane} ${value.client_remaining_amount})`;
    },
    getLatestBranch() {
      axios.get(`/get_latest_branch`).then(({ data }) => {
        this.expness = data.data;
        //  console.log(this.expness)
      });
    },
    fetchCounsumer() {
      axios
        .post(`/fetch_consumer_branches`, {
          role: 2,
        })
        .then(({ data }) => {
          // console.log(data.data.data, "oooo");
          this.expness = data.data.data;
        });
    },
    fetch_Invoice() {
      axios
        .post("/fetch_consumer_main_invoices", {
          consumer_id: this.expense_type_id.consumer_id,
        })
        .then(({ data }) => {
          // console.log(data.data, "wee");
          this.Invoices = data.data;
        });
    },
    FetchAccounts(account_id) {
      let type = { payment_method: account_id };
      axios.post(`/fetch_payment_method`, type).then(({ data }) => {
        this.accounts = data.data;
        this.accountValue = "";
      });
    },

    async addExchange() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const Data = {
          is_expenses_bank: this.checked_expense ? 1 : 0,
          expenses_amount: this.checked_expense ? this.expenses_amont : 0,
          type: 3,
          text: this.report,
          image: this.image_preview,
          date: moment(this.created_at).format("YYYY-MM-DD"),
          from: [
            {
              branch_id: this.expense_type_id.id,
              amount: this.amount,
            },
          ],
          to: [
            {
              branch_id: this.accountValue,
              amount: this.amount,
            },
          ],
        };

        // Check if this.Invoice exists and is not empty
        if (this.Invoice && this.Invoice.length > 0) {
          Data.invoices_ids = this.Invoice.map((invoice) => invoice.id);
        }

        axios
          .post("/daily_restriction", Data)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.$router.push("/exchange");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },

  watch: {
    checked(newValue) {
      if (newValue) {
        this.getLatestBranch();
      } else {
        this.fetchCounsumer();
      }
    },
    expense_type_id: {
      handler(newValue) {
        if (newValue.consumer_id) {
          this.fetch_Invoice();
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.getLatestBranch();
  },

  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_exchange.scss";
</style>
