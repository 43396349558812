<template>
  <div class="contract_table">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
      <h5 class="title">
        {{ $t("contracts") }}
      </h5>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <input
        type="text "
        v-model="contractText"
        @keyup="FetchContract((page = 1))"
        class="form-control mb-2 w-50"
        :placeholder="$t('search...')"
      />
      <router-link to="/contract/add" class="btn SecondButton">{{
        $t("add_contract")
      }}</router-link>
    </div>
    <div class="row my-2">
      <div class="table-responsive">
        <table class="table" id="contracts">
          <thead>
            <tr>
              <th scope="col">{{ $t("ID") }}</th>
              <th scope="col">{{ $t("contract_name") }}</th>
              <th scope="col">{{ $t("hotel_name") }}</th>
              <th scope="col">{{ $t("supplier_name") }}</th>
              <th scope="col">{{ $t("period_from_to") }}</th>
              <th scope="col">{{ $t("number of rooms") }}</th>
              <th scope="col">{{ $t("type_contract") }}</th>
              <th scope="col">{{ $t("contract_weekends") }}</th>
              <th scope="col">{{ $t("publish") }}</th>
              <th scope="col">{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="contract in 10" :key="contract">
              <td v-for="contract in 10" :key="contract">
                <Skeleton height="2rem" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(contract, index) in contracts.data" :key="index">
              <td :data-label="$t('ID')">{{ contract.id }}</td>
              <td :data-label="$t('contract_name')">{{ contract.name }}</td>
              <td :data-label="$t('hotel_name')">{{ contract.hotel.name }}</td>
              <td :data-label="$t('the_company')">
                {{
                  contract?.contract_supplier_type != "HOTEL_DIRECT"
                    ? contract?.supplier?.name
                    : contract?.hotel?.name
                }}
              </td>
              <td :data-label="$t('period_from_to')">
                {{ contract.from }} - {{ contract.to }}
              </td>
              <td :data-label="$t('number of rooms')">
                {{ contract.room_number }}
              </td>
              <td :data-label="$t('type_contract')">
                <span
                  >{{ contract.share_b2b == 1 ? "b2b" : ""
                  }}<span
                    class="dash"
                    v-if="contract.share_b2b == 1 && contract.share_b2c == 1"
                  >
                    -
                  </span></span
                >
                {{ contract.share_b2c == 1 ? "b2c" : "" }}
              </td>
              <td :data-label="$t('contract_weekends')">
                <div class="weedends">
                  <span
                    class="weekend"
                    v-for="(weekend, index) in contract.contract_weekends"
                    :key="index"
                    >{{
                      weekend.day_id == 6
                        ? $t("saturday")
                        : weekend.day_id == 0
                        ? $t("sunday")
                        : weekend.day_id == 1
                        ? $t("monday")
                        : weekend.day_id == 2
                        ? $t("tuesday")
                        : weekend.day_id == 3
                        ? $t("wenday")
                        : weekend.day_id == 4
                        ? $t("thursday")
                        : weekend.day_id == 5
                        ? $t("friday")
                        : ""
                    }}
                    <span class="dash">-</span>
                  </span>
                </div>
              </td>
              <td :data-label="$t('publish')">
                {{
                  contract.publish == "PUBLISH"
                    ? $t("published")
                    : $t("unpublished")
                }}
              </td>
              <td :data-label="$t('actions')">
                <router-link
                  class="btn btn-success text-white mx-1 edit_btn"
                  :to="`/contract/${contract.id}/edit`"
                  v-tooltip.top="$t('edit')"
                >
                  <i class="fa-solid fa-pen-to-square"></i>
                </router-link>

                <router-link
                  class="btn btn-warning text-white mx-1 edit_btn"
                  :to="`/days-stock/${contract.id}`"
                  v-tooltip.top="$t('days-stock')"
                >
                  <i class="fa-solid fa-store"></i>
                </router-link>

                <router-link
                  class="btn btn-info text-white mx-1 edit_btn"
                  v-tooltip.top="$t('seasons')"
                  :to="`/contract/${contract.id}/seasons/`"
                >
                  <i class="fa-solid fa-water"></i>
                </router-link>

                <button
                  class="btn btn-danger text-white mx-1 remove_btn"
                  type="button"
                  v-tooltip.top="$t('delete')"
                  @click="DeleteAlert(index)"
                >
                  <i class="fa-regular fa-trash-can"></i>
                </button>

                <router-link
                  class="btn btn-warning text-white mx-1 edit_btn"
                  v-tooltip.top="$t('reservations')"
                  :to="`/contract/${contract.id}/reservations`"
                >
                  <i class="fa-solid fa-receipt"></i>
                </router-link>
                <updateMoneyDialog
                  :contract_id="contract.id"
                  :total="contract.total"
                />
                <AddtionalMoneyDialog
                  :contract_id="contract.id"
                  :exrt="contract.extra_prices"
                  :total="contract.total"
                  :contracst="FetchContract"
                />
                <button
                  @click="publish(contract)"
                  class="btn btn-warning"
                  v-if="contract.publish != 'PUBLISH'"
                >
                  <i class="fa-solid fa-bullhorn"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="contracts"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchContract"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </div>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
import updateMoneyDialog from "@/components/contract/updateMoneyDialog.vue";
import AddtionalMoneyDialog from "@/components/contract/AddtionalMoneyDialog.vue";

export default {
  name: "table-customization",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
    updateMoneyDialog,
    AddtionalMoneyDialog,
  },
  data() {
    return {
      contracts: [],
      contractText: "",
      loading: true,
    };
  },
  methods: {
    publish(contract) {
      axios
        .post("/change_publish_contract", {
          contract_id: contract.id,
          publish: 1,
        })
        .then((res) => {
          Swal.fire({
            title: res.data.message,
            icon: "success",
            confirmButtonText: this.$t("ok"),
          });
          contract.publish = "PUBLISH";
        });
    },
    // fetch Customizations table
    FetchContract(page = 1) {
      axios
        .post(`/index_contracts?page=${page}`, { word: this.contractText })
        .then(({ data }) => {
          this.contracts = data.data;
          this.loading = false;
          //  console.log(this.contracts)
        });
    },
    //delete Customizations
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.contracts.data[index].id;
          const fromClick = true;
          axios
            .delete(`/contracts/${delete_id}`, { fromClick })
            .then(({ data }) => {
              // console.log(result);
              // console.log(delete_id);
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.contracts.data.splice(index, 1); // Remove the deleted item from the array
                // console.log(data);
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.FetchContract();
  },
};
</script>

<style lang="scss" scoped>
@import "./_contract.scss";
</style>
