<template>
  <section class="card_style">
    <div class="main_header_style flex-column">
      <h4>{{ $t("Edit Room") }}</h4>
      <p>{{ $t("enter this data to continue") }}</p>
    </div>

    <form @submit.prevent="editRoom()">
      <div class="row">
        <!-- types room -->
        <!-- <ul class="types">
          <li v-for="(type, index) in service_types" :key="index">
            <div class="form-group service_type">
              <input
                type="radio"
                name="service_type"
                class="wizard-required"
                v-model="selectedServiceType"
                :value="type.value"
                :id="'default' + type.value"
              />
              <label :for="'default' + type.value">
                {{ $t(getTypeName(type.value)) }}
              </label>
            </div>
          </li>
        </ul> -->
        <!-- types room -->
        <!-- room name ar -->
        <div class="col-lg-6 mb-4">
          <label for="name">{{ $t("room name") }} ar</label>
          <input
            type="text"
            :placeholder="$t('room name')"
            id="name"
            v-model="title_ar"
          />
        </div>

        <!-- room name en -->
        <div class="col-lg-6 mb-4">
          <label for="name">{{ $t("room name") }} en</label>
          <input
            type="text"
            :placeholder="$t('room name')"
            id="name"
            v-model="title_en"
          />
        </div>
        <div class="col-lg-6 mb-4">
          <label for="name">{{ $t("room name") }} id</label>
          <input
            type="text"
            :placeholder="$t('room name')"
            id="name"
            v-model="title_id"
          />
        </div>

        <div class="col-lg-6 mb-4">
          <label for="adult">{{ $t("adult number") }}</label>
          <input
            type="number"
            id="adult"
            placeholder="1"
            v-model="number_of_adults"
          />
        </div>

        <div class="col-lg-6 mb-4">
          <label for="children">{{ $t("children number") }}</label>
          <input
            type="number"
            id="children"
            placeholder="1"
            v-model="number_of_children"
          />
        </div>

        <!-- <div class="col-lg-6 mb-4">
          <label>{{$t("bed type")}}</label>
          <Multiselect
              v-model="bed_type_id"
              track-by="id"
              label="title"
              :options="bedsType"
              :clear-on-select="true"
              :placeholder="$t('choose bed type')"
              select-label=""
              :hide-selected="true"
          >
          </Multiselect>
        </div> -->
        <div class="col-lg-6 mb-4">
          <label for="bedsNumber">{{ $t("beds number") }}</label>
          <input
            type="number"
            id="bedsNumber"
            placeholder="1"
            v-model="number_of_beds"
          />
        </div>

        <!-- <div class="col-lg-6 mb-4">
          <label>{{$t("view_type")}}</label>
          <Multiselect
              v-model="bed_type_id"
              track-by="id"
              label="title"
              :options="viewsType"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
          >
          </Multiselect>
        </div> -->

        <!-- <div class="col-lg-6 mb-4">
          <label>{{$t("hotels")}}</label>
          <Multiselect
              v-model="hotel_id"
              track-by="id"
              label="title"
              :options="hotels"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
          >
          </Multiselect>
        </div> -->

        <div class="col-lg-6 mb-4">
          <label for="area">{{ $t("room_area") }}</label>
          <input type="number" v-model="room_size" />
        </div>
        <div class="col-lg-6 mb-4">
          <label for="type">{{ $t("type") }}</label>
          <div class="d-flex align-items-center checkboxs">
            <div class="flex align-items-center" v-for="(service_type, index) in service_types" :key="index">
              <RadioButton
                v-model="type"
                :inputId="index"
                name="Hotel"
                :value="service_type.value"
              />
              <label :for="index" class="mb-0"> {{service_type.title}} </label>
            </div>
            <!-- <div class="flex align-items-center">
              <RadioButton
                v-model="type"
                inputId="ingredient2"
                name="Package"
                value="2"
              />
              <label for="ingredient2" class="mb-0"> Package </label>
            </div>
            <div class="flex align-items-center">
              <RadioButton
                v-model="type"
                inputId="ingredient2"
                name="Both"
                checked
                value="3"
              />
              <label for="ingredient2" class="mb-0"> Both </label>
            </div> -->
          </div>
        </div>
      </div>
      <button type="submit" class="add save btn">{{ $t("edit") }}</button>
    </form>
  </section>
</template>

<script>
// import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import RadioButton from "primevue/radiobutton";

export default {
  name: "editRooms",
  components: {
    // Multiselect ,
    RadioButton,
  },
  data() {
    return {
      roomId: this.$route.params.roon_id,
      title_ar: "",
      title_en: "",
      title_id: "",
      number_of_adults: 1,
      number_of_children: 1,
      type: null,
      number_of_beds: 1,
      room_size: "",
      hotelId: this.$route.params.hotel_id,
      bedsType: [],
      viewsType: [],
      hotels: [],
      service_types: [{ value: 1, title: "Hotel" }, { value: 2, title: "Package" }, { value: 3, title: "Both" }],
      selectedServiceType: null,
    };
  },
  methods: {
    // getTypeName(value) {
    //   // Define mappings of value to type names
    //   const nameMap = {
    //     1: "rooms",
    //     2: "sweets",
    //     // ... add more mappings as needed ...
    //   };
    //   return nameMap[value];
    // },
    fetchData() {
      axios.get(`/rooms/${this.roomId}`).then(({ data }) => {
        // console.log(data.data)
        this.title_ar = data.data.title_ar;
        this.title_en = data.data.title_en;
        this.title_id = data.data.title_id;
        this.type = data.data.service_type;
        this.number_of_adults = data.data.number_of_adults;
        this.number_of_children = data.data.number_of_children;
        this.selectedServiceType = data.data.room_type_id;
        this.room_size = data.data.room_size;
        // console.log(data.data);
        // console.log(this.bed_type_id)
      });
    },
    editRoom() {
      // console.log(this.bed_type_id)
      axios
        .put(`/rooms/${this.roomId}`, {
          title_ar: this.title_ar,
          title_en: this.title_en,
          title_id: this.title_id,
          number_of_adults: this.number_of_adults,
          number_of_children: this.number_of_children,
          number_of_beds: this.number_of_beds,
          hotel_id: this.hotelId,
          room_size: this.room_size,
          room_type_id: this.selectedServiceType,
          service_type: this.type,
          // bed_type_id: this.bed_type_id.id,
        })
        .then(({ data }) => {
          // console.log(data)
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });

          this.$router.push(`/room`);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    // this.fetchBedTypeData();
    this.fetchData();
    // this.fetchViewType();
    // this.fetchHotel();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "./_room";
form {
  label {
    display: inline-block;
    margin-bottom: 10px;
    font-family: "bold" !important;
  }
  input,
  select {
    display: block;
    width: 100%;
    // height: 68px;
    border-radius: 4px;
    border: 1px solid #9ca2ac;
    color: #9ca2ac;
    padding: 10px 15px;
  }
}
</style>
