<template>
  <div
    class="offcanvas offcanvas-bottom"
    tabindex="-1"
    :id="`offcanvasBottom${flight.id}`"
    aria-labelledby="offcanvasBottomLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasBottomLabel">
        {{ $t("Flight Details") }}
      </h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body small">
      <div class="details_title mb-5">
        <h5 class="title">
          <span class="to">{{ flight.round_trips[flight.round_trips.length - 1].arrive.airport_title == null ? flight.round_trips[flight.round_trips.length - 1].arrive.airport : flight.round_trips[flight.round_trips.length - 1].arrive.airport_title == "" ? flight.round_trips[flight.round_trips.length - 1].arrive.airport : flight.round_trips[flight.round_trips.length - 1].arrive.airport_title }}</span>
          <!-- <i class="fa-solid fa-plane-departure mx-3"></i> -->
          <!-- <i class="fa-solid fa-plane mx-3"></i> -->
          <div class="line"> <img src="../../assets/media/image/icon/flight_icon.png" alt="flight_icon"> </div>
          <span class="from">
            {{ flight.round_trips[0].launch.airport_title == null ? flight.round_trips[0].launch.airport : flight.round_trips[0].launch.airport_title == "" ? flight.round_trips[0].launch.airport : flight.round_trips[0].launch.airport_title }}
          </span>
        </h5>
        <div class="details d-flex align-items-center">
          <div class="time">
            <i class="fa-regular fa-clock"></i>
            <span> {{ calculateTimeDifference() }} {{ $t("hours") }}</span>
          </div>
          <div class="date mx-5">
            <i class="fa-solid fa-calendar-days"></i>
            <span>{{ flight.round_trips[0].launch.date }} </span>
          </div>
        </div>
      </div>
      <div
        class="row flight_direction"
        v-for="(item, indexx) in flight.round_trips"
        :key="indexx"
      >
        <div class="col-lg-2">
          <div class="image d-flex justify-content-end align-items-center flex-column w-50">
            <img :src="flight.company.image == null ? require('@/assets/media/image/placeholder.png') : flight.company.image" alt="airport" />
            {{flight.company.name}}
          </div>
        </div>
        <div class="col-lg-9">
          <div class="row align-items-center text-center">
            <div class="col-lg-3">
              <h5 class="text-center justify-content-center">{{ item.launch.time }}</h5>
              <p class="airport">
                {{
                  item.launch.airport_title == null
                    ? item.launch.airport
                    : item.launch.airport_title == ""
                    ? item.launch.airport
                    : item.launch.airport_title
                }}
              </p>
            </div>
            <div class="col-lg-6">
              <div class="time_taken p-0">
                {{ calculateTimeArrayDifference(item.launch.date, item.launch.time, item.arrive.date, item.arrive.time) }}
                <div class="flight_trip_icon">
                  <hr class="auto_end">
                  <i class="fa-solid fa-plane mx-3"></i>
                  <hr>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <h5 class="text-center justify-content-center">{{ item.arrive.time }}</h5>
              <p class="airport">
                {{
                  item.arrive.airport_title == null
                    ? item.arrive.airport
                    : item.arrive.airport_title == ""
                    ? item.arrive.airport
                    : item.arrive.airport_title
                }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="bags_section">
        <h5>{{ $t("travel loggag") }}</h5>
        <div class="row">
          <div class="col-lg-3" v-for="(bag, index) in flight.BaggageAllowance" :key="index">
            <div class="d-flex align-items-center gap-3">
              <i class="fa-solid fa-check"></i>
              <div class="">
                <p class="title">{{bag.bags}} {{$t("back_bag")}}</p>
                <span>1 {{ $t("bag") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 information">
          <div class="title">تفاصيل تهمك للالغاء والتعديل</div>
          <ul>
            <li>
              <i class="fa-regular fa-circle-check"></i>
              <p>
                لطلبات الإلغاء أو التعديل، سيتم تطبيق رسوم من قِبل المسافر التي
                تتراوح من SAR 35 أو أكثر لكل مسافر لما يصل إلى 4 ركاب (باستثناء
                الأطفال الرضع) ، حسب نوع الرحلة ومصدر الطلب.
              </p>
            </li>
            <li>
              <i class="fa-regular fa-circle-check"></i>
              <p>
                لطلبات الإلغاء أو التعديل، سيتم تطبيق رسوم من قِبل المسافر التي
                تتراوح من SAR 35 أو أكثر لكل مسافر لما يصل إلى 4 ركاب (باستثناء
                الأطفال الرضع) ، حسب نوع الرحلة ومصدر الطلب.
              </p>
            </li>
            <li>
              <i class="fa-regular fa-circle-check"></i>
              <p>
                لطلبات الإلغاء أو التعديل، سيتم تطبيق رسوم من قِبل المسافر التي
                تتراوح من SAR 35 أو أكثر لكل مسافر لما يصل إلى 4 ركاب (باستثناء
                الأطفال الرضع) ، حسب نوع الرحلة ومصدر الطلب.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "flightDetails",
  props: {
    flight: Object,
  },
  methods: {
    calculateTimeArrayDifference(launchDate, launchTime, arriveDate, arriveTime) {
      const launchDateTime = new Date(`${launchDate}T${launchTime}`);
      const arriveDateTime = new Date(`${arriveDate}T${arriveTime}`);

      if (launchDateTime > arriveDateTime) {
        // Adjust date if launch time is on a different day
        arriveDateTime.setDate(launchDateTime.getDate());
      }

      const timeDifference = arriveDateTime - launchDateTime;

      const hoursDiff = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutesDiff = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

      return `${hoursDiff.toString().padStart(2, '0')}:${minutesDiff.toString().padStart(2, '0')}`;
    },

    calculateTimeDifference() {
      const startTime = this.flight.round_trips[0].launch.time;
      const endTime =
        this.flight.round_trips[this.flight.round_trips.length - 1].arrive.time;

      // Parse the time strings to create Date objects
      const startDate = new Date(`2000-01-01 ${startTime}`);
      const endDate = new Date(`2000-01-01 ${endTime}`);

      // Calculate the time difference in milliseconds
      const timeDifferenceMs = endDate - startDate;

      // Convert the time difference to hours
      const timeDifferenceHours = Math.abs(
        Math.round(timeDifferenceMs / (1000 * 60 * 60))
      );

      return timeDifferenceHours;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../view/dashboard/flight/_flight.scss";
.details_title h5 span{
  font-size: 20px;
}
.details_title h5 .line{
  width: 100px;
  img {
    margin: 0 auto;
    display: block;
  }
}
.flight_trip_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  i {
    position: absolute;
    top: 5px;
    color: #0a909b;
    transform: rotate(180deg);
    font-size: 1.5rem;
  }
  hr {
    border-top: 1px dashed #000;
    width: 45%;
  }
  .auto_end {
    margin-inline-end: auto;
  }
}
</style>
