<template>
  <a class="btn SecondButton offline w-100" @click="visible = true">
    <!-- <img src="@/assets/media/image/fi_2676818.svg" alt="" /> -->
    {{ $t("Filter Bookings") }}</a
  >
  <Dialog
    v-model:visible="visible"
    modal
    :pt="{
      root: 'border-none',
      mask: {
        style: 'backdrop-filter: blur(2px)',
      },
    }"
    :dismissableMask="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <form class="modal_form" @submit.prevent="filterBooking()">
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="country" class="form-label">{{
                    $t("Hotel Country")
                  }}</label>
                  <Multiselect
                    v-model="country_id"
                    :options="countries"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('choose_country')"
                    select-label=""
                    :hide-selected="true"
                    @update:model-value="FetchCityFromCountry"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="city" class="form-label">{{
                    $t("Hotel City")
                  }}</label>
                  <Multiselect
                    v-model="cityValue"
                    :options="cities"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('enter_city')"
                    select-label=""
                    :hide-selected="true"
                    @update:model-value="searchHotels(cityValue)"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="name" class="form-label">{{
                    $t("Hotel Name")
                  }}</label>
                  <div class="selects">
                    <div class="form-group">
                      <input
                        type="search"
                        class="form-control"
                        :placeholder="$t('Hotel')"
                        v-model="searchValue"
                        @keyup="searchHotelsByWord"
                        @update:model-value="searchHotels(cityValue)"
                      />
                      <!-- @update:model-value="searchHotels(cityValue)" -->
                      <div
                        class="resultSearch"
                        v-if="searchValue.length > 2 && hotelsSearch.length"
                      >
                        <h6 class="head_search">{{ $t("hotels") }}</h6>
                        <ul class="hotels">
                          <li
                            v-for="(hotel, index) in hotelsSearch"
                            :key="index"
                            @click="
                              sendHotelName(
                                hotel.title,
                                hotel.city_id,
                                hotel.id,
                              )
                            "
                          >
                            <img
                              :src="hotel.image ? hotel.image : defaultImage"
                              class="hotel_image"
                              :alt="hotel.title"
                              @error="handleImageError"
                            />
                            <div class="texts">
                              <p class="title">{{ hotel.title }}</p>
                              <p class="country">
                                {{ hotel.country_title }} -
                                {{ hotel.city_title }}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="booking_id" class="form-label">{{
                    $t("Booking ID")
                  }}</label>
                  <input
                    type="number"
                    id="booking_id"
                    class="form-control"
                    :placeholder="$t('Enter id')"
                    v-model="booking_id"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="booking_status" class="form-label">{{
                    $t("Booking Status")
                  }}</label>
                  <Multiselect
                    v-model="statusValue"
                    :options="statuses.map((status) => status.id)"
                    :custom-label="
                      (opt) => statuses.find((x) => x.id == opt).title
                    "
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="supplier_campany" class="form-label">{{
                    $t("Supplier")
                  }}</label>
                  <Multiselect
                    v-model="supplierValue"
                    :options="suppliers.map((supplier) => supplier.id)"
                    :custom-label="
                      (opt) => suppliers.find((x) => x.id == opt).name
                    "
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-group">
                  <label for="client" class="form-label">{{
                    $t("client")
                  }}</label>
                  <Multiselect
                    v-model="client_id"
                    :options="clients"
                    label="name"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="checkin" class="form-label">checkin</label>
                  <Calendar
                    v-model="checkin_from"
                    selectionMode="range"
                    :manualInput="true"
                    :minDate="new Date()"
                    showIcon
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="checkout" class="form-label">checkout</label>
                  <Calendar
                    v-model="checkout_to"
                    selectionMode="range"
                    :manualInput="true"
                    :minDate="new Date()"
                    showIcon
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn filter" type="submit">
                {{ $t("Filter Bookings") }}
              </button>
              <button
                style="width: 13%"
                class="btn reset PrimaryButton mx-2 mt-0 del"
                @click="resetForm"
              >
                {{ $t("Reset_data") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";

// import Datepicker from "vuejs3-datepicker";
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";

// import "vue-datepicker-ui/lib/vuedatepickerui.css";
// import VueDatepickerUi from "vue-datepicker-ui";
import moment from "moment";

export default {
  props: ["booking_type"],
  components: { Multiselect, Dialog, Calendar },
  emits: ["getData"],
  data() {
    return {
      visible: false,
      clients: [],
      client_id: null,
      suppliers: [],
      supplierValue: null,
      countries: [],
      country_id: null,
      cities: [],
      cityValue: null,
      searchValue: "",
      hotelsSearch: [],
      citiesSearch: [],
      defaultImage: require("@/assets/media/image/placeholder.png"), // Replace with your default image URL,
      checkin_from: null,
      checkout_to: null,
      statusValue: null,
      statuses: [
        {
          id: 0,
          title: this.$t("initial"),
        },
        {
          id: 1,
          title: this.$t("pending"),
        },
        {
          id: 2,
          title: this.$t("completed"),
        },
        {
          id: 3,
          title: this.$t("edited"),
        },
        {
          id: 4,
          title: this.$t("canceled"),
        },
      ],
      hotelId: null,
      booking_id: null,
      filtered_Bookings: [],
      checkin_selectedDate: ["", ""],
      checkout_selectedDate: ["", ""],
    };
  },
  methods: {
    handleImageError(event) {
      // When the image fails to load, replace it with the default image
      event.target.src = this.defaultImage;
    },
    fetchClients() {
      axios.post("/fetch_consumer_by_role", { role: 1 }).then(({ data }) => {
        this.clients = data.data.data;
      });
    },
    // fetch suppliers data
    FetchSuppliers() {
      axios.post("/fetch_consumer_by_role", { role: 2 }).then(({ data }) => {
        this.suppliers = data.data.data;
        // this.providers = data.data;
      });
    },
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    // fetch city
    FetchCityFromCountry(value) {
      let basic_country_ids = {
        country_id: [value.id],
      };
      // console.log(this.country_id);
      // console.log(basic_country_ids);
      axios
        .post("/fetch_cities_by_country_id", basic_country_ids)
        .then(({ data }) => {
          this.cities = data.data;
          // console.log(this.cities);
        });
    },
    //Search Hotels
    // searchHotels(event) {
    //   const searchText = { word: event.target.value };
    //   axios
    //     .post(`/fetch_new_home_hotels`, searchText)
    //     .then(
    //       (res) => (
    //         (this.hotelsSearch = res.data.data.hotels),
    //         (this.citiesSearch = res.data.data.cities)
    //       )
    //     )
    //     .catch((err) => console.log(err));
    //   // console.log(searchText);
    //   // console.log(this.hotelsSearch);
    //   // console.log(this.citiesSearch);
    // },
    searchHotelsByWord(event) {
      const search = { word: event.target.value };
      axios
        .post(`/search_hotels_by_city_id`, search)
        .then(({ data }) => {
          this.hotelsSearch = data.data;
          // console.log(this.hotelsSearch);
        })
        .catch((err) => console.log(err));
    },
    searchHotels(ele) {
      axios
        .post(`/search_hotels_by_city_id`, {
          city_id: ele?.id,
        })
        .then(({ data }) => {
          this.hotelsSearch = data.data.map((hotel) => {
            // Append the rooms data to each hotel
            hotel.rooms = this.all_rooms.map((room) => ({
              title: room.title,
              room_id: room.id,
              adult: {
                cost: 0,
                selling: 0,
              },
              child: {
                cost: 0,
                selling: 0,
              },
            }));
            return hotel;
          });
          console.log(this.hotelsSearch);
        })
        .catch((err) => console.log(err));
    },

    // fetch hotels
    sendHotelName(hotelName, cityId, hotelId) {
      this.hotelName = hotelName;
      this.searchValue = hotelName;
      this.hotelId = hotelId;
      this.cityId = cityId;
      this.citiesSearch.length = [];
      this.hotelsSearch = [];
    },
    filterBooking() {
      let form = {};

      if (this.client_id != null) {
        form.client_id = this.client_id.id;
      }
      if (this.supplierValue != null) {
        form.supplier_id = this.supplierValue;
      }
      if (this.country_id != null) {
        form.hotel_country_id = this.country_id.id;
      }
      if (this.cityValue != null) {
        form.hotel_city_id = this.cityValue.id;
      }
      if (this.searchValue != "") {
        form.hotel_name = this.searchValue;
      }
      if (this.booking_id != null) {
        form.booking_id = this.booking_id;
      }
      if (this.statusValue != null) {
        form.booking_status = this.statusValue;
      }
      if (this.checkin_from != null) {
        if (this.checkin_from[0] != null) {
          form.check_in_from = moment(this.checkin_from[0]).format(
            "YYYY-MM-DD",
          );
        }
        if (this.checkin_from[1] != null) {
          form.check_in_to = moment(this.checkin_from[1]).format("YYYY-MM-DD");
        }
      }
      if (this.checkout_to != null) {
        if (this.checkout_to[0] != null) {
          form.check_out_from = moment(this.checkout_to[0]).format(
            "YYYY-MM-DD",
          );
        }
        if (this.checkout_to[1] != null) {
          form.check_out_to = moment(this.checkout_to[1]).format("YYYY-MM-DD");
        }
      }

      this.$emit("getData", form);
    },
    //reset data
    resetForm() {
      // Reset all the data properties to their initial values
      this.country_id = null;
      this.cityValue = null;
      this.searchValue = "";
      this.booking_id = null;
      this.statusValue = null;
      this.supplierValue = null;
      this.client_id = null;
      this.checkin = null;
      this.checkout = null;
      this.checkin_selectedDate = ["", ""];
      this.checkout_selectedDate = ["", ""];
    },
  },
  mounted() {
    this.fetchClients();
    this.FetchSuppliers();
    this.fetch_country();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "../../view/dashboard/offline/_offline.scss";

.selects {
  .form-group {
    width: 100%;
  }
}
.SecondButton {
  height: fit-content !important;
}
</style>
