<template>
  <div class="contract_table">
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="title">{{ $t("history") }}</h5>
    </div>
    <div class="row my-4">
      <div class="table-responsive table_row">
        <table class="table" id="actions">
          <thead>
            <tr>
              <th scope="col">{{ $t("ID") }}</th>
              <th scope="col">{{ $t("employee name") }}</th>
              <th scope="col">{{ $t("date") }}</th>
              <th scope="col">{{ $t("time") }}</th>
              <th scope="col">{{ $t("Control") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(action, index) in actions.data" :key="index">
              <td :data-label="$t('ID')">{{ action.id }}</td>
              <td :data-label="$t('employee name')">{{ action.employee_organization_id }}</td>
              <td :data-label="$t('date')">{{ formatDate(action.created_at) }}</td>
              <td :data-label="$t('time')">{{ formatTime(action.created_at) }}</td>
              <td :data-label="$t('action')">{{ action.action == "store_contract_online" ? $t("store_contract_online") : action.action == "change_status" ? $t("change_status") : action.action == "store_book_hotel" ? $t("store_book_hotel") : action.action == "pay" ? $t("pay") : action.action == "update" ? $t("update") : action.action == "store" ? $t("store") : action.action }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="actions"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchContract"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </div>
</template>

<script>
// import "jquery/dist/jquery.min.js";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import moment from "moment";
// import Swal from "sweetalert2";
// import $ from "jquery";

export default {
  name: "table-customization",
  components: {
    pagination,
  },
  data() {
    return {
      actions: [],
    };
  },
  methods: {
    // fetch Customizations table
    FetchActions(page = 1) {
      const book_data = {
        id: this.$route.params.id,
        type: "ApiBooking\\Models\\Booking\\Booking"
      };
      axios.post(`/history_action?page=${page}`, book_data).then(({ data }) => {
        this.actions = data.data;
        //  console.log(this.actions)
      });
    },

    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    formatTime(value) {
      return moment(value).format("h:mm");
    }
  },
  mounted() {
    this.FetchActions();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "offline.scss";

.selects {
  .form-group {
    width: 100%;
  }
}
.search {
  padding-inline-start: 3rem !important;
}
</style>