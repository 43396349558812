<template>
  <div class="room-dilaog">
    <button
      class="btn btn-warning"
      type="button"
      @click="
        visible = true;
        fetchMealsTypes();
        fetchData();
      "
      :disabled="
        booking_portal_type === 2
          ? true
          : booking_portal_type === 1
            ? true
            : booking_portal_type === 3
              ? true
              : false
      "
      v-tooltip="$t('edit_room')"
    >
      <i class="fa-solid fa-bed"></i>
    </button>
    <Dialog
      v-model:visible="visible"
      modal
      :dismissableMask="true"
      :style="{ width: '60vw', height: 'fit-content' }"
    >
      <h4 class="my-2">{{ $t("Edit Room") }}</h4>
      <form @submit.prevent="onSubmit()" class="row my-3" style="height: 25vh">
        <div class="form-group col-12 col-md-6">
          <div class="d-flex align-items-center justify-content-between">
            <label class="form-label"> {{ $t("supplier_type") }}</label>
            <AddHotelSupplier
              :hotel="hotelValue"
              v-if="supplier?.title == 'Hotel Director'"
            />
          </div>
          <Multiselect
            v-model="supplier"
            :options="optionsSupplies"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
            @update:model-value="getContracts()"
          >
          </Multiselect>
        </div>
        <div
          class="form-group col-12 col-md-6"
          v-if="supplier?.title == 'Supplier'"
        >
          <div class="d-flex justify-content-between">
            <label class="">{{ $t("Supplier") }}</label>
            <supllierDialog />
          </div>
          <Multiselect
            v-model="supplierValue"
            :options="suppliers"
            label="name"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('suppliers')"
            select-label=""
            :hide-selected="true"
            :custom-label="formatSupplierLabel"
          >
          </Multiselect>
        </div>
        <div class="form-group col-12 col-md-6">
          <label class="">{{ $t("Room Name") }}</label>
          <Multiselect
            v-model="roomsValue"
            :options="rooms"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('Room Name')"
            select-label=""
            :hide-selected="true"
            :custom-label="formatSupplierLabel"
          >
          </Multiselect>
        </div>
        <div class="form-group col-12 col-md-6 position-relative">
          <div class="d-flex align-items-center justify-content-between">
            <label class="form-label"> {{ $t("meal") }} </label>
            <div
              class="form-check form-switch d-flex align-items-center justify-content-end"
            >
              <input
                class="form-check-input switch mx-1"
                type="checkbox"
                id="toggle_form_data_meal"
                v-model="toggle_form_meal"
              />
              <label for="toggle_form_data_meal" class="text_design mb-0">
                {{
                  toggle_form_meal ? $t("contain_meal") : $t("not_contain_meal")
                }}
              </label>
            </div>
          </div>
          <Multiselect
            v-model="mealsValue"
            :options="meals"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :hide-selected="true"
            :placeholder="$t('meals')"
            select-label=""
          >
          </Multiselect>
          <span
            v-if="mealsValue"
            @click="removeMeals"
            class="removeMeals text-danger mx-1 position-absolute"
            ><i class="fa-regular fa-trash-can"></i
          ></span>
        </div>
        <div class="form-group col-12 col-md-12 d-flex justify-content-end">
          <button type="submit" class="btn btn-primary w-25">
            {{ $t("save") }}
          </button>
        </div>
      </form>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Multiselect from "vue-multiselect";
import AddHotelSupplier from "@/components/Dialogs/addHotelSupplier.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "editRoomOffline",
  components: {
    Dialog,
    Multiselect,
    AddHotelSupplier,
  },
  props: ["room", "booking_portal_type"],
  emits: ["fetchData"],
  data() {
    return {
      visible: false,
      optionsSupplies: [
        {
          id: 1,
          title: "Hotel Director",
        },
        {
          id: 3,
          title: "Supplier",
        },
      ],
      supplier: null,
      rooms: [],
      suppliers: [],
      supplierValue: null,
      roomsValue: null,
      meals: [],
      mealsValue: "",
    };
  },
  methods: {
    fetchData() {
      if (this.room.supplier_type == "SUPPLIER") {
        this.supplier = {
          id: 3,
          title: "Supplier",
        };
        this.fetchSupplires();
        this.fetchRooms();
        this.supplierValue = this.room.supplier;
      }
      if (this.room.supplier_type == "HOTEL") {
        this.supplier = {
          id: 1,
          title: "Hotel Director",
        };
        this.fetchHotel();
      }
      this.roomsValue = {
        id: this.room.hotel_room_view_id,
        title: this.room.hotel_room_view_title
          ? this.room.hotel_room_view_title
          : this.room.room.title,
      };
      this.mealsValue = this.room.meal_type;
    },
    onSubmit() {
      //     "supplier_type" : 3, //HOTEL = 1;CONTRACT = 2;SUPPLIER = 3;
      // "supplier_id"  :26,
      // "room_id" :37464,
      // "meal_type_id" :1,
      // "offline_booking_room_id":146
      const formData = new FormData();
      formData.append("supplier_type", this.supplier.id);
      if (this.supplier.title == "Hotel Director") {
        formData.append("supplier_id", this.room.hotel_consumer_id);
      } else {
        if (this.supplierValue) {
          formData.append("supplier_id", this.supplierValue.id);
        }
      }
      // console.log(this.room);
      formData.append("hotel_room_view_id", this.roomsValue.id);
      if (this.mealsValue) {
        formData.append("meal_type_id", this.mealsValue.id);
      }
      formData.append("offline_booking_room_id", this.room.id);
      axios
        .post("update_custom_offline_booking_room", formData)
        .then((res) => {
          Swal.fire({
            title: "",
            text: res.data.message,
            icon: "success",
          });

          this.$emit("fetchData");

          this.visible = !this.visible;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    getContracts() {
      if (this.supplier.title == "Supplier") {
        this.fetchRooms();
        this.fetchSupplires();
      }
      if (this.supplier.title == "Hotel Director") {
        this.fetchHotel();
      }
      this.indexOfContract = null;
    },
    async fetchRooms() {
      this.hotelSubmit = true;
      const requestData = {
        hotel_id: this.room.hotel_id,
        // Add other properties as needed
      };

      if (requestData.hotel_id) {
        try {
          const { data } = await axios.post(
            "fetch_room_view_for_hotel",
            requestData
          );
          this.rooms = data.data;
          // console.log(this.rooms, "rooms");
        } catch (error) {
          Swal.fire({
            title: "",
            text: error.response.data.message || "Unknown error occurred",
            icon: "error",
          });
        }
      }
    },
    async fetchSupplires() {
      axios
        .post("/fetch_consumer_by_role", { role: 2, per_page: 0, active: 1 })
        .then(({ data }) => {
          this.suppliers = data.data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    fetchHotel() {
      axios
        .post("checkOrganizationHotel", { hotel_id: this.room.hotel_id })
        .then((res) => {
          if (res.data.data == false) {
            Swal.fire({
              title: this.$t("this_hotel_has_no_supplers"),
              icon: "error",
              confirmButtonText: this.$t("ok"),
            });
            this.hotelSubmit = false;
          } else {
            this.fetchRooms();
          }
        });
    },
    async fetchMealsTypes() {
      axios
        .post("/fetch_meal_types")
        .then(({ data }) => {
          this.meals = data.data.data;
          // console.log(this.meals , "meals");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    removeMeals() {
      this.mealsValue = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.removeMeals {
  left: 19px;
  top: 50%;
  transform: translateY(-19%);
}
.room-dilaog {
  .btn {
    width: 100%;
  }
}
</style>
