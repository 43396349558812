<template>
  <div class="d-flex align-items-center gap-3 m-2">
    <span class="fw-bold"> {{ $t("specific_permissions") }}</span>
    <InputSwitch v-model="checked" /><span class="fw-bold">{{
      $t("all_permissions")
    }}</span>
  </div>
  <!-- <div v-if="Loading" class="w-100">
    <Skeleton
      width="100%"
      height="160px"
      class="mt-3"
      v-for="i in 5"
      :key="i"
    ></Skeleton>
  </div> -->
  <div class="my-3 w-50">
    <label class="form-label fw-bold">{{ $t("roles name") }}</label>
    <input
      type="text"
      v-model="permission_role_name"
      class="form-control"
      :placeholder="$t('roles name')"
    />
  </div>

  <div v-if="checked">
    <Hotels @sendValues="receiveEmitHotel" :peremisionShow="peremisionShow" />
    <Flights @sendValues="receiveEmitFlight" :peremisionShow="peremisionShow" />
    <Packages
      @sendValues="receiveEmitPackages"
      :peremisionShow="peremisionShow"
    />
    <Clients
      @sendValues="receiveEmitClients"
      :peremisionShow="peremisionShow"
    />
    <Transportation
      @sendValues="receiveEmitTransportation"
      :peremisionShow="peremisionShow"
    />
    <visa @sendValues="receiveEmitvisa" :peremisionShow="peremisionShow" />
    <Insurances
      @sendValues="receiveEmitInsurances"
      :peremisionShow="peremisionShow"
    />
    <AccountsPermesion
      @sendValues="receiveEmitAccountsPermesion"
      :peremisionShow="peremisionShow"
    />
    <WebsiteSetting
      @sendValues="receiveEmitWebsiteSetting"
      :peremisionShow="peremisionShow"
    />
    <SystemSetting
      @sendValues="receiveEmitSystemSetting"
      :peremisionShow="peremisionShow"
    />
    <affiliateSystem
      @sendValues="receiveEmitaffiliateSystem"
      :peremisionShow="peremisionShow"
    />
    <Global
      class="d-none"
      @sendValues="receiveEmitGlobal"
      :peremisionShow="peremisionShow"
    />
    <button class="btn PrimaryButton my-3" @click="sendAllData">
      {{ $t("send") }}
    </button>
  </div>
  <div v-else class="w-100 d-flex align-items-center justify-content-center">
    <button class="btn PrimaryButton my-3" @click="sendAllData">
      {{ $t("save") }}
    </button>
  </div>
</template>

<script>
import Hotels from "@/components/permission/Hotels.vue";
import Flights from "@/components/permission/Flight.vue";
import Packages from "@/components/permission/Packages.vue";
import Clients from "@/components/permission/Clients.vue";
import Transportation from "@/components/permission/Transportation.vue";
import visa from "@/components/permission/visa.vue";
import Insurances from "@/components/permission/Insurances.vue";
import AccountsPermesion from "@/components/permission/Accounts-permesion.vue";
import WebsiteSetting from "@/components/permission/WebsiteSetting.vue";
import SystemSetting from "@/components/permission/SystemSetting.vue";
import affiliateSystem from "@/components/permission/affiliateSystem.vue";
import Global from "@/components/permission/Global.vue";
import InputSwitch from "primevue/inputswitch";
import Swal from "sweetalert2";
// import Skeleton from "primevue/skeleton";

import axios from "axios";

export default {
  name: "Permission-page",
  components: {
    Hotels,
    Flights,
    Packages,
    Clients,
    Transportation,
    visa,
    Insurances,
    AccountsPermesion,
    WebsiteSetting,
    SystemSetting,
    affiliateSystem,
    InputSwitch,
    Global,
    // Skeleton,
  },
  data() {
    return {
      checked: true,
      // Loading: true,
      Hotels: [],
      Flights: [],
      Packages: [],
      Clients: [],
      Transportation: [],
      visa: [],
      Insurances: [],
      AccountsPermesion: [],
      WebsiteSetting: [],
      SystemSetting: [],
      affiliateSystem: [],

      permission_role_id: +this.$route.params.id,

      Global: [],

      peremision: [],
      peremisionShow: [],
      permission_role_name: "",
    };
  },
  methods: {
    receiveEmitHotel(data) {
      this.Hotels = data;
    },
    receiveEmitFlight(data) {
      this.Flights = data;
    },
    receiveEmitPackages(data) {
      this.Packages = data;
    },
    receiveEmitClients(data) {
      this.Clients = data;
    },
    receiveEmitTransportation(data) {
      this.Transportation = data;
    },
    receiveEmitvisa(data) {
      this.visa = data;
    },
    receiveEmitInsurances(data) {
      this.Insurances = data;
    },
    receiveEmitAccountsPermesion(data) {
      this.AccountsPermesion = data;
    },
    receiveEmitWebsiteSetting(data) {
      this.WebsiteSetting = data;
    },
    receiveEmitSystemSetting(data) {
      this.SystemSetting = data;
    },
    receiveEmitaffiliateSystem(data) {
      this.affiliateSystem = data;
    },
    receiveEmitGlobal(data) {
      this.Global = data;
    },

    sendAllData() {
      this.peremision = [
        ...this.Hotels,
        ...this.Flights,
        ...this.Packages,
        ...this.Clients,
        ...this.Transportation,
        ...this.visa,
        ...this.Insurances,
        ...this.AccountsPermesion,
        ...this.WebsiteSetting,
        ...this.SystemSetting,
        ...this.affiliateSystem,
        ...this.Global,
      ];
      // console.log(this.peremision, "peremision");
      if (this.permission_role_name == "") {
        Swal.fire({
          icon: "error",
          text: this.$t("roles name"),
        });
      } else {
        const data = {
          routes: this.checked ? this.peremision : [],
          allow_all_permissions: this.checked ? 0 : 1,
          permission_role_name: this.permission_role_name,
          permission_role_id: this.permission_role_id
            ? this.permission_role_id
            : {},
        };
        axios.post("/store_permissions_route", data).then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: this.$t("success"),
            showConfirmButton: false,
            timer: 1500,
          });

          // this.Loading = false;
        });
      }
      // console.log(this.peremision, "peremision");
    },
    showPremisions() {
      axios
        .post(`/show_permissions_route`, {
          permission_role_id: this.permission_role_id,
        })
        .then((res) => {
          this.peremisionShow = res.data.data.unique_id;
          // console.log(this.peremisionShow, "peremisionShow");
          this.permission_role_name = res.data.data.permission_role_name;

          this.Loading = false;

          // console.log(this.peremisionShow, "peremisionShow");
        });
    },
  },
  // watch: {
  //   organization_employee_id(newValue, oldValue) {
  //     if (newValue !== oldValue) {
  //       this.showPremisions();
  //     }
  //   },
  // },
  mounted() {
    if (this.$route.params.id) {
      this.showPremisions();
    }
  },
};
</script>

<style lang="scss" scoped>
.PrimaryButton {
  margin: 1rem !important;
}
</style>
