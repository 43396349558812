<template>
  <section class="show_receipt card_style mt-0">
    <div class="main_header_style"></div>
    <div class="row options mb-2">
      <div class="d-flex align-items-center mb-2 gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>
          {{ $t("All Routes") }}
        </h4>
      </div>

      <div class="col-lg-10 col-12">
        <div class="form-group">
          <input
            type="text"
            :placeholder="$t('search by name')"
            v-model="routeTitle"
            @keyup="FetchRoutes((page = 1))"
            class="form-control w-50"
          />
        </div>
      </div>

      <div class="col-12 col-lg-2">
        <router-link :to="{ name: 'routersAdd' }" class="PrimaryButton btn"
          ><i class="fa-solid fa-plus"></i>
          {{ $t("New Route") }}
        </router-link>
      </div>
    </div>
    <div class="row">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("Route") }}</th>
            <th>{{ $t("contry") }}</th>
            <!-- <th>{{ $t("number") }}</th> -->
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 4" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) in routes.data" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.text }}</td>
            <td>{{ item.country == null ? "" : item.country.title }}</td>

            <td>
              <router-link
                class="btn btn-success mx-1 w-auto"
                :to="`/routers/edit/${item.id}`"
                :title="$t('edit')"
              >
                <i class="fa-solid fa-pen"></i>
              </router-link>

              <button
                class="btn btn-danger mx-1 w-auto"
                @click="DeleteAlert(index)"
              >
                <i class="fa-solid fa-trash" :title="$t('delete')"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="routes"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchRoutes"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
// import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  name: "show-nation",
  components: {
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      routes: [],
      routeTitle: "",
      loading: true,
    };
  },
  methods: {
    // fetch routes data

    FetchRoutes(page = 1) {
      axios
        .post(`/fetch_car_routes?page=${page}`, { word: this.routeTitle })
        .then(({ data }) => {
          this.routes = data.data;
          // console.log(this.routes);
          this.loading = false;
        });
    },
    //delete expness
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.routes.data[index].id;
          const fromClick = true;
          axios.delete("/car_routes/" + `${delete_id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.routes.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.FetchRoutes();
  },
};
</script>
<style lang="scss" scoped>
@import "./_routers.scss";
</style>
