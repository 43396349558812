<template>
  <section>
    <div class="container">
      <div class="main-parnt">
        <div class="row mt-5">
          <sidebar-pill />
          <div class="col-lg-12 col-12">
            <div class="specific-section">
              <div class="row">
                <div :class="['col-lg-6', rtl ? 'text-start' : 'text-end']">
                  <div class="main-text">
                    <div class="text-logo">
                      <p>{{ pills.id }}</p>
                      <h4>
                        <span>{{
                          pills.status === 0
                            ? $t("initial")
                            : pills.status === 1
                            ? $t("pending")
                            : pills.status === 2
                            ? $t("completed")
                            : pills.status === 3
                            ? $t("edited")
                            : pills.status === 4
                            ? $t("canceled")
                            : ""
                        }}</span>
                      </h4>
                    </div>
                    <div class="next-text">
                      <h4>{{ $t("hotel_booking_confirmation") }}</h4>
                      <h5>
                        {{ $t("booking_status") }}:
                        <span>{{
                          pills.status === 0
                            ? $t("initial")
                            : pills.status === 1
                            ? $t("pending")
                            : pills.status === 2
                            ? $t("completed")
                            : pills.status === 3
                            ? $t("edited")
                            : pills.status === 4
                            ? $t("canceled")
                            : ""
                        }}</span>
                      </h5>
                      <!-- <h5 v-if="pills.hotel_name">{{ pills.hotel_name }}</h5> -->
                      <!-- <h6
                          v-if="
                            pills.organization.city.title ||
                            pills.organization.country.title
                          "
                        >
                          {{ pills.organization.city.title }} -
                          {{ pills.organization.country.title }}
                        </h6> -->
                      <!-- <h6 v-if="pills.organization?.phone">
                          Phone: <span>{{ pills.organization?.phone }}</span>
                        </h6> -->
                      <!-- <h6 v-if="pills.organization.fax">FAX:</h6>
                        <h6 v-if="pills.organization.address">
                          {{ pills.organization.address }}
                        </h6> -->
                    </div>
                  </div>
                  <div class="main-list mt-4">
                    <div class="list-one">
                      <div class="right">
                        <h4 class="text-center">{{ $t("guest_name") }}</h4>
                        <h4 style="width: max-content">
                          {{ $t("hotel_name") }}
                        </h4>
                        <h4 style="width: max-content">
                          {{ $t("client_name") }}
                        </h4>
                      </div>
                      <div
                        class="left d-flex justify-content-center flex-column"
                      >
                        <h5 style="width: max-content" class="text">
                          : {{ pills.guests[0].name }}
                        </h5>
                        <h5 style="width: max-content" class="text">
                          : {{ pills.hotel_name }}
                        </h5>
                        <h5 style="width: max-content" class="text">
                          : {{ pills.guests[0].name }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="logo">
                    <img
                      v-if="pills.organization?.image"
                      :src="pills.organization?.image"
                    />
                    <img
                      v-else
                      src="@/assets/media/image/logo.png"
                      alt="LOGO"
                    />
                  </div>
                  <div :class="['under-text', rtl ? 'text-start' : 'text-end']">
                    <div class="head">
                      <!-- <h4>Date : {{ pills.check_in }}</h4>
                      <h4>To : {{ pills.check_out }}</h4> -->
                      <h4>
                        {{ $t("Dear Sir") }} : {{ pills.guests[0]?.name }}
                      </h4>
                      <h5>
                        {{ $t("Greeting From") }}
                        <span>{{ pills.organization?.name }}</span>
                      </h5>
                      <div class="pragra">
                        <p>
                          {{
                            $t(
                              "First of All, We would like to take this opportunity to welcome you at"
                            )
                          }}
                          <strong> {{ userName }} </strong>
                          {{
                            $t(
                              "and tourism We are pleased to confirm the following reservation on a definite basis."
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" class="w-25">{{ $t("room_type") }}</th>
                      <th scope="col">{{ $t("Meals") }}</th>
                      <th scope="col">{{ $t("Check_In") }}</th>
                      <th scope="col">{{ $t("Check_Out") }}</th>
                      <th scope="col">{{ $t("nights") }}</th>
                      <th scope="col">{{ $t("guests_per_room") }}</th>
                      <th scope="col">{{ $t("confirmation_number") }}</th>
                      <!-- <th scope="col">RATE</th> -->
                      <!-- <th class="last">TOTAL</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(room, index) in pills.rooms" :key="index">
                      <td class="frist">{{ room.title }}</td>
                      <td>{{ room.description }}</td>
                      <td>{{ pills.check_in }}</td>
                      <td>{{ pills.check_out }}</td>
                      <td>{{ numberOfNights }}</td>
                      <td>{{ pills.guests.length }}</td>
                      <td>{{ pills.confirmation_number }}</td>
                      <!-- <td>{{ pills.check_out }}</td> -->
                      <!-- <td class="last">{{ room.cost }}</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="mide-list">
                <ul>
                  <li>
                    <h3>{{ $t("net_accommodation_charge") }}</h3>

                    <h4>{{ pills.total_selling_price }}</h4>
                  </li>
                  <li>
                    <h3>{{ $t("vat_charge") }}</h3>
                    <h4>{{ pills.with_selling_price_tax }}</h4>
                  </li>
                  <div class="last-li">
                    <li>
                      <h3>
                        {{ pills.total_selling_price_with_tax }}
                      </h3>
                    </li>
                  </div>
                </ul>
              </div>

              <div class="end-page">
                <div class="hed">
                  <h4 style="text-align: right" class="w-100">
                    {{ $t("Terms and Conditions") }}
                  </h4>
                  <p>
                    {{ $t("Check in time at:") }}{{ pills.check_in }}
                    {{
                      $t(
                        "any early arrival subject to availability. Check out time at:"
                      )
                    }}{{ pills.check_out }} {{ $t(", after") }}
                    {{ pills.check_out }}
                    {{
                      $t(
                        "one night will be charged. To guarantee your booking tota amount to be transfer to our Account. before option datementioned in the booking In case of guarantee cancellation full payment will be charged."
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="lastes">
                <h4>
                  <!-- {{ pills.organization.name }} and tourism -->
                  <!-- {{ pills.id }} -->
                </h4>
                <h4>
                  {{ $t("booking_no") }} : {{ pills.confirmation_number }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="print-button" class="print_button w-25">
      <button class="PrimaryButton btn" @click="printContent">
        {{ $t("print") }}
      </button>
    </div>
  </section>
</template>

<script>
  import axios from "axios";
  //   import sidebarPill from "@/view/dashboard/offline/sidebarPill.vue";
  export default {
    name: "pill-hotel-cancel",
    components: {
      //   sidebarPill,
    },
    data() {
      return {
        pills: {
          organization: {},
          guests: [
            {
              nationality: {},
            },
          ],
          meal: [],
        },
        bookId: null,
        userData: {},
        numberOfNights: 0,
        userName: JSON.parse(localStorage.getItem("user"))?.name,
      };
    },
    methods: {
      fetchPayments() {
        axios
          .post("/get_invoice_book", {
            booking_id: this.$route.params.id,
          })
          .then(({ data }) => {
            this.pills = data.data;
            const checkInDate = new Date(this.pills.check_in);
            const checkOutDate = new Date(this.pills.check_out);

            // Calculate the time difference in milliseconds
            const timeDiff = checkOutDate.getTime() - checkInDate.getTime();

            // Calculate the number of nights
            const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));

            // Update the numberOfNights data property
            this.numberOfNights = nights;
            // console.log(this.pills);
          });
      },
      printContent() {
        let printContents =
          document.querySelector(".specific-section").outerHTML;
        let originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();

        // Restore the original content after printing
        document.body.innerHTML = originalContents;
      },
      getHotelDataFromLocalStorage() {
        // Check if the data exists in localStorage
        if (localStorage.getItem("user")) {
          // Retrieve the data from localStorage
          this.userData = JSON.parse(localStorage.getItem("user"));
          // Use this.hotelData for further operations
          console.log("Retrieved data:", this.userData);
        } else {
          console.log('No data found in localStorage with the key "user"');
        }
      },
    },
    mounted() {
      this.getHotelDataFromLocalStorage(); // Call the method when the component is mounted
    },
    created() {
      this.fetchPayments();
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  @import "./_pills.scss";
</style>
