<template>
  <section class="all_emp card_style">
    <div class="row d-flex align-items-center">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>
          {{ $t("flight_reservation_revenues") }}
        </h4>
      </div>

      <input
        type="text"
        v-model="flightResText"
        class="form-control w-50 mb-2"
        @keyup="handleGetBookingProfit((page = 1))"
        :placeholder="$t('search...')"
      />
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("booking date") }}</th>
            <th style="width: 150px">{{ $t("confirmation_number") }}</th>
            <th>{{ $t("final_price") }}</th>
            <th>{{ $t("organization_price") }}</th>
            <th>{{ $t("original_price") }}</th>
            <th>{{ $t("payment_profit") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="profit in 10" :key="profit">
            <td v-for="profit in 7" :key="profit">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(profit, index) in BookingProfit.data" :key="index">
            <td>{{ profit.id }}</td>
            <td>{{ profit.created_at }}</td>
            <td>{{ profit.confirmation_number || "---" }}</td>
            <td>{{ profit.final_price || "---" }}</td>
            <td>
              <span class="text-success">{{
                profit.final_flight_price || "---"
              }}</span>
            </td>
            <td>{{ profit.original_price || "---" }}</td>
            <td>{{ ((profit.final_price * 3) / 100).toFixed(2) }} %</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="BookingProfit"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="handleGetBookingProfit"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>
<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import Skeleton from "primevue/skeleton";
import pagination from "laravel-vue-pagination";
export default {
  name: "flightRevenue",

  data() {
    return {
      BookingProfit: [],
      flightResText: "",
      loading: true,
    };
  },
  components: { pagination, AddFavDailog, Skeleton },
  methods: {
    handleGetBookingProfit(page = 1) {
      axios
        .post(`/fetchFlightProfit?page=${page}`, { word: this.flightResText })
        .then(({ data }) => {
          this.BookingProfit = data.data;
          // console.log(this.BookingProfit);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.handleGetBookingProfit();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "./revenues";
</style>
