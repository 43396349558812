<template>
  <div
    class="modal fade"
    :id="`exampleModal_show${book_id}`"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <img :src="image" class="img-fluid w-100" alt="..." />
        </div>
        <div class="form-group" v-if="status != 1 && status != 2">
          <Multiselect
            v-model="organizaton"
            :options="organizations"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
          ></Multiselect>
        </div>
        <div class="modal-footer">
          <button
            @click="upladReceipt()"
            type="button"
            class="btn btn-outline-secondary"
            data-bs-dismiss="modal"
          >
            {{ $t("order_receipt") }} <i class="fa-solid fa-info"></i>
          </button>
          <!-- {{ status }} -fff -->
          <div class="d-flex gap-3" v-if="status != 1 && status != 2">
            <button
              @click="rejectAccept(1)"
              type="button"
              class="btn btn-outline-success"
              data-bs-dismiss="modal"
            >
              {{ $t("Accept") }} <i class="fa-solid fa-check"></i>
            </button>

            <button
              @click="rejectAccept(2)"
              type="button"
              class="btn btn-outline-danger"
              data-bs-dismiss="modal"
            >
              {{ $t("Cancel") }} <i class="fa-solid fa-x"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { Multiselect } from "vue-multiselect";
export default {
  data() {
    return {
      organizaton: "",
    };
  },
  props: [
    "book_id",
    "book_type",
    "image",
    "organizations",
    "status",
    "fetchmy_reservations",
  ],
  methods: {
    upladReceipt() {
      axios
        .post("/send_receipt_request", {
          book_id: this.book_id,
          book_type: this.book_type,
        })
        .then(() => {
          Swal.fire({
            title: "",
            text: this.$t("order_receipt_sent"),
            icon: "success",
          });
          this.fetchmy_reservations();
        });
    },
    rejectAccept(receipt_status) {
      axios
        .post("/change_receipt_status", {
          book_id: this.book_id,
          book_type: this.book_type,
          accountant_id: this.organizaton.id,
          receipt_status: receipt_status, // Use the passed receiptStatus parameter
        })
        .then(() => {
          // console.log(response.data, "eeeeeee");
          Swal.fire({
            title: "",
            text:
              receipt_status == 1
                ? this.$t("receipt sent")
                : this.$t("not receipt sent"),
            icon: "success",
          });
          this.fetchmy_reservations();
        });
    },
  },
  components: {
    Multiselect,
  },
};
</script>
