<template>
  <form class="flight_booking" @submit.prevent="Add_EditFlight()">
    <div class="container">
      <div class="row">
        <div class="d-flex gap-3 mb-2 align-items-center px-3">
          <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
          <h4 class="title">{{ $t("flight-booking") }}</h4>
        </div>
        <div class="col-lg-8 col-md-12 col-12">
          <div class="client-data">
            <div class="row">
              <div class="col-12">
                <div class="row trip_way">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        type="radio"
                        id="one_way"
                        value="one_way"
                        v-model="ways"
                        :disabled="ways == 'two_way' && flightId != undefined"
                      />
                      <label for="one_way"
                        ><div class="icon_circle mx-2">
                          <i class="fa-solid fa-person-walking-arrow-right"></i>
                        </div>
                        {{ $t("One way trip only") }}</label
                      >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        type="radio"
                        id="two_way"
                        value="two_way"
                        v-model="ways"
                        :disabled="ways == 'one_way' && flightId != undefined"
                      />
                      <label for="two_way"
                        ><div class="icon_circle mx-2">
                          <i
                            class="fa-solid fa-person-walking-arrow-loop-left"
                          ></i>
                        </div>
                        {{ $t("Round trip") }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>{{ $t("Service provider") }}</label>
                  <Multiselect
                    v-model="provider_value"
                    :options="providers"
                    label="name"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('choose provider')"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                  <span
                    v-for="error in v$.provider_value.$errors"
                    class="text-danger"
                    :key="error.$uid"
                    >{{ $t(error.$message) }}</span
                  >
                </div>
              </div>
              <div class="col-lg-6">
                <div class="input-group">
                  <div class="d-flex justify-content-between">
                    <label
                      class="form-label"
                      v-tooltip.left="$t('client_gender')"
                    >
                      {{ maxWordTruncate($t("client_gender")) }}
                    </label>
                  </div>

                  <Multiselect
                    v-model="clientsTypeValue"
                    :options="clientsTypes"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('gender')"
                    select-label=""
                    :hide-selected="true"
                    :custom-label="formatClientLabel"
                    @update:model-value="FetchSuppliers(clientsTypeValue)"
                  >
                  </Multiselect>
                  <span
                    v-for="error in v$.clientsTypeValue.$errors"
                    class="text-danger"
                    :key="error.$uid"
                    >{{ $t(error.$message) }}</span
                  >
                </div>
              </div>
              <div class="col-lg-6">
                <label
                  class="guest_label form-label"
                  v-tooltip.left="$t('client_name')"
                >
                  {{ maxWordTruncate($t("client_name")) }}
                </label>
                <div class="input-group">
                  <Multiselect
                    v-model="supplierValue"
                    :options="suppliers"
                    label="name"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('client_name')"
                    select-label=""
                    :hide-selected="true"
                    :custom-label="formatSupplierLabel"
                  >
                  </Multiselect>
                  <span
                    v-for="error in v$.supplierValue.$errors"
                    class="text-danger"
                    :key="error.$uid"
                    >{{ $t(error.$message) }}</span
                  >
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>{{ $t("Airlines") }}</label>
                  <Multiselect
                    v-model="company_value"
                    :options="companies"
                    label="name"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('choose Airlines')"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                  <span
                    v-for="error in v$.company_value.$errors"
                    class="text-danger"
                    :key="error.$uid"
                    >{{ $t(error.$message) }}</span
                  >
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>{{ $t("class") }}</label>
                  <Multiselect
                    v-model="cabin_code"
                    :options="cabin_codes"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('choose class')"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                  <span
                    v-for="error in v$.cabin_code.$errors"
                    class="text-danger"
                    :key="error.$uid"
                    >{{ $t(error.$message) }}</span
                  >
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>{{ $t("trip number") }}</label>
                  <input
                    type="number"
                    class="form-control"
                    :placeholder="$t('enter trip number')"
                    v-model="trip_number"
                  />
                  <span
                    v-for="error in v$.trip_number.$errors"
                    class="text-danger"
                    :key="error.$uid"
                    >{{ $t(error.$message) }}</span
                  >
                </div>
              </div>
              <div class="col-12 mt-1 travels_number">
                <div class="adults">
                  <div class="text d-flex align-items-center">
                    <span class="adult_text">{{ $t("number of adults") }}</span>
                    <form
                      class="d-flex align-items-center"
                      v-if="adults_number != 0"
                    >
                      <div class="form-group">
                        <!-- <label class="form-label">{{$t("cost")}}</label> -->
                        <input
                          type="number"
                          v-model="purchase_price_adt"
                          :placeholder="$t('cost')"
                          class="form-control"
                          :disabled="adults_number == 0"
                        />
                      </div>
                      <div class="form-group">
                        <!-- <label class="form-label">{{$t("selling")}}</label> -->
                        <input
                          type="number"
                          v-model="sell_price_adt"
                          :placeholder="$t('selling')"
                          class="form-control"
                          :disabled="adults_number == 0"
                        />
                      </div>
                    </form>
                  </div>
                  <div class="counter">
                    <div class="circle gray" @click="minusAdults()">
                      <i class="fa-solid fa-minus"></i>
                    </div>
                    <div class="count">{{ adults_number }}</div>
                    <div class="circle" @click="addAdults()">
                      <i class="fa-solid fa-plus"></i>
                    </div>
                  </div>
                </div>
                <div class="adults">
                  <div class="text d-flex align-items-center">
                    <span class="adult_text">{{
                      $t("number of children")
                    }}</span>
                    <form
                      class="d-flex align-items-center"
                      v-if="children_number != 0"
                    >
                      <div class="form-group">
                        <!-- <label class="form-label">{{$t("cost")}}</label> -->
                        <input
                          type="number"
                          v-model="purchase_price_chd"
                          :placeholder="$t('cost')"
                          class="form-control"
                          :disabled="children_number == 0"
                        />
                      </div>
                      <div class="form-group">
                        <!-- <label class="form-label">{{$t("selling")}}</label> -->
                        <input
                          type="number"
                          v-model="sell_price_chd"
                          :placeholder="$t('selling')"
                          class="form-control"
                          :disabled="children_number == 0"
                        />
                      </div>
                    </form>
                  </div>
                  <div class="counter">
                    <div class="circle gray" @click="minusChildren()">
                      <i class="fa-solid fa-minus"></i>
                    </div>
                    <div class="count">{{ children_number }}</div>
                    <div class="circle" @click="addChildren()">
                      <i class="fa-solid fa-plus"></i>
                    </div>
                  </div>
                </div>
                <div class="adults">
                  <div class="text d-flex align-items-center">
                    <span class="adult_text">{{ $t("number of babies") }}</span>
                    <form
                      class="d-flex align-items-center"
                      v-if="babies_number != 0"
                    >
                      <div class="form-group">
                        <!-- <label class="form-label">{{$t("cost")}}</label> -->
                        <input
                          type="number"
                          v-model="purchase_price_inf"
                          :placeholder="$t('cost')"
                          class="form-control"
                          :disabled="babies_number == 0"
                        />
                      </div>
                      <div class="form-group">
                        <!-- <label class="form-label">{{$t("selling")}}</label> -->
                        <input
                          type="number"
                          v-model="sell_price_inf"
                          :placeholder="$t('selling')"
                          class="form-control"
                          :disabled="babies_number == 0"
                        />
                      </div>
                    </form>
                  </div>
                  <div class="counter">
                    <div class="circle gray" @click="minusBabies()">
                      <i class="fa-solid fa-minus"></i>
                    </div>
                    <div class="count">{{ babies_number }}</div>
                    <div class="circle" @click="addBabies()">
                      <i class="fa-solid fa-plus"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="row details_reservation">
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="reservation_number">{{
                  $t("reservation_number")
                }}</label>
                <input
                  type="text"
                  name=""
                  id="reservation_number"
                  class="form-control"
                  v-model="reservation_number"
                  disabled
                />
              </div>
            </div>
            <!-- <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="ticket number">{{ $t("ticket number") }}</label>
                <input
                  type="number"
                  name=""
                  id="ticket number"
                  class="form-control"
                  v-model="ticket_number"
                />
              </div>
            </div> -->
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="">{{ $t("reservation_status") }}</label>
                <Multiselect
                  v-model="statusValue"
                  :options="statuses"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('choose')"
                  select-label=""
                  :hide-selected="true"
                >
                </Multiselect>
                <span
                  v-for="error in v$.statusValue.$errors"
                  class="text-danger"
                  :key="error.$uid"
                  >{{ $t(error.$message) }}</span
                >
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group" v-if="statusValue.id == 0">
                <label for="">option date</label>
                <Datepicker
                  v-model="option_date"
                  enable-seconds
                  locale="en-US"
                  time-picker-inline
                />
              </div>
            </div>
            <!--            <div class="col-md-6 col-12">-->
            <!--              <div class="input-group">-->
            <!--                <label class="form-label" v-tooltip="$t('time')">-->
            <!--                  {{ maxWordTruncate($t("time")) }}</label-->
            <!--                >-->
            <!--                &lt;!&ndash; <input type="time" name="" id="time" class="form-control" v-model="client_option_time" /> &ndash;&gt;-->
            <!--                <Calendar-->
            <!--                  class="col-lg-12 w-100"-->
            <!--                  v-model="client_option_time"-->
            <!--                  showIcon-->
            <!--                  iconDisplay="input"-->
            <!--                  timeOnly-->
            <!--                  hourFormat="12"-->
            <!--                >-->
            <!--                  <template #inputicon="{ clickCallback }">-->
            <!--                    <i class="pi pi-clock" @click="clickCallback" />-->
            <!--                  </template>-->
            <!--                </Calendar>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="col-12">
              <div class="trip_type d-flex align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    name="withTax"
                    id="withTax1"
                    value="withTax"
                    v-model="withTax"
                  />
                  <label class="form-check-label mx-2" for="withTax1">
                    {{ $t("Taxes included") }}
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    name="withTax"
                    id="withTax2"
                    value="notTax"
                    v-model="withTax"
                  />
                  <label class="form-check-label mx-2" for="withTax2">
                    {{ $t("Taxes not included") }}
                  </label>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group trip_cost">
                <label for="" v-tooltip="$t('Total cost of the trip')">{{
                  maxWordTruncate($t("Total cost of the trip"), 15)
                }}</label>
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="totalCost"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group trip_cost">
                <label for="">{{ $t("cost_with_tax") }}</label>
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="costAfterTax"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group trip_cost">
                <label for="">{{ $t("tax value") }}</label>
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="taxCost"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group trip_cost">
                <label for="">{{ $t("total selling price") }}</label>
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="totalSelling"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group trip_cost">
                <label for="">{{ $t("sales_with_tax") }}</label>
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="sellingAfterTax"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group trip_cost">
                <label for="">{{ $t("tax value") }}</label>
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="taxSelling"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" v-for="(trip, tripIndex) in trips" :key="tripIndex">
        <div class="trip_details">
          <div class="row">
            <div class="col-12">
              <h3 v-if="this.ways == 'two_way' && tripIndex == 0">
                {{ $t("Outbound flight details") }}
              </h3>
              <h3 v-if="this.ways == 'two_way' && tripIndex == 1">
                {{ $t("Return flight details") }}
              </h3>
            </div>
            <div class="col-lg-3">
              <!-- Launch -->
              <div class="form-group">
                <label class="form-label">{{ $t("Take-off airport") }}</label>
                <Multiselect
                  label="title"
                  track-by="status"
                  :clear-on-select="true"
                  select-label=""
                  :placeholder="$t('Launch')"
                  v-model="trip.searchValueLaunch"
                  :options="launchSearch"
                  @keyup="searchLaunch"
                  :custom-label="airportLabel"
                  :hide-selected="true"
                ></Multiselect>
              </div>
              <!-- Launch -->
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label>{{ $t("Take-off date") }}</label>
                <Datepicker
                  v-model="trip.Take_off_date"
                  enable-seconds
                  locale="en-US"
                  time-picker-inline
                  :preview-format="format"
                />
                <!--                <span-->
                <!--                  v-for="error in v$.trips.$each[tripIndex].Take_off_date-->
                <!--                    .$errors"-->
                <!--                  class="text-danger"-->
                <!--                  :key="error.$uid"-->
                <!--                  >{{ $t(error.$message) }}</span-->
                <!--                >-->
              </div>
            </div>
            <div class="col-lg-3">
              <!-- return -->
              <div class="form-group">
                <label class="form-label">{{ $t("Arrival Time") }}</label>
                <Calendar
                  class="col-lg-12 w-100"
                  v-model="trip.arrival_time"
                  showIcon
                  iconDisplay="input"
                  timeOnly
                  hourFormat="12"
                >
                  <template #inputicon="{ clickCallback }">
                    <i class="pi pi-clock" @click="clickCallback" />
                  </template>
                </Calendar>
              </div>
            </div>
            <div class="col-lg-3">
              <!-- return -->
              <div class="form-group">
                <label class="form-label">{{ $t("Arrival airport") }}</label>
                <Multiselect
                  :placeholder="$t('back')"
                  label="title"
                  track-by="status"
                  :clear-on-select="true"
                  select-label=""
                  v-model="trip.searchValueReturn"
                  :options="returnSearch"
                  @keyup="searchReturn"
                  :custom-label="airportLabel"
                  :hide-selected="true"
                ></Multiselect>
                <!--                <span-->
                <!--                  v-for="error in v$.trips.$each[tripIndex].searchValueReturn-->
                <!--                    .$errors"-->
                <!--                  class="text-danger"-->
                <!--                  :key="error.$uid"-->
                <!--                  >{{ $t(error.$message) }}</span-->
                <!--                >-->

                <!--                <div-->
                <!--                  class="resultSearch"-->
                <!--                  v-if="-->
                <!--                    trip.searchValueReturn.length > 2 && returnSearch.length-->
                <!--                  "-->
                <!--                >-->
                <!--                  <h6 class="head_search">{{ $t("back") }}</h6>-->
                <!--                  <ul class="hotels">-->
                <!--                    <li-->
                <!--                      v-for="(returns, index) in returnSearch"-->
                <!--                      :key="index"-->
                <!--                      @click="-->
                <!--                        sendReturnName(-->
                <!--                          returns.title == null ? '' : returns.title,-->
                <!--                          returns.code == null ? '' : returns.code,-->
                <!--                          tripIndex,-->
                <!--                        )-->
                <!--                      "-->
                <!--                    >-->
                <!--                      <div class="hotel_image">-->
                <!--                        {{ returns.code == null ? "" : returns.code }}-->
                <!--                      </div>-->
                <!--                      <div class="texts">-->
                <!--                        <p class="title">-->
                <!--                          {{ returns.title == null ? "" : returns.title }}-->
                <!--                          &lt;!&ndash; {{ returns.title == null ? "" : launch.returns }} &ndash;&gt;-->
                <!--                        </p>-->
                <!--                        <p class="country">-->
                <!--                          {{-->
                <!--                            returns.country == null ? "" : returns.country.title-->
                <!--                          }}-->
                <!--                          - -->
                <!--                          {{ returns.city == null ? "" : returns.city.title }}-->
                <!--                        </p>-->
                <!--                      </div>-->
                <!--                    </li>-->
                <!--                  </ul>-->
                <!--                </div>-->
              </div>
              <!-- return -->
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label>{{ $t("Arrival date") }}</label>
                <Datepicker
                  v-model="trip.Arrival_date"
                  enable-seconds
                  locale="en-US"
                  time-picker-inline
                  :preview-format="format"
                />
                <!--                <span-->
                <!--                  v-for="error in v$.trips.$each[tripIndex].Arrival_date-->
                <!--                    .$errors"-->
                <!--                  class="text-danger"-->
                <!--                  :key="error.$uid"-->
                <!--                  >{{ $t(error.$message) }}</span-->
                <!--                >-->
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label>{{ $t("Departure Time") }}</label>
                <Calendar
                  class="col-lg-12 w-100"
                  v-model="trip.departure_time"
                  showIcon
                  iconDisplay="input"
                  timeOnly
                  hourFormat="12"
                >
                  <template #inputicon="{ clickCallback }">
                    <i class="pi pi-clock" @click="clickCallback" />
                  </template>
                </Calendar>
              </div>
            </div>
            <!--                <span-->
            <!--                  v-for="error in v$.trips.$each[tripIndex].Departure_date-->
            <!--                    .$errors"-->
            <!--                  class="text-danger"-->
            <!--                  :key="error.$uid"-->
            <!--                  >{{ $t(error.$message) }}</span-->
            <!--                >-->
            <div class="col-12">
              <div class="trip_type d-flex align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    name="trip_type"
                    :id="`trip_type1${tripIndex}`"
                    value="direct"
                    v-model="trip.trip_type"
                  />
                  <label
                    class="form-check-label mx-2"
                    :for="`trip_type1${tripIndex}`"
                  >
                    {{ $t("Direct flight") }}
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    name="trip_type"
                    :id="`trip_type2${tripIndex}`"
                    value="stop"
                    v-model="trip.trip_type"
                  />
                  <label
                    class="form-check-label mx-2"
                    :for="`trip_type2${tripIndex}`"
                  >
                    {{ $t("stops") }}
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 my-4" v-if="trip.trip_type == 'stop'">
              <div
                class="row align-items-center"
                v-for="(stop, index) in trip.stops"
                :key="index"
              >
                <div class="col-lg-3">
                  <!-- Launch -->
                  <div class="form-group">
                    <label class="form-label">{{ $t("First Stop") }}</label>
                    <Multiselect
                      type="text"
                      class="form-control"
                      :placeholder="$t('First Stop')"
                      v-model="stop.launch_title"
                      :options="stopSearchArr"
                      :hide-selected="true"
                      @keyup="stopSearch"
                      :custom-label="airportLabel"
                    ></Multiselect>
                  </div>
                  <!-- Launch -->
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label>{{ $t("Arrival date") }}</label>
                    <Datepicker
                      v-model="stop.arrival_date"
                      enable-seconds
                      locale="en-US"
                      time-picker-inline
                      :preview-format="format"
                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label>{{ $t("Departure Date") }}</label>
                    <Datepicker
                      v-model="stop.departure_date"
                      enable-seconds
                      locale="en-US"
                      time-picker-inline
                      :preview-format="format"
                    />
                  </div>
                </div>
                <div
                  class="col-lg-3"
                  v-if="index == '0'"
                  @click="add_stop(tripIndex)"
                >
                  <div class="circle">
                    <i class="fa-solid fa-plus"></i>
                  </div>
                </div>
                <div
                  class="col-lg-3"
                  v-else
                  @click="remove_stop(index, tripIndex)"
                >
                  <div class="circle">
                    <i class="fa-solid fa-minus"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="Permissible_weight">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label>{{
                        $t("Permissible weight inside the aircraft")
                      }}</label>
                      <input
                        type="number"
                        class="form-control"
                        :placeholder="
                          $t(
                            'enter Permissible weight inside the aircraft (kg)',
                          )
                        "
                        v-model="trip.weight_bag"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3">
                    <div class="add_weight">
                      <i class="fa-solid fa-circle-plus"></i>
                      <span @click="add_bag(tripIndex)">{{
                        $t("Adding additional weight inside the safe")
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="row align-items-center"
                  v-for="(bag, index) in trip.bags"
                  :key="index"
                >
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label>{{ $t("The first bag") }}</label>
                      <input
                        type="number"
                        class="form-control"
                        :placeholder="$t('the first bag weight')"
                        v-model="bag.weight"
                      />
                    </div>
                  </div>
                  <div
                    class="col-lg-3"
                    v-if="index == '0'"
                    @click="add_bag(tripIndex)"
                  >
                    <div class="circle">
                      <i class="fa-solid fa-plus"></i>
                    </div>
                  </div>
                  <div
                    class="col-lg-3"
                    v-else
                    @click="remove_bag(index, tripIndex)"
                  >
                    <div class="circle">
                      <i class="fa-solid fa-minus"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="trip_details">
          <Accordion :activeIndex="0">
            <AccordionTab
              class="col-lg-6 col-12 traveler_data"
              v-for="traveler in travelers"
              :key="traveler.id"
              :header="traveler.type === 1 ? $t('Adult') : $t('Child')"
            >
              <clerck :ourTraveler="traveler" />
            </AccordionTab>
          </Accordion>
        </div>
      </div>
      <div class="container py-3">
        <button type="submit" class="btn PrimaryButton w-100">
          {{ $t("save data") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import Multiselect from "vue-multiselect";
import clerck from "@/components/flight/clerck.vue";
import axios from "axios";
import moment from "moment";
import Datepicker from "vuejs3-datepicker";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Calendar from "primevue/calendar";

export default {
  components: {
    Multiselect,
    clerck,
    Datepicker,
    AddFavDailog,
    Accordion,
    AccordionTab,
    Calendar,
  },
  name: "flight-booking",
  data() {
    return {
      v$: useVuelidate(),
      clients: [],
      clientsTypes: [],
      clientsTypeValue: {},
      suppliers: [],
      supplierValue: {},
      flightId: this.$route.params.id,
      ways: "one_way",
      adults_number: 0,
      purchase_price_adt: null,
      sell_price_adt: null,
      children_number: 0,
      purchase_price_chd: null,
      sell_price_chd: null,
      babies_number: 0,
      purchase_price_inf: null,
      sell_price_inf: null,
      totalCost: 0,
      costAfterTax: 0,
      taxCost: 0,
      totalSelling: 0,
      sellingAfterTax: 0,
      taxSelling: 0,
      cabin_codes: [
        {
          id: 1,
          title: "First",
        },
        {
          id: 2,
          title: "Business",
        },
        {
          id: 3,
          title: "Standard",
        },
        {
          id: 4,
          title: "Premium",
        },
        {
          id: 5,
          title: "Class",
        },
      ],
      cabin_code: "",
      statusValue: {
        id: 0,
        title: this.$t("basically"),
        code: "basically",
      },
      statuses: [
        {
          id: 0,
          title: this.$t("basically"),
          code: "basically",
        },
        {
          id: 1,
          title: this.$t("confirmed"),
          code: "confirmed",
        },
        {
          id: 2,
          title: this.$t("accepted"),
          code: "accepted",
        },
        {
          id: 3,
          title: this.$t("updated"),
          code: "updated",
        },
        {
          id: 4,
          title: this.$t("canceled"),
          code: "canceled",
        },
      ],
      checkin: null,
      companies: [],
      company_value: "",
      providers: [],
      provider_value: {},
      searchValueLaunch: "",
      searchValueReturn: "",
      searchValueReturnCode: "",
      searchValueLaunchCode: "",
      launchSearch: [],
      returnSearch: [],
      returnReturnFlight: "",
      stopSearchArr: [],
      trip_number: null,
      reservation_number: null,
      option_date: null,
      withTax: "notTax",
      trips: [
        {
          searchValueLaunch: "",
          searchValueLaunchCode: "",
          searchValueReturn: "",
          searchValueReturnCode: "",
          Arrival_date: null,
          Take_off_date: null,
          launch_time: null,
          arrive_time: null,
          trip_type: "direct",
          stops: [
            {
              launch_id: 1,
              launch_title: "",
              launch_code: "",
              arrival_date: null,
              arrival_time: null,
              departure_date: null,
              departure_time: null,
            },
          ],
          weight_bag: null,
          bags: [
            {
              weight: 20,
            },
          ],
        },
      ],
      travelers: [],
      tax: 0,
    };
  },
  computed: {
    validate() {
      if (this.ways === "one_way") {
        return (
          this.company_value &&
          this.provider_value &&
          this.cabin_code &&
          this.trip_number &&
          this.statusValue &&
          (this.sell_price_adt ||
            this.purchase_price_chd ||
            this.sell_price_chd ||
            this.purchase_price_inf ||
            this.sell_price_inf) &&
          this.travelers.every((traveler) => {
            return (
              traveler.first_name &&
              traveler.last_name &&
              traveler.nickname &&
              traveler.email &&
              traveler.phone &&
              traveler.birth_day &&
              traveler.IdentityDocID &&
              traveler.IdentityDocTypeCode &&
              traveler.ContactInfoRefID
            );
          }) &&
          this.trips.every((trip) => {
            return (
              trip.searchValueLaunch &&
              trip.Take_off_date &&
              trip.searchValueReturn &&
              trip.Arrival_date &&
              trip.weight_bag &&
              trip.bags.every((bag) => bag.weight)
            );
          })
        );
      } else {
        return (
          this.company_value &&
          this.provider_value &&
          this.cabin_code &&
          this.trip_number &&
          this.statusValue &&
          (this.sell_price_adt ||
            this.purchase_price_chd ||
            this.sell_price_chd ||
            this.purchase_price_inf ||
            this.sell_price_inf) &&
          this.travelers.every((traveler) => {
            return (
              traveler.first_name &&
              traveler.last_name &&
              traveler.nickname &&
              traveler.email &&
              traveler.phone &&
              traveler.birth_day &&
              traveler.IdentityDocID &&
              traveler.IdentityDocTypeCode &&
              traveler.ContactInfoRefID
            );
          }) &&
          this.trips.every((trip) => {
            return (
              trip.searchValueLaunch &&
              trip.Take_off_date &&
              trip.searchValueReturn &&
              trip.Arrival_date &&
              trip.weight_bag &&
              trip.bags.every((bag) => bag.weight)
            );
          })
        );
      }
    },
  },
  validations() {
    return {
      company_value: { required },
      provider_value: { required },
      cabin_code: { required },
      clientsTypeValue: { required },
      supplierValue: { required },
      trip_number: { required },
      statusValue: { required },
      // trips: {
      //   $each: helpers.forEach({
      //     searchValueLaunch: { required },
      //     searchValueReturn: { required },
      //     Take_off_date: { required },
      //     Arrival_date: { required },
      //     weight_bag: { required },
      //     bags: {
      //       $each: helpers.forEach({
      //         weight: { required },
      //       }),
      //     },
      //   }),
      // },
      // sell_price_adt: { required },
      // purchase_price_chd: { required },
      // sell_price_chd: { required },
      // purchase_price_inf: { required },
      // sell_price_inf: { required },
    };
  },

  methods: {
    // fetch company
    fetchFlightCompanies(page = 1) {
      axios.get("/flight_companies?page=" + page).then(({ data }) => {
        this.companies = data.data.data;
      });
    },
    // fetch suppliers data
    FetchProviders() {
      axios.post("/fetch_consumer_by_role", { role: 2 }).then(({ data }) => {
        this.providers = data.data.data;
        // console.log(this.providers)
      });
    },
    //Search Hotels
    searchLaunch(event) {
      const searchText = { title: event.target.value };
      axios
        .post(`/search_airports`, searchText)
        .then((res) => (this.launchSearch = res.data.data))
        .catch((err) => console.log(err));
    },
    FetchSuppliers(client_type_id) {
      if (client_type_id != undefined) {
        axios
          .post("/fetch_clients_by_client_tpye_id", {
            client_type_id: client_type_id.id,
          })
          .then(({ data }) => {
            this.suppliers = data.data;
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.suppliers = [];
          });
      }
    },

    searchReturn(event) {
      const searchText = { title: event.target.value };
      //   console.log(searchText)
      axios
        .post(`/search_airports`, searchText)
        .then(
          (res) => (this.returnSearch = res.data.data),
          // (this.citiesSearch = res.data.data.cities)
        )
        .catch((err) => console.log(err));
      // console.log(searchText);
      console.log(this.returnSearch);
      // console.log(this.citiesSearch);
    },
    // fetch launch
    sendLaunchName(launchTitle, launchCode, tripIndex) {
      // this.trips[tripIndex].launchTitle = launchTitle;
      this.trips[tripIndex].searchValueLaunch = launchTitle;
      this.trips[tripIndex].searchValueLaunchCode = launchCode;

      this.launchSearch = [];
      // console.log(this.launchSearch)
      // console.log(this.searchValueLaunch)
    },
    // fetch return
    sendReturnName(returnTitle, returnCode, tripIndex) {
      // this.trips[tripIndex].returnTitle = returnTitle;
      this.trips[tripIndex].searchValueReturn = returnTitle;
      this.trips[tripIndex].searchValueReturnCode = returnCode;

      this.returnSearch = [];
    },
    stopSearch(event) {
      const searchText = { title: event.target.value };
      axios
        .post(`/search_airports`, searchText)
        .then((res) => (this.stopSearchArr = res.data.data))
        .catch((err) => console.log(err));
    },
    sendLaunchNameStop(stopId, index, launchTitle, launchCode, tripIndex) {
      this.trips[tripIndex].stops[index].launch_id = stopId;
      this.trips[tripIndex].stops[index].launch_title = launchTitle;
      this.trips[tripIndex].stops[index].launch_code = launchCode;

      this.stopSearchArr = [];
    },
    minusAdults() {
      if (this.adults_number > 0) {
        this.adults_number = this.adults_number - 1;
        this.removeClient(1);
      }
    },
    addAdults() {
      this.adults_number = this.adults_number + 1;
      this.addClient(1);
    },
    minusChildren() {
      if (this.children_number > 0) {
        this.children_number = this.children_number - 1;
        this.removeClient(2);
      }
    },
    addChildren() {
      this.children_number = this.children_number + 1;
      this.addClient(2);
    },
    minusBabies() {
      if (this.babies_number > 0) {
        this.babies_number = this.babies_number - 1;
        this.removeClient(3);
      }
    },
    addBabies() {
      this.babies_number = this.babies_number + 1;
      this.addClient(3);
    },
    add_bag(tripIndex) {
      this.trips[tripIndex].bags.push({ weight: null });
    },
    remove_bag(index, tripIndex) {
      this.trips[tripIndex].bags.splice(index, 1);
    },
    add_stop(tripIndex) {
      this.trips[tripIndex].stops.push({
        launch_id: null,
        launch_title: "",
        launch_code: "",
        arrival_date: null,
        arrival_time: null,
        departure_date: null,
        departure_time: null,
      });
    },
    remove_stop(index, tripIndex) {
      this.trips[tripIndex].stops.splice(index, 1);
    },
    addClient(clientType) {
      this.travelers.push({
        id: this.travelers.length + 1,
        first_name: "",
        last_name: "",
        type: clientType,
        nickname: "",
        phone: null,
        email: "",
        birth_day: null,
        IdentityDocTypeCode: "",
        ContactInfoRefID: "",
        IdentityDocID: "",
        Expiry_date: null,
      });
    },
    removeClient(clientType) {
      let index;
      this.travelers.forEach((traveler) => {
        if (traveler.type == clientType) {
          index = this.travelers.indexOf(traveler);
        }
      });
      this.travelers.splice(index, 1);
    },
    Add_EditFlight() {
      if (this.flightId != undefined) {
        this.editFlight();
      } else {
        this.createFlight();
      }
    },
    // add flight
    async createFlight() {
      // if(this.ways=="one_way")

      this.v$.$validate();
      if (this.v$.$error) {
        return;
      }
      let FlightData = {
        client_type: this.clientsTypeValue.id,
        client_id: this.supplierValue.id,
        consumer_id: this.provider_value.id,
        flight_companie_id: this.company_value.id,
        trip_num: this.trip_number,
        total_trip_cost: this.totalCost,
        trip_cost_with_tax: this.costAfterTax,
        trip_tax: this.taxCost,
        total_sell_price: this.totalSelling,
        sell_cost_with_tax: this.sellingAfterTax,
        sell_tax: this.taxSelling,
        type_tax: this.withTax,

        reservation_status: this.statusValue.code,
        trip_degree: this.cabin_code.title,
        flight_type: this.ways,

        num_adt: this.adults_number,
        purchase_price_adt: this.purchase_price_adt,
        sell_price_adt: this.sell_price_adt,

        num_chd: this.children_number,
        purchase_price_chd: this.purchase_price_chd,
        sell_price_chd: this.sell_price_chd,

        num_inf: this.babies_number,
        purchase_price_inf: this.purchase_price_inf,
        sell_price_inf: this.sell_price_inf,
      };

      if (this.option_date) {
        FlightData.option_date = moment(this.option_date).format("YYYY-MM-DD");
      }

      FlightData.trips = this.trips.map((trip) => {
        return {
          departure: trip.searchValueLaunch.code,
          arrival: trip.searchValueReturn.code,
          launch_date: moment(trip.Take_off_date).format("YYYY-MM-DD"),
          launch_time: moment(trip.Take_off_date).format("hh:mm"),
          arrive_date: moment(trip.Arrival_date).format("YYYY-MM-DD"),
          arrive_time: moment(trip.Arrival_date).format("hh:mm"),
          tripe_type: trip.trip_type,
          weight_bag: trip.weight_bag,
          bags: trip.bags,
          stops: trip.stops.map((stop) => {
            return {
              stopover: stop.launch_code,
              arrival_date: moment(stop.arrival_date).format("YYYY-MM-DD"),
              arrival_time: moment(stop.arrival_date).format("hh:mm:ss"),
              departure_date: moment(stop.departure_date).format("YYYY-MM-DD"),
              departure_time: moment(stop.departure_date).format("hh:mm:ss"),
            };
          }),
        };
      });

      if (this.travelers.length > 0) {
        FlightData.clients = this.travelers.map((traveler) => {
          return {
            first_Name: traveler.first_name,
            Sur_name: traveler.last_name,
            NameTitle: traveler.nickname,
            type:
              traveler.type == 1 ? "ADT" : traveler.type == 2 ? "CHD" : "INF",
            Birthdate: moment(traveler.birth_day).format("YYYY-MM-DD"),
            IdentityDocID: traveler.IdentityDocID,
            IdentityDocTypeCode: traveler.IdentityDocTypeCode,
            ContactInfoRefID: traveler.ContactInfoRefID,
            phone_number: traveler.phone
              .split(" ")
              .slice(1)
              .join(" ")
              .replace(/\s/g, ""),
            CountryDialingCode: traveler.phone.split(" ")[0].replace("+", ""),
            email: traveler.email,
          };
        });
      }

      axios
        .post("/flight", FlightData)
        .then(({ data }) => {
          // console.log(data);
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/reservation/show");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    async fetchTax() {
      await axios.get("/fetch_organization_tax").then(({ data }) => {
        this.tax = data.data.tax;
      });
    },
    calcTotalCost() {
      if (this.withTax == "notTax") {
        this.totalCost =
          this.adults_number * this.purchase_price_adt +
          this.children_number * this.purchase_price_chd +
          this.babies_number * this.purchase_price_inf;
        this.costAfterTax = Math.round(
          (this.adults_number * this.purchase_price_adt +
            this.children_number * this.purchase_price_chd +
            this.babies_number * this.purchase_price_inf) *
            (1 + this.tax / 100),
        );
        this.taxCost =
          this.costAfterTax -
          (this.adults_number * this.purchase_price_adt +
            this.children_number * this.purchase_price_chd +
            this.babies_number * this.purchase_price_inf);
      } else {
        this.costAfterTax =
          this.adults_number * this.purchase_price_adt +
          this.children_number * this.purchase_price_chd +
          this.babies_number * this.purchase_price_inf;
        this.taxCost =
          this.costAfterTax -
          Math.round(this.costAfterTax / (1 + this.tax / 100));
        this.totalCost = Math.round(this.costAfterTax / (1 + this.tax / 100));
      }
    },
    calcTotalSelling() {
      if (this.withTax == "notTax") {
        this.totalSelling =
          this.adults_number * this.sell_price_adt +
          this.children_number * this.sell_price_chd +
          this.babies_number * this.sell_price_inf;
        this.sellingAfterTax = Math.round(
          (this.adults_number * this.sell_price_adt +
            this.children_number * this.sell_price_chd +
            this.babies_number * this.sell_price_inf) *
            (1 + this.tax / 100),
        );
        this.taxSelling =
          this.sellingAfterTax -
          (this.adults_number * this.sell_price_adt +
            this.children_number * this.sell_price_chd +
            this.babies_number * this.sell_price_inf);
      } else {
        this.sellingAfterTax =
          this.adults_number * this.sell_price_adt +
          this.children_number * this.sell_price_chd +
          this.babies_number * this.sell_price_inf;
        this.taxSelling =
          this.sellingAfterTax -
          Math.round(this.sellingAfterTax / (1 + this.tax / 100));
        this.totalSelling = Math.round(
          this.sellingAfterTax / (1 + this.tax / 100),
        );
      }
    },
    airportLabel(airport) {
      return `${airport.title} - ${airport.code}`;
    },
    fetchFlightData() {
      axios.get(`/flight/${this.flightId}`).then(({ data }) => {
        console.log(data);
        this.ways = data.data.flight_type;
        this.adults_number = data.data.num_adt;
        this.children_number = data.data.num_chd;
        this.babies_number = data.data.num_inf;
        this.clientsTypeValue = data.data.client_type;
        this.supplierValue = data.data.client_id;
        this.provider_value = data.data.supplier;
        this.company_value = data.data.flight_company;
        this.trip_number = data.data.trip_num;
        this.reservation_number = data.data.reservation_num;
        this.totalCost = data.data.total_trip_cost;
        this.costAfterTax = data.data.trip_cost_with_tax;
        this.taxCost = data.data.trip_tax;
        this.totalSelling = data.data.total_sell_price;
        this.sellingAfterTax = data.data.sell_cost_with_tax;
        this.taxSelling = data.data.sell_tax;
        this.withTax = data.data.type_tax;
        this.statuses.forEach((element) => {
          if (element.code == data.data.reservation_status) {
            this.statusValue = element;
          }
        });
        this.cabin_codes.forEach((ele) => {
          if (ele.title == data.data.trip_degree) {
            this.cabin_code = ele;
          }
        });
        this.ways = data.flight_type;

        this.purchase_price_adt = parseInt(data.data.purchase_price_adt);
        this.sell_price_adt = parseInt(data.data.sell_price_adt);
        this.adults_number = parseInt(data.data.num_adt);
        this.children_number = parseInt(data.data.num_chd);
        this.purchase_price_chd = parseInt(data.data.purchase_price_chd);
        this.sell_price_chd = parseInt(data.data.sell_price_chd);
        this.babies_number = parseInt(data.data.num_inf);
        this.purchase_price_inf = parseInt(data.data.purchase_price_inf);
        this.sell_price_inf = parseInt(data.data.sell_price_inf);

        this.option_date = data.data.option_date;

        this.trips = data.data.trips.map((trip) => {
          return {
            searchValueLaunch: trip.airport_arrival_name,
            searchValueLaunchCode: trip.departure,
            searchValueReturn: trip.airport_departure_name,
            searchValueReturnCode: trip.arrival,
            Take_off_date: `${trip.launch_date} ${trip.launch_time}`, // .............. time
            Arrival_date: `${trip.arrive_date} ${trip.arrive_time}`, // ............... time
            trip_type: trip.tripe_type,
            weight_bag: trip.weight_bag,
            bags: trip.bags.map((bag) => {
              return {
                weight: bag.weight,
              };
            }),
            stops: trip.stops.map((stop) => {
              return {
                launch_code: stop.stopover,
                launch_title: stop.stopover,
                arrival_date: `${stop.arrival_date} ${stop.arrival_time}`, /// time ............
                departure_date: `${stop.departure_date} ${stop.departure_time}`, //// time ............
              };
            }),
          };
        });

        this.travelers = data.data.client.map((client) => {
          return {
            first_name: client.first_Name,
            last_name: client.Sur_name,
            nickname: client.NameTitle,
            type: client.type == "ADT" ? 1 : client.type == "CHD" ? 2 : 3, /// ........
            birth_day: client.Birthdate,
            IdentityDocID: client.IdentityDocID,
            ContactInfoRefID: client.ContactInfoRefID,
            IdentityDocTypeCode: client.IdentityDocTypeCode,
            email: client.email,
            phone: `+${client.CountryDialingCode} ${client.phone_number}`,
          };
        });
      });
    },
    fetchClientType() {
      axios
        .post("/index_client_types", {
          is_portal: this.$route.params.id ? this.is_portal : 0,
        })
        .then(({ data }) => {
          this.clientsTypes = data.data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.clientsTypes = [];
        });
    },
    editFlight() {
      let FlightData = {
        consumer_id: this.provider_value.id,
        flight_companie_id: this.company_value.id,
        trip_num: this.trip_number,

        total_trip_cost: this.totalCost,
        trip_cost_with_tax: this.costAfterTax,
        trip_tax: this.taxCost,
        total_sell_price: this.totalSelling,
        sell_cost_with_tax: this.sellingAfterTax,
        sell_tax: this.taxSelling,
        type_tax: this.withTax,

        reservation_status: this.statusValue.code,
        trip_degree: this.cabin_code.title,
        flight_type: this.ways,

        num_adt: this.adults_number,
        purchase_price_adt: this.purchase_price_adt,
        sell_price_adt: this.sell_price_adt,

        num_chd: this.children_number,
        purchase_price_chd: this.purchase_price_chd,
        sell_price_chd: this.sell_price_chd,

        num_inf: this.babies_number,
        purchase_price_inf: this.purchase_price_inf,
        sell_price_inf: this.sell_price_inf,
      };

      if (this.option_date) {
        FlightData.option_date = moment(this.option_date).format("YYYY-MM-DD");
      }

      FlightData.trips = this.trips.map((trip) => {
        return {
          departure: trip.searchValueLaunchCode,
          arrival: trip.searchValueReturnCode,
          launch_date: moment(trip.Take_off_date).format("YYYY-MM-DD"),
          launch_time: moment(trip.Take_off_date).format("hh:mm"),
          arrive_date: moment(trip.Arrival_date).format("YYYY-MM-DD"),
          arrive_time: moment(trip.Arrival_date).format("hh:mm"),
          tripe_type: trip.trip_type,
          weight_bag: trip.weight_bag,
          bags: trip.bags,
          stops: trip.stops.map((stop) => {
            return {
              stopover: stop.launch_code,
              arrival_date: moment(stop.arrival_date).format("YYYY-MM-DD"),
              arrival_time: moment(stop.arrival_date).format("h:mm"),
              departure_date: moment(stop.departure_date).format("YYYY-MM-DD"),
              departure_time: moment(stop.departure_date).format("h:mm"),
            };
          }),
        };
      });

      if (this.travelers.length > 0) {
        FlightData.clients = this.travelers.map((traveler) => {
          return {
            first_Name: traveler.first_name,
            Sur_name: traveler.last_name,
            NameTitle: traveler.nickname,
            type:
              traveler.type == 1 ? "ADT" : traveler.type == 2 ? "CHD" : "INF",
            Birthdate: moment(traveler.birth_day).format("YYYY-MM-DD"),
            IdentityDocID: traveler.IdentityDocID,
            IdentityDocTypeCode: traveler.IdentityDocTypeCode,
            ContactInfoRefID: traveler.ContactInfoRefID,
            phone_number: traveler.phone
              .split(" ")
              .slice(1)
              .join(" ")
              .replace(/\s/g, ""),
            CountryDialingCode: traveler.phone.split(" ")[0].replace("+", ""),
            email: traveler.email,
          };
        });
      }

      axios
        .put(`/flight/${this.flightId}`, FlightData)
        .then(({ data }) => {
          // console.log(data)
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/reservation/show");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetchFlightCompanies();
    this.FetchProviders();
    this.fetchTax();
    this.fetchClientType();

    if (this.flightId != undefined) {
      this.fetchFlightData();
    }
  },
  watch: {
    adults_number() {
      this.calcTotalCost();
      this.calcTotalSelling();
    },
    children_number() {
      this.calcTotalCost();
      this.calcTotalSelling();
    },
    babies_number() {
      this.calcTotalCost();
      this.calcTotalSelling();
    },
    purchase_price_adt() {
      this.calcTotalCost();
    },
    purchase_price_chd() {
      this.calcTotalCost();
    },
    purchase_price_inf() {
      this.calcTotalCost();
    },
    sell_price_adt() {
      this.calcTotalSelling();
    },
    sell_price_chd() {
      this.calcTotalSelling();
    },
    sell_price_inf() {
      this.calcTotalSelling();
    },
    withTax() {
      this.calcTotalCost();
      this.calcTotalSelling();
    },
    ways(value) {
      if (value == "two_way") {
        this.trips.push({
          searchValueLaunch: "",
          searchValueLaunchCode: "",
          searchValueReturn: "",
          searchValueReturnCode: "",
          Arrival_date: null,
          Take_off_date: null,
          trip_type: "direct",
          stops: [
            {
              launch_id: 1,
              launch_title: "",
              launch_code: "",
              arrival_data: null,
              arrival_time: null,
              departure_data: null,
              departure_time: null,
            },
          ],
          weight_bag: null,
          bags: [
            {
              weight: 20,
            },
          ],
        });
        this.trips.length = 2;
      } else {
        this.trips.length = 1;
      }
    },
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.flight_booking {
  margin-top: 40px;
  margin-bottom: 40px;
}
.form-group {
  margin-bottom: 10px;
  label {
    font-size: 13px;
    color: #656b78;
    margin-bottom: 10px;
  }
  input {
    font-size: 13px;
  }
}
.form-check {
  margin-top: 20px;
  margin-bottom: 20px;
  input:checked {
    color: #0a909b;
    & ~ label {
      font-family: "bold";
    }
  }
  label {
    font-size: 13px;
    color: #0b1427;
  }
}
.client-data {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  .trip_way {
    margin-bottom: 20px;
    .form-group {
      position: relative;
      label {
        width: 100%;
        background-color: #fff;
        text-align: center;
        padding: 20px 20px;
        border-radius: 20px;
        border: 1px solid #d0d2d7;
        color: #9ca2ac;
        position: relative;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        .icon_circle {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: #d0d2d736;
          text-align: center;
          line-height: 30px;
          // padding: 5px;
        }
      }
      input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        opacity: 0;
        &:checked ~ label {
          border: 1px solid #0a909b;
          color: #0a909b;
          background-color: #0a8f9b0c;
          .icon_circle {
            background-color: #0a8f9b36;
          }
        }
      }
    }
  }
}
.adults {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  .counter {
    display: flex;
    align-items: center;
  }
  .form-group {
    margin: 0;
    margin-inline-start: 10px;
  }
  .form-control {
    width: 74px;
    font-size: 10px;
    border-color: #d0d2d7;
    padding: 5px;
  }
}
.circle {
  width: 30px;
  height: 30px;
  border: 1px solid #0a909b;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #0a909b;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  &.gray {
    border: 1px solid #9ca2ac;
    color: #9ca2ac;
  }
}
.trip_details {
  margin-top: 30px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  h3 {
    font-family: "bold";
    font-size: 20px;
    color: #0b1427;
    margin-bottom: 20px;
  }
  .form-group {
    position: relative;
    .resultSearch {
      position: absolute;
      z-index: 4444;
      top: 40px;
      background-color: #fff;
      height: 300px;
      width: 100%;
      overflow-y: auto;
      .head_search {
        font-family: "bold";
        background-color: $PrimaryColor;
        color: #fff;
        padding: 0.5rem;
        margin-bottom: 0;
      }
      .hotels,
      .cities {
        margin-bottom: 0;
        li {
          display: flex;
          align-items: center;
          font-family: "regular";
          font-size: 0.8rem;
          padding: 0.5rem;
          border-bottom: 1px solid #292d32;
          transition: 0.7s ease-in-out;
          cursor: pointer;
          &:last-child {
            border-bottom: unset;
          }
          .hotel_image {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            margin-inline-end: 0.5rem;
          }
          i {
            font-size: 1.5rem;
            color: #b5b5b5;
            margin-inline-end: 0.5rem;
          }
          .title,
          .country {
            margin-bottom: 0;
          }
          .title {
            font-size: 0.8rem;
          }
          .country {
            font-size: 0.7rem;
            color: #292d32;
          }
          &:hover {
            transition: 0.7s ease-in-out;
            background-color: rgba($color: $PrimaryColor, $alpha: 0.1);
          }
        }
      }
    }
  }
}
.trip_type {
  display: flex;
}
.Permissible_weight {
  .add_weight {
    color: #0a909b;
    cursor: pointer;
    i {
      margin-inline-end: 10px;
    }
  }
}
.details_reservation {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}
.adult_text {
  width: 100px;
}
.trip_cost {
  label {
    font-size: 10px;
  }
}
input#reservation_number {
  width: unset;
  width: 100% !important;
}
</style>
