<template>
  <section class="card_style">
    <div class="main_header mb-3">
      <h4>
        <router-link :to="`/hotels/book_offline/${$route.params.book_id}`" target="_blank"
          >{{ methods?.data[0]?.book_number }} &nbsp;</router-link
        >{{ $t("payment_methods_for_hotel") }}
      </h4>
      <div class="total_amount">
        <p class="mx-3 text-primary">
          {{ $t("total_reservation_value") }}:
          {{ hotelData.total_selling_price }}
        </p>
        <p class="mx-3 text-success">
          {{ $t("total_amount_paid") }}: {{ hotelData?.client_paid_amount }}
        </p>
        <p class="mx-3 text-danger">
          {{ $t("total_amount_remaining") }}:
          {{ hotelData?.client_remaining_amount }}
        </p>
      </div>
      <div class="post_container">
        <PostToAccount
          :book_id="hotelData.bookId"
          :book_type="'hotel'"
          :baseBookId="$route.params.id"
          :organizationAccounts="organizationAccounts"
          :organizationSafe="organizationSafe"
          :remaining_amount="hotelData.client_remaining_amount"
          :paid_amount="hotelData.client_paid_amount"
          @fetchPaymentMethods="fetchPaymentMethods"
          v-if="
            hotelData.client_remaining_amount !== 0 &&
            this.$route.params.status != 3
          "
        />
        <PostToAccountSupplier
          :book_id="hotelData.bookId"
          :book_type="'hotel'"
          :baseBookId="$route.params.id"
          :organizationAccounts="organizationAccounts"
          :organizationSafe="organizationSafe"
          :remaining_amount="hotelData.remaining_amount"
          :paid_amount="hotelData.paid_amount"
          @fetchPaymentMethods="fetchPaymentMethods"
          v-if="
            hotelData.supplier_remaining_amount !== 0 &&
            this.$route.params.status != 3
          "
        />
      </div>
    </div>
    <div class="payment_methods">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>{{ $t("id") }}</th>
            <th>{{ $t("employee_name") }}</th>
            <th>{{ $t("account_type") }}</th>
            <th>{{ $t("account") }}</th>
            <th>{{ $t("amount") }}</th>
            <th>{{ $t("transaction_type") }}</th>
            <th>{{ $t("single_client") }} / {{ $t("supplier") }}</th>
            <th>{{ $t("paid_type") }}</th>
            <th>{{ $t("date") }}</th>
            <th>{{ $t("acountant_name") }}</th>
            <th>{{ $t("description") }}</th>
            <th>{{ $t("status") }}</th>
            <th>{{ $t("attachments") }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 14" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) in methods?.data" :key="item.id">
            <td>{{ item?.id }}</td>
            <td>{{ item?.employee_name }}</td>
            <td>{{ $t(item?.account_type) }}</td>
            <td>{{ item?.account }}</td>
            <td>{{ item?.amount }}</td>
            <td>{{ $t(item?.transaction_type) }}</td>
            <td>{{ item?.consumer }}</td>
            <td>{{ $t(item?.paid_type) }}</td>
            <td>{{ item?.created_at }}</td>
            <td>{{ item?.action_depay }}</td>
            <td>
              <ShowDesciptionPayment :invoices="item?.invoices" v-if="item?.paid_type == 'Invoices'" />
            </td>
            <td>
              <span
                :class="[
                  'badge mx-1',
                  item?.status === 0
                    ? 'bg-primary'
                    : item?.status === 1
                      ? 'bg-warning text-dark'
                      : item?.status === 2
                        ? 'bg-success'
                        : item?.status === 3
                          ? 'bg-danger text-white'
                          : '',
                ]"
              >
                {{
                  item?.status === 0
                    ? $t("initial")
                    : item?.status === 1
                      ? $t("review_underway")
                      : item?.status === 2
                        ? $t("acceptable_batch")
                        : item?.status === 3
                          ? $t("Packs_rejected")
                          : ""
                }}
              </span>
            </td>
            <td>
              <!-- Button trigger modal -->
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                v-if="item?.attachments.length"
                :data-bs-target="`#attachmentModal${item?.id}`"
              >
                <i class="fa-solid fa-images"></i>
              </button>

              <!-- Modal -->
              <div
                class="modal fade"
                :id="`attachmentModal${item?.id}`"
                tabindex="-1"
                aria-labelledby="attachmentModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-body">
                      <img
                        v-for="(image, index) in item?.attachments"
                        :key="index"
                        :src="image.image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div
                v-if="item?.status != 2 && item?.status != 3"
                class="btn btn-sm btn-danger mx-1 w-auto"
                @click="deleteAccount(index)"
              >
                <i class="fa-solid fa-trash"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="methods"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchPaymentMethods"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import ShowDesciptionPayment from "@/components/offline/show_description_payment.vue";
import PostToAccount from "@/components/offline/PostToAccount.vue";
import PostToAccountSupplier from "@/components/offline/PostToAccountSupplier.vue";
import Swal from "sweetalert2";
import pagination from "laravel-vue-pagination";
import Skeleton from "primevue/skeleton";
export default {
  name: "hotelsPaymentMethods",
  components: { PostToAccount, PostToAccountSupplier, pagination, Skeleton, ShowDesciptionPayment },
  props: ["post_to_account_data"],
  data() {
    return {
      type: "hotel",
      methods: {
        data: []
      },
      organizationAccounts: [],
      organizationSafe: [],
      hotelData: {
        bookId: null,
        client_remaining_amount: null,
        client_paid_amount: null,
        total_selling_price: null,
      },
      subInvoiceOptions: [],
      visible: false,
      loading: true,
    };
  },
  methods: {
    async fetchPaymentMethods(page = 1) {
      await axios
        .post(`fetch_all_post_to_account_upon_book?page=${page}`, {
          book_type: this.type,
          base_booking_id: parseInt(this.$route.params.id),
        })
        .then((response) => {
          this.methods = response.data.data;
          this.loading = false;
        });
    },
    fetchOrganizationAccounts() {
      axios
        .post("/fetch_my_organization_accounts", { type: 1 })
        .then(({ data }) => {
          this.organizationAccounts = data.data.data;
        });
    },
    fetchOrganizationSafe() {
      axios
        .post("/fetch_my_organization_accounts", { type: 2 })
        .then(({ data }) => {
          this.organizationSafe = data.data.data;
        });
    },
    deleteAccount(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.methods.data[index].id;
          const fromClick = true;
          axios
            .delete(`/delete_post_to_account_order/${delete_id}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.methods.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
    fetchMainInvoices() {
      const data = {
        base_booking_id: this.$route.params.id,
        is_main: 1,
      };
      axios.post(`/fetch_sub_invoices`, data).then(({ data }) => {
        this.hotelData.bookId = data.data.id;
        this.hotelData.client_remaining_amount =
          data.data[0]?.client_remaining_amount;
        this.hotelData.client_paid_amount = data.data[0]?.client_paid_amount;
        this.hotelData.total_selling_price = data.data[0]?.client_total_amount;
      });
    },
  },
  mounted() {
    this.fetchPaymentMethods();
    this.fetchOrganizationAccounts();
    this.fetchOrganizationSafe();
    this.fetchMainInvoices();
  },
};
</script>

<style lang="scss" scoped>
.post_container {
  width: fit-content;
  margin-right: auto;
}
.total_amount {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-bottom: 0;
  }
}
.payment_methods {
  .table {
    .modal {
      img {
        width: fit-content !important;
        max-width: 100% !important;
        object-fit: scale-down;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
