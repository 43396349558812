<template>
  <section class="credintial_page card_style">
    <div class="main_header_style">
      <h4>{{ $t("add") }} Credintial</h4>
    </div>
    <ul class="types">
      <li>
        <div class="form-group service_type">
          <input
            type="radio"
            name="service_type"
            v-model="service_type"
            class="wizard-required"
            id="defaultHotel"
            value="1"
            required
            hidden
          />
          <label for="defaultHotel"> travel Soft tech</label>
        </div>
      </li>
      <li>
        <div class="form-group service_type">
          <input
            type="radio"
            name="service_type"
            v-model="service_type"
            class="wizard-required"
            id="defaultFlight"
            value="2"
            required
            hidden
          />
          <label for="defaultFlight"> {{ $t("anther_company") }}</label>
        </div>
      </li>
    </ul>
    <!-- exist company -->
    <form class="form_topic" @submit.prevent="addCradientsOrg()">
      <div class="row" v-if="service_type == 2">
        <!-- start input group -->
        <div class="col-12 col-lg-9">
          <div class="input-group">
            <label class="form-label"> {{ $t("choose_company") }}</label>
            <Multiselect
              v-model="select_company"
              :options="organization.map((company) => company.id)"
              :custom-label="
                (opt) => organization.find((x) => x.id == opt).name
              "
            
              :clear-on-select="true"
              :placeholder="$t('choose_company')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-5">
          <div class="input-group">
            <label class="form-label"> {{ $t("user_name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('user_name')"
              v-model="name"
              required
            />
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-4">
          <div class="input-group">
            <label class="form-label"> {{ $t("password") }}</label>
            <input
              type="password"
              class="form-control"
              v-model="password"
              required
            />
          </div>
        </div>
        <!-- finish input group -->
      </div>
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-9">
          <div class="input-group">
            <label class="form-label">
              {{ $t("country_didnt_avaliabel") }}</label
            >
            <Multiselect
              v-model="select_country"
              :options="countries.map((country) => country.id)"
              :custom-label="(opt) => countries.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('country_didnt_avaliabel')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
          </div>
        </div>
        <!-- finish input group -->
        <div class="col-12 col-lg-12">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" v-model="active" />
            {{ $t("cancel_credintal") }}
          </label>
        </div>
        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("save") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
    <!-- exist company -->
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  name: "add-provider",
  components: {
    Multiselect,
  },
  data() {
    return {
      service_type: 1,
      isChecked: false,
      active: false,
      name: "",
      text: "",
      select_country: "",
      select_company: "",
      revenue_type: "",
      password: "",
      countries: [],
      organization: [],
    };
  },
  methods: {
    //fetch country
    fetch_country() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
        // console.log(this.services);
      });
    },

    //fetch orgniztion
    fetch_organization() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/fetch_organizations").then(({ data }) => {
        this.organization = data.data;
        // console.log(this.organization);
      });
    },

    // add orignal company
    async addCradientsOrg() {
      let id = this.$route.params.id;
      const formData = new FormData();
      if (this.service_type == 2) {
        formData.append("organization_id", this.select_company);
        formData.append("name", this.name);
        formData.append("password", this.password);
      }
      formData.append("active", this.active ? 1 : 0);
      formData.append("provider_id", id);
      this.select_country.forEach((value) => {
        formData.append("country_ids[]", value);
      });

      try {
        const response = await axios.post("/credentials", formData);
        Swal.fire({
          title: "",
          text: response.data.message,
          icon: "success",
        });
        this.$router.push(`/credintial/${id}`);
      } catch (error) {
        Swal.fire({
          title: "",
          text: error.response.data.message,
          icon: "error",
        });
      }
    },
  },
  created() {
    this.fetch_country();
    this.fetch_organization();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_provider.scss";
</style>
