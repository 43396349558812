<template>
    <Button class="btn btn-success" @click="openDialog">
      <i class="fa-regular fa-circle-check"></i>
    </Button>
    <Dialog
      v-model:visible="visible"
      modal
      :dismissableMask="true"
      :style="{ width: '50rem', height: '50vh' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <pre>{{ wallets.wallet_id }}</pre>
      <div class="d-flex align-items-center justify-content-start gaps">
        <p class="my-3">
          {{ $t("Request a shipping amount") }}:
          <span class="mx-3 fw-bold">{{ amount }}</span>
        </p>
        <p class="my-3">
          {{ $t("From a company") }}:
          <span class="mx-3 fw-bold">{{ orgName }}</span>
        </p>
      </div>
      <div class="d-flex w-100 gap-3 my-4">
        <div class="d-flex justify-content-end flex-column w-50">
          <p class="mb-1">{{ $t("Choose account") }}</p>
          <multiselect
            v-model="value_wallet"
            :options="types"
            :custom-label="nameWithLang"
            :placeholder="$t('Choose account')"
            label="title"
            track-by="id"
            select-label=""
            :hide-selected="true"
          ></multiselect>
        </div>
        <div class="d-flex justify-content-end flex-column w-50">
          <p class="mb-1">{{ $t("Amount transferred") }}</p>
          <div class="card flex justify-content-center">
            <InputText
              :placeholder="$t('Amount transferred')"
              type="number"
              v-model="value"
            />
          </div>
        </div>
      </div>
      <Button
        @click="acceptRequest"
        class="accept_wallet_btn"
        :label="$t('save')"
      />
    </Dialog>
  </template>
  <!-- @click="acceptRequest(index)" -->
  <script>
  import Dialog from "primevue/dialog";
  import Button from "primevue/button";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Multiselect from "vue-multiselect";
  import InputText from "primevue/inputtext";
  
  export default {
    name: "acceptwallet-dialog",
    data() {
      return {
        visible: false,
        value_wallet: "",
        value: "",
        wallet: this.wallets,
        index: this.ined,
  
        types: [],
      };
    },
    components: {
      Dialog,
      Button,
      Multiselect,
      InputText,
    },
    methods: {
      openDialog() {
        this.visible = true;
        this.fetch_organization_accounts();
      },
      acceptRequest() {
        this.visible = false;
        Swal.fire({
          title: this.$t("check_accept"),
          text: this.$t("are_you_sure_you_want_to_accept"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.$t("back"),
          confirmButtonText: this.$t("confirm_accept"),
        }).then((result) => {
          if (result.isConfirmed) {
            let wallet_id = {
              wallet_id: this.wallet?.id,
              to_account_id: this.value_wallet.id,
              amount: this.value,
            };
            axios
              .post("/sharks/accept_wallet_portal", wallet_id)
              .then(({ data }) => {
                if (data.status == true) {
                  Swal.fire("", data.message, "success");
                  this.visible = false;
                  this.fetchWalet();
                } else {
                  Swal.fire("", data.message, "error");
                }
                return data;
              });
          }
        });
      },
      fetch_organization_accounts() {
        axios
          .post("/fetch_my_organization_accounts", {
            type: 1,
          })
          .then(({ data }) => {
            this.types = data.data.data;
            // console.log(data.data.data, "www");
          });
      },
    },
    mounted() {
      // fetch_organization_accounts is now called when the dialog is opened
      // this.fetch_organization_accounts();
    },
    props: ["amount", "orgName", "ined", "wallets", "fetchWalet"],
  };
  </script>
  
  <style scoped>
  .gaps {
    gap: 12rem;
  }
  .accept_wallet_btn {
    margin-top: auto;
    margin-inline-start: auto;
    display: block;
    border-radius: 5px;
  }
  </style>
  