<template>
  <div class="w-100 justify-content-center">
    <Button
      class="border-0 cursor-pointer btn btn-white w-100"
      @click="visible = true"
    >
      <form class="cursor-pointer" role="search">
        <input
          class="form-control me-2 cursor-pointer"
          type="search"
          :placeholder="$t('search')"
        />
      </form>
    </Button>
    <Dialog
      v-model:visible="visible"
      modal
      :dismissableMask="true"
      :header="$t('search')"
      :style="{ width: '50rem', height: '30rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <div class="form-group nas">
        <input
          type="search"
          class="form-control"
          :placeholder="$t('search')"
          v-model="searchValue"
          @keyup="searchHotels"
        />

        <div class="resultSearch" v-if="searchValue.length > 0">
          <h6 class="head_search" v-if="clientSearch.length">
            {{ $t("clients") }}
          </h6>

          <Card
            v-for="client in clientSearch"
            :key="client.id"
            @click="clientsDetails(client.id)"
            class="mt-3 border shadow"
          >
            <template #content>
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div class="suolie_name">
                  <p class="mb-0">
                    {{ client.name }}
                  </p>
                  <p>{{ client.id }}</p>
                </div>

                <div class="suolie_name">
                  <p v-if="client.supplier_type">
                    {{ client.supplier_type }}
                  </p>
                  <p v-else>-</p>
                </div>

                <div class="suolie_name">
                  <p v-if="client.balance">{{ client.balance }}</p>
                  <p v-else>-</p>
                </div>
              </div>
            </template>
          </Card>

          <!-- Hotels -->
          <h6 class="head_search" v-if="offlineBookingHotel.length">
            {{ $t("Offline Book") }}
          </h6>

          <div v-if="offlineBookingHotel.length" class="w-100">
            <Card
              v-for="hotel in offlineBookingHotel"
              :key="hotel.id"
              @click="offlineDetails(hotel.id)"
              :class="`${
                hotel.book_offline_status == 0
                  ? 'bg-warnings'
                  : hotel.book_offline_status == 1
                  ? 'bg-primarys'
                  : hotel.book_offline_status == 2
                  ? 'bg-successs'
                  : hotel.book_offline_status == 3
                  ? 'bg-dangers'
                  : hotel.book_offline_status == 4
                  ? 'bg-dangers'
                  : hotel.book_offline_status == 5
                  ? 'bg-dangers'
                  : hotel.book_offline_status == 6
                  ? 'bg-dangers'
                  : ''
              } mt-3 border shadow`"
            >
              <template #content>
                <div
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div class="Book_number">
                    <p class="mb-0">{{ hotel.book_number }}</p>

                    <span
                      :class="`${
                        hotel.book_offline_status == 0
                          ? 'text-warning'
                          : hotel.book_offline_status == 1
                          ? 'text-primary'
                          : hotel.book_offline_status == 2
                          ? 'text-success'
                          : hotel.book_offline_status == 3
                          ? 'text-danger'
                          : hotel.book_offline_status == 4
                          ? 'text-danger'
                          : hotel.book_offline_status == 5
                          ? 'text-danger'
                          : hotel.book_offline_status == 6
                          ? 'text-danger'
                          : ''
                      }`"
                    >
                      {{
                        hotel.book_offline_status == 0
                          ? $t("pending")
                          : hotel.book_offline_status == 1
                          ? $t("tentative")
                          : hotel.book_offline_status == 2
                          ? $t("confirmed")
                          : hotel.book_offline_status == 3
                          ? $t("canceled")
                          : hotel.book_offline_status == 4
                          ? $t("amended")
                          : hotel.book_offline_status == 5
                          ? $t("reconfirmed")
                          : hotel.book_offline_status == 6
                          ? $t("draft for supplier")
                          : ""
                      }}
                    </span>
                  </div>

                  <div class="hotel">
                    <ul v-if="hotel.hotels.length">
                      <li v-for="item in hotel.hotels" :key="item.id">
                        {{ item?.title ? item?.title : "-" }}
                      </li>
                    </ul>
                    <ul v-else>
                      <li>{{ hotel.hotel?.title }}</li>
                    </ul>
                  </div>
                  <div class="suppliers">
                    <ul v-if="hotel.suppliers.length">
                      <li v-for="item in hotel.suppliers" :key="item.id">
                        {{ item.name ? item.name : "-" }}
                      </li>
                    </ul>
                    <ul v-else>
                      <li>-</li>
                    </ul>
                  </div>

                  <!-- <div class="suppliers">
                    <ul v-if="hotel.suppliers.length">
                      <li v-for="item in hotel.suppliers" :key="item.id">
                        {{ item.name }}
                      </li>
                    </ul>
                    <ul v-else>
                      <li>-</li>
                    </ul>
                  </div> -->

                  <div class="payment">
                    <p>
                      {{
                        hotel.post_status == 0
                          ? "unposted"
                          : hotel.post_status == 1
                          ? "posted"
                          : ""
                      }}
                    </p>
                    <p
                      :class="`${
                        hotel.client_payment_status == 'UNPAID'
                          ? 'text-danger'
                          : hotel.client_payment_status == 'PAID'
                          ? 'text-success'
                          : hotel.client_payment_status == 'PARTIALLY_PAID'
                          ? 'text-warning'
                          : ''
                      }`"
                    >
                      {{
                        hotel.client_payment_status == "UNPAID"
                          ? $t("unpaid")
                          : hotel.client_payment_status == "PAID"
                          ? $t("paid")
                          : hotel.client_payment_status == "PARTIALLY_PAID"
                          ? $t("partially_paid")
                          : "-"
                      }}
                    </p>
                  </div>
                  <div class="paid">
                    <p class="mb-0">
                      {{ hotel.client_paid_amount }}
                    </p>
                  </div>
                  <div class="clients mb-0">
                    <p>{{ hotel.client.name }}</p>
                    <!-- <p class="mb-0">
                      {{
                        hotel.payment_method == 0
                          ? $t("cash_on_delivery")
                          : hotel.payment_method == 1
                          ? $t("Pay immediately")
                          : "-"
                      }}
                    </p> -->
                  </div>
                </div>
              </template>
            </Card>
          </div>
          <!-- Hotels -->

          <!-- supliers -->
          <h6 class="head_search" v-if="supplierSearch.length">
            {{ $t("supliers") }}
          </h6>

          <Card
            v-for="suplier in supplierSearch"
            :key="suplier.id"
            @click="supliersDetails(suplier.id)"
            class="mt-3 border shadow"
          >
            <template #content>
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div class="suolie_name">
                  <p class="mb-0">
                    {{ suplier.name }}
                  </p>
                  <p>{{ suplier.id }}</p>
                </div>

                <div class="suolie_name">
                  <p v-if="suplier.supplier_type">
                    {{ suplier.supplier_type?.title }}
                  </p>
                  <p v-else>-</p>
                </div>

                <div class="suolie_name">
                  <p v-if="suplier.balance">{{ suplier.balance }}</p>
                  <p v-else>-</p>
                </div>
              </div>
            </template>
          </Card>
          <!-- supliers -->
        </div>
        <div
          class="d-flex justify-content-center align-items-center"
          v-if="searchValue.length == 0"
          style="height: 34vh"
        >
          <p>{{ $t("No recent searches") }}</p>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import axios from "axios";
import Button from "primevue/button";

import Card from "primevue/card";

export default {
  data() {
    return {
      visible: false,
      searchValue: "",
      clientSearch: [],
      offlineBookingHotel: [],
      supplierSearch: [],
      defaultImage: require("@/assets/media/image/icon/user.png"),
    };
  },
  components: {
    Dialog,
    Button,
    Card,
  },
  methods: {
    searchHotels(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/search_in_all_system`, searchText)
        .then(
          (res) => (
            (this.offlineBookingHotel = res.data.data.offlineBookingHotel),
            (this.clientSearch = res.data.data.client),
            (this.supplierSearch = res.data.data.supplier)
          )
        )
        .catch((err) => console.log(err));
    },
    supliersDetails(id) {
      this.$router.push({ path: `/suppliers/details/${id}` });
      this.visible = false;
    },
    clientsDetails(id) {
      this.$router.push({ path: `/clients/details/${id}` });
      this.visible = false;
    },
    offlineDetails(id) {
      this.$router.push({ path: `/hotels/book_offline/${id}` });
      this.visible = false;
    },
  },
};
</script>
<style scoped lang="scss">
.resultSearch {
  margin: 4rem 0 0 0;
  position: absolute;
  top: 51px !important;
  background-color: #fff;
  height: 300px;
  min-width: 96% !important;
  overflow-y: auto;
  left: 0rem !important;
  border-bottom: 1px solid black !important;
}
.bg-dangers {
  background-color: rgba(226, 53, 53, 0.15) !important;
}
.bg-warnings {
  background-color: rgba(197, 112, 22, 0.15) !important;
}
.bg-primarys {
  background-color: rgba(10, 144, 155, 0.15) !important;
}
.bg-successs {
  background-color: rgba(136, 198, 75, 0.18) !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 10px;
}
.Book_number {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.hotel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.suppliers {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.payment {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.suolie_name {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.clients {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
