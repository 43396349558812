<template>
  <div class="tab-pane fade" id="images-tab-pane" role="tabpanel" aria-labelledby="images-tab" tabindex="0">
    <div class="images">
        <h4 class="title">{{$t("images")}}</h4>
        <div class="show_images">
            <img v-for="(img, index) in images" :src="img" :key="index" :alt="index" data-bs-toggle="modal" :data-bs-target="`#imageModal${index}`">
        </div>


        <!-- Modal -->
        <div class="modal fade" v-for="(img, index) in images" :key="index" :id="`imageModal${index}`" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="head_gallery">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <p class="length">{{images.length}} / {{index+1}}</p>
                        </div>
                        <div class="image">
                            <i class="fa-solid fa-chevron-right" data-bs-toggle="modal" :data-bs-target="`#imageModal${index+1}`" :class="{disable:index+1 == images.length}"></i>
                            <img :src="images[index]" :alt="index">
                            <i class="fa-solid fa-chevron-left" data-bs-toggle="modal" :data-bs-target="`#imageModal${index-1}`" :class="{disable:index+1 == 1}"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
    name: "images-hotel",
    props: {
        images: Array,
    },

}
</script>

<style lang="scss" scoped>
    @import "@/view/dashboard/hotels/_hotels.scss";
</style>