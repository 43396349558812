<script setup>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import pagination from "laravel-vue-pagination";
import Multiselect from "vue-multiselect";
import { onMounted, ref } from "vue";
import axios from "axios";
import Swal from "sweetalert2";

const addedHotels = ref([]);
const hotels = ref([]);
const hotelValue = ref([]);
const fetchHotels = async () => {
  await axios
    .post("/fetch_hotels_belongs_to_Organizations")
    .then((response) => {
      addedHotels.value = response.data.data.data;
      console.log(addedHotels.value);
    })
    .catch((error) => {
      console.log(error);
    });
};
// const fetchHotelsData = async () => {
//   await axios
//     .post("/fetch_hotels_not_belongs_to_Organizations")
//     .then((response) => {
//       hotels.value = response.data.data.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };
const assignHotels = async () => {
  const formData = new FormData();
  hotelValue.value.forEach((ele) => {
    formData.append("organization_hotel_ids[]", ele.id);
  });

  await axios
    .post("/assign_hotels_for_organization", formData)
    .then((response) => {
      if (response.status) fetchHotels();
      Swal.fire({
        title: "Success",
        text: response.data.message,
        icon: "success",
      });
      hotelValue.value = [];
    })
    .catch((error) => {
      console.log(error);
    });
};
onMounted(async () => {
  await fetchHotels();
});
const addFav = async (hotel) => {
  const formData = new FormData();
  formData.append("hotel_id", hotel);
  await axios
    .post("/favorite_organization_hotel", formData)
    .then((response) => {
      if (response.status) fetchHotels();
      Swal.fire({
        title: "Success",
        text: response.data.message,
        icon: "success",
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

const deleteHotel = async (hotel) => {
  await axios
    .delete(`/delete_organization_hotel/${hotel}`)
    .then((response) => {
      if (response.status) fetchHotels();
      Swal.fire({
        title: "Success",
        text: response.data.message,
        icon: "success",
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
const searchHotel = (event) => {
  const searchText = { word: event.target.value };
  if (event.target.value.length) {
    axios
      .post(`/fetch_hotels_not_belongs_to_Organizations`, searchText)
      .then((res) => (hotels.value = res.data.data.data || []))
      .catch((err) => console.log(err));
  }
};
</script>

<template>
  <section class="card_style">
    <div class="d-flex gap-2 px-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
      <h4 class="head-we">{{ $t("add_home_hotels") }}</h4>
    </div>
    <div class="flex">
      <div class="row">
        <form @submit.prevent="assignHotels" class="col-8">
          <div class="row">
            <div class="form-group col-8">
              <label class="form-label">{{ $t("hotel_name") }}</label>
              <Multiselect
                v-model="hotelValue"
                :options="hotels"
                label="title"
                track-by="id"
                @keyup="searchHotel"
                :clear-on-select="true"
                multiple
                :placeholder="$t('choose_country')"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
            </div>
            <div class="form-group col-4">
              <label class="form-label">{{ $t("add") }}</label>
              <button type="submit" class="btn PrimaryButton">
                {{ $t("add") }}
              </button>
            </div>
          </div>
        </form>
        <div class="col-4">
          <!--          <div class="form-group">-->
          <!--            <label class="form-label">{{ $t("all_hotels") }}</label>-->
          <!--            <input-->
          <!--              type="checkbox"-->
          <!--              class="form-check-input"-->
          <!--              :checked="all"-->
          <!--              disabled-->
          <!--              v-model="all"-->
          <!--            />-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <h4 v-if="addedHotels.length">{{ $t("special_hotels") }}</h4>
    <div class="row">
      <div class="row">
        <div class="table-responsive">
          <table class="table contract">
            <thead>
              <tr>
                <th>{{ $t("ID") }}</th>
                <th>{{ $t("hotel") }}</th>
                <th>{{ $t("city") }}</th>
                <th>{{ $t("country") }}</th>
                <th>{{ $t("actions") }}</th>
              </tr>
            </thead>

            <!--            <tbody v-if="loading" class="text-center">-->
            <!--              <tr v-for="item in 10" :key="item">-->
            <!--                <td v-for="item in 5" :key="item">-->
            <!--                  <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>-->
            <!--                </td>-->
            <!--              </tr>-->
            <!--            </tbody>-->

            <tbody>
              <tr v-for="(item, index) in addedHotels" :key="index">
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.city_title }}</td>
                <td>{{ item.country_title }}</td>
                <td>
                  <div class="d-flex gap-2 align-items-center">
                    <button
                      type="button"
                      class="btn btn-outline-warning"
                      v-if="item.is_favorite === 0"
                      @click="addFav(item.id)"
                      v-tooltip.top="$t('add_to_faviorites')"
                    >
                      <i class="fa-solid fa-star"></i>
                    </button>

                    <button
                      type="button"
                      @click="deleteHotel(item.id)"
                      class="btn btn-outline-danger"
                      v-tooltip.top="$t('delete')"
                    >
                      <i class="fa-solid fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <pagination
          :data="addedHotels"
          class="mx-auto d-flex align-items-center justify-content-center pagination"
          @pagination-change-page="fetchHotels"
        >
          <template v-slot:prev-nav>&lt;</template>
          <template v-slot:next-nav>&gt;</template>
        </pagination>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss"></style>
