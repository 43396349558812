<template>
  <section class="card_style tree_organization">
    <div class="d-flex align-items-center gap-3 mb-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4>
        {{ $t("student-note") }}
      </h4>
    </div>

    <div class="row mb-2">
      <div class="col-12 col-lg-3">
        <div class="input-group stu">
          <datepicker
            v-model="from"
            name="from_date"
            :placeholder="$t('from')"
          ></datepicker>
        </div>
      </div>

      <div class="col-12 col-lg-3">
        <div class="input-group stu">
          <datepicker
            v-model="to"
            name="to_date"
            :placeholder="$t('to')"
          ></datepicker>
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="input-group stu">
          <Multiselect
            v-model="accountValue"
            :options="allBranch.map((account) => account.id)"
            :custom-label="(opt) => allBranch.find((x) => x.id == opt).title_ar"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
            :searchable="true"
            @update:model-value="Fetchbranch"
          >
          </Multiselect>
        </div>
      </div>
      <div class="d-flex align-items-center col-12 col-lg-2">
        <button
          class="SecondButton btn w-100 action d-flex align-items-center justify-content-center"
          @click="exportToExcel"
        >
          <i class="fa-solid fa-file-excel"></i>
          {{ $t("export_to_excel") }}
        </button>

        <button class="btn SecondButton mx-2" v-print="'#printMe'" title="PDF">
          <i class="fa-solid fa-file-pdf"></i>
        </button>
      </div>
    </div>
    <h4 v-if="branch.data === null">{{ $t("no_data") }}</h4>

    <div class="row" v-else>
      <div class="table-responsive" id="printMe">
        <HeaderPdf :title="$t('student-note')" :fromDate="from" :toDate="to" />
        <table class="table stu" id="RoomTable">
          <thead>
            <tr>
              <th class="no-print">ID</th>
              <th>{{ $t("date") }}</th>
              <th>{{ $t("account") }}</th>
              <th>{{ $t("the statement") }}</th>
              <th>{{ $t("the value") }}</th>
              <th>{{ $t("debtor") }}</th>
              <th>{{ $t("creditor") }}</th>
              <th>{{ $t("balance") }}</th>
              <th>{{ $t("clerck") }}</th>
              <th class="no-print">{{ $t("accounting_process") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="client in 10" :key="client">
              <td v-for="client in 10" :key="client">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(client, index) in branch.data" :key="index">
              <td class="no-print">{{ client.id }}</td>
              <td>{{ client.date }}</td>
              <td>{{ client.branch }}</td>
              <td>{{ client.report }}</td>
              <td>{{ client.amount }}</td>
              <td>{{ client.debtor }}</td>
              <td>{{ client.creditor }}</td>
              <td>{{ client.balance }}</td>
              <td>{{ client.employee }}</td>
              <td class="no-print">
                <button
                  @click="getTransferItem(client.daily_restrictions_id)"
                  class="btn btn-info"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasBottomDetails"
                  aria-controls="offcanvasBottom"
                  :title="$t('details')"
                >
                  {{ client.daily_restrictions_id }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="branch"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="Fetchbranch"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
  <dailyConstruct :item="item" />
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Datepicker from "vuejs3-datepicker";
import Multiselect from "vue-multiselect";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import dailyConstruct from "@/components/dailyConstruct/detailsOffCanvas.vue";
import * as XLSX from "xlsx";
import moment from "moment";
import Skeleton from "primevue/skeleton";
import HeaderPdf from "@/components/pdf/headerPdf.vue";

export default {
  name: "detials-branch",
  components: {
    pagination,
    Datepicker,
    Multiselect,
    AddFavDailog,
    dailyConstruct,
    Skeleton,
    HeaderPdf,
  },
  data() {
    return {
      branch: [],
      from: "",
      to: "",
      accountValue: "",
      allBranch: [],
      item: {},
      loading: true,
    };
  },
  methods: {
    async exportToExcel() {
      try {
        const response = await axios.post("/fetch_ledger", {
          type: 1,
        });
        const responseData = response.data.data.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "ledger.xlsx");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed, e.g., show a message to the user
      }
    },
    // fetch suppliers data
    Fetchbranch(page = 1) {
      const formData = new FormData();
      formData.append("branch_id", this.accountValue);
      if (this.from != "") {
        formData.append("from", moment(this.from).format("YYYY-MM-DD"));
      }
      if (this.to != "") {
        formData.append("to", moment(this.to).format("YYYY-MM-DD"));
      }
      formData.append("per_page", 10);

      axios.post(`/fetch_ledger?page=${page}`, formData).then(({ data }) => {
        this.branch = data.data;
        this.loading = false;
        // console.log(this.branch);
      });
    },

    getTransferItem(id) {
      axios.get(`/daily_restriction/${id}`).then(({ data }) => {
        this.item = data.data;
        // console.log(this.item);
      });
    },

    FetchAllbranch() {
      axios.post(`/fetch_all_branches`).then(({ data }) => {
        this.allBranch = data.data;
        // console.log(this.branch);
      });
    },
  },
  watch: {
    from: "Fetchbranch",
    to: "Fetchbranch",
  },
  created() {
    this.Fetchbranch();
    this.FetchAllbranch();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "../dailyConstructor/_constructor.scss";
.vuejs3-datepicker {
  width: 90%;
}

@media print {
  .no-print {
    display: none !important;
  }
  .table {
    thead {
      tr {
        th {
          padding-inline: 1.5rem !important;
        }
      }
    }
    tbody {
      tr {
        td {
          padding-inline: 1.5rem !important;
        }
      }
    }
  }
}
</style>
