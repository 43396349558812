<template>
  <section class="invoice invoice-ar">
    <!-- Head invoice -->
    <div class="head_invoice">
      <div class="book_status">
        <h6>قسيمة حجز فندقي</h6>
        <div class="chart"></div>
        <div class="chart"></div>
      </div>
      <div class="logo">
        <img :src="pills.organization?.default_image" />
      </div>
      <div class="book_number">
        <div class="chart"></div>
        <div class="chart"></div>
        <h6>
          رقم الحجز : 
          <span>#{{ pills.book_number }}</span>
        </h6>
      </div>
    </div>
    <!-- Head invoice -->

    <!-- client reservation data -->
    <dataInvoicePerson :pills="pills" /> 
    <!-- client reservation data -->

    <!-- welcome invoice data -->
    <!-- <div class="welcome_text">
      <h5>
        تحيات من <span>{{ pills.organization?.name }}</span>
      </h5>
      <div class="pragra">
        <p>
          بادئ ذي بدء ، نود أن نغتنم هذه الفرصة للترحيب بك
           في
          <strong> {{ pills.organization?.name }} </strong> والسياحة نحن
           يسعدني تأكيد الحجز التالي على أ
          <span>{{ book_status_name }}.</span>
        </p>
      </div>
    </div> -->
    <!-- welcome invoice data -->

    <!-- Table reservation -->
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="frist">الفندق</th>
            <th class="frist">نوع الغرفة</th>
            <th scope="col">الوجبة</th>
            <th scope="col">عدد الأشخاص</th>
            <th scope="col">تاريخ الدخول</th>
            <th scope="col">تاريخ الخروج</th>
            <th scope="col">عدد الليالي</th>
            <th scope="col">رقم التأكيد</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(room, index) in pills.rooms" :key="index">
            <td>{{room.hotel_name}}</td>
            <td>
              <p class="mb-0 fs-8">{{room.room_number}} X {{ room.room_name }}</p>
              <p class="mb-0 fs-8">{{ room.title_view }}</p>
            </td>
            <td>{{ room.meal_type?.title }}</td>
            <td>{{ room.guest_number }}</td>
            <td>
              <p class="mb-0">
                {{ room.check_in_date }}
              </p>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_in_date))
              }}</span>
            </td>
            <td>
              <P class="mb-0">
                {{ room.check_out_date }}
              </P>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_out_date))
              }}</span>
            </td>
            <td>{{ room.number_of_nights }}</td>
            <td>{{ room.hotel_reference }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Table reservation -->

    <!-- Terms & amount reservation -->
    <div class="amounts_terms" v-if="pills?.Conditions">
      <!-- Terms -->
      <Terms :terms="pills?.Conditions" />
      <!-- Terms -->
    </div>
    <!-- Terms & amount reservation -->

    <!-- Invoice Organization Data -->
    <div class="invoice_organiztion_data">
      <ul class="social_accounts">
        <li>
          <i class="fa-solid fa-globe"></i>
          <a :href="pills.organization?.website_link" target="_blank" class="text">{{ pills.organization?.website_link }}</a>
        </li>
        <li>
          <i class="fa-solid fa-envelope"></i>
          <a :href="`mailto:${pills.organization?.email}`" class="text">{{ pills.organization?.email }}</a>
        </li>
        <li>
          <i class="fa-solid fa-phone"></i>
          <a :href="`tel:${pills.organization?.phone}`" class="text">{{ pills.organization?.phone }}</a>
        </li>
        <li>
          <i class="fa-solid fa-location-dot"></i>
          <p class="text">{{ pills.organization?.address }}</p>
        </li>
      </ul>
      <div class="logo">
        <img :src="pills.organization?.default_image" />
        <employeeData :pills="pills" />
        <socialMedia :organization="pills.organization" />
      </div>
    </div>
    <!-- Invoice Organization Data -->

    <div class="footer_invoice"></div>
  </section>
</template>

<script>
import socialMedia from './component/socialMedia.vue';
import Terms from './component/termsAr.vue';
import dataInvoicePerson from './component/dataInvoicePersonAr.vue';
import employeeData from './component/employeeData.vue';

export default {
  name: "pill-hotel-confirm",
  props: {
    pills: Object,
    terms: String,
    total_selling_price_with_out_tax: String,
    selling_tax: String,
    total_selling_price: String,
    book_status_name: String,
  },
  components: {
    socialMedia,
    Terms,
    dataInvoicePerson,
    employeeData
  }
};
</script>

<style lang="scss" scoped>
@import "./_pill.scss";
.invoice {
  .invoice_organiztion_data {
    .social_accounts, .logo {
      width: 50% !important;
    }
  }
}
</style>
