<template>
  <section class="card_style">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />

      <h4>{{ $t("Rooming List Report") }}</h4>
    </div>
    <form class="row mb-2" @submit.prevent="fetchRoomingList">
      <div class="col-12 col-lg-4">
        <div class="form-control border-0">
          <label class="form-label">{{ $t("Hotel") }}</label>
          <Multiselect
            v-model="form.hotels"
            :options="hotels"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('Choose_hotel')"
            select-label=""
            :hide-selected="true"
            :multiple="true"
            :custom-label="formatHotelLabel"
            @keyup="searchHotel"
          >
          </Multiselect>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="form-control border-0">
          <label class="form-label">{{ $t("day") }}</label>
          <Datepicker
            v-model="form.day"
            name="from"
            :placeholder="$t('day')"
          ></Datepicker>
          <span class="input-errors" v-if="v$.form.day.$error">{{
            v$.form.day.$errors[0].$message
          }}</span>
        </div>
      </div>

      <div class="col-12 col-lg-2 d-flex align-items-center">
        <button type="submit" class="btn PrimaryButton mb-2">
          {{ $t("search") }} <i class="fa-solid fa-magnifying-glass ms-2"></i>
        </button>
      </div>

      <div
        v-if="reports.length > 0"
        class="col-lg-2 col-md-12 col-12 d-flex align-items-center justify-content-end"
      >
        <button class="btn SecondButton mx-2" v-print="'#printMe'" title="PDF">
          <i class="fa-solid fa-file-pdf"></i>
        </button>
      </div>
    </form>
    <div id="printMe" class="table">
      <HeaderPdf
        :title="$t('Rooming List Report')"
        :fromDate="form.day"
        :hotels="form.hotels"
        :toDate="''"
      />
      <table
        class="table table-bordered"
        id="content"
        v-if="reports.length > 0"
      >
        <thead>
          <tr>
            <th>{{ $t("serial") }}</th>
            <th>{{ $t("Guest Name") }}</th>
            <th>{{ $t("Rooms") }}</th>
            <th>{{ $t("Rooms Type") }}</th>
            <th>{{ $t("View") }}</th>
            <th>{{ $t("Meal plan") }}</th>
            <th>{{ $t("Room Number") }}</th>
            <th>{{ $t("Notes") }}</th>
          </tr>
        </thead>
        <tbody v-if="loading" class="text-center table_loader">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 4" class="text-center" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(item, index) in reports" :key="index">
            <td class="text-center">{{ index + 1 }}</td>
            <td class="text-center">{{ item.guest_name }}</td>
            <td class="text-center">{{ item.room_number }}</td>
            <td class="text-center">{{ item.room_type }}</td>
            <td class="text-center">{{ item.view_type }}</td>
            <td class="text-center">{{ item.meal_type?.title }}</td>
            <td class="text-center">{{ item.transaction_code }}</td>
            <td class="text-center">
              {{ item.book_notes == "undefined" ? "-" : item.book_notes }}
            </td>
          </tr>
        </tbody>
      </table>

      <div v-else>
        <div class="d-flex align-items-center justify-content-center">
          <img
            src="@/assets/media/image/no-data.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <p class="text-center my-5 fs-3">{{ $t("Please Enter Data") }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderPdf from "@/components/pdf/headerPdf.vue";

import Skeleton from "primevue/skeleton";

export default {
  data() {
    return {
      v$: useVuelidate(),
      loading: true,
      form: {
        hotels: [],
        from: "",
        to: "",
        day: new Date(),
      },
      hotels: [],
      reports: [],
    };
  },
  components: {
    AddFavDailog,
    Multiselect,
    Datepicker,
    Skeleton,
    HeaderPdf,
  },
  validations() {
    return {
      form: {
        day: { required },
      },
    };
  },
  methods: {
    searchHotel(event) {
      const searchText = { word: event?.target?.value };
      if (event?.target?.value.length) {
        axios
          .post(`/fetch_new_home_hotels`, searchText)
          .then((res) => (this.hotels = res.data.data.hotels))
          .catch((err) => console.log(err));
      } else {
        axios
          .post(`/fetch_new_home_hotels`)
          .then((res) => (this.hotels = res.data.data.hotels))
          .catch((err) => console.log(err));
      }
    },
    formatHotelLabel(hotel) {
      return `${hotel.title} -
                ${hotel.city_title}, ${hotel.country_title} (${hotel.id})`;
    },

    fetchRoomingList() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = true;
        const formData = new FormData();
        formData.append("day", moment(this.form.day).format("YYYY-MM-DD"));
        this.form.hotels.forEach((hotel) => {
          formData.append("hotel_ids[]", hotel.id);
        });
        axios
          .post("/fetch_rooms_from_offline_book_upon_hotel", formData)
          .then(({ data }) => {
            this.reports = data.data;
            this.loading = false;
            if (this.reports.length == 0) {
              // console.log("No Data Found In Selected Day");
              Swal.fire({
                icon: "error",
                title: this.$t("error"),
                text: this.$t("No Data Found In Selected Day"),
              });
            }

            // console.log(this.reports);
          });
      }
    },
  },
  mounted() {
    this.searchHotel();
    this.$store.state.hotelValue = null;
    this.$store.state.roomValue = null;
    this.$store.state.supplierValue = null;
    this.$store.state.client_id = null;
    this.$store.state.payment_status = null;
    this.$store.state.booking_status = null;
    this.$store.state.post_status = null;
    this.$store.state.city = null;
    this.$store.state.nationality = null;
    this.$store.state.book_number = null;
    this.$store.state.hotel_ref = null;
    this.$store.state.suplier_ref = null;
    this.$store.state.hotel_as_suplier = null;
    this.$store.state.employ = null;
    this.$store.state.meal = null;
    this.$store.state.rate = null;
    this.$store.state.client_category = null;
    this.$store.state.paymentMethod = null;
    this.$store.state.paymentSuplier = null;
    this.$store.state.clientType = null;
    this.$store.state.suplierType = null;
    this.$store.state.discountType = null;
    this.$store.state.guest = null;
    this.$store.state.share_amount = null;
  },
};
</script>

<style lang="scss" scoped></style>
