<template>
    <section class="setting">
        <div class="edit_custom">
            <div class="container">
                <div class="texts">
                    <h5 class="title_setup">{{$t("customize_selling_fees_companies")}}</h5>
                    <p class="text_setup">{{$t("can_customize_sales_companies_following_inputs")}}</p>
                </div>
                <form @submit.prevent="submitCustomCompanySetup()">
                    <ul class="types">
                        <li>
                            <div class="form-group service_type">
                                <input type="radio" name="service_type" v-model="Form.service_type" class="wizard-required" id="hotel" value="1" required hidden>
                                <label for="hotel"><i class="fa-solid fa-bell-concierge"></i> {{$t("hotels")}}</label>
                            </div>
                        </li>
                        <li>
                            <div class="form-group service_type">
                                <input type="radio" name="service_type" v-model="Form.service_type" class="wizard-required" id="flight" value="2" required hidden>
                                <label for="flight"><i class="fa-solid fa-plane"></i> {{$t("flight")}}</label>
                            </div>
                        </li>
                        <li>
                            <div class="form-group service_type">
                                <input type="radio" name="service_type" v-model="Form.service_type" class="wizard-required" id="transportation" value="3" required hidden>
                                <label for="transportation"><i class="fa-solid fa-bus"></i> {{$t("transportation")}}</label>
                            </div>
                        </li>
                        <li>
                            <div class="form-group service_type">
                                <input type="radio" name="service_type" v-model="Form.service_type" class="wizard-required" id="private_trips" value="4" required hidden>
                                <label for="private_trips"><i class="fa-solid fa-tree"></i> {{$t("private_trips")}}</label>
                            </div>
                        </li>
                    </ul>
                    <ul class="customize_by">
                        <li>
                            <div class="form-check">
                                <label class="form-check-label" for="country">
                                    <input type="radio" class="form-check-input" name="customization_type" id="country" v-model="Form.customization_type" value="1" checked>
                                    البلاد
                                </label>
                            </div>
                        </li>
                        <li>
                            <div class="form-check">
                                <label class="form-check-label" for="city">
                                    <input type="radio" class="form-check-input" name="customization_type" id="city" v-model="Form.customization_type" value="2">
                                    المدن
                                </label>
                            </div>
                        </li>
                        <li>
                            <div class="form-check">
                                <label class="form-check-label" for="company">
                                    <input type="radio" class="form-check-input" name="customization_type" id="company" v-model="Form.customization_type" value="3">
                                    الشركات
                                </label>
                            </div>
                        </li>
                    </ul>
                    <div class="row form-group">
                        <!-- <Dropdown v-model="country_ids" :options="countries" optionLabel="title" :placeholder="$t('choose_country')" class="w-full md:w-14rem" /> -->
                        
                        <!-- start input group -->
                        <div class="col-12">
                            <div class="form-group w-100">
                                <label class="form-label"> {{ $t("choose_country") }}</label>
                                <Multiselect
                                    v-model="Form.countries"
                                    track-by="id"
                                    label="title"
                                    :options="countries"
                                    :clear-on-select="true"
                                    :placeholder="$t('choose_country')"
                                    select-label=""
                                    :hide-selected="true"
                                >
                                </Multiselect>
                            </div>
                        </div>
                        <!-- finish input group -->

                        <div class="row" v-if="Form.customization_type == 2">
                            <!-- start input group -->
                            <div class="col-lg-6 col-12">
                                <div class="form-group w-100">
                                    <label class="form-label"> {{ $t("choose_country") }}</label>
                                    <Multiselect
                                        v-model="Form.countries"
                                        track-by="id"
                                        label="title"
                                        :options="countries"
                                        :clear-on-select="true"
                                        :placeholder="$t('choose_country')"
                                        select-label=""
                                        :hide-selected="true"
                                        @update:model-value="FetchCityFromCountry"
                                    >
                                    </Multiselect>
                                </div>
                            </div>
                            <!-- finish input group -->
                            
                            <!-- start input group -->
                            <div class="col-lg-6 col-12">
                                <div class="form-group w-100">
                                    <label class="form-label"> {{ $t("city") }}</label>
                                    <Multiselect
                                        v-model="Form.cities"
                                        track-by="id"
                                        label="title"
                                        :options="cities"
                                        :clear-on-select="true"
                                        :placeholder="$t('enter_city')"
                                        select-label=""
                                        :multiple="true"
                                        :hide-selected="true"
                                    >
                                    </Multiselect>
                                </div>
                            </div>
                            <!-- finish input group -->
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-12">
                                <label for="">{{$t("time_period")}}</label>
                                <datepicker v-model="from_date" name="from_date" :placeholder="$t('from')"></datepicker>
                            </div>
                            <div class="col-lg-6 col-md-12 col-12">
                                <label for="">{{$t("time_period")}}</label>
                                <datepicker v-model="to_date" name="to_date" :placeholder="$t('to')"></datepicker>
                            </div>
                                <!-- :disabled-dates="{ to: new Date(formatDate(this.from_date)), from: new Date() }" -->
                            <div class="col-lg-12 col-md-12 col-12 mt-4">
                                <div class="form-group w-100">
                                    <label for="">{{$t("supposed_profit_ratio")}}</label>
                                    <input type="number" name="" id="" class="form-control" v-model="Form.percentage" :placeholder="$t('determine_profit_rate_companies')">
                                    <select class="form-control" v-model="Form.type_profit">
                                        <option value="1" selected>{{$t("percentages")}}</option>
                                        <option value="2">{{$t("value")}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn PrimaryButton">{{$t("save")}}</button>
                </form>
            </div> 
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from "moment";
import Datepicker from 'vuejs3-datepicker';
import Multiselect from "vue-multiselect";
// import Dropdown from 'primevue/dropdown';

export default {
    name: "customize-company",
    data() {
        return {
            countries: [],
            cities: [],
            Form: {
                service_type: 1,
                customization_type: 1,
                percentage: 0,
                type_profit: 1,
                type: this.$route.params.type_id,
                countries: [],
                city_ids: [],
            },
            from_date: "",
            to_date: "",
        }
    },
    components: {
        Datepicker,
        Multiselect,
        // Dropdown
    },
    methods: {
        FetchCustomization() {
            let id = this.$route.params.id;
            axios.get(`/customization_settings/${id}`).then(({ data }) => {
                this.Form = data.data;
                this.from_date = data.data.from_date;
                this.to_date = data.data.to_date;
                // this.country_ids = data.data.countries;
                // this.city_ids = data.data.cities;
                // console.log(this.Form)
            });
        },
        //fetch country
        fetch_country() {
            const newLocal = this.$i18n.locale;
            axios.defaults.headers.common["Accept-Language"] = newLocal;
            axios.post("/fetch_countries").then(({ data }) => {
                this.countries = data.data;
                // console.log(this.countries);
            });
        },

        FetchCityFromCountry(value) {
                let basic_country_ids = {
                    country_id: [value],
                };
                const newLocal = this.$i18n.locale;
                axios.defaults.headers.common["Accept-Language"] = newLocal;
                axios
                .post("/fetch_cities_by_country_id", basic_country_ids)
                .then(({ data }) => {
                    this.cities = data.data;
                });
        },
        async submitCustomCompanySetup() {
            const formData = new FormData();
            Object.entries(this.Form).forEach(([key, value]) => {
                formData.append(key, value);
            });
            for (let index = 0; index < this.Form.countries.length; index++) {
                formData.append(`country_ids[${index}]`, this.Form.countries[index]);
            }
            if (this.Form.customization_type == 2) {
                for (let index = 0; index < this.Form.cities.length; index++) {
                    formData.append(`city_ids[${index}]`, this.Form.cities[index]);
                }
            }
            formData.append("from_date", moment(this.from_date).format("YYYY-MM-DD"));
            formData.append("to_date", moment(this.to_date).format("YYYY-MM-DD"));
            axios.post(`/fetch_organization_customization_settings/${this.$route.params.id}`, formData)
            .then((response) => {
                Swal.fire({
                    title: "",
                    text: response.data.message,
                    icon: "success",
                });
                // console.log(response.data.data)
                // this.refreshKey += 1;
                this.$router.push(`/setup_fees/${this.Form.type}`);
            })
            .catch((error) => {
                Swal.fire({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                });
            });
        },

        // formatDate(value) {
        //     return moment(value).format("DD, MM, YYYY");
        // },
    },
    created() {
        this.FetchCustomization();
        this.fetch_country();
        localStorage.removeItem("flightData");
        localStorage.removeItem("flightTrips");
        localStorage.removeItem("bookDataFlight");
        localStorage.removeItem("bookDetails");
        localStorage.removeItem("hotelData");
        localStorage.removeItem("bookData");
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
    @import "../_setup.scss";
</style>
