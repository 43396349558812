<template>
  <div v-if="qrCodeData" class="qrCodeData">
    <img :src="qrCodeData" alt="QR Code" />
    </div>
</template>

<script>
import QRCode from "qrcode-generator";

export default {
    name: "qrcode-invoice",
    data() {
        return {
            qrCodeData: null,
        }
    },
    props: {
        qr_code: String
    },
    methods: {
        generateQrCode() {
            const typeNumber = 0;
            const errorCorrectionLevel = "L";
            const qr = QRCode(typeNumber, errorCorrectionLevel);
            // this.qrCode = this.qrCode
            qr.addData(this.qrCode)
            // console.log(this.qrCode)
            // qr.addData(` Name: ${this.organizationName} \n Tax Organization Number: ${this.organizationTaxNumber} \n Invoice number: ${this.taxInvoiceNumber} \n Total selling price with out tax: ${this.totalSellingPriceWithOutTax} \n Selling tax: ${this.sellingTax} \n Total selling price: ${this.totalSellingPrice}`);
            qr.make();
            this.qrCodeData = qr.createDataURL();
        },
        handleOrganizationDataChange() {
            this.qrCode = this.qr_code;
            this.generateQrCode();
        },
    },
    watch: {
        qr_code: {
            handler: 'handleOrganizationDataChange',
            immediate: true,
            deep: true
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../_pill.scss";
</style>