<template>
  <!-- {{ visaData }} -->
  <div class="Transformation_seeting">
    <div class="container">
      <div class="row border">
        <div class="col-lg-8 col-md-8 col-6">
          <div class="d-flex align-items-center justify-content-start p-2">
            <div class="d-flex align-items-center justify-content-center mx-1">
              <img src="@/assets/media/image/icon/card-pos.svg" />
              <h6 class="hotel_settings m-0">{{ $t("VisaSettings") }}</h6>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-6 border-start border-end bg-price d-flex align-items-center justify-content-center"
        >
          <div class="d-flex align-items-center justify-content-start p-2">
            <div class="d-flex align-items-center justify-content-center mx-1">
              <img src="@/assets/media/image/icon/moneys.svg" />
              <h6 class="hotel_settings m-0">{{ $t("prices") }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row border-start border-end mb-2">
        <div class="col-lg-8 col-md-8">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <label class="typo__label custom_label">{{
                $t("choose visas")
              }}</label>
              <Multiselect
                v-model="visa_types_id"
                :options="visa_types"
                label="type_name"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
                @update:model-value="check_is_contract"
              ></Multiselect>
              <div v-for="error in v$.visa_types_id.$errors" :key="error.$uid">
                <div class="invalid-feedback d-block">
                  {{ error.$message }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6" v-if="is_contract == true">
              <label class="typo__label custom_label">{{
                $t("contracts")
              }}</label>
              <multiselect
                v-model="contract_id"
                :options="contracts"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
                @update:model-value="fetch_rate_codes"
              >
              </multiselect>
              <div v-for="error in v$.contract_id.$errors" :key="error.$uid">
                <div class="invalid-feedback d-block">
                  {{ error.$message }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6" v-if="is_contract == true">
              <label class="typo__label custom_label">{{
                $t("Rate Code")
              }}</label>
              <multiselect
                v-model="rate_code_id"
                :options="rate_codes"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
              ></multiselect>
              <div v-for="error in v$.rate_code_id.$errors" :key="error.$uid">
                <div class="invalid-feedback d-block">
                  {{ error.$message }}
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <label class="typo__label custom_label">{{
                $t("Supplier")
              }}</label>
              <Multiselect
                v-model="supplierObj"
                :options="providers"
                label="name"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
              ></Multiselect>
              <div v-for="error in v$.supplierObj.$errors" :key="error.$uid">
                <div class="invalid-feedback d-block">
                  {{ error.$message }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4">
          <div class="row" v-if="visa_types_id?.type_name">
            <div class="d-flex align-items-center justify-content-start">
              <span class="mt-4 mx-1 font-size-text">{{
                visa_types_id?.type_name
              }}</span>
              <div class="mx-2 w-50">
                <label class="typo__label custom_label m-0">{{
                  $t("cost")
                }}</label>
                <input
                  type="number"
                  class="multiselect__tags form-control text-end p-2 w-100"
                  placeholder="0"
                  v-model="visa_types_id.cost"
                />
              </div>
              <div class="mx-2 w-50">
                <label class="typo__label custom_label m-0">{{
                  $t("selling")
                }}</label>
                <input
                  type="number"
                  class="multiselect__tags form-control text-end p-2 w-100"
                  placeholder="0"
                  v-model="visa_types_id.selling"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Multiselect } from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "hotelSettings",
  props: ["dateStart", "dateEnd", "visaData", "providers"],
  emits: ["saveVisaData", "totalCost", "totalSelling"],
  data() {
    return {
      visa_types: [],
      visa_types_id: {},
      is_contract: false,
      contracts: [],
      rate_codes: [],
      contract_id: null,
      rate_code_id: null,
      supplierObj: {},
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      visa_types_id: {
        required,
      },
      // contract_id: {
      //   required,
      // },
      // rate_code_id: {
      //   required,
      // },
      supplierObj: {
        required,
      },
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    fetch_visa_types() {
      axios.post(`/fetch_visa_types`).then(({ data }) => {
        // console.log(data)
        this.visa_types = data.data.data.map((ele) => {
          return {
            ...ele,
            cost: null,
            selling: null,
          };
        });
      });
    },
    check_is_contract() {
      if (this.is_contract) {
        this.fetch_contracts();
      }
    },
    fetch_contracts() {
      axios
        .post("/fetch_visa_contracts_by_visa_type_id", {
          visa_type_id: this.visa_types_id.id,
          from_date: this.dateStart,
          to_date: this.dateEnd,
        })
        .then(({ data }) => {
          // console.log(data)
          this.contracts = data.data;
        })
        .catch((error) => {
          // console.log(error)
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    fetch_rate_codes() {
      axios
        .post(`/fetch_visa_rate_codes_by_contract_id`, {
          visa_contract_id: this.contract_id.id,
          from_date: this.dateStart,
          to_date: this.dateEnd,
        })
        .then(({ data }) => {
          this.rate_codes = data.data;
        })
        .catch((error) => {
          // console.log(error)
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    sumCostAndSelling(visa) {
      let totalCost = 0;
      let totalSelling = 0;

      visa.forEach((entry) => {
        totalCost += entry.cost == "" ? 0 : parseFloat(entry.cost);
        totalSelling += entry.selling == "" ? 0 : parseFloat(entry.selling);
      });
      return { totalCost, totalSelling };
    },
  },
  mounted() {
    this.fetch_visa_types();
  },
  watch: {
    visaData: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (this.$route.params.id) {
          newValue.forEach((ele) => {
            ele.price.forEach((element) => {
              console.log(element, "visa");
              this.visa_types_id = { ...element.visa_type }; // Create a new object
              this.visa_types_id.cost = element.cost;
              this.visa_types_id.selling = element.selling;
            });
            this.supplierObj = ele.supplier;
          });
          let { totalCost, totalSelling } = this.sumCostAndSelling(newValue);

          this.$emit("saveVisaData", newValue);
          this.$emit("totalCost", totalCost);
          this.$emit("totalSelling", totalSelling);
        }
      },
    },
  },

  updated() {
    let visa = {
      contract_id: this.is_contract == true ? this.contract_id.id : null,
      rate_code_id: this.is_contract == true ? this.rate_code_id.id : null,
      supplier_id: this.supplierObj?.id,
      types: [
        {
          cost: this.visa_types_id?.cost,
          selling: this.visa_types_id?.selling,
          visa_type_id: this.visa_types_id?.id,
        },
      ],
    };
    function sumCostAndSelling(visa) {
      let totalCost = 0;
      let totalSelling = 0;

      visa.forEach((entry) => {
        totalCost += entry.cost == "" ? 0 : parseFloat(entry.cost);
        totalSelling += entry.selling == "" ? 0 : parseFloat(entry.selling);
      });

      return { totalCost, totalSelling };
    }
    let { totalCost, totalSelling } = sumCostAndSelling(visa.types);
    this.$emit("saveVisaData", visa);
    this.$emit("totalCost", totalCost);
    this.$emit("totalSelling", totalSelling);
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 0;
}

.Transformation_seeting {
  background-color: #fff;

  .title {
    font-family: "bold";
    color: #0b1427;
    font-size: 1.25rem;
  }

  .flight_num {
    color: #656b78;
    font-family: "regular";
    font-size: 0.8rem;
  }

  .flight-details {
    color: #0b1427;
    font-size: 1.2rem;
    font-family: "regular";
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-check-input {
    margin: 0;
    float: none;
    border-color: #656b78;
    // width: min-content;
  }

  .form-check-input:checked {
    margin: 0;
    float: none;
    border-color: #0a909b;
    background-color: #0a909b;
  }

  .form-check-label {
    color: #656b78;
    font-family: "bold";
    font-size: 1rem;
  }

  .hotel_settings {
    font-family: "bold";
    color: #0b1427;
    font-size: 1rem;
  }

  .subtitle-hotel-settings {
    color: #0b1427;
    font-size: 1rem;
  }

  .custom_label {
    font-size: 0.6875rem;
    font-family: "bold";
    color: #0b1427;
    position: relative;
    z-index: 22;
    // bottom: 5.5rem; //4.2rem
    top: 0.675rem;
    background: white;
    border-radius: 50%;
    text-align: center;
    color: #656b78;
    margin: 0 0.5rem;
  }

  .first_diriction {
    font-size: 1rem;
    font-family: "bold";
    color: #0b1427;
  }

  .form-check-input {
    margin: 0;
    float: none;
    border-color: #656b78;
  }

  .form-check-input:checked {
    margin: 0;
    float: none;
    border-color: #0a909b;
    background-color: #0a909b;
  }

  .form-check-label {
    color: #656b78;
    font-family: "bold";
    font-size: 1rem;
  }
}

.bg-wheate {
  background-color: #fdfbf8;
}

.bg-light-blue {
  background-color: rgba($color: #1664b8, $alpha: 3%);
}

.bg-price,
.adult {
  background-color: rgba($color: #0a909b, $alpha: 10%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.gap-4 {
  gap: 4.5rem !important;
}

.time {
  color: #0b1427;
  font-family: "bold";
  font-size: 1rem;
}

.number-available {
  border: 1px solid gray;
  width: fit-content;
  padding: 0 0.8rem;
  border-radius: 20px;
}

.live {
  color: #0a909b;
  font-family: "bold";
  font-size: 0.875rem;
}

// input[type="number"]::-webkit-outer-spin-button,
// input[type="number"]::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

input[type="number"] {
  width: 50px;
}

span {
  font-size: 0.875rem;
}
.font-size-text {
  font-size: 0.7rem;
}
</style>
