<template>
  <Loading v-if="loading" />
  <section class="account_statement">
    <div class="container-fluid">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="d-flex align-items-center gap-3">
          <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />

          <h4 class="mb-0">{{ $t("trial-balance") }}</h4>
        </div>
        <div id="print-button" class="print_button mx-2">
          <button class="PrimaryButton btn" v-print="'#printStatments'">
            {{ $t("print") }}
          </button>
        </div>
      </div>
      <div class="filter">
        <div class="item">
          <div class="form-group position-relative">
            <label>{{ $t("accounts") }}</label>
            <Multiselect
              v-model="branch"
              track-by="id"
              :label="lang == 'ar' ? 'title_ar' : 'title_en'"
              :options="branches"
              :clear-on-select="true"
              :placeholder="$t('choose') + ' ' + $t('accounts')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span
              v-if="branch"
              @click="removeBranch"
              class="remove text-danger mx-1"
              ><i class="fa-regular fa-trash-can"></i
            ></span>
          </div>
        </div>
        <div class="item">
          <div class="form-group position-relative">
            <label>{{ $t("Cost_center") }}</label>
            <Multiselect
              v-model="costCenter"
              track-by="id"
              label="title"
              :options="costCenters"
              :clear-on-select="true"
              :placeholder="$t('choose') + ' ' + $t('Cost_center')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span
              v-if="costCenter"
              @click="removeCostCenter"
              class="remove text-danger mx-1"
              ><i class="fa-regular fa-trash-can"></i
            ></span>
          </div>
        </div>
        <div class="item">
          <div class="form-group">
            <label for="checkin" class="form-label">{{ $t("checkin") }}</label>
            <Datepicker
              v-model="from_date"
              class="w-100"
              lang="ar"
              :placeholder="$t('checkin')"
            />
          </div>
        </div>
        <div class="item">
          <div class="form-group">
            <label for="checkout" class="form-label">{{
              $t("checkout")
            }}</label>
            <Datepicker
              v-model="to_date"
              class="w-100"
              lang="ar"
              :disabled-dates="{ to: from_date }"
              :placeholder="$t('checkout')"
            />
          </div>
        </div>
        <div class="item">
          <div class="form-group position-relative">
            <label for="checkout" class="form-label">{{
              $t("mezan_type")
            }}</label>
            <Multiselect
              v-model="type"
              track-by="id"
              label="title"
              remove-selected
              :options="types"
              :clear-on-select="true"
              :placeholder="$t('mezan_type')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span
              v-if="type"
              @click="removeType"
              class="remove text-danger mx-1"
              ><i class="fa-regular fa-trash-can"></i
            ></span>
          </div>
        </div>
        <div class="item">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="accounts_zero"
              id="hideAccountsZero"
            />
            <label class="form-check-label" for="hideAccountsZero">
              {{ $t("hide_accounts_zero") }}
            </label>
          </div>
        </div>
        <div class="submitButtons">
          <button
            class="btn PrimaryButton"
            type="button"
            @click="fetchMoneyCostCenter"
          >
            {{ $t("submit") }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="table-responsive" id="printStatments">
          <HeaderPdf
            :title="$t('trial-balance')"
            :fromDate="from_date"
            :toDate="to_date"
          />
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{ $t("account") }}</th>
                <th colspan="2">{{ $t("prev") }}</th>
                <th colspan="2">{{ $t("period") }}</th>
                <th colspan="2">{{ $t("total_to_period") }}</th>
                <th></th>
              </tr>
              <tr>
                <th></th>
                <th>{{ $t("total_debtor") }}</th>
                <th>{{ $t("total_creditor") }}</th>
                <th>{{ $t("total_debtor") }}</th>
                <th>{{ $t("total_creditor") }}</th>
                <th>{{ $t("total_debtor") }}</th>
                <th>{{ $t("total_creditor") }}</th>
                <th>{{ $t("balance") }}</th>
              </tr>
            </thead>
            <tbody v-if="loading" class="text-center">
              <tr v-for="statment in 10" :key="statment">
                <td v-for="statment in 8" :key="statment">
                  <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
                </td>
              </tr>
            </tbody>

            <tbody v-else>
              <tr v-for="(cost, index) in costs" :key="index" :class="{ hidden: cost == null}">
                <td>
                  ({{ cost?.code }}) -
                  {{ lang == "ar" ? cost?.title_ar : cost?.title_en }}
                </td>
                <td>{{ findNearestSets(cost?.last?.debtor) }}</td>
                <td>{{ findNearestSets(cost?.last?.creditor) }}</td>
                <td>{{ findNearestSets(cost?.duration?.debtor) }}</td>
                <td>{{ findNearestSets(cost?.duration?.creditor) }}</td>
                <td>{{ findNearestSets(cost?.total?.debtor) }}</td>
                <td>{{ findNearestSets(cost?.total?.creditor) }}</td>
                <td>{{ findNearestSets(cost?.balance) }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>{{ $t("total") }}</td>
                <td>{{ findNearestSets(sumLastDebtor) }}</td>
                <td>{{ findNearestSets(sumLastCreditor) }}</td>
                <td>{{ findNearestSets(sumDurationDebtor) }}</td>
                <td>{{ findNearestSets(sumDurationCreditor) }}</td>
                <td>{{ findNearestSets(sumTotalDebtor) }}</td>
                <td>{{ findNearestSets(sumTotalCreditor) }}</td>
                <td>{{ findNearestSets(sumBalance) }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import VueDatepickerUi from "vuejs3-datepicker";
import Loading from "@/components/LottieFiles/loading.vue";
import Skeleton from "primevue/skeleton";
import moment from "moment";
import HeaderPdf from "@/components/pdf/headerPdf.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  data() {
    return {
      from_date: new Date(moment().startOf("year").format()),
      to_date: new Date(moment().startOf("year").add(1, "year").format()),
      loading: false,
      types: [
        {
          id: 1,
          title: this.$t("groups"),
        },
        {
          id: 2,
          title: this.$t("the_branches"),
        },
      ],
      type: {
        id: 1,
        title: this.$t("groups"),
      },
      costs: [],
      branch: "",
      item: {},
      branches: [],
      costCenters: [],
      costCenter: "",
      accounts_zero: false,
      lang:
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == undefined
          ? "ar"
          : "en",
      sumLastDebtor: 0,
      sumLastCreditor: 0,
      sumDurationDebtor: 0,
      sumDurationCreditor: 0,
      sumTotalDebtor: 0,
      sumTotalCreditor: 0,
      sumBalance: 0,
    };
  },
  components: {
    Multiselect,
    Loading,
    Datepicker: VueDatepickerUi,
    Skeleton,
    HeaderPdf,
    AddFavDailog,
  },
  methods: {
    findNearestSets(number) {
      if (isNaN(number)) {
        return number; // Return the original value if not a number
      }
      const rounded = Math.round(number * 10000) / 10000;
      return rounded;
    },
    removeType() {
      this.type = "";
    },
    removeBranch() {
      this.branch = "";
    },
    removeCostCenter() {
      this.costCenter = "";
    },
    fetchMoneyCostCenter() {
      const formData = new FormData();
      if (this.branch) {
        formData.append("branch_id", this.branch.id);
      }
      if (this.costCenter) {
        formData.append("cost_center_id", this.costCenter.id);
      }
      if (this.from_date) {
        formData.append("from", moment(this.from_date).format("YYYY-MM-DD"));
      }
      if (this.to_date) {
        formData.append("to", moment(this.to_date).format("YYYY-MM-DD"));
      }
      if (this.type) {
        formData.append("trial_type", this.type.id);
      }
      formData.append("account_zero", this.accounts_zero == true ? 0 : 1);

      axios.post("/trial_balance", formData).then(({ data }) => {
        this.costs = data.data;

        this.sumLastDebtor = 0;
        this.sumLastCreditor = 0;
        this.sumDurationDebtor = 0;
        this.sumDurationCreditor = 0;
        this.sumTotalDebtor = 0;
        this.sumTotalCreditor = 0;
        this.sumBalance = 0;

        // when open this page after choose branch
        if (!this.branch) {
          this.costs.forEach((item) => {
            if (item?.is_main == 1) {
              this.sumLastDebtor += item?.last?.debtor || 0;
              this.sumLastCreditor += item?.last?.creditor || 0;
              this.sumDurationDebtor += item?.duration?.debtor || 0;
              this.sumDurationCreditor += item?.duration?.creditor || 0;
              this.sumTotalDebtor += item?.total?.debtor || 0;
              this.sumTotalCreditor += item?.total?.creditor || 0;
              this.sumBalance += item?.balance || 0;
              // this.sumBalance += item?.balance_type === 1 ? item?.balance || 0 : -1 * item?.balance || 0;
            }
          });
        }

        // when choose branch
        else {
          const item = this.costs[0];
          // Sum last debtor
          this.sumLastDebtor = item?.last?.debtor || 0;

          // console.log(this.sumLastDebtor);

          // Sum last creditor
          this.sumLastCreditor = item?.last?.creditor || 0;

          // Sum duration debtor
          this.sumDurationDebtor = item?.duration?.debtor || 0;

          // Sum duration creditor
          this.sumDurationCreditor = item?.duration?.creditor || 0;

          // Sum total debtor
          this.sumTotalDebtor = item?.total?.debtor || 0;

          // Sum total creditor
          this.sumTotalCreditor = item?.total?.creditor || 0;

          // Sum total debtor
          this.sumBalance = item?.balance || 0;
        }
      });
    },
    fetchAllTreeBranches() {
      axios
        .post("fetch_all_tree_branches", { per_page: 200 })
        .then(({ data }) => {
          this.branches = data.data.data;
          // console.log(this.statments);
        });
    },
    fetch_cost_center() {
      axios.post("/fetch_cost_center").then(({ data }) => {
        this.costCenters = data.data.data;
      });
    },
  },
  mounted() {
    this.fetchMoneyCostCenter();
    this.fetchAllTreeBranches();
    this.fetch_cost_center();
  },
};
</script>

<style lang="scss" scoped>
.filter {
  display: flex;
  align-items: center;
  border: 1px solid #377373;
  border-radius: 10px;
  margin-bottom: 1rem;
  .item {
    width: 18%;
    padding: 0 1rem;
  }
  .submitButtons {
    width: 10%;
  }
}
.form-check {
  .form-check-input {
    float: right;
    margin-left: 0.5em;
  }
}
.table-responsive {
  .table {
    thead {
      tr {
        th {
          text-align: center !important;
        }
      }
    }
    tbody {
      tr {
        td {
          text-align: center !important;
        }
      }
    }
    tfoot {
      tr {
        td {
          padding: 0.5rem 0;
          text-align: center;
        }
      }
    }
  }
}
.remove {
  position: absolute;
  left: 0;
  top: 40px;
  color: red;
  cursor: pointer;
  &:hover {
    color: #377373;
  }
}
.hidden {
  display: none;
}
</style>
