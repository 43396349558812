<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <div class="d-flex algn-items-center">
        <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
        <h4 class="mb-0">
          {{ $t("suplierType") }}
        </h4>
      </div>

      <div>
        <router-link to="/suplierTypeAdd" class="PrimaryButton btn w-100"
          ><i class="fa-solid fa-plus"></i> {{$t("add_supplier_type")}}
        </router-link>
      </div>
    </div>

    <!-- <div class="search-visa my-3">
          <form>
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('search...')"
            />
          </form>
        </div> -->

    <div class="row">
      <table class="table" id="VisaTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="suplierType in 10" :key="suplierType">
            <td v-for="suplierType in 3" :key="suplierType">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(insurance, index) in suplierType.data" :key="index">
            <td>{{ insurance.id }}</td>
            <td>{{ insurance.title }}</td>
            <td>
              <button
                @click="this.$router.push(`/suplierTypeEdit/${insurance.id}`)"
                class="btn btn-success mx-1 w-auto"
              >
                <i class="fa-regular fa-edit"></i>
              </button>
              <button
                @click="deletesuplierType(insurance.id, index)"
                class="btn btn-danger mx-1 w-auto"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="suplierType"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="suplierType_categories"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
// import $ from "jquery";
export default {
  name: "allVisa",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      suplierType: [],
      loading: true,
    };
  },
  methods: {
    suplierType_categories(page = 1) {
      axios.get(`/supplier_types?page=${page}`).then(({ data }) => {
        this.suplierType = data.data;
        //  console.log(this.suplierType);
        this.loading = false;
      });
    },
    //delete visa
    deletesuplierType(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.affiliates.id;
          const fromClick = true;
          axios.delete(`/supplier_types/${id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.suplierType.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  created() {
    this.suplierType_categories();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
.text-center {
  text-align: center !important;
}
</style>
