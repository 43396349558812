<template>
  <section class="card_style tree">
    <div class="main_header">
      <div class="w-100 d-flex justify-content-between">
        <div class="d-flex align-items-center gap-3">
          <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
          <h4 class="mb-0">
            {{ $t("accounts' tree") }}
          </h4>
        </div>
        <div class="d-flex">
          <button
            class="SecondButton btn action d-flex align-items-center mx-2"
            @click="exportToExcel"
          >
            <i class="fa-solid fa-file-excel"></i>
            <div
              class="img-border d-flex align-items-center justify-content-center"
            >
              <img src="@/assets/media/image/fi_7268609.svg" alt="" />
            </div>
          </button>
          <router-link
            to="/opening-balance"
            class="SecondButton btn action d-flex align-items-center mx-2"
          >
            <i class="fa-solid fa-plus"></i>
            {{ $t("Add opening balance") }}
          </router-link>
        </div>
      </div>
    </div>

    <getBranches :treeBranches="allTreeBranches" @getParentId="get_Parent_Id" />

    <treeOffCanvas :branch_id="branch_id" />
  </section>
</template>

<script>
import axios from "axios";
import treeOffCanvas from "../../../components/tree/treeOffCanvas.vue";
import getBranches from "../../../components/tree/getBranches.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import * as XLSX from "xlsx/xlsx.mjs";

export default {
  name: "treeBranches",
  data() {
    return {
      allTreeBranches: [],
      branch_id: null,
      myBranch: {},
      tree_table: [],
    };
  },
  components: {
    treeOffCanvas,
    getBranches,
    AddFavDailog,
  },
  methods: {
    fetchTreeBranches() {
      axios.post("/fetch_tree_branches").then(({ data }) => {
        this.allTreeBranches = data.data.map((branch) => {
          return {
            ...branch,
            children: [],
            showChild: false,
            deleted: false,
            loading: false,
          };
        });
      });
    },
    get_Parent_Id(branchId) {
      this.branch_id = branchId;
    },
    async exportToExcel() {
      await axios
        .post(`/fetch_all_tree_branches`, { per_page: 1000 })
        .then(({ data }) => {
          this.tree_table = data.data.data;
        });

      const tableData = [
        // Header row
        [
          this.$t("code"),
          this.$t("title_en"),
          this.$t("title_ar"),
          this.$t("text"),
          this.$t("code"),
          this.$t("opening_balance"),
          this.$t("balance"),
          this.$t("created_at"),
        ],
        // Data rows
        ...this.tree_table.map((item) => [
          item.code,
          item.title_en,
          item.title_ar,
          item.text,
          item.code,
          item.opening_balance,
          item.balance,
          item.created_at,
        ]),
      ];

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(tableData);

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      XLSX.writeFile(wb, "accounts_tree.xlsx");
    },
  },
  created() {
    this.fetchTreeBranches();
  },
};
</script>

<style lang="scss" scoped>
@import "./_tree";
</style>
