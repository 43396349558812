<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("money_center_list") }}</h4>
      <div class="btn_group">
        <button class="btn PrimaryButton">
          <i class="fa-solid fa-print"></i>
          {{ $t("print") }}
        </button>
        <!-- <router-link :to="{ name: 'flightBooking' }" class="PrimaryButton btn"
          ><i class="fa-solid fa-plus"></i>
          {{ $t("new_reservation") }}</router-link> -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="header">
          <li>
            <h4>{{ $t("type") }}</h4>
            <p>{{ $t("the amount") }}</p>
          </li>
          <li v-for="(child, index) in newChild" :key="index">
            <router-link
              :to="`/trial-balance/${child.id}`"
              v-if="child.has_children == true"
            >
              <h4>{{ child.title_ar }}</h4>
              <p>
                {{ child.balance }}
              </p>
            </router-link>
            <div class="lis" v-else>
              <h4>{{ child.title_ar }}</h4>
              <p>
                {{ child.balance }}
              </p>
            </div>
          </li>

          <li class="sub">
            <h4>{{ $t("total") }}</h4>
            <p>{{ totalAssets }}</p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "money-list",
  components: {
    // Multiselect,
  },
  data() {
    return {
      newChild: [],
    };
  },
  computed: {
    totalAssets() {
      return this.newChild.reduce(
        (total, child) => total + parseFloat(child.balance),
        0
      );
    },
  },

  methods: {
    FetchHasChild() {
      let id = this.$route.params.id;
      const formData = new FormData();
      formData.append("parent_id", id);
      axios.post("/fetch_tree_branches", formData).then(({ data }) => {
        this.newChild = data.data;
        // Trigger the updateSelectedBranch method with the new selectedBranchId
        // this.updateSelectedBranch(value);
        // console.log(this.hasChild);
      });

      // let hasChild = { value };
    },
  },
  mounted() {
    this.FetchHasChild();
  },
  watch: {
    $route: {
      handler: "FetchHasChild",
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_accounts";

ul.header {
  padding: 2%;
  border-radius: 9px;
  border: 1px solid #e8e8e8;
  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0%;
    padding: 2%;
    a , .lis {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    h4 {
      font-size: 1rem;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
  }
}
ul.content {
  padding: 2%;
  border-radius: 9px;
}
.sub {
  background-color: rgba($color: #0a909b, $alpha: 0.1);
  padding: 2%;
  border-radius: 10px;
}
.total {
  background-color: rgba($color: #0000, $alpha: 0.1);
  padding: 2%;
  border-radius: 10px;
}
</style>
