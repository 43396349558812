<template>
  <p class="my-4">{{ description[0]?.description }}</p>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "descriptionHotel",
  computed: {
    ...mapState(["description"]),
  },
};
</script>

<style></style>
