<template>
  <section class="all_emp card_style">
    <h4>{{ $t("categotey-blogs") }}</h4>
    <div class="main_header_style">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <input
          type="text"
          v-model="categorieText"
          @keyup="fetch_categories((page = 1))"
          class="form-control w-50"
          :placeholder="$t('search...')"
        />

        <router-link to="/add/categorey-blogs" class="PrimaryButton btn"
          ><i class="fa-solid fa-plus"></i> Add New
        </router-link>
      </div>
    </div>

    <!-- <div class="search-visa my-3">
          <form>
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('search...')"
            />
          </form>
        </div> -->

    <div class="row">
      <table class="table" id="VisaTable">
        <thead>
          <tr>
            <th>ID</th>
            <th class="text-center">{{ $t("name") }}</th>
            <th class="text-center">{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(categorie, index) in categories.data" :key="index">
            <td>{{ categorie.id }}</td>
            <td class="text-center">{{ categorie.title }}</td>
            <td
              class="text-center d-flex align-items-center justify-content-center"
            >
              <button
                @click="
                  this.$router.push(`/edit/categorey-blogs/${categorie.id}`)
                "
                class="btn btn-success mx-1 w-auto"
              >
                <i class="fa-regular fa-edit"></i>
              </button>
              <button
                @click="deletecategorie(categorie.id, index)"
                class="btn btn-danger mx-1 w-auto"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="categories"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_categories"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
// import $ from "jquery";
export default {
  name: "allVisa",
  components: {
    pagination,
  },
  data() {
    return {
      categories: [],
      categorieText: "",
    };
  },
  methods: {
    fetch_categories(page = 1) {
      axios
        .post(`/index_blog_categories?page=${page}`, {
          word: this.categorieText,
        })
        .then(({ data }) => {
          this.categories = data.data;
          // console.log(this.categories);
        });
    },
    //delete visa
    deletecategorie(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.categories.id;
          const fromClick = true;
          axios.delete(`/blog_categories/${id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.categories.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  created() {
    this.fetch_categories();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
.text-center {
  text-align: center !important;
}
</style>
