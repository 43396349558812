<template>
  <div
      class="offcanvas offcanvas-bottom"
      tabindex="-1"
      id="offcanvasBottom"
      aria-labelledby="offcanvasBottomLabel"
    >
      <div class="offcanvas-header">
        <h4 class="offcanvas-title" id="offcanvasBottomLabel">
          {{ $t("The Transfers") }}
        </h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body small">
        <form class="charge_wallet_request" @submit.prevent="save()">

            <div class="row">
                <div class="col-lg-6">
                    <div class="transfer-data">

                        <div class="row">
                            <h5> {{$t("From")}} </h5>
                            <div class="col-lg-6">
                                <div class="bank service_type">
                                    <input
                                    v-model="transferTypeFrom"
                                    value="bank"
                                    type="radio"
                                    name="transfer_from"
                                    id="bank" 
                                    @change="getTransferType()" />
                                    <label for="bank"><i class="fa-solid fa-building-columns"></i> {{$t("bank account")}}</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="bank service_type">
                                    <input
                                    v-model="transferTypeFrom"
                                    value="safe"
                                    type="radio"
                                    name="transfer_from"
                                    id="safe"
                                    @change="getTransferType()" />
                                    <label for="safe"><i class="fa-solid fa-box-archive"></i> {{$t("safe")}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">

                            <div v-if="transferTypeFrom == 'bank'">
                                <label class="d-block mb-2">{{$t("bank account")}}</label>
                                <select class="col-12" v-model="from_account">
                                    <option v-for="(account) in accounts" :key="account.id" :value="account">{{account.title}}</option>
                                </select>
                            </div>
                            <div v-if="transferTypeFrom == 'safe'">
                                <label class="d-block mb-2">{{$t("The Safe")}}</label>
                                <select class="col-12" v-model="from_account">
                                    <option v-for="(safe) in safes" :key="safe.id" :value="safe">{{safe.title}}</option>
                                </select>
                                <!-- <Multiselect
                                    :clear-on-select="true"
                                    :placeholder="$t('the account')"
                                    select-label=""
                                    :hide-selected="true"
                                >
                                </Multiselect> -->
                            </div>
                            
                        </div>

                        <div class="row mt-4">
                            <div class="col-4">
                                <label for="amount" class="d-block mb-2">{{$t("the amount")}}</label>
                                <input type="text" id="amount" placeholder="0.00" v-model="amount" @keyup="calcTransferFromMoney()"/>
                            </div>
                            <div class="col-4">
                                <label for="before" class="d-block mb-2">{{$t("avalible_before")}}</label>
                                <input type="text" id="before" disabled :value="from_account.money"/>
                            </div>
                            <div class="col-4">
                                <label for="after" class="d-block mb-2">{{$t("avalible_after")}}</label>
                                <input type="text" id="after" :value="FromMoneyAfter" disabled />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="transfer-data">

                        <div class="row">
                            <h5> {{$t("To")}} </h5>
                            <div class="col-lg-6">
                                <div class="bank service_type">
                                    <input
                                    v-model="transferTypeTo"
                                    value="bank"
                                    type="radio"
                                    name="transfer_to"
                                    id="bank2"
                                    />
                                    <label for="bank2"><i class="fa-solid fa-building-columns"></i> {{$t("bank account")}}</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="bank service_type">
                                    <input
                                    v-model="transferTypeTo"
                                    value="safe"
                                    type="radio"
                                    name="transfer_to"
                                    id="safe2"  />
                                    <label for="safe2"><i class="fa-solid fa-box-archive"></i> {{$t("safe")}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div v-if="transferTypeTo == 'bank'">
                                <label class="d-block mb-2">{{$t("bank account")}}</label>
                                <select class="col-12" v-model="to_account">
                                    <option v-for="(account) in accounts" :key="account.id" :value="account">{{account.title}}</option>
                                </select>

                                
                            </div>
                            <div v-if="transferTypeTo == 'safe'">
                                <label class="d-block mb-2">{{$t("The Safe")}}</label>
                                <select class="col-12" v-model="to_account">
                                    <option v-for="(safe) in safes" :key="safe.id" :value="safe">{{safe.title}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-6">
                                <label for="before" class="d-block mb-2">{{$t("avalible_before")}}</label>
                                <input type="text" id="before" disabled :value="to_account.money"/>
                            </div>
                            <div class="col-6">
                                <label for="after" class="d-block mb-2">{{$t("avalible_after")}}</label>
                                <input type="text" id="after" :value="toMoneyAfter" disabled />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row my-4">
                <div class="col-6">
                    <label for="date" class="d-block mb-3">{{$t("transfer's date and time")}}</label>
                    <input type="date" id="date" v-model="date"/>
                </div>
                <div class="col-6">
                    <label for="notes" class="d-block mb-3">{{$t("notes")}}</label>
                    <input type="text" id="notes" placeholder="..." v-model="notes">
                </div>
            </div>

            <button type="submit" class="btn save">{{$t("save")}}</button>
        </form>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
// import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";

export default {
    components: {
        // Multiselect,
    },
    data(){
        return{
            transferTypeFrom:'bank',
            transferTypeTo: 'bank',
            type: 1,
            accounts:[],
            from_account:'',
            to_account:'',
            amount:"",
            FromMoneyAfter:"",
            toMoneyAfter:"",
            safes:[],
            date:"",
            notes:"",
            toAccounts:[
                {
                    id:1,
                    transferTypeTo: "bank",
                    account_id: "",
                    amount: "",
                    available_before: "",
                    available_after: "",
                }
            ],
        }
    },
    methods:{
        getTransferType(){
            this.type = this.transferTypeFrom == "bank" ? 1 : 2
        },
        fetchAccountData(){
            axios.post("/fetch_my_organization_accounts" , {type: this.type}).then(({data}) => {
                this.accounts = data.data.data
            })
        },
        fetchSafeData(){
            axios.post("/fetch_my_organization_accounts" , {type: 2}).then(({data})=>{
                this.safes = data.data.data
            })
        },
        calcTransferFromMoney(){
            this.FromMoneyAfter = parseFloat(this.from_account.money) - parseFloat(this.amount)
            this.calcTransferToMoney()
        },
        calcTransferToMoney() {
            this.toMoneyAfter = parseFloat(this.to_account.money) + parseFloat(this.amount)
            // console.log(this.to_account)
            // console.log(this.from_account)
        },
        addToAccount(){
            this.toAccounts.push({
                id:1,
                transferTypeTo: "bank",
                account_id: "",
                amount: "",
                available_before: "",
                available_after: "",
            })
        },
        save(){
            let transferData = {
                text: this.notes,
                date: moment(this.date).format("YYYY-MM-DD"),
                from: [
                    {
                        account_id: this.from_account.id,
                        amount: this.amount,
                        // report: 
                    }
                ]
            }

            axios.post(`/store_transfer_accounts` , transferData).then(({data})=>{
                console.log(data)
            })

            // Swal.fire({
            //     title: this.$t("check_transfer"),
            //     text: this.$t("are_you_sure_to_transfer"),
            //     icon: "warning",
            //     showCancelButton: true,
            //     confirmButtonColor: "#3085d6",
            //     cancelButtonColor: "#d33",
            //     cancelButtonText: this.$t("back"),
            //     confirmButtonText:  this.$t("confirm_transfer") ,
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //     const newLocal = this.$i18n.locale;
            //     axios.defaults.headers.common["Accept-Language"] = newLocal;
            //     axios.post("/transfer_account" , {
            //         amount: this.amount ,
            //         date: this.date ,
            //         notes: this.notes ,
            //         from_account_id: this.from_account.id ,
            //         to_account_id: this.to_account.id ,
            //     })
            //     .then(({ data }) => {
            //         // console.log(data)
            //     if (data.status == true) {
            //         Swal.fire("", data.message, "success");
            //         // this.$emit('custom-event');
            //     }  else {
            //         Swal.fire("", data.message, "error");
            //     }
            //     return data;
            //     })
            //     .catch((error) => {
            //         Swal.fire({
            //             title: "",
            //             text: error.response.data.message,
            //             icon: "error",
            //         });
            //     });
            //     }
            // });
        }
    },
    created() {
        this.fetchAccountData();
        this.fetchSafeData();
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "../../view/dashboard/wallet/_wallet.scss";
</style>


