<template>
  
  <button class="btn btn-sm btn-primary" @click="visible = true" v-tooltip.top="$t('show_details_payment_book')"><i class="fa-solid fa-eye"></i></button>

<Dialog v-model:visible="visible" maximizable modal :dismissableMask="true" :header="$t('details_payment_book')" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <ol class="px-0 mx-0">
        <li v-for="(invoice, index) in invoices" :key="index">
            {{ $t("done_pay_amount") }} {{ invoice.amount }} {{ invoice.type == 'OfflineBookingRoom' ? $t("on") : $t("on_service") }} {{ invoice.title }} <span v-if="invoice.type == 'OfflineBookingRoom'">{{ $t("to_hotel") }} {{ invoice.hotel_name }}</span>
        </li>
    </ol>
</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

export default {
    props: {
        invoices: Array
    },
    data() {
        return {
            visible: false
        }
    },
    components: {
        Dialog
    }
}
</script>

<style>

</style>