<template>
  <section class="card_style">
    <div class="main_header_style">
      <h5 class="mb-4">{{ $t("Edit View") }}</h5>
      <!-- <p>{{$t("enter this data to continue")}}</p> -->
    </div>

    <div class="content">
      <form @submit.prevent="editView()">
        <div class="row">
          <div class="col-lg-9 my-3">
            <label>{{ $t("The Country") }}</label>
            <Multiselect
              v-model="countryValue"
              track-by="id"
              label="title"
              :options="countries"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
              @update:model-value="FetchCityFromCountry"
            >
            </Multiselect>
          </div>
          <div class="col-lg-9 my-3">
            <label>{{ $t("The City") }}</label>
            <Multiselect
              v-model="cityValues"
              :options="cities"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('enter_city')"
              select-label=""
              :multiple="true"
              :hide-selected="true"
            >
            </Multiselect>
          </div>
          <!-- view ar -->
        </div>
        <div class="row align-items-end my-3">
          <div class="col-lg-5">
            <div class="w-100">
              <label>{{ $t("view name") }} ar</label>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('enter view name')"
                v-model="title_ar"
              />
            </div>
          </div>
          <!-- view en -->
          <div class="col-lg-5">
            <div class="w-100">
              <label>{{ $t("view name") }} en</label>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('enter view name')"
                v-model="title_en"
              />
            </div>
          </div>
        </div>
        <button type="submit" class="add save btn mt-5">
          {{ $t("edit") }}
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      viewId: this.$route.params.id,
      countries: [],
      cities: [],
      countryValue: "",
      cityValues: [],
      title_ar: "",
      title_en: "",
      country_id: "",
    };
  },
  methods: {
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    FetchCityFromCountry(value) {
      this.cityValues = [];
      this.cities = [];
      let basic_country_ids = {
        country_id: [value.id],
      };
      axios
        .post("/fetch_cities_by_country_id", basic_country_ids)
        .then(({ data }) => {
          this.cities = data.data;
        });
    },
    editView() {
      let viewId = this.$route.params.id;
      axios
        .put(`/view_types/${viewId}`, {
          title_ar: this.title_ar,
          title_en: this.title_en,
          city_ids: this.cityValues.map((city) => {
            return city.id;
          }),
          country_id: this.countryValue.id,
        })
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/view");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    fetchViewData() {
      axios.get(`/view_types/${this.viewId}`).then(({ data }) => {
        // console.log(data.data.country)
        // console.log(data.data.cities)

        this.countryValue = data.data.country;
        this.cityValues = data.data.cities;
        // console.log(this.cityValues)
        this.title_ar = data.data.title_ar;
        this.title_en = data.data.title_en;
      });
    },
  },
  created() {
    this.fetch_country();
    this.fetchViewData();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "../_crudStyle.scss";
</style>
