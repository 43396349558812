<template>
    <div class="rate">
        <i class="fa-solid fa-star active" v-for="(star, index) in stars" :key="index"></i>
        <i class="fa-regular fa-star" v-for="(star, index) in 5-stars" :key="index"></i>
    </div>
</template>

<script>
export default {
    name: "star-rate",
    props: ["stars"]
}
</script>

<style>

</style>