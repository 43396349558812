<template>
  <div class="invoice">
    <div class="row">
      <div class="col-8">
        <div class="main-text">
          <div class="text-logo">
            <h4>{{ pills.book_number }}</h4>
            <h4>Voucher</h4>
          </div>
          <div class="next-text">
            <h4>Payment Voucher</h4>
            <h5>
              Booking Status: <span>{{ book_status_name }}</span>
            </h5>
            <h6 v-if="pills.created_at_date">
              Created date:
              {{ pills.created_at_date }} -
              {{ pills.created_at_time }}
            </h6>
          </div>
        </div>
        <!-- <div class="under-text">
          <div class="head">
            <h4>Dear Sir : {{ pills.client_name }}</h4>
            <h5>
              Greeting From <span>{{ pills.organization?.name }}</span>
            </h5>
            <div class="pragra">
              <p>
                First of All, We would like to take this opportunity to welcome you
                at
                <strong> {{ pills.organization?.name }} </strong> and tourism We are
                pleased to confirm the following reservation on a
                <span>{{ book_status_name }}.</span>
              </p>
            </div>
          </div>
        </div> -->
      </div>
      <div class="col-4">
        <div class="logo">
          <img :src="pills.organization?.default_image" />
        </div>
      </div>
    </div>

    <div class="table_account" v-if="pills?.account">
      <div class="list_account">
        <div class="account_data">
          <p class="title_account">Client</p>
          <p class="text_account">: {{ pills.client_name }}</p>
        </div>
        <div class="account_data">
          <p class="title_account">Guest Name</p>
          <p class="text_account">: {{ pills.guest_name }}</p>
        </div>
        <div class="account_data">
          <p class="title_account">Nationality</p>
          <p class="text_account">: {{ pills.nationality_name }}</p>
        </div>
      </div>
      <div class="list_account">
        <div class="account_data">
          <p class="title_account">Check In</p>
          <p class="text_account">
            : {{ pills.check_in_date }},
            {{ updateHijriDate(new Date(pills.check_in_date)) }}
          </p>
        </div>
        <div class="account_data">
          <p class="title_account">Check Out</p>
          <p class="text_account">
            : {{ pills.check_out_date }},
            {{ updateHijriDate(new Date(pills.check_out_date)) }}
          </p>
        </div>
      </div>
      <div class="list_account">
        <div
          class="account_data"
          v-for="(hotel, index) in pills.hotels"
          :key="index"
        >
          <p class="title_account">Hotel Name {{ index + 1 }}</p>
          <p class="text_account hotel_name">: {{ hotel.hotel_name }}</p>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 10%" class="frist">ROOM TYPE</th>
            <th style="width: 10%" scope="col">MEAL</th>
            <th style="width: 10%" scope="col">CHECK IN</th>
            <th style="width: 10%" scope="col">CHECK OUT</th>
            <th style="width: 10%" scope="col">Nights</th>
            <th style="width: 10%" scope="col">GUESTS PER ROOM</th>
            <th style="width: 10%" scope="col">CONF NO</th>
            <!-- <th style="width: 10%" scope="col">RATE</th>
              <th style="width: 10%" class="last">TOTAL</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(room, index) in pills.rooms" :key="index">
            <td class="frist">
              <p class="mb-0 fs-8">{{ room.hotel_name }}</p>
              <p class="mb-0 fs-8">
                {{ room.room_number }} X {{ room.title_hotel_room_view }}
              </p>
            </td>
            <td>{{ room.meal_type?.title }}</td>
            <td>
              <p class="mb-0">
                {{ room.check_in_date }}
              </p>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_in_date))
              }}</span>
            </td>
            <td>
              <P class="mb-0">
                {{ room.check_out_date }}
              </P>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_out_date))
              }}</span>
            </td>
            <td>{{ room.number_of_nights }}</td>
            <td>{{ room.guest_number }}</td>
            <td>{{ room.hotel_reference }}</td>
            <!-- <td>260</td>
              <td class="last">{{ pills.total_selling_price }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>

    <div class="end-page">
      <div class="hed terms" v-if="pills?.Conditions">
        <h4>Terms & Conditions</h4>
        <p class="text" v-html="pills?.Conditions?.description"></p>
      </div>
      <div class="hed terms" v-if="pills?.book_notes">
        <h4>Notes</h4>
        <p class="text" v-html="pills?.book_notes"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pill-hotel-voucher",
  props: {
    pills: Object,
    terms: String,
    total_selling_price_with_out_tax: String,
    selling_tax: String,
    total_selling_price: String,
    book_status_name: String,
  },
};
</script>

<style lang="scss" scoped>
@import "./_pill.scss";
</style>
