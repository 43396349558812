<template>
  <section class="offline_details">
    <div class="container">
      <router-link class="back" to="/book_table"
        ><i class="fa-solid fa-arrow-right"></i>
        {{ $t("back_reservation") }}</router-link
      >
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="title">
          تفاصيل الحجز لفندق آمون ( 4 بالغين , 5 ليالي , 3 غرف )
        </h4>
        <span class="status">حجز معدل</span>
      </div>
      <div class="d-flex align-items-center gap-3">
        <div class="date">
          <p class="title">{{ $t("reservation_date") }} :</p>
          <p class="text">{{ book.created_at }}</p>
        </div>
        <div class="date">
          <p class="title">{{ $t("reservation_number") }} :</p>
          <p class="text">{{ book.booking_number }}</p>
        </div>
      </div>
      <div class="row">
        <h6 class="title">{{ $t("details_booking") }}</h6>
        <div class="col-lg-8 col-md-12 col-12">
          <div class="hotel_details">
            <div class="d-flex align-items-center gap-3">
              <img src="../../../assets/media/image/login.png" alt="hotel" />
              <div class="details">
                <h6 class="title">{{ hotel.title }}</h6>
                <p class="address">
                  <i class="fa-solid fa-map-pin"></i> {{ hotel.address }}
                </p>
                <Star :stars="4" />
              </div>
            </div>
            <div class="data">
              <p class="title">
                <i class="fa-solid fa-calendar-days"></i> {{ book.check_in }}
                <i class="fa-solid fa-arrow-left"></i> {{ book.check_out }}
              </p>
              <p class="title">
                <i class="fa-solid fa-bed"></i> {{ rooms.length }}
                {{ $t("rooms") }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="hotel_details">
            <h6 class="title">{{ $t("reservation_data") }}</h6>
            <ul>
              <li>
                <p class="title">{{ $t("reservation_number") }} :</p>
                <p class="text">{{ book.booking_number }}</p>
              </li>
              <li>
                <p class="title">{{ $t("booking_by") }} :</p>
                <p class="text">{{ booking_by.name }}</p>
              </li>
              <li>
                <p class="title">{{ $t("client_name") }} :</p>
                <p class="text">{{ book.guest_name }}</p>
              </li>
              <li>
                <p class="title">{{ $t("reservation_date") }} :</p>
                <p class="text">{{ book.created_at }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="rooms">
    <div class="container">
      <div class="row">
        <table class="table" id="customizationHotelTable">
          <thead>
            <tr>
              <th>{{ $t("room_type") }}</th>
              <th>{{ $t("view_type") }}</th>
              <th>{{ $t("Selling_price") }}</th>
              <th>{{ $t("price") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(room, index) in rooms" :key="index">
              <td>{{ room.title }}</td>
              <td>{{ room.view_type_title }}</td>
              <td>{{ room.selling_price }}</td>
              <td>{{ room.cost }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>

  <section class="guests">
    <div class="container">
      <div class="row">
        <table class="table" id="customizationHotelTable">
          <thead>
            <tr>
              <th>{{ $t("guest_name") }}</th>
              <th>{{ $t("nationality") }}</th>
              <th>{{ $t("ID Number") }}</th>
              <th>{{ $t("phone") }}</th>
              <th>{{ $t("email") }}</th>
              <th>{{ $t("Image") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(guest, index) in guests" :key="index">
              <td>{{ guest.name }}</td>
              <td>{{ guest.nationality_title }}</td>
              <td>{{ guest.passport_number }}</td>
              <td>{{ guest.phone }}</td>
              <td>{{ guest.email }}</td>
              <td><img class="guest_image" v-for="(image, index) in images" :key="index" :src="image" alt=""></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>

  <section class="payment">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-12">
                <h4 class="title">{{$t("details_payment_client")}}</h4>
                <ul>
                    <li>
                        <p class="title">{{$t("total_cost")}} : </p>
                        <p class="text">{{price_model.total_cost}}</p>
                    </li>
                    <li>
                        <p class="title">{{$t("total_cost_with_tax")}} : </p>
                        <p class="text">{{price_model.total_cost_with_tax}}</p>
                    </li>
                    <li>
                        <p class="title">{{$t("cost_tax")}} : </p>
                        <p class="text">{{price_model.cost_tax}}</p>
                    </li>
                    <li>
                        <p class="title">{{$t("total_selling_price")}} : </p>
                        <p class="text">{{price_model.total_selling_price}}</p>
                    </li>
                    <li>
                        <p class="title">{{$t("total_selling_price_with_tax")}} : </p>
                        <p class="text">{{price_model.total_selling_price_with_tax}}</p>
                    </li>
                    <li>
                        <p class="title">{{$t("selling_price_tax")}} : </p>
                        <p class="text">{{price_model.selling_price_tax}}</p>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 col-12"></div>
        </div>
    </div>
  </section>
</template>

<script>
import Star from "@/components/rate/star.vue";
import axios from "axios";

export default {
  name: "offline-details",
  data() {
    return {
      book: {},
      hotel: {},
      guests: [],
      price_model: {},
      booking_by: {},
      rooms: [],
    };
  },
  components: {
    Star,
  },
  methods: {
    fetchDetailsBook() {
      let booking_id = { booking_id: this.$route.params };
      axios.post(`fetch_booking_offline_details`, booking_id).then(({ data }) => {
        this.book = data.data;
        this.booking_by = data.data.booking_by;
        this.hotel = data.data.hotels;
        this.guests = data.data.guests;
        this.price_model = data.data.price_model;
        this.rooms = data.data.rooms;
        // console.log(this.book);
      });
    },
  },
  created() {
    this.fetchDetailsBook();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "./_offline.scss";
</style>
