<template>
  <div class="permission">
    <h2>{{ $t("Permission Denied") }}</h2>
    <img src="@/assets/media/image/403.png" alt="Permission" />
  </div>
</template>

<script>
export default {
  name: "Permission-Denied",
};
</script>

<style lang="scss" scoped>
.permission {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
h2 {
  text-align: center;
}
img {
  display: block;
  width: 35%;
  margin: 0 auto;
}
</style>
