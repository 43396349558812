<template>
  <table class="table" id="userTable">
    <thead>
      <tr>
        <th>{{ $t("ID") }}</th>
        <th>{{ $t("book_type") }}</th>
        <th>{{ $t("Serial Number") }}</th>
        <th>{{ $t("confimration_number") }}</th>
        <th>{{ $t("date") }}</th>
        <th>{{ $t("whoseller") }}</th>
        <th>{{ $t("client_name") }}</th>
        <th>{{ $t("hotel_code") }}</th>
        <th>{{ $t("currency") }}</th>
        <th>{{ $t("checkin") }}</th>
        <th>{{ $t("checkout") }}</th>
        <th class="night">{{ $t("number_of_nights") }}</th>
        <th>{{ $t("Hotel Name") }}</th>
        <th>{{ $t("title_room") }}</th>
        <th class="room">{{ $t("number_of_rooms") }}</th>
        <th>{{ $t("meals") }}</th>
        <th>{{ $t("price") }}</th>
        <th>{{ $t("coupon") }}</th>
        <th>{{ $t("status") }}</th>
        <th>{{ $t("status_payment") }}</th>
      </tr>
    </thead>

    <tbody v-if="loading" class="text-center">
      <tr v-for="item in 10" :key="item">
        <td v-for="item in 20" :key="item">
          <Skeleton width="90%" height="1.5rem" class="mx-2"></Skeleton>
        </td>
      </tr>
    </tbody>

    <tbody v-else>
      <tr v-for="(item, index) in books" :key="index">
        <td :data-label="$t('ID')">{{ item.book.id }}</td>
        <td :data-label="$t('book_type')">
          {{ $t(item.paymentable_type.split("\\").pop()) }}
        </td>
        <td style="width: 100px">
          {{ item.book.booking_number }}
        </td>
        <td style="width: 150px">{{ item.book.confirmation_number }}</td>
        <td style="width: 140px">{{ item.book.created_date }}</td>
        <td style="width: 140px">X-coders</td>
        <td style="width: 140px">{{ item.book.client_name }}</td>
        <td style="width: 140px">{{ item.book.hotel_phone }}</td>
        <td style="width: 140px">{{ item.book.client_currency_code }}</td>
        <td style="width: 140px">{{ item.book.check_in }}</td>
        <td style="width: 140px">{{ item.book.check_out }}</td>
        <td class="night" style="width: 30px">
          {{ item.book.numberOfNights }}
        </td>
        <td style="width: 250px">{{ item.book.hotel_name }}</td>
        <td style="width: 250px">
          <span
            v-for="(room, index) in item.book.Rooms"
            :key="index"
            class="px-3"
            >{{ room.title }}</span
          >
        </td>
        <td class="room" style="width: 30px">
          {{ item.book.Rooms.length }}
        </td>
        <td style="width: 150px">
          <span
            v-for="(meal, id) in item.book.Rooms"
            :key="id"
            class="meal_type"
          >
            <span
              >{{ meal.description }} <span class="dash"> - </span>
            </span></span
          >
        </td>
        <td>
          {{ item.book.client_currency_code }}
          {{
            item.book.booking_type == "online"
              ? (
                  item.book.total_selling_price + item.book.selling_price_tax
                ).toFixed(2)
              : item.book.total_selling_price.toFixed(2)
          }}
        </td>
        <td>{{ item.book.coupon == null ? "" : item.book.coupon.value }}</td>
        <td>
          <span
            :class="[
              'badge p-2 ',
              item.book.status === 0
                ? 'bg-primary'
                : item.book.status === 1
                ? 'bg-warning text-dark'
                : item.book.status === 2
                ? 'bg-success'
                : item.book.status === 3
                ? 'bg-info text-dark'
                : item.book.status === 4
                ? 'bg-danger'
                : '',
            ]"
          >
            {{
              item.book.status === 0
                ? $t("initial")
                : item.book.status === 1
                ? $t("pending")
                : item.book.status === 2
                ? $t("completed")
                : item.book.status === 3
                ? $t("edited")
                : item.book.status === 4
                ? $t("canceled")
                : ""
            }}
          </span>
        </td>
        <td v-if="item.book.payment_status == '0'">{{ $t("Pending") }}</td>
        <td v-if="item.book.payment_status == '1'">{{ $t("Accept") }}</td>
        <td v-if="item.book.payment_status == '2'">{{ $t("Decline") }}</td>
        <td v-if="item.book.payment_status == '3'">
          {{ $t("REFUND PENDING ") }}
        </td>
        <td v-if="item.book.payment_status == '4'">
          {{ $t("REFUND Accept ") }}
        </td>
        <td v-if="item.book.payment_status == '5'">
          {{ $t("REFUND Decline ") }}
        </td>
        <td v-if="item.book.payment_status == '6'">
          {{ $t("payment send ") }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Skeleton from "primevue/skeleton";
export default {
  name: "hotel-pending",
  props: ["books", "loading"],

  components: {
    Skeleton,
  },
};
</script>

<style></style>
