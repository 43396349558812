<script setup>
import { ref, onMounted } from "vue";
import Datepicker from "vuejs3-datepicker";
import Multiselect from "vue-multiselect";
import HeaderPdf from "@/components/pdf/headerPdf.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import moment from "moment";
import axios from "axios";
import Skeleton from "primevue/skeleton";
const fromDate = ref(null);
const toDate = ref(null);
const income = ref([]);
const loader = ref(true);
const costCenters = ref([]);
const costCenterValue = ref(null);
const fetchIncome = () => {
  axios
    .post("/income_statement")
    .then(({ data }) => {
      income.value = data.data;
      loader.value = false;
      // console.log(income.value);
    })
    .catch((error) => {
      console.log(error);
    });
};

onMounted(() => {
  fetchIncome();
  fetchCostCenter();
});
const incomeFilter = () => {
  const formData = new FormData();
  formData.append("from", moment(fromDate.value).format("YYYY-MM-DD"));
  formData.append("to", moment(toDate.value).format("YYYY-MM-DD"));
  formData.append("cost_center_id", costCenterValue.value.id);
  axios
    .post("/income_statement", formData)
    .then((res) => (income.value = res.data.data));
};
// const calculateTotalBalance = (branches) => {
//   return branches.reduce((total, branch) => total + (branch.balance || 0), 0);
// };
const fetchCostCenter = () => {
  axios.post("/index_cost_center").then(({ data }) => {
    costCenters.value = data.data.data;
  });
};
</script>

<template>
  <div class="card_style">
    <div class="d-flex gap-2 align-items-center mb-3">
      <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />

      <h1>{{ $t("income_list") }}</h1>
    </div>
    <form @submit.prevent="incomeFilter" class="row">
      <div class="col-3">
        <label for="date" class="form-label">{{ $t("from") }}</label>
        <Datepicker
          v-model="fromDate"
          :not-after="toDate"
          name="from"
          :placeholder="$t('From')"
        />
      </div>
      <div class="col-3">
        <label for="date" class="form-label">{{ $t("to") }}</label>
        <Datepicker
          v-model="toDate"
          name="to"
          :placeholder="$t('To')"
          :from="fromDate"
        />
      </div>
      <div class="col-3">
        <label for="date" class="form-label">{{ $t("cost_center") }}</label>
        <Multiselect
          v-model="costCenterValue"
          :placeholder="$t('Cost_Center')"
          :options="costCenters"
          label="title"
          :clear-on-select="true"
          select-label=""
          :hide-selected="true"
          track-by="id"
        />
      </div>

      <div class="col-3 d-flex gap-2 justify-content-end mt-2">
        <button class="btn PrimaryButton" type="submit">
          {{ $t("submit") }}
        </button>
        <button
          class="btn PrimaryButton"
          type="button"
          v-print="'#printStatments'"
        >
          {{ $t("print") }}
        </button>
      </div>
    </form>

    <hr />
    <div class="row">
      <div class="col-12" v-if="loader">
        <div
          class="border d-flex align-items-center justify-content-between py-3 px-3 w-100"
          v-for="i in 5"
          :key="i"
        >
          <Skeleton height="2rem" width="10%" class="mb-2"></Skeleton>

          <Skeleton height="2rem" width="80%" class="mb-2"></Skeleton>
        </div>
      </div>
      <div id="printStatments" class="col-12" v-else>
        <HeaderPdf
          :title="$t('income_list')"
          :fromDate="fromDate"
          :toDate="toDate"
        />
        <div class="">
          <div class="d-flex">
            <div class="border p-2 d-flex align-items-center w-25">
              <p class="mb-0">
                {{ income?.income?.title_ar }}
              </p>
            </div>
            <div class="w-100 py-2 border">
              <div
                v-for="(branch, index) in income?.income?.branches"
                :key="index"
                :class="[
                  'd-flex',
                  'align-items-center',
                  'justify-content-between',
                  'border-bottom',
                  'p-3',
                ]"
              >
                <p class="mb-0">
                  <router-link :to="`/tree/cost_center/${branch.id}`">{{
                    branch.title_ar
                  }}</router-link>
                </p>
                <p class="mb-0">{{ branch.balance }}</p>
              </div>
              <div
                :key="index"
                :class="[
                  'd-flex',
                  'align-items-center',
                  'justify-content-between',
                  'p-3',
                  'bg-success text-white',
                  'fw-bold',
                ]"
              >
                <p class="mb-0">{{ $t("total") }}</p>
                <p class="mb-0">{{ income?.income?.branches_total_balance }}</p>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="border p-2 d-flex align-items-center w-25">
              <p class="mb-0">
                {{ income?.procurement?.title_ar }}
              </p>
            </div>
            <div class="w-100 py-2 border">
              <div
                v-for="(branch, index) in income?.procurement?.branches"
                :key="index"
                :class="[
                  'd-flex',
                  'align-items-center',
                  'justify-content-between',
                  'p-3',
                  'border-bottom',
                ]"
              >
                <!--                  index === item.branches.length - 1 ? '' : 'border-bottom',-->
                <router-link :to="`/tree/cost_center/${branch.id}`">{{
                  branch.title_ar
                }}</router-link>
                <p class="mb-0">{{ branch.balance }}</p>
              </div>
              <div
                :key="index"
                :class="[
                  'd-flex',
                  'align-items-center',
                  'justify-content-between',
                  'p-3',
                  'bg-success text-white ',
                ]"
              >
                <p class="mb-0">{{ $t("total") }}</p>
                <p class="mb-0">
                  {{ income?.procurement?.branches_total_balance }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="d-flex bg-info text-white fw-bold py-3 justify-content-between text-white px-3"
          >
            <p class="mb-0">{{ $t("total") }}</p>
            <p class="mb-0">
              {{ income?.total_income_and_procurement }}
            </p>
          </div>
          <div class="d-flex">
            <div class="border p-2 d-flex align-items-center w-25">
              <p class="mb-0">
                {{ income?.expenditure?.title_ar }}
              </p>
            </div>
            <div class="w-100 py-2 border">
              <div
                v-for="(branch, index) in income?.expenditure?.branches"
                :key="index"
                :class="[
                  'd-flex',
                  'align-items-center',
                  'justify-content-between',
                  'p-3',
                  'border-bottom',
                ]"
              >
                <!--                  index === item.branches.length - 1 ? '' : 'border-bottom',-->
                <router-link :to="`/tree/cost_center/${branch.id}`">{{
                  branch.title_ar
                }}</router-link>
                <p class="mb-0">{{ branch.balance }}</p>
              </div>
              <div
                :key="index"
                :class="[
                  'd-flex',
                  'align-items-center',
                  'justify-content-between',
                  'p-3',
                  'bg-success text-white',
                ]"
              >
                <p class="mb-0">{{ $t("total") }}</p>
                <p class="mb-0">
                  {{ income?.expenditure?.branches_total_balance }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="d-flex bg-info text-white fw-bold py-3 justify-content-between text-white px-3"
          >
            <p class="mb-0">{{ $t("total") }}</p>
            <p class="mb-0">
              {{ income?.total_balance }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
