<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4 class="mb-0">
        {{ $t("Clients Opinion") }}
      </h4>
    </div>
    <div class="main_header_style mb-2">
      <input
        type="text"
        v-model="clientText"
        @keyup="fetchOpinions((page = 1))"
        class="form-control w-50"
        :placeholder="$t('search...')"
      />
      <router-link to="/client_opinion/add" class="PrimaryButton mt-0 btn"
        ><i class="fa-solid fa-plus"></i> {{ $t("add opinion") }}
      </router-link>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table client-opinion">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("Image") }}</th>
              <th>{{ $t("name") }}</th>
              <th>{{ $t("describtion") }}</th>
              <th>{{ $t("country") }}</th>
              <th>{{ $t("rate") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="opinion in 10" :key="opinion">
              <td>
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
              <td width="8%">
                <Skeleton shape="circle" size="5rem"></Skeleton>
              </td>
              <td v-for="opinion in 5" :key="opinion">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(opinion, index) in opinions.data" :key="index">
              <td>{{ opinion.id }}</td>
              <td><img :src="opinion.image" :alt="opinion.name" /></td>
              <td>{{ opinion.name }}</td>
              <td>
                <span v-html="opinion.content.substring(0, 50) + ' ...'"></span>
              </td>
              <td>{{ opinion.country_id }}</td>
              <td>{{ opinion.rate }}</td>
              <td>
                <router-link
                  :to="`/client_opinion/edit/${opinion.id}`"
                  class="btn btn-success mx-1 w-auto"
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </router-link>

                <div
                  class="btn btn-danger mx-1 w-auto"
                  @click="deleteOpinion(opinion.id, index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="opinions"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchOpinions"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  name: "clientOpinion",
  data() {
    return {
      opinions: [],
      clientText: "",
      loading: true,
    };
  },
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  methods: {
    fetchOpinions(page = 1) {
      axios
        .post(`/client_opinion?page=${page}`, { word: this.clientText })
        .then(({ data }) => {
          this.opinions = data.data;
          this.loading = false;
        });
    },
    deleteOpinion(opinionId, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios
            .delete(`/website/client_opinion/${opinionId}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.opinions.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
            });
        }
      });
    },
  },
  mounted() {
    this.fetchOpinions();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style>
td {
}
</style>
