<template>
  <section class="card_style">
    <div class="main_header_style flex-column">
      <h4>{{ $t("add_clients") }}</h4>
    </div>

    <form @submit.prevent="addClient()">
      <div class="row">
        <!-- room name ar -->
        <div class="col-lg-4 mb-4">
          <label for="name">{{ $t("name") }}</label>
          <input
            type="text"
            :placeholder="$t('name')"
            id="name"
            v-model="name"
          />
          <span class="input-errors" v-if="v$.name.$error">{{
            v$.name.$errors[0].$message
          }}</span>
        </div>

        <!-- room name en -->
        <div class="col-lg-4 mb-4">
          <label for="email">{{ $t("email") }}</label>
          <input
            type="email"
            :placeholder="$t('email')"
            id="email"
            v-model="email"
          />
          <span class="input-errors" v-if="v$.email.$error"
            >{{ v$.email.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="type">{{ $t("client type") }}</label>
          <Multiselect
            v-model="client_type"
            :options="types.map((type) => type.id)"
            :custom-label="(opt) => types.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('choose_client_type')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="type">{{ $t("client_gender") }}</label>
          <Multiselect
            v-model="client_type_value"
            :options="client_types"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose_client_type')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
          <span class="input-errors" v-if="v$.client_type_value.$error"
            >{{ v$.client_type_value.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="type" class="fw-bold"
            >{{ $t("Manual code") }}

            <sub class="info">
              <b>( {{ $t("unique code for client") }} )</b>
            </sub>
          </label>
          <input
            class="form-control"
            type="text"
            :placeholder="$t('Manual code')"
            v-model="manual_code"
          />
        </div>

        <div class="col-lg-4 mb-4">
          <label for="type">{{ $t("discount_type") }}</label>
          <Multiselect
            v-model="discount_value"
            :options="discount_types"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('discount_type')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="adult">{{ $t("phone") }}</label>
          <input type="number" :placeholder="$t('phone')" v-model="phone" />
          <span class="input-errors" v-if="v$.phone.$error"
            >{{ v$.phone.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="country">{{ $t("country") }}</label>
          <Multiselect
            v-model="countryValues"
            :options="countries.map((country) => country.id)"
            :custom-label="(opt) => countries.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('choose_country')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
          <span class="input-errors" v-if="v$.countryValues.$error"
            >{{ v$.countryValues.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="address">{{ $t("location") }}</label>
          <input type="text" :placeholder="$t('location')" v-model="address" />
          <span class="input-errors" v-if="v$.address.$error"
            >{{ v$.address.$errors[0].$message }}
          </span>
        </div>

        <div class="row" v-if="client_type == 2">
          <div class="col-lg-6 mb-4">
            <label for="tax">{{ $t("tax number") }}</label>
            <input
              type="text"
              :placeholder="$t('tax number')"
              v-model="tax_number"
            />
          </div>

          <div class="col-lg-6 mb-4">
            <label for="Registration">{{ $t("Registration number") }}</label>
            <input
              type="text"
              :placeholder="$t('Registration number')"
              v-model="registration_number"
            />
          </div>
        </div>

        <div class="col-12 my-2">
          <div class="d-flex align-items-center gap-2">
            <Checkbox v-model="checked" :binary="true" />
            <p class="mb-0">{{ $t("can deal with as supplier") }}</p>
          </div>
        </div>

        <div class="col-lg-12 mb-4">
          <div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="cash"
                v-model="payment_method"
                value="1"
              />
              <label class="form-check-label" for="cash">
                {{ $t("cash") }}
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="pending"
                v-model="payment_method"
                value="2"
              />
              <label class="form-check-label" for="pending">
                {{ $t("pending_payment") }}
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="prepending"
                v-model="payment_method"
                value="3"
              />
              <label class="form-check-label" for="prepending">
                {{ $t("Pre_Payment") }}
              </label>
            </div>
          </div>
        </div>

        <div class="row" v-if="payment_method == 3">
          <div class="col-lg-4 mb-4">
            <label for="adult">{{ $t("down_money") }}</label>
            <input
              type="number"
              :placeholder="$t('enter_down_money')"
              v-model="down_payment"
            />
          </div>
          <!-- start input group -->
          <div class="col-lg-4 col-12 mb-4">
            <div class="input-group">
              <label class="form-label">{{
                $t("choose_payment_method")
              }}</label>
              <Multiselect
                v-model="paidValues"
                :options="paids.map((paid) => paid.id)"
                :custom-label="(opt) => paids.find((x) => x.id == opt).title"
                :clear-on-select="true"
                :placeholder="$t('choose_payment_method')"
                select-label=""
                :hide-selected="true"
                @update:model-value="FetchAccounts(paidValues)"
              >
              </Multiselect>
            </div>
          </div>
          <!-- finish input group -->

          <div class="col-lg-4 col-12 mb-4">
            <label class="form-label">{{ $t("accounts") }}</label>
            <Multiselect
              v-model="accountValue"
              :options="accounts.map((account) => account.id)"
              :custom-label="
                (opt) => accounts.find((x) => x.id == opt).title_ar
              "
              :clear-on-select="true"
              :placeholder="$t('accounts')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <div v-if="payment_method == 2">
          <div class="col-lg-6 mb-4">
            <label for="adult">{{ $t("Credit Limit") }}</label>
            <input
              type="number"
              :placeholder="$t('Enter Credit Limit')"
              v-model="credit_limit"
            />
          </div>
        </div>
      </div>

      <button type="submit" class="add save btn">{{ $t("add") }}</button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

import Checkbox from "primevue/checkbox";

export default {
  name: "add-suppliers",

  data() {
    return {
      checked: false,
      v$: useVuelidate(),
      pageId: this.$route.params.page,
      manual_code: "",
      name: "",
      email: "",
      phone: "",
      address: "",
      countryValues: "",
      countries: [],
      credit_limit: 0, // Initialize as an empty string to store the selected option
      down_payment: 0,
      paids: [
        {
          id: 1,
          title: this.$t("treasury"),
        },
        {
          id: 2,
          title: this.$t("bank"),
        },
        {
          id: 3,
          title: this.$t("ewalllets"),
        },
        {
          id: 4,
          title: this.$t("checks_under_collection"),
        },
      ],
      accountValue: "",
      paidValues: "",
      accounts: [],
      client_type: "",
      types: [
        {
          id: 1,
          title: this.$t("person"),
        },
        {
          id: 2,
          title: this.$t("company"),
        },
      ],
      payment_method: 1,
      registration_number: "",
      tax_number: "",
      client_types: [],
      client_type_value: "",
      discount_types: [],
      discount_value: "",
    };
  },
  components: {
    Multiselect,
    Checkbox,
  },
  validations() {
    return {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      address: {
        required,
      },
      countryValues: {
        required,
      },
      client_type_value: {
        required,
      },
    };
  },
  methods: {
    addClient() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        if (this.manual_code) {
          formData.append("manual_code", this.manual_code);
        }
        if (this.name) {
          formData.append("name", this.name);
        }
        if (this.email) {
          formData.append("email", this.email);
        }
        if (this.phone) {
          formData.append("phone", this.phone);
        }
        if (this.address) {
          formData.append("address", this.address);
        }
        if (this.countryValues) {
          formData.append("country_id", this.countryValues);
        }
        formData.append("role", 1);
        if (this.client_type) {
          formData.append("client_type", this.client_type);
        }
        if (this.client_type_value) {
          formData.append("client_type_id", this.client_type_value.id);
        }
        if (this.discount_value) {
          formData.append("discount_type_id", this.discount_value?.id);
        }
        formData.append(`is_supplier`, this.checked ? 1 : 0);

        formData.append(`payment_method`, this.payment_method);
        if (this.payment_method == 2) {
          formData.append("Pending_limit", this.credit_limit);
          formData.append("credit_limit", this.credit_limit);
        } else if (this.payment_method == 3) {
          formData.append("prepayment_amount", this.down_payment);
          formData.append("down_payment", this.down_payment);
          if (this.accountValue) {
            formData.append(`safe_branch_id`, this.accountValue);
            formData.append(`payment_method_type`, this.paidValues);
          }
        }
        if (this.client_type == 2) {
          formData.append("registration_number", this.registration_number);
          formData.append("tax_number", this.tax_number);
        }
        axios
          .post("/consumers", formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });

            this.visible = false;

            // console.log(data)
            if (this.pageId == 1) {
              this.$router.push("/book_offline");
            } else {
              if (this.$route.path == `clients/add/${this.pageId}`) {
                this.$router.push("/clients");
              }
            }

            this.$emit("closrDilaog", false);
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });

            this.$emit("closrDilaog", false);
          });
      }
    },

    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
        // console.log(this.countries);
      });
    },

    FetchAccounts(account_id) {
      let type = { payment_method: account_id };
      axios.post(`/fetch_payment_method`, type).then(({ data }) => {
        this.accounts = data.data;
        //  console.log(this.accounts)
      });
    },
    fetch_client_types() {
      axios.post("/index_client_types").then(({ data }) => {
        // console.log(data.data.data);
        this.client_types = data.data.data;
      });
    },
    fetch_discount_value() {
      axios.get("/discount_types").then(({ data }) => {
        // console.log(data);
        this.discount_types = data.data.data;
      });
    },
  },

  created() {
    this.fetch_country();
    this.fetch_client_types();
    this.fetch_discount_value();
  },
};
</script>

<style lang="scss" scoped>
@import "@/view/dashboard/clients/_clients";

form {
  label {
    display: inline-block;
    margin-bottom: 10px;
    font-family: "bold" !important;
  }

  input,
  select {
    display: block;
    width: 100%;
    // height: 68px;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    color: #9ca2ac;
    padding: 10px 15px;
  }
}

.form-check-inline {
  display: inline-flex;
  flex-direction: column;
  margin-top: 0;
}

.form-check-input {
  width: 17px;
  height: 17px;
  padding: 0;
}
.info {
  font-size: 0.7rem;
}
</style>
