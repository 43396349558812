<template>
  <div class="col-12">
    <ul>
      <li v-if="book_status_name == 'AMENDED'">
        <router-link :to="`/book_table/${id}/amendment`">
          AMENDMENT
        </router-link>
      </li>

      <li v-if="book_status_name == 'CANCELED'">
        <router-link :to="`/book_table/${id}/cancelation`">
          Cancelation
        </router-link>
      </li>

      <li v-if="book_status_name != 'PENDING' && book_status_name != 'TENTATIVE'">
        <router-link :to="`/book_table/${id}/pill-confirm`">
          Confirmation
        </router-link>
      </li>
      <li v-if="book_status_name != 'PENDING' && book_status_name != 'TENTATIVE'">
        <router-link :to="`/book_table/${id}/non-refundable`">
          Non Refundable
        </router-link>
      </li>
      <li v-if="book_status_name == 'CANCELED' || book_status_name == 'AMENDED' || book_status_name == 'CONFIRMED'">
        <router-link :to="`/book_table/${id}/tax-innvoice-en`">
          TAX INVOICE EN
        </router-link>
      </li>
      <li v-if="book_status_name == 'CANCELED' || book_status_name == 'AMENDED' || book_status_name == 'CONFIRMED'">
        <router-link :to="`/book_table/${id}/tax-innvoice-ar`">
          TAX INVOICE AR
        </router-link>
      </li>

      <li v-if="book_status_name != 'PENDING'">
        <router-link :to="`/book_table/${id}/option-date`">
          TENTATIVE
        </router-link>
      </li>

      <li>
        <router-link :to="`/book_table/${id}/price-offer`">
          PRICE OFFER
        </router-link>
      </li>
      <li v-if="book_status_name != 'PENDING'">
        <router-link :to="`/book_table/${id}/report`"> REPORT </router-link>
      </li>
      <li v-if="book_status_name == 'CANCELED' || book_status_name == 'AMENDED' || book_status_name == 'CONFIRMED'">
        <router-link :to="`/book_table/${id}/rooming-list`">
          ROOMING LIST
        </router-link>
      </li>
      <li>
        <router-link :to="`/book_table/${id}/supplier-voucher`">
          Supplier Voucher
        </router-link>
      </li>
      <li>
        <router-link :to="`/book_table/${id}/voucher-en`">
           Voucher En
        </router-link>
      </li>
      <li>
        <router-link :to="`/book_table/${id}/voucher-ar`">
           Voucher Ar
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "sidebar-Pill",
  props: {
    book_status_name: String
  },
  data() {
    return {
      id: this.$route.params.id
    }
  }
};
</script>
<style lang="scss" scoped>
ul {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  margin-top: 1rem;
  li {
    margin-inline-end: 1rem;
    &:first-child {
      margin-inline-end: 0;
    }
    a {
      font-size: 0.7rem;
      font-family: "bold";
      border: 1px solid #0a909b;
      color: #0a909b;
      transition: 0.7s ease-in-out;
      padding: 0.5rem;
      border-radius: 7px;
      &:hover {
        background-color: #0a909b;
        border: 1px solid #0a909b;
        transition: 0.7s ease-in-out;
        color: #fff;
      }
    }
    a.active {
      background-color: #0a909b;
      border: 1px solid #0a909b;
      transition: 0.7s ease-in-out;
      color: #fff;
    }
  }
}
</style>
