<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
        <h4>
          {{ $t("flight_reservations") }}
        </h4>
      </div>

      <div class="search-user w-50 my-3">
        <form>
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            class="form-control"
            :placeholder="$t('search...')"
            v-model="query"
            @keyup="fetchAlluser"
          />
        </form>
      </div>
    </div>

    <div class="row">
      <table class="table" id="userTable">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("reservation_date") }}</th>
            <th>{{ $t("reservation_number") }}</th>
            <th>{{ $t("type") }}</th>
            <th>{{ $t("airport_departure_name") }}</th>
            <th>{{ $t("Interface") }}</th>
            <th>{{ $t("tour_degree") }}</th>
            <th>{{ $t("trip_number") }}</th>
            <th>{{ $t("Status") }}</th>
            <th>{{ $t("no_passenger") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 11" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) in alluser.data" :key="index">
            <!-- :class="getTableRowClass(item.reservation_status)" -->
            <td :data-label="$t('ID')">{{ item.id }}</td>
            <td :data-label="$t('reservation_date')">
              <div v-for="(trip, tripIndex) in item.trips" :key="tripIndex">
                {{ trip.arrive_date }}
              </div>
            </td>
            <td class="reservation_num" :data-label="$t('reservation_number')">
              {{ item.reservation_num }}
            </td>
            <td :data-label="$t('type_flight')">
              {{
                item.flight_type == "one_way"
                  ? $t("one_way")
                  : item.flight_type == "two_way"
                  ? $t("two_way")
                  : $t("multi_city")
              }}
            </td>
            <td :data-label="$t('reservation_date')">
              <div v-for="(trip, tripIndex) in item.trips" :key="tripIndex">
                {{ trip.departure }}
              </div>
            </td>
            <td :data-label="$t('reservation_date')">
              <div v-for="(trip, tripIndex) in item.trips" :key="tripIndex">
                {{ trip.arrival }}
              </div>
            </td>
            <td :data-label="$t('tour_degree')">{{ item.trip_degree }}</td>
            <td :data-label="$t('trip_history')">{{ item.trip_num }}</td>
            <td :data-label="$t('Status')">
              <span
                :class="[
                  'badge',
                  'p-2',
                  item.reservation_status == 'basically'
                    ? 'bg-primary'
                    : item.reservation_status == 'confirmed'
                    ? 'bg-dark text-white'
                    : item.reservation_status == 'updated'
                    ? 'bg-success'
                    : item.reservation_status == 'accepted'
                    ? 'bg-info text-dark'
                    : item.reservation_status == 'canceled'
                    ? 'bg-danger'
                    : '',
                ]"
              >
                {{
                  item.reservation_status == "basically"
                    ? $t("basically")
                    : item.reservation_status == "confirmed"
                    ? $t("confirmed")
                    : item.reservation_status == "updated"
                    ? $t("updated")
                    : item.reservation_status == "accepted"
                    ? $t("accepted")
                    : item.reservation_status == "canceled"
                    ? $t("canceled")
                    : ""
                }}
              </span>
            </td>
            <td :data-label="$t('no_passenger')">{{ item.num_of_pax }}</td>
            <td>
              <router-link
                :to="`/b2cUsersflight/${item.id}`"
                class="btn btn-info mx-1 w-auto"
              >
                <i class="fa-solid fa-info"></i>
              </router-link>

              <button
                class="btn btn-success"
                type="button"
                data-bs-toggle="modal"
                :data-bs-target="`#exampleModal_show${item.id}`"
                v-if="item.receipts.length > 0 && item.payment_method == 1"
              >
                {{ $t("view receipt") }}
              </button>
              <button
                class="btn btn-warning mx-2"
                type="button"
                @click="upladReceipt(item.id, Flight)"
                v-else
              >
                {{ $t("order_receipt") }}
              </button>

              <showReceipt
                :book_id="item.id"
                :book_type="Flight"
                :organizationAccounts="organizationAccounts"
                :fetchAlluser="fetchAlluser"
                :image="
                  item?.receipts[0]?.image ? item?.receipts[0]?.image : ''
                "
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="alluser"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchAlluser"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import showReceipt from "@/view/dashboard/flight-client/showReceipt.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";

export default {
  name: "b2cUsers",
  components: {
    pagination,
    showReceipt,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      alluser: [],
      Flight: "Flight",
      count: 10,
      book_person_type: 2,
      query: "",
      organizationAccounts: [],
      loading: true,
    };
  },
  methods: {
    fetchOrganizationAccounts() {
      axios
        .post("/fetch_my_organization_accounts", { type: 1 })
        .then(({ data }) => {
          this.organizationAccounts = data.data.data;
          // console.log(this.organizationAccounts);
        });
    },
    fetchOrganizationSafe() {
      axios
        .post("/fetch_my_organization_accounts", { type: 2 })
        .then(({ data }) => {
          this.organizationSafe = data.data.data;
          // console.log(this.organizationSafe);
        });
    },
    upladReceipt(id, type) {
      axios
        .post(`/send_receipt_request`, { book_id: id, book_type: type })
        .then(() => {
          Swal.fire({
            title: "",
            text: this.$t("order_receipt_sent"),
            icon: "success",
          });
        });
    },
    fetchAlluser(page = 1) {
      let user_data = {
        count: this.count,
        book_person_type: this.book_person_type,
      };
      if (this.query != "") {
        user_data.reservation_num = this.query;
      }
      // axios.post(`/fetch_all_flight`, user_data).then((res) => {
      //   this.alluser = res.data.data.data;
      // });

      axios
        .post(`/flight_reservation?page=${page}`, user_data)
        .then(({ data }) => {
          this.alluser = data.data;
          this.loading = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },

    reservartionStatus(book_id, status) {
      const data = {
        book_id: book_id,
        receipt_status: status,
        book_type: "Flight",
      };
      axios.post(`/change_receipt_status`, data).then((res) => {
        this.alluser = res.data.data.data;
      });
    },
  },
  mounted() {
    this.fetchAlluser();
    this.fetchOrganizationAccounts();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_user.scss";

.main_header_style {
  align-items: center;

  h4 {
    margin-bottom: 0;
  }
}

.search-user {
  form {
    position: relative;

    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }

    input {
      padding-right: 40px !important;
    }
  }
}
.reservation_num {
  unicode-bidi: plaintext;
  text-align: end;
}
.btn-warning {
  @media (max-width: 1200px) {
    font-size: 14px;
    // margin-top: 10px;
  }
}
</style>
