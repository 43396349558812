<template>
  <div class="Flight_settings">
    <div class="container">
      <div class="row border">
        <div class="col-lg-4 col-md-4 col-6">
          <div class="d-flex align-items-center justify-content-between p-2">
            <div class="d-flex align-items-center justify-content-center mx-1">
              <img src="@/assets/media/image/icon/airplane.svg" />
              <h6 class="hotel_settings m-0">{{ $t("Flight_settings") }}</h6>
            </div>

            <input
              type="number"
              class="form-control flight_num w-25"
              :placeholder="$t('Enter the number of trips')"
              v-model="number_of_trips"
            />
            <div class="input-errors" v-if="v$.number_of_trips.$error">
              {{ v$.number_of_trips.$errors[0].$message }}
            </div>
          </div>
        </div>

        <div
          class="col-lg-8 col-md-8 col-6 d-flex align-items-center justify-content-center bg-price border-start"
        >
          <img src="@/assets/media/image/icon/moneys.svg" class="mx-1" />
          <span class="flight_num"> {{ $t("prices") }} </span>
        </div>
      </div>

      <div
        class="row bg-wheate border-start border-end"
        v-for="(flight, index) in flights"
        :key="index"
      >
        <div class="d-flex justify-content-between align-items-center my-2">
          <div class="trip d-flex align-items-center">
            <img src="@/assets/media/image/icon/Vector2.svg" />
            <p class="first_diriction mx-1 mb-0">{{ index + 1 }}</p>
          </div>
          <div class="form-check d-flex align-items-center">
            <input
              class="form-check-input"
              type="checkbox"
              :id="`contractFlight${index}`"
              v-model="flight.is_contract"
              @change="check_is_contract(index)"
            />
            <label
              class="form-check-label mx-2"
              :for="`contractFlight${index}`"
            >
              {{ $t("choose from contracts") }}
            </label>
          </div>
        </div>
        <div class="col-lg-12 d-flex align-items-start">
          <div class="row w-100">
            <div class="col-lg-3 col-md-4 col-12">
              <div class="form-group mb-0">
                <label class="typo__label custom_label">{{
                  $t("lunch airport")
                }}</label>
                <multiselect
                  v-model="flight.lunchObj"
                  :options="flight.lunchArr"
                  label="title"
                  track-by="id"
                  :custom-label="flightLabel"
                  :clear-on-select="true"
                  :placeholder="$t('choose')"
                  select-label=""
                  :hide-selected="true"
                  @keyup="searchLunch($event, index)"
                  @update:model-value="check_is_contract(index)"
                ></multiselect>
                <div
                  v-for="error in v$.flights.$each.$response.$errors[index]
                    .lunchObj"
                  :key="error"
                  class="input-errors"
                >
                  {{ error.$message }}
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-12">
              <div class="form-group mb-0">
                <label class="typo__label custom_label">{{
                  $t("arrival airport")
                }}</label>
                <multiselect
                  v-model="flight.returnObj"
                  :options="flight.returnArr"
                  label="title"
                  track-by="id"
                  :custom-label="flightLabel"
                  :clear-on-select="true"
                  :placeholder="$t('choose')"
                  select-label=""
                  :hide-selected="true"
                  @keyup="searchReturn($event, index)"
                  @update:model-value="check_is_contract(index)"
                ></multiselect>
                <div
                  v-for="error in v$.flights.$each.$response.$errors[index]
                    .returnObj"
                  :key="error"
                  class="input-errors"
                >
                  {{ error.$message }}
                </div>
              </div>
            </div>

            <div
              class="col-lg-3 col-md-4 col-12"
              v-if="flight.is_contract == false"
            >
              <div class="form-group mb-0">
                <label class="typo__label custom_label">{{
                  $t("flight companies")
                }}</label>
                <multiselect
                  v-model="flight.company_value"
                  :options="flight.flightCompanies"
                  label="name"
                  track-by="id"
                  :custom-label="flightCompanyLabel"
                  :clear-on-select="true"
                  :placeholder="$t('choose')"
                  select-label=""
                  :hide-selected="true"
                  @keyup="searchCompany($event, index)"
                ></multiselect>
                <div
                  v-for="error in v$.flights.$each.$response.$errors[index]
                    .company_value"
                  :key="error"
                  class="input-errors"
                >
                  {{ error.$message }}
                </div>
              </div>
            </div>

            <div
              class="col-lg-3 col-md-4 col-12"
              v-if="flight.is_contract == false"
            >
              <div class="form-group mb-0">
                <label class="typo__label custom_label">{{
                  $t("cabin_codes")
                }}</label>
                <multiselect
                  v-model="flight.cabin_code"
                  :options="cabin_codes"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('choose')"
                  select-label=""
                  :hide-selected="true"
                ></multiselect>
                <div
                  v-for="error in v$.flights.$each.$response.$errors[index]
                    .cabin_code"
                  :key="error"
                  class="input-errors"
                >
                  {{ error.$message }}
                </div>
              </div>
            </div>

            <div
              class="col-lg-3 col-md-4 col-12"
              v-if="flight.is_contract == false"
            >
              <div class="form-group mb-0">
                <label class="typo__label custom_label">{{
                  $t("trip_number")
                }}</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="flight.trip_number"
                />
              </div>
              <div
                v-for="error in v$.flights.$each.$response.$errors[index]
                  .trip_number"
                :key="error"
                class="input-errors"
              >
                {{ error.$message }}
              </div>
            </div>

            <div
              class="col-lg-3 col-md-4 col-12"
              v-if="flight.is_contract == true"
            >
              <div class="form-group mb-0">
                <label class="typo__label custom_label">{{
                  $t("contract")
                }}</label>
                <multiselect
                  v-model="flight.contract"
                  :options="flight.contracts"
                  label="name"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('choose')"
                  select-label=""
                  :hide-selected="true"
                  @update:model-value="
                    fetch_flight_rate_codes_by_contract_id(index)
                  "
                ></multiselect>
                <!-- <div
                  v-for="error in v$.flights.$each.$response.$errors[index].contract"
                  :key="error"
                  class="input-errors"
                >
                  {{ error.$message }}
                </div> -->
              </div>
            </div>

            <div
              class="col-lg-3 col-md-4 col-12"
              v-if="flight.is_contract == true"
            >
              <div class="form-group mb-0">
                <label class="typo__label custom_label">{{
                  $t("Rate Code")
                }}</label>
                <multiselect
                  v-model="flight.rate_code"
                  :options="flight.rate_codes"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('choose')"
                  select-label=""
                  :hide-selected="true"
                  @update:model-value="get_prices(index)"
                ></multiselect>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-12">
              <div class="form-group mb-0">
                <label class="typo__label custom_label">{{
                  $t("Supplier")
                }}</label>
                <Multiselect
                  v-model="flight.supplierObj"
                  :options="providers"
                  label="name"
                  track-by="id"
                  :custom-label="formatSupplierLabel"
                  :clear-on-select="true"
                  :placeholder="$t('choose')"
                  select-label=""
                  :hide-selected="true"
                ></Multiselect>
                <div
                  v-for="error in v$.flights.$each.$response.$errors[index]
                    .supplierObj"
                  :key="error"
                  class="input-errors"
                >
                  {{ error.$message }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-lg-12 border-start border-end"
          v-if="flight?.lunchObj?.title && flight?.returnObj?.title"
        >
          <div class="row">
            <div class="col-md-4 col-12">
              <label>{{ $t("adults") }}</label>
              <div class="mt-2">
                <div class="form-group">
                  <label class="typo__label custom_label">{{
                    $t("cost")
                  }}</label>
                  <input
                    type="number"
                    class="multiselect__tags form-control text-end p-2"
                    placeholder="0"
                    v-model="flight.adults.cost"
                  />
                </div>
                <div class="form-group mx-1">
                  <label class="typo__label custom_label">{{
                    $t("selling")
                  }}</label>
                  <input
                    type="number"
                    class="multiselect__tags form-control text-end p-2"
                    placeholder="0"
                    v-model="flight.adults.selling"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <label>{{ $t("childrens") }}</label>
              <div class="mt-2">
                <div class="form-group">
                  <label class="typo__label custom_label">{{ "cost" }}</label>
                  <input
                    type="number"
                    class="multiselect__tags form-control text-end p-2"
                    placeholder="0"
                    v-model="flight.childrens.cost"
                  />
                </div>
                <div class="form-group mx-1">
                  <label class="typo__label custom_label">{{
                    $t("selling")
                  }}</label>
                  <input
                    type="number"
                    class="multiselect__tags form-control text-end p-2"
                    placeholder="0"
                    v-model="flight.childrens.selling"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <label>{{ $t("babies") }}</label>
              <div class="mt-2">
                <div class="form-group">
                  <label class="typo__label custom_label">{{ "cost" }}</label>
                  <input
                    type="number"
                    class="multiselect__tags form-control text-end p-2"
                    placeholder="0"
                    v-model="flight.babies.cost"
                  />
                </div>
                <div class="form-group mx-1">
                  <label class="typo__label custom_label">{{
                    $t("selling")
                  }}</label>
                  <input
                    type="number"
                    class="multiselect__tags form-control text-end p-2"
                    placeholder="0"
                    v-model="flight.babies.selling"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Multiselect } from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { helpers, required, minValue } from "@vuelidate/validators";

export default {
  name: "hotelSettings",
  props: ["dateStart", "dateEnd", "flightsData", "editedFlights", "providers"],
  emits: [
    "saveFlightsData",
    "sumAdultsCost",
    "sumAdultsSelling",
    "sumChildrenCost",
    "sumChildrenSelling",
  ],
  data() {
    return {
      v$: useVuelidate(),
      value: "",
      options: ["Select option", "options", "selected", "multiple", "label"],
      number_of_trips: 1,
      flights: [
        {
          lunchObj: {},
          lunchArr: [],
          returnObj: {},
          returnArr: [],
          is_contract: false,
          contract: {},
          contracts: [],
          rate_code: {},
          rate_codes: [],
          supplierObj: {},
          adults: {
            cost: null,
            selling: null,
          },
          childrens: {
            cost: null,
            selling: null,
          },
          babies: {
            cost: null,
            selling: null,
          },
          company_value: {},
          flightCompanies: [],
          cabin_code: "",
          trip_number: null,
        },
      ],
      cabin_codes: [
        {
          id: 1,
          title: "First",
        },
        {
          id: 2,
          title: "Business",
        },
        {
          id: 3,
          title: "Standard",
        },
        {
          id: 4,
          title: "Premium",
        },
        {
          id: 5,
          title: "Class",
        },
      ],
    };
  },
  components: {
    Multiselect,
  },
  validations() {
    return {
      number_of_trips: {
        required,
        minValue: minValue(1),
      },
      flights: {
        $each: helpers.forEach({
          lunchObj: {
            required,
          },
          returnObj: {
            required,
          },
          supplierObj: {
            required,
          },
          cabin_code: {
            required,
          },
          company_value: {
            required,
          },
          trip_number: {
            required,
          },
          // is_contract: function(value){
          //   if(value == true){
          //     return{
          //       contract: {required}
          //     }
          //   }
          // },
        }),
      },
    };
  },
  methods: {
    flightLabel(flight) {
      return Object.entries(flight).length == 0
        ? ""
        : `${flight.title} - ${flight.code} (${flight.id})`;
    },

    flightCompanyLabel(flight) {
      return Object.entries(flight).length == 0
        ? ""
        : `${flight.name} - ${flight.code} (${flight.id})`;
    },

    formatSupplierLabel(supplier) {
      return Object.entries(supplier).length == 0
        ? ""
        : `${supplier.name}  (${supplier.id}) `;
    },

    searchLunch(event, index) {
      const searchText = { title: event.target.value };
      axios
        .post(`/search_airports`, searchText)
        .then((res) => (this.flights[index].lunchArr = res.data.data))
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    searchReturn(event, index) {
      const searchText = { title: event.target.value };
      axios
        .post(`/search_airports`, searchText)
        .then((res) => (this.flights[index].returnArr = res.data.data))
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    check_is_contract(index) {
      if (this.flights[index].is_contract) {
        this.fetch_Contract_by_airport(index);
      }
    },
    fetch_Contract_by_airport(index) {
      if (this.flights[index].lunchObj.id && this.flights[index].returnObj.id) {
        axios
          .post(`/fetch_Contract_by_airport`, {
            arrive_airport: this.flights[index].lunchObj.id,
            dep_airport: this.flights[index].returnObj.id,
            from: this.dateStart,
            to: this.dateEnd,
          })
          .then(({ data }) => {
            // console.log(data)
            this.flights[index].contracts = data.data;
          })
          .catch(() => {
            // console.log(error)
            Swal.fire({
              title: "",
              text: this.$t("choose trip date"),
              // text: error.response.data.message,
              icon: "error",
            });
            this.flights[index].is_contract = false;
          });
      }
    },
    fetch_flight_rate_codes_by_contract_id(index) {
      axios
        .post(`/fetch_flight_rate_codes_by_contract_id`, {
          contract_id: this.flights[index].contract.id,
          from_date: this.dateStart,
          to_date: this.dateEnd,
        })
        .then(({ data }) => {
          // console.log(data)
          this.flights[index].rate_codes = data.data;
        })
        .catch((error) => {
          // console.log(error)
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    get_prices(index) {
      this.flights[index].adults = {
        cost: this.flights[index].rate_code.prices.adult_cost,
        selling: this.flights[index].rate_code.prices.adult_selling,
      };
      this.flights[index].childrens = {
        cost: this.flights[index].rate_code.prices.child_cost,
        selling: this.flights[index].rate_code.prices.child_selling,
      };
      this.flights[index].babies = {
        cost: this.flights[index].rate_code.prices.inf_cost,
        selling: this.flights[index].rate_code.prices.inf_selling,
      };
    },
    searchCompany(event, index) {
      const search = event.target.value;
      axios.get(`/flight_company/search?search=${search}`).then(({ data }) => {
        if (data.data) {
          this.flights[index].flightCompanies = data.data;
        }
      });
    },
  },
  mounted() {
    if (this.$route.params.id && this.editedFlights.length > 0) {
      this.number_of_trips = this.editedFlights.length;

      // Reset the flights array
      this.flights = [];

      this.editedFlights.forEach((el) => {
        console.log(el);
        const flight = {
          lunchObj: el.launch_airport,
          lunchArr: [], // Initialize lunchArr as an empty array
          returnObj: el.arrival_airport,
          returnArr: [], // Initialize returnArr as an empty array
          is_contract: el.flight_contract != null ? true : false,
          contract: el.contract_id == "" ? {} : el.contract_id,
          contracts: [],
          rate_code: el.rate_code_id == "" ? {} : el.rate_code_id,
          rate_codes: [],
          adults: {
            cost: el.adult_cost,
            selling: el.adult_selling,
          },
          childrens: {
            cost: el.child_cost,
            selling: el.child_selling,
          },
          babies: {
            cost: el.inf_cost,
            selling: el.inf_selling,
          },
          company_value: el.flight_company,
          flightCompanies: [], // Initialize flightCompanies as an empty array
          trip_number: el.trip_number,
          supplierObj: el.supplier,
        };
        this.cabin_codes.forEach((cabin) => {
          if (cabin.title == el.trip_degree) {
            flight.cabin_code = cabin;
          }
        });

        this.flights.push(flight);
      });

      // Fetch options for multiselects
      this.flights.forEach((flight, index) => {
        if (flight.is_contract == false) {
          this.searchCompany(
            {
              target: {
                value:
                  flight.company_value != undefined
                    ? flight.company_value.name
                    : "",
              },
            },
            index,
          );
        }
        this.searchLunch({ target: { value: flight?.lunchObj?.title } }, index);
        this.searchReturn(
          { target: { value: flight?.returnObj?.title } },
          index,
        );
      });
    }
  },
  watch: {
    flights: {
      deep: true,
      immediate: true,
      handler(value) {
        let flightsData = value.map((flight) => {
          return {
            contract_id: flight.is_contract == true ? flight.contract.id : null,
            rate_code_id:
              flight.is_contract == true ? flight.rate_code.id : null,
            launch_airport_id: flight.lunchObj.id,
            arrival_airport_id: flight.returnObj.id,
            supplier_id: flight.supplierObj?.id,
            adults: flight.adults,
            child: flight.childrens,
            inf: flight.babies,
            flight_company_id:
              flight.is_contract == false
                ? flight.company_value != undefined
                  ? flight.company_value.id
                  : null
                : null,
            trip_degree:
              flight.is_contract == false
                ? flight.cabin_code != undefined
                  ? flight.cabin_code.id
                  : null
                : null,
            trip_number: flight.trip_number,
          };
        });

        let flightsCost = value.map((flight) => {
          return {
            adults: flight.adults,
            child: flight.childrens,
            inf: flight.babies,
          };
        });
        function sumAdultsChildrenAndInf(flightsCost) {
          let sumAdultsCost = 0;
          let sumAdultsSelling = 0;
          let sumChildrenCost = 0;
          let sumChildrenSelling = 0;

          flightsCost.forEach((entry) => {
            sumAdultsCost += entry.adults.cost;
            sumAdultsSelling += entry.adults.selling;
            sumChildrenCost += entry.child.cost;
            // + entry.inf.cost
            sumChildrenSelling += entry.child.selling;
            // + entry.inf.selling
          });
          return {
            sumAdultsCost,
            sumAdultsSelling,
            sumChildrenCost,
            sumChildrenSelling,
          };
        }
        let {
          sumAdultsCost,
          sumAdultsSelling,
          sumChildrenCost,
          sumChildrenSelling,
        } = sumAdultsChildrenAndInf(flightsCost);

        this.$emit("saveFlightsData", flightsData);
        this.$emit("sumAdultsCost", sumAdultsCost);
        this.$emit("sumAdultsSelling", sumAdultsSelling);
        this.$emit("sumChildrenCost", sumChildrenCost);
        this.$emit("sumChildrenSelling", sumChildrenSelling);
      },
    },
    number_of_trips(value) {
      while (this.flights.length < value) {
        this.flights.push({
          lunchObj: {},
          lunchArr: [],
          returnObj: {},
          returnArr: [],
          contract: {},
          contracts: [],
          rate_code: {},
          rate_codes: [],
          adults: {
            cost: null,
            selling: null,
          },
          childrens: {
            cost: null,
            selling: null,
          },
          babies: {
            cost: null,
            selling: null,
          },
          company_value: {},
          flightCompanies: [],
          cabin_code: "",
          trip_number: null,
          is_contract: false,
        });
      }

      // If value is less than the current length, remove objects
      while (this.flights.length > value) {
        this.flights.pop();
      }
    },
  },
  updated() {},
};
</script>

<style lang="scss" scoped>
.row {
  margin: 0;
}

.Flight_settings {
  background-color: #fff;

  .title {
    font-family: "bold";
    color: #0b1427;
    font-size: 1.25rem;
  }

  .flight_num {
    color: #656b78;
    font-family: "regular";
    font-size: 0.8rem;
  }

  .flight-details {
    color: #0b1427;
    font-size: 1.2rem;
    font-family: "regular";
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-check-input {
    margin: 0;
    float: none;
    border-color: #656b78;
    // width: min-content;
  }

  .form-check-input:checked {
    margin: 0;
    float: none;
    border-color: #0a909b;
    background-color: #0a909b;
  }

  .form-check-label {
    color: #656b78;
    font-family: "bold";
    font-size: 1rem;
  }

  .hotel_settings {
    font-family: "bold";
    color: #0b1427;
    font-size: 1rem;
  }

  .subtitle-hotel-settings {
    color: #0b1427;
    font-size: 1rem;
  }

  .form-group {
    position: relative;
  }

  .custom_label {
    font-size: 0.6875rem;
    font-family: "bold";
    color: #0b1427;
    position: absolute;
    z-index: 22;
    // bottom: 5.5rem; //4.2rem
    top: -0.5rem;
    background: white;
    border-radius: 50%;
    text-align: center;
    color: #656b78;
    margin: 0 0.5rem;
  }

  .first_diriction {
    font-size: 1rem;
    font-family: "bold";
    color: #0b1427;
  }

  .form-check-input {
    margin: 0;
    float: none;
    border-color: #656b78;
  }

  .form-check-input:checked {
    margin: 0;
    float: none;
    border-color: #0a909b;
    background-color: #0a909b;
  }

  .form-check-label {
    color: #656b78;
    font-family: "bold";
    font-size: 1rem;
  }
}

.bg-wheate {
  background-color: #fdfbf8;
}

.bg-light-blue {
  background-color: rgba($color: #1664b8, $alpha: 3%);
}

.bg-price,
.adult {
  background-color: rgba($color: #0a909b, $alpha: 10%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.gap-4 {
  gap: 4.5rem !important;
}

.time {
  color: #0b1427;
  font-family: "bold";
  font-size: 1rem;
}

.number-available {
  border: 1px solid gray;
  width: fit-content;
  padding: 0 0.8rem;
  border-radius: 20px;
}

.live {
  color: #0a909b;
  font-family: "bold";
  font-size: 0.875rem;
}

.form-group {
  padding-bottom: 10px;
}
</style>
