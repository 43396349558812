<template>
    <div class="row" v-for="(item , index) in selected_attachments" :key="index">
        <div class="col-lg-3 col-md-3 col-12">
            <div class="form-group">
                <label class="form-label">{{$t("attachment_type")}}</label>
                <Multiselect
                    v-model="item.attachment_type_value"
                    :options="attachments_type"
                    :placeholder="$t('choose')"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    select-label=""
                    :hide-selected="true"
                    @update:model-value="fetch_amenities_by_amenity_type(index)"
                >
                </Multiselect>
            </div>
        </div>
        <div class="col-lg-8 col-md-8 col-11">
            <div class="form-group">
                <label class="form-label">{{$t("attachments")}}</label>
                <Multiselect
                    v-model="item.attachment_values"
                    :options="item.attachments"
                    :placeholder="$t('choose')"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    select-label=""
                    :hide-selected="true"
                    :multiple="true"
                >
                </Multiselect>
            </div>
        </div>
        <div class="col-1 d-flex align-items-center">
            <div class="icon_container plus" v-if="index == selected_attachments.length - 1" @click="add_attachment()">
                <i class="fa-solid fa-plus"></i>
            </div>
            <div class="icon_container trash" v-else @click="remove_attachment(index)">
                <i class="fa-solid fa-trash-can"></i>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Multiselect from "vue-multiselect";

export default {
    name: "attachmentsSec",
    props: ["fetchedAmenities"],
    emits: ["selected_attachments"],
    components: {Multiselect},
    data(){
        return{
            attachments_type: [],
            attachment_type_value: "",
            attachments: [],
            attachments_value: [],
            selected_attachments: [
                {
                    attachment_type_value: "",
                    attachments: [],
                    attachment_values: []
                }
            ]
        }
    },
    methods:{
        add_attachment(){
            this.selected_attachments.push({
                attachment_type_value: "",
                attachments: [],
                attachment_values: [],
            })
        },
        remove_attachment(index){
           this.selected_attachments.splice(index , 1);
        },
        fetch_amenity_type(){
            axios.get(`/amenity_types`).then(({data})=>{
                this.attachments_type = data.data.data;

                // if(this.$route.params.id != undefined){
                //     // slice selected item type from array
                //     this.selected_attachments.forEach((ele)=>{
                //         let type_index = this.attachments_type.indexOf(ele.attachment_type_value);
                //         this.attachments_type.splice(type_index , 1)
                //     })
                // }
            })
        },
        fetch_amenities_by_amenity_type(index){
            axios.post(`fetch_amenities_by_amenity_type_id` , {
                amenity_type_id: this.selected_attachments[index].attachment_type_value.id
            }).then(({data})=>{
                this.selected_attachments[index].attachments = data.data.data;
            })

            // slice selected item type from array
            let selected_obj = this.selected_attachments[index].attachment_type_value;
            let type_index = this.attachments_type.indexOf(selected_obj);
            this.attachments_type.splice(type_index , 1)

        }
    },
    mounted(){
        this.fetch_amenity_type();
        if(this.$route.params.id != undefined){
            this.selected_attachments = this.fetchedAmenities; 
        }
    },
    updated(){
        this.$emit("selected_attachments" , this.selected_attachments)
    }
}
</script>

<style lang="scss" scoped>
.icon_container{
    width: 46px;
    height: 46px;
    background-color: #E23535;
    border-radius: 50%;
    line-height: 46px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    &.plus{
        background-color: #0B1427;
    }
}
</style>