<template>
  <section class="card_style">
    <div class="main_header_style">
        <h5 class="mb-4">{{$t("Edit Facility")}}</h5>
    </div>

    <form @submit.prevent="editFacility()">
        <div class="row mb-5 mt-3">
            <div class="col-lg-6">
                <label>{{$t("The Name in arabic")}}</label>
                <input type="text" :placeholder="$t('enter the name')" v-model="facilityName_ar"/>
            </div>
            <div class="col-lg-6">
                <label>{{$t("The Name in english")}}</label>
                <input type="text" :placeholder="$t('enter the name')" v-model="facilityName_en"/>
            </div>
        </div>

        <button type="submit" class="edit save btn" >{{$t("edit")}}</button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from 'sweetalert2';

export default {
    data(){
        return{
            facilityId: this.$route.params.id ,
            facilityName_ar:"",
            facilityName_en:"",
        }
    },
    methods:{
        fetchFacilityData(){
            axios.get(`/features/${this.facilityId}`).then(({data})=>{
                this.facilityName_en = data.data.title_en
                this.facilityName_ar = data.data.title_ar
            }).catch((error)=>{
                console.log(error)
            })
        },
        editFacility(){
            axios.put(`/features/${this.facilityId}` , {
                title_en : this.facilityName_en ,
                title_ar : this.facilityName_ar
            }).then(({data})=>{
                Swal.fire({
                    title: "",
                    text: data.message,
                    icon: "success",
                });
                this.$router.push('/facilities')
            })
            .catch((error) => {
                Swal.fire({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                });
            });
        }
    },
    created(){
        this.fetchFacilityData();
        localStorage.removeItem("flightData");
        localStorage.removeItem("flightTrips");
        localStorage.removeItem("bookDataFlight");
        localStorage.removeItem("bookDetails");
        localStorage.removeItem("hotelData");
        localStorage.removeItem("bookData");
    }
}
</script>

<style lang="scss" scoped>
@import "../_crudStyle.scss";
</style>