import { defineStore } from "pinia";

import * as XLSX from "xlsx";

export const useExcelStore = defineStore("excel", {
  state: () => ({
    excelData: [],
  }),
  getters: {},
  actions: {
    setExcelData(data) {
      this.excelData = data;
    },
    processFile(file) {
      return new Promise((resolve, reject) => {
        if (!(file instanceof Blob)) {
          return reject(
            new TypeError("The provided file is not of type 'Blob'"),
          );
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            this.setExcelData(jsonData);
            resolve(jsonData);
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
  },
});
