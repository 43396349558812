<script setup>
import Skeleton from "primevue/skeleton";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import { ref } from "vue";
import moment from "moment";
import Datepicker from "vuejs3-datepicker";

const hotels = ref([]);
const loader = ref(false);
const startDate = ref(null);
const endDate = ref(null);

const fetchHotelsAvailability = async () => {
  try {
    loader.value = true;
    const formData = new FormData();
    formData.append("start_date", moment(startDate.value).format("YYYY-MM-DD"));
    formData.append("end_date", moment(endDate.value).format("YYYY-MM-DD"));
    const response = await axios.post("/get_hotels_upon_contract", formData);
    hotels.value = response.data.data;
  } catch (error) {
    console.log(error);
  } finally {
    loader.value = false;
  }
};
</script>

<template>
  <div class="card_style">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
      <h3 class="mb-0">{{ $t("hotel_availability") }}</h3>
    </div>
    <div class="row my-4">
      <div class="col-4">
        <label for="from">{{ $t("from") }}</label>
        <Datepicker
          v-model="startDate"
          name="from"
          :placeholder="$t('choose_date')"
        />
      </div>
      <div class="col-4">
        <label for="to">{{ $t("to") }}</label>
        <Datepicker
          v-model="endDate"
          name="to"
          :placeholder="$t('choose_date')"
        />
      </div>
      <div class="col-4 d-flex justify-content-end align-items-end">
        <button
          class="btn PrimaryButton w-100"
          @click="fetchHotelsAvailability"
        >
          {{ $t("submit") }}
        </button>
      </div>

      <hr class="mt-2" />
      <table class="table" v-if="hotels.length">
        <thead>
          <tr>
            <th>{{ $t("id") }}</th>
            <th>{{ $t("hotel_name") }}</th>
            <th>{{ $t("rooms") }}</th>
            <th>{{ $t("room_number_contract") }}</th>
            <th>{{ $t("available_rooms") }}</th>
            <!--            <th>{{ $t("tax") }}</th>-->
            <!--            <th>{{ $t("total") }}</th>-->
            <!--            <th>{{ $t("actions") }}</th>-->
          </tr>
        </thead>
        <tbody v-if="loader">
          <tr v-for="i in 10" :key="i">
            <td v-for="l in 4" :key="l" class="text-center">
              <Skeleton height="2rem" width="80%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(hotel, index) in hotels" :key="index">
            <td>
              {{ hotel.id }}
            </td>
            <td>{{ hotel.hotel_name }}</td>
            <td>
              <span
                class="badge bg-primary p-2 me-2 mb-2"
                v-for="i in hotel.offline_rooms"
                :key="i"
              >
                {{ i.room_name }}
              </span>
            </td>
            <td>{{ hotel.available_rooms }}</td>
            <td>{{ hotel.booked_rooms }}</td>
            <!--            <td>-->
            <!--              {{ getSum(hotel.hotel_bookings, "total_room_selling_with_tax") }}-->
            <!--            </td>-->
            <!--            <td>-->
            <!--              {{-->
            <!--                getSum(hotel.hotel_bookings, "total_room_selling_with_out_tax")-->
            <!--              }}-->
            <!--            </td>-->
            <!--            <td>-->
            <!--              <router-link-->
            <!--                class="btn btn-outline-info"-->
            <!--                :to="`/collected-hotel/${hotel.id}`"-->
            <!--              >-->
            <!--                <i class="fa-solid fa-file-hotel"></i>-->
            <!--              </router-link>-->
            <!--            </td>-->
          </tr>
        </tbody>
      </table>
      <div v-else class="text-center">
        <p>{{ $t("no_data_found") }}</p>
        <img src="@/assets/media/image/no-data.png" alt="" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
