<template>
  <div class="mt-2 text-center">
    <!-- <input v-model="text" placeholder="Enter text for QR code" /> -->
    <!-- <button @click="generateQrCode">Generate QR Code</button> -->
    <div v-if="qrCodeData">
      <img :src="qrCodeData" alt="QR Code" />
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode-generator";

export default {
  data() {
    return {
      // webSiteUrl: JSON.parse(localStorage.getItem("user")).website_link,
      text: `${JSON.parse(localStorage.getItem("user")).website_link}/hotel-booking/pills/${this.$route.params.id}`,
      qrCodeData: null,
    };
  },
  methods: {
    generateQrCode() {
      const typeNumber = 0;
      const errorCorrectionLevel = "L";
      const qr = QRCode(typeNumber, errorCorrectionLevel);
      qr.addData(this.text);
      qr.make();
      this.qrCodeData = qr.createDataURL(4, 0);
    },
  },
  mounted() {
    this.generateQrCode();
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
