<template>
  <setOrganizationprovider/>
</template>

<script>
import setOrganizationprovider from '../../../components/organization/setOrganizationprovider.vue'
export default {
name: "set-Organizationprovider",
components: {
  setOrganizationprovider
},
}
</script>

<style>
</style>