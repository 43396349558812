<template>
  <section class="card_style">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4>{{ $t("App Application") }}</h4>
    </div>

    <form @submit.prevent="updateAppSection()">
      <div class="row">
        <div class="col-lg-12 col-12">
          <div class="image_upload">
            <label for="imageEntry" class="upload_img" v-if="imageEntry == ''">
              <img :src="Form.image ? Form.image : defaultImage" alt="user" />
            </label>
            <img
              :src="imageEntry"
              v-else-if="imageEntry"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntry"
              class="form-control"
              @change="onFileChange"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
          <span class="input-errors" v-if="v$.imageEntry.$error">{{v$.imageEntry.$errors[0].$message}}</span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.Form.title.$error">{{v$.Form.title.$errors[0].$message}}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("describtion") }}</label>
            <div class="accordion" id="accordionExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("describtion") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_content, index) in Form.subtitle"
                      :key="index"
                    >
                      <label class="mt-3"
                        >{{ $t("describtion") }} ({{
                          blog_content.lang
                        }})</label
                      >
                      <textarea
                        v-model="blog_content.name"
                        style="height: 120px"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.Form.subtitle.$error">{{v$.Form.subtitle.$errors[0].$message}}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("google_play_url") }}</label>
            <input
              type="text"
              name=""
              id=""
              class="form-control"
              v-model="Form.google_play_url"
              :placeholder="$t('google_play_url')"
            />
          </div>
          <span class="input-errors" v-if="v$.Form.google_play_url.$error">{{v$.Form.google_play_url.$errors[0].$message}}</span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("app_store_url") }}</label>
            <input
              type="text"
              name=""
              id=""
              class="form-control"
              v-model="Form.app_store_url"
              :placeholder="$t('app_store_url')"
            />
          </div>
          <span class="input-errors" v-if="v$.Form.app_store_url.$error">{{v$.Form.app_store_url.$errors[0].$message}}</span>
        </div>
      </div>

      <button type="submit" class="add btn PrimaryButton">
        {{ $t("edit") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import useVuelidate from '@vuelidate/core';
import { required , url } from '@vuelidate/validators';

export default {
  name: "add-service",
  components: {
    AddFavDailog,
  },
  data() {
    return {
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      options: {
        placeholder: this.$t("describtion"),
        theme: "snow",
      },
      Form: {
        title: [],
        subtitle: [],
        google_play_url: "",
        app_store_url: "",
      },
      imageEntry: "",
      defaultImage: require("@/assets/media/image/user.png"),
      app_id: this.$route.params.id,
    };
  },
    validations(){
    return{
      Form:{
          title: this.Form.title.map(()=>{
            return{
              name: { required }
            }
          }),
          subtitle: this.Form.subtitle.map(()=>{
            return{
              name: { required }
            }
          }),
          google_play_url: { required , url},
          app_store_url: { required , url}
        },
        imageEntry: this.$route.params.id != undefined ? '' : {required}
    }
  },
  methods: {
    setTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          lang: language,
          name: "",
        });
        this.Form.subtitle.push({
          lang: language,
          name: "",
        });
      });
    },
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    fetchAppData() {
      axios.get(`/app_sections/${this.app_id}`).then(({ data }) => {
        this.Form.title = data.data.title.map((ele) => {
          return {
            lang: ele.locale,
            name: ele.title,
          };
        });
        this.Form.subtitle = data.data.subtitle.map((ele) => {
          return {
            lang: ele.locale,
            name: ele.subtitle,
          };
        });
        this.Form.google_play_url = data.data.google_play_url;
        this.Form.app_store_url = data.data.app_store_url;
        this.Form.image = data.data.image;
      });
    },

    updateAppSection() {
      this.v$.$validate();
      if(!this.v$.$error){
        const formData = new FormData();

        this.Form.title.forEach((ele) => {
          formData.append(`title_${ele.lang}`, ele.name);
        });
        this.Form.subtitle.forEach((ele) => {
          formData.append(`subtitle_${ele.lang}`, ele.name);
        });
        formData.append("google_play_url", this.Form.google_play_url);
        formData.append("app_store_url", this.Form.app_store_url);

        if (this.fileData instanceof File) {
          formData.append("image", this.fileData);
        } else {
          // myVariable is not a file
        }

        axios
          .post("/website/app_sections/update", formData)
          .then(({ data }) => {
            // console.log(data)
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push("/");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },
  mounted() {
    this.setTitle();
    // this.fetchAppData();
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
  textarea {
    width: 100%;
    height: 350px;
    display: block;
    padding: 20px;
  }
}
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
</style>
