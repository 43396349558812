<template>
  <div class="tab-pane fade" id="details-tab-pane" role="tabpanel" aria-labelledby="details-tab" tabindex="0">
    <div class="general_details">
        <h4 class="title">{{title}} <i class="fa-solid fa-star" v-for="(star, index) in rate" :key="index"></i></h4>
        <p class="address"><i class="fa-solid fa-map-pin"></i> {{address}}</p>
        <p class="description">{{description}}</p>

        <h4 class="title" v-if="services != null">{{$t("services")}}</h4>
        <ul class="services" v-if="services != null">
            <li v-for="(service, index) in services" :key="index"><i class="fa-solid fa-check"></i> {{ service.title }}</li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
    name: "details-hotel",
    props: {
        services: Array,
        title: String,
        address: String,
        description: String,
        rate: String
    },
}
</script>

<style lang="scss" scoped>
    @import "@/view/dashboard/hotels/_hotels.scss";
</style>