<template>
  <!-- <div v-if="loading" class="loading">
    <Loading />
  </div> -->
  <section class="hotels">
    <div class="">
      <div class="filter" v-if="hideFilter == true || loading == true">
        <div class="d-flex align-items-center gap-3">
          <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
          <h4 class="title">
            {{ $t("New hotel reservation") }}
          </h4>
        </div>
        <p class="text">
          {{
            $t(
              "Enter the following data and choose the appropriate hotel to follow the reservation process",
            )
          }}
        </p>
        <div class="selects">
          <div class="form-group nas">
            <input
              type="search"
              class="form-control"
              :placeholder="$t('select city / hotels')"
              v-model="searchValue"
              @keyup="searchHotels"
            />
            <!-- <img src="@/assets/media/image/icon/location.svg" alt="" /> -->

            <!-- searchValue.length > 2 && -->
            <div
              class="resultSearch"
              v-if="hotelsSearch.length || citiesSearch.length"
            >
              <h6 class="head_search" v-if="citiesSearch.length">
                {{ $t("The Cities") }}
              </h6>
              <ul class="cities" v-if="citiesSearch.length">
                <li
                  v-for="(city, index) in citiesSearch"
                  :key="index"
                  @click="
                    sendCityId(
                      city.title,
                      city.id,
                      city.country_id,
                      city.country_title,
                      city.country_code,
                    )
                  "
                >
                  <i class="fa-solid fa-location-dot"></i>
                  <div class="texts">
                    <p class="title">{{ city.title }}</p>
                    <p class="country">{{ city.country_title }}</p>
                  </div>
                </li>
              </ul>
              <!-- Hotels -->
              <h6 class="head_search" v-if="hotelsSearch.length">
                {{ $t("hotels") }}
              </h6>
              <ul class="hotels" v-if="hotelsSearch.length">
                <li
                  v-for="(hotel, index) in hotelsSearch"
                  :key="index"
                  @click="
                    sendHotelName(hotel.title, hotel.hotel_code, hotel.city_id)
                  "
                >
                  <img
                    :src="hotel.image == '' ? defaultImage : hotel.image"
                    class="hotel_image"
                    :alt="hotel.title"
                  />
                  <div class="texts">
                    <p class="title">{{ hotel.title }}</p>
                    <p class="country">
                      {{ hotel.country_title }} , {{ hotel.city_title }}
                    </p>
                  </div>
                </li>
              </ul>
              <!-- Hotels -->
            </div>
          </div>

          <div class="form-group ad">
            <Multiselect
              v-model="nationalityValue"
              :options="nationalities"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose_nationality')"
              select-label=""
              :hide-selected="true"
              @keyup="searchNationality"
            >
            </Multiselect>
            <img src="@/assets/media/image/icon/natss.svg" alt="" />
          </div>
          <!-- <div class="form-group">
            <Datepicker range v-model="selectedDate" lang="ar" />
          </div> -->

          <div class="form-group nas">
            <div class="card flex justify-content-center">
              <Calendar
                v-model="selectedDate"
                selectionMode="range"
                :manualInput="true"
                showIcon
              />
              <!-- :numberOfMonths="2" -->
            </div>

            <img src="@/assets/media/image/icon/calendar.svg" alt="" />
          </div>

          <div class="form-group nas">
            <div class="dropdown">
              <button
                type="button"
                class="btn btn-primary dropdown-toggle hh"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                {{ $t("rooms") }} {{ rooms }} - {{ $t("Adults") }}
                {{ adults }} - {{ $t("childrens") }} {{ childrens }}
              </button>
              <div class="dropdown-menu">
                <div class="list">
                  <span>{{ $t("Adults") }}</span>
                  <div class="number">
                    <button class="btn minus" @click="minusQuantityAdults()">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <!-- <input type="text" class="count form-control" v-model=""> -->
                    <p class="count">{{ adults }}</p>
                    <button class="btn add" @click="addQuantityAdults()">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="list">
                  <span>{{ $t("childrens") }}</span>
                  <div class="number">
                    <button class="btn minus" @click="minusQuantityChildrens()">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <!-- <input type="text" class="count form-control" v-model=""> -->
                    <p class="count">{{ childrens }}</p>
                    <button class="btn add" @click="addQuantityChildrens()">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="list">
                  <span>{{ $t("rooms") }}</span>
                  <div class="number">
                    <button class="btn minus" @click="minusQuantityRooms()">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <!-- <input type="text" class="count form-control" v-model=""> -->
                    <p class="count">{{ rooms }}</p>
                    <button class="btn add" @click="addQuantityRooms()">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <img src="@/assets/media/image/icon/pod.svg" alt="" />
          </div>
        </div>
        <button
          class="btn PrimaryButton"
          v-if="searchValue && nationalityValue && selectedDate"
          @click="FetchHotels()"
        >
          {{ $t("search_now") }}
        </button>
      </div>
      <div
        class="emptySection"
        v-if="showSearchResult == false && loading == false"
      >
        <div class="empty">
          <h4>خطوات بسيطة لحجز فندقك</h4>
        </div>
        <div class="imge-ph">
          <img src="@/assets/media/image/empty.png" alt="" />
        </div>
        <div class="loty">
          <lottie-player
            :src="lottiePlayer"
            background="transparent"
            speed="1"
            loop
            autoplay
            style="width: 300px; height: 300px"
          ></lottie-player>
        </div>
      </div>
      <!-- <h3 class="not_found">{{$t("not_found_data")}}</h3> -->

      <!-- start search result -->
      <div
        v-if="showSearchResult == true"
        class="search_ruslt mt-4 d-flex align-items-center justify-content-between"
      >
        <h3 class="d-flex align-items-center">
          {{ $t("search_result") }} (
          <p class="mb-0">{{ searchValue }}</p>
          ,
          <p class="mb-0">{{ localDiffernce }} {{ $t("nights") }}</p>
          ,
          <p class="mb-0">{{ rooms }}{{ $t("rooms") }}</p>
          ,
          <p class="mb-0">{{ adults }}{{ $t("Adults") }}</p>
          ,
          <p class="mb-0">{{ childrens }}{{ $t("childrens") }}</p>
          )
        </h3>
        <div class="buttons">
          <button
            class="btn btn-sm btn-danger"
            type="button"
            @click="removeSearch"
          >
            <i class="fa-solid fa-filter-circle-xmark"></i>
          </button>
          <button
            v-if="hideFilter == false"
            class="btn btn-sm btn-outline-warning"
            type="button"
            @click="showSearch"
          >
            {{ $t("edit_search_result") }}
          </button>
        </div>
      </div>
      <!-- finish search result -->

      <!-- start filter_sec -->
      <!--  -->
      <div class="row filter_sec py-3 mt-4" v-if="showSearchResult == true">
        <div class="col-lg-5 col-md-12 col-12">
          <div class="options">
            <form>
              <i class="fa-solid fa-magnifying-glass"></i>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('search by name')"
                v-model="hotelName"
                @keyup="FetchHotels"
              />
            </form>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-12">
          <Multiselect
            v-model="order_price"
            :options="prices"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('lower_price')"
            select-label=""
            :hide-selected="true"
            @update:model-value="FetchHotels"
          >
          </Multiselect>
        </div>
        <div class="col-lg-3 col-md-4 col-12">
          <Multiselect
            v-model="order_rate"
            :options="rates"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('lower_review')"
            select-label=""
            :hide-selected="true"
            @update:model-value="FetchHotels"
          >
          </Multiselect>
        </div>
        <div class="col-lg-1 col-md-4 col-12">
          <button @click="openNewFilter()" class="btn filter_icon">
            <i class="fa-solid fa-filter"></i>
          </button>
        </div>
      </div>
      <!-- finish filter_sec -->

      <!-- start filter_third -->
      <!--  -->
      <div
        class="row filter_third mt-4 align-items-center"
        v-if="showFilterResult == true"
      >
        <div class="col-lg-3 col-md-6 col-12">
          <Multiselect
            v-model="rate"
            :options="reviews"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('star_number')"
            select-label=""
            :hide-selected="true"
            @update:model-value="FetchHotels"
          >
          </Multiselect>
        </div>
        <div class="col-lg-3 col-md-6 col-12"></div>
        <div class="col-lg-4 col-md-4 col-12">
          <h6>{{ $t("price") }}</h6>
          <div class="mt-3">
            <vue-slider
              v-model="priceValue"
              :min="minValue"
              :max="maxValue"
              :step="stepValue"
              :lazy="true"
              direction="rtl"
              :tooltip="'always'"
              :tooltip-placement="['top', 'bottom']"
            ></vue-slider>
          </div>
        </div>
        <div class="col-lg-2 col-md-4 col-12">
          <button @click="logFilter()" class="btn PrimaryButton">
            {{ $t("application") }}
          </button>
        </div>
      </div>
      <!-- finish filter_third -->

      <div class="card px-5" v-if="loading">
        <div class="d-flex w-100 my-3" v-for="i in 4" :key="i">
          <ul class="m-0 p-0 list-none w-100">
            <li class="mb-3 border p-2">
              <div class="d-flex">
                <Skeleton size="8rem" class="mr-2"></Skeleton>
                <div class="align-self-center mx-3" style="flex: 1">
                  <Skeleton width="75%" class="mb-2"></Skeleton>
                  <Skeleton width="50%"></Skeleton>
                </div>
                <Skeleton width="10%"></Skeleton>
              </div>
              <div class="w-100 d-flex justify-content-end align-items-center">
                <Skeleton width="10rem" height="2rem"></Skeleton>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="row" v-if="!loading">
        <hotelCard
          v-for="(hotel, index) in fetchedHotels"
          :hotel="hotel"
          :lat="hotel.lat"
          :long="hotel.long"
          :adults="adults"
          :range="selectedDate"
          :childrens="childrens"
          :hotel_code="hotel.id"
          :hotel_name="hotel.name"
          :guest_nationality="nationalityValue"
          :resultIndex="null"
          :session_id="null"
          :credential_id="2"
          :provider_id="2"
          :show_price="show_price"
          :show_loader="show_loader"
          :index="index"
          :priceFetched="priceFetched"
          :key="index"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Skeleton from "primevue/skeleton";
//import Slider from "@vueform/slider";
// import "jquery/dist/jquery.min.js";
// import $ from "jquery";
import "t-datepicker";
import axios from "axios";
import Multiselect from "vue-multiselect";
// import "vue-datepicker-ui/lib/vuedatepickerui.css";
// import VueDatepickerUi from "vue-datepicker-ui";
import moment from "moment";
import hotelCard from "@/components/hotels/hotelCard.vue";
import Swal from "sweetalert2";
// import Loading from "@/components/LottieFiles/HotelSearchLoading.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Calendar from "primevue/calendar";
import "primevue/resources/themes/lara-light-teal/theme.css";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "hotels-index",
  data() {
    return {
      checkOutDate: null,
      dates: null,
      hotels: {
        data: [],
      },
      lottiePlayer: require("@/assets/media/hotel_loader.json"),
      countries: [],
      countryValue: "",
      defaultImage: require("@/assets/media/image/placeholder.png"), // Replace with your default image URL
      // searchValue: JSON.parse(localStorage.getItem("bookDetails")) == null ? this.searchValue : JSON.parse(localStorage.getItem("bookDetails")).hotel_name == null || JSON.parse(localStorage.getItem("bookDetails")).hotel_name == "" ? JSON.parse(localStorage.getItem("bookDetails")).city_title : JSON.parse(localStorage.getItem("bookDetails")).hotel_name,
      // hotelName: JSON.parse(localStorage.getItem("bookDetails")) == null ? this.hotelName : JSON.parse(localStorage.getItem("bookDetails")).hotel_name == null ? null : JSON.parse(localStorage.getItem("bookDetails")).hotel_name,
      // cityTitle: JSON.parse(localStorage.getItem("bookDetails")) == null ? this.searchValue : JSON.parse(localStorage.getItem("bookDetails")).city_title == null ? null : JSON.parse(localStorage.getItem("bookDetails")).city_title,
      hotelName: "",
      // hotelName:  JSON.parse(localStorage.getItem("bookDetails")) != null ? JSON.parse(localStorage.getItem("bookDetails")).hotel_name : "",
      cityTitle:
        JSON.parse(localStorage.getItem("bookDetails")) != null
          ? JSON.parse(localStorage.getItem("bookDetails")).city_title
          : "",
      searchValue:
        JSON.parse(localStorage.getItem("bookDetails")) == null
          ? this.searchValue
          : JSON.parse(localStorage.getItem("bookDetails")).city_title !=
                null ||
              JSON.parse(localStorage.getItem("bookDetails")).city_title != ""
            ? JSON.parse(localStorage.getItem("bookDetails")).city_title
            : JSON.parse(localStorage.getItem("bookDetails")).hotel_name,
      cityValue: [],
      nationalities: [],
      nationalityValue:
        JSON.parse(localStorage.getItem("bookDetails")) == null
          ? this.nationalityValue
          : JSON.parse(localStorage.getItem("bookDetails")).guest_nationality ==
              null
            ? null
            : JSON.parse(localStorage.getItem("bookDetails")).guest_nationality,
      hotelsSearch: [],
      citiesSearch: [],
      hotelValue: "",
      selectedDate: new Date(),
      adults: 1,
      childrens: 0,
      rooms: 1,
      loading: false,
      showSearchResult: false,
      checkInDate: null,
      parsedData: null,
      childrenLocal: null,
      adultsLocal: null,
      roomLocal: null,
      checkinLocal: null,
      checkoutLocal: null,
      localDiffernce: "",
      prices: [
        {
          id: 1,
          title: this.$t("lower_price"),
        },
        {
          id: 2,
          title: this.$t("high_price"),
        },
      ],
      order_price:
        JSON.parse(localStorage.getItem("bookDetails")) == null
          ? this.order_price
          : JSON.parse(localStorage.getItem("bookDetails")).order_price == null
            ? null
            : JSON.parse(localStorage.getItem("bookDetails")).order_price,
      order_rate:
        JSON.parse(localStorage.getItem("bookDetails")) == null
          ? this.order_rate
          : JSON.parse(localStorage.getItem("bookDetails")).order_rate == null
            ? null
            : JSON.parse(localStorage.getItem("bookDetails")).order_rate,
      rates: [
        {
          id: 1,
          title: this.$t("lower_review"),
        },
        {
          id: 2,
          title: this.$t("high_review"),
        },
      ],
      reviews: [
        {
          id: 1,
          title: 1,
        },
        {
          id: 2,
          title: 2,
        },
        {
          id: 3,
          title: 3,
        },
        {
          id: 4,
          title: 4,
        },
        {
          id: 5,
          title: 5,
        },
      ],
      session_id: "",
      hasResults: false,
      priceValue: [0, 500], // Initial range values
      minValue: 0, // Minimum value
      maxValue: 5000, // Maximum value
      stepValue: 100, // Step increment
      showFilterResult: false,
      rate: "",
      hideFilter: true,
      searchData: false,
      fetchedHotels: [],
      copy_hotels: [],
      show_loader: true,
      priceFetched: false,
      show_price: false,
      data_filtered: false,
      countryTitle: "",
      countryCode: "",
      hotelList: [],
      hotel_with_min_price: [],
      providers: [
        { is_contract: 1 },
        { is_contract: 0, provider_id: 2, credential_id: 2 },
      ],
    };
  },
  components: {
    Multiselect,
    hotelCard,
    // Loading,
    Skeleton,
    VueSlider,
    Calendar,
    AddFavDailog,
  },
  methods: {
    //Search nationality
    searchNationality(event) {
      // console.log(event)
      const searchText = {
        word: event == undefined ? "" : event.target.value,
      };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.nationalities);
    },

    //Search Hotels
    searchHotels(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_new_home_hotels`, searchText)
        .then(
          (res) => (
            (this.hotelsSearch = res.data.data.hotels),
            (this.citiesSearch = res.data.data.cities)
          ),
        )
        .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.hotelsSearch)
      // console.log(this.citiesSearch)
    },

    // fetch hotels
    sendHotelName(hotelName, hotelID, cityId) {
      this.hotelName = hotelName;
      this.hotelID = hotelID;
      this.cityTitle = null;
      this.searchValue = hotelName;
      this.cityId = cityId;
      this.citiesSearch.length = [];
      this.hotelsSearch = [];
      // console.log(this.hotelsSearch, "hotels");
    },
    sendCityId(cityTitle, cityId, countryId, countryTitle, countryCode) {
      this.cityId = cityId;
      this.cityTitle = cityTitle;
      this.countryId = countryId;
      this.countryTitle = countryTitle;
      // console.log(this.countryTitle, "country");
      this.countryCode = countryCode;
      // console.log(this.countryCode, "countryCode");
      this.hotelName = null;
      this.citiesSearch = [];
      this.hotelsSearch = [];
    },
    logFilter() {
      const filterFinalResults = {
        from_price: this.priceValue[0],
        to_price: this.priceValue[1],
      };
      this.FetchHotels(filterFinalResults);
    },

    openNewFilter() {
      this.showFilterResult = !this.showFilterResult;
    },
    showSearch() {
      this.hideFilter = true;
      this.showSearchResult = false;
      this.searchData = true;
      this.hotels.data = [];
    },
    filter_hotels() {
      this.data_filtered = true;
      // console.log(this.fetchedHotels, "fetch");
      // console.log(this.copy_hotels, "copy");
      this.copy_hotels = [...this.fetchedHotels];

      // hotel name search
      if (this.hotel_name != "") {
        this.copy_hotels = this.copy_hotels.filter((ele) => {
          return (
            ele.title.toUpperCase().indexOf(this.hotel_name.toUpperCase()) > -1
          );
        });
      }

      // star count
      if (this.selectedStars != "") {
        this.copy_hotels = this.copy_hotels.filter((ele) => {
          return ele.starCount == this.selectedStars;
        });
      }

      if (this.order_rate == true) {
        for (let i = 0; i < this.copy_hotels.length; i++) {
          let lowest = i;
          for (let j = i + 1; j < this.copy_hotels.length; j++) {
            if (
              +this.copy_hotels[j].rating > +this.copy_hotels[lowest].rating
            ) {
              lowest = j;
            }
          }

          if (lowest !== i) {
            // Swap
            [this.copy_hotels[i], this.copy_hotels[lowest]] = [
              this.copy_hotels[lowest],
              this.copy_hotels[i],
            ];
          }
        }
      }

      // // lowest price and heighest rate
      // if(this.order_price == true && this.order_rate == true){
      //   console.log("two")
      //   for (let i = 0; i < this.copy_hotels.length; i++) {
      //     let lowest = i;
      //     for (let j = i + 1; j < this.copy_hotels.length; j++) {
      //       if (+this.copy_hotels[j].min_price < +this.copy_hotels[lowest].min_price && +this.copy_hotels[j].rating > +this.copy_hotels[lowest].rating) {
      //         lowest = j
      //       }
      //     }

      //     if (lowest !== i) {
      //       // Swap
      //       [this.copy_hotels[i], this.copy_hotels[lowest]] = [this.copy_hotels[lowest], this.copy_hotels[i]]
      //     }
      //   }
      //   // this.fetchedHotels.sort((a, b) => b.rating - a.rating && a.min_price - b.min_price);
      // }
      // else if(this.order_price == true){
      //   // lowest price search
      //   console.log(this.order_price)
      //   for (let i = 0; i < this.copy_hotels.length; i++) {
      //     let lowest = i;
      //     for (let j = i + 1; j < this.copy_hotels.length; j++) {
      //       if (+this.copy_hotels[j].min_price < +this.copy_hotels[lowest].min_price) {
      //         lowest = j
      //       }
      //     }

      //     if (lowest !== i) {
      //       // Swap
      //       [this.copy_hotels[i], this.copy_hotels[lowest]] = [this.copy_hotels[lowest], this.copy_hotels[i]]
      //     }
      //   }
      // }
      // else if(this.order_rate == true){
      //   // heighest rating
      //   for (let i = 0; i < this.copy_hotels.length; i++) {
      //     let lowest = i;
      //     for (let j = i + 1; j < this.copy_hotels.length; j++) {
      //       if (+this.copy_hotels[j].rating > +this.copy_hotels[lowest].rating) {
      //         lowest = j
      //       }
      //     }

      //     if (lowest !== i) {
      //       // Swap
      //       [this.copy_hotels[i], this.copy_hotels[lowest]] = [this.copy_hotels[lowest], this.copy_hotels[i]]
      //     }
      //   }
      // }

      // // price range
      // this.copy_hotels = this.fetchedHotels.filter((ele)=>{
      //   return ele.min_price > this.priceValue[0] && ele.min_price < this.priceValue[1]
      // });
    },
    async FetchHotels(passNewFilter = {}) {
      this.loading = true; //the loading begin
      this.hideFilter = false;

      const filterWebPeds = {
        city_id: this.cityId,
        city_name: this.cityTitle,
        country_code: this.countryCode,
        country_name: this.countryTitle,
        // hotel_name: this.hotelName,
        // hotel_code: this.hotelID,
        nationality_name: this.nationalityValue.title,
        nationality_id: this.nationalityValue.id,
        nationality_code: this.nationalityValue.code,
        passenger_country_of_residence_code: this.nationalityValue.code,
        no_of_rooms: this.rooms,
        converter_status: 1,
        test: "test",
        check_in: moment(this.selectedDate[0]).format("YYYY-MM-DD"),
        check_out: moment(this.selectedDate[1]).format("YYYY-MM-DD"),
        rooms_guests: [
          {
            rate_basis: "1",
            adults_count: this.adults,
            children_count: this.childrens,
            children_ages: Array.from(
              {
                length: this.hotelData == null ? 0 : this.hotelData?.childrens,
              },
              () => 7,
            ),
          },
        ],
        // meta_data: {
        //   credential_id: 2,
        //   provider_id: 2,
        // },
      };
      // console.log(filterWebPeds);
      localStorage.setItem("bookDetails", JSON.stringify(filterWebPeds));
      const filter = {
        check_in_date: moment(this.selectedDate[0]).format("YYYY-MM-DD"),
        check_out_date: moment(this.selectedDate[1]).format("YYYY-MM-DD"),
        city_id: this.cityId,
        city_title: this.cityTitle,
        country_code: this.country_code,
        country_name: this.country_title,
        hotel_name: this.hotelName,
        hotel_code: this.hotelID,
        is_near_by_search_allowed: false,
        no_of_rooms: this.rooms,
        adults: this.adults,
        childrens: this.childrens,
        order_price: this.order_price,
        order_rate: this.order_rate,
        rate: this.rate,
        ...(passNewFilter && {
          from_price: passNewFilter.from_price,
          to_price: passNewFilter.to_price,
        }),
      };
      if (typeof this.nationalityValue == "object") {
        filter.guest_nationality = this.nationalityValue.code;
      }

      // localStorage.setItem("bookDetails", JSON.stringify(filter));

      await axios
        .post("/fetch_local_hotels", filterWebPeds)
        .then((response) => {
          const data = response.data;
          // console.log(data)
          if (data.status == true) {
            this.loading = false; //the loading ended
            // Show the search result div after loading data
            this.showSearchResult = true;
            this.searchData = true;
            this.fetchedHotels = data.data;
            this.hotelsData = data.data;
            this.session_id = "";

            this.providers.forEach((element) => {
              if (element.is_contract == 1) {
                filterWebPeds.is_contract = 1;
                filterWebPeds.converter_status = 0;
                filterWebPeds.meta_data = {
                  is_contract: 1,
                  provider_id: 2,
                  credential_id: 2,
                };
                this.fetch_min_price_hotels(filterWebPeds);
              } else {
                filterWebPeds.is_contract = 0;
                filterWebPeds.converter_status = 1;
                filterWebPeds.meta_data = {
                  provider_id: 2,
                  credential_id: 2,
                  is_contract: 0,
                };
                this.fetch_min_price_hotels(filterWebPeds);
              }
            });
            // console.log(this.providers);
          } else {
            Swal.fire({
              title: "",
              text: data.message || "Status false Error Fetch Local",
              icon: "error",
            });
            // console.log("false")
            this.loading = false;
            this.hideFilter = true;
          }
        })
        .catch(({ error }) => {
          Swal.fire({
            title: "",
            text: error?.response?.data?.message || "Catch Error Fetch Local",
            icon: "error",
          });
          // console.log("error")
          this.loading = false; //the loading ended
          this.hideFilter = true;
        });
    },

    async fetch_min_price_hotels(fetched_hotels) {
      await axios
        .post("/fetch_hotel_with_min_price", fetched_hotels)
        .then((response) => {
          const data = response.data;
          if (data.status == true) {
            this.loading = false; //the loading ended
            this.hotel_with_min_price = data.data.data;
            // this.showHotels = [];
            this.hotel_with_min_price.map((hotel) => {
              this.priceFetched = true;
              let index = this.fetchedHotels.findIndex(
                (element) => element.title_en == hotel.title,
              );
              // console.log(index);
              if (index > -1) {
                if (hotel.min_price > 0) {
                  this.fetchedHotels[index].price_exist = true;
                  let usedHotel = hotel;
                  usedHotel.index =
                    (this.fetchedHotels.prices ?? []).length + 1;
                  (this.fetchedHotels[index] ?? []).prices.push(usedHotel);
                  let hotleListIndex = this.hotelList.findIndex(
                    (element) => element.title_en == hotel.title_en,
                  );
                  if (hotleListIndex == -1) {
                    this.hotelList.push(hotel.title);
                  }
                }
              }
            });
            this.show_price = true;
            this.show_loader = false;

            //console.log(this.fetchedHotels);
          } else {
            Swal.fire({
              title: "",
              text: data.message || "Status false Error Fetch Min price",
              icon: "error",
            });
            this.loading = false;
            this.hideFilter = true;
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error?.data?.data?.message,
            icon: "error",
          });
          // console.log("error")
          this.loading = false; //the loading ended
          this.hideFilter = true;
        });
    },
    removeSearch() {
      localStorage.removeItem("bookDetails");
      location.reload();
    },

    // Adults Minus && Add
    minusQuantityAdults() {
      if (this.adults > 1) {
        this.adults -= 1;
      }
    },
    addQuantityAdults() {
      this.adults += 1;
    },

    // childrens Minus && Add
    minusQuantityChildrens() {
      if (this.childrens > 0) {
        this.childrens -= 1;
      }
    },
    addQuantityChildrens() {
      this.childrens += 1;
    },

    // Rooms Minus && Add
    minusQuantityRooms() {
      if (this.rooms > 1) {
        this.rooms -= 1;
      }
    },
    addQuantityRooms() {
      this.rooms += 1;
    },
    // Get the data from localStorage
    retrieveBookDetailsFromLocalStorage() {
      const storedData = JSON.parse(localStorage.getItem("bookDetails"));
      if (
        storedData &&
        // storedData.childrens &&
        // storedData.adults &&
        // storedData.no_of_rooms &&
        storedData.check_in_date &&
        storedData.check_out_date
      ) {
        // Assign the value to the data property
        // this.childrenLocal = storedData.childrens;
        // this.adultsLocal = storedData.adults;
        // this.roomLocal = storedData.no_of_rooms;
        this.checkinLocal = storedData.check_in_date;
        this.checkoutLocal = storedData.check_out_date;
        // Parse the date strings into Date objects
        const checkinDate = new Date(this.checkinLocal);
        const checkoutDate = new Date(this.checkoutLocal);
        const timeDifferenceMs = checkoutDate - checkinDate;
        const timeDifferenceDays = Math.ceil(
          timeDifferenceMs / (1000 * 60 * 60 * 24),
        );
        this.localDiffernce = timeDifferenceDays;
        this.hasResults = true;
      } else {
        // Handle the case where the value is not found or undefined
        // console.log("childrens not found in localStorage");
        this.hasResults = false;
        // You can assign a default value here if needed
        // this.childrenLocal = childrenLocal;
        // this.adultsLocal = adultsLocal;
        // this.roomLocal = roomLocal;
      }
    },
  },
  // created() {
  //   // localStorage.removeItem("bookDetails");
  //   if (localStorage.getItem("bookDetails") != null) {
  //     const bookDetails = JSON.parse(localStorage.getItem("bookDetails"));
  //     this.selectedDate = [
  //       bookDetails.check_in_date,
  //       bookDetails.check_out_date,
  //     ];
  //     this.cityId = bookDetails.city_id;
  //     this.cityTitle = bookDetails.city_title;
  //     this.hotelName = bookDetails.hotel_name;
  //     this.hotelID = bookDetails.hotel_code;
  //     this.rooms = bookDetails.no_of_rooms;
  //     this.adults = bookDetails.adults;
  //     this.childrens = bookDetails.childrens;
  //     this.nationalityValue = {};

  //     // this.FetchHotels();
  //   }
  //   localStorage.removeItem("bookData");
  //   localStorage.removeItem("sessionData");
  //   this.retrieveBookDetailsFromLocalStorage();
  //   this.searchNationality();
  // },
  // mounted() {
  //   console.log($.fn.jquery); // Check jQuery version
  //   console.log($.fn.tdatepicker); // Check if tDatePicker function is defined
  // },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_hotels.scss";
.loading {
  background-color: #fff;
}
.filter_icon {
  background-color: $PrimaryColor;
  padding: 0.55rem 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: 0.3s all ease-in-out;
  border: 1px solid $PrimaryColor;
  cursor: pointer;
  &:hover {
    background-color: white;
    i {
      color: $PrimaryColor;
    }
  }

  i {
    color: white;
    font-size: 1.2rem;
    transition: 0.3s all ease-in-out;
  }
}
.options form .form-control {
  padding: 7px 40px;
  border: 1px solid #e8e8e8;
  padding-inline-start: 3rem !important;
}
.options form i {
  right: 1rem;
  color: #b1b1b1;
  font-size: 1rem;
}
.hotels .multiselect .multiselect__tags {
  border: unset !important;
}
.vue-slider-process {
  background-color: $PrimaryColor !important;
}
.PrimaryButton {
  width: 84%;
}
.not_found {
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
