<template>
  <div
    class="offcanvas offcanvas-bottom"
    tabindex="-1"
    id="offcanvasBottomClient"
    aria-labelledby="offcanvasBottomLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasBottomLabel">
        {{ $t("add_clients") }}
      </h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body small">
      <form @submit.prevent="saveClient()">
        <div class="row">
          <div class="col-lg-6 my-3">
            <label for="name">{{ $t("client_name") }}</label>
            <input type="text" id="name" v-model="name" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 my-3">
            <label for="email">{{ $t("client_email") }}</label>
            <input type="email" id="email" v-model="email" />
          </div>
          <div class="col-lg-3 my-3">
            <label for="phone">{{ $t("phone_number") }}</label>
            <input type="number" id="phone" v-model="phone" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 my-3">
            <label for="country">{{ $t("client_country") }}</label>
            <Multiselect
              v-model="country_id"
              :options="countries.map((country) => country.id)"
              :custom-label="(opt) => countries.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
          <div class="col-lg-3 my-3">
            <label for="address">{{ $t("address") }}</label>
            <input type="text" id="address" v-model="address" />
          </div>
        </div>
        <div class="row">
          <h5>{{$t("client type")}}</h5>
          <div class="col-lg-3 my-3">
            <div class="form-group d-inline-block">
              <input type="radio" id="member" name="type1" value="1" v-model="client_type"/>
              <label for="member">{{$t("member")}}</label>
            </div>
            <div class="form-group d-inline-block mx-3">
              <input type="radio" id="company" name="type2" value="2" v-model="client_type"/>
              <label for="company">{{$t("company")}}</label>
            </div>
          </div>
        </div>
        <button type="submit" class="add save btn mt-5">
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

export default {
  name: "addClient",
  emits: ["fetchClients"],
  components: { Multiselect },
  data() {
    return {
      countries: [],
      country_id: "",
      email: "",
      phone: "",
      address: "",
      name: "",
      role: 1,
      client_type:""
    };
  },
  methods: {
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    saveClient() {
      axios
        .post("/consumers", {
          name: this.name,
          email: this.email,
          phone: this.phone,
          address: this.address,
          country_id: this.country_id,
          role: this.role,
          client_type: this.client_type
        })
        .then(({ data }) => {
          // console.log(data)
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$emit("fetchClients");
          // reset data
          (this.country_id = ""),
            (this.email = ""),
            (this.phone = ""),
            (this.address = ""),
            (this.name = "");
            (this.role = "");
            (this.client_type = "");
          // finish reset data
         
    
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetch_country();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "../../view/dashboard/wallet/_wallet.scss";
label {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}
input[type="radio"]{
  width: auto !important;
  margin-inline-end: 10px;
}
</style>
