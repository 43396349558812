<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("edit_airplane") }}</h4>
    </div>
    <form @submit.prevent="editAirports()">
      <div class="row">
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The Country") }}</label>
            <Multiselect
              v-model="countryValue"
              track-by="id"
              label="title"
              :options="countries"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
              @update:model-value="FetchCityFromCountry(this.countryValue)"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The City") }}</label>

            <Multiselect
              v-model="cityValues"
              track-by="id"
              label="title"
              :options="cities"
              :clear-on-select="true"
              :placeholder="$t('enter_city')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
      </div>
      <!-- <div class="row" >
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The Country") }}</label>
            <Multiselect
              v-model="regularCountryValue"
              :options="countries.map((country) => country.id)"
              :custom-label="(opt) => countries.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
              @update:model-value="FetchCityFromCountry"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The City") }}</label>
            <Multiselect
              v-model="regularCityValues"
              :options="cities.map((city) => city.id)"
              :custom-label="(opt) => cities.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('enter_city')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("write_name") }} (EN)</label>
            <input
              type="text"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('write_name')"
              v-model="title_en"
            />
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("write_name") }} (AR)</label>
            <input
              type="text"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('write_name')"
              v-model="title_ar"
            />
          </div>
        </div>
        <div class="col-md-12 mb-4">
          <div class="form-group">
            <label for="adress">{{ $t("code") }}</label>
            <input
              type="text"
              class="form-control mt-2"
              id="code"
              :placeholder="$t('code')"
              v-model="code"
            />
          </div>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("edit") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  name: "editAirports",
  components: {
    Multiselect,
  },
  data() {
    return {
      title_en: "",
      title_ar: "",
      code: "",
      countryValue: "",
      regularCountryValue: "", // For the regular country selection
      countries: [],
      cityValues: [],
      regularCityValues: [], // For the regular city selection
      cities: [],
      country_id: "",
      city_id: "",
      useCustomCitySelect: false,
    };
  },
  methods: {
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    FetchCityFromCountry(value) {
      let basic_country_ids = {
        country_id: [value.id],
      };
      axios
        .post("/fetch_cities_by_country_id", basic_country_ids)
        .then(({ data }) => {
          this.cities = data.data;
        });
    },

    fetchAirport() {
      let id = this.$route.params.id;
      axios.get("/airports/" + id).then(({ data }) => {
        this.title_en = data.data.title_en;
        this.title_ar = data.data.title_ar;
        this.code = data.data.code;
        this.countryValue = data.data.country;
        this.cityValues = data.data.city;
        this.regularCityValues = data.data.city; // For regular city selection
        // console.log(data.data);
      });
    },

    async editAirports() {
      let id = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("title_en", this.title_en);
      params.append("title_ar", this.title_ar);
      params.append("code", this.code);
      params.append("country_id", this.countryValue.id);
      params.append("city_id", this.cityValues.id);
      axios
        .put(`/airports/${id}`, params)

        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/airport");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetchAirport();
    this.fetch_country();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style></style>
