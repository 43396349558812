<template>
  <section class="show_receipt card_style">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4>
        {{ $t("services") }}
      </h4>
    </div>
    <div class="main_header_style services_dashboard mb-2">
      <input
        type="text"
        v-model="serviceText"
        class="form-control w-50"
        @keyup="FetchServices((page = 1))"
        :placeholder="$t('search...')"
      />
      <router-link
        :to="{ name: 'servicesDashboardAdd' }"
        type="button"
        v-if="canAdd"
        class="PrimaryButton btn"
        ><i class="fa-solid fa-plus"></i>
        {{ $t("add_new_service") }}</router-link
      >
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table services_dashboard" id="revenueTable">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("title") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 3" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in services.data" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.title }}</td>
              <td>
                <!-- v-if="canEdit" -->
                <router-link
                  class="btn btn-success mx-1 w-auto"
                  :to="`/services_dashboard/edit/${item.id}`"
                  :title="$t('edit')"
                >
                  <i class="fa-solid fa-pen"></i>
                </router-link>

                <!-- v-if="canDelete" -->
                <button
                  type="button"
                  :title="$t('delete')"
                  class="btn btn-danger mx-1 w-auto"
                  @click="DeleteAlert(item.id, index)"
                >
                  <i class="fa-solid fa-trash" :title="$t('delete')"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="services"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchServices"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import { usePermissionStore } from "@/store/modules/permissions/index"; // Adjust the path accordingly
import { mapActions, mapState } from "pinia";
import Skeleton from "primevue/skeleton";
export default {
  name: "show-services",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      services: [],
      serviceText: "",
      loading: true,
    };
  },
  computed: {
    ...mapState(usePermissionStore, ["canEdit", "canDelete", "canAdd"]),
  },
  methods: {
    ...mapActions(usePermissionStore, ["setUserRole"]),
    FetchServices(page = 1) {
      axios
        .post(`/fetch_additional_services?page=${page}`, {
          word: this.serviceText,
        })
        .then(({ data }) => {
          this.services = data.data;
          this.loading = false;
          // console.log(this.revenue);
        });
    },
    //delete revenue
    DeleteAlert(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.services[index].id;
          const fromClick = true;
          axios.delete("/additional_services/" + `${id}`, { fromClick }).then(({ data }) => {
            // console.log(result);
            // console.log(delete_id);
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.services.data.splice(index, 1); // Remove the deleted item from the array
              // console.log(data);
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.FetchServices();
    this.setUserRole("employee");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_services.scss";
</style>
