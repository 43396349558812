<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <div>
        <router-link class="PrimaryButton btn" to="#">{{
          $t("operation")
        }}</router-link>
        <router-link class="PrimaryButton btn" to="#">{{
          $t("suppliers_payment")
        }}</router-link>
        <router-link class="PrimaryButton btn" to="#">{{
          $t("clinet_payment")
        }}</router-link>
        <router-link :to="{ name: 'paymentAdd' }" class="PrimaryButton btn"
          ><i class="fa-solid fa-plus"></i> {{ $t("new_payment") }}</router-link
        >
      </div>
    </div>

    <div class="row options mb-1">
      <div class="col-1">
        <h4>{{ $t("payment_table") }}</h4>
      </div>
      <form class="col-lg-5 col-12">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input type="text" :placeholder="$t('search by name')" />
      </form>
      <!-- start input group -->
      <div class="col-lg-2 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="statusValue"
            :options="statuses.map((status) => status.id)"
            :custom-label="(opt) => statuses.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->
      <div class="col-lg-4 col-md-6 col-12">
        <div class="form-group">
          <Datepicker range v-model="selectedDate" lang="ar" />
        </div>
      </div>
      <!-- start filter -->
      <div class="col-lg-1 col-md-6 col-12 d-flex justify-content-end">
        <button class="filter_btn btn" @click="showFilterRow()">
          <i class="fa-solid fa-filter"></i>
        </button>
      </div>
      <!-- finish filter -->
    </div>
    <div class="row show_filter" v-if="showFilter">
      <!-- start input group -->
      <div class="col-lg-4 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="paymentValue"
            :options="payments.map((payment) => payment.id)"
            :custom-label="(opt) => payments.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('payment_type_choose')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="col-lg-4 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="safeValue"
            :options="safes.map((safe) => safe.id)"
            :custom-label="(opt) => safes.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('safe_choose')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="col-lg-4 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="bankValue"
            :options="banks.map((bank) => bank.id)"
            :custom-label="(opt) => banks.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('bank_choose')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="col-lg-4 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="clientValue"
            :options="clientss.map((client) => client.id)"
            :custom-label="(opt) => clientss.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('client_choose')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="col-lg-4 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="benfitesValue"
            :options="benfites.map((benfite) => benfite.id)"
            :custom-label="(opt) => benfites.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('pay_choose')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="col-lg-4 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="createsValue"
            :options="creates.map((create) => create.id)"
            :custom-label="(opt) => creates.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('creates_before_choose')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->

      .
      <div class="col-lg-3 col-md-6 col-12 mb-4">
        <button class="PrimaryButton btn">{{ $t("search_now") }}</button>
      </div>
    </div>

    <div class="row mb-5">
      <table class="table" id="RoomTable">
        <thead>
          <tr>
            <th>{{ $t("payment_methods") }}</th>
            <th>{{ $t("version_date") }}</th>
            <th>{{ $t("discription") }}</th>
            <th>{{ $t("payment_type") }}</th>
            <th>{{ $t("usage") }}</th>
            <th>{{ $t("payment_method") }}</th>

            <th>{{ $t("the recepit") }}</th>
            <th>{{ $t("Status") }}</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(client, index) in clients" :key="index">
            <td>{{ client.id }}</td>
            <td>{{ client.name }}</td>
            <td>{{ client.email }}</td>
            <td>{{ client.phone }}</td>
            <td>{{ client.phone }}</td>
            <td>{{ client.phone }}</td>

            <td>
              <router-link class="btn attachment" to="#">
                {{ $t("download_attachment") }}
              </router-link>
            </td>

            <td>
              <span
                :class="[
                  'badge',
                  status === 0
                    ? 'bg-primary'
                    : status === 1
                    ? 'bg-success'
                    : '',
                ]"
              >
                {{
                  status === 0 ? $t("new") : status === 1 ? $t("Accept") : ""
                }}
              </span>
            </td>

            <td>
              <div v-if="status === 0">
                <span class="ok"><i class="fa-solid fa-circle-check"></i></span>
                <span
                  data-bs-toggle="modal"
                  :data-bs-target="'#exampleModal' + index"
                  class="wrong"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>
              <!-- modal -->
              <div
                class="modal fade"
                :id="'exampleModal' + index"
                tabindex="-1"
                :aria-labelledby="'exampleModalLabel' + index"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-body">
                      <div
                        class="d-flex justify-content-center align-items-center w-100 flex-column"
                      >
                        <img
                          src="@/assets/media/image/warning1.png"
                          alt="warning"
                        />
                        <h5>{{ $t("are_u_want_to_cancel") }}</h5>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn PrimaryButton"
                        data-bs-dismiss="modal"
                      >
                        {{ $t("no_return") }}
                      </button>
                      <button type="button" class="btn SecondButton">
                        {{ $t("yes_cancel") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- modal -->
              <div v-if="status === 1">
                <span class="return">
                  <i class="fa-solid fa-arrow-rotate-left"></i>
                </span>
              </div>
            </td>

            <td>
              <button
                class="btn attachment"
                type="button"
                data-bs-toggle="offcanvas"
                :data-bs-target="`#offcanvasBottom${index}`"
                :key="`#offcanvasBottom${index}`"
              >
                {{ $t("details_booking") }}
              </button>
            </td>
            <!-- offcavans -->
            <div
              :key="'offcanvas' + index"
              class="offcanvas offcanvas-bottom"
              :id="'offcanvasBottom' + index"
              aria-labelledby="offcanvasBottomLabel"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasBottomLabel">
                  <span> {{ $t("payment_methos_detials") }}</span>
                  P2023 - 1810
                </h5>
                <button
                  type="button"
                  class="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body small">
                <div class="information">
                  <div class="row">
                    <div class="col-lg-8 col-12">
                      <div>
                        <span>{{ $t("client") }}</span>
                        <h6>Alwalid Golden</h6>
                      </div>
                      <div>
                        <span>{{ $t("date") }}</span>
                        <h6>2023-01-01</h6>
                      </div>

                      <div>
                        <span>Amount to pay</span>
                        <h6>12815.00</h6>
                      </div>

                      <div>
                        <span>{{ $t("payment_type") }}</span>
                        <h6>تسبقة</h6>
                      </div>
                    </div>

                    <div class="col-lg-4 col-12">
                      <div>
                        <span>{{ $t("the amount") }}</span>
                        <h6>200.000 SAR</h6>
                      </div>
                    </div>
                  </div>
                  <div class="booking">
                    <div class="row">
                      <div class="col-lg-6 col-6"></div>
                      <div class="col-lg-6 col-6">
                        <div class="recipt">
                          <h6>{{ $t("receipt") }}</h6>
                          <div class="row">
                            <div class="col-lg-6 col-12">
                              <div class="img">
                                <img
                                  src="@/assets/media/image/recit.png"
                                  alt="resipt"
                                />
                              </div>
                            </div>
                            <div class="col-lg-6 col-12"></div>
                          </div>
                          <div class="content"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- offcavans -->
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="clients"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchClients"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import "t-datepicker";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
// import moment from "moment";
export default {
  name: "payment-page",
  components: {
    pagination,
    Multiselect,
    Datepicker: VueDatepickerUi,
  },
  data() {
    return {
      clients: [],
      status: 0,
      statuses: [
        {
          id: 0,
          title: this.$t("new"),
        },
        {
          id: 1,
          title: this.$t("Accept"),
        },
      ],
      statusValue: "",
      payments: [
        {
          id: 0,
          title: "cash",
        },
        {
          id: 1,
          title: "تحويل",
        },
      ],
      paymentValue: "",
      bankValue: "",
      banks: [
        {
          id: 0,
          title: "bank2",
        },
        {
          id: 1,
          title: "bank2",
        },
      ],
      safes: [
        {
          id: 1,
          title: "Safe 1",
        },
        {
          id: 2,
          title: "Safe 2",
        },
      ],
      safeValue: "",
      clientss: [
        {
          id: 1,
          title: "Client 1",
        },
        {
          id: 2,
          title: "Client 2",
        },
      ],
      clientValue: "",
      benfites: [
        {
          id: 1,
          title: "Benfite 1",
        },
        {
          id: 2,
          title: "Benfite 2",
        },
      ],
      benfitesValue: "",
      createsValue: "",
      creates: [
        {
          id: 1,
          title: "Create 1",
        },
        {
          id: 2,
          title: "Create 2",
        },
      ],
      showFilter: false,
    };
  },
  methods: {
    // fetch suppliers data
    FetchClients() {
      axios.post(`/fetch_consumer_by_role`, { role: 1 }).then(({ data }) => {
        this.clients = data.data.data;
        // console.log(this.suppliers);
      });
    },
    //delete suppliers
    deleteClients(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.clients.data[index].id;
          const fromClick = true;
          axios
            .delete(`/clients/${delete_id}`, { fromClick })
            .then(({ data }) => {
              //   console.log(data)
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.clients.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            })
            .catch((error) => {
              if(error.response.status != 403){
                Swal.fire("", error.response.data.message, "error");
              }
            });
        }
      });
    },
    showFilterRow() {
      this.showFilter = true;
    },
  },
  mounted() {
    this.FetchClients();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "./__payment.scss";
</style>
