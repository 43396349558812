<template>
  <div class="add-fav" @click="visible = true">
    <i class="fa-solid fa-bookmark"></i>
  </div>

  <Dialog
    v-model:visible="visible"
    modal
    :header="$t('add route')"
    :style="{ width: '25rem' }"
    :dismissableMask="true"
  >
    <div class="form-group">
      <label class="form-label" for="pax">{{ $t("name of path") }}</label>
      <input type="text" class="form-control" id="pax" v-model="title" />
    </div>
    <button
      type="button"
      class="PrimaryButton"
      :disabled="!isValid"
      @click="addFavRoute(this.$route.path, title), (visible = false)"
    >
      {{ $t("Save") }}
    </button>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";

export default {
  name: "addFavDailog",
  data() {
    return {
      visible: false,
      title: "",
    };
  },
  components: {
    Dialog,
  },
  computed: {
    isValid() {
      return this.title.trim() !== "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.add-fav {
  padding: 5px;
  background-color: #fff;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $SecondColor;
  transition: 0.3s ease-in-out;
  border: 1px solid $SecondColor;
  border-radius: 50%;
  &:hover {
    background-color: $SecondColor;
    color: #fff;
    border-color: #fff;
  }
  i {
    margin-inline-start: initial;
    margin-inline-end: initial;
  }
}
</style>
