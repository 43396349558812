<template>
  <section class="card_style">
    <div class="main_header_style flex-column">
      <h4>{{ $t("add_supplirs") }}</h4>
    </div>

    <form @submit.prevent="addsupplier()">
      <div class="row">
        <!-- room name ar -->
        <div class="col-lg-4 mb-4">
          <label for="name">{{ $t("name") }}</label>
          <input
            type="text"
            :placeholder="$t('name')"
            id="name"
            v-model="name"
          />
          <span class="input-errors" v-if="v$.name.$error">{{
            v$.name.$errors[0].$message
          }}</span>
        </div>

        <!-- room name en -->
        <div class="col-lg-4 mb-4">
          <label for="name">{{ $t("email") }}</label>
          <input
            type="email"
            :placeholder="$t('email')"
            id="name"
            v-model="email"
          />
          <span class="input-errors" v-if="v$.email.$error"
            >{{ v$.email.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="adult">{{ $t("phone") }}</label>
          <input type="number" :placeholder="$t('phone')" v-model="phone" />
          <span class="input-errors" v-if="v$.phone.$error"
            >{{ v$.phone.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="adult">{{ $t("address") }}</label>
          <input type="text" :placeholder="$t('address')" v-model="address" />
          <span class="input-errors" v-if="v$.address.$error"
            >{{ v$.address.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="adult">{{ $t("country") }}</label>
          <Multiselect
            v-model="countryValues"
            :options="countries.map((country) => country.id)"
            :custom-label="(opt) => countries.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('choose_country')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
          <span class="input-errors" v-if="v$.countryValues.$error"
            >{{ v$.countryValues.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-4 mb-2">
          <label for="type" class="fw-bold"
            >{{ $t("Manual code") }}

            <sub class="info">
              <b>( {{ $t("unique code for suplier") }} )</b>
            </sub>
          </label>
          <input
            class="form-control"
            type="text"
            :placeholder="$t('Manual code')"
            v-model="manual_code"
          />
          <span class="input-errors" v-if="v$.manual_code.$error"
            >{{ v$.manual_code.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="type">{{ $t("supplier_type") }}</label>
          <Multiselect
            v-model="supplier_type_value"
            :options="supplier_types"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose_supplier_type')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
          <span class="input-errors" v-if="v$.supplier_type_value.$error"
            >{{ v$.supplier_type_value.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="tax">{{ $t("tax number") }}</label>
          <input
            type="text"
            :placeholder="$t('tax number')"
            v-model="tax_number"
          />
          <span class="input-errors" v-if="v$.tax_number.$error"
            >{{ v$.tax_number.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="Registration">{{ $t("Registration number") }}</label>
          <input
            type="text"
            :placeholder="$t('Registration number')"
            v-model="registration_number"
          />
          <span class="input-errors" v-if="v$.registration_number.$error"
            >{{ v$.registration_number.$errors[0].$message }}
          </span>
        </div>
        <div class="col-12 my-2">
          <div class="d-flex align-items-center gap-2">
            <Checkbox v-model="checked" :binary="true" />
            <p class="mb-0">{{ $t("can deal with as client") }}</p>
          </div>
        </div>
        <div class="col-lg-12 mb-4">
          <div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="cash"
                v-model="payment_method"
                value="1"
              />
              <label class="form-check-label" for="cash">
                {{ $t("cash") }}
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="pending"
                v-model="payment_method"
                value="2"
              />
              <label class="form-check-label" for="pending"> آجل </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="prepending"
                v-model="payment_method"
                value="3"
              />
              <label class="form-check-label" for="prepending">
                {{ $t("Pre_Payment") }}
              </label>
            </div>
          </div>
        </div>

        <div class="row" v-if="payment_method == 3">
          <div class="col-lg-4 mb-4">
            <label for="adult">{{ $t("down_money") }}</label>
            <input
              type="number"
              :placeholder="$t('enter_down_money')"
              v-model="down_payment"
            />
            <span class="input-errors" v-if="v$.down_payment.$error"
              >{{ v$.down_payment.$errors[0].$message }}
            </span>
          </div>
          <!-- start input group -->
          <div class="col-lg-4 col-12 mb-4">
            <div class="input-group">
              <label class="form-label">{{
                $t("choose_payment_method")
              }}</label>
              <Multiselect
                v-model="paidValues"
                :options="paids.map((paid) => paid.id)"
                :custom-label="(opt) => paids.find((x) => x.id == opt).title"
                :clear-on-select="true"
                :placeholder="$t('choose_payment_method')"
                select-label=""
                :hide-selected="true"
                @update:model-value="FetchAccounts(paidValues)"
              >
              </Multiselect>
              <span class="input-errors" v-if="v$.paidValues.$error"
                >{{ v$.paidValues.$errors[0].$message }}
              </span>
            </div>
          </div>
          <!-- finish input group -->

          <div class="col-lg-4 col-12 mb-4">
            <label class="form-label">{{ $t("accounts") }}</label>
            <Multiselect
              v-model="accountValue"
              :options="accounts.map((account) => account.id)"
              :custom-label="
                (opt) => accounts.find((x) => x.id == opt).title_ar
              "
              :clear-on-select="true"
              :placeholder="$t('accounts')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.accountValue.$error"
              >{{ v$.accountValue.$errors[0].$message }}
            </span>
          </div>
        </div>

        <div v-if="payment_method == 2">
          <div class="col-lg-6 mb-4">
            <label for="adult">{{ $t("Credit Limit") }}</label>
            <input
              type="number"
              :placeholder="$t('Enter Credit Limit')"
              v-model="credit_limit"
            />
            <span class="input-errors" v-if="v$.credit_limit.$error"
              >{{ v$.credit_limit.$errors[0].$message }}
            </span>
          </div>
        </div>

        <div class="col-lg-4 mb-4" v-if="payment_method == 1">
          <label for="account">{{ $t("account number") }}</label>
          <input
            type="number"
            :placeholder="$t('account number')"
            v-model="account_number"
          />
          <span class="input-errors" v-if="v$.account_number.$error"
            >{{ v$.account_number.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-4 mb-4" v-if="payment_method == 1">
          <label for="bank">{{ $t("bank name") }}</label>
          <input
            type="text"
            :placeholder="$t('bank name')"
            v-model="bank_name"
          />
          <span class="input-errors" v-if="v$.bank_name.$error"
            >{{ v$.bank_name.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-4 mb-4" v-if="payment_method == 1">
          <label for="IBAN">{{ $t("IBAN") }}</label>
          <input type="text" :placeholder="$t('IBAN')" v-model="IBAN" />
          <span class="input-errors" v-if="v$.IBAN.$error"
            >{{ v$.IBAN.$errors[0].$message }}
          </span>
        </div>
      </div>

      <button type="submit" class="add save btn">{{ $t("add") }}</button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import Checkbox from "primevue/checkbox";
export default {
  name: "add-suppliers",
  data() {
    return {
      checked: false,
      v$: useVuelidate(),
      manual_code: "",
      name: "",
      email: "",
      phone: "",
      address: "",
      countryValues: "",
      countries: [],
      credit_limit: 0, // Initialize as an empty string to store the selected option
      down_payment: 0,
      payment_method: 1,
      tax_number: "",
      registration_number: "",
      supplier_types: [],
      supplier_type_value: "",
      paids: [
        {
          id: 1,
          title: this.$t("treasury"),
        },
        {
          id: 2,
          title: this.$t("bank"),
        },
        {
          id: 3,
          title: this.$t("ewalllets"),
        },
        {
          id: 4,
          title: this.$t("checks_under_collection"),
        },
      ],
      accountValue: "",
      paidValues: "",
      accounts: [],
      account_number: "",
      bank_name: "",
      IBAN: "",
    };
  },
  components: {
    Multiselect,
    Checkbox,
  },
  validations() {
    return {
      manual_code: {
        required,
      },
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      address: {
        required,
      },
      countryValues: {
        required,
      },
      supplier_type_value: {
        required,
      },
      tax_number: {
        required,
      },
      registration_number: {
        required,
      },

      account_number: this.payment_method == 1 ? { required } : "",

      bank_name: this.payment_method == 1 ? { required } : "",

      IBAN: this.payment_method == 1 ? { required } : "",

      credit_limit: this.payment_method == 2 ? { required } : "",

      down_payment: this.payment_method == 3 ? { required } : "",

      paidValues: this.payment_method == 3 ? { required } : "",

      accountValue: this.payment_method == 3 ? { required } : "",
    };
  },
  methods: {
    addsupplier() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        formData.append("manual_code", this.manual_code);
        formData.append("name", this.name);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        formData.append("address", this.address);
        formData.append("country_id", this.countryValues);
        formData.append("role", 2);
        formData.append("registration_number", this.registration_number);
        formData.append("tax_number", this.tax_number);
        formData.append("supplier_type_id", this.supplier_type_value.id);
        formData.append(`payment_method`, this.payment_method);
        formData.append("is_client", this.checked ? 1 : 0);
        if (this.payment_method == 1) {
          formData.append("bank_name", this.bank_name);
          formData.append("Iban", this.IBAN);
          formData.append("bank_account_number", this.account_number);
        } else if (this.payment_method == 2) {
          formData.append("Pending_limit", this.credit_limit);
          formData.append("credit_limit", this.credit_limit);
        } else if (this.payment_method == 3) {
          formData.append("prepayment_amount", this.down_payment);
          formData.append("down_payment", this.down_payment);
          formData.append(`payment_method_type`, this.paidValues);
          if (this.accountValue) {
            formData.append(`safe_branch_id`, this.accountValue);
          }
        }
        axios
          .post("/consumers", formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            if (this.$route.params.page == 1) {
              this.$router.push("/book_offline");
            } else {
              if (this.$route.path == `suppliers/add/${this.pageId}`) {
                this.$router.push("/suppliers");
              }
            }
            this.$emit("closrDilaog", false);
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.$emit("closrDilaog", false);
          });
      }
    },

    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data.map((ele) => {
          ele.title = `${ele.title} - ${ele.code} (${ele.id})`;
          return ele;
        });
      });
    },
    FetchAccounts(account_id) {
      let type = { payment_method: account_id };
      axios.post(`/fetch_payment_method`, type).then(({ data }) => {
        this.accounts = data.data;
      });
    },
    fetch_supplier_types() {
      axios.get("/supplier_types").then(({ data }) => {
        this.supplier_types = data.data.data;
      });
    },
  },
  created() {
    this.fetch_country();
    this.fetch_supplier_types();
  },
};
</script>

<style lang="scss" scoped>
@import "@/view/dashboard/suppliers/_suppliers";
form {
  label {
    display: inline-block;
    margin-bottom: 10px;
    font-family: "bold" !important;
  }
  input,
  select {
    display: block;
    width: 100%;
    // height: 68px;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    color: #9ca2ac;
    padding: 10px 15px;
  }
}
.form-check-inline {
  display: inline-flex;
  flex-direction: column;
  margin-top: 0;
}
.form-check-input {
  width: 17px;
  height: 17px;
  padding: 0;
}
.info {
  font-size: 0.7rem;
}
</style>
