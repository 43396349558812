<template>
  <div class="row">
    <div class="main_header_style">
      <h4>
        {{ $t("shipping requests") }} <span>({{ wallets.length }})</span>
      </h4>

      <div class="d-flex flex-row justify-content-end w-50 mt-4">
        <div style="margin-inline-end: 1%" class="input-group w-50">
          <Multiselect
            v-model="bankAccount"
            :options="banks.map((bank) => bank.id)"
            :custom-label="(opt) => banks.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('reservation_status')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
    </div>
    <div class="row">
      <table class="table table_shipping" id="safeTable">
        <thead>
          <tr>
            <th>{{ $t("transfer request from") }}</th>
            <th>{{ $t("the amount") }}</th>
            <th>{{ $t("the date of application") }}</th>
            <th>{{ $t("transfer_to") }}</th>
            <th>{{ $t("Status") }}</th>
            <th>{{ $t("Receipt") }}</th>
            <th>{{ $t("the action") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(wallet, index) in wallets.date" :key="index">
            <td>{{ wallet.from_organization.name }}</td>
            <td>{{ wallet.amount }}</td>
            <td>{{ wallet.created_at }}</td>
            <td>{{ wallet.to_organization.name }}</td>
            <td>
              <span
                class="status"
                :class="{
                  accept: wallet.status == 1,
                  pending: wallet.status == 0,
                  decline: wallet.status == 2,
                }"
                >{{
                  wallet.status == 0
                    ? $t("Pending")
                    : wallet.status == 1
                    ? $t("Accept")
                    : $t("Decline")
                }}</span
              >
            </td>
            <td>
              <a class="receipt" href="">{{
                wallet.receipt != "" ? $t("view receipt") : ""
              }}</a>
            </td>
            <!-- dropdown -->
            <td>
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa-solid fa-bars"></i>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <div
                      class="dropdown-item"
                      type="button"
                      data-bs-toggle="offcanvas"
                      :data-bs-target="`#offcanvasBottom${wallet.id}`"
                      aria-controls="offcanvasBottom"
                    >
                      <i class="fa-solid fa-circle-exclamation"></i>
                      <span>{{ $t("request's Details") }}</span>
                    </div>
                  </li>
                  <div v-if="wallet.status == 0">
                    <li>
                      <div class="dropdown-item" @click="acceptRequest(index)">
                        <i class="fa-regular fa-circle-check text-primary"></i>
                        <span class="text-primary">{{
                          $t("accept request")
                        }}</span>
                      </div>
                    </li>
                    <li>
                      <div class="dropdown-item" @click="declineRequest()">
                        <i class="fa-regular fa-circle-xmark text-danger"></i>
                        <span class="text-danger">{{
                          $t("decline request")
                        }}</span>
                      </div>
                    </li>
                  </div>
                </ul>
              </div>
            </td>
            <!-- toggle popup -->
            <div
              class="offcanvas offcanvas-bottom"
              tabindex="-1"
              :id="`offcanvasBottom${wallet.id}`"
              aria-labelledby="offcanvasBottomLabel"
            >
              <div class="offcanvas-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body small">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="wallet-charge">
                      <div class="detail-row">
                        <p class="details-name">{{ $t("request type") }}</p>
                        <p>{{ wallet.type_request }}</p>
                      </div>
                      <div class="detail-row">
                        <p class="details-name">{{ $t("charge from") }}</p>
                        <p>{{ wallet.from_organization.name }}</p>
                      </div>
                      <div class="detail-row">
                        <p class="details-name">
                          {{ $t("the date of application") }}
                        </p>
                        <p>{{ wallet.created_at }}</p>
                      </div>
                      <div class="detail-row">
                        <p class="details-name">
                          {{ $t("transfer from account") }}
                        </p>
                        <p>{{ wallet.from_account }}</p>
                      </div>
                      <div class="detail-row">
                        <p class="details-name">
                          {{ $t("transfer to account") }}
                        </p>
                        <p>{{ wallet.to_account }}</p>
                      </div>
                      <div class="detail-row">
                        <p class="details-name">IBAN{{ $t("Number") }}</p>
                        <p>{{ wallet.iban }}</p>
                      </div>
                      <div class="detail-row">
                        <p class="details-name">SWIFT{{ $t("Number") }}</p>
                        <p>{{ wallet.swift }}</p>
                      </div>
                      <div class="detail-row">
                        <p class="details-name">{{ $t("request status") }}</p>
                        <p>
                          <span
                            class="status"
                            :class="{
                              accept: wallet.status == 1,
                              pending: wallet.status == 0,
                              decline: wallet.status == 2,
                            }"
                            >{{
                              wallet.status == 0
                                ? $t("Pending")
                                : wallet.status == 1
                                ? $t("Accept")
                                : $t("Decline")
                            }}</span
                          >
                        </p>
                      </div>
                      <div class="detail-row">
                        <p class="details-name">{{ $t("the amount") }}</p>
                        <p>{{ wallet.amount }}</p>
                      </div>
                      <div class="detail-row">
                        <p class="details-name">{{ $t("transfer number") }}</p>
                        <p>{{ wallet.number_transform }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="receipt-details">
                      <h4 class="my-4">{{ $t("the recepit") }}</h4>
                      <div class="row content">
                        <div class="col-12 col-lg-6 mb-4 image">
                          <img :src="wallet.receipt" alt="" />
                        </div>
                        <div class="col-12 col-lg-6 details">
                          <a :href="wallet.receipt" target="_blank" class="btn"
                            ><i class="fa-solid fa-download"></i>
                            {{ $t("Download") }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button class="btn cancel my-5">
                  <i class="fa-solid fa-upload"></i> {{ $t("Cancel Request") }}
                </button>
              </div>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="row">
      <pagination
        :data="wallets"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchWalletsData"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div> -->
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import Multiselect from "vue-multiselect";
export default {
  name: "shipping-requst",
  components: {
    Multiselect,
  },
  data() {
    return {
      wallets: [],
      banks: [
        {
          id: 1,
          title: "sfasf",
        },
      ],
      bankAccount: "",
    };
  },
  methods: {
    // fetch shipping  data
    fetchWalletsData(page = 1) {
      axios.get(`/fetch_wallet_history?page=${page}`).then(({ data }) => {
        this.wallets = data.data;
        // console.log(this.wallets)
      });
    },

    //delete recipt
    declineRequest() {
      Swal.fire({
        title: this.$t("check_decline"),
        text: this.$t("are_you_sure_you_want_to_decline"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_decline"),
      }).then((result) => {
        if (result.isConfirmed) {
          let wallet_id = { wallet_id: 1 };
          axios.post("/decline_request", wallet_id).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.fetchWalletsData();
  },

  updated() {
    // axios.get("API_Goes_Here").then((response) => {
    //   this.products = response.data;
    // });
    $("#safeTable").DataTable({
      bPaginate: false,
      retrieve: true,
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("employ"),
        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("from") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("employ"),
      },
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "../../view/dashboard/organization/_organization.scss";
</style>
