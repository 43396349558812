<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center mb-2 gap-3">
      <AddFavDailog v-tooltip.left="$t('add to favorites')" />
      <h4>
        {{ $t("History") }}
      </h4>
    </div>
    <div class="main_header_style mb-2">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="search-visa w-100">
          <form>
            <input
              type="text"
              class="form-control w-50"
              :placeholder="$t('search...')"
              v-model="terms_search"
              @keyup="fetchHistory((page = 1))"
            />
          </form>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table visaType" id="VisaTable">
          <thead>
            <tr>
              <th>{{ $t("empoly_name") }}</th>
              <th>{{ $t("date") }}</th>
              <th>{{ $t("time") }}</th>
              <th>{{ $t("old cost") }}</th>
              <th>{{ $t("new cost") }}</th>
              <th>{{ $t("old selling") }} (b2b)</th>
              <th>{{ $t("new selling") }} (b2b)</th>
              <th>{{ $t("old selling") }} (b2c)</th>
              <th>{{ $t("new selling") }} (b2c)</th>
              <th>
                {{ $t("Identification Number") }}
              </th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="term in 10" :key="term">
              <td v-for="term in 10" :key="term">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(term, index) in historys.data" :key="index">
              <td>{{ term.employee?.name }}</td>
              <td>{{ term?.created_at }}</td>
              <td>{{ term?.time }}</td>
              <td>{{ term?.old_cost }}</td>
              <td>{{ term?.new_cost }}</td>
              <td>{{ term?.old_b2b_price }}</td>
              <td>{{ term?.new_b2b_price }}</td>
              <td>{{ term?.old_b2c_price }}</td>
              <td>{{ term?.new_b2c_price }}</td>
              <td>{{ term?.edit_number ? term?.edit_number : "-" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="historys"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchHistory"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import Skeleton from "primevue/skeleton";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import pagination from "laravel-vue-pagination";
export default {
  name: "history-page",
  data() {
    return {
      historys: [],
      loading: true,
      terms_search: "",
    };
  },
  components: {
    Skeleton,
    AddFavDailog,
    pagination,
  },
  methods: {
    fetchHistory(page = 1) {
      axios
        .post(`/fetch_season_rate_code_room_view_day_history?page=${page}`, {
          season_rate_code_room_view_id: this.$route.params.id,
          word: this.terms_search,
        })
        .then(({ data }) => {
          //   console.log(data.data, "his");
          this.historys = data.data;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchHistory();
  },
};
</script>
