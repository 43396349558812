<template>
  <div
    class="offcanvas offcanvas-bottom"
    tabindex="-1"
    id="offcanvasBottom"
    aria-labelledby="offcanvasBottomLabel"
  >
    <div class="offcanvas-header">
      <h4 class="offcanvas-title" id="offcanvasBottomLabel">
        {{ $t("charge wallet request") }}
      </h4>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body small">
      <form class="charge_wallet_request" @submit.prevent="rechargeWallet()">
        <h5>{{ $t("choose account bank") }}</h5>
        <div class="row">
          <div
            v-for="organization in organizationAccounts.data"
            :key="organization.id"
            class="col-6 col-lg-3"
          >
            <div class="bank service_type">
              <input
                type="radio"
                name="bank"
                :id="`bank${organization.id}`"
                @change="getcurrentOrganization(organization)"
              />
              <label :for="`bank${organization.id}`">
                {{ organization.title }}
              </label>
            </div>
          </div>
        </div>

        <div class="row bank-info my-4">
          <div class="col-6 my-2">
            <label class="mb-2">{{ $t("Bank Name") }}</label>
            <input
              type="text"
              :placeholder="$t('Bank Name')"
              name="bankName"
              v-model="currentOrganization.title"
              disabled
            />
          </div>
          <div class="col-6 my-2">
            <label class="mb-2">{{ $t("account Number") }}</label>
            <input
              type="text"
              :placeholder="$t('account Name')"
              name="accountNumber"
              v-model="currentOrganization.account_number"
              disabled
            />
          </div>
          <div class="col-6 my-2">
            <label class="mb-2">IBAN NO.</label>
            <input
              type="text"
              placeholder="iban Number"
              name="iban"
              v-model="currentOrganization.iban"
              disabled
            />
          </div>
          <div class="col-6 my-2">
            <label class="mb-2">SWIFT</label>
            <input
              type="text"
              placeholder="SWIFT Number"
              name="swift"
              v-model="currentOrganization.swift_code"
              disabled
            />
          </div>
        </div>

        <div class="row transfer-info my-4">
          <h5>{{ $t("transfer's Details") }}</h5>
          <div class="col-6 mt-2 mb-3">
            <label class="mb-2">{{ $t("the amount of transfer") }}</label>
            <input
              type="number"
              :placeholder="$t('Enter the amount')"
              name="the amount"
              v-model="amount"
            />
          </div>
          <!-- <div class="col-6 mt-2 mb-3">
            <label class="mb-2">{{ $t("the account") }}</label>
            <Multiselect v-model="from_account_id" :options="organizationAccounts.map((account) => account.id)"
              :custom-label="(opt) => organizationAccounts.find((x) => x.id == opt).title
                " :clear-on-select="true" :placeholder="$t('the account')" select-label="" :hide-selected="true">
            </Multiselect>
          </div> -->

          <div>
            <input
              type="file"
              id="file"
              @change="receiptUpload"
              multiple
              hidden
            />
            <label for="file" class="add_file"
              ><i class="fa-solid fa-upload"></i> {{ $t("Add File") }}</label
            >
            <p>{{ this.receiptName }}</p>
          </div>

          <!-- <div class="row">
            <h4>{{ $t("wallet type") }}</h4>
            <div class="col-lg-1">
              <div class="d-flex align-items-center justify-content-center">
                <input
                  type="radio"
                  name="wallet_type"
                  value="recharge"
                  v-model="walletType"
                  class="w"
                />
                <label class="form-check-label">{{ $t("transfer") }}</label>
              </div>
            </div>
            <div class="col-lg-1">
              <div class="d-flex align-items-center justify-content-center">
                <input
                  type="radio"
                  name="wallet_type"
                  value="transfer"
                  v-model="walletType"
                  class="w"
                />
                <label class="form-check-label">{{ $t("recharge") }}</label>
              </div>
            </div>
          </div> -->
        </div>

        <button
          class="btn confirm-request"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          type="submit"
        >
          {{ $t("Confirm Request") }}
        </button>
        <button
          class="btn cancel-request"
          type="button"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          {{ $t("Cancel") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  emits: ["custom-event"],
  data() {
    return {
      parentOrgnization: [],
      currentOrganization: {},
      organizationAccounts: [],
      // from_account_id: "",
      receiptName: "",
      receipt: "",
      type: 1,
      amount: "",
      // walletType: "",
    };
  },
  methods: {
    fetchParentOrgnizationAccount() {
      axios
        .post("/fetch_parent_organization_account", this.type)
        .then(({ data }) => {
          this.parentOrgnization = data.data;
          // console.log(this.parentOrgnization)
        });
    },
    getcurrentOrganization(organization) {
      this.currentOrganization = organization;
    },
    fetchOrganizationAccounts() {
      axios
        .post("/fetch_my_organization_accounts", { type: 1 })
        .then(({ data }) => {
          this.organizationAccounts = data.data;
          // console.log(this.organizationAccounts);
        });
    },
    // image
    receiptUpload(event) {
      this.receipt = event.target.files[0];
      const name = this.receipt.name;

      const reader = new FileReader();
      reader.readAsDataURL(this.receipt);
      reader.onload = () => (this.receiptName = name);
    },
    rechargeWallet() {
      const formData = new FormData();
      formData.append("payment_type", this.type);
      // formData.append("from_account_id", this.from_account_id);
      formData.append("from_account_id", this.currentOrganization.id);

      // if (this.walletType == "transfer") {
      //   formData.append("wallet_type", 1);
      // } else {
      //   formData.append("wallet_type", 2);
      // }
      formData.append("wallet_type", 1);
      formData.append("amount", this.amount);
      formData.append("receipt", this.receipt);

      axios
        .post("/recharge_wallet", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
            timer: 1500,
          });
          // window.location.href = data?.data?.payment_link ?? null;
          // console.log(data?.data?.payment_link);
          this.$emit("custom-event");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
            timer: 1500,
          });
        });
    },
  },
  created() {
    this.fetchParentOrgnizationAccount();
    this.fetchOrganizationAccounts();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "../../view/dashboard/wallet/_wallet.scss";
input.w {
  width: 10%;
}
.form-check-label {
  width: -webkit-fill-available;
}
</style>
