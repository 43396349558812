<template>
  <div class="tabs_sec">
    <ul
      class="nav nav-tabs d-flex gap-4 align-items-center"
      id="myTab"
      role="tablist"
      v-if="loading"
    >
      <li class="nav-item" role="presentation">
        <Skeleton width="5rem" class="mb-2"></Skeleton>
      </li>
      <li class="nav-item" role="presentation">
        <Skeleton width="5rem" class="mb-2"></Skeleton>
      </li>
      <li class="nav-item" role="presentation">
        <Skeleton width="5rem" class="mb-2"></Skeleton>
      </li>
      <li class="nav-item" role="presentation">
        <Skeleton width="5rem" class="mb-2"></Skeleton>
      </li>
    </ul>

    <ul class="nav nav-tabs" id="myTab" role="tablist" v-else>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="hotel-tab"
          data-bs-toggle="tab"
          data-bs-target="#hotel-tab-pane"
          type="button"
          role="tab"
          aria-controls="hotel-tab-pane"
          aria-selected="true"
        >
          {{ $t("hotel_reservation") }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="flight-tab"
          data-bs-toggle="tab"
          data-bs-target="#flight-tab-pane"
          type="button"
          role="tab"
          aria-controls="flight-tab-pane"
          aria-selected="false"
        >
          {{ $t("flight_reservation") }}
        </button>
      </li>
      <!-- <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="transport-tab"
          data-bs-toggle="tab"
          data-bs-target="#transport-tab-pane"
          type="button"
          role="tab"
          aria-controls="transport-tab-pane"
          aria-selected="false"
        >
          {{ $t("transportation") }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="spcial-tab"
          data-bs-toggle="tab"
          data-bs-target="#spcial-tab-pane"
          type="button"
          role="tab"
          aria-controls="spcial-tab-pane"
          aria-selected="false"
        >
          {{ $t("private_trips") }}
        </button>
      </li> -->
    </ul>

    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="hotel-tab-pane"
        role="tabpanel"
        aria-labelledby="hotel-tab"
        tabindex="0"
      >
        <hotelReservation />
      </div>
      <div
        class="tab-pane fade"
        id="flight-tab-pane"
        role="tabpanel"
        aria-labelledby="flight-tab"
        tabindex="0"
      >
        <flightReservation />
      </div>
      <!-- <div
        class="tab-pane fade"
        id="transport-tab-pane"
        role="tabpanel"
        aria-labelledby="transport-tab"
        tabindex="0"
      >
        <transporter />
      </div>
      <div
        class="tab-pane fade"
        id="spcial-tab-pane"
        role="tabpanel"
        aria-labelledby="spcial-tab"
        tabindex="0"
      >
        <spcialTrips />
      </div> -->
    </div>
  </div>
</template>

<script>
import hotelReservation from "./hotel-reservation";
import flightReservation from "./flight-reservation";
// import transporter from "./transporter.vue";
// import spcialTrips from "./spcial-trips.vue";
import Skeleton from "primevue/skeleton";
export default {
  name: "tabs-consumer",
  components: {
    hotelReservation,
    flightReservation,
    // transporter,
    // spcialTrips,
    Skeleton,
  },
  props: ["loading"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.tabs_sec {
  padding: 1rem;
  border-radius: 16px;
  background-color: #fff;
  h4 {
    font-size: 1.2rem;
    font-family: "bold";
    color: $PrimaryText;
    margin: 2% 0;
  }

  .nav-tabs {
    border-bottom: 1px solid #d0d2d7;

    li {
      .nav-link {
        font-size: 1rem;
        font-family: "light";
        color: #656b78;
      }
      .nav-link.active {
        border: unset;
        border-bottom: 2px solid $PrimaryColor;
      }
    }
  }
}
</style>
