<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="add_recipt card_style">
    <div class="main_header_style">
      <h4>{{ $t("add_new_contract") }}</h4>
    </div>
    <form class="form_topic" @submit.prevent="addContract()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("contract_name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('enter_contract_name')"
              v-model="name"
            />
          </div>
          <span class="input-errors" v-if="v$.name.$error">{{
            v$.name.$errors[0].$message
          }}</span>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-3 col-md-6">
          <label class="form-label"> {{ $t("hotel_name") }}</label>
          <div class="selects">
            <div class="input-group">
              <input
                type="search"
                class="form-control"
                :placeholder="$t('Hotel')"
                v-model="searchValue"
                @input="debouncedSearchHotels"
                :disabled="hotelSearchLoading"
              />
              <div class="spinner" v-if="hotelSearchLoading">
                <i class="fa-solid fa-circle-notch fa-spin"></i>
              </div>
              <div
                class="resultSearch"
                v-if="searchValue.length > 2 && hotelsSearch.length"
              >
                <h6 class="head_search">{{ $t("hotels") }}</h6>
                <ul class="hotels">
                  <li
                    v-for="(hotel, index) in hotelsSearch"
                    :key="index"
                    @click="
                      sendHotelName(
                        hotel.title,
                        hotel.city_id,
                        hotel.id,
                        hotel.supplier
                      )
                    "
                  >
                    <img
                      :src="hotel.image"
                      class="hotel_image"
                      :alt="hotel.title"
                    />
                    <div class="texts">
                      <p class="title">{{ hotel.title }}</p>
                      <p class="country">
                        {{ hotel.country_title }} - {{ hotel.city_title }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <span class="input-errors" v-if="v$.hotelId.$error">{{
            v$.hotelId.$errors[0].$message
          }}</span>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("period_from") }}</label>
            <datepicker
              v-model="from_date"
              name="from_date"
              :placeholder="$t('from')"
            ></datepicker>
            <span class="input-errors" v-if="v$.from_date.$error">{{
              v$.from_date.$errors[0].$message
            }}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("to") }}</label>
            <datepicker
              v-model="to_date"
              name="from_date"
              :placeholder="$t('to')"
              :disabled-dates="{
                to: from_date,
              }"
            ></datepicker>
            <span class="input-errors" v-if="v$.to_date.$error">{{
              v$.to_date.$errors[0].$message
            }}</span>
          </div>
        </div>
        <!-- finish input group -->

        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label">{{ $t("supplier_type") }}</label>
            <Multiselect
              v-model="supplierOptionValue"
              :options="optionsSupplies"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
              @update:model-value="checkHotel()"
            >
            </Multiselect>
          </div>
        </div>

        <!-- start input group -->
        <div
          class="col-12 col-lg-3 col-md-6"
          v-if="supplierOptionValue?.value != 'hotel_direct'"
        >
          <div class="input-group">
            <label class="form-label"> {{ $t("suppliers") }}</label>
            <Multiselect
              v-model="supply_id"
              :options="suppliers.map((supply) => supply.id)"
              :custom-label="(opt) => suppliers.find((x) => x.id == opt).name"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
              @click="fetch_suppliers()"
            >
            </Multiselect>
          </div>
        </div>
        <!-- finish input group -->

        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label">{{ $t("contract_type") }}</label>
            <Multiselect
              v-model="nightValue"
              :options="nigths"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.nightValue.id.$error">{{
              v$.nightValue.id.$errors[0].$message
            }}</span>
          </div>
        </div>

        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("Total_contract_value") }}</label>
            <input
              type="number"
              class="form-control"
              v-model="totalValue"
              placeholder="0"
            />
          </div>
          <span class="input-errors" v-if="v$.totalValue.$error">{{
            v$.totalValue.$errors[0].$message
          }}</span>
        </div>

        <!-- start input group -->
        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label"
              >{{ $t("Total_number_of_rooms") }}
              <span class="fs-8" v-if="nightValue.value"
                >({{
                  nightValue.value == "room_night"
                    ? $t("total_number_rooms_contract_period")
                    : nightValue.value == "block"
                      ? $t("total_number_rooms_per_day")
                      : ""
                }})</span
              ></label
            >
            <input
              type="number"
              class="form-control"
              v-model="room_number"
              placeholder="0"
            />
          </div>
          <span class="input-errors" v-if="v$.room_number.$error">{{
            v$.room_number.$errors[0].$message
          }}</span>
        </div>
        <!-- finish input group -->

        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label">{{ $t("default_rooms") }}</label>
            <Multiselect
              class="w-100"
              v-model="roomValue"
              :options="rooms"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
            ></Multiselect>
            <span class="input-errors" v-if="v$.roomValue.id.$error">{{
              v$.roomValue.id.$errors[0].$message
            }}</span>
          </div>
        </div>

        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label">{{ $t("default_meal") }}</label>
            <Multiselect
              v-model="mealsValue"
              :options="meals"
              label="title"
              track-by="meal_type_id"
              :clear-on-select="true"
              :hide-selected="true"
              :placeholder="$t('meals')"
              select-label=""
              @click="fetchMealsTypes()"
            >
            </Multiselect>
            <span
              class="input-errors"
              v-if="v$.mealsValue.meal_type_id.$error"
              >{{ v$.mealsValue.meal_type_id.$errors[0].$message }}</span
            >
          </div>
        </div>

        <!-- start input group -->
        <!-- <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("status") }}</label>
            <Multiselect
              v-model="status_id"
              :options="statues.map((status) => status.id)"
              :custom-label="(opt) => statues.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div> -->
        <!-- finish input group -->

        <div class="col-12 col-lg-3 col-md-6">
          <div class="form-check form-switch form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchbtb"
              v-model="share_b2b"
            />
            <label class="form-check-label form-label" for="flexSwitchbtb">
              {{ $t("b2b_publish") }}</label
            >
          </div>
          <div class="form-check form-switch form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchbtb"
              v-model="active"
            />
            <label class="form-check-label form-label" for="flexSwitchbtb">
              {{ $t("active") }}</label
            >
          </div>
          <div class="form-check form-switch form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchbtc"
              v-model="share_b2c"
              checked
            />
            <label class="form-check-label form-label" for="flexSwitchbtc">
              {{ $t("b2c_publish") }}</label
            >
          </div>
        </div>

        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group mb-0">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="can_minus_room"
                v-model="can_minus_room"
              />
              <label class="form-check-label" for="can_minus_room">
                {{ $t("can_minus_room") }}
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="can_over_room"
                v-model="can_over_room"
              />
              <label class="form-check-label" for="tax_included">
                {{ $t("over_contract") }}
              </label>
            </div>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="tax_included"
              v-model="tax_included"
            />
            <label class="form-check-label" for="tax_included">
              {{ $t("Taxes included") }}
            </label>
          </div>
        </div>

        <div class="col-12">
          <div class="advice">
            <h4>{{ $t("holiday") }}</h4>
            <div class="row">
              <div
                class="col-lg-3 col-12"
                v-for="(day, index) in days"
                :key="index"
              >
                <div class="form-check mt-0">
                  <div class="input-group">
                    <label :for="'default' + day.value">
                      <input
                        class="form-control"
                        type="checkbox"
                        v-model="day.selected"
                        :id="'default' + day.value"
                        :value="day.value"
                      />
                      <div class="contents">
                        <h6>{{ $t(day.name) }}</h6>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- <span class="input-errors" v-if="v$.days.$error">{{v$.days.$errors[0].$message}}</span> -->
          </div>
        </div>

        <!-- start input group -->
        <div class="col-12">
          <button
            type="submit"
            class="btn PrimaryButton w-25"
            :disabled="!hotelSubmit"
          >
            {{ $t("save_cont") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import Loading from "@/components/LottieFiles/loading.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "add-contract",
  components: {
    Multiselect,
    Datepicker,
    Loading,
  },
  data() {
    return {
      v$: useVuelidate(),
      tax_included: false,
      days: [
        {
          value: 6,
          name: "saturday",
          selected: false,
        },
        {
          value: 0,
          name: "sunday",
          selected: false,
        },
        {
          value: 1,
          name: "monday",
          selected: false,
        },
        {
          value: 2,
          name: "tuesday",
          selected: false,
        },
        {
          value: 3,
          name: "wenday",
          selected: false,
        },
        {
          value: 4,
          name: "thursday",
          selected: false,
        },
        {
          value: 5,
          name: "friday",
          selected: false,
        },
      ],
      name: "",
      from_date: "",
      to_date: "",
      suppliers: [],
      supply_id: "",
      room_number: "",
      selectedDayIds: [],
      hotels: [],
      statues: [
        {
          id: 0,
          title: "disactive",
        },
        {
          id: 1,
          title: "active",
        },
      ],
      // status_id: 1,
      share_b2b: false,
      share_b2c: true,
      selectedValue: "",
      accounts: [],
      revenue: [],
      amount: "",
      report: "",
      account_id: "",
      image: "",
      created_at: "",
      code_number: "",
      revenue_type_id: "",
      image_preview: "",
      loading: false,
      hotelId: "",
      hotelsSearch: [],
      hotelValue: "",
      searchValue: "",
      optionsSupplies: [
        {
          id: 1,
          title: "Hotel direct",
          value: "hotel_direct",
        },
        {
          id: 2,
          title: "supplier",
          value: "supplier",
        },
      ],
      supplierOptionValue: {},
      nigths: [
        {
          id: 1,
          title: "block",
          value: "block",
        },
        {
          id: 2,
          title: "Room night",
          value: "room_night",
        },
      ],
      nightValue: {},
      totalValue: "",
      rooms: [],
      roomValue: {},
      mealsValue: {},
      meals: [],
      hotelSubmit: true,
      hotelSupplier: [],
      can_over_room: false,
      can_minus_room: false,
      active: true,
      debounceTimeout: null,
      isRequestPending: false,
      hotelSearchLoading: false
    };
  },
  validations() {
    return {
      name: { required },
      hotelId: { required },
      from_date: { required },
      to_date: { required },
      nightValue: { id: { required } },
      totalValue: { required },
      room_number: { required },
      roomValue: { id: { required } },
      mealsValue: { meal_type_id: { required } },
      // days: this.days.filter((day)=>{day.selected}).map(()=>{required})
    };
  },
  methods: {
    checkHotel() {
      if (this.supplierOptionValue?.value == "supplier") {
        this.fetchRooms();
      }
      if (this.supplierOptionValue?.value == "hotel_direct") {
        this.fetchHotel();
      }
    },
    fetchHotel() {
      axios
        .post("checkOrganizationHotel", { hotel_id: this.hotelId })
        .then((res) => {
          if (res.data.data == false) {
            Swal.fire({
              title: this.$t("this_hotel_has_no_supplers"),
              icon: "error",
              confirmButtonText: this.$t("ok"),
            });
            this.hotelSubmit = false;
            this.supplierOptionValue = {};
          } else {
            this.fetchRooms();
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    //fetch supplier
    fetch_suppliers() {
      axios.get("/fetch_consumers").then(({ data }) => {
        this.suppliers = data.data.data;
      });
    },

    // Debounce function with character length check
    debouncedSearchHotels(event) {

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        if (event.target.value.length <= 2) {
          return; // Exit if the input length is 2 or less
        }
        this.searchHotels(event);
      }, 1000); // Adjust the delay as needed (e.g., 1000ms)
    },

    //Search Hotels
    searchHotels(event) {
      if (this.isRequestPending) return;
      this.hotelSearchLoading = true;

      this.isRequestPending = true;
      const searchText = { word: event.target.value };

      axios
        .post(`/fetch_new_home_hotels`, searchText)
        .then((res) => {
          this.hotelsSearch = res.data.data.hotels;
          this.citiesSearch = res.data.data.cities;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.isRequestPending = false;
          this.hotelSearchLoading = false;
        });
    },

    // fetch hotels
    sendHotelName(hotelName, cityId, hotelId, supplier) {
      this.hotelName = hotelName;
      this.searchValue = hotelName;
      this.hotelId = hotelId;
      this.cityId = cityId;
      this.hotelSupplier = supplier;
      this.citiesSearch.length = [];
      this.hotelsSearch = [];

      this.supplierOptionValue = {};
      this.rooms = [];
      this.roomValue = {};
    },
    sendCityId(cityTitle, cityId) {
      this.cityId = cityId;
      this.searchValue = cityTitle;
      this.hotelName = null;
      this.citiesSearch = [];
      this.hotelsSearch = [];
    },

    //fetch organization type
    fetchOrganizationType(value) {
      let type = {
        type: value,
      };
      axios.post("/fetch_my_organization_accounts", type).then(({ data }) => {
        this.accounts = data.data.data;
      });
    },

    async addContract() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const selectedDays = this.days
          .filter((day) => day.selected)
          .map((day) => day.value);
        if (selectedDays.length == 0) {
          Swal.fire({
            title: "",
            text: this.$t("please select days"),
            icon: "error",
          });
        } else {
          const formData = new FormData();
          formData.append("name", this.name);
          formData.append(
            "from_date",
            moment(this.from_date).format("YYYY-MM-DD")
          );
          formData.append("to_date", moment(this.to_date).format("YYYY-MM-DD"));
          if (this.supplierOptionValue?.title == "supplier") {
            formData.append("supplier_id", this.supply_id);
          } else if (this.supplierOptionValue?.value == "hotel_direct") {
            formData.append("supplier_id", this.hotelSupplier[0].id);
          }
          formData.append("hotel_id", this.hotelId);
          formData.append("room_number", this.room_number);
          formData.append("status", 1);
          // Append the array of selected service type values to formData
          selectedDays.forEach((value, index) => {
            formData.append(`contract_weekends[${index}]`, value);
          });
          if (this.share_b2b == true) {
            formData.append("share_b2b", 1);
          } else {
            formData.append("share_b2b", 0);
          }
          if (this.active == true) {
            formData.append("status", 1);
          } else {
            formData.append("status", 0);
          }

          if (this.share_b2c == true) {
            formData.append("share_b2c", 1);
          } else {
            formData.append("share_b2c", 0);
          }

          formData.append(
            "contract_supplier_type",
            this.supplierOptionValue?.id
          );
          formData.append("contract_price_type", this.nightValue?.id);
          formData.append("total", this.totalValue);
          formData.append("hotel_room_view_id", this.roomValue?.id);
          formData.append("default_meal_id", this.mealsValue?.id);
          formData.append("can_over_room", this.can_over_room == true ? 1 : 0);
          formData.append("tax_included", this.tax_included == true ? 1 : 0);
          formData.append(
            "can_minus_room",
            this.can_minus_room == true ? 1 : 0
          );

          this.loading = true; // Start loading
          try {
            const response = await axios.post("/contracts", formData);
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.loading = false; //the loading ended
            this.$router.push("/contract");
          } catch (error) {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false; //the loading ended
          }
        }
      }
    },
    async fetchRooms() {
      this.hotelSubmit = true;
      const requestData = {
        hotel_id: this.hotelId,
      };

      if (requestData.hotel_id) {
        try {
          const { data } = await axios.post(
            "fetch_room_view_for_hotel",
            requestData
          );
          this.rooms = data.data;
        } catch (error) {
          Swal.fire({
            title: "",
            text: error.response.data.message || "Unknown error occurred",
            icon: "error",
          });
        }
      }
    },
    async fetchMealsTypes() {
      axios
        .post("/fetch_meal_types")
        .then(({ data }) => {
          this.meals = data.data.data;
          // console.log(this.meals);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },

  async created() {
    // this.fetch_suppliers();
    await this.fetchMealsTypes();
  },
  updated() {
    // console.log(this.supply_id);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_contract.scss";
</style>
