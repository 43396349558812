<template>
  <section class="add_recipt card_style">
    <div class="main_header_style">
      <h4>{{ $t("edit_provider") }}</h4>
    </div>
    <form class="form_topic" @submit.prevent="editProvider()">
      <div class="row">
        <!-- <ul class="types">
          <li>
            <div class="form-group service_type">
              <input
                type="radio"
                name="service_type"
                v-model="service_type"
                class="wizard-required"
                id="defaultHotel"
                value="1"
                required
                hidden
              />
              <label for="defaultHotel"
                ><i class="fa-solid fa-bell-concierge"></i>
                {{ $t("hotels") }}</label
              >
            </div>
          </li>
          <li>
            <div class="form-group service_type">
              <input
                type="radio"
                name="service_type"
                v-model="service_type"
                class="wizard-required"
                id="defaultFlight"
                value="2"
                required
                hidden
              />
              <label for="defaultFlight"
                ><i class="fa-solid fa-plane"></i> {{ $t("flight") }}</label
              >
            </div>
          </li>
          <li>
            <div class="form-group service_type">
              <input
                type="radio"
                name="service_type"
                v-model="service_type"
                class="wizard-required"
                id="defaultTransportation"
                value="3"
                required
                hidden
              />
              <label for="defaultTransportation"
                ><i class="fa-solid fa-bus"></i>
                {{ $t("transportation") }}</label
              >
            </div>
          </li>
          <li>
            <div class="form-group service_type">
              <input
                type="radio"
                name="service_type"
                v-model="service_type"
                class="wizard-required"
                id="defaultPrivateTrips"
                value="4"
                required
                hidden
              />
              <label for="defaultPrivateTrips"
                ><i class="fa-solid fa-tree"></i>
                {{ $t("private_trips") }}</label
              >
            </div>
          </li>
        </ul> -->

        <ul class="types">
          <li v-for="(type, index) in service_types" :key="index">
            <div class="form-group service_type">
              <input
                type="checkbox"
                name="service_type"
                v-model="type.selected"
                class="wizard-required"
                :id="'default' + type.value"
                :value="type.value"
              />
              <label :for="'default' + type.value">
                <i class="fa-solid" :class="getTypeIcon(type.value)"></i>
                {{ $t(getTypeName(type.value)) }}
              </label>
            </div>
          </li>
        </ul>
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("provider_name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('provider_name')"
              v-model="name"
              required
            />
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("provider_description") }}</label>
            <textarea
              class="form-control"
              :placeholder="$t('provider_description')"
              v-model="text"
            ></textarea>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("edit") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "add-provider",
  data() {
    return {
      service_types: [
        { value: 1, selected: false },
        { value: 2, selected: false },
        { value: 3, selected: false },
        { value: 4, selected: false },
      ],
      name: "",
      text: "",
      service_type: [],
    };
  },
  methods: {
    //fetch provider data
    fetchProviderData() {
      let id = this.$route.params.id;
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/providers/" + id).then(({ data }) => {
        this.name = data.data.name;
        this.text = data.data.text;
        // Update the service_type array with fetched data
        this.service_type = data.data.service_type;

        // Extract the fetched service type IDs from the fetched data
        const fetchedServiceTypeIds = this.service_type.map(
          (service) => service.service_type_id
        );

        // Update the selected property in the service_types array
        this.service_types.forEach((type) => {
          type.selected = fetchedServiceTypeIds.includes(type.value);
        });
      });
    },

    // define service type
    getTypeIcon(value) {
      // Define mappings of value to icon classes
      const iconMap = {
        1: "fa-bell-concierge",
        2: "fa-plane",
        3: "fa-bus",
        4: "fa-tree",
        // ... add more mappings as needed ...
      };
      return `fa-solid ${iconMap[value]}`;
    },
    getTypeName(value) {
      // Define mappings of value to type names
      const nameMap = {
        1: "hotels",
        2: "flight",
        3: "transportation",
        4: "private_trips",
        // ... add more mappings as needed ...
      };
      return nameMap[value];
    },
    //update provider data
    async editProvider() {
      let id = this.$route.params.id;
      const params = new URLSearchParams();

      params.append("name", this.name);
      params.append("text", this.text);
      const selectedServiceTypes = this.service_types
        .filter((type) => type.selected)
        .map((type) => type.value);

      // Append the array of selected service type values to formData
      selectedServiceTypes.forEach((value) => {
        params.append("service_type_id[]", value);
      });

      axios
        .put(`/providers/${id}`, params)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/provider");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    this.fetchProviderData();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "./_provider.scss";
</style>
