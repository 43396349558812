<template>
  <section class="card_style">
    <div class="main_header_style">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4 class="mb-0">
          {{ $t("Add New Bank Account") }}
        </h4>
      </div>
    </div>
    <form class="py-3" @submit.prevent="addAccount()">
      <div class="row">
        <div class="col-12 mb-4 account-status">
          <h4>{{ $t("Account Status") }}</h4>
          <form class="row">
            <div class="form-check col-3 d-flex align-items-center">
              <input
                class="form-check-input mx-3"
                type="radio"
                name="status"
                v-model="active"
                value="1"
                checked
                id="active"
              />
              <label class="form-check-label mb-0" for="active">
                {{ $t("Active") }}
              </label>
            </div>
            <div class="form-check col-3 d-flex align-items-center">
              <input
                class="form-check-input mx-3"
                type="radio"
                name="status"
                v-model="active"
                value="0"
                id="not-active"
              />
              <label class="form-check-label mb-0" for="not-active">
                {{ $t("Not Active") }}
              </label>
            </div>
          </form>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-label"> {{ $t("Bank Account Name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('Write Bank Account Name')"
              v-model="accountName"
              required
            />
            <span class="input-errors" v-if="v$.accountName.$error">{{v$.accountName.$errors[0].$message}}</span>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-label"> {{ $t("Bank Account Number") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('Write Bank Account Number')"
              v-model="accountNumber"
              required
            />
            <span class="input-errors" v-if="v$.accountNumber.$error">{{v$.accountNumber.$errors[0].$message}}</span>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-label">
              {{ $t("Bank Account Description") }}</label
            >
            <textarea
              type="text"
              class="form-control"
              :placeholder="$t('Write Bank Account description')"
              v-model="text"
              required
            ></textarea>
            <span class="input-errors" v-if="v$.text.$error">{{v$.text.$errors[0].$message}}</span>
          </div>
        </div>

        <div class="col-12 col-lg-3">
          <div class="form-group">
            <label class="form-label">Swift Code</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('Write') + ' Swift Code'"
              v-model="swiftCode"
              required
            />
            <span class="input-errors" v-if="v$.swiftCode.$error">{{v$.swiftCode.$errors[0].$message}}</span>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="form-group">
            <label class="form-label">IBAN CODE</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('Write') + ' IBAN CODE'"
              v-model="ibanCode"
              required
            />
            <span class="input-errors" v-if="v$.ibanCode.$error">{{v$.ibanCode.$errors[0].$message}}</span>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-check">
            <input class="form-check-input" type="radio" value="1" id="online_bank" v-model="online_bank">
            <label class="form-check-label" for="online_bank">
              {{$t("online_bank")}}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="0" id="offline_bank" v-model="online_bank">
            <label class="form-check-label" for="offline_bank">
              {{$t("offline_bank")}}
            </label>
          </div>
          <span class="input-errors" v-if="v$.online_bank.$error">{{v$.online_bank.$errors[0].$message}}</span>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-label"> {{ $t("Initial Balance") }}</label>
            <input
              type="number"
              class="form-control initial-balance"
              :placeholder="$t('Initial Balance')"
              v-model="initialBalance"
              required
            />
          </div>
        </div>

        <div class="col-6">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("Save") }}
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import Swal from "sweetalert2";
import useVuelidate from '@vuelidate/core';
import { required , maxLength , minLength  } from '@vuelidate/validators';

export default {
  name: "AddAccount",
  data() {
    return {
      v$: useVuelidate(),
      accountName: "",
      accountNumber: "",
      swiftCode: "",
      ibanCode: "",
      active: 1,
      initialBalance: 0,
      text: "",
      type: 1,
      online_bank: 1,
    };
  },
  validations(){
    return{
      accountName: { required },
      accountNumber: { required: required , maxLength: maxLength(20) , minLength: minLength(10) },
      swiftCode: { required },
      ibanCode: { required },
      text: { required },
      type: { required },
      online_bank: { required },
    }
  },
  methods: {
    async addAccount() {
      this.v$.$validate();
      if(!this.v$.$error){
        const formData = new FormData();
        formData.append("title", this.accountName);
        formData.append("account_number", this.accountNumber);
        formData.append("swift_code", this.swiftCode);
        formData.append("iban", this.ibanCode);
        formData.append("active", this.active);
        formData.append("money", this.initialBalance);
        formData.append("text", this.text);
        formData.append("type", this.type);
        formData.append("is_online", this.online_bank);
        axios
          .post("/organization_accounts", formData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.$router.push("/account");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },
  components: { AddFavDailog },
};
</script>

<style lang="scss" scoped>
@import "./_accounts";
</style>
