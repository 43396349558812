<template>
  <div
    title="whatsapp"
    class="btn SecondButton fs-12 d-flex align-items-center justify-content-center"
    @click="visible = true"
  >
    <i class="fa-brands fa-whatsapp"></i>
  </div>

  <Dialog v-model:visible="visible" modal class="w-50" :dismissableMask="true">
    <h4 class="my-2">{{ $t("send_whatsapp") }}</h4>
    <form @submit.prevent="onSubmit()">
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">
          {{ $t("phone") }}
        </label>
        <input
          type="text"
          class="form-control"
          id="exampleFormControlInput1"
          v-model="phone"
          :placeholder="$t('phone')"
          disabled
        />
      </div>
      <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label">{{
          $t("description")
        }}</label>
        <Editor
          class="form-control"
          :placeholder="$t('description')"
          id="exampleFormControlTextarea2"
          rows="5"
          v-model="text"
        />
      </div>
      <button type="submit" class="btn SecondButton">{{ $t("send") }}</button>
    </form>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import axios from "axios";
import Swal from "sweetalert2";

import Editor from "primevue/editor";

export default {
  data() {
    return {
      visible: false,
      phone: "",
      organization_name: JSON.parse(localStorage.getItem("user"))
        .organization_name,
      text: "",
    };
  },
  props: [
    "guest_name",
    "rooms",
    "total_cost_price_with_out_tax",
    "total_room_cost_with_tax",
    "clientEmail",
    "reservation_number",
    "tax",
    "clientPhone",
  ],

  components: {
    Dialog,
    Editor,
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        // Set the phone value to the clientPhone prop when the dialog is opened
        this.phone = this.clientPhone;
        console.log(
          this.organization_name,
          this.guest_name,
          this.rooms,
          this.total_cost_price_with_out_tax,
          this.total_room_cost_with_tax,
          this.clientEmail,
          this.reservation_number,
          this.tax,
          this.clientPhone,
        );
        // Set the text value to the initialText prop when the dialog is opened
        this.text = this.generateEmailBody();
      }
    },
  },
  methods: {
    resetForm() {
      this.text = "";
    },
    generateEmailBody() {
      console.log(this.rooms); // Add this line to inspect the rooms array
      let roomsList = this.rooms
        .map((room, index) => {
          if (!room.room) {
            console.warn(
              `Room ${index + 1} is missing the 'room' property.`,
              room,
            );
            return `<p>Room ${index + 1} is missing details.</p>`;
          }
          return `<p>Room ${index + 1}:<br>Hotel Title: ${
            room.hotel_title
          }<br>Room Title: ${room.room.title}</p>`;
        })
        .join("");

      return `<p>Dear: ${this.guest_name},</p>

          <p>Greeting From ${this.organization_name},</p>

          <p>First of all, we would like to take this opportunity to welcome you at ${
            this.organization_name
          } and tourism. We are pleased to confirm the following reservation on a ${
            this.invoice?.title || "Unknown"
          } basis.</p>

          <p>Reservation Number: # ${this.reservation_number}</p>
          <p>Rooms:</p>
          ${roomsList}
          <p>Total Cost Without Tax: ${Math.round(this.total_cost_price_with_out_tax)}</p>
          <p>Total Tax: ${Math.round(this.tax)}</p>
          <p>Total Cost With Tax: ${Math.round(this.total_room_cost_with_tax)}</p>

          <p>Best Regards,<br>
          ${this.organization_name} Team</p>`;
    },

    onSubmit() {
      const params = {
        phone: this.phone,
        text: this.text,
      };

      axios
        .get("whatsapp/send_message", { params })
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.visible = !this.visible;
          this.resetForm();
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.visible = !this.visible;
          this.resetForm();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.SecondButton {
  background-color: transparent;
  color: #0a909b;
  border: 1px solid #0a909b;
  font-size: 1.2rem;
  margin: 0 5px;
  font-family: "bold";
  padding: 0.6rem 1.1rem;
  transition: 0.7s ease-in-out;

  &:hover {
    background-color: #0a909b;
    color: #ffff;
    border: 1px solid #ffff;
  }
}
</style>
