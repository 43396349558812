<template>
  <section class="card_style">
    <div class="main_header">
        <h4>{{$t("payment visis")}}</h4>
    </div>

    <form>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="form-group">
                    <label class="form-label">{{$t("percentage")}}</label>
                    <input type="number" class="form-control" v-model="percentage" :placeholder="0" />
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-12">
                <div class="form-group">
                    <label class="form-label">{{$t("value")}}</label>
                    <input type="number" class="form-control" v-model="value" :placeholder="0" />
                </div>
            </div>

            <div class="col-12 d-flex align-items-end">
                <button type="submit" class="btn PrimaryButton">{{$t("submit")}}</button>
            </div>
        </div>
    </form>
  </section>
</template>

<script>
export default {
    data(){
        return{
            percentage: "",
            value: "",
        }
    },
    methods:{
        // submitData(){
        //     let data = {
        //         percentage: this.percentage,
        //         value: this.value
        //     }
        // }
    }
}
</script>

<style>

</style>