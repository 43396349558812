<template>
  <section class="card_style">
    <!-- skelton -->
    <div class="row" v-if="loading && $route.params.id">
      <div class="col-12">
        <Skeleton width="20%" class="mb-2"></Skeleton>
      </div>
      <div class="col-12">
        <Skeleton size="10rem"></Skeleton>
      </div>
      <div class="col-12 my-2" v-for="i in 3" :key="i">
        <Skeleton width="10%" class="mb-2"></Skeleton>
        <Skeleton width="70%" class="mb-2" height="2rem"></Skeleton>
      </div>
    </div>
    <!-- skelton -->
    <div class="row" v-else>
      <form @submit.prevent="addOpinion()">
        <div class="main_header_style">
          <h4>{{ $t("add opinion") }}</h4>
        </div>
        <div class="image_upload">
          <label for="imageEntry" class="upload_img" v-if="imageEntry == ''">
            <img :src="Form.image ? Form.image : defaultImage" alt="user" />
          </label>
          <img
            :src="imageEntry"
            v-else-if="imageEntry"
            class="entry_image"
            alt="entry"
          />
          <input
            type="file"
            name=""
            id="imageEntry"
            class="form-control"
            @change="onFileChange"
            hidden
            accept="image/png, image/gif, image/jpeg, image/jpg"
          />
        </div>
        <div
          class="col-lg-6 col-md-6 col-12"
          v-for="(title, index) in Form.titles"
          :key="index"
        >
          <div class="form-group">
            <label :for="'title_' + index"
              >{{ $t("name") }} - {{ title.locale }}</label
            >
            <input
              type="text"
              :name="'title_' + title.locale"
              :id="'title_' + index"
              class="form-control"
              :placeholder="$t('title')"
              v-model="title.title"
            />
          </div>
        </div>

        <button type="submit" class="add btn PrimaryButton">
          {{ $t("add") }}
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";

export default {
  name: "clientOpinionAdd",
  data() {
    return {
      options: {
        placeholder: this.$t("describtion"),
        theme: "snow",
      },
      Form: {
        // title_ar: "",
        title: {},
        titles: [],
        // title_en: "",
      },
      imageEntry: "",
      defaultImage: require("@/assets/media/image/user.png"),
      opinion_id: undefined,
      loading: true,
    };
  },

  methods: {
    showService() {
      this.opinion_id = this.$route.params.id;
      axios
        .post(`/website/show_client_advantage/${this.opinion_id}`)
        .then(({ data }) => {
          console.log(data.data);
          this.Form.titles = data.data.titles;
          console.log(this.Form.titles);
          this.Form.image = data.data.image;
          this.Form.title = {};
          this.loading = false;
        });
    },
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    //fetch country

    addOpinion() {
      let formData = new FormData();

      //   for (let locale in this.Form.titles) {
      //     formData.append(`title_${locale}`, this.Form.title[locale]);
      //   }

      this.Form.titles.forEach((title) => {
        formData.append(`title_${title.locale}`, title.title);
      });

      if (this.fileData instanceof File && this.fileData.size > 0) {
        formData.append("image", this.fileData);
      }

      axios
        .post("/website/client_advantage", formData)
        .then(({ data }) => {
          // console.log(data);
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/client-adventage");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },

  created() {
    this.showService();

    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
  components: {
    Skeleton,
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
</style>
