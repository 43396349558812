<template>
  <section class="home">
    <!-- charge wallet popup -->
    <CharegeWalletOffcanves @custom-event="fetchWalletsData()" />
    <!-- end charge wallet popup -->
    <header-home />
    <div class="reservation_wallet">
      <div class="row">
        <reservation-card
          :booking_offline_count="homeData.offline_booking_count"
          :booking_online_count="homeData.online_booking_count"
          :flight_offline_count="homeData.offline_flight_count"
          :flight_online_count="homeData.online_flight_count"
        />
        <div class="col-lg-5 col-md-12 col-12">
          <!-- <div
            class="total-balance d-flex align-items-center justify-content-between"
          >
            <img src="@/assets/media/image/Mask-group.png" />
            <img src="@/assets/media/image/Mask-group.png" />
            <img src="@/assets/media/image/Mask-group.png" />
            <div class="d-flex align-items-center gap-3">
              <div class="image">
                <img src="@/assets/media/image/wallet.png" alt="wallet" />
              </div>
              <div class="text">
                <p>{{ $t("the total current balance") }}</p>
                <p class="price">{{ homeData.total_price }} SAR</p>
              </div>
            </div>
            <div>
              <button
                class="charge btn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom"
                aria-controls="offcanvasBottom"
              >
                {{ $t("charge balance") }}
              </button>
            </div>
          </div> -->
          <!-- <div class="closed_balance">
            <div class="card_list">
              <div class="text">
                <h6>{{ $t("credit outstanding balance") }}</h6>
                <span>{{ homeData.to_pending_balance}}</span>
              </div>
              <div class="img">
                <img src="@/assets/media/image/export.png" alt="btn" />
              </div>
            </div>

            <div class="card_list">
              <div class="text">
                <h6>{{ $t("debit outstanding balance") }}</h6>
                <span>{{ homeData.for_pending_balance }}</span>
              </div>
              <div class="img">
                <img src="@/assets/media/image/import.png" alt="btn" />
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="third_section">
      <div class="row">
        <div class="col-lg-7 col-md-12 col-12">
          <div
            style="background-color: white; border-radius: 8px; padding: 24px"
          >
            <div class="main_header_style">
              <h4>{{ $t("reserve_rate") }}</h4>
              <div class="w-50 d-flex flex-row justify-content-end">
                <div class="dropdown">
                  <button
                    class="btn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ $t("reserve_rate") }}
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="this.reserve_rate = 'hotels'"
                        >{{ $t("hotels") }}</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="this.reserve_rate = 'flights'"
                        >{{ $t("flight") }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <RateChart :reserve_rate="reserve_rate"></RateChart>
          </div>
          <div
            style="
              background-color: white;
              border-radius: 8px;
              padding: 24px;
              margin-top: 24px;
            "
          >
            <div class="main_header_style">
              <h4>{{ $t("last_reservation") }}</h4>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ $t("last_reservation") }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="last_reservation_data('hotel')"
                      >{{ $t("hotels") }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="last_reservation_data('flight')"
                      >{{ $t("flight") }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table home" id="safeTable">
                <thead>
                  <tr>
                    <th>{{ $t("booking number") }}</th>
                    <th>{{ $t("employee name") }}</th>
                    <th>{{ $t("Hotel") }}</th>
                    <th>{{ $t("Number Of Nights") }}</th>
                    <th>{{ $t("number of rooms") }}</th>
                    <th>{{ $t("price") }}</th>
                    <th>{{ $t("status") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(book, index) in last_reservation" :key="index">
                    <td>
                      <router-link
                        :to="`/book_table/edit/${book.id}`"
                        v-if="book.booking_type == 'offline'"
                        >{{ book.booking_number }}</router-link
                      >
                      <router-link :to="`/hotel-view/${book.id}`" v-else>{{
                        book.booking_number
                      }}</router-link>
                    </td>
                    <td>
                      <span>{{ book.booking_by }}</span>
                    </td>
                    <td>{{ book.hotel_name }}</td>
                    <td>{{ book.number_of_nights }}</td>
                    <td>{{ book.rooms_number }}</td>
                    <td>
                      {{
                        book.booking_type == "online"
                          ? (
                              book.total_selling_price + book.selling_price_tax
                            ).toFixed(2)
                          : book.total_selling_price.toFixed(2)
                      }}
                    </td>
                    <td>
                      <span
                        class="status"
                        :class="{
                          accept:
                            book.status == 1 || book.booking_type == 'online',
                          pending: book.status == 0,
                          decline: book.status == 2,
                        }"
                      >
                        {{
                          book.booking_type == "online"
                            ? $t("Accept")
                            : book.status == 0
                            ? $t("Pending")
                            : book.status == 1
                            ? $t("Accept")
                            : $t("Decline")
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-12">
          <!-- <div
            style="background-color: white; border-radius: 8px; padding: 24px"
          >
            <table class="table" id="safeTable">
              <thead>
                <tr>
                  <th>{{ $t("the amount") }}</th>
                  <th>{{ $t("the date of application") }}</th>
                  <th>{{ $t("Status") }}</th>
                  <th>{{ $t("application number") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(payment, index) in homeData.last_payment" :key="index">
                  <td>{{ payment.amount }}</td>
                  <td>{{ formatDate(payment.created_at) }}</td>
                  <td>
                    <span
                      class="status"
                      >{{
                        payment.status == 0
                          ? $t("new")
                          : payment.status == 1
                          ? $t("Accept")
                          : ""
                      }}</span
                    >
                  </td>
                  <td>{{payment.bookingable_id}}</td>
                </tr>
              </tbody>
            </table>
          </div> -->

          <div
            style="
              background-color: white;
              border-radius: 8px;
              padding: 24px;
            "
          >
            <div class="main_header_style">
              <h4>{{ $t("clerck_most_reserved") }}</h4>
              <router-link to="#">{{ $t("show_all") }} </router-link>
            </div>
            <div>
              <clerks-chart :chart-data="clerkData"></clerks-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from "axios";
  import ClerksChart from "@/components/home/chartClerck.vue";
  import RateChart from "@/components/home/chartRate.vue";
  import headerHome from "@/components/home/header.vue";
  import reservationCard from "@/components/home/reservationCard.vue";
  import CharegeWalletOffcanves from "@/components/wallet/CharegeWalletOffcanves.vue";
  import moment from "moment";

  export default {
    name: "home-page",
    components: {
      ClerksChart,
      RateChart,
      headerHome,
      reservationCard,
      CharegeWalletOffcanves,
    },
    data() {
      return {
        homeData: {},
        reserve_rate: "",
        last_reservation: [],

        wallets: [],
        hotel_booking: {},
        trip_booking: {},
        transport_booking: {},
        flight_booking: {},
      };
    },

    methods: {
      fetchWalletsData(page = 1) {
        axios.get(`/fetch_wallet_history?page=${page}`).then(({ data }) => {
          this.wallets = data.data;
          // console.log(this.wallets)
        });
      },
      // fetch details ORGANIZATION data
      async fetchdetailsOrganization() {
        let id = this.$route.params.id;
        const formData = new FormData();
        formData.append("organization_id", id);
        await axios
          .post("/fetch_organization_customization_details", formData)
          .then(({ data }) => {
            this.details = data.data;
            this.trip_booking = data.data.trip_booking;
            this.transport_booking = data.data.transport_booking;
            this.flight_booking = data.data.flight_booking;
            this.hotel_booking = data.data.hotel_booking;

            //  console.log(this.details.hotel_booking.complete_booking);
          });
      },
      async fetch_home_data() {
        await axios.get("/fetch_home_data").then(({ data }) => {
          // console.log(data.data);
          this.homeData = data.data;
          this.last_reservation = this.homeData.last_bookings;
          // console.log(this.homeData.last_bookings)
          // console.log(typeof this.homeData)
        });
      },
      last_reservation_data(type) {
        if (type == "hotel") {
          this.last_reservation = this.homeData.last_bookings;
        } else {
          this.last_reservation = this.homeData.last_flights;
        }
      },
      formatDate(value) {
        return moment(value).format("YYYY-MM-DD");
      },
    },
    created() {
      this.fetchWalletsData();
      this.fetch_home_data();
      localStorage.removeItem("flightData");
      localStorage.removeItem("flightTrips");
      localStorage.removeItem("bookDataFlight");
      localStorage.removeItem("bookDetails");
      localStorage.removeItem("hotelData");
      localStorage.removeItem("bookData");
    },
    mounted() {
      // this.renderChart(this.data, {
      //   responsive: true,
      //   maintainAspectRatio: false,
      // });
    },
  };
</script>

<style lang="scss">
  @import "@/assets/scss/variables.scss";
  .home {
    display: block;
    height: 75vh;
    padding: 0;
    //   img {
    //     display: block;
    //     margin: 0 auto;
    //   }
    .header_dashboard {
      padding: 24px 0;
      h2 {
        color: #0b1427;
        font-size: 1.9rem;
        font-family: "bold";
      }
      p {
        color: #656b78;
        font-size: 1rem;
        font-family: "light";
      }
      .collection_btn {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        .btn-main {
          background-color: white;
          color: #656b78;
          font-size: 1rem;
          font-family: "regular";
          border: 1px solid transparent;
          border-radius: 8px;
          padding: 15px 9px;
          width: 24%;
          margin-inline-end: 1%;
          &:last-child {
            margin-inline-end: 0;
          }
        }
      }
    }
    .card_lists {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .card_list {
        width: 46%;
        background-color: rgba($color: #0a909b, $alpha: 0.06);
        border-radius: 8px;
        margin-inline-end: 20px;
        margin-bottom: 20px;
        padding: 20px;
        display: flex;
        justify-content: space-between;

        .text {
          h6 {
            color: #656b78;
            font-size: 1rem;
            font-family: "regular";
          }
          span {
            color: #0b1427;
            font-size: 1.2rem;
            font-family: "bold";
          }
        }
        .img {
          width: 52px;
          height: 52px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .total-balance {
      background-color: #0b1427;
      color: white;
      padding: 9px 20px;
      height: 120px;
      border-radius: 8px;
      flex-wrap: wrap;
      position: relative;

      .total-balance > div {
        position: relative;
        z-index: 2222;
      }

      .text {
        p {
          margin-bottom: 8px;

          &.price {
            font-size: 24px;
            font-weight: 700;
          }
        }
      }

      .charge {
        background-color: #fff;
        border: 2px solid #0b1427;
        color: #0b1427;
        margin-inline-end: 15px;
      }

      .transfer {
        background-color: #0b1427;
        border: 2px solid #fff;
        color: #fff;
      }

      img:nth-child(1) {
        position: absolute;
        top: -55px;
        right: 0px;
      }

      img:nth-child(2) {
        position: absolute;
        top: 67%;
        right: 40%;
        transform: rotate(180deg);
      }

      img:nth-child(3) {
        position: absolute;
        left: -53px;
        transform: rotate(-90deg);
      }

      .image {
        width: 62px;
        height: 62px;
        border-radius: 50%;
        background-color: #3c4352;
        margin-inline-end: 15px;
        text-align: center;
        line-height: 62px;
        position: relative;

        img {
          position: absolute;
          top: 0.5rem;
          right: 0.6rem;
        }
      }
    }
    .closed_balance {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 20px;
      .card_list {
        width: 49%;
        background-color: rgba($color: #0a909b, $alpha: 0.06);
        border-radius: 8px;
        margin-inline-end: 2%;
        margin-bottom: 20px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        .text {
          h6 {
            color: #656b78;
            font-size: 1rem;
            font-family: "regular";
          }
          span {
            color: #0b1427;
            font-size: 1.2rem;
            font-family: "bold";
          }
        }
        .img {
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &:last-child {
          margin-inline-end: 0;
        }
      }
    }

    .main_header_style {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 30px;
      // margin-top: 20px;

      h4 {
        font-size: 1.5rem;
        color: $PrimaryText;
        font-family: "bold";
        margin-bottom: 1.25rem;
      }
      a {
        color: #0a909b;
        font-family: "light";
        font-size: 0.9rem;
      }
    }
    .dropdown {
      border: 1px solid #9ca2ac;
      border-radius: 4px;
      font-family: "light";
      margin-inline-start: 1rem;
    }
  }
  #myChart,
  #myChart1 {
    width: 100% !important;
    height: 100%;
  }
</style>
