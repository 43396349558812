<template>
  <div class="data_invoice_person">
    <div class="list_account">
      <div class="account_data">
        <p class="title_account">Client name</p>
        <p class="text_account">: {{ pills.client_name }}</p>
      </div>
      <div class="account_data" v-if="pills?.client_tax_number">
        <p class="title_account">Client Tax Number</p>
        <p class="text_account">: {{ pills.client_tax_number }}</p>
      </div>
      <div class="account_data">
        <p class="title_account">Phone</p>
        <a :href="`tel:${pills.client_phone}`" class="text_account"
          >: {{ pills.client_phone }}</a
        >
      </div>
      <div class="account_data">
        <p class="title_account">Email</p>
        <a :href="`mailto:${pills.client_email}`" class="text_account"
          >: {{ pills.client_email }}</a
        >
      </div>
      <div class="account_data">
        <p class="title_account">Guest Name</p>
        <p class="text_account">: {{ pills.guest_name }}</p>
      </div>
      <div class="account_data">
        <p class="title_account">Nationality</p>
        <p class="text_account">: {{ pills.nationality_name }}</p>
      </div>
    </div>
    <div class="list_account">
      <div class="account_data" v-if="pills.client_country">
        <p class="title_account">Client Address</p>
        <p class="text_account">
          : {{ pills.client_country }} - {{ pills.client_address }}
        </p>
      </div>
      <div
        class="account_data"
        v-for="(hotel, index) in pills.hotels"
        :key="index"
      >
        <p class="title_account">Hotel Name {{ index + 1 }}</p>
        <p class="text_account hotel_name">: {{ hotel.hotel_name }}</p>
      </div>
      <div class="account_data">
        <p class="title_account">Check In</p>
        <p class="text_account">
          : {{ pills.check_in_date }},
          {{
            updateHijriDate(
              pills?.check_in_date
                ? new Date(pills?.check_in_date)
                : new Date(),
            )
          }}
        </p>
      </div>
      <div class="account_data">
        <p class="title_account">Check Out</p>
        <p class="text_account">
          : {{ pills.check_out_date }},
          {{
            updateHijriDate(
              pills?.check_out_date
                ? new Date(pills?.check_out_date)
                : new Date(),
            )
          }}
        </p>
      </div>
    </div>
  </div>
  <!-- client reservation data -->
</template>

<script>
export default {
  props: {
    pills: Object,
  },
};
</script>

<style lang="scss" scoped>
@import "../_pill.scss";
</style>
