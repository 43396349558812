<template>
  <section class="card_style">
    <div class="header-name">
      <h3>آنشئ البرنامج</h3>
    </div>
    <div class="main-sec">
      <div class="sec-one">
        <div class="row">
          <div class="col-lg-2 col-md-12 col-12">
            <div class="form-group name">
              <label class="name-travel" for="">اسم الرحلة</label>
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="col-lg-2 col-md-12 col-12">
            <div class="form-group type-program">
              <label for="">{{ $t("Program type") }}</label>
              <!-- <select class="form-select starts" aria-label="Default select example">
            <option selected>5</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select> -->
              <Multiselect
                v-model="stars"
                :options="tests.map((test) => test.id)"
                :custom-label="(opt) => tests.find((x) => x.id == opt).title"
                :clear-on-select="true"
                :placeholder="$t('Umrah')"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
            </div>
          </div>
          <div class="col-lg-2 col-md-12 col-12">
            <div class="form-group type-program">
              <label for="">{{ $t("stars") }}</label>
              <!-- <select class="form-select starts" aria-label="Default select example">
            <option selected>5</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select> -->
              <Multiselect
                v-model="stars"
                :options="tests.map((test) => test.id)"
                :custom-label="(opt) => tests.find((x) => x.id == opt).title"
                :clear-on-select="true"
                :placeholder="$t('stars')"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
            </div>
          </div>
          <div class="col-lg-2 col-md-12 col-12">
            <div class="form-group">
              <label for="">المدة/ يوم</label>
              <input type="number" class="form-control" />
            </div>
          </div>
          <div class="col-lg-2 col-md-12 col-12">
            <div class="form-group">
              <label for="">تاريخ البداية</label>
              <Datepicker
                v-model="dateValue"
                lang="ar"
                :placeholder="$t('date')"
              />
            </div>
          </div>
          <div class="col-lg-2 col-md-12 col-12">
            <div class="form-group">
              <label for="">تاريخ النهاية</label>
              <Datepicker
                v-model="dateValue"
                lang="ar"
                :placeholder="$t('date')"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-12 col-12">
            <div class="form-group">
              <label for="exampleFormControlTextarea1" class="form-label"
                >وصف الرحلة</label
              >
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="2"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import Multiselect from "vue-multiselect";
  import "vue-datepicker-ui/lib/vuedatepickerui.css";
  import VueDatepickerUi from "vue-datepicker-ui";

  export default {
    name: "add-umrah",
    components: {
      Multiselect,
      Datepicker: VueDatepickerUi,
    },
    data() {
      return {
        tests: [
          {
            id: 1,
            title: "test",
          },
          {
            id: 2,
            title: "test2",
          },
          {
            id: 3,
            title: "test3",
          },
        ],
        stars: "",
        dateValue: "",
      };
    },
  };
</script>
<style lang="scss" scoped>
  @import "./_umrah.scss";
</style>
