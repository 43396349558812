<template>
  <section class="auth">
    <div class="container">
      <form class="verification-code" @submit.prevent="verification_code">
        <h3>{{ $t("return_code") }}</h3>
        <h6>{{ $t("enter_verfication_code") }}</h6>
        <div class="form-group">
          <input
            type="number"
            class="form-control"
            v-model="Form.verification_code"
            required
          />
        </div>
        <button type="submit" class="btn submit_btn">{{ $t("send") }}</button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "forget-page",
  data() {
    return {
      Form: {
        email: localStorage.getItem("email"),
        verification_code: "",
      },
    };
  },
  methods: {
    verification_code() {
      axios
        .post("/check_verification_code", this.Form)
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: data.message,
              timer: 1500,
            });
            localStorage.setItem("email", this.Form.email);
            this.$router.push("/reset_password");
          }
          if (data.status == false) {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: data.message,
              timer: 1500,
            });
          }
          // console.log(data.message)
        })
        .catch((error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: error.response.data.message,
            timer: 1500,
          });
          return error;
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
  created() {
    if (this.Form.email == null) {
      this.$router.push("/forget")
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./_auth.scss";
</style>
