<template>
  <section class="card_style">
    <div class="w-100" v-if="loading && $route.params.id">
      <Skeleton width="20%" class="mb-2"></Skeleton>
      <div class="row mt-4">
        <div class="col-lg-12">
          <Skeleton width="20%" class="mb-2"></Skeleton>
          <Skeleton width="60%" height="2rem"></Skeleton>
        </div>
        <div class="col-lg-6 my-3" v-for="i in 4" :key="i">
          <Skeleton width="20%" class="mb-2"></Skeleton>
          <Skeleton width="60%" height="2rem"></Skeleton>
        </div>
      </div>
    </div>
    <form class="form_topic" @submit.prevent="addEmploy()" v-else>
      <div class="main_header_style">
        <h4>{{ $t("edit_empoly") }}</h4>
      </div>
      <div class="row">
        <!-- start input group -->
        <div class="col-lg-3 col-md-6 col-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("empoly_name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('empoly_name')"
              v-model="name"
            />
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-3 col-md-6 col-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("phone") }}</label>
            <input
              type="tel"
              class="form-control xyz"
              :placeholder="$t('enter_phone')"
              v-model="phone"
            />
            <span class="icon_form">
              <i class="fas fa-phone"></i>
            </span>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-3 col-md-6 col-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("email") }}</label>
            <input
              type="email"
              class="form-control xyz"
              :placeholder="$t('enter_email')"
              v-model="email"
            />
            <span class="icon_form">
              <i class="fa-regular fa-envelope"></i>
            </span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->

        <!-- start input group -->
        <div class="col-lg-3 col-md-6 col-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("the_salary") }}</label>
            <input
              type="number"
              class="form-control xyz"
              :placeholder="$t('the_salary')"
              min="0"
              v-model="salary"
            />
            <span class="icon_form">
              <i class="fa-solid fa-money-bill"></i>
            </span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-3 col-md-6 col-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("password") }}</label>
            <input
              type="password"
              class="form-control xyz"
              :placeholder="$t('password')"
              v-model="password"
            />
            <span class="icon_form">
              <i class="fa-solid fa-lock"></i>
            </span>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-12">
          <label class="form-label"> {{ $t("roles name") }}</label>
          <Multiselect
            v-model="role"
            :options="roles"
            :clear-on-select="true"
            :placeholder="$t('roles name')"
            label="name"
            track-by="id"
            select-label=""
            :hide-selected="true"
            @search-change="fetchRoels"
          >
          </Multiselect>
        </div>
        <!-- finish input group -->

        <div class="col-lg-3 col-md-6 col-12">
          <label class="form-label"> {{ $t("hotels") }}</label>
          <div class="d-flex align-items-center fs-8">
            <span>{{ $t("all_hotels") }}</span>
            <InputSwitch v-model="checkedHotels" class="mx-2" />
            <span>{{ $t("specific_hotels") }}</span>
          </div>
        </div>

        <div
          class="col-lg-3 col-md-6 col-12"
          v-if="checkedHotels == false && checkedHotels == 0"
        >
          <label class="form-label"> {{ $t("hotels") }}</label>
          <Multiselect
            v-model="hotelSelect"
            :options="hotels"
            :clear-on-select="true"
            :placeholder="$t('hotels')"
            label="title"
            track-by="id"
            select-label=""
            :multiple="true"
            :hide-selected="true"
            @keyup="fetchHotel"
          >
          </Multiselect>
        </div>

        <div class="col-lg-3 col-md-6 col-12">
          <div class="form-check d-flex flex-column-reverse">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              v-model="is_master"
              id="master"
            />
            <label class="form-check-label" for="master">
              {{ $t("employee_master") }}
            </label>
          </div>
        </div>

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("edit") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Skeleton from "primevue/skeleton";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import InputSwitch from "primevue/inputswitch";

export default {
  name: "add-employment",
  data() {
    return {
      id: "",
      name: "",
      phone: "",
      email: "",
      password: "",
      salary: "",
      loading: true,
      roles: [],
      role: "",
      checkedHotels: true,
      hotelSelect: [],
      hotels: [],
      is_master: 0,
    };
  },
  methods: {
    fetchEmployData() {
      let id = this.$route.params.id;
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/organization_employees/" + id).then(({ data }) => {
        this.name = data.data.name;
        this.phone = data.data.phone;
        this.is_master = data.data.is_master;
        this.email = data.data.email;
        this.salary = data.data.salary;
        this.role = data.data.Permission_role;
        this.checkedHotels = data.data.allow_all_hotels;
        this.hotelSelect = data.data.employee_hotels;
        // console.log(data.data);
        this.loading = false;
      });
    },
    fetchRoels(search) {
      axios
        .post("/index_permissions_role", { word: search, per_page: 10 })
        .then((data) => {
          this.roles = data.data.data.data;
          // console.log(data.data.data.data);
        });
    },
    async addEmploy() {
      let id = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("name", this.name);
      params.append("phone", this.phone);
      params.append("email", this.email);
      params.append("salary", this.salary);
      params.append("password", this.password);
      params.append("is_master", this.is_master == true ? 1 : 0);
      params.append("permission_role_id", this.role.id);
      params.append("allow_all_hotels", this.checkedHotels == true ? 1 : 0);
      this.hotelSelect.forEach((ele, index) => {
        params.append(`hotel_ids[${index}]`, ele.id);
      });
      axios
        .put(`/organization_employees/${id}`, params)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/employees");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },

    fetchHotel(event) {
      const searchText = { word: event?.target?.value };
      axios.post(`/fetch_new_home_hotels`, searchText).then(({ data }) => {
        this.hotels = data.data.hotels;
      });
    },
  },
  created() {
    this.fetchEmployData();
    this.fetchRoels();
    this.fetchHotel();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
  components: {
    Skeleton,
    Multiselect,
    InputSwitch,
  },
};
</script>
<style lang="scss" scoped>
@import "./_employment";
</style>
