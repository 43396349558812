<template>
  <div>
    <div class="fav d-flex align-items-center py-2 px-3">
      <div
        class="route d-flex align-items-center"
        v-for="fav in favorites"
        :key="fav.id"
      >
        <i class="fa-solid fa-route"></i>
        <router-link :to="`${fav.path}`" class="">{{ fav.name }}</router-link>
        <i
          class="fa-solid fa-trash text-danger"
          @click="deleteRoute(fav.id)"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
// import { Breadcrumb } from "primevue/breadcrumb";
// import Dialog from "primevue/dialog";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "fav-layout",
  data() {
    return {
      visible: false,
      favorites: [],
      routeChange: this.$route.path,
    };
  },
  watch: {
    routeChange() {
      this.fetchAllFavorites();
    },
  },
  components: {
    // Breadcrumb,
    // Dialog,
  },
  methods: {
    deleteRoute(id) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`/delete_favorite_route/${id}`)
            .then((data) => {
              this.fetchAllFavorites();
              Swal.fire({
                icon: "success",
                title: this.$t("deleted"),
                text: data.data.message,
                timer: 1500,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    async fetchAllFavorites() {
      axios
        .get("/all_favorite_route")
        .then((data) => {
          this.favorites = data.data.data;
          //   console.log(this.favorites);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  async mounted() {
    await this.fetchAllFavorites();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.fav {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid $SecondColor;
  gap: 10px;
  overflow-x: auto;
  transition: 0.4s ease-in-out;
  .icons {
    font-size: 0.85rem;
    color: $SecondColor;
    cursor: pointer;
    span {
      font-weight: bold;
    }
  }
  .route {
    color: $SecondColor;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid $SecondColor;
    padding: 5px;
    gap: 10px;
    &:hover,
    &:hover a {
      background-color: $SecondColor;
      color: #fff;
      border-color: $SecondColor;
    }
    a {
      color: $SecondColor;
      width: max-content;
    }
    i.fa-trash {
      cursor: pointer;
      transition: 0.4s ease-in-out;
      &:hover {
        scale: 1.2;
      }
    }
  }
}
.fav::-webkit-scrollbar {
  display: none;
  width: 10px;
  height: 11px;
}

/* Track */
.fav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $SecondColor;
  border-radius: 10px;
}
.fav {
  &:hover::-webkit-scrollbar {
    display: block;
    width: 10px;
    height: 11px;
  }
}

/* Handle */
.fav:-webkit-scrollbar-thumb {
  background: $PrimaryColor;
  border-radius: 10px;
}

/* Handle on hover */
.fav::-webkit-scrollbar-thumb:hover {
  background: $PrimaryColor;
}
</style>
