<template>
  <section class="card_style">
    <div class="w-100" v-if="loading">
      <Skeleton width="5rem" class="mb-2"></Skeleton>
      <div class="d-flex align-items-center gap-3 w-100">
        <div class="d-flex flex-column gap-2 w-50 my-2">
          <Skeleton width="4%"></Skeleton>
          <Skeleton height="2rem" class="mb-2"></Skeleton>
        </div>
        <div class="d-flex flex-column gap-2 w-50 my-2">
          <Skeleton width="4%"></Skeleton>
          <Skeleton height="2rem" class="mb-2"></Skeleton>
        </div>
      </div>
      <div class="d-flex flex-column gap-2 w-50 my-2">
        <Skeleton width="4%"></Skeleton>
        <Skeleton height="2rem" class="mb-2"></Skeleton>
      </div>
    </div>

    <form class="form_topic" @submit.prevent="addSeason()" v-else>
      <div class="main_header_style">
        <h4>{{ $t("edit_season") }}</h4>
      </div>
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("season_name") }}</label>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("season_name") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(season, index) in season_name"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("season_name") }} ({{ season.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="season.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.season_name.$error">{{
              v$.season_name.$errors[0].$message
            }}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("period_from") }}</label>
            <Datepicker
              v-model="from_date"
              lang="ar"
              :placeholder="$t('period_from')"
              disabled
            />
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("period_to") }}</label>
            <Datepicker
              v-model="to_date"
              lang="ar"
              :placeholder="$t('period_to')"
              disabled
            />
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("edit") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Skeleton from "primevue/skeleton";
export default {
  name: "add-route",
  components: {
    Datepicker: VueDatepickerUi,
    Skeleton,
  },
  data() {
    return {
      loading: true,
      v$: useVuelidate(),
      contract_id: this.$route.params.id,
      season_id: this.$route.params.seasonId,
      season_name: [
        {
          lang: "ar",
          name: "",
        },
        {
          lang: "en",
          name: "",
        },
        {
          lang: "id",
          name: "",
        },
      ],
      from_date: "",
      to_date: "",
    };
  },
  validations() {
    return {
      season_name: this.season_name.map(() => {
        return {
          name: { required },
        };
      }),
    };
  },
  methods: {
    fetchSeasonData() {
      axios
        .get(`/transportation_seasons/${this.season_id}`)
        .then(({ data }) => {
          this.from_date = data.data.from_date;
          this.to_date = data.data.to_date;
          this.season_name = data.data.title.map((season) => {
            return {
              lang: season.locale,
              name: season.title,
            };
          });
          this.loading = false;
        });
    },
    async addSeason() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const params = new URLSearchParams();

        this.season_name.forEach((season) => {
          params.append(`title_${season.lang}`, season.name);
        });
        params.append("from_date", moment(this.from_date).format("YYYY-MM-DD"));
        params.append("to_date", moment(this.to_date).format("YYYY-MM-DD"));
        params.append("transportation_contract_id", this.contract_id);
        axios
          .patch(`/transportation_seasons/${this.season_id}`, params)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.$router.push(
              `/contract-transport/${this.contract_id}/season-transport`
            );
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },
  mounted() {
    this.fetchSeasonData();
  },
};
</script>
<style lang="scss" scoped>
@import "./_seasons.scss";
</style>
