<template>
  <!-- <div v-if="loading" class="loading">
    <Loading />
  </div> -->
  <section class="all_emp card_style">
    <div class="col-lg-2 col-md-12 col-12 d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4>
        {{ $t("rooming_list") }}
      </h4>
    </div>
    <div class="row mb-2 align-items-center justify-content-between">
      <!-- <div class="col-lg-4 col-md-12 col-12"></div> -->
      <form class="col-lg-8 col-md-12 col-12">
        <div class="form-group">
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            class="form-control search w-75"
            :placeholder="$t('search by name')"
            v-model="resrvationName"
            @keyup="fetchBooks((page = 1))"
          />
        </div>
      </form>
      <div class="col-lg-2 col-md-12 col-12 d-flex justify-content-end">
        <filterBookings @getData="getData" :booking_type="book_type" />
        <!-- <filterBookings @fetchBooks = "fetchBooks"/> -->
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table visaType" id="VisaTable">
          <thead>
            <tr>
              <th>{{ $t("book_number") }}</th>
              <th>{{ $t("confirmation_number") }}</th>
              <th>{{ $t("hotel_name") }}</th>
              <th>{{ $t("rooms") }}</th>
              <th>{{ $t("meals") }}</th>
              <th>{{ $t("guests") }}</th>
              <th>{{ $t("checkin") }}</th>
              <th>{{ $t("checkout") }}</th>
              <th>{{ $t("book_date") }}</th>
              <th>{{ $t("book_status") }}</th>
              <!-- <th>{{ $t("Control") }}</th> -->
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="guests in 10" :key="guests">
              <td v-for="guests in 11" :key="guests">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(guests, index) in books.data" :key="index">
              <td>
                <router-link :to="`/hotel-view/${guests.id}`">{{
                  guests.id
                }}</router-link>
              </td>
              <td>{{ guests.confirmation_number }}</td>
              <td>{{ guests.hotel_name }}</td>
              <td>
                <div
                  class="guest_room"
                  v-for="(room, index) in guests.rooms"
                  :key="index"
                >
                  - {{ room.title }}
                </div>
              </td>
              <td>
                <div class="" v-for="room in guests.rooms" :key="room.id">
                  {{ room.description }}
                </div>
              </td>
              <td>
                <div class="" v-for="room in guests.rooms" :key="room.id">
                  <div
                    class=""
                    v-for="(guest, index) in room.guests"
                    :key="index"
                  >
                    {{ guest.title }} {{ guest.name }}
                  </div>
                </div>
              </td>
              <td>{{ guests.check_in }}</td>
              <td>{{ guests.check_out }}</td>
              <td>{{ guests.created_date }}</td>
              <td>
                <span
                  :class="[
                    'badge',
                    guests.status === 0
                      ? 'bg-primary'
                      : guests.status === 1
                      ? 'bg-warning text-dark'
                      : guests.status === 2
                      ? 'bg-success'
                      : guests.status === 3
                      ? 'bg-info text-dark'
                      : guests.status === 4
                      ? 'bg-danger'
                      : '',
                  ]"
                >
                  {{
                    guests.status === 0
                      ? $t("initial")
                      : guests.status === 1
                      ? $t("pending")
                      : guests.status === 2
                      ? $t("completed")
                      : guests.status === 3
                      ? $t("edited")
                      : guests.status === 4
                      ? $t("canceled")
                      : ""
                  }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="books"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchBooks"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import filterBookings from "../../../components/offline/filterBookings.vue";
import Swal from "sweetalert2";
// import Loading from "@/components/LottieFiles/loading.vue";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";

export default {
  name: "bookTablePage",
  data() {
    return {
      books: [],
      Form: {},
      bookingType: "",
      resrvationName: "",
      loading: false,
      book_type: "b2c",
      filterButton: "",
    };
  },
  components: {
    pagination,
    filterBookings,
    Skeleton,
    AddFavDailog,
  },
  methods: {
    fetchBooks(page = 1) {
      this.loading = true; //the loading begin
      let booking_type = {
        booking_type: 2,
        confirmed_only: 1,
      };

      // Uncommented code blocks
      if (this.resrvationName !== "") {
        booking_type.word = this.resrvationName;
      }
      if (
        Object.keys(this.Form).length !== 0 &&
        this.Form.constructor === Object
      ) {
        booking_type = {
          ...booking_type,
          ...this.Form,
        };
      }

      axios
        .post(`/filter_offline_bookings?page=${page}`, booking_type)
        .then(({ data }) => {
          this.books = data.data;
          this.loading = false; //the loading ended
          // console.log(this.books);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false;
        });
    },

    getData(data) {
      this.Form = data;
      this.fetchBooks();
    },
    cancelBook(index, bookID) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`/cancel_booking`, { booking_id: bookID })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.books.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
            });
        }
      });
    },
  },
  updated() {
    $("#bookTable").DataTable({
      bPaginate: false,
      retrieve: true,
      searching: false,
      paging: false,
      ordering: false,
      info: false,
      oLanguage: { sZeroRecords: "", sEmptyTable: "" },
      dom: "Blfrtip",
      buttons: [
        {
          extend: "csvHtml5",
          text: '<i class="fa-solid fa-file-csv"></i>',
          titleAttr: "CSV",
          title: this.$t("reservation"),
          charset: "utf-8",
          bom: "true",
          init: function (node) {
            $(node).removeClass("btn-default");
          },
          exportOptions: {
            columns: ":not(:last-child)",
          },
        },
      ],
    });
  },
  mounted() {
    this.fetchBooks();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
// @import "offline.scss";

.selects {
  .form-group {
    width: 100%;
  }
}
.search {
  padding-inline-start: 3rem !important;
}
.table thead tr th {
  font-size: 0.8rem;
}
.main_header_style {
  margin-bottom: unset !important;
}
.btn-drop {
  width: fit-content !important;
}
.table thead tr th {
  text-align: center !important;
}
td {
  text-align: center !important;
}
form {
  .form-group {
    position: relative;
    i {
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }
}
</style>
