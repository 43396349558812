<template>
  <div class="add-excel card_style">
    <div class="row">
      <div class="col-12">
        <h3>{{ $t("Add Discounts Type") }}</h3>
      </div>
      <div class="form-group col-lg-12 col-md-12 col-sm-12">
        <div class="d-flex flex-column align-items-center border py-4 rounded">
          <i class="fa-solid fa-upload fs-1 mb-3"></i>
          <label for="file" class="btn PrimaryButton">
            {{ $t("add file") }}</label
          >
          <input
            type="file"
            id="file"
            hidden=""
            @change="handleFileUpload"
            class="form-control"
          />
          <DialogSheet
            :type="type"
            :excelData="excelData"
            :clientData="clientData"
            @sentedData="sentedData"
            endpoint="/check_excel_sheet_for_discount_type"
          />
        </div>
        <ExcelTable
          v-if="DataForTable.length > 0"
          :type="type"
          :sentedData="DataForTable"
          endpoint="/check_excel_sheet_for_discount_type"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useExcelStore } from "@/store/modules/excel/index.js";
import { ref } from "vue";
import DialogSheet from "@/components/excelsheet/dialogSheet.vue";
import ExcelTable from "@/components/excelsheet/excelTable.vue";

// Handle file upload from store
const excelStore = useExcelStore();
const excelData = ref([]);
const DataForTable = ref([]);
const clientData = ref([
  {
    title: "discount_type_coding",
    data: "",
  },
  { title: "discount_type", data: "" },
]);
const sentedData = (data) => {
  DataForTable.value = data;
  console.log(DataForTable.value);
};
const type = ref("discount_types");
const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    excelStore
      .processFile(file)
      .then((data) => {
        excelData.value = data;
        // console.log("Excel data processed:", data);
      })
      .catch((error) => {
        console.error("Error processing Excel file:", error);
      });
  }
};
</script>

<style scoped lang="scss"></style>
