<template>
  <section class="invoice">
    <!-- Head invoice -->
    <div class="head_invoice">
      <div class="book_status">
        <h6>
          booking status : 
          <span>{{ book_status_name }}</span>
        </h6>
        <div class="chart"></div>
        <div class="chart"></div>
      </div>
      <div class="logo">
        <img :src="pills.organization?.default_image" />
      </div>
      <div class="book_number">
        <div class="chart"></div>
        <div class="chart"></div>
        <h6>
          booking number : 
          <span>#{{ pills.book_number }}</span>
        </h6>
      </div>
    </div>
    <!-- Head invoice -->

    <!-- client reservation data -->
    <dataInvoicePerson :pills="pills" /> 
    <!-- client reservation data -->

    <!-- welcome invoice data -->
    <div class="welcome_text">
      <!-- <h4>Dear Sir : {{ pills.client_name }}</h4> -->
      <h5>
        Greeting From <span>{{ pills.organization?.name }}</span>
      </h5>
      <div class="pragra">
        <p>
          First of All, We would like to take this opportunity to welcome you
          at
          <strong> {{ pills.organization?.name }} </strong> and tourism We are
          pleased to confirm the following reservation on a
          <span>{{ book_status_name }}.</span>
        </p>
      </div>
    </div>
    <!-- welcome invoice data -->

    <!-- Table reservation -->
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="frist">HOTEL</th>
            <th class="frist">ROOM TYPE</th>
            <th scope="col">MEAL</th>
            <th scope="col">PAX</th>
            <th scope="col">CHECK IN</th>
            <th scope="col">CHECK OUT</th>
            <th scope="col">NTS</th>
            <th scope="col">Hotel conf</th>
            <th scope="col">RATE</th>
            <th class="last">TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(room, index) in pills.rooms" :key="index">
            <td>{{room.hotel_name}}</td>
            <td>
              <p class="mb-0 fs-8">{{room.room_number}} X {{ room.room_name }}</p>
              <p class="mb-0 fs-8">{{ room.title_view }}</p>
            </td>
            <td>{{ room.meal_type?.title }}</td>
            <td>{{ room.guest_number }}</td>
            <td>
              <p class="mb-0">
                {{ room.check_in_date }}
              </p>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_in_date))
              }}</span>
            </td>
            <td>
              <P class="mb-0">
                {{ room.check_out_date }}
              </P>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_out_date))
              }}</span>
            </td>
            <td>{{ room.number_of_nights }}</td>
            <td>{{ room.hotel_reference }}</td>
            <td>{{ (room.total_selling_price_room_without_tax / room.number_of_nights).toFixed(2) }}</td>
            <td class="last">
              {{ room.total_selling_price_room_without_tax }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Table reservation -->

    <div class="footer_invoice"></div>
  </section>
</template>

<script>
import dataInvoicePerson from './component/dataInvoicePersonEn.vue';

export default {
  name: "pill-hotel-confirm",
  props: {
    pills: Object,
    terms: String,
    total_selling_price_with_out_tax: String,
    selling_tax: String,
    total_selling_price: String,
    book_status_name: String,
  },
  components: {
    dataInvoicePerson
  }
};
</script>

<style lang="scss" scoped>
@import "./_pill.scss";
.invoice {
  .invoice_organiztion_data {
    .social_accounts, .logo {
      width: 50% !important;
    }
  }
}
</style>
