<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <h4>{{ $t("DiscountTypeAdd") }}</h4>

      <div>
        <router-link to="/DiscountTypeAdd" class="PrimaryButton btn w-100"
          ><i class="fa-solid fa-plus"></i> Add New
        </router-link>
      </div>
    </div>

    <!-- <div class="search-visa my-3">
           <form>
             <i class="fa-solid fa-magnifying-glass"></i>
             <input
               type="text"
               class="form-control"
               :placeholder="$t('search...')"
             />
           </form>
         </div> -->

    <div class="row">
      <table class="table" id="VisaTable">
        <thead>
          <tr>
            <th>ID</th>
            <th class="text-center">{{ $t("name") }}</th>
            <th class="text-center">{{ $t("percentages") }}</th>
            <th class="text-center">{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(insurance, index) in DiscountType.data" :key="index">
            <td>{{ insurance.id }}</td>
            <td class="text-center">{{ insurance.title }}</td>
            <td class="text-center">{{ insurance.percentage }}</td>
            <td
              class="text-center d-flex align-items-center justify-content-center"
            >
              <button
                @click="this.$router.push(`/DiscountType_Edit/${insurance.id}`)"
                class="btn btn-success mx-1 w-auto"
              >
                <i class="fa-regular fa-edit"></i>
              </button>
              <button
                @click="deleteDiscountType(insurance.id, index)"
                class="btn btn-danger mx-1 w-auto"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="DiscountType"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="DiscountType_categories"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
  import "jquery/dist/jquery.min.js";
  import "bootstrap/dist/css/bootstrap.min.css";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import axios from "axios";
  import pagination from "laravel-vue-pagination";
  import Swal from "sweetalert2";
  // import $ from "jquery";
  export default {
    name: "allVisa",
    components: {
      pagination,
    },
    data() {
      return {
        DiscountType: [],
      };
    },
    methods: {
      DiscountType_categories(page = 1) {
        axios.get(`/discount_types?page=${page}`).then(({ data }) => {
          this.DiscountType = data.data;
          //  console.log(this.DiscountType);
        });
      },
      //delete visa
      deleteDiscountType(id, index) {
        Swal.fire({
          title: this.$t("check_delete"),
          text: this.$t("are_you_sure_delete"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.$t("back"),
          confirmButtonText: this.$t("confirm_delete"),
        }).then((result) => {
          if (result.isConfirmed) {
            // let delete_id = this.affiliates.id;
            const fromClick = true;
            axios.delete(`/discount_types/${id}`, { fromClick }).then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.DiscountType.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
          }
        });
      },
    },
    created() {
      this.DiscountType_categories();
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/variables.scss";
  // @import "./_visa.scss";

  .main_header_style {
    align-items: center;
    h4 {
      margin-bottom: 0;
    }
  }
  .search-visa {
    form {
      position: relative;
      i {
        position: absolute;
        top: 12px;
        right: 15px;
        color: #9ca2ac;
      }
      input {
        padding-right: 40px !important;
      }
    }
  }
  .text-center {
    text-align: center !important;
  }
</style>
