<template>
  <section class="card_style tree_organization">
    <div class="main_header_style">
      <h4>{{ $t("company_tree") }}</h4>
    </div>

    <div class="row">
      <table class="table" id="organizationTable">
        <thead>
          <tr>
            <th>{{ $t("the_company") }}</th>
            <th>{{ $t("company_balance") }}</th>
            <th></th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 2" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) in tree" :key="index">
            <td>{{ item.name }}</td>
            <td>{{ item.wallet }} {{}}</td>

            <td>
              <router-link
                class="btn btn-warning"
                :to="`/sub_organization/${item.id}`"
                :title="$t('sub_organization')"
              >
                <i class="fa-solid fa-bars-staggered"></i>
              </router-link>

              <router-link
                class="btn btn-primary mx-1"
                :to="`/organization-form/details/${item.id}`"
                :title="$t('company_detials')"
              >
                <i class="fa-solid fa-circle-info"></i>
              </router-link>

              <router-link
                class="btn btn-success text-white mx-1"
                :to="`/organization-form/edit/${item.id}`"
                :title="$t('edit')"
              >
                <i class="fa-solid fa-pen"></i>
              </router-link>
              <router-link
                :to="`/organization-theme/${item.id}`"
                class="btn SecondButton mx-3"
                v-if="status === 1"
              >
                <i class="fa-solid fa-palette"></i>
                {{ $t("company_theme") }}
              </router-link>

              <button
                class="btn btn-info text-white w-25r"
                @click="blockAlert(index)"
              >
                <i class="fa-solid fa-ban"></i>
                {{ item.block == 0 ? $t("bolck_company") : $t("unblock") }}
              </button>

              <button
                class="btn btn-danger text-white mx-1 remove_btn"
                @click="DeleteAlert(index)"
              >
                <i class="fa-solid fa-trash" :title="$t('delete')"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="tree"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchTreeOrganization"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import Swal from 'sweetalert2';
import pagination from "laravel-vue-pagination";
import $ from "jquery";
import Skeleton from "primevue/skeleton";
export default {
  name: "tree-organization",
  components: {
    pagination,
    Skeleton,
  },
  data() {
    return {
      isMenuVisible: false,
      tree: [],
      loading: true,
      status: JSON.parse(localStorage.getItem("user")).status,
      // showChildTree: false,
    };
  },
  computed: {
    organization_id() {
      // You can adjust this depending on your actual data structure
      if (this.tree.length > 0) {
        return this.tree[0].id;
      }
      // Default value if the tree is empty
      return null;
    },
  },

  methods: {
    toggleMenu(index) {
      this.tree[index].isMenuVisible = !this.tree[index].isMenuVisible;
    },
    //fetch companies data
    FetchTreeOrganization() {
      let organization_id = { organization_id: this.$route.params.id };
      axios
        .post("/fetch_child_organizations", organization_id)
        .then(({ data }) => {
          this.tree = data.data;
          this.loading = false;
        });
    },
    // block company
    blockAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_block"),
      }).then((result) => {
        if (result.isConfirmed) {
          let block_id = { organization_id: this.tree.data[index].id };
          const newLocal = this.$i18n.locale;
          axios.defaults.headers.common["Accept-Language"] = newLocal;
          axios
            .post("/change_block_organization", block_id)
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.FetchTreeOrganization();
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
    // delete company
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.tree.data[index].id;
          const fromClick = true;
          axios.delete("/organizations/" + `${delete_id}`, { fromClick }).then(({ data }) => {
            // console.log(result);
            // console.log(delete_id);
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.tree.data.splice(index, 1); // Remove the deleted item from the array
              // console.log(data);
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.FetchTreeOrganization();
  },
  updated() {
    $("#organizationTable").DataTable({
      bPaginate: true,
      bInfo: false,
      retrieve: true,
      sEmptyTable: false,
      // displayStart: 5,
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("company"),
        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("from") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("company"),
      },
      oLanguage: {
        sEmptyTable: " ",
      },
    });
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },

  watch: {
    $route: {
      handler: "FetchTreeOrganization",
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./organization.scss";
</style>
