<template>
  <div
    class="modal fade"
    :id="`Downpayment${clienId}`"
    tabindex="-1"
    aria-labelledby="DownpaymentLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="DownpaymentLabel">
            {{ $t("Downpayment") }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form action="">
            <div class="form-group">
              <label for="amount">{{ $t("the amount") }}</label>
              <input
                type="number"
                min="0"
                class="form-control"
                v-model="amount"
                :placeholder="$t('the amount')"
              />
            </div>
            <AccountsBanksVue
              @updateAccountValue="updateAccountValue"
              @updateSafeValue="updateSafeValue"
              :organizationAccounts="organizationAccounts"
              :organizationSafe="organizationSafe"
            />
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="addDownpayment">
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountsBanksVue from "@/components/clients/AccountsBanks.vue";
import axios from "axios";

export default {
  name: "modal-dialog",
  props: ["clienId" , "organizationAccounts" , "organizationSafe"],
  data() {
    return {
      amount: 0,
      accountValue: "",
      safeValue: "",
    };
  },
  components: {
    AccountsBanksVue,
  },
  methods: {
    updateAccountValue(accountValue) {
      this.accountValue = accountValue.tree_relation[0].tree_branch_id;
      // console.log();
    },
    updateSafeValue(safeValue) {
      this.safeValue = safeValue.tree_relation[0].tree_branch_id;
      // console.log(this.safeValue);
    },
    addDownpayment() {
      axios
        .post("/store_advance_payment_to_branch_client", {
          amount: this.amount,
          client_id: this.clienId,
          safe_branch_id: this.safeValue ? this.safeValue : this.accountValue,
        })
        .then((response) => {
          console.log(response.data);
        });
    },
  },
};
</script>

<style></style>
