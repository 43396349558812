<template>
  <section class="add_recipt card_style">
    <div class="main_header_style">
      <h4>{{ $t("edit_recipt") }}</h4>
    </div>
    <form class="form_topic" @submit.prevent="editRecipt()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("statement") }}</label>
            <textarea
              class="form-control"
              :placeholder="$t('add_statement')"
              v-model="report"
            ></textarea>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("edit") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "add-recipt",
  components: {},
  data() {
    return {
      report: "",
    };
  },
  methods: {
    fetchReciptData() {
      let id = this.$route.params.id;
      axios.get("/receipt/" + id).then(({ data }) => {
        this.report = data.data.report;
        // console.log(data.data);
      });
    },
    async editRecipt() {
      let id = this.$route.params.id;
      const params = new URLSearchParams();

      params.append("report", this.report);

      axios
        .put(`/receipt/${id}`, params)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/receipt");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },

  created() {
    this.fetchReciptData();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "./_recipt";
</style>
