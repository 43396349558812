z
<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("Create New Car Type") }}</h4>
    </div>
    <form class="form_topic" @submit.prevent="addType()">
      <div class="row">
        <div class="col-lg-4 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("choose_type") }}</label>
            <Multiselect
              v-model="car_category_id"
              :options="category.map((type) => type.id)"
              :custom-label="(opt) => category.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose_type')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.car_category_id.$error">{{v$.car_category_id.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- start input group -->
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <label class="form-label">{{ $t("car_name") }}</label>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("car_name") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(car, index) in car_name"
                      :key="index"
                    >
                      <label :for="`name${index}`"
                        >{{ $t("car_name") }} ({{ car.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="car.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.car_name.$error">{{v$.car_name.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-4">
          <div class="input-group">
            <label class="form-label"> {{ $t("total_number") }}</label>
            <input
              type="number"
              class="form-control"
              :placeholder="$t('total_number')"
              v-model="max_number"
              required
            />
            <span class="input-errors" v-if="v$.max_number.$error">{{v$.max_number.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- finish input group -->
        <div class="col-md-6 mb-6">
          <input type="file" id="file" @change="imageUpload" multiple hidden />

          <label for="file" class="add_file"
            ><i class="fa-solid fa-upload"></i> {{ $t("Add image") }}</label
          >
          <p>{{ this.imageCompanyName }}</p>
        </div>

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("add") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Loading from "@/components/LottieFiles/loading.vue";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  name: "add-car-type",
  components: {
    Multiselect,
    Loading,
  },
  data() {
    return {
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      car_category_id: "",
      car_name: [],
      max_number: "",
      category: [],
      imageCompany: "",
      imageCompanyName: "",
      Loading: false,
    };
  },
  validations(){
    return{
      car_category_id: { required },
      car_name: this.car_name.map(()=>{
        return{
          name : {required}
        }
      }),
      max_number: { required }
    }
  },
  methods: {
    imageUpload(event) {
      this.imageCompany = event.target.files[0];
      const name = this.imageCompany.name;

      const reader = new FileReader();
      reader.readAsDataURL(this.imageCompany);
      reader.onload = () => (this.imageCompanyName = name);
      // reader.onload = () => (this.imageEntry = reader.result);
    },
    setCarName() {
      this.languages.forEach((language) => {
        this.car_name.push({
          lang: language,
          name: "",
        });
      });
    },
    // fetch car cat
    fetchCategoryData() {
      axios.post(`/fetch_car_categories`).then(({ data }) => {
        this.category = data.data.data;
        // console.log(data.data.data);
      });
    },
    async addType() {
      this.v$.$validate();
      if(!this.v$.$error){
        const formData = new FormData();
        formData.append("car_category_id", this.car_category_id);
        formData.append("max_number", this.max_number);
        formData.append("image", this.imageCompany);
        this.car_name.forEach((car) => {
          formData.append(`title_${car.lang}`, car.name);
        });
        this.loading = true; // start loading

        axios
          .post("/car_types", formData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.loading = false; // end loading
            this.$router.push("/car_type");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false; // end loading
          });
      }
    },
  },
  mounted() {
    this.fetchCategoryData();
    this.setCarName();
  },
};
</script>
<style lang="scss" scoped>
@import "./_cartype.scss";
</style>
