<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <div class="hotel_card pb-3">
    <img
      :src="hotel.image"
      class="image_hotel"
      :alt="hotel.title"
      @error="handleImageError"
    />
    <div class="data_hotel">
      <h4 class="title">{{ hotel.name }}</h4>
      <p class="address">
        <i class="fa-solid fa-map-pin"></i> {{ hotel.address }}
      </p>
      <div class="rate" v-if="hotel.star_count">
        <i
          class="fa-solid fa-star"
          v-for="(star, index) in hotel.star_count"
          :key="index"
        ></i>
        <i
          class="fa-regular fa-star"
          v-for="(star, index) in 5 - hotel.star_count"
          :key="index"
        ></i>
      </div>
      <!-- <rate :length="5" :value="hotel.star_count" :disabled="true" /> -->
      <p class="text">{{ hotel.description }}</p>
    </div>
    <div class="details_hotel">
      <div class="details_price">
        <h4 class="price">
          {{ hotel.price.toFixed(2) }} {{ hotel.currency }}
          <span class="text_price">
            / {{ $t("Less price for the night") }}</span
          >
        </h4>
        <!-- <router-link :to="`/hotel/${hotel.id}`" class="btn PrimaryButton">{{$t("show_details")}} <i class="fa-solid fa-chevron-left"></i></router-link> -->
        <button
          class="btn PrimaryButton"
          type="button"
          @click="sendDataTohotelRooms"
        >
          {{ $t("show_details") }} <i class="fa-solid fa-chevron-left"></i>
        </button>
        <p class="offers">
          {{ $t("Get more prices and offers from") }}
          <span class="here" @click="FetchRooms()">{{ $t("here") }}</span>
        </p>
        <!-- v-if="hotelIdLocal == hotel.id || hotelId == hotel.id || !hotelIdLocal" -->
      </div>
      <div class="rate">
        <p class="number_rate">{{ hotel.rating }}</p>
        <div class="number_assessments">
          ({{ hotel.number_assessments }} {{ $t("evaluation") }})
        </div>
      </div>
    </div>
  </div>
  <div class="check_room_is_empty" v-if="roomsSelected">
    <div class="room_selected">
      <h5 class="title">{{ $t("room_selected") }}</h5>
      <div
        class="card_room_selected"
        v-for="(room, index) in roomsSelected.hotel_rooms"
        :key="index"
      >
        <div class="card_quantity">
          <div class="details">
            <p class="title">
              {{ room.room_index }} - {{ room.room_type_name }}
            </p>
          </div>
          <div class="price_quantity">
            <p class="price">
              {{ room.room_rate == null ? null : room.room_rate.price }} / {{ room.room_rate == null ? null : room.room_rate.currency }}
            </p>
            <div class="quantity">
              <button class="btn minus" @click="minusQuantity(index)">
                <i class="fa-solid fa-minus"></i>
              </button>
              <span class="number">{{ room.quantity }}</span>
              <button class="btn add" @click="addQuantity(index)">
                <i class="fa-solid fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <button type="button" class="btn remove" @click="removeRooms(index)">
          <i class="fa-regular fa-circle-xmark"></i>
        </button>
      </div>
      <div class="buttons">
        <button
          type="button"
          class="btn PrimaryButton"
          @click="newBookCheckPrice(hotel.id)"
        >
          {{ $t("book_now") }}
        </button>
      </div>
    </div>
  </div>
  <div class="show_offers">
    <div class="line_offer" v-if="offers.length && lengthOffers == true"></div>
    <div class="head_offer" v-if="offers.length && lengthOffers == true">
      <h4 class="title">{{ $t("Other prices offers") }}</h4>
      <i class="fa-solid fa-xmark" @click="removeOffers()"></i>
    </div>
    <hotelRooms
      v-for="(room, index) in rooms"
      :key="index"
      :room="room"
      :rooms="rooms"
      :index="index"
      :hotel_id="hotel_code"
      :hotel_name="hotel_name"
      :session_id="session_id"
      :resultIndex="resultIndex"
      :guest_nationality="guest_nationality"
      :provider_id="provider_id"
      :credential_id="credential_id"
    />
  </div>
</template>

<script>
import axios from "axios";
import hotelRooms from "./roomCardNew.vue";
import Swal from "sweetalert2";
import Loading from "@/components/LottieFiles/loading.vue";
// import roomSelected from './roomSelected.vue';

export default {
  name: "hotel-card",
  props: {
    hotel: Object,
    index: Number,
    session_id: String,
    hotel_code: Number,
    hotel_name: String,
    guest_nationality: String,
    resultIndex: Number,
    provider_id: Number,
    credential_id: Number,
  },
  data() {
    return {
      offers: [],
      lengthOffers: false,
      roomsSelected: [],
      rooms: [],
      hotelId: "",
      hotelIdLocal: "",
      loading: false,
      defaultImage: require("@/assets/media/image/placeholder.png"), // Replace with your default image URL,
      element_position: 0,
      rooms_guests: JSON.parse(localStorage.getItem("bookDetails"))
        .rooms_guests,
      check_in: JSON.parse(localStorage.getItem("bookDetails")).check_in,
      check_out: JSON.parse(localStorage.getItem("bookDetails")).check_out,
    };
  },
  components: {
    hotelRooms,
    Loading,
    // roomSelected
  },
  methods: {
    handleImageError(event) {
      // When the image fails to load, replace it with the default image
      event.target.src = this.defaultImage;
    },
    // fetch offers
    FetchRooms() {
      this.loading = true; //the loading begin

      let filter = {
        hotel_id: this.hotel.id,
        nationality_code: this.guest_nationality,
        passenger_country_of_residence_code: this.guest_nationality,
        rooms_guests: this.rooms_guests,
        check_in: this.check_in,
        check_out: this.check_out,
        meta_data: {
          credential_id: this.credential_id,
          provider_id: this.provider_id,
        },
      };

      // Conditionally add result_index to meta_data if this.resultIndex is not null
      if (this.resultIndex != null) {
        filter.meta_data.result_index = this.resultIndex;
        filter.meta_data.session_id = this.session_id;
      }
      if (this.resultIndex == null) {
        filter.converter_status = 1;
      }

      axios
        .post("/fetch_new_available_rooms", filter)
        .then(({ data }) => {
          if (data.status == true) {
            this.rooms = data.data;
            this.lengthOffers = true;
            this.loading = false; //the loading Eneded
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: data.message,
              timer: 3000,
            });
            this.loading = false; //the loading Eneded
          }
          // console.log(data)
        })
        .catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.response.data.message,
            timer: 3000,
          });
          this.loading = false; //the loading Eneded
          return error;
        })
        .finally(() => {
          //Perform action in always
          this.loading = false; //the loading Eneded
        });
    },
    newBookCheckPrice(hotel_id) {
      // console.log(this.hotel);
      localStorage.setItem("hotel_Details", JSON.stringify(this.hotel));
      const contract_type = JSON.parse(localStorage.getItem("bookData")).type;
      if (contract_type == "online") {
        this.loading = true; //the loading begin
        const bookDetails = JSON.parse(localStorage.getItem("bookDetails"));
        const bookData = JSON.parse(localStorage.getItem("bookData"));
        for (let index = 0; index < bookData.hotel_rooms.length; index++) {
          this.room_id = bookData.hotel_rooms[index].room_index;
          this.room_count = bookData.hotel_rooms[index].quantity;
          this.room_fare = bookData.hotel_rooms[index].room_rate.room_fare;
          this.room_tax = bookData.hotel_rooms[index].room_rate.room_tax;
          this.total_fare = bookData.hotel_rooms[index].room_rate.total_fare;
        }
        const checkPrice = {
          session_id: bookData.session_id,
          result_index: bookData.result_index,
          response_time: bookDetails.response_time,
          hotel_code: hotel_id,
          rooms: [
            {
              room_id: this.room_id,
              room_count: this.room_count,
              room_fare: this.room_fare,
              room_tax: this.room_tax,
              total_fare: this.total_fare,
            },
          ],
        };
        axios
          .post("/check_availability_and_price", checkPrice)
          .then(({ data }) => {
            this.is_available_for_confirm_book =
              data.data.is_available_for_confirm_book;
            this.is_available_for_book = data.data.is_available_for_book;
            if (
              this.is_available_for_confirm_book == true &&
              this.is_available_for_book == true
            ) {
              this.loading = false; //the loading ended
              this.$router.push("/new_book");
            } else {
              this.loading = false; //the loading ended
              Swal.fire({
                position: "center",
                icon: "error",
                title: this.$t("room_not_available"),
                timer: 3000,
              });
            }
          });
      } else if (contract_type == "contract") {
        this.loading = true; //the loading begin
        const bookData = JSON.parse(localStorage.getItem("bookData"));
        for (let index = 0; index < bookData.hotel_rooms.length; index++) {
          this.room_id = bookData.hotel_rooms[index].room_id;
          this.room_count = bookData.hotel_rooms[index].count;
        }
        const checkPrice = {
          contract_id: bookData.contract_id,
          hotel_rooms: [
            {
              room_id: this.room_id,
              count: this.room_count,
            },
          ],
        };
        axios
          .post("/check_contract_rooms_price", checkPrice)
          .then(({ data }) => {
            if (data.status == true) {
              this.loading = false; //the loading ended
              this.$router.push("/new_book");
            } else {
              this.loading = false; //the loading ended
              Swal.fire({
                position: "center",
                icon: "error",
                title: this.$t("room_not_available"),
                timer: 3000,
              });
            }
          });
      }
    },

    removeOffers() {
      this.lengthOffers = false;
    },

    receiveData(data) {
      this.roomsSelected = data;
      // console.log(this.roomsSelected)
    },
    receiveHotelId(data) {
      this.hotelId = data;
    },

    minusQuantity(index) {
      if (this.roomsSelected.hotel_rooms[index].quantity > 1) {
        this.roomsSelected.hotel_rooms[index].quantity -= 1;
        this.updateLocalStorage();
      }
    },
    addQuantity(index) {
      this.roomsSelected.hotel_rooms[index].quantity += 1;
      this.updateLocalStorage();
    },

    removeRooms(index) {
      this.roomsSelected.hotel_rooms.splice(index, 1); // Remove 1 item at the specified index
      this.updateLocalStorage();
    },

    updateLocalStorage() {
      localStorage.setItem("bookData", JSON.stringify(this.roomsSelected));
      if (!this.roomsSelected.length) {
        localStorage.removeItem("hotelId");
      }
    },
    sendDataTohotelRooms() {
      let hotelData = {
        session_id: this.session_id,
        resultIndex: this.resultIndex,
        hotel_code: this.hotel_code,
        hotel_name: this.hotel_name,
        guest_nationality: this.guest_nationality,
      };
      localStorage.setItem("hotelData", JSON.stringify(hotelData));
      this.$router.push(`/hotel/${this.hotel.id}`);
    },
  },
  created() {
    const roomsSelected = localStorage.getItem("bookData");
    const hotelId = localStorage.getItem("bookDetails");
    this.roomsSelected = JSON.parse(roomsSelected);
    if (roomsSelected) {
      this.hotelIdLocal = JSON.parse(hotelId).hotel_code;
      // console.log(this.hotelIdLocal);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../view/dashboard/hotels/_hotels.scss";
</style>
