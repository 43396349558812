<template>
  <section class="card_style">
    <supliersSkelton v-if="loading" />
    <form @submit.prevent="editClient()" v-else>
      <div class="main_header_style flex-column">
        <h4>{{ $t("edit_client") }}</h4>
      </div>
      <div class="row">
        <!-- room name ar -->
        <div class="col-lg-4 mb-4">
          <label for="name">{{ $t("name") }}</label>
          <input
            type="text"
            :placeholder="$t('room name')"
            id="name"
            v-model="name"
          />
        </div>

        <!-- room name en -->
        <div class="col-lg-4 mb-4">
          <label for="email">{{ $t("email") }}</label>
          <input
            type="email"
            :placeholder="$t('email')"
            id="email"
            v-model="email"
          />
        </div>

        <div class="col-lg-4 mb-4">
          <label for="type">{{ $t("client type") }}</label>
          <Multiselect
            v-model="client_type"
            :options="types"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose_client_type')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>

        <div class="col-lg-4 mb-4" v-if="this.role == 'Client'">
          <label for="type">{{ $t("client_gender") }}</label>
          <Multiselect
            v-model="client_type_value"
            :options="client_types"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose_client_type')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
        <div class="col-lg-4 mb-4">
          <label for="type" class="fw-bold"
            >{{ $t("Manual code") }}

            <sub class="info">
              <b>( {{ $t("unique code for client") }} )</b>
            </sub>
          </label>
          <input
            class="form-control"
            type="text"
            :placeholder="$t('Manual code')"
            v-model="manual_code"
          />
        </div>

        <div class="col-lg-4 mb-4" v-if="this.role == 'Client'">
          <label for="type">{{ $t("discount_type") }}</label>
          <Multiselect
            v-model="discount_value"
            :options="discount_types"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose_discount_type')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="phone">{{ $t("phone") }}</label>
          <input type="number" :placeholder="$t('phone')" v-model="phone" />
        </div>

        <div class="col-lg-4 mb-4">
          <label for="adult">{{ $t("country") }}</label>

          <Multiselect
            v-model="countryValues"
            track-by="id"
            label="title"
            :options="countries"
            :clear-on-select="true"
            :placeholder="$t('choose_country')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>

        <div class="col-lg-4 mb-4">
          <label for="address">{{ $t("address") }}</label>
          <input type="text" :placeholder="$t('address')" v-model="address" />
        </div>

        <div class="row" v-if="client_type?.id == 2">
          <div class="col-lg-6 mb-4">
            <label for="tax">{{ $t("tax number") }}</label>
            <input
              type="text"
              :placeholder="$t('tax number')"
              v-model="tax_number"
            />
          </div>

          <div class="col-lg-6 mb-4">
            <label for="Registration">{{ $t("Registration number") }}</label>
            <input
              type="text"
              :placeholder="$t('Registration number')"
              v-model="registration_number"
            />
          </div>
        </div>

        <div class="col-12 my-2">
          <div class="d-flex align-items-center gap-2">
            <Checkbox v-model="checked" :binary="true" />
            <p class="mb-0">{{ $t("can deal with as supplier") }}</p>
          </div>
        </div>

        <div class="col-lg-12 mb-4">
          <div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="cash"
                v-model="payment_method"
                value="1"
              />
              <label class="form-check-label" for="cash">
                {{ $t("cash") }}
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="pending"
                v-model="payment_method"
                value="2"
              />
              <label class="form-check-label" for="pending"> آجل </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="prePanding"
                v-model="payment_method"
                value="3"
              />
              <label class="form-check-label" for="prePanding">
                {{ $t("Pre_Payment") }}
              </label>
            </div>
          </div>
        </div>

        <div class="row" v-if="payment_method == 3">
          <div class="col-lg-4 mb-4">
            <label for="adult">{{ $t("down_money") }}</label>
            <input
              type="number"
              :placeholder="$t('enter_down_money')"
              v-model="down_payment"
            />
          </div>
          <!-- start input group -->
          <div class="col-lg-4 col-12 mb-4">
            <div class="input-group">
              <label class="form-label">{{
                $t("choose_payment_method")
              }}</label>
              <Multiselect
                v-model="paidValues"
                :options="paids.map((paid) => paid.id)"
                :custom-label="(opt) => paids.find((x) => x.id == opt).title"
                :clear-on-select="true"
                :placeholder="$t('choose_payment_method')"
                select-label=""
                :hide-selected="true"
                @update:model-value="FetchAccounts(paidValues)"
              >
              </Multiselect>
            </div>
          </div>
          <!-- finish input group -->

          <div class="col-lg-4 col-12 mb-4">
            <label class="form-label">{{ $t("accounts") }}</label>
            <Multiselect
              v-model="accountValue"
              :options="accounts"
              label="text"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('accounts')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <div v-if="payment_method == 2">
          <div class="col-lg-6 mb-4">
            <label for="adult">{{ $t("Credit Limit") }}</label>
            <input
              type="number"
              :placeholder="$t('Enter Credit Limit')"
              v-model="credit_limit"
            />
          </div>
        </div>
      </div>

      <button type="submit" class="add save btn">{{ $t("add") }}</button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import supliersSkelton from "@/components/skelton/supliersSkelton.vue";
import Checkbox from "primevue/checkbox";
export default {
  name: "add-suppliers",
  data() {
    return {
      checked: false,
      manual_code: "",
      name: "",
      email: "",
      phone: "",
      address: "",
      countryValues: "",
      countries: [],
      credit_limit: 0,
      down_payment: 0,
      payment_method: 1,
      loading: true,
      paids: [
        {
          id: 1,
          title: this.$t("treasury"),
        },
        {
          id: 2,
          title: this.$t("bank"),
        },
        {
          id: 3,
          title: this.$t("ewalllets"),
        },
        {
          id: 4,
          title: this.$t("checks_under_collection"),
        },
      ],
      accountValue: "",
      paidValues: "",
      accounts: [],
      client_type: "",
      types: [
        {
          id: 1,
          title: this.$t("person"),
        },
        {
          id: 2,
          title: this.$t("company"),
        },
      ],
      registration_number: "",
      tax_number: "",
      client_types: [],
      client_type_value: "",
      discount_types: [],
      discount_value: "",
      role: "",
    };
  },
  components: {
    Multiselect,
    supliersSkelton,
    Checkbox,
  },
  methods: {
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
        this.loading = false;
      });
    },
    fetch_client_types() {
      axios.post("/index_client_types").then(({ data }) => {
        this.client_types = data.data.data;
        this.loading = false;
      });
    },
    fetch_discount_value() {
      axios.get("/discount_types").then(({ data }) => {
        this.loading = false;
        this.discount_types = data.data.data;
      });
    },
    FetchAccounts(account_id) {
      let type = { payment_method: account_id };
      axios.post(`/fetch_payment_method`, type).then(({ data }) => {
        this.accounts = data.data;
        this.loading = false;
      });
    },
    fetchSuppliersData() {
      let id = this.$route.params.id;
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/consumers/" + id).then(({ data }) => {
        this.name = data.data.name;
        this.email = data.data.email;
        this.phone = data.data.phone;
        this.address = data.data.address;
        this.countryValues = data.data.country;
        this.credit_limit = data.data.credit_limit;
        this.down_payment = data.data.prepayment_amount;
        this.payment_method = data.data.payment_method;
        this.registration_number = data.data.registration_number;
        this.tax_number = data.data.tax_number;
        this.role = data.data.role;
        this.client_type_value = data.data.custom_client_type;
        this.manual_code = data.data.manual_code;
        this.discount_value = data.data.discount_type;
        this.checked = data.data.is_supplier == 1 ? true : false;
        this.types.forEach((ele) => {
          if (ele.id == data.data.client_type) {
            this.client_type = ele;
          }
        });
        if (this.payment_method == 3) {
          this.accountValue = data.data.tree_branch;
          this.paidValues = data.data.payment_method_type;
        }
        this.loading = false;
      });
    },
    async editClient() {
      let id = this.$route.params.id;
      const params = new URLSearchParams();
      if (this.manual_code) {
        params.append("manual_code", this.manual_code);
      }
      if (this.name) {
        params.append("name", this.name);
      }
      params.append(`is_supplier`, this.checked ? 1 : 0);
      if (this.email) {
        params.append("email", this.email);
      }
      if (this.phone) {
        params.append("phone", this.phone);
      }
      if (this.address) {
        params.append("address", this.address);
      }
      if (this.role == "Client") {
        if (this.client_type_value) {
          params.append("client_type_id", this.client_type_value.id);
        }
        if (this.discount_value) {
          params.append("discount_type_id", this.discount_value?.id);
        }
        if (this.client_type) {
          params.append("client_type", this.client_type?.id);
        }
      }
      if (this.countryValues) {
        params.append("country_id", this.countryValues.id);
      }
      params.append("role", 1);

      params.append("payment_method", this.payment_method);
      if (this.payment_method == 2) {
        params.append("Pending_limit", this.credit_limit);
        params.append("credit_limit", this.credit_limit);
      }
      if (this.payment_method == 3) {
        params.append("prepayment_amount", this.down_payment);
        params.append("down_payment", this.down_payment);
        if (this.accountValue) {
          params.append(`safe_branch_id`, this.accountValue?.id);
          params.append(`payment_method_type`, this.paidValues);
        }
      }
      if (this.client_type?.id == 2) {
        params.append("registration_number", this.registration_number);
        params.append("tax_number", this.tax_number);
      }

      axios
        .put(`/consumers/${id}`, params)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/clients");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    this.fetch_country();
    this.fetchSuppliersData();
    this.fetch_client_types();
    this.fetch_discount_value();
  },
};
</script>

<style lang="scss" scoped>
@import "./_clients";

form {
  label {
    display: inline-block;
    margin-bottom: 10px;
    font-family: "bold" !important;
  }

  input,
  select {
    display: block;
    width: 100%;
    // height: 68px;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    color: #9ca2ac;
    padding: 10px 15px;
  }
}

.form-check-inline {
  display: inline-flex;
  flex-direction: column;
  margin-top: 0;
}

.form-check-input {
  width: 17px;
  height: 17px;
  padding: 0;
}
.info {
  font-size: 0.7rem;
}
</style>
