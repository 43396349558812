<template>
  <div class="card flex justify-content-center">
    <KeepAlive>
      <Sidebar
        v-model:visible="visible"
        header="Sidebar"
        class="new-sidebar"
        :position="direction"
      >
        <template #header>
          <div class="flex align-items-center gap-2">
            <router-link to="/" class="logo-link">
              <img :src="logo" alt="logo" class="logo" />
            </router-link>
          </div>
        </template>
        <!------------------------- Hotels ------------------------------>
        <template v-if="checkAllowedPermissionServices('HOTEL')">
          <Accordion>
            <AccordionTab :header="`${$t('hotels')}`">
              <Accordion v-if="checkAllowedPermissionApps('WEBSITE')">
                <AccordionTab :header="`${$t('B2C')}`">
                  <li>
                    <router-link
                      to="/book_table_type/b2c"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/task-square.svg"
                        alt="icon"
                      />
                      <span>{{ $t("bookings") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/pending_book/Hotel" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/task-square.svg"
                        alt="icon"
                      />
                      <span>{{ $t("book_pending") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/arrival_guests/b2c" class="nav-link px-0">
                      <img src="@/assets/media/image/graph.svg" alt="icon" />
                      <span>{{ $t("arrival_guests") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/room_guests/b2c" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/تقارير الغرف.svg"
                        alt="icon"
                      />
                      <span>{{ $t("rooming_list") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/BookingProfit"
                      replace
                      class="nav-link align-middle"
                    >
                      <img
                        src="@/assets/media/image/status-up.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Hotel revenues") }}</span>
                    </router-link>
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion v-if="checkAllowedPermissionApps('SYSTEM')">
                <AccordionTab :header="`${$t('B2B')}`">
                  <li>
                    <router-link
                      to="/hotels/book_offline"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/hotel.svg"
                        alt="icon"
                      />
                      <span>{{ $t("book_offline") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/hotel-availability" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/hotel.svg"
                        alt="icon"
                      />
                      <span>{{ $t("hotel_availability") }}</span></router-link
                    >
                  </li>
                  <li>
                    <a :href="`https://portal.travelsofttechsys.net/auth/login?user=${userEmail}&pass=${userPassword}`" target="_blank" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/hotel.svg"
                        alt="icon"
                      />
                      <span>{{ $t("bookings_online") }}</span></a>
                  </li>
                  <!-- booking history -->
                  <li>
                    <router-link to="/offlineBookHotels" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/task-square.svg"
                        alt="icon"
                      />
                      <span>{{ $t("reservation_offline") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/BookHotels-portal" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/task-square.svg"
                        alt="icon"
                      />
                      <span>{{
                        $t("reservation_offline_portal")
                      }}</span></router-link
                    >
                  </li>
                  <!-- booking history -->

                  <!-- online b2b -->
                  <li>
                    <router-link
                      to="/book_table_type/b2b"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/task-square.svg"
                        alt="icon"
                      />
                      <span>{{ $t("reservation_online") }}</span></router-link
                    >
                  </li>
                  <!-- online b2b -->

                  <!-- contract -->
                  <li>
                    <router-link to="/contract" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/document-copy.svg"
                        alt="icon"
                      />
                      <span>{{ $t("contracts_hotels") }}</span></router-link
                    >
                  </li>
                  <!-- contract -->

                  <!-- contract -->
                </AccordionTab>
              </Accordion>
              <Accordion v-if="checkAllowedPermissionApps('SYSTEM')">
                <AccordionTab :header="`${$t('reports')}`">
                  <li>
                    <router-link to="/tax-reports/index" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/document-copy.svg"
                        alt="icon"
                      />
                      <span>{{ $t("taxes_reports") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/tax-reports-due" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/document-copy.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Tax reports due") }}</span></router-link
                    >
                  </li>

                  <!-- <li>
                    <router-link
                      to="/purchase-reports/index"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/document-copy.svg"
                        alt="icon"
                      />
                      <span>{{
                        $t("purchase_taxes_reports")
                      }}</span></router-link
                    >
                  </li> -->

                  <li>
                    <router-link to="/contract/room" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/document-copy.svg"
                        alt="icon"
                      />
                      <span>{{ $t("available_rooms") }}</span></router-link
                    >
                  </li>
                  <!-- contract -->
                  <!-- rooming list -->
                  <li>
                    <router-link to="/room_guests/b2b" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/status-up.svg"
                        alt="icon"
                      />
                      <span>{{ $t("rooming_list") }}</span></router-link
                    >
                  </li>
                  <!--rooming list  -->

                  <!-- arrival -->
                  <li>
                    <router-link to="/arrival_guests/b2b" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/status-up.svg"
                        alt="icon"
                      />
                      <span>{{ $t("arrival") }}</span></router-link
                    >
                  </li>
                  <!--arrival -->

                  <li>
                    <router-link to="/revenues" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/money-recive.svg"
                        alt="icon"
                      />
                      <span>{{ $t("revenues") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/rooming-list-report"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/money-recive.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Rooming List Report") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/during-period/arrivals"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/money-recive.svg"
                        alt="icon"
                      />
                      <span>{{
                        $t("arrivals report  for hotel")
                      }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/during-period/in-house"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/money-recive.svg"
                        alt="icon"
                      />
                      <span>{{ $t("in-house report") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/salesAnalysis/1" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/money-recive.svg"
                        alt="icon"
                      />
                      <span>{{ $t("total-salesAnalysis") }}</span></router-link
                    >
                  </li>
                  <!-- <li>
                    <router-link to="/salesAnalysis/2" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/money-recive.svg"
                        alt="icon"
                      />
                      <span>{{
                        $t("salesAnalysis for hotels")
                      }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/salesAnalysis/3" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/money-recive.svg"
                        alt="icon"
                      />
                      <span>{{
                        $t("salesAnalysis for nationality")
                      }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/salesAnalysis/4" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/money-recive.svg"
                        alt="icon"
                      />
                      <span>{{
                        $t("salesAnalysis for supplier")
                      }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/salesAnalysis/5" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/money-recive.svg"
                        alt="icon"
                      />
                      <span>{{
                        $t("salesAnalysis for empolyee")
                      }}</span></router-link
                    >
                  </li> -->
                </AccordionTab>
              </Accordion>
              <Accordion v-if="stage == 1">
                <AccordionTab :header="`${$t('Hotel Settings')}`">
                  <li>
                    <router-link to="/allHotels" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/hotels-buld.svg"
                        alt="icon"
                      />
                      <span>{{ $t("hotels") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/roomViewGlobal" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/hotels-buld.svg"
                        alt="icon"
                      />
                      <span>{{ $t("room management") }}</span>
                    </router-link>
                  </li>

                  <li>
                    <router-link to="/room" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/hotels-buld.svg"
                        alt="icon"
                      />
                      <span>{{ $t("rooms") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/view" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/hotels-buld.svg"
                        alt="icon"
                      />
                      <span>{{ $t("views") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/meal" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/hotels-buld.svg"
                        alt="icon"
                      />
                      <span>{{ $t("meals") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/bed" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/hotels-buld.svg"
                        alt="icon"
                      />
                      <span>{{ $t("The Beds") }}</span>
                    </router-link>
                  </li>
                </AccordionTab>
              </Accordion>
            </AccordionTab>
          </Accordion>
        </template>
        <!------------------------- Hotels ------------------------------>

        <!------------------------- Flight ------------------------------>
        <template v-if="checkAllowedPermissionServices('FLIGHT')">
          <Accordion>
            <AccordionTab :header="`${$t('Flights')}`">
              <Accordion v-if="checkAllowedPermissionApps('WEBSITE')">
                <AccordionTab :header="`${$t('B2C')}`">
                  <li>
                    <router-link to="/b2cUsersflight" class="nav-link px-0">
                      <img src="@/assets/media/image/airplane.svg" alt="icon" />
                      <span>{{ $t("bookings") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/pending_book/Flight"
                      class="nav-link px-0"
                    >
                      <img src="@/assets/media/image/airplane.svg" alt="icon" />
                      <span>{{ $t("book_pending") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/flightsRevenue"
                      replace
                      class="nav-link align-middle"
                    >
                      <img src="@/assets/media/image/activity.svg" alt="icon" />
                      <span>{{ $t("Aviation_revenues") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/arrival_guests/b2c"
                      replace
                      class="nav-link align-middle"
                    >
                      <img src="@/assets/media/image/graph.svg" alt="icon" />
                      <span>{{ $t("arrival_guests") }}</span>
                    </router-link>
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion v-if="checkAllowedPermissionApps('SYSTEM')">
                <AccordionTab :header="`${$t('B2B')}`">
                  <li>
                    <router-link to="/flight" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/flight.svg"
                        alt="icon"
                      />
                      <span>{{ $t("flight_online") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/flight-booking" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/flight.svg"
                        alt="icon"
                      />
                      <span>{{ $t("flight-offline") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/reservation/show" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/task-square.svg"
                        alt="icon"
                      />
                      <span>{{ $t("reservation") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="#" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/agent-account.svg"
                        alt="icon"
                      />
                      <span>{{ $t("client_profile") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="#" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/agent-account.svg"
                        alt="icon"
                      />
                      <span>{{ $t("supplier_profile") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/flightsRevenue" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/money-recive.svg"
                        alt="icon"
                      />
                      <span>{{ $t("revenues") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/flight-contract" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/agent-account.svg"
                        alt="icon"
                      />
                      <span>{{
                        $t("choose_from_contracts")
                      }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion v-if="stage == 1">
                <AccordionTab :header="`${$t('Flight_Settings')}`">
                  <li>
                    <router-link to="/airport" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/flight.svg"
                        alt="icon"
                      />
                      <span>{{ $t("airport") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/flight-company" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/flight.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Airlines") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
            </AccordionTab>
          </Accordion>
        </template>
        <!------------------------- Flight ------------------------------>

        <!------------------------- PACKAGE ------------------------------>
        <template v-if="checkAllowedPermissionServices('PACKAGE')">
          <Accordion v-if="checkAllowedPermissionApps('WEBSITE')">
            <AccordionTab :header="`${$t('Tourism_programs')}`">
              <li>
                <router-link
                  to="/package/add/tourism"
                  replace
                  class="nav-link align-middle"
                >
                  <img src="@/assets/media/image/add-square.svg" alt="icon" />
                  <span>{{ $t("Add_program_turism") }}</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/package/add/umrah"
                  replace
                  class="nav-link align-middle"
                >
                  <img src="@/assets/media/image/add-square.svg" alt="icon" />
                  <span>{{ $t("Add_program_umrah") }}</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/package/tourism"
                  replace
                  class="nav-link align-middle"
                >
                  <img src="@/assets/media/image/color-swatch.svg" alt="icon" />
                  <span>{{ $t("Tourism_programs") }}</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/package/umrah"
                  replace
                  class="nav-link align-middle"
                >
                  <img src="@/assets/media/image/color-swatch.svg" alt="icon" />
                  <span>{{ $t("Umrah_packages") }}</span>
                </router-link>
              </li>
              <li>
                <router-link to="/books_Package" class="nav-link px-0">
                  <img src="@/assets/media/image/task-square.svg" alt="icon" />
                  <span>{{ $t("bookings") }}</span>
                </router-link>
              </li>
              <li>
                <router-link to="/pending_book/Package" class="nav-link px-0">
                  <img src="@/assets/media/image/task-square.svg" alt="icon" />
                  <span>{{ $t("book_pending") }}</span>
                </router-link>
              </li>
              <li>
                <router-link to="/packageRevenue" class="nav-link px-0">
                  <img
                    src="@/assets/media/image/presention-chart.svg"
                    alt="icon"
                  />
                  <span>{{ $t("revenues") }}</span>
                </router-link>
              </li>

              <Accordion v-if="checkAllowedPermissionApps('WEBSITE')">
                <AccordionTab :header="`${$t('program_settings')}`">
                  <li>
                    <router-link
                      to="/allPackageTypes"
                      replace
                      class="nav-link align-middle"
                    >
                      <img
                        src="@/assets/media/image/grid-edit.svg"
                        alt="icon"
                      />
                      <span>{{ $t("package_type") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/package_Star_Category"
                      class="nav-link px-0"
                    >
                      <img src="@/assets/media/image/3square.svg" alt="icon" />
                      <span>{{ $t("package_Star_Category") }}</span>
                    </router-link>
                  </li>
                </AccordionTab>
              </Accordion>
            </AccordionTab>
          </Accordion>
        </template>
        <!------------------------- PACKAGE ------------------------------>

        <!------------------------- Clients ------------------------------>
        <template
          v-if="
            checkAllowedPermissionApps('WEBSITE') ||
            checkAllowedPermissionApps('SYSTEM')
          "
        >
          <Accordion>
            <AccordionTab :header="`${$t('clients')} & ${$t('supliers')}`">
              <Accordion v-if="checkAllowedPermissionApps('WEBSITE')">
                <AccordionTab :header="`${$t('clients_online')}`">
                  <li>
                    <router-link
                      to="/b2cUsers"
                      replace
                      class="nav-link align-middle"
                    >
                      <img src="@/assets/media/image/b2c.svg" alt="icon" />
                      <span>{{ $t("clients_online") }}</span>
                    </router-link>
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion v-if="checkAllowedPermissionApps('SYSTEM')">
                <AccordionTab :header="`${$t('B2B')}`">
                  <li>
                    <router-link to="/DiscountType" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("discount_types") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/clientType"
                      replace
                      class="nav-link align-middle"
                    >
                      <img
                        src="@/assets/media/image/icon/agent-account.svg"
                        alt="icon"
                      />
                      <span>{{ $t("client_type") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/clients"
                      replace
                      class="nav-link align-middle"
                    >
                      <img
                        src="@/assets/media/image/icon/agent-account.svg"
                        alt="icon"
                      />
                      <span>{{ $t("clients") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/suplierType" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/agent-account.svg"
                        alt="icon"
                      />
                      <span>{{ $t("suplierType") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/suppliers" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/agent-account.svg"
                        alt="icon"
                      />
                      <span>{{ $t("suppliers") }}</span>
                    </router-link>
                  </li>
                </AccordionTab>
              </Accordion>
            </AccordionTab>
          </Accordion>
        </template>
        <!------------------------- Clients ------------------------------>

        <!-- SERVICES -->
        <template v-if="checkAllowedPermissionServices('SERVICE')">
          <Accordion>
            <AccordionTab :header="`${$t('services')}`">
              <li>
                <router-link
                  to="/services_dashboard"
                  replace
                  class="nav-link align-middle"
                >
                  <img src="@/assets/media/image/layer.svg" alt="icon" />
                  <span>{{ $t("services") }}</span> </router-link
                ><router-link
                  to="/book-offline-service"
                  replace
                  class="nav-link align-middle"
                >
                  <img src="@/assets/media/image/layer.svg" alt="icon" />
                  <span>{{ $t("book_offline_service") }}</span> </router-link
                ><router-link
                  to="/book-offline-service/index"
                  replace
                  class="nav-link align-middle"
                >
                  <img src="@/assets/media/image/layer.svg" alt="icon" />
                  <span>{{ $t("books_offline_service") }}</span>
                </router-link>
              </li>
            </AccordionTab>
          </Accordion>
        </template>
        <!-- SERVICES -->

        <!------------------------- Transportation ------------------------------>
        <template v-if="checkAllowedPermissionServices('TRANSPORT')">
          <Accordion>
            <AccordionTab :header="$t('transportation')">
              <Accordion v-if="checkAllowedPermissionApps('SYSTEM')">
                <AccordionTab :header="`${$t('B2B')}`">
                  <li>
                    <router-link
                      to="/contract-transport"
                      replace
                      class="nav-link align-middle"
                    >
                      <img
                        src="@/assets/media/image/icon/hgozat.svg"
                        alt="icon"
                      />
                      <span>{{ $t("choose_from_contracts") }}</span>
                    </router-link>
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="`${$t('settings')}`">
                  <li>
                    <router-link
                      to="/car_cat"
                      replace
                      class="nav-link align-middle"
                    >
                      <img
                        src="@/assets/media/image/تصنيف السيارة.svg"
                        alt="icon"
                      />
                      <span>{{ $t("car_category") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/car_type"
                      replace
                      class="nav-link align-middle"
                    >
                      <img
                        src="@/assets/media/image/نوع السيارة.svg"
                        alt="icon"
                      />
                      <span>{{ $t("car_type") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/routers"
                      replace
                      class="nav-link align-middle"
                    >
                      <img src="@/assets/media/image/خط السير.svg" alt="icon" />
                      <span>{{ $t("Routes") }}</span>
                    </router-link>
                  </li>
                </AccordionTab>
              </Accordion>
            </AccordionTab>
          </Accordion>
        </template>
        <!------------------------- Transportation ------------------------------>

        <!------------------------- visa ------------------------------>
        <template v-if="checkAllowedPermissionServices('PACKAGE')">
          <Accordion>
            <AccordionTab :header="$t('visa')">
              <Accordion>
                <AccordionTab :header="`${$t('B2B')}`">
                  <li>
                    <router-link to="/visa/contracts" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/note-text.svg"
                        alt="icon"
                      />
                      <span>{{ $t("contract") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/visa/add_contract" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/note-favorite.svg"
                        alt="icon"
                      />
                      <span>{{ $t("add_contract") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="`${$t('settings')}`">
                  <li>
                    <router-link to="/visa/add" class="nav-link px-0">
                      <img src="@/assets/media/image/card-add.svg" alt="icon" />
                      <span>{{ $t("add visa type") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/visa" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/card-tick.svg"
                        alt="icon"
                      />
                      <span>{{ $t("visa type") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
            </AccordionTab>
          </Accordion>
        </template>
        <!------------------------- visa ------------------------------>

        <!------------------------- Insurances ------------------------------>
        <template v-if="checkAllowedPermissionServices('PACKAGE')">
          <Accordion>
            <AccordionTab :header="$t('Insurances')">
              <Accordion>
                <AccordionTab :header="`${$t('Insurances')}`">
                  <li>
                    <router-link
                      to="/Insurance_Companies"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/buildings.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Insurance_Companies") }}</span></router-link
                    >
                  </li>

                  <li>
                    <router-link to="/Insurance_Type" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/shield-search.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Insurance_Type") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
            </AccordionTab>
          </Accordion>
        </template>
        <!------------------------- Insurances ------------------------------>

        <!------------------------- accounts ------------------------------>
        <template v-if="checkAllowedPermissionApps('SYSTEM')">
          <Accordion>
            <AccordionTab :header="$t('accounts')">
              <li class="nav-item" v-if="stage == 1">
                <router-link
                  to="/organization-tree"
                  replace
                  class="nav-link align-middle"
                >
                  <img src="@/assets/media/image/icon/tree.svg" alt="icon" />
                  <span>{{ $t("company_tree") }}</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link to="/tree" replace class="nav-link align-middle">
                  <img src="@/assets/media/image/icon/tree.svg" alt="icon" />
                  <span>{{ $t("tree") }}</span>
                </router-link>
              </li>

              <Accordion>
                <AccordionTab :header="`${$t('company transactions')}`">
                  <li>
                    <router-link to="/exchange" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("exchange") }}</span></router-link
                    >
                  </li>

                  <li>
                    <router-link to="/collect-invoices" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("collect_invoices") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/collect-invoices-index"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{
                        $t("show_collect_invoices")
                      }}</span></router-link
                    >
                  </li>

                  <li>
                    <router-link to="/receipt" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("recipt") }}</span></router-link
                    >
                  </li>

                  <li>
                    <router-link to="/restrictions" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("daily_constuct") }}</span></router-link
                    >
                  </li>

                  <!-- <li>
                    <router-link to="/add/exchange" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("exchange_paper") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/add/receipt" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("receipt_paper") }}</span></router-link
                    >
                  </li> -->
                  <li>
                    <router-link to="/show/receipt" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("show_receipt") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/show/exchange" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("show_exchange") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="$t('Approval of reservations')">
                  <li>
                    <router-link
                      to="/confirm-multiReservation"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/document-copy.svg"
                        alt="icon"
                      />
                      <span>{{
                        $t("confirm_reservation_unposted")
                      }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/confirm-reservation-unposted"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/document-copy.svg"
                        alt="icon"
                      />
                      <span>{{
                        $t("confirm_reservation_posted")
                      }}</span></router-link
                    >
                  </li>

                  <li>
                    <router-link to="/post_to_account" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Approval of payments") }}</span></router-link
                    >
                  </li>

                  <li>
                    <router-link to="/paid-supplier" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("supplier_paids") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="`${$t('banks')}`">
                  <li>
                    <router-link to="/account/add" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Add Bank Account") }}</span></router-link
                    >
                  </li>

                  <li>
                    <router-link to="/account" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Bank Accounts") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="`${$t('Safe')}`">
                  <li>
                    <router-link to="/safe/add" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Add Safe") }}</span></router-link
                    >
                  </li>

                  <li>
                    <router-link to="/safe" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("safe") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="`${$t('reports')}`">
                  <li>
                    <router-link
                      to="/movement-detection/safe"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span
                        >{{ $t("report") }} {{ $t("safe") }}</span
                      ></router-link
                    >
                  </li>

                  <li>
                    <router-link
                      to="/movement-detection/cost_center"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span
                        >{{ $t("report") }} {{ $t("cost_center") }}</span
                      ></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/movement-detection/client"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span
                        >{{ $t("report") }} {{ $t("client") }}</span
                      ></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/movement-detection/client-accounts"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span
                        >{{ $t("report") }} {{ $t("client-accounts") }}</span
                      ></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/movement-detection/suppliers"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span
                        >{{ $t("report") }} {{ $t("suppliers") }}</span
                      ></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/movement-detection/sales"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span
                        >{{ $t("report") }} {{ $t("sales") }}</span
                      ></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/movement-detection/client_balance"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span
                        >{{ $t("report") }} {{ $t("client_balance") }}</span
                      ></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/movement-detection/purchases"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span
                        >{{ $t("report") }} {{ $t("purchases") }}</span
                      ></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/student-notes" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span
                        >{{ $t("report") }} {{ $t("Ledger") }}</span
                      ></router-link
                    >
                  </li>

                  <li>
                    <router-link to="/account_statement" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("account_statement") }}</span></router-link
                    >
                  </li>

                  <li>
                    <router-link
                      to="/general-account-statement"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{
                        $t("general_account_statement_to")
                      }}</span></router-link
                    >
                  </li>
                  <!-- <li>
                    <router-link to="#" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span
                        >{{ $t("report") }} {{ $t("budget") }}</span
                      ></router-link
                    >
                  </li> -->
                  <li>
                    <router-link to="/report_hotel" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span
                        >{{ $t("report") }} {{ $t("hotels") }}</span
                      ></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/report_flight" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span
                        >{{ $t("report") }}
                        {{ $t("flight_company_name") }}</span
                      ></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="`${$t('Financial statement')}`">
                  <li>
                    <router-link to="/FinancialCenter" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/document-copy.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Financial Center") }}</span></router-link
                    >
                  </li>

                  <li>
                    <router-link to="/incomeList" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/document-copy.svg"
                        alt="icon"
                      />
                      <span>{{ $t("income_list") }}</span></router-link
                    >
                  </li>

                  <li>
                    <router-link to="/trial-balance" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span
                        >{{ $t("report") }} {{ $t("Trial Balance") }}</span
                      ></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="$t('Cost_center')">
                  <li class="nav-item">
                    <router-link
                      to="/cost_center/add"
                      replace
                      class="nav-link align-middle"
                    >
                      <img
                        src="@/assets/media/image/icon/tree.svg"
                        alt="icon"
                      />
                      <span>{{ $t("add_cost_center") }}</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link
                      to="/cost_center"
                      replace
                      class="nav-link align-middle"
                    >
                      <img
                        src="@/assets/media/image/icon/tree.svg"
                        alt="icon"
                      />
                      <span>{{ $t("cost_centers") }}</span>
                    </router-link>
                  </li>
                </AccordionTab>
              </Accordion>
            </AccordionTab>
          </Accordion>
        </template>
        <!------------------------- accounts ------------------------------>

        <!------------------------- Website ------------------------------>
        <template v-if="checkAllowedPermissionApps('WEBSITE')">
          <Accordion>
            <AccordionTab :header="$t('Website Setting')">
              <Accordion>
                <AccordionTab :header="$t('coupons')">
                  <li>
                    <router-link to="/coupons/add" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/receipt-add.svg"
                        alt="icon"
                      />
                      <span>{{ $t("add coupon") }}</span></router-link
                    >
                  </li>

                  <li>
                    <router-link to="/coupons" class="nav-link px-0">
                      <img src="@/assets/media/image/ticket.svg" alt="icon" />
                      <span>{{ $t("coupon") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="$t('main_page')">
                  <li>
                    <router-link to="/services_website" class="nav-link px-0">
                      <img src="@/assets/media/image/crown.svg" alt="icon" />
                      <span>{{ $t("Main_features") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/client_opinion" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/messages-2.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Clients Opinion") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/web_features/add" class="nav-link px-0">
                      <img src="@/assets/media/image/mobile.svg" alt="icon" />
                      <span>{{ $t("Web Features") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/app_section" class="nav-link px-0">
                      <img src="@/assets/media/image/mobile.svg" alt="icon" />
                      <span>{{ $t("App Application") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/banners/hotels" class="nav-link px-0">
                      <img src="@/assets/media/image/mobile.svg" alt="icon" />
                      <span
                        >{{ $t("all_banners") }} {{ $t("hotels") }}</span
                      ></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/banners/flight" class="nav-link px-0">
                      <img src="@/assets/media/image/mobile.svg" alt="icon" />
                      <span
                        >{{ $t("all_banners") }} {{ $t("flight") }}</span
                      ></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/banners/package" class="nav-link px-0">
                      <img src="@/assets/media/image/mobile.svg" alt="icon" />
                      <span
                        >{{ $t("all_banners") }} {{ $t("package") }}</span
                      ></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="$t('about_us')">
                  <li>
                    <router-link to="/header_about" class="nav-link px-0">
                      <img src="@/assets/media/image/kanban.svg" alt="icon" />
                      <span>{{ $t("first_sec") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/about_second" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/format-square.svg"
                        alt="icon"
                      />
                      <span>{{ $t("categories") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/client-adventage" class="nav-link px-0">
                      <img src="@/assets/media/image/people.svg" alt="icon" />
                      <span>{{ $t("client-adventage") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="$t('Policies')">
                  <li>
                    <router-link to="/terms" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/shield-slash.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Terms and Conditions") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/policy" class="nav-link px-0">
                      <img src="@/assets/media/image/eye.svg" alt="icon" />
                      <span>{{ $t("privacy_policy") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/faqs" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/warning-2.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Faqs") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="$t('Blogs')">
                  <li>
                    <router-link to="/blogs-website/add" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/add-square-2.svg"
                        alt="icon"
                      />
                      <span>{{ $t("add blog") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/blogs-website" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/finger-scan.svg"
                        alt="icon"
                      />
                      <span>{{ $t("show") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/categorey-blogs" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/format-circle.svg"
                        alt="icon"
                      />
                      <span>{{ $t("categories") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/hastag-blogs" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/filter-search.svg"
                        alt="icon"
                      />
                      <span>{{ $t("hash_tags") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="$t('Destinations')">
                  <li>
                    <router-link to="/visit_website" class="nav-link px-0">
                      <img src="@/assets/media/image/building.svg" alt="icon" />
                      <span>{{
                        $t("The most visited places")
                      }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/all-flight-companies"
                      class="nav-link px-0"
                    >
                      <img src="@/assets/media/image/airplane.svg" alt="icon" />
                      <span>{{ $t("flight companies") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/sections" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/buildings-2.svg"
                        alt="icon"
                      />
                      <span>{{ $t("hotel_section") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="$t('Contact Us')">
                  <li class="nav-item">
                    <router-link to="/contact-website" class="nav-link">
                      <img
                        src="@/assets/media/image/device-message.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Contact Us") }}</span></router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/news" class="nav-link">
                      <img
                        src="@/assets/media/image/device-message.svg"
                        alt="icon"
                      />
                      <span>{{ $t("news") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
            </AccordionTab>
          </Accordion>
        </template>
        <!------------------------- Website ------------------------------>

        <!------------------------- SYSTEM ------------------------------>
        <template
          v-if="
            checkAllowedPermissionApps('SYSTEM') ||
            checkAllowedPermissionApps('WEBSITE')
          "
        >
          <Accordion>
            <AccordionTab :header="$t('System Setting')">
              <Accordion v-if="checkAllowedPermissionApps('WEBSITE')">
                <AccordionTab :header="$t('B2C')">
                  <li>
                    <router-link
                      to="/website/header_images"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/gallery.svg"
                        alt="icon"
                      />
                      <span>{{ $t("header_images") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/over_price" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/chart.svg"
                        alt="icon"
                      />
                      <span>{{ $t("over_price") }}</span></router-link
                    >
                  </li>

                  <li>
                    <router-link to="/payment_fees" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/chart.svg"
                        alt="icon"
                      />
                      <span>{{ $t("payment_fees") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/wallet" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("The Wallet") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/loader" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>{{ $t("loader") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion v-if="checkAllowedPermissionApps('SYSTEM')">
                <AccordionTab :header="$t('B2B')">
                  <li>
                    <router-link to="/add-home-hotels" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/company-data.svg"
                        alt="icon"
                      />
                      <span>{{ $t("special_hotels") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/website/setting" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/company-data.svg"
                        alt="icon"
                      />
                      <span>{{ $t("company_data") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/terms_and_conditions"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/company-data.svg"
                        alt="icon"
                      />
                      <span>{{ $t("terms_and_conditions") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/update-invoice-theme"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/company-data.svg"
                        alt="icon"
                      />
                      <span>{{ $t("edit invoice theme") }}</span></router-link
                    >
                  </li>
                  <li v-if="stages">
                    <router-link to="/invoice-theme/show" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/company-data.svg"
                        alt="icon"
                      />
                      <span>{{ $t("invoice theme") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/room" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/hotels-buld.svg"
                        alt="icon"
                      />
                      <span>{{ $t("rooms") }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/organization-hotels"
                      class="nav-link px-0"
                    >
                      <img
                        src="@/assets/media/image/icon/company-data.svg"
                        alt="icon"
                      />
                      <span>{{ $t("Private hotels") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="$t('currency')">
                  <li>
                    <router-link to="/currency/add" class="nav-link px-0">
                      <i class="fa-solid fa-coins"></i>
                      <span>{{ $t("add_currency") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/currencies" class="nav-link px-0">
                      <i class="fa-brands fa-gg-circle"></i>
                      <span>{{ $t("currency") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/defaultCurrency" class="nav-link px-0">
                      <i class="fa-solid fa-dollar-sign"></i>
                      <span>{{ $t("default_currency") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="$t('setting_send')">
                  <li>
                    <router-link to="/email-Setting" class="nav-link px-0">
                      <i class="fa-solid fa-envelope mx-1"></i>
                      <span>{{ $t("email_settings") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/whatsapp-config" class="nav-link px-0">
                      <i class="fa-brands fa-whatsapp mx-1"></i>
                      <span> {{ $t("whatsapp") }} </span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="$t('employ')">
                  <!-- <router-link
                    to="/employees"
                    class="nav-link d-flex align-items-center px-0 border-0"
                  >
                    <img
                      src="@/assets/media/image/icon/agent-account.svg "
                      alt="icon"
                    />
                    <span>{{ $t("employ") }}fffff</span></router-link
                  > -->
                  <li>
                    <router-link to="/employees" class="nav-link px-0">
                      <i class="fa-solid fa-user mx-1"></i>
                      <span>{{ $t("employ") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/permissionrole" class="nav-link px-0">
                      <i class="fa-solid fa-lock mx-1"></i>
                      <span>{{ $t("roles name") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion>
                <AccordionTab :header="$t('accounts')">
                  <li>
                    <router-link
                      to="/evidence-limitations"
                      class="nav-link px-0"
                    >
                      <i class="fa-solid fa-lock mx-1"></i>
                      <span>{{ $t("Evidence limitations") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion v-if="stage == 1">
                <AccordionTab :header="$t('provider')">
                  <li>
                    <router-link to="/provider/add" class="nav-link px-0">
                      <i class="fa-solid fa-coins"></i>
                      <span>{{ $t("add_provider") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/provider" class="nav-link px-0">
                      <i class="fa-brands fa-gg-circle"></i>
                      <span>{{ $t("Service provider") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion v-if="stage == 1">
                <AccordionTab :header="$t('setting')">
                  <li>
                    <router-link to="/setting" class="nav-link px-0">
                      <img
                        src="@/assets/media/image/icon/restrict.svg"
                        alt="icon"
                      />
                      <span>B2B</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
              <Accordion v-if="stage == 1">
                <AccordionTab :header="$t('General_Settings')">
                  <li>
                    <router-link to="/countries" class="nav-link px-0">
                      <i class="fa-solid fa-coins"></i>
                      <span>{{ $t("The Countries") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/cities" class="nav-link px-0">
                      <i class="fa-brands fa-gg-circle"></i>
                      <span>{{ $t("The Cities") }}</span></router-link
                    >
                  </li>
                  <li>
                    <router-link to="/nationality" class="nav-link px-0">
                      <i class="fa-brands fa-gg-circle"></i>
                      <span>{{ $t("nationalities") }}</span></router-link
                    >
                  </li>
                </AccordionTab>
              </Accordion>
            </AccordionTab>
          </Accordion>
        </template>
        <!------------------------- SYSTEM ------------------------------>
        <template v-if="checkAllowedPermissionServices('TRIPS')">
          <Accordion>
            <AccordionTab :header="$t('affiliate_system')">
              <li>
                <router-link
                  to="/Affiliates"
                  replace
                  class="nav-link align-middle"
                >
                  <img
                    src="@/assets/media/image/folder-connection.svg"
                    alt="icon"
                  />
                  <span>{{ $t("Affiliates") }}</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/stage_percentages"
                  replace
                  class="nav-link align-middle"
                >
                  <img src="@/assets/media/image/convertshape.svg" alt="icon" />
                  <span>{{ $t("Stages") }}</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/penndingMarkters"
                  replace
                  class="nav-link align-middle"
                >
                  <img src="@/assets/media/image/information.svg" alt="icon" />
                  <span>{{ $t("Pending") }} {{ $t("Markters") }}</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/penddingGifts"
                  replace
                  class="nav-link align-middle"
                >
                  <img src="@/assets/media/image/dent-(dent).svg" alt="icon" />
                  <span>{{ $t("Pending") }} {{ $t("Gifts") }}</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/AddPoints"
                  replace
                  class="nav-link align-middle"
                >
                  <img src="@/assets/media/image/save-2.svg" alt="icon" />
                  <span>{{ $t("Book Count") }}</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/markters"
                  replace
                  class="nav-link align-middle"
                >
                  <img src="@/assets/media/image/user-tag.svg" alt="icon" />
                  <span>{{ $t("Markters") }}</span>
                </router-link>
              </li>
              <li>
                <router-link to="/gift" replace class="nav-link align-middle">
                  <img src="@/assets/media/image/cup.svg" alt="icon" />
                  <span>{{ $t("Gifts") }}</span>
                </router-link>
              </li>
            </AccordionTab>
          </Accordion>
        </template>
        <template v-if="checkAllowedPermissionServices('TRIPS')">
          <Accordion v-if="stage == 1">
            <AccordionTab :header="$t('clients_portal')">
              <li>
                <router-link
                  to="/portal_user"
                  replace
                  class="nav-link align-middle"
                >
                  <img
                    src="@/assets/media/image/folder-connection.svg"
                    alt="icon"
                  />
                  <span>{{ $t("clients_portal") }}</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/client-type-portal"
                  replace
                  class="nav-link align-middle"
                >
                  <img
                    src="@/assets/media/image/folder-connection.svg"
                    alt="icon"
                  />
                  <span>{{ $t("client type portal") }}</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/add/portal-setting"
                  replace
                  class="nav-link align-middle"
                >
                  <img
                    src="@/assets/media/image/folder-connection.svg"
                    alt="icon"
                  />
                  <span>{{ $t("portal setting") }}</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/portal-wallet"
                  replace
                  class="nav-link align-middle"
                >
                  <img
                    src="@/assets/media/image/folder-connection.svg"
                    alt="icon"
                  />
                  <span>{{ $t("The Wallet") }}</span>
                </router-link>
              </li>
            </AccordionTab>
          </Accordion>
        </template>
      </Sidebar>
    </KeepAlive>
    <i class="fa-solid fa-bars" @click="visible = true"></i>
  </div>
</template>

<script>
import Sidebar from "primevue/sidebar";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

export default {
  data() {
    return {
      stages: JSON.parse(localStorage.getItem("user")).stage === 1,
      visible: false,
      stage: "",
      isSidebarClosed: false,
      userEmail: JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user")).email
        : null,
      userPassword: localStorage.getItem("portalAuth") ?? null,
      direction:
        localStorage.getItem("lang") == null
          ? "left"
          : localStorage.getItem("lang") === "ar"
            ? "right"
            : "left",
      targetEmail: "info@tripsavisor.com",
      logo: JSON.parse(localStorage.getItem("user")).logo,
      apps: [],
      isHotelsB2CSubmenuActive: false,
      isHotelsB2BSubmenuActive: false,
      isHotelsSettingSubmenuActive: false,
      isFlightsB2CSubmenuActive: false,
      isFlightsB2BSubmenuActive: false,
      isFlightsSettingSubmenuActive: false,
      isTourismProgramsSubmenuActive: false,
      isTourismProgramsSettingSubmenuActive: false,
      isClientsSubmenuActive: false,
      isServicesSubmenuActive: false,
      isTransportationSubmenuActive: false,
      isTransportationSettingSubmenuActive: false,
      isVisaSubmenuActive: false,
      isVisaSettingSubmenuActive: false,
      isInsurancesSubmenuActive: false,
      isTransactionsSubmenuActive: false,
      isBankSubmenuActive: false,
      isBankSavesSubmenuActive: false,
      isBankReportsSubmenuActive: false,
      isCouponsSubmenuActive: false,
      isMainPageSubmenuActive: false,
      isAboutuspageSubmenuActive: false,
      isPoliciesSubmenuActive: false,
      isBlogSubmenuActive: false,
      isDestinationsSubmenuActive: false,
      isB2CSystemSettingSubmenuActive: false,
      isCurrencySubmenuActive: false,
      isProviderSubmenuActive: false,
      isGeneralSettingsSubmenuActive: false,
      isAffiliatesSubmenuActive: false,
    };
  },
  components: {
    Sidebar,
    Accordion,
    AccordionTab,
  },
  created() {
    this.apps = JSON.parse(localStorage.getItem("user")).apps;
    this.services = JSON.parse(localStorage.getItem("user")).services;
    this.stage = JSON.parse(localStorage.getItem("user")).stage;
    this.is_master = JSON.parse(localStorage.getItem("user")).is_master;

    this.app_name = this.apps && this.apps?.map((app) => app);
    this.service_name =
      this.services && this.services?.map((service) => service);

    // Set isHotelsB2CSubmenuActive based on active route
    this.isHotelsB2CSubmenuActive =
      this.$route.path === "/book_table_type/b2c" ||
      this.$route.path === "/arrival_guests/b2c" ||
      this.$route.path === "/room_guests/b2c" ||
      this.$route.path === "/BookingProfit";

    // Set isHotelsB2BSubmenuActive based on active route
    this.isHotelsB2BSubmenuActive =
      this.$route.path === "/hotels" ||
      this.$route.path === "/book_offline" ||
      this.$route.path === "/book_table_type/b2b" ||
      this.$route.path === "/contract" ||
      this.$route.path === "/contract/add" ||
      this.$route.path === "/room_guests/b2b" ||
      this.$route.path === "/arrival_guests/b2b" ||
      this.$route.path === "/hotels/book_offline";

    // Set isHotelsSettingSubmenuActive based on active route
    this.isHotelsSettingSubmenuActive =
      this.$route.path === "/allHotels" ||
      this.$route.path === "/room" ||
      this.$route.path === "/view" ||
      this.$route.path === "/meal" ||
      this.$route.path === "/bed";

    // Set isFlightsB2CSubmenuActive based on active route
    this.isFlightsB2CSubmenuActive =
      this.$route.path === "/b2cUsersflight" ||
      this.$route.path === "/b2cUsersflight/arrival_guests";

    // Set isFlightsB2BSubmenuActive based on active route
    this.isFlightsB2BSubmenuActive =
      this.$route.path === "/flight" ||
      this.$route.path === "/flight-booking" ||
      this.$route.path === "/reservation/show" ||
      this.$route.path === "/flightsRevenue" ||
      this.$route.path === "/flight-contract";

    // Set isFlightsSettingSubmenuActive based on active route
    this.isFlightsSettingSubmenuActive =
      this.$route.path === "/airport" || this.$route.path === "/flight-company";

    // Set isTourismProgramsSubmenuActive based on active route
    this.isTourismProgramsSubmenuActive =
      this.$route.path === "/bookUmrah" ||
      this.$route.path === "/package/tourism" ||
      this.$route.path === "/package/umrah" ||
      this.$route.path === "/books_Package" ||
      this.$route.path === "/packageRevenue";

    // Set isTourismProgramsSettingSubmenuActive based on active route
    this.isTourismProgramsSettingSubmenuActive =
      this.$route.path === "/allPackageTypes" ||
      this.$route.path === "/package_Star_Category";

    // Set isClientsSubmenuActive based on active route
    this.isClientsSubmenuActive =
      this.$route.path === "/clients" || this.$route.path === "/suppliers";

    // Set isServicesSubmenuActive based on active route
    this.isServicesSubmenuActive =
      this.$route.path === "/services_dashboard" ||
      this.$route.path === "/suppliers";

    // Set isTransportationSubmenuActive based on active route
    this.isTransportationSubmenuActive =
      this.$route.path === "/contract-transport";

    // Set isTransportationSettingSubmenuActive based on active route
    this.isTransportationSettingSubmenuActive =
      this.$route.path === "/car_cat" ||
      this.$route.path === "/car_type" ||
      this.$route.path === "/routers";

    // Set isVisaSubmenuActive based on active route
    this.isVisaSubmenuActive =
      this.$route.path === "/visa/contracts" ||
      this.$route.path === "/visa/add_contract";

    // Set isVisaSettingSubmenuActive based on active route
    this.isVisaSettingSubmenuActive =
      this.$route.path === "/visa/add" || this.$route.path === "/visa";

    // Set isInsurancesSubmenuActive based on active route
    this.isInsurancesSubmenuActive =
      this.$route.path === "/Insurance_Companies" ||
      this.$route.path === "/Insurance_Type";

    // Set isTransactionsSubmenuActive based on active route
    this.isTransactionsSubmenuActive =
      this.$route.path === "/exchange" ||
      this.$route.path === "/receipt" ||
      this.$route.path === "/restrictions";

    // Set isBankSubmenuActive based on active route
    this.isBankSubmenuActive =
      this.$route.path === "/account/add" || this.$route.path === "/account";

    // Set isBankSavesSubmenuActive based on active route
    this.isBankSavesSubmenuActive =
      this.$route.path === "/safe/add" || this.$route.path === "/safe";

    // Set isBankReportsSubmenuActive based on active route
    this.isBankReportsSubmenuActive =
      this.$route.path === "/student-notes" ||
      this.$route.path === "/trial-balance" ||
      this.$route.path === "/account_statement";

    // Set isCouponsSubmenuActive based on active route
    this.isCouponsSubmenuActive =
      this.$route.path === "/coupons/add" || this.$route.path === "/coupons";

    // Set isMainPageSubmenuActive based on active route
    this.isMainPageSubmenuActive =
      this.$route.path === "/services_website" ||
      this.$route.path === "/client_opinion" ||
      this.$route.path === "/sections" ||
      this.$route.path === "/web_features";

    // Set isAboutuspageSubmenuActive based on active route
    this.isAboutuspageSubmenuActive =
      this.$route.path === "/header_about" ||
      this.$route.path === "/about_second" ||
      this.$route.path === "/client-adventage";

    // Set isAboutuspageSubmenuActive based on active route
    this.isPoliciesSubmenuActive =
      this.$route.path === "/terms" ||
      this.$route.path === "/policy" ||
      this.$route.path === "/faqs";

    // Set isBlogSubmenuActive based on active route
    this.isBlogSubmenuActive =
      this.$route.path === "/blogs-website/add" ||
      this.$route.path === "/blogs-website" ||
      this.$route.path === "/blogs-category" ||
      this.$route.path === "/blogs-hashtag";

    // Set isDestinationsSubmenuActive based on active route
    this.isDestinationsSubmenuActive =
      this.$route.path === "/visit_website" ||
      this.$route.path === "/all-flight-companies";

    // Set isB2CSystemSettingSubmenuActive based on active route
    this.isB2CSystemSettingSubmenuActive =
      this.$route.path === "/website/setting" ||
      this.$route.path === "/website/header_images" ||
      this.$route.path === "/over_price" ||
      this.$route.path === "/payment_fees" ||
      this.$route.path === "/wallet" ||
      this.$route.path === "/loader";

    // Set isCurrencySubmenuActive based on active route
    this.isCurrencySubmenuActive =
      this.$route.path === "/currency/add" ||
      this.$route.path === "/currencies" ||
      this.$route.path === "/defaultCurrency";

    // Set isProviderSubmenuActive based on active route
    this.isProviderSubmenuActive =
      this.$route.path === "/provider" || this.$route.path === "/provider/add";

    // Set isGeneralSettingsSubmenuActive based on active route
    this.isGeneralSettingsSubmenuActive =
      this.$route.path === "/countries" ||
      this.$route.path === "/cities" ||
      this.$route.path === "/nationality";

    // Set isAffiliatesSubmenuActive based on active route
    this.isAffiliatesSubmenuActive =
      this.$route.path === "/Affiliates" ||
      this.$route.path === "/stage_percentages" ||
      this.$route.path === "/penndingMarkters" ||
      this.$route.path === "/penddingGifts" ||
      this.$route.path === "/bookCount" ||
      this.$route.path === "/markters" ||
      this.$route.path === "/gift";
  },

  methods: {
    checkAllowedPermissionApps(permission_name) {
      if (!this.is_master) {
        if (this.app_name && this.app_name.includes("*")) {
          return true;
        } else {
          const permissions = [permission_name];
          return permissions.some((per) =>
            this.allowedActionsPermissionsApps(per)
          );
        }
      } else {
        return true;
      }
    },

    checkAllowedPermissionServices(permission_name) {
      if (!this.is_master) {
        if (this.service_name && this.service_name.includes("*")) {
          return true;
        } else {
          const permissions = [permission_name];
          return permissions.some((per) =>
            this.allowedActionsPermissionsServices(per)
          );
        }
      } else {
        return true;
      }
    },

    allowedActionsPermissionsApps(perName) {
      return this.app_name && this.app_name.some((per) => per == perName);
    },

    allowedActionsPermissionsServices(perName) {
      return (
        this.service_name && this.service_name.some((per) => per == perName)
      );
    },
  },
  watch: {
    "$route.path": {
      handler() {
        this.visible = false;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.logo {
  height: 80px;
  width: 170px;
  object-fit: contain;
}
ul {
  list-style: none;
}
li {
  padding: 0 !important;
  list-style: none;
}
.nav-pills {
  width: 100%;
  font-family: "semibold";
  padding: 25px 5px;

  .accordion-button {
    display: block;

    &.collapsed {
      background-color: transparent !important;
      box-shadow: none;

      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      float: right;
      font-size: 1rem;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      color: $PrimaryColor;
      transform: rotate(0deg);
      display: none;
    }
  }
  .nav-link.active {
    background-color: #fff !important;
  }
}
</style>
