<template>
  <div>
    fasdfgadgadsg
    <h1>ZATCA E-Invoicing Example</h1>
    <!--    <button @click="generateInvoice">Generate Invoice</button>-->
  </div>
</template>

<!--<script>-->
<!--// Import the required functions from zatca-xml-js-->
<!--import { createInvoice, validateInvoice } from "zatca-xml-js";-->

<!--export default {-->
<!--  name: "InvoiceGenerator",-->
<!--  methods: {-->
<!--    generateInvoice() {-->
<!--      // Example invoice data-->
<!--      const invoiceData = {-->
<!--        seller: {-->
<!--          name: "Your Company",-->
<!--          vatNumber: "1234567890",-->
<!--        },-->
<!--        buyer: {-->
<!--          name: "Customer",-->
<!--          vatNumber: "0987654321",-->
<!--        },-->
<!--        items: [-->
<!--          {-->
<!--            description: "Item 1",-->
<!--            quantity: 1,-->
<!--            unitPrice: 100,-->
<!--          },-->
<!--        ],-->
<!--        total: 100,-->
<!--      };-->

<!--      // Create an invoice-->
<!--      const invoiceXML = createInvoice(invoiceData);-->

<!--      // Validate the invoice-->
<!--      const isValid = validateInvoice(invoiceXML);-->

<!--      if (isValid) {-->
<!--        console.log("Invoice generated and validated successfully.");-->
<!--        console.log(invoiceXML);-->
<!--      } else {-->
<!--        console.error("Invoice validation failed.");-->
<!--      }-->
<!--    },-->
<!--  },-->
<!--};-->
<!--</script>-->
