<template>
    <div v-if="loading" class="loading">
        <Loading />
    </div>
    <div class="new_book">
        <div class="form_book">
            <h4 class="title">{{$t("basic_information")}}</h4>
            <p class="text">{{$t("Enter the inmates' customer data to complete the reservation process")}}</p>
            <form @submit.prevent="formBooking()">
                <div class="row" v-for="(adult, index) in adults" :key="index">
                    <div class="head">
                        <h6 class="title">{{$t("guest")}} {{$t("adult")}} {{index+1}}</h6>
                    </div>
                    <!-- <div class="check_person_responsible" v-if="index == 0">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="check_person_responsible" v-model="check_person_responsible">
                            <label class="form-check-label" for="check_person_responsible">{{$t("check_person_responsible")}}</label>
                        </div>
                    </div> -->
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="adults[index].first_name" :placeholder="$t('first_name')">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="adults[index].last_name" :placeholder="$t('last_name')">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="form-group">
                            <Multiselect
                                v-model="adults[index].salutation"
                                :options="gendersADT.map((gender) => gender.title_id)"
                                :custom-label="(opt) => gendersADT.find((x) => x.title_id == opt).title"
                                :clear-on-select="true"
                                :placeholder="$t('gender')"
                                select-label=""
                                :hide-selected="true"
                            >
                            </Multiselect>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="form-group">
                            <input type="number" class="form-control" min="11" v-model="adults[index].age" :placeholder="$t('age')">
                        </div>
                    </div>
                </div>
                <div class="row" v-for="(children, index) in childrens" :key="index">
                    <div class="head">
                        <h6 class="title">{{$t("guest")}} {{$t("the_children")}} {{adults.length+1}}</h6>
                    </div>
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="childrens[index].first_name" :placeholder="$t('first_name')">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="childrens[index].last_name" :placeholder="$t('last_name')">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="form-group">
                            <Multiselect
                                v-model="childrens[index].salutation"
                                :options="gendersCHD.map((gender) => gender.code)"
                                :custom-label="(opt) => gendersCHD.find((x) => x.code == opt).title"
                                :clear-on-select="true"
                                :placeholder="$t('gender')"
                                select-label=""
                                :hide-selected="true"
                            >
                            </Multiselect>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="form-group">
                            <input type="number" class="form-control" max="10" v-model="childrens[index].age" :placeholder="$t('age')">
                        </div>
                    </div>
                </div>
                <button class="btn PrimaryButton" type="submit">{{$t("payment")}}</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Multiselect from "vue-multiselect";
// import { VueMultiImageUpload } from "@zakerxa/vue-multiple-image-upload";
import Swal from 'sweetalert2';
import Loading from "@/components/LottieFiles/loading.vue";
// import { json } from 'body-parser';

export default {
    name: "form-book",
    props: {
        check_price: Object
    },
    data() {
        return {
            nationalities: [],
            nationalitValues: "",
            check_person_responsible: false,
            adults: JSON.parse(localStorage.getItem("roomData")).room_guests.adults,
            childrens: JSON.parse(localStorage.getItem("roomData")).room_guests.children,
            // guests: JSON.parse(localStorage.getItem("roomData")).room_guests.adults + JSON.parse(localStorage.getItem("roomData")).room_guests.children,
            guest_types: [
                {
                    id: 1,
                    title: this.$t("adult"),
                    key: "Adult"
                },
                {
                    id: 2,
                    title: this.$t("the_children"),
                    key: "Children"
                },
            ],
            gendersADT: [],
            gendersCHD: [
                {
                id: 1,
                title: "Mstr",
                code : "Mstr"
                },
                {
                id: 2,
                title: "Miss",
                code : "Miss"
                },
            ],
            component: {},
            loading: false,
            options: {},
            imageSize: 20000000, // 2MB
            formatType: ["image/jpeg", "image/png", "image/jpg"],
        }
    },
    components :{
        Multiselect,
        // VueMultiImageUpload,
        Loading
    },
    methods: {
        formBooking() {
            this.loading = true; // Start loading
            let bookData = JSON.parse(localStorage.getItem("bookData"))
            let data = {
                check_in: bookData.check_in,
                check_out: bookData.check_out,
                hotel_id: bookData.hotel_id,
                hotel_name: bookData.hotel_name,
                from_b2b: 1,
                nationality_code: bookData.nationality_code,
                passenger_country_of_residence_code: bookData.passenger_country_of_residence_code,
                rooms: [
                    {
                        room_type_name: JSON.parse(localStorage.getItem("roomData")).meta_data.room_type_name,
                        room_number: bookData.room_numbers[0],
                        rate_basis: bookData.rooms_guests[0].rate_basis,
                        allocation_details: JSON.parse(localStorage.getItem("roomData")).allocation_details,
                        meta_data: JSON.parse(localStorage.getItem("roomData")).meta_data,
                        room_guests: {
                            adults: this.adults.map((adult, index) => ({...adult, leading: index === 0})),
                            children: this.childrens.map(children => ({...children})),
                            extrabeds: [],
                        }
                    }
                ],
                // bookData.meta_data
                meta_data: {
                    credential_id: bookData.meta_data.credential_id,
                    provider_id: bookData.meta_data.provider_id,
                    hotel_result_index: bookData.meta_data.result_index,
                    session_id: bookData.meta_data.session_id
                },
            }

            if (JSON.parse(localStorage.getItem("roomData")).meta_data.room_index == null) {
                data.converter_status = 1;
            }

            axios.post("/new_book_hotel", data).then(({ data }) => {
                if (data.status == true) {
                    Swal.fire({
                        title: "",
                        text: data.message,
                        icon: "success",
                    });
                    this.loading = false; //the loading ended
                    localStorage.removeItem("hotelData");
                    localStorage.removeItem("bookDetails");
                    localStorage.removeItem("roomsSelected");
                    localStorage.setItem("newBook", JSON.stringify(data.data))
                    this.$router.push("/success_hotel_book");
                }
                else {
                    this.loading = false; //the loading ended
                    Swal.fire({
                        title: "",
                        text: data.message,
                        icon: "error",
                    });
                }
                // console.log(data)
            }).catch((error) => {
                Swal.fire({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                });
                this.loading = false; //the loading ended
            });
        },
        fetchTitleProvider() {
            // const provider_id = {provider_id: JSON.parse(localStorage.getItem("bookData")).meta_data.provider_id}
            const provider_id = {
                provider_id: 2,
            };
            axios.post(`/fetch_title_provider`, provider_id).then(({ data }) => {
                this.gendersADT = data.data
                // console.log(data.data)
            })
        }
    },
    created() {
        // this.fetch_nationalities();
        // console.log(this.adults);
        // this.pushArrayGuests();
        this.fetchTitleProvider();
    }
}
</script>

<style lang="scss" scoped>
    @import "./_book.scss";
</style>