<template>
  <table class="table" id="userTable">
    <thead>
      <tr>
        <th>{{ $t("ID") }}</th>
        <th>{{ $t("book_type") }}</th>
        <th>{{ $t("the amount") }}</th>
        <th>{{ $t("reservation_date") }}</th>
        <th>{{ $t("reservation_number") }}</th>
        <th>{{ $t("type") }}</th>
        <th>{{ $t("airport_departure_name") }}</th>
        <th>{{ $t("Interface") }}</th>
        <th>{{ $t("tour_degree") }}</th>
        <th>{{ $t("trip_number") }}</th>
        <th>{{ $t("no_passenger") }}</th>
      </tr>
    </thead>

    <tbody v-if="loading" class="text-center">
      <tr v-for="item in 10" :key="item">
        <td v-for="item in 11" :key="item">
          <Skeleton width="90%" height="1.5rem" class="mx-2"></Skeleton>
        </td>
      </tr>
    </tbody>

    <tbody v-else>
      <tr v-for="(item, index) in books" :key="index">
        <td :data-label="$t('ID')">{{ item.book.id }}</td>
        <td :data-label="$t('book_type')">
          {{ $t(item.paymentable_type.split("\\").pop()) }}
        </td>
        <td :data-label="$t('the amount')">{{ item.amount }}</td>
        <td :data-label="$t('reservation_date')">
          <div v-for="(trip, tripIndex) in item.book.trips" :key="tripIndex">
            {{ trip.arrive_date }}
          </div>
        </td>
        <td class="reservation_num" :data-label="$t('reservation_number')">
          {{ item.book.reservation_num }}
        </td>
        <td :data-label="$t('type_flight')">
          {{
            item.book.flight_type == "one_way"
              ? $t("one_way")
              : item.book.flight_type == "two_way"
              ? $t("two_way")
              : $t("multi_city")
          }}
        </td>
        <td :data-label="$t('reservation_date')">
          <div v-for="(trip, tripIndex) in item.book.trips" :key="tripIndex">
            {{ trip.departure }}
          </div>
        </td>
        <td :data-label="$t('reservation_date')">
          <div v-for="(trip, tripIndex) in item.book.trips" :key="tripIndex">
            {{ trip.arrival }}
          </div>
        </td>
        <td :data-label="$t('tour_degree')">{{ item.book.trip_degree }}</td>
        <td :data-label="$t('trip_history')">{{ item.book.trip_num }}</td>
        <td :data-label="$t('no_passenger')">{{ item.book.num_of_pax }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Skeleton from "primevue/skeleton";
export default {
  name: "flight-pending",
  props: ["books", "loading"],
  components: {
    Skeleton,
  },
};
</script>

<style></style>
