<template>
    <form @submit.prevent="submitCompanySetup()">
        <ul class="types">
            <li>
                <div class="form-group service_type">
                    <input type="radio" name="service_type" v-model="Form.service_type" @change="fetch_organization_b2b_b2c(Form.service_type)" class="wizard-required" id="defaultHotel" value="1" required hidden>
                    <label for="defaultHotel"><i class="fa-solid fa-bell-concierge"></i> {{$t("hotels")}}</label>
                </div>
            </li>
            <li>
                <div class="form-group service_type">
                    <input type="radio" name="service_type" v-model="Form.service_type" @change="fetch_organization_b2b_b2c(Form.service_type)" class="wizard-required" id="defaultFlight" value="2" required hidden>
                    <label for="defaultFlight"><i class="fa-solid fa-plane"></i> {{$t("flight")}}</label>
                </div>
            </li>
            <li>
                <div class="form-group service_type">
                    <input type="radio" name="service_type" v-model="Form.service_type" @change="fetch_organization_b2b_b2c(Form.service_type)" class="wizard-required" id="defaultTransportation" value="3" required hidden>
                    <label for="defaultTransportation"><i class="fa-solid fa-bus"></i> {{$t("transportation")}}</label>
                </div>
            </li>
            <li>
                <div class="form-group service_type">
                    <input type="radio" name="service_type" v-model="Form.service_type" @change="fetch_organization_b2b_b2c(Form.service_type)" class="wizard-required" id="defaultPrivateTrips" value="4" required hidden>
                    <label for="defaultPrivateTrips"><i class="fa-solid fa-tree"></i> {{$t("private_trips")}}</label>
                </div>
            </li>
        </ul>
        <div class="form-group">
            <label for="">{{$t("supposed_profit_ratio")}}</label>
            <input type="number" name="" id="" class="form-control" v-model="Form.percentage" :placeholder="$t('determine_profit_rate_companies')">
            <select class="form-control" v-model="Form.type_profit">
                <option value="1" selected>{{$t("percentages")}}</option>
                <option value="2">{{$t("value")}}</option>
            </select>
        </div>
        <button type="button" class="btn SecondButton" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">{{$t("customize")}}</button>
        <button type="submit" class="btn PrimaryButton">{{$t("save_changes")}}</button>
    </form>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: "customize-defult",
    data() {
        return {
            Form: {
                percentage: 0,
                type_profit: 1,
                type: this.$route.params.type_id,
                service_type: 1,
            },
        }
    },
    methods: {
        fetch_organization_b2b_b2c(service_type) {
            const filter = {
                type: this.$route.params.type_id,
                service_type: service_type == undefined ? 1 : service_type
            }
            axios.post("/fetch_organization_b2b_b2c", filter).then(({data})=>{
                this.Form.percentage = data.data.percentage;
                this.Form.type_profit = data.data.type_profit;
            })
            .catch((error) => {
                Swal.fire({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                });
            });
        },

        async submitCompanySetup() {
            axios.post("/update_organizations_default_setting", this.Form)
            .then((response) => {
                Swal.fire({
                    title: "",
                    text: response.data.message,
                    icon: "success",
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                });
            });
        },
    },
    created() {
        this.fetch_organization_b2b_b2c(1)
    }
}
</script>

<style lang="scss" scoped>
    @import "../../view/setup/_setup.scss";
</style>
