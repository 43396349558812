<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>
        <span v-if="faq_id">{{ $t("edit_Faqs") }}</span>
        <span v-else>{{ $t("add_Faqs") }}</span>
      </h4>
    </div>

    <form @submit.prevent="addFaq()">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(title, index) in faq.titles"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("describtion") }}</label>
            <div class="accordion" id="accordionExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("describtion") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(desc, index) in faq.descriptions"
                      :key="index"
                    >
                      <label class="mt-3"
                        >{{ $t("describtion") }} ({{ desc.lang }})</label
                      >
                      <Editor v-model="desc.name" editorStyle="height: 120px" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Editor from "primevue/editor";

export default {
  name: "blogsWebsiteAdd",
  data() {
    return {
      faq_id: this.$route.params.id,
      languages: ["ar", "en", "id"],
      faq: {
        titles: [],
        descriptions: [],
      },
    };
  },
  components: {
    Editor,
  },
  methods: {
    setFaqTitle() {
      this.languages.forEach((language) => {
        this.faq.titles.push({
          lang: language,
          name: "",
        });
        this.faq.descriptions.push({
          lang: language,
          name: "",
        });
      });
    },
    addFaq() {
      const formData = new FormData();

      this.faq.titles.forEach((ele) => {
        formData.append(`title_${ele.lang}`, ele.name);
      });
      this.faq.descriptions.forEach((ele) => {
        formData.append(`description_${ele.lang}`, ele.name);
      });

      if (this.$route.params.id != undefined) {
        axios
          .post(`/website/faq_update/${this.faq_id}`, formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push("/faqs");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      } else {
        axios
          .post(`/website/faq`, formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push("/faqs");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
    fetchBlog() {
      if (this.$route.params.id != undefined) {
        this.faq_id = this.$route.params.id;
        axios.get(`/fetch_faq/${this.faq_id}`).then(({ data }) => {
          this.faq.titles = data.data.titles.map((ele) => {
            return {
              lang: ele.locale,
              name: ele.title,
            };
          });

          this.faq.descriptions = data.data.descriptions.map((ele) => {
            return {
              lang: ele.locale,
              name: ele.description,
            };
          });
        });
      }
    },
  },
  mounted() {
    this.setFaqTitle();
    this.fetchBlog();
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
</style>
