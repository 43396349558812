<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="hotels">
    <div class="container">
      <div class="filter" v-if="hideFilter == true">
        <h4 class="title">{{ $t("New hotel reservation") }}</h4>
        <p class="text">
          {{
            $t(
              "Enter the following data and choose the appropriate hotel to follow the reservation process",
            )
          }}
        </p>
        <div class="selects">
          <div class="form-group">
            <input
              type="search"
              class="form-control"
              :placeholder="$t('Hotel city')"
              v-model="searchValue"
              @keyup="searchHotels"
            />
            <!-- searchValue.length > 2 && -->
            <div
              class="resultSearch"
              v-if="hotelsSearch.length || citiesSearch.length"
            >
              <h6 class="head_search" v-if="citiesSearch.length">
                {{ $t("The Cities") }}
              </h6>
              <ul class="cities" v-if="citiesSearch.length">
                <li
                  v-for="(city, index) in citiesSearch"
                  :key="index"
                  @click="
                    sendCityId(
                      city.title,
                      city.id,
                      city.country_id,
                      city.country_title,
                      city.country_code,
                    )
                  "
                >
                  <i class="fa-solid fa-location-dot"></i>
                  <div class="texts">
                    <p class="title">{{ city.title }}</p>
                    <p class="country">{{ city.country_title }}</p>
                  </div>
                </li>
              </ul>
              <h6 class="head_search" v-if="hotelsSearch.length">
                {{ $t("hotels") }}
              </h6>
              <ul class="hotels" v-if="hotelsSearch.length">
                <li
                  v-for="(hotel, index) in hotelsSearch"
                  :key="index"
                  @click="
                    sendHotelName(
                      hotel.title,
                      hotel.hotel_code,
                      hotel.city_id,
                      hotel.city_title,
                      hotel.country_id,
                      hotel.country_title,
                      hotel.country_code,
                    )
                  "
                >
                  <img
                    :src="hotel.image == '' ? defaultImage : hotel.image"
                    class="hotel_image"
                    :alt="hotel.title"
                  />
                  <div class="texts">
                    <p class="title">{{ hotel.title }}</p>
                    <p class="country">
                      {{ hotel.country_title }} , {{ hotel.city_title }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="form-group">
            <Multiselect
              v-model="nationalityValue"
              :options="nationalities"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose_nationality')"
              select-label=""
              :hide-selected="true"
              @keyup="searchNationality"
            >
            </Multiselect>
          </div>
          <!-- <div class="form-group">
            <Datepicker range v-model="selectedDate" lang="ar" />
          </div> -->
          <div class="form-group">
            <div class="card flex justify-content-center">
              <Calendar
                v-model="selectedDate"
                selectionMode="range"
                :manualInput="true"
                showIcon
              />
              <!-- :numberOfMonths="2" -->
            </div>
          </div>

          <div class="form-group">
            <div class="dropdown">
              <button
                type="button"
                class="btn btn-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                {{ $t("rooms") }} {{ rooms }} - {{ $t("Adults") }}
                {{ adults }} - {{ $t("childrens") }} {{ childrens }}
              </button>
              <div class="dropdown-menu">
                <div class="list">
                  <span>{{ $t("Adults") }}</span>
                  <div class="number">
                    <button class="btn minus" @click="minusQuantityAdults()">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <!-- <input type="text" class="count form-control" v-model=""> -->
                    <p class="count">{{ adults }}</p>
                    <button class="btn add" @click="addQuantityAdults()">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="list">
                  <span>{{ $t("childrens") }}</span>
                  <div class="number">
                    <button class="btn minus" @click="minusQuantityChildrens()">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <!-- <input type="text" class="count form-control" v-model=""> -->
                    <p class="count">{{ childrens }}</p>
                    <button class="btn add" @click="addQuantityChildrens()">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="list">
                  <span>{{ $t("rooms") }}</span>
                  <div class="number">
                    <button class="btn minus" @click="minusQuantityRooms()">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <!-- <input type="text" class="count form-control" v-model=""> -->
                    <p class="count">{{ rooms }}</p>
                    <button class="btn add" @click="addQuantityRooms()">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="btn PrimaryButton" @click="FetchHotels()">
          {{ $t("search_now") }}
        </button>
      </div>

      <!-- start search result -->
      <div
        v-if="showSearchResult == true"
        class="search_ruslt mt-4 d-flex align-items-center justify-content-between"
      >
        <h3 class="d-flex align-items-center">
          {{ $t("search_result") }} (
          <p class="mb-0">{{ searchValue }}</p>
          ,
          <p class="mb-0">{{ localDiffernce }} {{ $t("nights") }}</p>
          ,
          <p class="mb-0">{{ rooms }}{{ $t("rooms") }}</p>
          ,
          <p class="mb-0">{{ adults }}{{ $t("Adults") }}</p>
          ,
          <p class="mb-0">{{ childrens }}{{ $t("childrens") }}</p>
          )
        </h3>
        <div class="buttons">
          <button
            class="btn btn-sm btn-danger"
            type="button"
            @click="removeSearch"
          >
            <i class="fa-solid fa-filter-circle-xmark"></i>
          </button>
          <button
            v-if="hideFilter == false"
            class="btn btn-sm btn-outline-warning"
            type="button"
            @click="showSearch"
          >
            {{ $t("edit_search_result") }}
          </button>
        </div>
      </div>
      <!-- finish search result -->

      <!-- start filter_sec -->
      <div class="row filter_sec py-3 mt-4" v-if="showSearchResult == true">
        <div class="col-lg-5 col-md-12 col-12">
          <div class="options">
            <form>
              <i class="fa-solid fa-magnifying-glass"></i>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('search by name')"
                v-model="hotelName"
                @keyup="FetchHotels"
              />
            </form>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-12">
          <Multiselect
            v-model="order_price"
            :options="prices.map((price) => price.id)"
            :custom-label="(opt) => prices.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('lower_price')"
            select-label=""
            :hide-selected="true"
            @update:model-value="FetchHotels"
          >
          </Multiselect>
        </div>
        <div class="col-lg-3 col-md-4 col-12">
          <Multiselect
            v-model="order_rate"
            :options="rates.map((rate) => rate.id)"
            :custom-label="(opt) => rates.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('lower_review')"
            select-label=""
            :hide-selected="true"
            @update:model-value="FetchHotels"
          >
          </Multiselect>
        </div>
        <div class="col-lg-1 col-md-4 col-12">
          <button @click="openNewFilter()" class="btn filter_icon">
            <i class="fa-solid fa-filter"></i>
          </button>
        </div>
      </div>
      <!-- finish filter_sec -->

      <!-- start filter_third -->
      <div
        class="row filter_third mt-4 align-items-center"
        v-if="showFilterResult == true"
      >
        <div class="col-lg-3 col-md-6 col-12">
          <Multiselect
            v-model="rate"
            :options="reviews.map((review) => review.id)"
            :custom-label="(opt) => reviews.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('star_number')"
            select-label=""
            :hide-selected="true"
            @update:model-value="FetchHotels"
          >
          </Multiselect>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <!-- <Multiselect
            v-model="reservarion_type"
            :options="reservarion_types.map((type) => type.id)"
            :custom-label="
              (opt) => reservarion_types.find((x) => x.id == opt).title
            "
            :clear-on-select="true"
            :placeholder="$t('extera_service')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect> -->
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <h6>{{ $t("price") }}</h6>
          <div class="mt-3">
            <vue-slider
              v-model="priceValue"
              :min="minValue"
              :max="maxValue"
              :step="stepValue"
              :lazy="true"
              direction="rtl"
              :tooltip="'always'"
              :tooltip-placement="['top', 'bottom']"
            ></vue-slider>
          </div>
        </div>
        <div class="col-lg-2 col-md-4 col-12">
          <button @click="logFilter()" class="btn PrimaryButton">
            {{ $t("application") }}
          </button>
        </div>
      </div>
      <!-- finish filter_third -->

      <h3 class="not_found" v-if="hotels.length == 0 && searchData == true">
        {{ $t("not_found_data") }}
      </h3>

      <hotelCard
        v-else
        v-for="(hotel, index) in hotels"
        :hotel="hotel"
        :hotel_code="hotel.id"
        :hotel_name="hotel.name"
        :guest_nationality="nationalityValue.code"
        :resultIndex="
          hotel.meta_data == null ? null : hotel.meta_data.result_index
        "
        :session_id="
          hotelsData.meta_data.length == 0
            ? null
            : hotelsData.meta_data.session_id
        "
        :credential_id="hotel.credential_id"
        :provider_id="hotel.provider_id"
        :index="index"
        :key="index"
      />
      <!-- <h4 class="not_found">{{}}</h4> -->
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import moment from "moment";
import hotelCard from "@/components/hotels/hotelCardNew.vue";
import Swal from "sweetalert2";
import Loading from "@/components/LottieFiles/HotelSearchLoading.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Calendar from "primevue/calendar";
import "primevue/resources/themes/lara-light-teal/theme.css";

export default {
  name: "hotels-index",
  data() {
    return {
      checkOutDate: null,
      dates: null,
      hotels: [],
      hotelsData: {},
      countries: [],
      countryValue: "",
      defaultImage: require("@/assets/media/image/placeholder.png"), // Replace with your default image URL
      hotelName: "",
      cityTitle:
        JSON.parse(localStorage.getItem("bookDetails")) != null
          ? JSON.parse(localStorage.getItem("bookDetails")).city_title
          : "",
      searchValue:
        JSON.parse(localStorage.getItem("bookDetails")) == null
          ? this.searchValue
          : JSON.parse(localStorage.getItem("bookDetails")).city_title !=
                null ||
              JSON.parse(localStorage.getItem("bookDetails")).city_title != ""
            ? JSON.parse(localStorage.getItem("bookDetails")).city_title
            : JSON.parse(localStorage.getItem("bookDetails")).hotel_name,
      cityValue: [],
      nationalities: [],
      nationalityValue:
        JSON.parse(localStorage.getItem("bookDetails")) == null
          ? this.nationalityValue
          : JSON.parse(localStorage.getItem("bookDetails")).guest_nationality ==
              null
            ? null
            : JSON.parse(localStorage.getItem("bookDetails")).guest_nationality,
      hotelsSearch: [],
      citiesSearch: [],
      hotelValue: "",
      selectedDate:
        JSON.parse(localStorage.getItem("bookDetails")) == null
          ? null
          : [
              JSON.parse(localStorage.getItem("bookDetails")).check_in_date,
              JSON.parse(localStorage.getItem("bookDetails")).check_out_date,
            ], //[new Date(), ""]
      adults: 1,
      childrens: 0,
      rooms: 1,
      loading: false,
      showSearchResult: false,
      checkInDate: null,
      parsedData: null,
      childrenLocal: null,
      adultsLocal: null,
      roomLocal: null,
      checkinLocal: null,
      checkoutLocal: null,
      localDiffernce: "",
      prices: [
        {
          id: 1,
          title: this.$t("lower_price"),
        },
        {
          id: 2,
          title: this.$t("high_price"),
        },
      ],
      order_price:
        JSON.parse(localStorage.getItem("bookDetails")) == null
          ? this.order_price
          : JSON.parse(localStorage.getItem("bookDetails")).order_price == null
            ? null
            : JSON.parse(localStorage.getItem("bookDetails")).order_price,
      order_rate:
        JSON.parse(localStorage.getItem("bookDetails")) == null
          ? this.order_rate
          : JSON.parse(localStorage.getItem("bookDetails")).order_rate == null
            ? null
            : JSON.parse(localStorage.getItem("bookDetails")).order_rate,
      rates: [
        {
          id: 1,
          title: this.$t("lower_review"),
        },
        {
          id: 2,
          title: this.$t("high_review"),
        },
      ],
      reviews: [
        {
          id: 1,
          title: 1,
        },
        {
          id: 2,
          title: 2,
        },
        {
          id: 3,
          title: 3,
        },
        {
          id: 4,
          title: 4,
        },
        {
          id: 5,
          title: 5,
        },
      ],
      session_id: "",
      hasResults: false,
      priceValue: [0, 500], // Initial range values
      minValue: 0, // Minimum value
      maxValue: 5000, // Maximum value
      stepValue: 100, // Step increment
      showFilterResult: false,
      rate: "",
      hideFilter: true,
      searchData: false,
    };
  },
  components: {
    Multiselect,
    // Datepicker: VueDatepickerUi,
    hotelCard,
    Loading,
    VueSlider,
    Calendar,
    //Slider,
  },
  methods: {
    //Search nationality
    searchNationality(event) {
      // console.log(event)
      const searchText = { word: event == undefined ? "" : event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.nationalities);
    },

    //Search Hotels
    searchHotels(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_new_home_hotels`, searchText)
        .then(
          (res) => (
            (this.hotelsSearch = res.data.data.hotels),
            (this.citiesSearch = res.data.data.cities)
          ),
        )
        .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.hotelsSearch)
      // console.log(this.citiesSearch)
    },

    // fetch hotels
    sendHotelName(
      hotelName,
      hotelID,
      cityId,
      cityTitle,
      countryId,
      countryTitle,
      countryCode,
    ) {
      this.hotelName = hotelName;
      this.hotelID = hotelID;
      this.cityTitle = null;
      this.searchValue = hotelName;
      this.cityId = cityId;
      this.cityTitle = cityTitle;
      this.countryId = countryId;
      this.countryTitle = countryTitle;
      this.countryCode = countryCode;
      this.citiesSearch.length = [];
      this.hotelsSearch = [];
    },
    sendCityId(cityTitle, cityId, countryId, countryTitle, countryCode) {
      this.cityId = cityId;
      this.cityTitle = cityTitle;
      this.countryId = countryId;
      this.countryTitle = countryTitle;
      this.countryCode = countryCode;
      this.searchValue = cityTitle;
      this.hotelName = null;
      this.citiesSearch = [];
      this.hotelsSearch = [];
    },
    logFilter() {
      const filterFinalResults = {
        from_price: this.priceValue[0],
        to_price: this.priceValue[1],
      };
      this.FetchHotels(filterFinalResults);
    },

    openNewFilter() {
      this.showFilterResult = !this.showFilterResult;
    },
    showSearch() {
      this.hideFilter = true;
      this.showSearchResult = false;
      this.searchData = true;
      this.hotels = [];
    },
    FetchHotels(passNewFilter = {}) {
      this.loading = true; //the loading begin
      this.hideFilter = false;

      const filterTPO = {
        // city_id: this.cityId,
        // city_title: this.cityTitle,
        city_id: this.cityId,
        city_name: this.cityTitle,
        country_name: this.countryTitle,
        country_code: this.countryCode,
        converter_status: 1,
        rooms_guests: [
          {
            rate_basis: "1",
            adults_count: this.adults,
            children_count: this.childrens,
            children_ages: Array.from({ length: this.childrens }, () => 7),
          },
        ],
        check_in: moment(this.selectedDate[0]).format("YYYY-MM-DD"),
        check_out: moment(this.selectedDate[1]).format("YYYY-MM-DD"),
        meta_data: {
          credential_id: 1,
          provider_id: 1,
        },
        filter_data: {
          rate: this.rate,
          order_rate: this.order_rate,
          ...(passNewFilter && {
            // Merge the second filter conditions if secondFilter is not null
            from_price: passNewFilter.from_price,
            to_price: passNewFilter.to_price,
          }),
          star_count: this.rate,
          hotel_name: this.hotelName,
          order_price: this.order_price,
        },
      };

      const filterWebPeds = {
        // city_id: this.cityId,
        // city_title: this.cityTitle,
        city_id: this.cityId,
        city_name: this.cityTitle,
        country_name: this.countryTitle,
        country_code: this.countryCode,
        converter_status: 1,
        rooms_guests: [
          {
            rate_basis: "1",
            adults_count: this.adults,
            children_count: this.childrens,
            children_ages: Array.from({ length: this.childrens }, () => 7),
          },
        ],
        check_in: moment(this.selectedDate[0]).format("YYYY-MM-DD"),
        check_out: moment(this.selectedDate[1]).format("YYYY-MM-DD"),
        meta_data: {
          credential_id: 2,
          provider_id: 2,
        },
        filter_data: {
          rate: this.rate,
          order_rate: this.order_rate,
          ...(passNewFilter && {
            // Merge the second filter conditions if secondFilter is not null
            from_price: passNewFilter.from_price,
            to_price: passNewFilter.to_price,
          }),
          star_count: this.rate,
          hotel_name: this.hotelName,
          order_price: this.order_price,
        },
      };

      if (typeof this.nationalityValue == "object") {
        filterTPO.nationality_code = this.nationalityValue.code;
        filterTPO.passenger_country_of_residence_code =
          this.nationalityValue.code;
      }
      if (typeof this.nationalityValue == "object") {
        filterWebPeds.nationality_code = this.nationalityValue.code;
        filterWebPeds.passenger_country_of_residence_code =
          this.nationalityValue.code;
      }

      localStorage.setItem("bookDetails", JSON.stringify(filterTPO));

      // Fetch Tpo
      axios
        .post("/fetch_new_hotels", filterTPO)
        .then((response) => {
          const data = response.data;
          if (data.status === true) {
            this.loading = false; //the loading ended
            // Show the search result div after loading data
            this.showSearchResult = true;
            this.searchData = true;
            // Update hotels list by merging the existing list with the new one
            this.hotels = this.hotels.concat(data.data.data || []);

            // metaData TPO
            this.hotelsData = data.data;
            // console.log(this.hotels)
            this.session_id =
              data.data.meta_data.length == 0
                ? data.data.meta_data.session_id
                : "";
            localStorage.removeItem("bookData");
            this.retrieveBookDetailsFromLocalStorage();
          } else {
            Swal.fire({
              title: "",
              text: data.message || "Unknown error",
              icon: "error",
            });
            this.loading = false;
            this.hideFilter = true;
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response?.data?.message || "Unknown error",
            icon: "error",
          });
          this.loading = false; //the loading ended
          this.hideFilter = true;
        });

      // Fetch webpeds
      axios
        .post("/fetch_new_hotels", filterWebPeds)
        .then((response) => {
          const data = response.data;
          if (data.status == true) {
            this.loading = false; //the loading ended
            // Show the search result div after loading data
            this.showSearchResult = true;
            this.searchData = true;
            // Update hotels list by merging the existing list with the new one
            this.hotels = this.hotels.concat(data.data.data || []);
            // metaData webpeds
            this.hotelsData = data.data;
            // console.log(this.hotels)

            this.session_id =
              data.data.meta_data.length == 0
                ? data.data.meta_data.session_id
                : "";
            localStorage.removeItem("bookData");
            this.retrieveBookDetailsFromLocalStorage();
          } else {
            Swal.fire({
              title: "",
              text: data.message || "Unknown error",
              icon: "error",
            });
            this.loading = false;
            this.hideFilter = true;
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response?.data?.message || "Unknown error",
            icon: "error",
          });
          this.loading = false; //the loading ended
          this.hideFilter = true;
        });
    },

    removeSearch() {
      localStorage.removeItem("bookDetails");
      location.reload();
    },

    // Adults Minus && Add
    minusQuantityAdults() {
      if (this.adults > 1) {
        this.adults -= 1;
      }
    },
    addQuantityAdults() {
      this.adults += 1;
    },

    // childrens Minus && Add
    minusQuantityChildrens() {
      if (this.childrens > 0) {
        this.childrens -= 1;
      }
    },
    addQuantityChildrens() {
      this.childrens += 1;
    },

    // Rooms Minus && Add
    minusQuantityRooms() {
      if (this.rooms > 1) {
        this.rooms -= 1;
      }
    },
    addQuantityRooms() {
      this.rooms += 1;
    },
    // Get the data from localStorage
    retrieveBookDetailsFromLocalStorage() {
      const storedData = JSON.parse(localStorage.getItem("bookDetails"));
      if (
        storedData &&
        // storedData.childrens &&
        // storedData.adults &&
        // storedData.no_of_rooms &&
        storedData.check_in_date &&
        storedData.check_out_date
      ) {
        // Assign the value to the data property
        // this.childrenLocal = storedData.childrens;
        // this.adultsLocal = storedData.adults;
        // this.roomLocal = storedData.no_of_rooms;
        this.checkinLocal = storedData.check_in_date;
        this.checkoutLocal = storedData.check_out_date;
        // Parse the date strings into Date objects
        const checkinDate = new Date(this.checkinLocal);
        const checkoutDate = new Date(this.checkoutLocal);
        const timeDifferenceMs = checkoutDate - checkinDate;
        const timeDifferenceDays = Math.ceil(
          timeDifferenceMs / (1000 * 60 * 60 * 24),
        );
        this.localDiffernce = timeDifferenceDays;
        this.hasResults = true;
      } else {
        // Handle the case where the value is not found or undefined
        // console.log("childrens not found in localStorage");
        this.hasResults = false;
        // You can assign a default value here if needed
        // this.childrenLocal = childrenLocal;
        // this.adultsLocal = adultsLocal;
        // this.roomLocal = roomLocal;
      }
    },
  },
  created() {
    localStorage.removeItem("bookData");
    localStorage.removeItem("roomData");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("sessionData");
    this.retrieveBookDetailsFromLocalStorage();
    this.searchNationality();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_hotels.scss";
.loading {
  background-color: #fff;
}
.filter_icon {
  background-color: $PrimaryColor;
  padding: 0.55rem 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: 0.3s all ease-in-out;
  border: 1px solid $PrimaryColor;
  cursor: pointer;
  &:hover {
    background-color: white;
    i {
      color: $PrimaryColor;
    }
  }

  i {
    color: white;
    font-size: 1.2rem;
    transition: 0.3s all ease-in-out;
  }
}
.options form .form-control {
  padding: 7px 40px;
  border: 1px solid transparent;
  padding-inline-start: 3rem !important;
}
.options form i {
  right: 1rem;
  color: #b1b1b1;
  font-size: 1rem;
}
.hotels .multiselect .multiselect__tags {
  border: unset !important;
}
.vue-slider-process {
  background-color: $PrimaryColor !important;
}
.PrimaryButton {
  width: 84%;
}
.not_found {
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
