<script setup>
import { ref, computed } from "vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
// import Textarea from "primevue/textarea";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

const suppliers = ref([]);
const supplierValue = ref(null);
const amount = ref(null);
const invoices = ref(null);

const types = ref([
  {
    title: "bank",
    id: 1,
  },
  {
    title: "safe",
    id: 2,
  },
]);
const typeValue = ref(null);
const description = ref(null);
// import Datepicker from "vuejs3-datepicker";
// const dateTo = ref(null);
// const dateFrom = ref(null);
const dataArray = ref([]);
const organizationAccounts = ref([]);
const accountValue = ref(null);

// fetch suppliers
const fetchSupplires = () => {
  axios
    .post("/fetch_consumer_by_role", { role: 2, with_hotel: 1 })
    .then(({ data }) => {
      suppliers.value = data.data.data;
    });
};
const formatSupplierLabel = (supplier) => {
  return `${supplier.name} (${supplier.id})`;
};
//fetch invoices
const fetchSubInvoices = () => {
  axios
    .post("fetch_sub_invoice_supplier", { supplier_id: supplierValue.value.id })
    .then(({ data }) => {
      invoices.value = data.data;
      const obj = {
        distributed_amount: 0,
        checked: false,
      };
      invoices.value.forEach((item) => {
        Object.assign(item, obj);
      });
      // console.log(invoices.value, "fetchSubInvoices");
    });
};
//speard cost
const speardCost = () => {
  invoices.value.forEach((item) => {
    if (amount.value > 0 && amount.value >= item.supplier_remaining_amount) {
      item.distributed_amount = item.supplier_remaining_amount;
      amount.value -= item.supplier_remaining_amount;
      console.log(amount.value);
    } else if (
      amount.value > 0 &&
      amount.value < item.supplier_remaining_amount
    ) {
      item.distributed_amount = amount.value;
      amount.value = 0;
    } else {
      item.distributed_amount = 0;
    }
  });
};
const checkInvoice = (item) => {
  if (item.checked) {
    dataArray.value.push(item);
  } else {
    dataArray.value.splice(dataArray.value.indexOf(item), 1);
  }
  // console.log(dataArray.value);
};
const fetchOrganizationAccounts = (type) => {
  axios
    .post("/fetch_my_organization_accounts", { type: type })
    .then(({ data }) => {
      organizationAccounts.value = data.data.data;
      // console.log(this.organizationAccounts);
    });
};
// Computed property to calculate the sum of distributed_amount and ensure it's an integer
const totalDistributedAmount = computed(() => {
  const sum = invoices.value.reduce((total, item) => {
    return total + (item.distributed_amount || 0);
  }, 0);
  return Math.round(sum); // Ensuring the result is an integer
});

const totalAmount = computed(() => {
  const sum = invoices.value.reduce((total, item) => {
    return total + (item.supplier_remaining_amount || 0);
  }, 0);
  return Math.round(sum); // Ensuring the result is an integer
});
const totalPrice = computed(() => {
  const sum = invoices.value.reduce((total, item) => {
    return total + (item.supplier_total_amount || 0);
  }, 0);
  return Math.round(sum); // Ensuring the result is an integer
});
const totalPaid = computed(() => {
  const sum = invoices.value.reduce((total, item) => {
    return total + (item.supplier_paid_amount || 0);
  }, 0);
  return Math.round(sum); // Ensuring the result is an integer
});
const sentInvoices = () => {
  const formData = new FormData();

  formData.append("required_revision", 0);
  formData.append("consumer_id", supplierValue.value.id);
  formData.append("paid_type", 2);
  formData.append("consumer_type", 2);
  formData.append("transaction_type", 2);
  formData.append("amount", totalDistributedAmount.value + amount.value);
  formData.append("type", typeValue.value?.id);
  formData.append("is_main", 0);
  formData.append("description", description.value);
  formData.append("organization_account_id", accountValue.value?.id);
  invoices.value.forEach((item, index) => {
    Object.keys(item).forEach((key) => {
      if (key === "id") {
        formData.append(
          `invoices[${index}][base_booking_invoice_id]`,
          item[key]
        );
      } else if (key === "distributed_amount") {
        formData.append(`invoices[${index}][amount]`, item[key]);
      }
    });
  });
  console.log(totalDistributedAmount.value, "totalDistributedAmount");
  console.log(amount.value, "amount");
  axios
    .post("makePostToAccountantInvoices", formData)
    .then(({ data }) => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: data.message,
      });
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
      });
    });
};
</script>

<template>
  <form @submit.prevent="sentInvoices" class="card_style">
    <div class="d-flex gap-2 align-items-center px-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
      <h4 class="title">{{ $t("paid-supplier") }}</h4>
    </div>
    <div class="row">
      <div class="col-3 form-group">
        <label class="form-label">{{ $t("choose supplier") }}</label>
        <Multiselect
          v-model="supplierValue"
          :options="suppliers"
          label="name"
          track-by="id"
          :clear-on-select="true"
          :placeholder="$t('suppliers')"
          select-label=""
          :hide-selected="true"
          @open="fetchSupplires"
          @select="fetchSubInvoices"
          :custom-label="formatSupplierLabel"
        >
        </Multiselect>
      </div>
      <div class="col-3">
        <label for="" class="from-label">
          {{ $t("amount_paid") }}
        </label>
        <input
          type="number"
          class="form-control"
          :placeholder="$t('amount')"
          v-model="amount"
        />
      </div>
      <div class="col-3">
        <label for="" class="from-label">
          {{ $t("choose_paid_type") }}
        </label>
        <Multiselect
          v-model="typeValue"
          :options="types"
          label="title"
          track-by="id"
          :clear-on-select="true"
          :placeholder="$t('type')"
          select-label=""
          @select="fetchOrganizationAccounts(typeValue.id)"
          :hide-selected="true"
        >
        </Multiselect>
      </div>
      <div class="col-3">
        <label for="" class="from-label">
          {{ $t("choose account") }}
        </label>
        <Multiselect
          v-model="accountValue"
          :options="organizationAccounts"
          label="title"
          track-by="id"
          :clear-on-select="true"
          :placeholder="$t('account')"
          select-label=""
          :hide-selected="true"
        >
        </Multiselect>
      </div>
      <!--      <div class="col-12">-->
      <!--        <label for="" class="from-label">-->
      <!--          {{ $t("description") }}-->
      <!--        </label>-->
      <!--        <Textarea-->
      <!--          class="form-control"-->
      <!--          :placeholder="$t('description')"-->
      <!--          v-model="description"-->
      <!--          :autoResize="true"-->
      <!--        ></Textarea>-->
      <!--      </div>-->
      <div class="col-12 d-flex align-center justify-content-end mt-2">
        <button class="btn PrimaryButton" type="button" @click="speardCost">
          {{ $t("Spread the cost") }}
        </button>
      </div>
    </div>
    <hr />
    <div class="row">
      <div v-if="invoices?.length">
        <div
          class="d-flex align-center justify-content-between border rounded p-2 mt-2 bg-opacity-25 bg-secondary"
        >
          <span>{{ $t("choose") }}</span>
          <span>{{ $t("service") }}</span>
          <span>{{ $t("Document Number") }}</span>
          <span>{{ $t("price") }}</span>
          <span>{{ $t("paid") }}</span>
          <span>{{ $t("remaining") }}</span>
          <span>{{ $t("Distributed amount") }}</span>
        </div>
        <div
          v-for="invoice in invoices"
          :key="invoice.id"
          class="d-flex align-center justify-content-between border rounded p-2 mt-2 shadow"
        >
          <span
            ><input
              type="checkbox"
              name=""
              id=""
              v-model="invoice.checked"
              @change="checkInvoice(invoice)"
          /></span>
          <span>{{ invoice.type }}</span>
          <span>{{ invoice.type_id }}</span>
          <span>{{ invoice.supplier_total_amount }}</span>
          <span>{{ invoice.supplier_paid_amount }}</span>
          <span>{{ invoice.supplier_remaining_amount }}</span>
          <span
            ><input
              type="number"
              name=""
              id=""
              class="form-control"
              v-model="invoice.distributed_amount"
              :disabled="!invoice.checked"
          /></span>
        </div>
        <div
          class="col-12 d-flex align-center justify-content-between border rounded p-2 mt-2 bg-opacity-25 bg-secondary"
        >
          <span> {{ $t("total_amount") }}: {{ totalPrice }} </span>
          <span> {{ $t("paid_amount") }}: {{ totalPaid }} </span>
          <span> {{ $t("remaining_amount_of_paid") }}: {{ totalAmount }} </span>
          <span
            >{{ $t("Distributed amount") }} : {{ totalDistributedAmount }}</span
          >
        </div>
      </div>
    </div>
    <div class="col-12 d-flex align-items-center justify-content-end">
      <button class="btn SecondButton" type="submit">
        {{ $t("save_paids") }}
      </button>
    </div>
  </form>
</template>

<style scoped lang="scss">
.d-flex.align-center.justify-content-between.border.rounded.p-2.mt-2 {
  span {
    width: calc(100% / 7);
    text-align: center;
  }
}
</style>
