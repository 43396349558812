<template>
  <div class="header-pdf">
    <div class="head_invoice">
      <div class="book_number">
        <div class="d-flex flex-column w-100">
          <h6 class="date">
            <p>
              <img src="@/assets/media/image/icon/task-squares.svg" />
              {{ $t("the report") }} : {{ title }}
            </p>
            <p v-if="hotel">
              <img src="@/assets/media/image/icon/building.svg" />
              {{ $t("Hotel") }} : {{ hotel.title }}
            </p>
            <p
              v-if="hotels"
              class="w-100 d-flex align-items-center flex-wrap justify-content-start"
            >
              <img src="@/assets/media/image/icon/building.svg" />
              {{ $t("Hotel") }} :
              <span v-for="item in hotels" :key="item.id">
                {{ item.title ? item.title : "-" }},
              </span>
            </p>
          </h6>
        </div>
        <div class="chart"></div>
        <div class="chart"></div>
      </div>
      <div class="logo">
        <img :src="logo" />
      </div>
      <div class="book_status">
        <div class="chart"></div>
        <div class="chart"></div>
        <div class="d-flex flex-column w-100">
          <h6 class="date">
            <p v-if="fromDates">
              <img src="@/assets/media/image/icon/calendars.svg" />
              {{ $t("date") }}: {{ fromDates }}
              <img
                src="@/assets/media/image/icon/Icons.svg"
                v-if="toDates && fromDate"
              />
              {{ toDates ? toDates : toDate }}
            </p>
            <p>
              <img src="@/assets/media/image/icon/calendar-tick.svg" />
              {{ $t("Export date") }}: {{ date }}
            </p>
          </h6>
        </div>
      </div>
    </div>

    <filterShow
      v-if="
        $route.path == '/room_guests/b2b' ||
        $route.path == '/arrival_guests/b2b'
      "
    />

    <filterShowReport
      v-if="
        $route.path == '/account_statement' ||
        $route.path == '/general-account-statement'
      "
      :toDates="toDates"
      :fromDates="fromDates"
      :typeTransactionValue="typeTransactionValue"
      :typeValue="typeValue"
      :branch="branch"
      :statusValues="statusValues"
      :roomstatusValue="roomstatusValue"
      :sent_to_accounts_value="sent_to_accounts_value"
    />

    <filterShowRoom
      v-if="$route.path == '/contract/room'"
      :toDates="toDates"
      :fromDates="fromDates"
      :hotelValue="hotelValue"
    />

    <FilterShowrevenus
      v-if="$route.path == '/revenues'"
      :hotelValue="hotelValue"
      :client="client"
    />
  </div>
</template>

<script>
import moment from "moment";
import filterShow from "./filterShow.vue";
import filterShowReport from "@/components/pdf/filterShowReport.vue";
import filterShowRoom from "@/components/pdf/filterShowRoom.vue";
import FilterShowrevenus from "@/components/pdf/filterSHowRevenues.vue";

export default {
  name: "headerPdf",
  components: {
    filterShow,
    filterShowReport,
    filterShowRoom,
    FilterShowrevenus,
  },
  props: {
    title: String,
    fromDate: String,
    toDate: String,
    hotel: Object,
    hotels: Array,
    typeTransactionValue: String,
    typeValue: String,
    branch: String,
    statusValues: Array,
    roomstatusValue: String,
    sent_to_accounts_value: String,
    typeValueReport: String,
    hotelValue: String,
    client: String,
  },
  data() {
    return {
      logo: JSON.parse(localStorage.getItem("user")).logo,
      fromDates: "",
      toDates: "",
      date: moment(new Date()).format("YYYY-MM-DD"),
    };
  },
  computed: {
    formattedFromDate() {
      const date = moment(this.fromDate);
      return date.isValid() ? date.format("DD/MM/YYYY") : "";
    },
    formattedToDate() {
      const date = moment(this.toDate);
      return date.isValid() ? date.format("DD/MM/YYYY") : "";
    },
  },
  mounted() {
    this.fromDates = this.formattedFromDate;
    this.toDates = this.formattedToDate;
  },
  watch: {
    fromDate() {
      this.fromDates = this.formattedFromDate;
    },
    toDate() {
      this.toDates = this.formattedToDate;
    },
  },
};
</script>

<style scoped lang="scss">
.header-pdf .head_invoice .book_status h6,
.header-pdf .head_invoice .book_number h6 {
  width: 100%;
}
.header-pdf .head_invoice .book_status h6 {
  clip-path: polygon(0 0, 100% 0, 98% 100%, 0% 100%);
}
.header-pdf .head_invoice .book_number h6 {
  text-align: start;
  padding-inline-end: 0;
  padding-inline-start: 1.5rem;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 2% 100%);
}
.header-pdf {
  min-height: 2.5cm;
  margin-bottom: 0.5rem;
  display: none;
  .logo {
    img {
      width: 2.2cm;
      height: 2.2cm;
      object-fit: scale-down
    }
  }
}
.date {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: start;
  p {
    margin-bottom: 0;
    font-size: 0.8rem;
  }
}
</style>
