<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center gap-3 mb-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4>{{ $t("currency") }}</h4>
    </div>
    <div class="main_header_style">
      <input
        type="text"
        v-model="currencyText"
        @keyup="fetchcurrency((page = 1))"
        class="form-control w-50"
        :placeholder="$t('search...')"
      />
      <router-link to="/currency/add" class="PrimaryButton btn"
        ><i class="fa-solid fa-plus"></i> {{ $t("add_currency") }}
      </router-link>
    </div>

    <div class="row mt-3">
      <div class="table-responsive">
        <table class="table currencies">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("Image") }}</th>
              <th>{{ $t("Title") }}</th>
              <th>{{ $t("code") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="currency in 10" :key="currency">
              <td>
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
              <td width="10%">
                <Skeleton shape="circle" size="5rem"></Skeleton>
              </td>
              <td v-for="currency in 3" :key="currency">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(currency, index) in currencies.data" :key="index">
              <td>{{ currency.id }}</td>
              <td><img :src="currency.image" :alt="currency.title" /></td>
              <td>{{ currency.title }}</td>
              <td>
                <span v-html="currency.code"></span>
              </td>
              <td>
                <router-link
                  :to="`/currency/edit/${currency.id}`"
                  class="btn btn-success mx-1 text-white"
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </router-link>

                <button
                  class="btn btn-danger mx-1 text-white"
                  @click="deletecurrency(currency.id, index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="currencies"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchcurrency"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
export default {
  name: "currencies-website",
  data() {
    return {
      currencies: {},
      currencyText: "",
      loading: true,
    };
  },

  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  methods: {
    async fetchcurrency(page = 1) {
      await axios
        .post(`index_organization_currencies?page=${page}`, {
          word: this.currencyText,
        })
        .then(({ data }) => {
          this.currencies = data.data;
          this.loading = false;
          // console.log(this.currencies);
        });
    },
    deletecurrency(currency, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`organization_currencies/${currency}`)
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.currencies.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
            });
        }
      });
    },
  },
  mounted() {
    this.fetchcurrency();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style scoped>
table tbody tr td img {
  width: 100px;
  height: 100px;
}
</style>
