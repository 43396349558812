<template>
  <section class="all_emp card_style mt-0">
    <div class="d-flex align-items-center gap-3 mb-3">
      <Fav v-tooltip.left="$t('add_to_favorites')" />
      <h4>{{ $t("opening balance") }}</h4>
    </div>

    <div class="row">
      <table class="table" id="VisaTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("title") }}</th>
            <th>{{ $t("balance") }}</th>
            <th>{{ $t("opening balance") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="balance in 10" :key="balance">
            <td><Skeleton height="2rem" width="90%" class="mb-2" /></td>
            <td><Skeleton height="2rem" width="90%" class="mb-2" /></td>
            <td><Skeleton height="2rem" width="90%" class="mb-2" /></td>
            <td><Skeleton height="2rem" width="90%" class="mb-2" /></td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(balance, index) in openingBalaces" :key="index">
            <td>{{ balance.id }}</td>
            <td>{{ balance.title }}</td>
            <td class="fixed-width">
              {{ computedBalance(balance).toFixed(2) }}
            </td>
            <td>
              <div class="card flex justify-content-center w-50">
                <InputText
                  type="number"
                  v-model="balance.opening_balance"
                  min="0"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="w-100 d-flex align-items-center justify-content-end">
        <Button
          :label="$t('save')"
          @click="save_balance"
          class="PrimaryButton btn"
        />
      </div>
    </div>

    <div class="row">
      <pagination
        :data="openingBalaces"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_balance"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Fav from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Swal from "sweetalert2";

export default {
  name: "openingBalacesPage",
  components: {
    pagination,
    Fav,
    Skeleton,
    InputText,
    Button,
  },
  data() {
    return {
      openingBalaces: [],
      balanceText: "",
      value: null,
      loading: true,
    };
  },
  methods: {
    fetch_balance() {
      axios.post(`/fetch_tree_branch_with_out_child`).then(({ data }) => {
        this.openingBalaces = data.data.data;
        this.loading = false;
      });
    },
    save_balance() {
      const daily_restrictions = this.openingBalaces
        .filter((balance) => balance.opening_balance > 0)
        .map((balance) => ({
          branch_id: balance.id,
          opening_balance: balance.opening_balance,
        }));

      if (daily_restrictions.length) {
        axios
          .post(`/makeDailyRestrictionForCapital`, { daily_restrictions })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: this.$t("save_successfuly"),
              showConfirmButton: false,
              timer: 1500,
            });
            this.$router.push({ path: "/tree" });
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: this.$t("please enter greater than zero opening balance"),
              showConfirmButton: true,
            });
          });
      } else {
        Swal.fire({
          icon: "warning",
          title: this.$t("no_balance_to_save"),
          showConfirmButton: true,
        });
      }
    },
    computedBalance(balance) {
      return +balance.balance + +balance.opening_balance;
    },
  },
  mounted() {
    this.fetch_balance();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}

.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}

.text-center {
  text-align: center !important;
}

.fixed-width {
  width: 40rem;
  text-align: right;

  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
