import { createStore } from "vuex";

import authModule from "./modules/auth/index.js";

import filteroffline from "./modules/filteroffline/index.js";

import axios from "axios";

const store = createStore({
  state: {
    notifications: [],
    amenities: [],
    entertainments: [],
    business: [],
    description: [],
    reservation_number: "",
    roomSelling: null,
    roomCost: null,
    flightError: false,
  },
  getters: {
    getNotifications: (state) => state.notifications,
  },
  actions: {
    async fetchNotifications({ commit }) {
      try {
        const data = await axios.get("/fetch_employee_notifications");
        commit("SET_Notifications", data.data.data);
        // console.log(data.data.data);
        // console.log(data.data.data.length);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_Notifications(state, notifications) {
      state.notifications = notifications;
    },
  },
  modules: {
    auth: authModule,
    filteroffline,
  },
});

export default store;
