<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="card_style">
    <div class="main_header_style">
      <h4>
        <span>{{ $t("edit_season") }}</span>
        <span v-if="seasons.length">
          / {{ seasons[0].contract }} /
          {{ seasons[0].hotel_name }}
        </span>
      </h4>
    </div>
    <form class="form_topic" @submit.prevent="editSeason()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('write_name')"
              v-model="name"
              required
            />
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("period_from") }}</label>
            <datepicker
              v-model="start_date"
              name="start_date"
              :placeholder="$t('from')"
              :disabled-dates="{
                to: new Date(start_date),
                from: new Date(
                  seasons.length ? getLastObjectSeason : fromDateContract
                ),
              }"
              :value="start_date"
            ></datepicker>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("to") }}</label>
            <datepicker
              v-model="end_date"
              name="end_date"
              :placeholder="$t('to')"
              :disabled-dates="{
                to: new Date(
                  start_date == ''
                    ? seasons.length
                      ? getLastObjectSeason
                      : fromDateContract
                    : start_date
                ),
                from: new Date(toDateContract),
              }"
              :value="end_date"
            ></datepicker>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("edit") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import Loading from "@/components/LottieFiles/loading.vue";

export default {
  name: "add-season",
  components: {
    Datepicker,
    Loading,
  },
  data() {
    return {
      name: "",
      end_date: "",
      start_date: "",
      fromDateContract: "",
      toDateContract: "",
      seasons: [],
      loading: false,
    };
  },
  methods: {
    fetchSeasonData() {
      let id = this.$route.params.season_id;
      axios.get("/seasons/" + id).then(({ data }) => {
        this.name = data.data.name;
        this.end_date = data.data.end_date;
        this.start_date = data.data.start_date;
        // console.log(data.data);
      });
    },

    FetchContract() {
      let id = this.$route.params.id;
      axios.get(`/contracts/${id}`).then(({ data }) => {
        this.fromDateContract = data.data.from;
        this.toDateContract = data.data.to;
        this.seasons = data.data.seasons;
        // console.log(data.data);
      });
    },

    async editSeason() {
      let id = this.$route.params.id;
      let season_id = this.$route.params.season_id;
      const params = new URLSearchParams();

      params.append("name", this.name);
      params.append("contract_id", id);
      params.append("start_date", moment(this.start_date).format("YYYY-MM-DD"));
      params.append("end_date", moment(this.end_date).format("YYYY-MM-DD"));
      this.loading = true; // Start loading

      axios
        .put(`/seasons/${season_id}`, params)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.loading = false; //the loading ended
          this.$router.push(`/contract/${id}/seasons`);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
  },
  computed: {
    getLastObjectSeason() {
      if (this.seasons.length > 0) {
        return this.seasons[this.seasons.length - 1].end_date;
      } else {
        return null;
      }
    },
  },
  created() {
    this.fetchSeasonData();
    this.FetchContract();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style lang="scss" scoped>
@import "./_season.scss";
</style>
