<template>
  <div class="row">
    <div class="main_header_style">
      <h4>
        {{ $t("total_company") }} <span>({{ total_company }})</span>
      </h4>
      <router-link
        :to="{ name: 'FormOrganization' }"
        class="PrimaryButton btn w-25 mt-4"
        ><i class="fa-solid fa-plus"></i>
        {{ $t("add_new_company") }}</router-link
      >
    </div>

    <table class="table" id="employTable">
      <thead>
        <tr>
          <th>{{ $t("the_company") }}</th>
          <th>{{ $t("company_balance") }}</th>
          <th>{{ $t("hotel_profit") }}</th>
          <th>{{ $t("air_profit") }}</th>
          <th>{{ $t("journy_profit") }}</th>
          <th>{{ $t("transporter_profit") }}</th>

          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in organization.data" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ item.wallet }}</td>
          <td>{{ item.hotel_percentage == "" ? 0 : item.hotel_percentage }}</td>
          <td>
            {{ item.flight_percentage == "" ? 0 : item.flight_percentage }}
          </td>
          <td>{{ item.trips_percentage == "" ? 0 : item.trips_percentage }}</td>
          <td>
            {{
              item.transparent_percentage == ""
                ? 0
                : item.transparent_percentage
            }}
          </td>

          <td>
            <router-link
              class="btn btn-info mx-1"
              :to="`/organization-form/edit/${item.id}`"
              :title="$t('company_detials')"
            >
              <i class="fa-solid fa-circle-info"></i>
            </router-link>

            <router-link
              class="btn btn-success text-white mx-1"
              :to="`/organization-form/edit/${item.id}`"
              :title="$t('edit')"
            >
              <i class="fa-solid fa-pen"></i>
            </router-link>

            <button
              class="btn btn-secondary text-white mx-1"
              @click="blockAlert(index)"
              :title="$t('bolck_company')"
            >
              <i class="fa-solid fa-ban"></i>
            </button>

            <button
              class="btn btn-danger text-white mx-1 remove_btn"
              @click="DeleteAlert(index)"
            >
              <i class="fa-solid fa-trash" :title="$t('remove_company')"></i>
            </button>
          </td>

          <!-- Modal -->
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <pagination
      :data="organization"
      class="mx-auto d-flex align-items-center justify-content-center pagination"
      @pagination-change-page="FetchTreeOrganization"
    >
      <template v-slot:prev-nav>&lt;</template>
      <template v-slot:next-nav>&gt;</template>
    </pagination>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import pagination from "laravel-vue-pagination";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "company-name",
  components: {pagination},
  data() {
    return {
      organization: [],
      total_company: 0,
    };
  },
  methods: {
    // fetch organization branch data
    FetchTreeOrganization(page = 1) {
      axios.post(`/fetch_my_organization_child?page=${page}` , {per_page: 10}).then(({ data }) => {
        this.organization = data.data;
        this.total_company = this.organization.data.length;
      });
    },
    // block company
    blockAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_block"),
      }).then((result) => {
        if (result.isConfirmed) {
          let block_id = { organization_id: this.organization.data[index].id };
          const newLocal = this.$i18n.locale;
          axios.defaults.headers.common["Accept-Language"] = newLocal;
          axios.post("/block_organization", block_id).then(({ data }) => {
            // console.log(result);
            // console.log(delete_id);
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.organization.data.splice(index, 1); // Remove the deleted item from the array
              // console.log(data);
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.organization.data[index].id;
          const fromClick = true;
          // console.log(delete_id);
          axios.delete("/organizations/" + `${delete_id}`, { fromClick }).then(({ data }) => {
            // console.log(result);
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.organization.data[index].splice(index, 1); // Remove the deleted item from the array
              // console.log(data);
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.FetchTreeOrganization();
  }
};
</script>
<style lang="scss" scoped>
@import "../../view/dashboard/organization/_organization.scss";
</style>
