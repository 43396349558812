<template>
  <section class="card_style">
    <!-- skeleton -->
    <skeltonTitles v-if="loading && package_id !== undefined" />
    <!-- skeleton -->

    <form @submit.prevent="addPackage()" v-else>
      <div class="main_header_style">
        <h4 v-if="package_id != undefined">{{ $t("edit_package_type") }}</h4>
        <h4 v-else>{{ $t("add_package_type") }}</h4>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <TabView>
              <TabPanel
                v-for="(desc, index) in package_title"
                :key="index"
                :class="[desc.title !== '' ? 'text-success' : '']"
                :header="$t('program name') + ' - ' + desc.lang"
              >
                <div class="form-group mb-0">
                  <input
                    type="text"
                    :placeholder="$t('name')"
                    class="form-control"
                    :id="`name${index}`"
                    v-model="desc.title"
                  />
                  <!-- Add any additional content specific to each tab here -->
                </div>
              </TabPanel>
            </TabView>
            <span class="text-danger" v-if="v$.package_title.$error">{{
              $t("please_fill_at_least_one_title")
            }}</span>
          </div>
        </div>
      </div>
      <div class="row my-4">
        <div class="d-flex align-items-center gap-3">
          <span class="form-label mb-0">{{ $t("Tourism_programs") }}</span>
          <InputSwitch v-model="checked" />
          <span class="form-label mb-0">{{ $t("islamic_program") }}</span>
        </div>
      </div>
      <button type="submit" class="btn PrimaryButton">{{ $t("save") }}</button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import skeltonTitles from "@/components/skelton/skeltonTitles.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

import InputSwitch from "primevue/inputswitch";

// const atLeastOneTitle = helpers.withMessage(
//   "Please fill at least one title",
//   (value) => value.some((item) => item.title.trim() !== "")
// );
const atLeastOneTitleFilled = helpers.withMessage(
  "Please fill at least one title",
  (value) => value.some((item) => item.title.trim() !== "")
);
export default {
  name: "addPackage",
  data() {
    return {
      checked: true,
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      package_title: [],
      package_id: this.$route.params.id,
      loading: true,
    };
  },
  validations() {
    return {
      package_title: {
        atLeastOneTitleFilled,
      },
    };
  },
  methods: {
    setpackageTitle() {
      this.languages.forEach((ele) => {
        this.package_title.push({
          lang: ele,
          title: "",
        });
      });
    },
    fetch_package_data() {
      axios.get(`/package_types/${this.$route.params.id}`).then(({ data }) => {
        this.package_title = data.data.titles.map((ele) => {
          return {
            lang: ele.locale,
            title: ele.title,
          };
        });
        this.checked = data.data.package_type == 2 ? true : false;
        this.loading = false;
      });
    },
    addPackage() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const params = new URLSearchParams();
        this.package_title.forEach((ele) => {
          params.append(`title_${ele.lang}`, ele.title);
        });

        params.append("package_type", this.checked ? 2 : 1);
        if (this.$route.params.id != undefined) {
          axios
            .patch(`/package_types/${this.$route.params.id}`, params)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/allPackageTypes");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          axios
            .post(`/package_types`, params)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/allPackageTypes");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
  },
  mounted() {
    this.setpackageTitle();
    if (this.$route.params.id != undefined) {
      this.fetch_package_data();
    }
  },
  components: { skeltonTitles, TabView, TabPanel, InputSwitch },
};
</script>

<style></style>
