<template>
  <nav class="navbar pe-1">
    <div class="left_section_nav">
      <NewSidebar />
      <div class="d-flex align-items-center justify-content-between">
        <div class="dates">
          <p class="fw-bold">{{ $t("birth_date") }}: {{ gregorianDate }}</p>
          <p class="fw-bold">
            {{ $t("hijri_date") }}: {{ formattedHijriDate }}
          </p>
          <p class="times fw-bold">
            {{ $t("time") }}: {{ new Date().toLocaleTimeString() }}
          </p>
        </div>
      </div>
    </div>

    <div class="search-section">
      <searchSection />
    </div>

    <div class="nav-controls">
      <div class="">
        <!-- router link to wallet path  -->
        <router-link to="/wallet" class="btn btn-website mx-1" type="button"
          ><i class="fa-solid fa-wallet"></i>
          {{ $t("The Wallet") }}</router-link
        >

        <a :href="website" target="_blank" class="btn btn-website" type="button"
          ><i class="fa-solid fa-display"></i> {{ $t("go_to_website") }}</a
        >
        <a
          :href="whatsapp"
          target="_blank"
          class="btn btn-whatsapp"
          type="button"
          ><i class="fa-brands fa-whatsapp"></i> {{ $t("go_to_whatsapp") }}</a
        >
      </div>
      <div class="d-flex align-items-center gap-3">
        <div class="changelocal d-inline-block">
          <div class="dropdown lang">
            <a
              class="btn dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <!-- lang -->
              <i class="fa-solid fa-earth-europe px-2"></i>
            </a>

            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li>
                <a
                  class="dropdown-item"
                  v-for="entry in languages"
                  :key="entry.title"
                  @click="changeLocale(entry.language)"
                  :iso="entry.flag"
                  v-bind:squared="false"
                >
                  <flag :iso="entry.flag" v-bind:squared="false" />
                  {{ entry.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <notifications />
        <div class="dropdown d-inline-block">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              :src="logo"
              class="admin_img"
              alt="person"
            />
            {{ name }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button class="dropdown-item removeToken" @click="removeToken">
                {{ $t("logout") }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  .removeToken {
    padding: 0.75rem 0.5rem;
  }
  .btn {
    background: transparent;
    border-color: transparent;
    color: #000;
    font-family: "regular";
    font-size: 0.8rem;
    &:focus {
      box-shadow: none;
    }
  }
  .menu {
    border: 1.5px solid var(--color-primary);
    border-radius: 5px;
    padding: 0.5rem;
    // width: 40px;
    // height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-inline: 50px;
    cursor: pointer;
    font-size: 1.3rem;
  }
  .admin_img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0 0.5rem;
    object-fit: contain;
  }
  .nav-controls {
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  // @media only screen and (max-width: 1440px) {
  //   padding-inline: 50px;
  // }
  // @media only screen and (max-width: 1240px) {
  //   padding-inline: 90px;
  // }
  // .search-section {
  //   width: 20%;
  // }
}
</style>

<script>
import Notifications from "../Notifications/notifications.vue";
import NewSidebar from "./newSidebar.vue";
import searchSection from "../Search/searchSection.vue";
import axios from "axios";
import Swal from "sweetalert2";

// import moment from "moment-hijri";
export default {
  name: "navbar-layout",
  data() {
    return {
      formattedHijriDate: "",
      languages: [
        { flag: "us", language: "en", title: this.$i18n.t("english") },
        { flag: "eg", language: "ar", title: this.$i18n.t("arabic") },
        { flag: "id", language: "id", title: this.$i18n.t("indonosia") },
        // { flag: "tr", language: "tr", title: "Turkish" },
      ],
      profile: {},
      website: JSON.parse(localStorage.getItem("user"))?.website_link,
      logo: JSON.parse(localStorage.getItem("user"))?.logo,
      name: JSON.parse(localStorage.getItem("user"))?.name,
      whatsapp: "https://wa.me/+966561492362",
      gregorianDate: "",
    };
  },
  methods: {
    updateHijriDate() {
      const currentDate = new Date();
      const hijriFormatter = new Intl.DateTimeFormat(
        "en-US-u-ca-islamic-umalqura",
        {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          timeZone: "UTC", // Use UTC to avoid time zone differences
        }
      );
      this.formattedHijriDate = hijriFormatter
        .format(currentDate)
        .replace(/\s*AH$/, "");
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", locale);
      // this.$forceUpdate('lang',locale);
      localStorage.setItem("lang", locale);
      location.reload();
      // console.log(locale);
    },
    removeToken: function () {
      Swal.fire({
        title: this.$t("logout"),
        text: this.$t("are_you_sure_logout"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_logout"),
      }).then((result) => {
        if (result.isConfirmed) {
          axios.get("/logout").then(({data}) => {
            if (data.status == true) {
              localStorage.clear();
              this.$router.go("/login");
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          }).catch((error) => {
              localStorage.clear();
              this.$router.go("/login");
              Swal.fire({
                  position: "top-end",
                  icon: "error",
                  title: error.response.data.message,
                  timer: 1500,
              });
          })
          .finally(() => {
              //Perform action in always
          });
        }
      });
    },
  },
  created() {
    // changeLocale(locale)
    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", this.$i18n.locale);
    // console.log(i18n.locale);
    this.profile = JSON.parse(localStorage.getItem("user"));
    this.updateHijriDate();
    const currentDateGregorian = new Date();
    this.gregorianDate = currentDateGregorian.toLocaleDateString();
    // console.log(this.profile)
  },
  components: { NewSidebar, Notifications, searchSection },
};
</script>
