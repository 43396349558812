<template>
  <div class="details_organization_card bg-white mt-5">
    <div class="profite_percentage my-3">
      <div class="title">
        <img src="../../assets/media/image/details-shape.png" alt="" />
        <h6>{{ $t("profite_percentage") }}</h6>
        <router-link class="edit" :to="{ name: 'editOrganization' }">
          <i class="fa-solid fa-pen"></i
        ></router-link>
      </div>
    </div>
    <div class="row bg-white">
      <div class="col-lg-6 col-12">
        <h4>b2b</h4>
        <table class="table_profit">
          <tr>
            <td>{{ $t("hotel_profit") }}</td>
            <td class="second">{{ hotel_percentage.b2b_percentage }}</td>
          </tr>
          <tr>
            <td>{{ $t("air_profit") }}</td>
            <td class="second">{{ flight_percentage.b2b_percentage }}</td>
          </tr>
          <tr>
            <td>{{ $t("journy_profit") }}</td>
            <td class="second">{{ trips_percentage.b2b_percentage }}</td>
          </tr>
          <tr>
            <td>{{ $t("transporter_profit") }}</td>
            <td class="second">{{ transport_percentage.b2b_percentage }}</td>
          </tr>
        </table>
      </div>

      <div class="col-lg-6 col-12">
        <h4>b2c</h4>
        <table class="table_profit">
          <tr>
            <td>{{ $t("hotel_profit") }}</td>
            <td class="second">{{ hotel_percentage.b2c_percentage }}</td>
          </tr>
          <tr>
            <td>{{ $t("air_profit") }}</td>
            <td class="second">{{ flight_percentage.b2c_percentage }}</td>
          </tr>
          <tr>
            <td>{{ $t("journy_profit") }}</td>
            <td class="second">{{ trips_percentage.b2c_percentage }}</td>
          </tr>
          <tr>
            <td>{{ $t("transporter_profit") }}</td>
            <td class="second">{{ transport_percentage.b2c_percentage }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profite-organization",
  props: {
    hotel_percentage: Object,
    flight_percentage: Object,
    transport_percentage: Object,
    trips_percentage: Object,
  },
};
</script>
<style lang="scss" scoped>
@import "../../view/dashboard/organization/_organization.scss";
</style>
