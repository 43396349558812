<script setup>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Skeleton from "primevue/skeleton";
import { ref } from "vue";
import Swal from "sweetalert2";

const clients = ref([]);
const client = ref(null);
const invoices = ref([]);
const selectedInvoices = ref([]);
const loader = ref(false);

const fetchClients = async () => {
  try {
    await axios
      .post("/fetch_consumer_by_role", { role: 1 })
      .then(({ data }) => {
        clients.value = data.data.data;
      });
  } catch (error) {
    console.log(error);
  }
};
const fetchInvoices = async () => {
  try {
    loader.value = true;
    await axios
      .post("/get_not_publish_invoice", {
        client_id: client.value.id,
      })
      .then(({ data }) => {
        invoices.value = data.data;
      });
    loader.value = false;
  } catch (error) {
    console.log(error);
    loader.value = false;
  }
};
const publishInvoices = async () => {
  try {
    const formData = new FormData();
    selectedInvoices.value.forEach((ele) => {
      formData.append("base_booking_ids[]", ele);
    });
    await axios.post("/SetBaseBookingTax", formData).then(({ data }) => {
      console.log(data);
      Swal.fire({
        icon: "success",
        title: data.message,
        showConfirmButton: false,
        timer: 1500,
      });
      fetchInvoices();
    });
  } catch (error) {
    console.log(error);
  }
};
const selectAll = () => {
  selectedInvoices.value = invoices.value.map((i) => i.base_booking_id);
};
</script>

<template>
  <section class="card_style">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
      <h3 class="mb-0">{{ $t("collected_invoices") }}</h3>
    </div>
    <div class="row my-4">
      <div class="col-12 col-lg-8">
        <label class="form-label">{{ $t("client") }}</label>
        <Multiselect
          v-model="client"
          :options="clients"
          label="name"
          track-by="id"
          :clear-on-select="true"
          :placeholder="$t('choose')"
          select-label=""
          @open="fetchClients"
          :hide-selected="true"
        >
        </Multiselect>
      </div>
      <div class="col-4 d-flex justify-content-end align-items-end">
        <button class="btn PrimaryButton w-100" @click="fetchInvoices">
          {{ $t("submit") }}
        </button>
      </div>
      <hr class="mt-2" />

      <div class="col-4"></div>
      <div class="col-4" v-if="invoices.length || loader">
        <button class="btn SecondButton w-100" @click="selectAll">
          {{ $t("select_all") }}
        </button>
      </div>
      <div class="col-4" v-if="invoices.length || loader">
        <button class="btn PrimaryButton w-100" @click="publishInvoices">
          {{ $t("publish") }}
        </button>
      </div>
      <hr class="mt-2" />
      <div class="col-12">
        <h4 class="my-4" v-if="invoices.length || loader">
          {{ $t("collected_invoices") }}
        </h4>
        <table class="table" v-if="invoices.length || loader">
          <thead>
            <tr>
              <th>{{ $t("choose") }}</th>
              <th>{{ $t("name") }}</th>
              <th>{{ $t("rooms") }}</th>
            </tr>
          </thead>
          <tbody v-if="loader">
            <tr v-for="i in 10" :key="i">
              <td v-for="l in 3" :key="l" class="text-center">
                <Skeleton height="2rem" width="80%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="invoice in invoices" :key="invoice">
              <td>
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="selectedInvoices"
                  :value="invoice.base_booking_id"
                  id="choose"
                />
              </td>
              <td>{{ invoice.titles }}</td>
              <td>{{ invoice.rooms }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <div class="d-flex align-items-center justify-content-center">
            <img
              src="@/assets/media/image/no-data.png"
              class="img-fluid"
              alt=""
            />
          </div>
          <p class="text-center my-5 fs-3">
            {{ $t("No Data Found In Selected Day") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss"></style>
