<template>
  <section class="card_style">
    <div class="main_header_style"></div>
    <div class="row options mb-2">
      <div class="col-12 col-md-4 d-flex align-items-center">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>{{ $t("report") }} {{ $t("flight-company") }}</h4>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group search">
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            :placeholder="$t('search by name')"
            class="form-control"
            v-model="search_center"
            @keyup="book_flight_company_report"
          />
        </div>
      </div>

      <div
        class="col-12 col-md-4 d-flex align-items-center justify-content-end"
      >
        <button
          class="SecondButton btn action d-flex align-items-center justify-content-center"
          @click="exportToExcel"
        >
          <i class="fa-solid fa-file-excel"></i>
          {{ $t("export_to_excel") }}
        </button>

        <button class="btn SecondButton mx-2" v-print="'#printMe'" title="PDF">
          <i class="fa-solid fa-file-pdf"></i>
        </button>
      </div>
    </div>
    <div class="row" id="printMe">
      <HeaderPdf
        :title="$t('report') + ' ' + $t('flight-company')"
        :fromDate="''"
        :toDate="''"
      />
      <table class="table">
        <thead>
          <tr>
            <th class="no-print">{{ $t("ID") }}</th>
            <th>{{ $t("flight_company_name") }}</th>
            <th>{{ $t("book_count") }}</th>
            <th>{{ $t("profite") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 5" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) in flights_reports" :key="index">
            <td class="no-print">{{ index + 1 }}</td>
            <td>{{ item.flight_company_name }}</td>
            <td>{{ item.book_count }}</td>
            <td>{{ item.profit }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="row">
      <pagination
        :data="flights_reports"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_flights_reports"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div> -->
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
// import pagination from "laravel-vue-pagination";
import HeaderPdf from "@/components/pdf/headerPdf.vue";

import * as XLSX from "xlsx";
import Skeleton from "primevue/skeleton";
export default {
  name: "costCenterIndex",
  components: {
    // pagination,
    AddFavDailog,
    Skeleton,
    HeaderPdf,
  },

  data() {
    return {
      flights_reports: [],
      search_center: "",
      loading: true,
    };
  },
  methods: {
    async exportToExcel() {
      try {
        const response = await axios.post("/book_flight_company_report");
        const responseData = response.data.data.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "cost_center.xlsx");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed, e.g., show a message to the user
      }
    },
    fetch_flights_reports() {
      let data = {
        // per_page: 10,
      };
      if (this.search_center != "") {
        data.word = this.search_center;
      }
      axios.post(`/book_flight_company_report`, data).then(({ data }) => {
        this.flights_reports = data.data;
        this.loading = false;
      });
    },
    //delete expness
  },
  mounted() {
    this.fetch_flights_reports();
  },
};
</script>

<style lang="scss" scope>
.search {
  position: relative;
  i {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
.PrimaryButton {
  margin-top: auto !important;
}

@media print {
  .no-print {
    display: none !important;
  }
  .table {
    thead {
      tr {
        th {
          padding-inline: 1.5rem !important;
        }
      }
    }
    tbody {
      tr {
        td {
          padding-inline: 1.5rem !important;
        }
      }
    }
  }
}
</style>
