<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <h4>
        {{ $t("Safe Name") }}/<span>{{ $t("safe's details") }}</span>
      </h4>
    </div>

    <div class="row safe-details">
      <div class="col-3">
        <div class="d-flex align-items-center gap-3">
          <i class="fa-solid fa-building-columns"></i>
          <div class="text">
            <p class="title">{{ $t("Safe Name") }}</p>
            <p>{{ safe.title }}</p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="d-flex align-items-center gap-3">
          <i class="fa-solid fa-dollar-sign"></i>
          <div class="text">
            <p class="title">{{ $t("init value") }}</p>
            <p v-for="item in safe.tree_relation" :key="item">
              {{ item.opening_balance }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="d-flex align-items-center gap-3">
          <i class="fa-solid fa-calendar-days"></i>
          <div class="text">
            <p class="title">{{ $t("date created") }}</p>
            <p>{{ safe.created_at }}</p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="d-flex align-items-center gap-3">
          <i class="fa-solid fa-user"></i>
          <div class="text">
            <p class="title">{{ $t("Created by") }}</p>
            <!-- <p>{{account}}</p> -->
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="d-flex align-items-center gap-3">
          <i class="fa-solid fa-circle-check"></i>
          <div class="text">
            <p class="title">{{ $t("status") }}</p>
            <p>{{ safe.active === 0 ? $t("Not Active") : $t("Active") }}</p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="d-flex align-items-center gap-3">
          <i class="fa-solid fa-dollar-sign"></i>
          <div class="text">
            <p class="title">{{ $t("balance") }}</p>
            <p v-for="item in safe.tree_relation" :key="item">
              {{ item.balance }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="d-flex align-items-center justify-content-between">
        <h4>{{ $t("the transactions") }}</h4>
        <form>
          <select class="transactions_select">
            <option>{{ $t("all transactions") }}</option>
          </select>
        </form>
      </div>
    </div>

    <div class="row">
      <table class="table" id="RoomTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("time") }}</th>
            <th>{{ $t("Cost_center") }}</th>
            <th>{{ $t("creditor") }}</th>
            <th>{{ $t("debtor") }}</th>
            <th>{{ $t("balance") }}</th>
            <th class="w-25">{{ $t("the statement") }}</th>
            <th>{{ $t("clerck") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="client in 10" :key="client">
            <td v-for="client in 9" :key="client">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(client, index) in transfersData.data" :key="index">
            <td>{{ client.id }}</td>
            <td>{{ client.daily_restriction_date }}</td>
            <td>
              {{ client.cost_center?.title }}
            </td>
            <td>{{ client.creditor }}</td>
            <td>{{ client.debtor }}</td>
            <td>
              {{
                client.balance > 0 ? client.balance : "(" + client.balance + ")"
              }}
            </td>
            <td>{{ client.description }}</td>
            <!--            <td>-->
            <!--              <span v-if="client.type === 1"> {{ $t("debtor") }}</span>-->
            <!--              <span v-if="client.type === 2"> {{ $t("creditor") }}</span>-->
            <!--            </td>-->
            <td>{{ client.employee?.name }}</td>
            <td>
              <button
                @click="getTransferItem(client.daily_restrictions_id)"
                class="btn btn-info"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottomDetails"
                aria-controls="offcanvasBottom"
                :title="$t('details')"
              >
                {{ client.daily_restrictions_id }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <TransfersOffcanves />
    <div class="row">
      <pagination
        :data="transfersData"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchTransfer"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import TransfersOffcanves from "../../../components/safe/TransfersOffcanves.vue";
import pagination from "laravel-vue-pagination";
// import moment from "moment";
import Skeleton from "primevue/skeleton";
export default {
  name: "softDetails",
  data() {
    return {
      safe: {},
      safe_id: this.$route.params.id,
      transfersData: [],
      loading: true,
    };
  },
  components: {
    TransfersOffcanves,
    pagination,
    Skeleton,
  },
  methods: {
    fetchSafeData() {
      axios.get(`/organization_accounts/${this.safe_id}`).then((data) => {
        this.safe = data.data.data;
        this.fetchTransfer();
      });
    },
    fetchTransfer() {
      let branch_id = this.safe.branch_id;
      axios
        .post("/fetch_branch_statements", { branch_id: branch_id })
        .then(({ data }) => {
          this.transfersData = data.data;
          this.loading = false;
        });
    },
    // dateFormate(value) {
    //   let date = moment(value).format("LL");
    //   return date;
    // },
    // timeFormate(value) {
    //   let time = moment(value).format("LT");
    //   return time;
    // },
  },
  created() {
    this.fetchSafeData();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "./_safe";
</style>
