<template>
  <div class="row my-4 border p-3 rounded-2 me-3">
    <div class="title d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center justify-content-start flex-column">
        <h5 class="text-point mb-0 w-100">
          {{ $t("Total profits") }}
        </h5>
        <span class="points mb-0 w-100">{{ Math.floor(total_profit) }} </span>
      </div>
      <div class="form-group w-25">
        <Multiselect
          v-model="nationalityValue_total_profit"
          :options="nationalities"
          :placeholder="$t('choose_nationality')"
          label="title"
          track-by="id"
          :clear-on-select="true"
          select-label=""
          :hide-selected="true"
          @keyup="searchNationality"
          @update:model-value="profitss"
        >
        </Multiselect>
      </div>
    </div>

    <div class="card">
      <Chart type="bar" :data="Total" :options="TwoTotal" class="Books" />
    </div>
  </div>
</template>

<script>
import Chart from "primevue/chart";
import Multiselect from "vue-multiselect";
import axios from "axios";
export default {
  data() {
    return {
      Total: null,
      TwoTotal: null,
      nationalities: [],
      nationalityValue_total_profit: "",
      total_profit: 0,
      months: [],
      profitssss: [],
      labels_profits: [],
      labels_months: [],
    };
  },
  async mounted() {
    // this.Total = this.fetcgTotal();

    this.TwoTotal = this.fetchTot();

    await this.profitss();
  },
  methods: {
    async searchNationality(event) {
      const searchText = {
        word: event == undefined ? "" : event.target.value,
      };
      await axios
        .post("/fetch_custom_countries", searchText)
        .then((res) => {
          // console.log(res.data.data.data, "searchNationality");
          this.nationalities = res.data.data.data;
        })
        .catch((err) => console.log(err));
    },
    fetcgTotal() {
      const documentStyle = getComputedStyle(document.documentElement);
      // console.log(documentStyle);

      return {
        labels: this.labels_months,
        datasets: [
          {
            type: "line",
            label: this.$t("Profit rate"),
            borderColor: documentStyle.getPropertyValue("--blue-500"),
            borderWidth: 2,
            fill: false,
            tension: 0.4,
            data: this.labels_profits,
          },
          {
            type: "bar",
            label: this.$t("required_percentage"),
            backgroundColor: documentStyle.getPropertyValue("--orange-500"),
            data: this.labels_profits,
          },
        ],
      };
    },
    fetchTot() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--text-color-secondary"
      );
      const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
      return {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };
    },
    async profitss() {
      const formData = new FormData();
      formData.append(
        "country_id",
        this.nationalityValue_total_profit.id == undefined
          ? 598
          : this.nationalityValue_total_profit.id
      );
      const res = await axios.post("/flight_profit_chart", formData);
      this.labels_months = res.data.data.months;
      this.labels_profits = res.data.data.profits;
      this.total_profit = res.data.data.total_profit;

      // this.labels_months.forEach((label) => {
      //   this.months.push(label);
      // });
      // this.labels_profits.forEach((prof) => {
      //   this.profitssss.push(prof);
      // });

      getComputedStyle(document.documentElement);

      this.Total = this.fetcgTotal();
    },
  },
  components: {
    Chart,
    Multiselect,
  },
};
</script>

<style lang="scss" scoped>
.text_point {
  color: #656b78;
  font-family: "regular";
  font-size: 0.8rem;
  margin: 0.5rem 0 0.5rem 0;
}
.bg-img {
  background: #0a909b;
}
.point {
  color: #0b1427;
  font-weight: bold;
  font-size: 1.3rem;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.p-chart.h-80 {
  min-height: 28dvh;
}
.linechart {
  min-height: 28dvh;
}
.text-book {
  color: #0b1427;
  font-weight: bold;
  font-size: 1.2rem;
}
.Books {
  min-height: 28dvh;
  max-height: 28dvh;
  overflow-y: auto;
}
.text-point {
  color: #656b78;
  font-family: "regular";
  font-size: 1rem;
}
.points {
  color: #0b1427;
  font-size: 2rem;
  font-family: "bold";
}
thead {
  background-color: #eef4f4 !important;
  th.text-center {
    padding: 0.8rem;
  }
}
.pendding {
  color: #c57016;
}
.cancel {
  color: #e23535;
}
.accept {
  color: #2a8f6f;
}
.initial {
  color: #1664b8;
}
</style>
