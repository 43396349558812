<template>
  <div class="table-responsive">
    <table class="table" id="userTable">
      <thead>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-center">{{ $t("date") }}</th>
          <th class="text-center">{{ $t("title") }}</th>
          <th class="text-center">{{ $t("package_services") }}</th>

          <th class="text-center">{{ $t("adult_count") }}</th>
          <th class="text-center">{{ $t("child_count") }}</th>
          <th class="text-center">{{ $t("total_price") }}</th>
        </tr>
      </thead>
      <tbody class="bg-primary">
        <tr v-for="(item, index) in user.data" :key="index">
          <td>{{ item.id }}</td>
          <td>{{ item.package.trip_date }}</td>
          <td>{{ item.package.title }}</td>
          <td>
            <span
              class="service"
              v-for="(service, index) in item.package.package_services"
              :key="index"
              >{{ $t(`${service}`) }}<span class="dash"> - </span></span
            >
          </td>
          <td>{{ item.adult_count }}</td>
          <td>{{ item.child_count }}</td>
          <td>{{ item.total_price }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <pagination
      :data="user"
      class="mx-auto d-flex align-items-center justify-content-center pagination"
      @pagination-change-page="fetchUser"
    >
      <template v-slot:prev-nav>&lt;</template>
      <template v-slot:next-nav>&gt;</template>
    </pagination>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      user: [],
    };
  },
  methods: {
    fetchUser() {
      try {
        const client_id = { client_id: this.$route.params.id };
        axios.post(`client_book_package_reservation`, client_id).then((res) => {
          this.user = res.data.data;
          //  console.log(this.user, "wddddd");
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
        });
      }
    },
  },
  mounted() {
    this.fetchUser();
  },
  components: {
    pagination,
  },
};
</script>

<style lang="scss" scoped>
// @import "@/assets/scss/variables.scss";
@import "@/view/dashboard/offline/_offline.scss";
@import "@/assets/scss/_variables.scss";

// @import "./_user.scss";
.image {
  height: 8rem;
}

.main_header_style {
  align-items: center;

  h4 {
    margin-bottom: 0;
  }
}

.search-user {
  form {
    position: relative;

    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }

    input {
      padding-right: 40px !important;
    }
  }
}

.avatar {
  text-align: start;
  width: 50%;

  .person_name {
    color: #0b1427;
    font-family: "bold";
    font-size: 1.5rem;
  }

  .device_details {
    display: flex;
    align-items: flex-end;

    .icon {
      width: 1.2rem;
    }
  }

  .email_contact {
    background-color: #0a909b;
    color: white;
    padding: 0.5rem 1rem;
    width: 80%;
  }

  .connects {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .sms {
      border: 1px solid #0a909b;
      border-radius: 6px;
      padding: 0.5rem 1rem;
      background-color: transparent;

      span {
        color: #0b1427;
        font-size: 1rem;
      }
    }

    .whatsapp {
      border: 1px solid #64bf72;
      border-radius: 6px;
      padding: 0.5rem 1rem;
      background-color: transparent;

      span {
        color: #0b1427;
        font-size: 1rem;
      }
    }
  }
}

.points {
  background-image: url("@/assets/media/image/icon/Rectangle 10280.svg");
  background-size: cover;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 7%;
  min-height: 40dvh;
  height: 40dvh;

  .points_title {
    color: #fff;
    font-family: "regular";
    font-size: 1.2rem;
  }

  .points_number {
    color: white;
    font-family: "bold";
    font-size: 2rem;
  }
}

.personal_details {
  color: #0b1427;
  font-family: "bold";
  font-size: 1.5rem;
}

.details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  width: 80%;
  margin-top: 1.3rem;
}

.details_two {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  width: 50%;
  justify-content: space-between;
}

.detailsss {
  color: #0b1427;
  font-size: 1.5rem;
  font-family: "bold";
}

p {
  color: #9ca2ac;
  font-size: 1.1rem;
  font-family: "regular";
  margin-bottom: 0.5rem;
}

.all_emp .card_style {
  background: transparent !important;
}
</style>
