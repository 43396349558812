export default {
  state() {
    return {
      checked: true,
      visible: false,
      clients: [],
      client_id: null,
      suppliers: [],
      formData: {},
      supplierValue: null,
      hotels: [],
      hotelValue: null,
      fromDate: "",
      toDate: "",
      roomValue: "",
      rooms: [],
      checkin_from: [],
      client_categorys: [
        {
          id: 1,
          title: "Individual",
        },
        {
          id: 2,
          title: "Company",
        },
      ],
      client_category: "",

      checkout_to: [],
      optionDate: [],
      post_status: "",
      payment_status: "",
      booking_status: "",
      nationalities: [],
      nationality: "",
      create_at_in: [],
      create_at_to: [],
      book_numbers: [],
      book_number: "",
      hotel_refs: [],
      hotel_ref: "",
      suplier_refs: [],
      suplier_ref: "",
      hotel_as_supliers: [],
      hotel_as_suplier: "",
      emploies: [],
      employ: "",
      post_statuses: [
        {
          id: 0,
          title: "unposted",
        },
        {
          id: 1,
          title: "posted",
        },
      ],
      payment_statuses: [
        // {
        //   id: 0,
        //   title: this.$t("unpaid"),
        // },
        // {
        //   id: 1,
        //   title: this.$t("paid"),
        // },
        // {
        //   id: 2,
        //   title: this.$t("partially_paid"),
        // },
      ],
      paymentSupliers: [],
      paymentSuplier: "",
      booking_statuses: [
        {
          id: 0,
          title: "pending",
        },
        {
          id: 1,
          title: "tentative",
        },
        {
          id: 2,
          title: "confirmed",
        },
        {
          id: 3,
          title: "cancelled",
        },
      ],
      room_statuses: [
        {
          id: 0,
          title: "not available",
        },
        {
          id: 1,
          title: "available",
        },
      ],
      routePath: "/offlineBookHotels",
      room_status: "",
      guest: "",
      share_amount: "",
      localBookSearch: "",
      meals: [],
      meal: "",
      rates: [],
      rate: "",
      cities: [],
      clientTypes: [],
      clientType: "",
      city: "",
      suplierTypes: [],
      discountTypes: [],
      discountType: "",
      suplierType: "",
      paymentMethod: "",
      paymentMethods: [],
    };
  },
};
