<template>
  <table class="table" id="userTable">
    <thead>
      <tr>
        <th>{{ $t("ID") }}</th>
        <th>{{ $t("book_type") }}</th>
        <th class="text-center">{{ $t("date") }}</th>
        <th class="text-center">{{ $t("program") }}</th>
        <th class="text-center">{{ $t("date_trip") }}</th>
        <th class="text-center">{{ $t("package_services") }}</th>
        <th class="text-center">{{ $t("title") }}</th>
        <th class="text-center">{{ $t("number_of_adults") }}</th>
        <th class="text-center">{{ $t("number_of_childrens") }}</th>
        <th class="text-center">{{ $t("total_price") }}</th>
      </tr>
    </thead>

    <tbody v-if="loading" class="text-center">
      <tr v-for="item in 10" :key="item">
        <td v-for="item in 10" :key="item">
          <Skeleton height="1.5rem" width="90%" class="mb-2"></Skeleton>
        </td>
      </tr>
    </tbody>

    <tbody>
      <tr v-for="(item, index) in books" :key="index">
        <td :data-label="$t('ID')">{{ item.book.id }}</td>
        <td :data-label="$t('book_type')">
          {{ $t(item.paymentable_type.split("\\").pop()) }}
        </td>
        <td>{{ item.book.date }}</td>
        <td>{{ item.book.package?.title }}</td>
        <td>{{ item.book.package?.luanch_date }}</td>
        <td>
          <span
            class="service"
            v-for="(service, index) in item?.book.package?.package_services"
            :key="index"
            >{{ $t(`${service}`) }}<span class="dash"> - </span></span
          >
        </td>

        <td>
          <span v-for="guest in item.book.package_guests" :key="guest.id">{{
            guest.first_name
          }}</span>
        </td>
        <td>{{ item.book.adult_count }}</td>
        <td>{{ item.book.child_count }}</td>
        <td>{{ item.book.total_price }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Skeleton from "primevue/skeleton";
export default {
  name: "package-pending",
  props: ["books", "loading"],
  components: {
    Skeleton,
  },
};
</script>

<style></style>
