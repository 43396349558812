<template>
  <div class="d-flex gap-3 align-items-center  px-4">
    <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />

    <h2 class="my-2">{{ $t("edit invoice theme") }}</h2>
  </div>
  <div class="d-flex align-items-center justify-content-beween w-100 flex-wrap">
    <Card
      style="width: 26rem; overflow: hidden"
      class="m-3 shadow"
      v-for="theme in themes"
      :key="theme.id"
    >
      <template #header>
        <!-- <img alt="user header" :src="theme.image" class="images" /> -->

        <div class="card flex justify-content-center">
          <Button label="Show" @click="showImage(theme.image)" class="btn">
            <img alt="user header" :src="theme.image" class="images"
          /></Button>
        </div>
      </template>

      <template #content>
        <div
          class="form-check d-flex align-items-center justify-content-between"
        >
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            :id="`themeCode${theme.id}`"
            v-model="selectedThemeId"
            :value="theme.code"
          />
          <label class="form-check-label" :for="`themeCode${theme.id}`">
            <h5>code : {{ theme.code }}</h5>
          </label>
        </div>
      </template>
      <template #footer>
        <div class="flex gap-3 mt-1"></div>
      </template>
    </Card>
  </div>
  <div class="w-100 d-flex align-items-center justify-content-end">
    <Button :label="$t('save')" @click="update_theme" />
  </div>

  <Dialog
    v-model:visible="visible"
    :dismissableMask="true"
    maximizable
    modal
    :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <img alt="user header" :src="theme_image" class="w-100" />
  </Dialog>

  <!-- <div class="row">
    <pagination
      :data="themes"
      class="mx-auto d-flex align-items-center justify-content-center pagination"
      @pagination-change-page="fetch_theme"
    >
      <template v-slot:prev-nav>&lt;</template>
      <template v-slot:next-nav>&gt;</template>
    </pagination>
  </div> -->
</template>

<script>
import Card from "primevue/card";
import axios from "axios";
// import pagination from "laravel-vue-pagination";

import Dialog from "primevue/dialog";

import Button from "primevue/button";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "update-invoice-theme",
  data() {
    return {
      ingredient: [],
      themes: [],
      selectedThemeId: JSON.parse(localStorage.getItem("user"))?.invoice_theme,
      visible: false,
      theme_image: null,
    };
  },
  components: {
    Card,
    Button,
    Dialog,
    // pagination,
    AddFavDailog,
  },
  methods: {
    fetch_theme() {
      axios.get(`/show_invoice_theme`).then(({ data }) => {
        this.themes = data.data;
        this.loading = false;
      });
    },
    update_theme() {
      axios
        .post("/update_invoice_theme_in_organization", {
          invoice_theme_id: this.selectedThemeId,
        })
        .then(() => {
          // Retrieve current user data from local storage
          let userData = JSON.parse(localStorage.getItem("user"));

          // Update the invoice_theme property
          userData.invoice_theme = this.selectedThemeId;

          // Save the updated user data back to local storage
          localStorage.setItem("user", JSON.stringify(userData));

          Swal.fire({
            icon: "success",
            title: this.$t("save_successfuly"),
          });
        });
    },
    showImage(image) {
      this.theme_image = image;
      this.visible = true;
    },
  },
  created() {
    this.fetch_theme();
  },
};
</script>

<style scoped>
.images {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}
</style>
