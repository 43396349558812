<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("Create New Car Category") }}</h4>
    </div>
    <form class="form_topic" @submit.prevent="addCategory()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("cat_name") }} </label>
            <!-- <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("cat_name") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(car, index) in car_cat_name"
                      :key="index"
                    >
                      <label :for="`name${index}`"
                        >{{ $t("cat_name") }} ({{ car.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="car.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="form-group">
              <TabView>
                <TabPanel
                  v-for="(car, index) in car_cat_name"
                  :key="index"
                  :class="[car.name !== '' ? 'text-success' : '']"
                  :header="$t('cat_name') + ' - ' + car.lang"
                >
                  <div class="form-group mb-0">
                    <input
                      type="text"
                      :placeholder="$t('name')"
                      class="form-control"
                      :id="`name${index}`"
                      v-model="car.name"
                    />
                    <!-- Add any additional content specific to each tab here -->
                  </div>
                </TabPanel>
              </TabView>
            </div>
            <span class="input-errors" v-if="v$.car_cat_name.$error">{{
              v$.car_cat_name.$errors[0].$message
            }}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("add") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/components/LottieFiles/loading.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

export default {
  name: "add-car-category",
  data() {
    return {
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      car_cat_name: [],
      program_name: [],
      Loading: false,
    };
  },
  components: {
    Loading,
    TabView,
    TabPanel,
  },
  validations() {
    return {
      car_cat_name: this.car_cat_name.map(() => {
        return {
          name: { required },
        };
      }),
    };
  },
  methods: {
    setCarCatName() {
      this.languages.forEach((language) => {
        this.car_cat_name.push({
          lang: language,
          name: "",
        });
      });
    },
    async addCategory() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();

        this.car_cat_name.forEach((car) => {
          formData.append(`title_${car.lang}`, car.name);
        });
        this.loading = true; // Start loading
        axios
          .post("/car_categories", formData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.loading = false; // end loading

            this.$router.push("/car_cat");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false; // end loading
          });
      }
    },
  },
  mounted() {
    this.setCarCatName();
  },
};
</script>
<style lang="scss" scoped>
@import "./_cat.scss";
</style>
