<template>
  <Skeleton width="10%" class="mb-3"></Skeleton>
  <div
    class="d-flex align-items-center justify-content-around w-100"
    v-for="i in 2"
    :key="i"
  >
    <div class="d-flex flex-column gap-2 w-100 my-2" v-for="i in 3" :key="i">
      <Skeleton width="15%"></Skeleton>
      <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
    </div>
  </div>
  <!-- <div class="d-flex gap-3 align-items-center">
      <Skeleton width="2%" shape="circle" height="2rem"></Skeleton>
      <Skeleton width="2%" shape="circle" height="2rem"></Skeleton>
      <Skeleton width="2%" shape="circle" height="2rem"></Skeleton>
    </div>
    <div
      class="d-flex align-items-center justify-content-around w-100 mt-4"
      v-for="i in 1"
      :key="i"
    >
      <div class="d-flex flex-column gap-2 w-100 my-2" v-for="i in 3" :key="i">
        <Skeleton width="15%"></Skeleton>
        <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
      </div>
    </div> -->
</template>

<script>
import Skeleton from "primevue/skeleton";
export default {
  components: {
    Skeleton,
  },
};
</script>

<style></style>
