<template>
  <div class="col-lg-3 col-md-12 col-12">
    <div class="btn d-flex justify-content-start">
      <router-link :to="`/pills/${id}`" class="btn SecondButton mx-1">
        <i class="fa-solid fa-print fs-12"></i>
      </router-link>
      <emailDialog :clientEmail="clientEmail" :initialText="text" />
      <whatsAppDialog :clientPhone="clientPhone" :initialText="text" />
      <div
        class="btn SecondButton fs-12 d-flex align-items-center justify-content-center"
        title="edit"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        v-if="hotelsDetials.confirmation_number != ''"
      >
        <i class="fa-solid fa-pen-to-square"></i>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              {{ $t("edit") }}
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="editcheckout()">
              <div class="form-group d-flex align-items-center">
                <div class="ms-2 w-100">
                  <h4>{{ $t("from") }}</h4>
                  <Datepicker
                    v-model="from"
                    class="w-100"
                    lang="ar"
                    :disabled-start-date="{ to: new Date() }"
                    :placeholder="hotelsDetials.check_in"
                  />
                </div>

                <div class="ms-2 w-100">
                  <h4>{{ $t("to") }}</h4>
                  <Datepicker
                    v-model="to"
                    lang="ar"
                    :placeholder="hotelsDetials.check_out"
                    class="w-100"
                  />
                  <!-- :disabled-start-date="{ to: addOneDay(from) }" -->
                </div>
              </div>
              <button class="btn SecondButton ms-2 mt-3" type="submit">
                {{ $t("save") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="card-detalis">
      <div class="header">
        <h4>{{ $t("reservation_detials") }}</h4>
        <button
          class="btn btn-success"
          type="button"
          data-bs-toggle="modal"
          :data-bs-target="`#exampleModal_show${hotelsDetials.id}`"
          v-if="hotelsDetials.receipts && hotelsDetials.payment_method == 1"
        >
          {{ $t("view receipt") }}
        </button>
        <button
          class="btn btn-warning"
          type="button"
          @click="upladReceipt(hotelsDetials.id, Hotel)"
          v-else
        >
          {{ $t("order_receipt") }}
        </button>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-3 gap-2">
        <div class="dit">
          <h5>
            <i class="fa-regular fa-calendar-check"></i>
            {{ $t("reservation_by") }} :
            <span>{{ hotelsDetials.client_name }}</span>
          </h5>
          <h5>
            <i class="fa-regular fa-calendar-check"></i>
            {{ $t("reservation_confirmation_number") }}
            :<span>{{ hotelsDetials.confirmation_number }}</span>
          </h5>
          <h5>
            <i class="fa-regular fa-calendar-check"></i>

            {{ $t("create_reservation_date") }} :
            <span>{{ hotelsDetials.created_at }}</span>
          </h5>
        </div>
        <div class="QR">
          <QrCodeGenerator />
        </div>
      </div>
      <hr />
      <div class="under-line">
        <div class="hed">
          <h4>
            {{ $t("payment details") }}
          </h4>
        </div>
        <div class="pay">
          <h5>
            {{ rooms.length }} {{ $t("rooms") }} -
            {{ hotelsDetials.number_of_nights }} {{ $t("number_night") }}
          </h5>
          {{ hotelsDetials.price_model.total_selling_price_with_tax }} USD
        </div>
      </div>
      <div class="under-line">
        <div class="pay-1">
          <h5>{{ $t("tax") }}</h5>
          {{ hotelsDetials.price_model.selling_price_tax }} USD
        </div>
        <div class="pay-1">
          <h5>
            {{ $t("price_without_tax") }}
          </h5>
          {{ hotelsDetials.price_model.total_selling_price }} USD
        </div>
        <div class="pay-1">
          <h5>
            {{ $t("The total includes value added tax") }}
          </h5>
          {{ hotelsDetials.price_model.total_selling_price_with_tax }} USD
        </div>
        <showReceipt
          :book_id="hotelsDetials.id"
          :book_type="Hotel"
          :image="
            hotelsDetials.receipts ? hotelsDetials?.receipts[0]?.image : ''
          "
          :organizations="organizations"
        />
      </div>
    </div>
  </div>
</template>

<script>
import showReceipt from "./showReceipt.vue";
import QrCodeGenerator from "./QrScan.vue";
import axios from "axios";
import moment from "moment";
import VueDatepickerUi from "vue-datepicker-ui";
import Swal from "sweetalert2";
import whatsAppDialog from "../Dialogs/whatsAppDialog.vue";
import emailDialog from "../Dialogs/emailDialog.vue";
export default {
  name: "sidebar-hotel",
  props: {
    hotelsDetials: Object,
    rooms: Array,
    clientPhone: String,
    clientEmail: String,
  },
  components: {
    Datepicker: VueDatepickerUi,
    whatsAppDialog,
    emailDialog,
    QrCodeGenerator,
    showReceipt,
  },
  data() {
    return {
      organizations: [],
      Hotel: "Hotel",
      text: `شركة جود لاب التقنية
            عزيزي العميل،
            نرجو التكرم بسداد كامل مبلغ الحجز رقم 4365 قبل تاريخ 03/01/2024  على الساعة 17:38

            معلومات الحجز :
            التاريخ : 03/01/2024
            رقم الحجز : 4365
            المبلغ الجملي : 500.00 ريال

            الفندق : فندق أنجم مكة
            تاريخ الدخول : 03/01/2024      
            تاريخ الخروج : 04/01/2024
            النزيل : test mahmoud
            آخر تاريخ للدفع : 03/01/2024 على الساعة 17:38

            الغرف :
            نوع الغرفة : غرفة لأربع أشخاص من 1/3/2024 الى 1/4/2024
            عدد الغرف : 1
            الإطلالة : مطلّة على المدينة
            الوجبة : غرفة بدون وجبة


            الخدمات :
            معلومات العميل :
            معرّف العميل : 12949
            الإسم و اللقب : sokna alhijaz
            الهاتف : 
            الجوال : 
            البريد الإلكتروني :`,
      email: "",
      phone: "",
      address: "",
      userName: JSON.parse(localStorage.getItem("user"))?.name,
      numberOfNights: 0,
      id: this.$route.params.id,
      from: "",
      to:  "",
      modalImage: "",
    };
  },
  methods: {
    fetch_organizations() {
      axios
        .post(`fetch_my_organization_accounts`, { type: 1 })
        .then(({ data }) => {
          this.organizations = data.data.data;
          // console.log(this.organizations);
        });
    },
    upladReceipt(id, type) {
      axios
        .post(`/send_receipt_request`, { book_id: id, book_type: type })
        .then(() => {
          Swal.fire({
            title: "",
            text: this.$t("order_receipt_sent"),
            icon: "success",
          });
        });
    },
    addOneDay(date) {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 2);
      return newDate.toISOString().split("T")[0];
    },
    editcheckout() {
      const formData = new FormData();
      formData.append("check_in", moment(this.from).format("YYYY-MM-DD"));
      formData.append("check_out", moment(this.to).format("YYYY-MM-DD"));
      if (this.hotelsDetials.confirmation_number != null) {
        formData.append("booking_code", this.hotelsDetials.confirmation_number);
      }
      axios
        .post("/amend_booking", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    calculateNumberOfNights() {
      const checkInDate = new Date(this.hotelsDetials.check_in);
      const checkOutDate = new Date(this.hotelsDetials.check_out);

      // Calculate the time difference in milliseconds
      const timeDiff = checkOutDate.getTime() - checkInDate.getTime();

      // Calculate the number of nights
      const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));

      // Update the numberOfNights data property
      this.numberOfNights = nights;
    },
  },
  mounted() {
    this.calculateNumberOfNights();
    this.fetch_organizations();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.fa-solid,
.fa-brands {
  font-size: 1.2rem !important;
}

.SecondButton {
  background-color: transparent;
  color: #0a909b;
  border: 1px solid #0a909b;
  font-size: 1.2rem;
  margin: 0 5px;
  font-family: "bold";
  padding: 0.6rem 1.1rem;
  transition: 0.7s ease-in-out;
}

@media screen and (min-width: 1024px) {
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
