<template>
  <!-- <div v-if="loading" class="loading">
    <Loading />
  </div> -->
  <section class="all_emp card_style">
    <div class="row">
      <div
        class="col-lg-11 col-md-12 col-12 d-flex align-items-center gap-3 mb-2"
      >
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>
          {{ $t("rooming_list") }}
        </h4>
      </div>
      <!--      <button class="btn SecondButton col-1" v-print="'#printMe'" title="PDF">-->
      <!--        <i class="fa-solid fa-file-pdf"></i>-->
      <!--      </button>-->
    </div>
    <div class="row mb-2 align-items-center justify-content-between">
      <!-- <div class="col-lg-4 col-md-12 col-12"></div> -->
      <form class="col-lg-6 col-md-12 col-12">
        <div class="form-group">
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            class="form-control search w-75"
            :placeholder="$t('search by name')"
            v-model="resrvationName"
            @keyup="fetchBooks((page = 1))"
          />
        </div>
      </form>
      <div class="col-lg-4 col-md-12 col-12 d-flex justify-content-end">
        <!-- <filterBookings @getData="getData" :booking_type="book_type" /> -->
        <!-- <filterBookings @fetchBooks = "fetchBooks"/> -->
        <FilterOfflineBooking @getData="getData" :bookSearch="resrvationName" />
      </div>
      <div class="col-lg-2 col-md-12 col-12 d-flex">
        <button class="btn SecondButton mx-2" v-print="'#printMe'" title="PDF">
          <i class="fa-solid fa-file-pdf"></i>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive" id="printMe">
        <HeaderPdf :title="$t('rooming_list')" :fromDate="''" :toDate="''" />
        <table class="table visaType arrivalGuests" id="VisaTable">
          <thead>
            <tr>
              <th class="no-print">ID</th>
              <th>{{ $t("book_number") }}</th>
              <th>{{ $t("confirmation_number") }}</th>
              <th>{{ $t("hotel_name") }}</th>
              <th>{{ $t("rooms") }}</th>
              <th>{{ $t("meals") }}</th>
              <th>{{ $t("guests") }}</th>
              <th>{{ $t("checkin") }}</th>
              <th>{{ $t("checkout") }}</th>
              <th>{{ $t("book_date") }}</th>
              <th>{{ $t("book_status") }}</th>
              <!-- <th>{{ $t("Control") }}</th> -->
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="guests in 10" :key="guests">
              <td v-for="guests in 11" :key="guests">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(guests, index) in books.data" :key="index">
              <td class="no-print default_table">
                <router-link :to="`/hotels/book_offline/${guests.id}`">{{
                  guests.id
                }}</router-link>
              </td>
              <td class="default_table">{{ guests.book_number }}</td>
              <td class="default_table">
                <div v-if="!guests?.rooms?.length">-</div>
                <div v-for="(room, index) in guests.rooms" :key="index">
                  <!-- <span v-tooltip.left="$t('hotel_confirmation')" v-if="room?.hotel_reference != null"></span> -->
                  <span v-if="room?.hotel_reference == null">-</span>
                  <p
                    class="d-inline-block text-center mb-0"
                    v-tooltip.left="$t('hotel_confirmation') + $t('code')"
                  >
                    {{ room?.hotel_reference == null ? "" : room?.hotel_reference }}
                  </p>
                </div>
              </td>
              <td class="hotel_table">
                <p class="mb-0" v-if="guests.hotel">
                  {{ guests.hotel?.hotel_name }}
                </p>
                <div v-else>
                  <p
                    class="mb-0"
                    v-for="(hotel, index) in guests.hotels"
                    :key="index"
                  >
                    {{ hotel?.hotel_name }}
                  </p>
                </div>
              </td>
              <td class="room_table">
                <div
                  class="guest_room"
                  v-for="(room, index) in guests.rooms"
                  :key="index"
                >
                  <div class="d-flex justify-content-center flex_table_print">
                    <p
                      class="text-center"
                      v-tooltip.left="$t('room_type')"
                      v-html="room?.room_name"
                    ></p>
                    ,
                    <p class="text-center" v-tooltip.left="$t('supplier')">
                      {{ room?.supplier?.supplier_name }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="meal_table">
                <div
                  class="guest_room"
                  v-for="(room, index) in guests.rooms"
                  :key="index"
                >
                  <div class="d-flex justify-content-center">
                    <p
                      class="text-center"
                      v-tooltip.left="$t('meal_type')"
                      v-html="room?.meal_type?.title"
                    ></p>
                  </div>
                </div>
              </td>
              <td class="default_table">
                <div class="d-flex flex-column align-items-center mx-2">
                  <p
                    style="width: max-content; margin-bottom: 0"
                    v-tooltip.left="$t('client_name')"
                  >
                    {{ guests?.guest?.name }}
                  </p>
                </div>
              </td>
              <td class="date_table">{{ guests.check_in_date }}</td>
              <td class="date_table">{{ guests.check_out_date }}</td>
              <td class="date_table">{{ guests.created_at }}</td>
              <td class="default_table">
                <span
                  :class="[
                    'badge mx-1',
                    guests?.book_offline_status == 0
                      ? 'bg-secondary text-capitalize'
                      : guests?.book_offline_status == 1
                      ? 'bg-primary text-capitalize'
                      : guests?.book_offline_status == 2
                      ? 'bg-success text-capitalize'
                      : guests?.book_offline_status == 3
                      ? 'bg-danger text-capitalize'
                      : guests?.book_offline_status == 4
                      ? 'bg-danger text-capitalize'
                      : '',
                  ]"
                >
                  {{
                    guests?.book_offline_status == 0
                      ? $t("pending")
                      : guests?.book_offline_status == 1
                      ? $t("tentative")
                      : guests?.book_offline_status == 2
                      ? $t("confirmed")
                      : guests?.book_offline_status == 3
                      ? $t("cancelled")
                      : guests?.book_offline_status == 4
                      ? $t("canceled")
                      : ""
                  }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="books"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchBooks"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
// import filterBookings from "../../../components/offline/filterBookings.vue";
import Swal from "sweetalert2";
// import Loading from "@/components/LottieFiles/loading.vue";

import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import FilterOfflineBooking from "@/components/offline_booking/FilterOfflineBooking.vue";
import Skeleton from "primevue/skeleton";

import HeaderPdf from "@/components/pdf/headerPdf.vue";

export default {
  name: "bookTablePage",
  data() {
    return {
      books: [],
      Form: {},
      bookingType: "",
      resrvationName: "",
      loading: true,
      book_type: this.$route.params.bookType,
      filterButton: "",
      filterParams: {},
    };
  },
  components: {
    HeaderPdf,
    pagination,
    // filterBookings,
    // Loading,
    AddFavDailog,
    FilterOfflineBooking,
    Skeleton,
  },
  methods: {
    fetchBooks(page = 1) {
      this.loading = true;
      // let params = {
      //   book_offline_status: 2,
      // };
      // this.filterParams,
      // if (this.resrvationName != "") {
      //   params.word = this.resrvationName;
      // }
      axios
        .post(`/index_offline_booking_hotel?page=${page}`, this.filterParams)
        .then(({ data }) => {
          this.books = data.data;
          // this.indexPage = page;
          this.loading = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.books = [];
        });
    },
    getData(data) {
      this.filterParams = data;
      this.fetchBooks();
    },
    cancelBook(index, bookID) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`/cancel_booking`, { booking_id: bookID })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.books.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
            });
        }
      });
    },
  },
  updated() {
    $("#bookTable").DataTable({
      bPaginate: false,
      retrieve: true,
      searching: false,
      paging: false,
      ordering: false,
      info: false,
      oLanguage: { sZeroRecords: "", sEmptyTable: "" },
      dom: "Blfrtip",
      buttons: [
        {
          extend: "csvHtml5",
          text: '<i class="fa-solid fa-file-csv"></i>',
          titleAttr: "CSV",
          title: this.$t("reservation"),
          charset: "utf-8",
          bom: "true",
          init: function (node) {
            $(node).removeClass("btn-default");
          },
          exportOptions: {
            columns: ":not(:last-child)",
          },
        },
      ],
    });
  },
  created() {
    this.fetchBooks();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
// @import "offline.scss";
// @page {
//   size: A4 landscape;
//   margin: 0;
//   padding: 0;
// }
.selects {
  .form-group {
    width: 100%;
  }
}
.search {
  padding-inline-start: 3rem !important;
}
.table thead tr th {
  font-size: 0.8rem;
}
.main_header_style {
  margin-bottom: unset !important;
}
.btn-drop {
  width: fit-content !important;
}
.table thead tr th {
  text-align: center !important;
}
td {
  text-align: center !important;
}
form {
  .form-group {
    position: relative;
    i {
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }
}

@media print {
  .no-print {
    display: none !important;
  }
  .table {
    thead {
      tr {
        th {
          padding-inline: 1.5rem !important;
        }
      }
    }
    tbody {
      tr {
        td {
          padding-inline: 1.5rem !important;
        }
      }
    }
  }
}
</style>
