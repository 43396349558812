<template>
  <section class="card_style">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4 v-if="this.$route.params.type == 'arrivals'">
        {{ $t("arrivals report  for hotel") }}
      </h4>
      <h4 v-else>{{ $t("Reports") + " " + this.$route.params.type }}</h4>
    </div>
    <form class="row mb-2" @submit.prevent="fetchDuringPeriodFilters">
      <div class="col-12 col-lg-4">
        <div class="form-control border-0">
          <label class="form-label">{{ $t("Hotel") }}</label>
          <Multiselect
            v-model="form.hotels"
            :options="hotels"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('Choose_hotel')"
            select-label=""
            :hide-selected="true"
            :multiple="true"
            :custom-label="formatHotelLabel"
            @keyup="searchHotel"
          >
          </Multiselect>
          <!-- <span class="input-errors" v-if="v$.form.hotels.$error">{{
            v$.form.hotels.$errors[0].$message
          }}</span> -->
        </div>
      </div>
      <div class="col-12 col-lg-6" v-if="this.$route.params.type == 'arrivals'">
        <div class="form-control border-0">
          <label class="form-label">{{ $t("date") }}</label>
          <Datepicker
            v-model="form.day"
            :format="dateFormat"
            :not-after="form.to"
            name="from"
            :placeholder="$t('date')"
          ></Datepicker>
          <span class="input-errors" v-if="v$.form.day.$error">{{
            v$.form.day.$errors[0].$message
          }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-3" v-if="this.$route.params.type == 'in-house'">
        <div class="form-control border-0">
          <label class="form-label">{{ $t("From") }}</label>
          <Datepicker
            v-model="form.from"
            :format="dateFormat"
            :not-after="form.to"
            name="from"
            :placeholder="$t('From')"
          ></Datepicker>
          <span class="input-errors" v-if="v$.form.from.$error">{{
            v$.form.from.$errors[0].$message
          }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-3" v-if="this.$route.params.type == 'in-house'">
        <div class="form-control border-0">
          <label class="form-label">{{ $t("To") }}</label>
          <Datepicker
            v-model="form.to"
            :format="dateFormat"
            :not-after="form.to"
            name="from"
            :placeholder="$t('From')"
          ></Datepicker>
          <span class="input-errors" v-if="v$.form.to.$error">{{
            v$.form.to.$errors[0].$message
          }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-2 d-flex">
        <button type="submit" class="btn PrimaryButton mb-2">
          {{ $t("search") }} <i class="fa-solid fa-magnifying-glass ms-2"></i>
        </button>
      </div>
    </form>
    <table v-if="loading" class="table table-striped">
      <tbody>
        <tr v-for="report in 10" :key="report.id">
          <td class="text-center mx-2" v-for="item in 7" :key="item">
            <Skeleton width="90%" height="2rem" class="mb-2"></Skeleton>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="table" v-else>
      <ReportTable
        :reports="reports"
        :loading="loading"
        :checkIn="form.from ? form.from : ''"
        :checkOut="form.to ? form.to : ''"
        :hotels="form.hotels"
        :fromDay="form.day ? form.day : ''"
        v-if="reports.length > 0"
        @roomAdded="fetchDuringPeriodReports"
        :fetchDuringPeriodFilters="fetchDuringPeriodFilters"
      />
      <div v-else>
        <div class="d-flex align-items-center justify-content-center">
          <img
            src="@/assets/media/image/no-data.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <p class="text-center my-5 fs-3">
          {{ $t("No Data Found In Selected Day") }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import axios from "axios";
import ReportTable from "@/components/table/reportTable.vue";
import Swal from "sweetalert2";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import Skeleton from "primevue/skeleton";

export default {
  data() {
    return {
      loading: true,
      v$: useVuelidate(),
      form: {
        hotels: [],
        from: new Date(),
        to: new Date(new Date().setDate(new Date().getDate() + 1)), // Set 'to' to tomorrow's date
        day: new Date(),
      },
      hotels: [],
      reports: [],
    };
  },
  validations() {
    return {
      form: {
        from: this.$route.params.type == "in-house" ? { required } : {},
        to: this.$route.params.type == "in-house" ? { required } : {},
        day: this.$route.params.type == "arrivals" ? { required } : {},
      },
    };
  },
  components: {
    AddFavDailog,
    Multiselect,
    Datepicker,
    ReportTable,
    Skeleton,
  },
  watch: {
    $route() {
      this.reports = [];
    },
  },
  methods: {
    searchHotel(event) {
      const searchText = { word: event?.target?.value };
      if (event?.target?.value.length) {
        axios
          .post(`/fetch_new_home_hotels`, searchText)
          .then((res) => (this.hotels = res.data.data.hotels))
          .catch((err) => console.log(err));
      } else {
        axios
          .post(`/fetch_new_home_hotels`)
          .then((res) => (this.hotels = res.data.data.hotels))
          .catch((err) => console.log(err));
      }
    },
    formatHotelLabel(hotel) {
      return `${hotel.title} -
              ${hotel.city_title}, ${hotel.country_title} (${hotel.id})`;
    },
    fetchDuringPeriodReports() {
      if (this.$route.params.type == "arrivals") {
        axios.post("/fetch_room_arrived_list").then(({ data }) => {
          this.reports = data.data;
          this.loading = false;
          // console.log(this.reports);
          // console.log(this.loading);
        });
      }
    },
    fetchDuringPeriodFilters() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (this.$route.params.type == "in-house") {
          this.loading = true;
          const formData = new FormData();
          formData.append(
            "check_in",
            moment(this.form.from).format("YYYY-MM-DD"),
          );
          formData.append(
            "check_out",
            moment(this.form.to).format("YYYY-MM-DD"),
          );
          this.form.hotels.forEach((hotel) => {
            formData.append("hotel_ids[]", hotel.id);
          });
          axios.post("/fetch_room_in_house_list", formData).then(({ data }) => {
            this.reports = data.data;
            this.loading = false;
            if (this.reports.length == 0) {
              Swal.fire({
                title: this.$t("error"),
                text: this.$t("No Data Found"),
                icon: "error",
              });
            }
          });
        } else if (this.$route.params.type == "arrivals") {
          const formData = new FormData();
          formData.append("day", moment(this.form.day).format("YYYY-MM-DD"));
          this.form.hotels.forEach((hotel, index) => {
            formData.append(`hotel_ids[${index}]`, hotel.id);
          });
          axios.post("/fetch_room_arrived_list", formData).then(({ data }) => {
            this.reports = data.data;
            this.loading = false;
            // if (this.reports.length == 0) {
            //   Swal.fire({
            //     title: this.$t("error"),
            //     text: this.$t("No Data Found"),
            //     icon: "error",
            //   });
            // }
          });
        }
      }
      // console.log("omar");
    },
  },
  mounted() {
    this.fetchDuringPeriodFilters();
    this.searchHotel();
    this.$store.state.hotelValue = null;
    this.$store.state.roomValue = null;
    this.$store.state.supplierValue = null;
    this.$store.state.client_id = null;
    this.$store.state.payment_status = null;
    this.$store.state.booking_status = null;
    this.$store.state.post_status = null;
    this.$store.state.city = null;
    this.$store.state.nationality = null;
    this.$store.state.book_number = null;
    this.$store.state.hotel_ref = null;
    this.$store.state.suplier_ref = null;
    this.$store.state.hotel_as_suplier = null;
    this.$store.state.employ = null;
    this.$store.state.meal = null;
    this.$store.state.rate = null;
    this.$store.state.client_category = null;
    this.$store.state.paymentMethod = null;
    this.$store.state.paymentSuplier = null;
    this.$store.state.clientType = null;
    this.$store.state.suplierType = null;
    this.$store.state.discountType = null;
    this.$store.state.guest = null;
    this.$store.state.share_amount = null;
  },
};
</script>

<style lang="scss" scoped>
.table thead tr th {
  text-align: center !important;
}
</style>
