<script setup>
import { onMounted, ref } from "vue";
// import socialMedia from "@/components/offline/newInvoice/component/socialMedia.vue";
// import dataInvoicePerson from "@/components/offline/newInvoice/component/dataInvoicePersonEn.vue";
// import Terms from "@/components/offline/newInvoice/component/termsEn.vue";
// import TotalPrice from "@/components/offline/newInvoice/component/TotalPriceEn.vue";
// import services from "@/components/offline/newInvoice/component/servicesEN.vue";
import axios from "axios";
import { useRoute } from "vue-router";
const route = useRoute();

const pills = ref({});
const book_status_name = ref(null);

const fetchData = async () => {
  await axios
    .post(`get_all_base_booking_invoice`, {
      base_booking_tax_invoice_id: route.params.id,
    })
    .then((res) => {
      pills.value = res.data.data;
      book_status_name.value = res.data.data.book_status_name;
    })
    .catch((err) => {
      console.log(err);
    });
};

onMounted(async () => {
  await fetchData();
});
</script>

<template>
  <section class="container-fluid">
    <div class="d-flex">
      <button class="PrimaryButton printButton" v-print="'#specific-section'">
        <i class="fa fa-print"></i>
      </button>
    </div>
    <div class="main-parnt">
      <!-- Head invoice -->
      <div class="specific-section" id="specific-section">
        <div class="invoice">
          <div class="head_invoice">
            <div class="book_status">
              <h6>
                booking status :
                <span>{{ book_status_name }}</span>
              </h6>
              <div class="chart"></div>
              <div class="chart"></div>
            </div>
            <div class="logo">
              <img :src="pills?.organization?.default_image" alt="logo" />
            </div>
            <div class="book_number">
              <div class="chart"></div>
              <div class="chart"></div>
              <h6>
                booking number :
                <span>#{{ pills.book_number }}</span>
              </h6>
            </div>
          </div>
          <!--          <dataInvoicePerson :pills="pills" />-->
          <div class="welcome_text">
            <!-- <h4>Dear Sir : {{ pills.client_name }}</h4> -->
            <h5>
              Greeting From <span>{{ pills.organization?.name }}</span>
            </h5>
            <div class="pragra">
              <p>
                First of All, We would like to take this opportunity to welcome
                you at
                <strong> {{ pills.organization?.name }} </strong> and tourism We
                are pleased to confirm the following reservation on a
                <span>{{ book_status_name }}.</span>
              </p>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="frist">-</th>
                  <th class="frist">{{ $t("client_name") }}</th>
                  <th class="frist">{{ $t("book_type") }}</th>
                  <th scope="col">{{ $t("price") }}</th>
                  <th scope="col">{{ $t("tax") }}</th>
                  <th scope="col">{{ $t("price_after_tax") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(room, index) in pills.sub_invoice" :key="index">
                  <td class="frist">
                    {{ room.book_number ? room.book_number : "-" }}
                  </td>
                  <td>{{ room.client_name.name }}</td>
                  <td>
                    {{ room.type === 2 ? $t("offline") : $t("online") }}
                  </td>
                  <td>{{ room.total }}</td>
                  <td>{{ room.tax }}</td>
                  <td>{{ room.total_after_tax }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="invoice_organiztion_data">
            <ul class="social_accounts">
              <li>
                <i class="fa-solid fa-globe"></i>
                <a
                  :href="pills.organization?.website_link"
                  target="_blank"
                  class="text"
                  >{{ pills.organization?.website_link }}</a
                >
              </li>
              <li>
                <i class="fa-solid fa-envelope"></i>
                <a :href="`mailto:${pills.organization?.email}`" class="text">{{
                  pills.organization?.email
                }}</a>
              </li>
              <li>
                <i class="fa-solid fa-phone"></i>
                <a :href="`tel:${pills.organization?.phone}`" class="text">{{
                  pills.organization?.phone
                }}</a>
              </li>
              <li>
                <i class="fa-solid fa-location-dot"></i>
                <p class="text">{{ pills.organization?.address }}</p>
              </li>
            </ul>
            <div class="logo">
              <img :src="pills.organization?.default_image" />
              <div class="data">
                <h6 class="created_at" v-if="pills.created_at_date">
                  {{ pills.created_at_date }} -
                  {{ pills.created_at_time }}
                </h6>
                <h6
                  class="employee_name"
                  v-if="pills.organization_employee_name"
                >
                  {{ pills.organization_employee_name }}
                </h6>
              </div>
              <div class="data">
                <h6 class="created_at" v-if="pills.organization_employee_phone">
                  {{ pills.organization_employee_phone }}
                </h6>
                <h6
                  class="employee_name"
                  v-if="pills.organization_employee_position"
                >
                  {{ pills.organization_employee_position }}
                </h6>
              </div>
              <socialMedia :organization="pills.organization" />
            </div>
          </div>
        </div>
      </div>

      <!-- Head invoice -->
    </div>

    <!-- client reservation data -->
    <!-- client reservation data -->

    <!-- welcome invoice data -->
    <!-- welcome invoice data -->

    <!-- Table services -->
    <!--    <services :service="service" v-if="service.length" />-->
    <!-- Table services -->

    <!-- Terms & amount reservation -->
    <div class="amounts_terms">
      <!-- Terms -->
      <!--      <Terms :terms="pills?.Conditions" />-->
      <!-- Terms -->

      <!--      <TotalPrice-->
      <!--        :total_selling_price_with_out_tax="total_selling_price_with_out_tax"-->
      <!--        :selling_tax="selling_tax"-->
      <!--        :total_selling_price="total_selling_price"-->
      <!--      />-->
    </div>
    <!-- Terms & amount reservation -->

    <!-- Account Data -->
    <!--    <div class="table_account" v-if="pills?.account">-->
    <!--      <div-->
    <!--        class="list_account d-flex align-items-center justify-content-between w-100"-->
    <!--      >-->
    <!--        <div class="account_data">-->
    <!--          <p class="title_account">Account Name</p>-->
    <!--          <p class="text_account">: {{ pills.account?.title }}</p>-->
    <!--        </div>-->
    <!--        <div class="account_data">-->
    <!--          <p class="title_account">IBAN</p>-->
    <!--          <p class="text_account">: {{ pills.account?.iban }}</p>-->
    <!--        </div>-->
    <!--        <div class="account_data">-->
    <!--          <p class="title_account">Account number</p>-->
    <!--          <p class="text_account">: {{ pills.account?.account_number }}</p>-->
    <!--        </div>-->
    <!--        <div class="account_data">-->
    <!--          <p class="title_account">Swift Code</p>-->
    <!--          <p class="text_account">: {{ pills.account?.swift_code }}</p>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- Account Data -->

    <div class="footer_invoice"></div>
  </section>
</template>

<style scoped lang="scss">
@import "@/components/offline/newInvoice/_pill.scss";
</style>
