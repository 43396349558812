<script setup>
import detailsOffCanvas from "@/components/dailyConstruct/detailsOffCanvas.vue";
import axios from "axios";
import { ref, defineProps } from "vue";

const props = defineProps({
  dailyRestrictions: {
    type: Array,
    required: true,
    default: () => [],
  },
});
const item = ref({});

const getTransferItem = (id) => {
  axios.get(`daily_restriction/${id}`).then((res) => {
    item.value = res.data.data;
  });
};
</script>

<template>
  <div class="row">
    <div
      class="col-12 border border-1 d-flex align-items-center gap-3 p-2"
      style="overflow-x: auto"
    >
      <div
        v-for="dailyRestriction in props.dailyRestrictions"
        :key="dailyRestriction.id"
        class="p-2 border border-1 rounded"
      >
        <span
          class="text-info cursor-pointer"
          data-bs-toggle="offcanvas"
          :data-bs-target="`#offcanvasBottomDetails${item.id}`"
          aria-controls="offcanvasBottom"
          :title="$t('details')"
          @click="getTransferItem(dailyRestriction.id)"
        >
          <!--              <i class="fa-solid fa-info"></i>>-->
          {{ dailyRestriction.text }}
        </span>
        <detailsOffCanvas v-if="item" :item="item" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
