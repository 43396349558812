<template>
  <section class="card_style">
    <!-- skelton -->
    <div class="w-100" v-if="loading && $route.params.id">
      <Skeleton height="2rem" width="20%" class="mb-2"></Skeleton>
      <div class="w-100">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="w-100 d-flex mt-3 flex-column" v-for="i in 3" :key="i">
              <Skeleton width="10%" class="mb-2"></Skeleton>
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="w-100 d-flex mt-3 flex-column" v-for="i in 3" :key="i">
              <Skeleton width="10%" class="mb-2"></Skeleton>
              <Skeleton height="3rem" width="90%" class="mb-2"></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- skelton -->
    <form @submit.prevent="addTerms()" v-else>
      <div class="main_header">
        <h4>{{ $t("terms_and_conditions") }}</h4>
      </div>
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="accordion" id="accordionExample1">
            <div class="accordion-item">
              <h4>
                {{ $t("title") }}
              </h4>
              <TabView>
                <TabPanel
                  v-for="(term, index) in terms_title"
                  :key="index"
                  :header="term.locale"
                >
                  <div class="form-group">
                    <input
                      type="text"
                      :placeholder="$t('title')"
                      class="form-control"
                      :id="`title${index}`"
                      v-model="term.title"
                    />
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>

          <div class="d-flex align-items-center my-3">
            <p class="form-check-label mb-0" for="flexSwitchCheckDefault">
              {{ $t("custom_invoices") }}
            </p>
            <div class="form-check form-switch mx-3">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                v-model="checked"
                id="flexSwitchCheckDefault"
              />
            </div>
            <p class="form-lable mb-0">
              {{ $t("all_invoices") }}
            </p>
          </div>
          <div class="form-group mt-2" v-if="checked">
            <label class="form-lable">
              {{ $t("invoice") }}
            </label>
            <Multiselect
              v-model="invoice"
              :placeholder="$t('select_invoice')"
              :options="invoice_options"
              label="status"
              track-by="invoice_type"
              select-label=""
              :hide-selected="true"
              :multiple="true"
              :clear-on-select="true"
            />
          </div>
        </div>

        <div class="col-lg-6 col-12">
          <div class="accordion" id="accordionExample2">
            <div class="accordion-item">
              <h4>
                {{ $t("description") }}
              </h4>
              <TabView>
                <TabPanel
                  v-for="(term, index) in terms_desc"
                  :key="index"
                  :header="term.locale"
                >
                  <div class="form-group">
                    <Editor
                      :id="`desc${index}`"
                      :placeholder="$t('description')"
                      v-model="term.title"
                    />
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
        <div class="col-12 mt-4">
          <button type="submit" class="btn PrimaryButton" :disabled="!isValid">
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import Editor from "primevue/editor";
import axios from "axios";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { Multiselect } from "vue-multiselect";

export default {
  name: "termsAndConditions",
  components: {
    Editor,
    Skeleton,
    TabView,
    TabPanel,
    Multiselect,
  },
  data() {
    return {
      checked: false,
      languages: ["ar", "en", "id"],
      terms_title: [],
      terms_desc: [],
      term_id: this.$route.params.id,
      loading: true,
      invoice_options: [
        { status: "Canceled", invoice_type: 12 },
        { status: "Confirmation", invoice_type: 1 },
        { status: "Non Refundable", invoice_type: 2 },
        { status: "TAX INVOICE EN\n", invoice_type: 3 },
        { status: "TAX INVOICE AR\n", invoice_type: 4 },
        { status: "TENTATIVE", invoice_type: 5 },
        { status: "PRICE OFFER", invoice_type: 6 },
        { status: "REPORT", invoice_type: 7 },
        { status: "ROOMING LIST\n", invoice_type: 8 },
        { status: "Supplier Voucher", invoice_type: 9 },
        { status: "Voucher En", invoice_type: 11 },
        { status: "Voucher ", invoice_type: 10 },
        { status: "Default ", invoice_type: 0 },
      ],
      invoice: [],
    };
  },
  methods: {
    setTermsLang() {
      this.languages.forEach((ele) => {
        this.terms_title.push({
          locale: ele,
          title: "",
        });

        this.terms_desc.push({
          locale: ele,
          title: "",
        });
      });
    },
    fetchTermData() {
      axios
        .get(`/show_terms_and_conditions/${this.term_id}`)
        .then(({ data }) => {
          this.terms_title = data.data.titles;
          this.terms_desc = data.data.description.map((ele) => {
            return {
              locale: ele.locale,
              title: ele.description,
            };
          });
          // if (data.data.status == "DEFAULT") {
          //   this.invoice = {
          //     type: 0,
          //     title: "Default",
          //   };
          // } else if (data.data.status == "CANCELED") {
          //   this.invoice = {
          //     type: 12,
          //     title: "Canceled",
          //   };
          // } else if (data.data.status == "CONFIRMATION") {
          //   this.invoice = {
          //     type: 1,
          //     title: "Confirmation",
          //   };
          // } else if (data.data.status == "NON_REFUNDABLE") {
          //   this.invoice = {
          //     type: 2,
          //     title: "Non Refundable",
          //   };
          // } else if (data.data.status == "TAX_INVOICE_EN") {
          //   this.invoice = {
          //     type: 3,
          //     title: "TAX INVOICE EN",
          //   };
          // } else if (data.data.status == "TAX_INVOICE_AR") {
          //   this.invoice = {
          //     type: 4,
          //     title: "TAX INVOICE AR",
          //   };
          // } else if (data.data.status == "TENTATIVE") {
          //   this.invoice = {
          //     type: 5,
          //     title: "TENTATIVE",
          //   };
          // } else if (data.data.status == "PRICE_OFFER") {
          //   this.invoice = {
          //     type: 6,
          //     title: "PRICE OFFER",
          //   };
          // } else if (data.data.status == "REPORT") {
          //   this.invoice = {
          //     type: 7,
          //     title: "REPORT",
          //   };
          // } else if (data.data.status == "ROOMING_LIST") {
          //   this.invoice = {
          //     type: 8,
          //     title: "ROOMING LIST",
          //   };
          // } else if (data.data.status == "SUPPLIER_VOUCHER") {
          //   this.invoice = {
          //     type: 9,
          //     title: "Supplier Voucher",
          //   };
          // } else if (data.data.status == "VOUCHER") {
          //   this.invoice = {
          //     type: 10,
          //     title: "Voucher",
          //   };
          // } else if (data.data.status == "VOUCHER_EN") {
          //   this.invoice = {
          //     type: 11,
          //     title: "Voucher En",
          //   };
          // }
          this.invoice = data.data.invoice_types;
          console.log(this.invoice_options);
          this.loading = false;
          this.checked = data.data.status == 0 ? true : false;
        });
    },
    addTerms() {
      const formData = new FormData();
      this.terms_title.forEach((ele) => {
        formData.append(`title_${ele.locale}`, ele.title);
      });
      this.terms_desc.forEach((ele) => {
        formData.append(`description_${ele.locale}`, ele.title);
      });

      // this.invoice.forEach((ele, index) => {
      //   formData.append(`status[${index}]`, ele.type);
      // });

      if (this.checked) {
        this.invoice.forEach((ele, index) => {
          formData.append(`invoice_types[${index}]`, ele.invoice_type);
        });
        formData.append("status", 0);
      } else {
        formData.append("status", 1);
      }

      if (this.term_id !== undefined) {
        // edit term
        axios
          .post(`/update_terms_and_conditions/${this.term_id}`, formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push(`/terms_and_conditions`);
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error,
              icon: "error",
            });
          });
      } else {
        // add term
        axios
          .post(`/store_terms_and_conditions`, formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push(`/terms_and_conditions`);
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error,
              icon: "error",
            });
          });
      }
    },
  },
  computed: {
    isValid() {
      const titlesValid = this.terms_title.every(
        (term) => term.title.trim() !== ""
      );
      const descriptionsValid = this.terms_desc.every(
        (term) => term.title.trim() !== ""
      );
      const invoicesValid =
        !this.checked || (this.checked && this.invoice?.length > 0);

      return titlesValid && descriptionsValid && invoicesValid;
    },
  },

  mounted() {
    this.setTermsLang();
    if (this.term_id !== undefined) {
      this.fetchTermData();
    }
  },
};
</script>

<style></style>
