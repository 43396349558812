<template>
    <div
      class="offcanvas offcanvas-bottom"
      tabindex="-1"
      id="offcanvasBottom"
      aria-labelledby="offcanvasBottomLabel"
    >
      <div class="offcanvas-header">
        <h4 class="offcanvas-title" id="offcanvasBottomLabel">
          {{ $t("Details of rooms") }}
        </h4>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="close()"
        ></button>
        <!-- <button type="button" class="btn-close text-reset" aria-label="Close" @click="close()" id="close_btn"></button> -->
      </div>
      <div class="offcanvas-body small">
        <div class="row">
          <div class="col-lg-2 col-md-4 col-6">
            <div class="form-group add_room">
              <label class="form-label" for="name">{{ $t("room_name") }}</label>
              <Multiselect
                v-model="room_value"
                :options="rooms"
                :placeholder="$t('choose_rooms')"
                label="title"
                track-by="id"
                :clear-on-select="true"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-6">
            <div class="form-group">
              <label class="form-label" for="pax">{{ $t("Pax") }}</label>
              <input
                type="number"
                class="form-control"
                id="pax"
                v-model="pax_value"
              />
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-6">
            <div class="form-group">
              <label class="form-label" for="children">{{
                $t("children")
              }}</label>
              <input
                type="number"
                class="form-control"
                id="children"
                v-model="children_count"
              />
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-6">
            <div class="form-group">
              <label class="form-label" for="Max_Pax">{{ $t("Max Pax") }}</label>
              <input
                type="number"
                class="form-control"
                id="Max_Pax"
                v-model="max_pax"
              />
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-6">
            <div class="form-group">
              <label class="form-label" for="view">{{ $t("view") }}</label>
              <Multiselect
                v-model="view_values"
                :options="views"
                :placeholder="$t('choose_view')"
                label="title"
                track-by="meal_type_id"
                :clear-on-select="true"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-6">
            <div class="form-group">
              <label class="form-label" for="meal">{{ $t("meal") }}</label>
              <Multiselect
                v-model="meal_value"
                :options="meals"
                :placeholder="$t('choose_meals')"
                label="title"
                track-by="meal_type_id"
                :clear-on-select="true"
                select-label=""
                :hide-selected="true"
                :multiple="true"
              >
              </Multiselect>
            </div>
          </div>
          <div class="col-12">
            <div class="image_upload">
              <label for="videoEntry" class="upload_img" v-if="videoEntry == ''">
                <div class="image_caption">
                  <i class="fa-solid fa-video"></i>
                  <span>
                    {{ $t("Attach a video of the hotel, not exceeding 30 MB") }}
                  </span>
                  <a>{{ $t("Attach a video") }}</a>
                </div>
              </label>
  
              <video controls v-else-if="videoEntry" class="entry_image">
                <source :src="videoEntry" type="video/mp4" />
              </video>
              <input
                type="file"
                id="videoEntry"
                class="form-control"
                @change="onVideoChange"
                hidden
                accept="video/*"
              />
            </div>
          </div>
        </div>
  
        <hr />
  
        <div class="row">
          <h4>{{ $t("Details of room attachments") }}</h4>
          <div class="col-lg-3 col-6">
            <div class="w-100">
              <div class="form-group">
              <label class="form-label">{{ $t("attachments") }}</label>
                <vue-multi-image-upload
                  @data-image="uploadImages"
                  :max="4"
                  :image-size="4e6"
                  :alert-timeout="3e3"
                  :accept="imageType"
                  :preview="{ h: 100, w: 100 }"
                  :resize="{ h: 500, w: 500, keepRatio: true }"
                  :data-reset="component"
                  v-model="images"
                />
                <button
                  v-if="inputImages?.length > 0"
                  class="btn"
                  type="button"
                  @click="clear()"
                >
                  {{ $t("Clear") }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-9">
            <div class="form-group">
              <label class="form-label">{{ $t("attachments") }}</label>
              <Multiselect
                v-model="attachment_values"
                :options="attachments"
                :placeholder="$t('choose')"
                label="title"
                track-by="id"
                :clear-on-select="true"
                select-label=""
                :hide-selected="true"
                :multiple="true"
              >
              </Multiselect>
            </div>
          </div>
        </div>
  
        <button type="button" class="btn PrimaryButton" @click="addRoom()">
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import { VueMultiImageUpload } from "@zakerxa/vue-multiple-image-upload";
  import axios from "axios";
  import Multiselect from "vue-multiselect";
  import Swal from "sweetalert2";
  
  export default {
    props: ["hotel_room_id"],
    emits: ["fetch_hotel_rooms"],
    components: {
      VueMultiImageUpload,
      Multiselect,
    },
    data() {
      return {
        component: {},
        inputImages: [],
        imageType: ["image/jpeg", "image/png", "image/gif"],
        meals: [],
        meal_value: "",
        views: [],
        view_values: "",
        room_name: "",
        pax_value: null,
        children_count: null,
        max_pax: null,
        rooms: [],
        room_value: "",
        attachment_values: [],
        attachments: [],
        videoEntry: "",
        hotel_id: this.$route.params.id,
        images: [],
      };
    },
    methods: {
      clear() {
        this.component.clear = true;
      },
      onVideoChange(event) {
        this.VideoData = event.target.files[0];
  
        const reader = new FileReader();
        reader.readAsDataURL(this.VideoData);
        reader.onload = () => (this.videoEntry = reader.result);
      },
      fetchRooms() {
        axios.post(`/fetch_all_rooms`, { service_type: 1 }).then(({ data }) => {
          this.rooms = data.data;
        });
      },
      // fetch meals
      fetchMeals() {
        axios.post("/fetch_meal_types").then(({ data }) => {
          this.meals = data.data.data;
        });
      },
      fetchAllViews() {
        axios.post(`/fetch_all_view`).then(({ data }) => {
          this.views = data.data.data;
        });
      },
      fetch_attachments() {
        axios.post(`/fetch_amenities`, { amenity_status: 2 }).then(({ data }) => {
          this.attachments = data.data.data;
        });
      },
      fetch_hotel_room_data(roomId) {
        const formData = new FormData();
        formData.append("hotel_id", this.hotel_id);
        formData.append("room_view_id", roomId);
  
        axios
          .post(`/fetch_hotel_room_view_by_room_view_id`, formData)
          .then(({ data }) => {
            this.room_value = data.data.room;
            this.pax_value = data.data.pax;
            this.children_count = data.data.child_count;
            this.max_pax = data.data.max_pax;
            this.view_values = data.data.view_type;
            this.meal_value = data.data.meals;
            this.attachment_values = data.data.amenities;
            this.videoEntry = data.data.video;
            this.inputImages = data.data.images;
            this.uploadImages = data.data.images;
          });
      },
      addRoom() {
        const formData = new FormData();
        // console.log(this.view_values)
        formData.append("hotel_id", this.hotel_id);
        formData.append("room_id", this.room_value.id);
        formData.append("view_type_id", this.view_values.id);
        formData.append("child_count", this.children_count);
        formData.append("pax", this.pax_value);
        formData.append("max_pax", this.max_pax);
        if (this.VideoData instanceof File) {
          formData.append("video", this.VideoData);
        }
        this.meal_value.forEach((ele, index) => {
          formData.append(`meal_type_ids[${index}]`, ele.meal_type_id);
        });
        this.attachment_values.forEach((ele, index) => {
          formData.append(`amenity_ids[${index}]`, ele.id);
        });
        this.inputImages.forEach((ele, index) => {
          if (ele instanceof File) {
            formData.append(`images[${index}]`, ele);
          }
        });
  
        if (this.hotel_room_id != undefined) {
          axios
            .post(`/update_room_view_for_hotel/${this.hotel_room_id}`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.resetData();
              this.$emit("fetch_hotel_rooms");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          axios
            .post(`/store_room_view_for_hotel`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.resetData();
              this.$emit("fetch_hotel_rooms");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      },
      resetData() {
        this.room_value = "";
        this.view_values = "";
        this.children_count = null;
        this.pax_value = null;
        this.max_pax = null;
        this.meal_value = [];
        this.attachment_values = [];
        this.inputImages = [];
        this.VideoData = "";
      },
      uploadImages(e) {
        this.inputImages = e;
  
        // The way to append images to FormData.
        let formData = new FormData();
        let base64Strings = []; // Array to store base64 strings
  
        // Convert each image to base64 and append to formData
        this.inputImages.forEach((imgFile, index) => {
          const reader = new FileReader();
  
          // Read the file as a data URL
          reader.readAsDataURL(imgFile);
  
          // Once the file is loaded, convert it to base64 and append to formData
          reader.onloadend = () => {
            const base64String = reader.result.split(",")[1];
            formData.append(`images[${index}]`, base64String);
            base64Strings.push(base64String);
          };
        });
      },
      close() {
        this.resetData();
        // Swal.fire({
        //     title: "Are you sure?",
        //     text: "You won't be able to revert this!",
        //     icon: "warning",
        //     showCancelButton: true,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     confirmButtonText: "Yes, delete it!"
        //     }).then((result) => {
        //     if (result.isConfirmed) {
        //         this.resetData();
        //         console.log(document.getElementById("close_btn"))
        //         document.getElementById("close_btn").setAttribute("data-bs-dismiss" , "offcanvas")
  
        //         Swal.fire({
        //         title: "Deleted!",
        //         text: "Your file has been deleted.",
        //         icon: "success"
        //         });
        //     }
        // });
      },
    },
    watch: {
      hotel_room_id(value) {
        if (value != undefined) {
          this.fetch_hotel_room_data(value);
          console.log(value);
        }
      },
    },
    mounted() {
      this.fetchRooms();
      this.fetchMeals();
      this.fetchAllViews();
      this.fetch_attachments();
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  
  h4 {
    color: #0b1427;
    font-weight: 700;
    font-size: 16px;
  }
  
  .control {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .image_upload {
    margin: unset;
    border: unset;
    .upload_img {
      border: 1px dashed #d0d2d7;
      color: #576b74;
      font-size: 12px;
      background-color: #d0d2d736;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      div {
        display: flex;
        flex-direction: column;
        text-align: center;
        i {
          color: #656b78;
          font-size: 18px;
        }
        a {
          color: $PrimaryColor;
          text-decoration: underline;
        }
      }
    }
  }
  hr {
    width: 100%;
    height: 4px;
    background-color: #e7e8eb;
    border-color: #e7e8eb;
  }
  .add_data {
    width: auto;
    color: $PrimaryColor;
  }
  .offcanvas {
    height: 80%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .image_upload {
    margin: unset;
    border: unset;
    .upload_img {
      border: 1px dashed #d0d2d7;
      color: #576b74;
      font-size: 12px;
      background-color: #d0d2d736;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      div {
        display: flex;
        flex-direction: column;
        text-align: center;
        i {
          color: #656b78;
          font-size: 18px;
        }
        a {
          color: $PrimaryColor;
          text-decoration: underline;
        }
      }
    }
  }
  .form-group {
    .form-control {
      padding: 0.7rem !important;
    }
  }
  </style>
  