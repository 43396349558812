<template>
  <!-- <div v-if="loading" class="loading w-100">
    <loading class="w-100" />
  </div> -->
  <!-- skelton -->
  <div class="w-100 my-2" v-if="loading">
    <Skeleton width="10%" class="mb-2"></Skeleton>
    <div class="row">
      <div class="col-lg-9">
        <div class="d-flex align-items-center justify-content-between">
          <Skeleton width="20%" height="2rem" class="mb-2"></Skeleton>
          <Skeleton width="20%" height="2rem" class="mb-2"></Skeleton>
          <Skeleton width="20%" height="2rem" class="mb-2"></Skeleton>
          <Skeleton width="20%" height="2rem" class="mb-2"></Skeleton>
        </div>
        <div class="d-flex align-items-center justify-content-between my-3">
          <Skeleton width="20%" height="2rem" class="mb-2"></Skeleton>
          <Skeleton width="20%" height="2rem" class="mb-2"></Skeleton>
          <Skeleton width="20%" height="2rem" class="mb-2"></Skeleton>
          <Skeleton width="20%" height="2rem" class="mb-2"></Skeleton>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <Skeleton width="20%" height="2rem" class="mb-2"></Skeleton>
          <Skeleton width="20%" height="2rem" class="mb-2"></Skeleton>
          <Skeleton width="20%" height="2rem" class="mb-2"></Skeleton>
          <Skeleton width="20%" height="2rem" class="mb-2"></Skeleton>
        </div>
        <Skeleton width="10%" class="my-2"></Skeleton>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>
                <Skeleton width="30%" class="me-5"></Skeleton>
              </th>
              <th>
                <Skeleton width="30%" class="me-5"></Skeleton>
              </th>
              <th>
                <Skeleton width="30%" class="me-5"></Skeleton>
              </th>
              <th>
                <Skeleton width="30%" class="me-5"></Skeleton>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="d-flex align-items-center flex-column gap-3">
                  <Skeleton width="50%" height="2rem" class="mb-2"></Skeleton>
                  <Skeleton width="50%" height="2rem" class="mb-2"></Skeleton>
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center flex-column gap-3">
                  <Skeleton width="50%" height="2rem" class="mb-2"></Skeleton>
                  <Skeleton width="50%" height="2rem" class="mb-2"></Skeleton>
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center flex-column gap-3">
                  <Skeleton width="50%" height="2rem" class="mb-2"></Skeleton>
                  <Skeleton width="50%" height="2rem" class="mb-2"></Skeleton>
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center flex-column gap-3">
                  <Skeleton width="50%" height="2rem" class="mb-2"></Skeleton>
                  <Skeleton width="50%" height="2rem" class="mb-2"></Skeleton>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex align-items-center justify-content-end w-100">
          <Skeleton width="10%" height="2rem" class="mb-2"></Skeleton>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="d-flex flex-column gap-3">
          <Skeleton width="30%" height="2rem" class="mb-2"></Skeleton>
        </div>
      </div>
    </div>
  </div>
  <!-- skelton -->
  <form
    class="book-umrah"
    @submit.prevent="bookUmrah(this.button_param)"
    v-else
  >
    <div class="row">
      <div class="col-lg-9 col-12">
        <bookSettings
          v-bind="UmrahData"
          :fetched_setting_data="fetched_setting_data"
          @selectedstartDate="selectedstartDate($event)"
          @selectedendDate="selectedendDate($event)"
          @save_setting_data="save_setting_data"
          @inputImagesEmit="inputImagesEmit"
          @selectedCountryID="selectedCountryID($event)"
          @typesValue_id="typesValue_id"
        />

        <!-- <ItinerarySetting @getDestinationsData="getDestinationsData" /> -->

        <!-- v-if="hotels" -->
        <hotelSettings
          :typesValue_id="setting_data"
          :dateSrtart="dateSrtart"
          :dateEnd="dateEnd"
          :lunchObj="lunchObj"
          :destinations="destinations"
          :country_id="country_id"
          :editedHotel="editedHotel"
          :providers="providers"
          class="mt-3 border-bottom"
          @saveHotelData="saveHotelData"
          @saveDestinationsData="saveDestinationsData"
          :types_Value_id="this.$route.params.type == 'tourism' ? 2 : 1"
        />
        <!-- Hotel setting component -->

        <!-- @hotelEmit="hotelEmit($event)" -->
        <TripOptions
          @flightEmit="flightEmit($event)"
          @transportEmit="transportEmit($event)"
          @visaEmit="visaEmit($event)"
          @insuranceEmit="insuranceEmit($event)"
          :trans_setting_Data="trans_setting_Data"
          :editedFlights="editedFlights"
          :visaData="visa_Data"
          :insurancesData="insurance_Data"
        />

        <!-- Hotel setting component -->

        <!-- FlightSettings  component -->
        <FlightSettings
          v-if="flight"
          class="mt-3 border-bottom"
          :editedFlights="editedFlights"
          :dateStart="dateSrtart"
          :dateEnd="dateEnd"
          :flightsData="flightsData"
          :providers="providers"
          @saveFlightsData="saveFlightsData"
          @sumAdultsCost="sumAdultsCost"
          @sumAdultsSelling="sumAdultsSelling"
          @sumChildrenCost="sumChildrenCost"
          @sumChildrenSelling="sumChildrenSelling"
        />
        <!-- FlightSettings  component -->

        <!-- FlightSettings  component -->
        <Transformationseeting
          v-if="transformation"
          class="mt-3 border-bottom"
          :dateStart="dateSrtart"
          :fetched_data="transDataFetched"
          :dateEnd="dateEnd"
          :providers="providers"
          @saveTransData="saveTransData"
          @costTrans="costTrans"
          @sellingTrans="sellingTrans"
        />
        <!-- FlightSettings  component -->

        <!-- FlightSettings  component -->
        <VisaSettings
          v-if="visa"
          class="mt-3 border-bottom"
          @saveVisaData="saveVisaData"
          :dateStart="dateSrtart"
          :dateEnd="dateEnd"
          :visaData="visa_Data"
          :providers="providers"
          @totalSelling="totalSelling"
          @totalCost="totalCost"
        />
        <!-- FlightSettings  component -->

        <!-- InsuranceSettings  component -->
        <InsuranceSettings
          v-if="Insurances"
          class="mt-3 border-bottom"
          @saveInsuranceData="saveInsuranceData"
          :insurancesData="insurance_Data"
          :providers="providers"
        />
        <!--InsuranceSettings  component-->

        <!-- AddedServices  component -->
        <AddedServices
          class="mt-3 border-bottom"
          @saveServicesData="saveServicesData"
          :additional_services="add_services"
          :providers="providers"
        />
        <!--AddedServices  component-->

        <!-- included -->
        <!-- <includedSection
          class="mt-3 border-bottom"
          @save_package_included="save_package_included"
        /> -->

        <div class="container mt-4">
          <div class="row">
            <div class="col-lg-6">
              <h5>{{ $t("included") }}</h5>
              <div class="form-group p-2">
                <!-- <label for="included">{{ $t("included") }}</label> -->

                <TabView>
                  <TabPanel
                    v-for="(desc, index) in included"
                    :key="index"
                    :header="$t('included') + ' (' + desc.lang + ')'"
                  >
                    <div class="form-group">
                      <Editor
                        :id="`desc${index}`"
                        :placeholder="$t('included')"
                        v-model="desc.title"
                      />
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>
            <div class="col-lg-6">
              <h5>{{ $t("not_included") }}</h5>
              <div class="form-group p-2">
                <TabView>
                  <TabPanel
                    v-for="(desc, index) in not_included"
                    :key="index"
                    :header="$t('not_included') + ' (' + desc.lang + ')'"
                  >
                    <div class="form-group">
                      <Editor
                        :id="`desc${index}`"
                        :placeholder="$t('not_included')"
                        v-model="desc.title"
                      />
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>
          </div>
        </div>
        <!-- included -->

        <!-- AddedServices  component -->
        <!-- <saveandPublich class="mt-3 border-bottom" /> -->
        <!--AddedServices  component-->
      </div>
      <div class="col-lg-3 col-12 bg-white">
        <div>
          <div class="row">
            <!-- :addToBasePrice="addToBasePrice" -->
            <DoubleRoom
              :selectedRooms="calculated_rooms"
              :basePrice="basePrice"
              v-if="calculated_rooms.length > 0"
              @saveBtn="saveBtn"
              @total="total"
            />
            <div class="d-flex flex-column">
              <div
                class="side-fixed-img mt-5"
                v-if="calculated_rooms.length == 0"
              >
                <div
                  class="d-flex align-items-center justify-content-center flex-column"
                >
                  <img src="@/assets/media/image/rooms.png" />
                  <p>
                    {{
                      $t("Enter the required data to get the available rooms")
                    }}
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <span
                  class="SecondButton btn mb-2 col-12 col-md-6 py-2"
                  @click="
                    addToBasePrice(
                      sumChildrenCostValue,
                      sumChildrenSellingValue,
                      sumAdultsSellingValue,
                      sumAdultsCostValue,
                      insuranceData,
                      totalSellingValue,
                      totalCostValue,
                      totalCostTrans,
                      totalSellingTrans
                    )
                  "
                >
                  {{ $t("calc_base_price") }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// import { Multiselect } from "vue-multiselect";
// import "vue-datepicker-ui/lib/vuedatepickerui.css";
import bookSettings from "@/components/UmrahPackage/bookSettings.vue";
import DoubleRoom from "@/components/UmrahPackage/DoubleRoom.vue";
// import ItinerarySetting from "@/components/UmrahPackage/ItinerarySetting.vue";
import TripOptions from "@/components/UmrahPackage/TripOptions.vue";
import hotelSettings from "@/components/UmrahPackage/hotelSettings.vue";
import FlightSettings from "@/components/UmrahPackage/Flight_settings.vue";
import Transformationseeting from "@/components/UmrahPackage/Transformation_seeting.vue";
import VisaSettings from "@/components/UmrahPackage/VisaSettings.vue";
import InsuranceSettings from "@/components/UmrahPackage/InsuranceSettings.vue";
import AddedServices from "@/components/UmrahPackage/AddedServices.vue";
import Skeleton from "primevue/skeleton";
// import loading from "@/components/LottieFiles/loading.vue";
// import saveandPublich from "@/components/UmrahPackage/saveandPublich.vue";
// import includedSection from "@/components/UmrahPackage/includedSection.vue";
import Editor from "primevue/editor";
import axios from "axios";
import Swal from "sweetalert2";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import useVuelidate from "@vuelidate/core";
// import { required } from "@vuelidate/validators";

export default {
  data() {
    return {
      // package_tourism_type_data: "",
      // selectedStarsIDdata: "",
      // selectedPackageTypeIDdata: " ",
      types_Value_id: "",
      v$: useVuelidate(),
      loading: true,
      adults: { cost: 0, selling: 0 },
      childrens: { cost: 0, selling: 0 },
      babies: { cost: 0, selling: 0 },
      cars: { cost: 0, selling: 0 },
      // value: "",
      // options: ["Select option", "options", "selected", "multiple", "label"],
      flight: false,
      hotels: false,
      transformation: false,
      visa: false,
      Insurances: false,
      dateSrtart: "",
      dateEnd: "",
      country_id: "",
      lunchObj: {},
      destinations: [],
      setting_data: {},
      citiesData: {},
      hotelData: [],
      destinationsData: [],
      flightsData: [],
      visaData: {},
      visa_Data: {},
      servicesData: [],
      insuranceData: {},
      package_included_services: [],
      included: [],
      included_data: {},
      not_included: [],
      not_included_data: {},
      sumAdultsSellingValue: 0,
      sumAdultsCostValue: 0,
      sumChildrenCostValue: 0,
      sumChildrenSellingValue: 0,
      totalSellingValue: 0,
      totalCostValue: 0,
      totalCostTrans: 0,
      totalSellingTrans: 0,
      languages: ["ar", "en", "id"],
      selectedRooms: [],
      flightsCost: [],
      insurance_Data: {},
      add_services: [],
      editedHotel: [],
      basePrice: {},
      calculated_rooms: [],
      button_param: 1,
      totalArray: null,
      fetched_setting_data: {},
      transData: [],
      trans_setting_Data: {},
      editedFlights: [],
      providers: [],
      editedImages: [],
      transDataFetched: [],
    };
  },
  components: {
    // Multiselect,
    hotelSettings,
    bookSettings,
    // ItinerarySetting,
    TripOptions,
    FlightSettings,
    Transformationseeting,
    VisaSettings,
    InsuranceSettings,
    AddedServices,
    // saveandPublich,
    Editor,
    DoubleRoom,
    Skeleton,
    TabView,
    TabPanel,
    // loading,
    // includedSection,
  },
  computed: {
    // this.test_data = {id: "541" , title: "12121"}
    test_data() {
      return {
        id: "541",
        title: "12121",
      };
    },
    UmrahData() {
      // console.log(this.fetched_setting_data, "dfff");
      return this.fetched_setting_data;
    },
  },
  // validations() {
  //   return {
  //     // setting_data:{
  //     //   package_type_id: {required}
  //     // }

  //   };
  // },
  methods: {
    // package_tourism_type(data) {
    //   this.package_tourism_type_data = data;
    // },
    // selectedPackageTypeID(data) {
    //   this.selectedPackageTypeIDdata = data;
    // },
    // selectedStarsID(data) {
    //   this.selectedStarsIDdata = data;
    // },
    async fetchUmrahData() {
      // console.log("data")
      await axios
        .post("/fetch_specific_package", { package_id: this.$route.params.id })
        .then(({ data }) => {
          this.trans_setting_Data = data.data.transportation;
          console.log(this.trans_setting_Data, "Elsayed");
          this.transDataFetched = data.data.transportation;
          console.log(this.transDataFetched, "ElsayedHassan");
          this.fetched_setting_data = {
            setting_day_count: data.data.day_count,
            setting_title: data.data.title,
            setting_description: data.data.description,
            setting_images: data.data.images,
            main_image: data.data.main_image,
            setting_launch_city: data.data.launch_city,
            setting_from_date: data.data.from_date,
            setting_to_date: data.data.to_date,
            setting_days: data.data.days,
            setting_trip_date: data.data.trip_date,
            setting_luanch_date: data.data.luanch_date,
            setting_trip_path: data.data.trip_path,
            setting_cities_count: data.data.cities_count,
            setting_star_category: data.data.star_category,
            setting_max_adt_book: data.data.max_adt_book,
            setting_max_chd_book: data.data.max_chd_book,
            setting_min_adt_book: data.data.min_adt_book,
            setting_min_chd_book: data.data.min_chd_book,
            setting_package_type: data.data.package_type,
            setting_countries: data.data.countries,
            setting_all_nationalities: data.data.all_nationalities,
            setting_employees: data.data.employees,
          };
          this.editedHotel = data.data.hotels;
          this.country_id = data.data.countries[0].country_id;
          this.editedFlights = data.data.flight;
          this.calculated_rooms = data.data.package_rooms;
          this.calculated_rooms.forEach((room) => {
            // Calculate penifit for adult
            room.adult.penifit =
              room.adult.total_adult_selling - room.adult.total_adult_cost;
            // Calculate penifit for child
            room.child.penifit =
              room.child.total_child_selling - room.child.total_child_cost;
          });
          let allRooms = [];
          data.data.hotels.forEach((obj) => {
            allRooms = allRooms.concat(obj.rooms);
          });

          // Assign allRooms to this.selectedRooms
          this.selectedRooms = allRooms;
          // console.log(this.selectedRooms);
          // console.log(this.calculated_rooms);

          this.included = data.data.included.map((ele) => {
            return {
              lang: ele.locale,
              title: ele.included,
            };
          });

          this.not_included = data.data.not_included.map((ele) => {
            return {
              lang: ele.locale,
              title: ele.not_included,
            };
          });

          // this.flightsData = data.data.flight;
          // console.log(this.fetched_setting_data);
          this.visa_Data = data.data.visa;
          this.insurance_Data = data.data.insurances;
          this.add_services = data.data.additional_services;

          // console.log(data.data, "data");
        });
    },
    recalculateBasePrice() {
      // This method is empty as the computation is handled by the computed property
      // The computed property basePrice will automatically recompute when the watched properties change
    },
    setIncludedTitle() {
      this.languages.forEach((ele) => {
        this.included.push({
          lang: ele,
          title: "",
        });

        this.not_included.push({
          lang: ele,
          title: "",
        });
      });
    },

    // hotelEmit(e) {
    //   this.hotels = e;
    // },
    flightEmit(e) {
      this.flight = e;
      if (e == false) {
        this.sumChildrenCostValue = 0;
        this.sumChildrenSellingValue = 0;
        this.sumAdultsSellingValue = 0;
        this.sumAdultsCostValue = 0;
        this.flightsData = [];
      }
    },
    transportEmit(e) {
      this.transformation = e;
      if (e == false) {
        this.totalCostTrans = 0;
        this.totalSellingTrans = 0;
        this.transData = [];
      }
    },
    visaEmit(e) {
      this.visa = e;
      if (e == false) {
        this.totalCostValue = 0;
        this.totalSellingValue = 0;
        this.visaData = [];
      }
    },
    insuranceEmit(e) {
      this.Insurances = e;
      if (e == false) {
        this.insuranceData = {};
      }
    },

    selectedstartDate(e) {
      // console.log(e);
      this.dateSrtart = e;
      // console.log("selectedstartDate");
    },

    selectedCountryID(data) {
      this.country_id = data;
      // console.log(data)
      // console.log(this.country_id)
    },

    selectedendDate(e) {
      // console.log(e);
      this.dateEnd = e;
      // console.log("selectedendDate");
    },
    getDestinationsData(lunch, destinations, citiesData) {
      this.lunchObj = lunch;
      (this.destinations = destinations), (this.citiesData = citiesData);
    },
    save_setting_data(data) {
      this.setting_data = data;
    },
    saveHotelData(data, rooms) {
      this.hotelData = data;
      this.selectedRooms = rooms;
      // console.log(this.selectedRooms);
    },
    // typesValue_id(data) {
    //   this.types_Value_id = data;
    // },
    saveDestinationsData(data) {
      this.destinationsData = data;
      // console.log(this.destinationsData);
    },
    sumAdultsCost(data) {
      this.sumAdultsCostValue = data;
      // console.log(this.sumAdultsCostValue)
    },
    sumAdultsSelling(data) {
      this.sumAdultsSellingValue = data;
      // console.log(this.sumAdultsSellingValue)
    },
    sumChildrenCost(data) {
      this.sumChildrenCostValue = data;
      // console.log(this.sumChildrenCostValue)
    },
    sumChildrenSelling(data) {
      this.sumChildrenSellingValue = data;
      // console.log(this.sumChildrenSellingValue)
    },
    saveFlightsData(data) {
      this.flightsData = data;
    },

    receiveAdultsData(data) {
      this.adults.cost = data.cost;
      this.adults.selling = data.selling;
    },

    receiveChildrenData(data) {
      this.childrens.cost = data.cost;
      this.childrens.selling = data.selling;
    },

    receiveBabiesData(data) {
      this.babies.cost = data.cost;
      this.babies.selling = data.selling;
    },

    receiveCarsData(data) {
      this.cars.cost = data.cost;
      this.cars.selling = data.selling;
    },

    saveTransData(data) {
      this.transData = data;
    },
    costTrans(data) {
      this.totalCostTrans = data;
    },
    sellingTrans(data) {
      this.totalSellingTrans = data;
    },
    saveVisaData(data) {
      this.visaData = data;
    },
    totalSelling(data) {
      this.totalSellingValue = data;
    },
    totalCost(data) {
      this.totalCostValue = data;
    },
    saveServicesData(data) {
      this.servicesData = data;
    },
    ValueTest() {
      // console.log(this.included_data);
    },

    saveInsuranceData(data) {
      this.insuranceData = data;
    },
    inputImagesEmit(data, editedImages) {
      this.inputImages = data;
      this.editedImages = editedImages;
      // console.log(this.inputImages)
    },
    addToBasePrice(
      sumChildrenCost,
      sumChildrenSelling,
      sumAdultsSelling,
      sumAdultsCost,
      insuranceData,
      totalSelling,
      totalCost,
      totalCostTrans,
      totalSellingTrans
    ) {
      this.basePrice = {
        adult: {
          cost: 0,
          selling: 0,
        },
        child: {
          cost: 0,
          selling: 0,
        },
      };
      this.basePrice.adult.cost +=
        (sumAdultsCost ? sumAdultsCost : 0) +
        (insuranceData.adult
          ? insuranceData.adult.cost == ""
            ? 0
            : insuranceData.adult.cost
          : 0) +
        (totalCost ? totalCost : 0) +
        (totalCostTrans ? totalCostTrans : 0);

      this.basePrice.adult.selling +=
        (sumAdultsSelling ? sumAdultsSelling : 0) +
        (insuranceData.adult
          ? insuranceData.adult.selling == ""
            ? 0
            : insuranceData.adult.selling
          : 0) +
        (totalSelling ? totalSelling : 0) +
        (totalSellingTrans ? totalSellingTrans : 0);

      this.basePrice.child.cost +=
        (sumChildrenCost ? sumChildrenCost : 0) +
        (insuranceData.child
          ? insuranceData.child.cost == ""
            ? 0
            : insuranceData.child.cost
          : 0) +
        (totalCost ? totalCost : 0) +
        (totalCostTrans ? totalCostTrans : 0);

      this.basePrice.child.selling +=
        (sumChildrenSelling ? sumChildrenSelling : 0) +
        (insuranceData.child
          ? insuranceData.child.selling == ""
            ? 0
            : insuranceData.child.selling
          : 0) +
        (totalSelling ? totalSelling : 0) +
        (totalSellingTrans ? totalSellingTrans : 0);
      console.log(this.basePrice);
      this.add_base_price_to_rooms();
    },
    add_base_price_to_rooms() {
      this.calculated_rooms = this.selectedRooms.map((room) => {
        return {
          title: room.title,
          room_id: room.room_id,
          adult: {
            total_adult_cost: room.adult.sendCost + this.basePrice.adult.cost,
            total_adult_selling:
              room.adult.sendSelling + this.basePrice.adult.selling,
            penifit:
              room.adult.sendSelling +
              this.basePrice.adult.selling -
              (room.adult.sendCost + this.basePrice.adult.cost),
          },
          child: {
            total_child_cost: room.child.sendCost + this.basePrice.child.cost,
            total_child_selling:
              room.child.sendSelling + this.basePrice.child.selling,
            penifit:
              room.child.sendSelling +
              this.basePrice.child.selling -
              (room.child.sendCost + this.basePrice.child.cost),
          },
        };
      });
      // console.log(this.calculated_rooms);
    },
    saveBtn(data) {
      this.button_param = data;
    },
    total(data) {
      this.totalArray = data;
      // console.log(this.totalArray);
    },
    bookUmrah(button_param) {
      this.v$.$validate();
      console.log(this.setting_data, "a4aaaaaaaaaaa");

      console.log(this.v$);
      if (!this.v$.$error) {
        this.loading = true;
        let UmrahData = {
          ...this.setting_data,
          // ...this.package_included_services,
          ...this.citiesData,
          status: button_param == 1 ? 0 : 1,
        };
        if (this.inputImages != undefined) {
          if (this.inputImages.length) {
            UmrahData.images = this.inputImages;
          } else {
            UmrahData.images = this.inputImages;
          }
        }
        if (this.editedImages != undefined) {
          UmrahData.old_images = this.editedImages;
        }

        // if (this.hotels) {
        UmrahData.hotels = this.hotelData;
        UmrahData.price_type = this.totalArray.price_type;
        UmrahData.total_price = this.totalArray.total_price;
        UmrahData.destinations = this.destinationsData;

        // UmrahData.package_type_id = this.selectedPackageTypeIDdata;

        // UmrahData.package_tourism_type = this.package_tourism_type_data;
        // }
        if (this.flight) {
          if (this.flightsData.length > 0) {
            UmrahData.flights = this.flightsData;
          }
        }
        if (this.transformation) {
          UmrahData.transportation = this.transData;
        }
        if (this.visa) {
          UmrahData.visa = this.visaData;
        }
        if (this.Insurances) {
          UmrahData.package_insurance = this.insuranceData;
        }
        if (this.servicesData.length > 0) {
          // console.log(this.servicesData);
          UmrahData.package_additional_services = this.servicesData;
        }

        this.included.forEach((ele) => {
          UmrahData[`included_${ele.lang}`] = ele.title;
        });

        this.not_included.forEach((ele) => {
          UmrahData[`not_included_${ele.lang}`] = ele.title;
        });

        if (this.$route.params.id != undefined) {
          UmrahData.package_id = parseInt(this.$route.params.id);

          axios
            .post("/update_basic_package", UmrahData)
            .then(({ data }) => {
              // console.log(data);
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.loading = false;
              if (this.$route.params.type == "umrah") {
                this.$router.push("/package/umrah");
              } else {
                this.$router.push("/package/tourism");
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
              this.loading = false;
            });
        } else {
          axios
            .post("/store_basic_package", UmrahData)
            .then(({ data }) => {
              // console.log(data);
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              if (this.$route.params.type == "umrah") {
                this.$router.push("/package/umrah");
              } else {
                this.$router.push("/package/tourism");
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
              this.loading = false;
            });
        }
      }
    },
    FetchSuppliers() {
      axios.post("/fetch_consumer_by_role", { role: 2 }).then(({ data }) => {
        this.providers = data?.data?.data;
        // console.log(this.providers.slice(0, 5));
      });
    },
  },
  mounted() {
    this.setIncludedTitle();
    this.FetchSuppliers();
    if (this.$route.params.id) {
      this.fetchUmrahData();
    }
    console.log(this.$route.params, "asdadasdasd");

    setTimeout(() => {
      this.loading = false;
    }, 1600);
  },
};
</script>
<style lang="scss" scoped>
.side-fixed-img {
  img {
    max-width: 100%;
  }
}
</style>
