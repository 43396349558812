<template>
  <section class="rate_code card_style">
    <div class="w-100" v-if="loading">
      <Skeleton width="5rem" class="mb-2"></Skeleton>
      <div class="d-flex align-items-center gap-3 w-100">
        <div class="d-flex flex-column gap-2 w-50 my-2">
          <Skeleton width="4%"></Skeleton>
          <Skeleton height="2rem" class="mb-2"></Skeleton>
        </div>
        <div class="d-flex flex-column gap-2 w-50 my-2">
          <Skeleton width="4%"></Skeleton>
          <Skeleton height="2rem" class="mb-2"></Skeleton>
        </div>
      </div>
      <div class="d-flex flex-column gap-2 w-50 my-2">
        <Skeleton width="4%"></Skeleton>
        <Skeleton height="2rem" class="mb-2"></Skeleton>
      </div>
    </div>

    <form @submit.prevent="addRateCode()" v-else>
      <div class="title">
        <h4>Rate Code</h4>
        <!-- <div class="contract_details">
                <span>{{$t("contract name")}} : </span>
                <span>{{$t("Duration of contract")}} : </span>
            </div> -->
      </div>
      <div class="row mb-3">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label>{{ $t("Rate name") }}</label>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("Rate name") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(rate, index) in rate_name"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("Rate name") }} ({{ rate.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="rate.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.rate_name.$error">{{
              v$.rate_name.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="from">{{ $t("Period from") }}</label>
            <Datepicker v-model="fromDate" lang="ar" disabled />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="to">{{ $t("Period to") }}</label>
            <Datepicker v-model="toDate" lang="ar" disabled />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <ul class="all_routes">
          <li v-for="(route, routeIndex) in allRoute" :key="routeIndex">
            <div class="form-group">
              <input
                class="form-check-input"
                type="checkbox"
                :id="`route${routeIndex}`"
                v-model="route.checked"
                @change="routeData(routeIndex)"
              />
              <label :for="`route${routeIndex}`">Route#{{ route.text }}</label>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="row mb-3"
        v-for="(route, routeIndex) in allRoute"
        :key="routeIndex"
      >
        <div v-if="route.routeDataShow">
          <p>{{ route.text }}</p>
          <div
            class="col-lg-6 col-md-6 col-12 visaData"
            v-for="(car, index) in route.cars"
            :key="index"
          >
            <div class="visa_name">
              <input
                class="form-check-input"
                type="checkbox"
                :id="`visaCheck${index}${routeIndex}`"
                v-model="car.checked"
                @change="getCheckedCar()"
              />
              <label
                class="form-check-label"
                :for="`visaCheck${index}${routeIndex}`"
              >
                {{ car.title }}
              </label>
            </div>
            <div class="price" v-if="car.checked">
              <div class="form-group">
                <label>{{ $t("cost") }}</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="0"
                  v-model="car.cost"
                />
              </div>
              <div class="form-group">
                <label>{{ $t("selling") }}</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="0"
                  v-model="car.price"
                />
              </div>
              <div class="form-group">
                <label>{{ $t("count") }}</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="0"
                  v-model="car.count"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table
            class="table"
            v-if="route.routeDataShow && route.selectedCars.length"
          >
            <thead>
              <tr>
                <th></th>
                <th v-for="day in selectedDays" :key="day.date">
                  <div class="w-100 d-flex justify-content-center">
                    <div class="date">{{ day.date }}</div>
                  </div>
                </th>
              </tr>
              <tr>
                <th>{{ $t("type") }}</th>
                <th
                  v-for="(day, index) in selectedDays"
                  :key="day.date"
                  :class="{ blue: index % 2 == 0, yellow: index % 2 == 1 }"
                >
                  <div>{{ $t("cost") }}</div>
                  <div>{{ $t("selling") }}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(car, carIndex) in route.selectedCars" :key="carIndex">
                <td class="column_title">
                  <div>{{ car.title }}</div>
                  <div class="text-danger">{{ $t("Deactivate day") }}</div>
                </td>
                <td
                  v-for="(day, index) in car.days"
                  :key="index"
                  :class="{ blue: index % 2 == 0, yellow: index % 2 == 1 }"
                >
                  <div>
                    <input
                      type="number"
                      class="form-control"
                      v-model="day.cost"
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      class="form-control"
                      v-model="day.selling_price"
                    />
                  </div>

                  <div class="w-100 d-flex justify-content-end ps-4">
                    <label class="switch">
                      <input
                        type="checkbox"
                        :id="`diactive${day_index}`"
                        v-model="day.date_activation"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button type="submit" class="btn PrimaryButton">
          {{ $t("save") }}
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Skeleton from "primevue/skeleton";
export default {
  name: "AddrateCodeTransport",
  components: { Datepicker, Skeleton },
  data() {
    return {
      v$: useVuelidate(),
      rate_id: this.$route.params.rateId,
      contract_id: this.$route.params.id,
      season_id: this.$route.params.seasonId,
      languages: ["ar", "en", "id"],
      rate_name: [],
      name: "",
      fromDate: new Date(),
      toDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      number_of_nights: 1,
      allRoute: [],
      allCarType: [],
      loading: true,
    };
  },
  computed: {
    selectedDays() {
      const selectedDays = [];
      if (this.fromDate && this.toDate && this.number_of_nights > 0) {
        const fromDate = new Date(this.fromDate);
        for (let i = 0; i < this.number_of_nights; i++) {
          const currentDate = new Date(fromDate);
          currentDate.setDate(fromDate.getDate() + i);
          selectedDays.push({
            date: currentDate.toISOString().split("T")[0],
            dayOfWeek: this.getDayOfWeek(currentDate.getDay()),
          });
        }
      }
      return selectedDays;
    },
  },
  validations() {
    return {
      rate_name: this.rate_name.map(() => {
        return {
          name: { required },
        };
      }),
    };
  },
  methods: {
    routeData(index) {
      // if (this.allRoute[index].checked == true) {
      if (this.allRoute[index].routeDataShow == false) {
        this.allRoute[index].routeDataShow = true;
      } else if (this.allRoute[index].routeDataShow == true) {
        this.allRoute[index].routeDataShow = false;
      }
      // }
    },
    getDayOfWeek(dayIndex) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[dayIndex];
    },
    async fetchCarRoute() {
      const contract_id = { contract_id: this.contract_id };

      await axios
        .post(`/fetch_all_car_type_by_contract_id`, contract_id)
        .then(({ data }) => {
          this.allCarType = data.data;
        });
      await axios
        .post(`/fetch_all_car_route_by_contract_id`, contract_id)
        .then(({ data }) => {
          this.allRoute = data.data.map((route) => {
            return {
              ...route,
              routeDataShow: false,
              checked: false,
              selectedCars: [],
              cars: this.allCarType.map((car) => {
                return {
                  ...car,
                  cost: 0,
                  price: 0,
                  count: 1,
                  checked: false,
                };
              }),
            };
          });
          this.loading = false;
        });
      this.fetchRateData();
    },
    getCheckedCar() {
      this.allRoute.forEach((route) => {
        route.selectedCars = route.cars.filter((car) => {
          return car.checked == true;
        });
        route.selectedCars.forEach((car) => {
          car.days = this.selectedDays.map((day) => {
            return {
              ...day,
              cost: 0,
              selling_price: 0,
              count: 1,
              date_activation: false,
            };
          });
        });
      });
    },
    async fetchRateData() {
      await axios.get(`/rate_codes/${this.rate_id}`).then(({ data }) => {
        this.rate_name = data.data.title.map((title) => {
          return {
            lang: title.locale,
            name: title.title,
          };
        });
        this.fromDate = data.data.from_date;
        this.toDate = data.data.to_date;

        data.data.cars.forEach((car) => {
          this.allRoute.forEach((route) => {
            if (car.car_route.id == route.id) {
              route.routeDataShow = true;
              route.checked = true;
            }

            route.cars.forEach((route_car) => {
              if (route_car.id == car.car_type.id) {
                route_car.checked = true;
                route_car.cost = car.cost;
                route_car.price = car.price;
                route_car.count = car.count;
                route_car.days = car.days.map((day) => {
                  return {
                    date: day.date,
                    count: day.count,
                    cost: day.cost,
                    selling_price: day.selling_price,
                    date_activation: day.date_activation == 1 ? true : false,
                  };
                });
              }
            });
            route.selectedCars = route.cars.filter((car) => {
              return car.checked == true;
            });
          });
        });
      });
    },
    addRateCode() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let rate_data = {
          transportation_season_id: this.season_id,
          from_date: moment(this.fromDate).format("YYYY-MM-DD"),
          to_date: moment(this.toDate).format("YYYY-MM-DD"),
        };
        this.rate_name.forEach((rate) => {
          rate_data["title_" + rate.lang] = rate.name;
        });
        rate_data.rate_code_routes = this.allRoute
          .filter((route) => route.checked == true)
          .map((route) => {
            return {
              car_route_id: route.id,
              cars: route.selectedCars.map((car) => {
                return {
                  car_type_id: car.id,
                  count: car.count,
                  cost: car.cost,
                  price: car.price,
                  days: car.days.map((day) => {
                    return {
                      count: day.count,
                      date: day.date,
                      cost: day.cost,
                      selling_price: day.selling_price,
                      date_activation: day.date_activation == true ? 1 : 0,
                    };
                  }),
                };
              }),
            };
          });

        axios
          .patch(`/rate_codes/${this.rate_id}`, rate_data)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push(
              `/contract-transport/${this.contract_id}/season-transport/${this.season_id}/rate-code`
            );
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
    from_to_date_update() {
      const toDate = new Date(this.toDate);
      const fromDate = new Date(this.fromDate);
      var timeDiff = Math.abs(toDate.getTime() - fromDate.getTime());
      var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
      this.number_of_nights = numberOfNights;

      this.allRoute.forEach((route) => {
        route.selectedCars.forEach((car) => {
          let old_days = [...car.days];
          car.days = this.selectedDays.map((day) => {
            return {
              ...day,
              cost: 0,
              selling_price: 0,
              count: 0,
              date_activation: false,
            };
          });
          car.days.forEach((new_day) => {
            old_days.forEach((old_day) => {
              if (new_day.date == old_day.date) {
                new_day.cost = old_day.cost;
                new_day.selling_price = old_day.selling_price;
                new_day.count = old_day.count;
                new_day.date_activation = old_day.date_activation;
              }
            });
          });
        });
      });
    },
  },
  mounted() {
    this.fetchCarRoute();
    // this.fetchRateData();
  },
  watch: {
    fromDate() {
      this.from_to_date_update();
    },
    toDate() {
      this.from_to_date_update();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $PrimaryColor;
}

input:focus + .slider {
  box-shadow: 0 0 1px $PrimaryColor;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .contract_details {
    margin-right: 30px;
    margin-left: 30px;
    color: #0b1427;
    font-size: 18px;
    span {
      color: #656b78;
    }
  }
}
.form-group {
  margin-bottom: 20px;
  label {
    font-weight: 700;
    font-family: "bold";
    font-size: 16px;
    color: #0b1427;
    margin-bottom: 10px;
  }
}
h5 {
  font-family: "bold";
  font-size: 20px;
  color: #0b1427;
}
.visaData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .form-group {
    display: flex;
    align-items: center;
    margin-inline-start: 10px;
    label {
      font-size: 12px;
      margin: 0px 10px 0px 10px;
    }
    .form-control {
      width: 80px;
    }
  }
  .form-check-input:checked {
    background-color: $PrimaryColor;
    border-color: $PrimaryColor;
  }
  .form-check-label {
    font-family: "bold";
    margin: 0px 10px 0px 10px;
  }
}
.price {
  display: flex;
  align-items: center;
}
table {
  border-bottom: 1px solid #d0d2d7;
  width: auto;
  th,
  td {
    border-left: 1px solid #d0d2d7;
    min-width: 300px;
    width: 300px;
    &.yellow {
      background-color: #faf4ec !important;
    }
    &.blue {
      background-color: #ebf6f7 !important;
    }
  }
  th > div,
  td > div {
    display: inline-flex;
    width: 50%;
    text-align: center;
    justify-content: center;
    .form-control {
      width: 100px;
      background-color: transparent;
    }
  }
  .date {
    border: 1px solid $PrimaryColor;
    color: $PrimaryColor;
    padding: 7px 20px;
    text-align: center;
    width: fit-content;
    border-radius: 5px;
  }
  thead tr:first-child {
    th {
      border: 0;
      background-color: #fff;
    }
  }
  .column_title {
    display: flex;
    flex-direction: column;
    height: 100%;
    & > div {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
}
.accordion-item:first-of-type .accordion-button {
  font-size: 14px;
  color: #adadad;
  padding: 10px;
  justify-content: space-between;
  &::after {
    width: 30px;
    margin-left: 0;
    color: #adadad;
  }
}
.v-calendar {
  width: 100%;
}
.visaData .price {
  .form-group {
    margin-bottom: 0;
    .form-control {
      text-align: center;
      padding: 8px 3px !important;
    }
  }
}
.all_routes {
  display: flex;
  align-items: center;
  li {
    background-color: #f7f7f7;
    border-radius: 7px;
    font-size: 20px;
    color: #0b1427;
    text-align: center;
    cursor: pointer;
    margin-inline-end: 5px;
    width: 306px;
    height: 55px;
    line-height: 55px;
    .form-group {
      margin-bottom: 0;
      position: relative;
      .form-check-input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
      }
      label {
        width: 100%;
        margin-bottom: 0;
        border-radius: 7px;
      }
      .form-check-input:checked ~ label {
        background-color: $PrimaryColor;
        color: #ffffff;
      }
    }
  }
}
</style>
