<template>
  <!-- <button class="btn SecondButton my-2"><i class="fa-solid fa-plus"></i> {{$t("add_room")}}</button> -->
  <button
    class="btn add_data"
    type="button"
    data-bs-toggle="offcanvas"
    data-bs-target="#addRoomoffcanvasBottom"
    aria-controls="addRoomoffcanvasBottom"
  >
    <i class="fa-solid fa-plus"></i> {{ $t("add_room") }}
  </button>

  <div class="row">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("source") }}</th>
            <th>{{ $t("room_name") }}</th>
            <th>{{ $t("Pax") }}</th>
            <th>{{ $t("children") }}</th>
            <th>{{ $t("Max Pax") }}</th>
            <th>{{ $t("view") }}</th>
            <th>{{ $t("meals") }}</th>
            <th></th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="room in 10" :key="room">
            <td v-for="room in 7" :key="room">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(room, index) in hotel_rooms" :key="index">
            <td>
              {{ room.source == 0 ? "API" : room.source == 1 ? "source" : "" }}
            </td>
            <td>{{ room.room_title }}</td>
            <td>{{ room.pax }}</td>
            <td>{{ room.child_count }}</td>
            <td>{{ room.max_pax }}</td>
            <td>{{ room.view_title }}</td>
            <td>
              <div
                v-for="(meal, mealIndex) in room.meal_types"
                :key="mealIndex"
              >
                {{ meal.title }}
              </div>
            </td>
            <td>
              <div class="control">
                <!-- <button type="button" class="btn edit" @click="edit_room(room.id)">{{$t("edit")}}</button> -->
                <button
                  type="button"
                  class="btn edit"
                  @click="edit_room(room.id)"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasBottom"
                  aria-controls="offcanvasBottom"
                >
                  {{ $t("edit") }}
                </button>
                <button
                  type="button"
                  class="btn delete"
                  @click="delete_room(room.id, index)"
                >
                  {{ $t("delete") }}
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <addRoom
    @fetch_hotel_rooms="fetch_hotel_rooms"
  />
  <editRoom
    @fetch_hotel_rooms="fetch_hotel_rooms"
    :hotel_room_id="hotel_room_id"
  />
</template>

<script>
import axios from "axios";
import addRoom from "./addRoom.vue";
import editRoom from "./editRoom.vue";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  components: {
    addRoom,
    editRoom,
    Skeleton,
  },
  data() {
    return {
      hotel_rooms: [],
      hotel_room_id: undefined,
      loading: true,
    };
  },
  methods: {
    fetch_hotel_rooms() {
      axios
        .post(`/fetch_hotel_room_view_by_hotel_id`, {
          hotel_id: this.$route.params.id,
        })
        .then(({ data }) => {
          // console.log(data.data);
          this.hotel_rooms = data.data;
          this.loading = false;
        });
    },
    delete_room(roomId, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`/delete_hotel_room_view/${roomId}`)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.hotel_rooms.splice(index, 1);
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      });
    },
    edit_room(roomId) {
      this.hotel_room_id = roomId;
    },
  },
  mounted() {
    this.fetch_hotel_rooms();
  },
};
</script>

<style lang="scss" scoped>
table thead tr th {
  border-left: 1px solid #d0d2d7 !important;
  &:last-child {
    border-left: 0 !important;
  }
  &:nth-last-child(2) {
    border-left: 0 !important;
  }
}
table tbody tr td {
  border-bottom: 1px solid #d0d2d7 !important;
  border-left: 1px solid #d0d2d7 !important;
  padding: 0.5rem 0.5rem !important ;
  &:last-child {
    border-left: 0 !important;
  }
  &:nth-last-child(2) {
    border-left: 0 !important;
  }
}
.control {
  display: flex;
  justify-content: flex-end;
  button {
    margin-inline-end: 10px;
    background-color: #fff;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
  }
  .edit {
    color: #0a909b;
    border: 1px solid #0a909b;
    &:hover {
      background-color: #0a909b;
      color: #fff;
    }
  }
  .delete {
    color: #e23535;
    border: 1px solid #e23535;
    &:hover {
      background-color: #e23535;
      color: #fff;
    }
  }
}
</style>
