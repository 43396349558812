<template>
  <section class="all_emp card_style">
    <div class="row mb-3">
      <div class="col-lg-2 col-md-12 col-12 gap-3 d-flex align-items-center">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>{{ $t("contract reservations") }}</h4>
      </div>
      <!-- <div class="col-lg-5 col-md-12 col-12">
                <form>
                    <div class="form-group book_search">
                        <i class="fa-solid fa-magnifying-glass"></i>
                        <input
                        type="text"
                        class="form-control"
                        v-model="bookSearch"
                        :placeholder="$t('search by name')"
                        @keyup="fetchBooks((page = 1))"
                        />
                    </div>
                </form>
            </div> -->
    </div>
    <Transition name="fade">
      <div class="books-wrapper" v-if="loading">
        <div
          class="books mt-1 row py-1 shadow px-3 align-items-center"
          v-for="book in 10"
          :key="book"
        >
          <div class="col-1">
            <Skeleton height="2rem"></Skeleton>
          </div>
          <div class="col-1 mt-2 mb-2">
            <Skeleton height="1rem" class="mb-2"></Skeleton>
            <Skeleton height="1rem"></Skeleton>
          </div>
          <div v-for="index in 5" :key="index" class="col-2 mt-2 mb-2">
            <Skeleton height="1rem" class="mb-2"></Skeleton>
            <Skeleton height="1rem"></Skeleton>
          </div>
        </div>
      </div>
      <div class="books-wrapper" v-else-if="books.length">
        <div
          class="books mt-1 d-flex align-items-center py-1 shadow px-3 flex-lg-nowrap"
          v-for="(book, index) in books"
          :key="index"
        >
          <div class="id">
            <router-link
              class="details_id"
              :to="`/hotels/book_offline/${book.id}`"
              target="_blank"
              :title="$t('edit')"
            >
              <h4 class="text-center" v-tooltip.left="$t('show_details')">
                {{ index + 1 }}
              </h4>
            </router-link>
          </div>
          <div
            class="d-flex serial align-items-center justify-content-center flex-column"
          >
            <p class="text-center text-temperature">{{ book.book_number }}</p>
            <span
              v-tooltip.bottom="$t('book_status')"
              :class="[
                'badge mx-1',
                book?.book_offline_status == 0
                  ? 'text-secondary text-capitalize'
                  : book?.book_offline_status == 1
                    ? 'text-primary text-capitalize'
                    : book?.book_offline_status == 2
                      ? 'text-success text-capitalize'
                      : book?.book_offline_status == 3
                        ? 'text-danger text-capitalize'
                        : book?.book_offline_status == 4
                          ? 'text-danger text-capitalize'
                          : '',
              ]"
            >
              {{
                book?.book_offline_status == 0
                  ? $t("pending")
                  : book?.book_offline_status == 1
                    ? $t("tentative")
                    : book?.book_offline_status == 2
                      ? $t("confirmed")
                      : book?.book_offline_status == 3
                        ? $t("cancelled")
                        : book?.book_offline_status == 4
                          ? $t("canceled")
                          : ""
              }}
            </span>
          </div>
          <div class="option_date">
            <p>{{ $t("option_date") }}</p>
            <p>
              {{
                book?.client_option_date == "" ? "--" : book?.client_option_date
              }}
            </p>
          </div>
          <div
            class="info d-flex align-items-center justify-content-end flex-column"
          >
            <div class="d-flex">
              <p class="fw-bold" v-tooltip.left="$t('hotel_name')">
                {{ book.hotel_name }}
              </p>
              <span
                class="nights p-1 d-flex align-items-center justify-content-center mx-1"
                v-tooltip.left="$t('number_of_nights')"
                ><span>{{ book.num_of_nights }}</span> <span> N</span>
              </span>
            </div>
            <p v-tooltip.left="$t('check-in:check-out')">
              {{ book.check_in_date }} -- {{ book.check_out_date }}
            </p>
          </div>
          <div class="rooms mt-0 mb-0">
            <div
              v-for="(room, index) in book.rooms"
              :key="index"
              class="room d-block flex-row-reverse"
              style="width: max-content"
            >
              <!-- <span>{{ room.count }}</span> -->
              <div class="d-flex align-items-center">
                <p
                  class="text-center text-primary"
                  v-tooltip.left="$t('room_type')"
                >
                  {{ room?.title }}
                </p>
                <!-- <p
                    class="text-center text-success"
                    v-tooltip.left="$t('supplier')"
                    >
                    {{ room?.supplier?.title }}
                    </p> -->
                <span
                  v-if="room?.rate_code"
                  class="text-center text-capitalize text-info"
                  v-tooltip.left="$t('rate_code')"
                  >, {{ room?.rate_code }}</span
                >
              </div>
            </div>
          </div>

          <div class="d-flex room_con flex-column">
            <div
              class="d-flex align-items-center justify-content-center"
              v-for="(room, index) in book.rooms"
              :key="index"
            >
              <p v-tooltip.left="$t('hotel_confirmation')">h.con:</p>
              <p class="hot_con text-center" v-tooltip.left="$t('code')">
                {{ book?.book_number }}
              </p>
            </div>
          </div>

          <div class="cost d-flex justify-content-end gap-3">
            <div class="d-flex flex-column w-50 mx-2">
              <p style="width: max-content" v-tooltip.left="$t('Date_created')">
                {{ book?.created_at }}
              </p>
              <p style="width: max-content" v-tooltip.left="$t('client_name')">
                {{ book?.client }}
              </p>
            </div>
            <div
              class="d-flex flex-column"
              :class="`${book.post_status == '0' ? 'd-none' : ''}`"
            >
              <div
                v-if="book.post_status == '0'"
                :class="`icons w-100 ${
                  book.post_status == '0' ? ' text-white p-1 rounded ' : ''
                }`"
              >
                <p
                  class="paid d-flex text-warning align-items-center justify-content-center px-2"
                ></p>
              </div>
              <div
                v-else-if="book.post_status == '1'"
                :class="`icons w-100 ${
                  book.post_status == '1' ? ' text-white p-1 rounded ' : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center text-warning justify-content-center px-2"
                >
                  {{ $t("POST") }}
                </p>
              </div>
            </div>
            <div class="d-flex flex-column align-items-center total_price w-50">
              <p class="total mb-0" v-tooltip.left="$t('total')">
                {{ book.total_selling_price }}
              </p>

              <p class="total_company" v-tooltip.left="$t('total_without_tax')">
                {{ Math.round(book?.total_room_cost_with_out_tax) }}
              </p>
              <div
                :class="` ${
                  book.payment_status == '0'
                    ? ' text-danger p-1 rounded mb-1'
                    : ''
                }`"
              ></div>
              <span class="hint_element bank_hint">{{
                $t("total_company")
              }}</span>
              <span class="hint_element bank_hint">{{
                $t("total_client")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" v-else>
        <img src="@/assets/media/image/no-data-flight.png" alt="" />
        <p class="text-center my-5 fs-3">
          {{ $t("No Reservations Found In This Contract") }}
        </p>
      </div>
    </Transition>

    <div class="row mt-3">
      <pagination
        :data="books"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchBooks"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import Skeleton from "primevue/skeleton";

export default {
  name: "contractReservations",
  data() {
    return {
      books: [],
      indexPage: 1,
      loading: true,
      bookSearch: "",
      contract_id: this.$route.params.contract_id,
    };
  },
  components: {
    Skeleton,
  },
  methods: {
    fetchBooks() {
      const formData = new FormData();
      formData.append("contract_id", this.contract_id);

      axios
        .post("/fetch_books_upon_status_and_days", formData)
        .then(({ data }) => {
          this.books = data.data;
          this.loading = false;
          // console.log(this.books);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.fetchBooks();
  },
};
</script>

<style lang="scss" scoped>
//   @import "@/view/dashboard/offline/_offline.scss";
@import "@/view/dashboard/hotelsBookOffline/_hotelsBookOffline.scss";
@import "@/assets/scss/_variables.scss";

.text-warning {
  font-size: 0.8rem !important;
}
.text-temperature {
  text-align: center !important;
}
.postToAccount {
  width: 10%;
}
.SecondButton {
  height: unset !important;
}
.total_price {
  width: 10%;
}
.book_search {
  position: relative;
  margin-bottom: unset !important;
  i {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  input {
    width: 100%;
    padding-right: unset !important;
    padding-right: 40px !important;
  }
}
.add_book.PrimaryButton {
  margin: unset !important;
}
</style>
