import axios from "axios";
import Swal from "sweetalert2";
export const globalMixin = {
  methods: {
    //function to truncate the word if it exceeds the limit
    maxWordTruncate(value, count = 15) {
      if (value && value.length > count) {
        return value && value.substring(0, count) + "...";
      } else {
        return value;
      }
    },
    //add fav route
    async addFavRoute(routePath, routeName) {
      const favRoutes = {
        path: routePath,
        name: routeName,
      };
      await axios
        .post("/store_favorite_route", favRoutes)
        .then((response) => {
          // console.log(response.data)
          Swal.fire({
            icon: "success",
            title: "Route Added to Favorites",
            text: response.data.message,
            timer: 1500,
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: this.$t("This name already exists"),
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    //HijriDate
    updateHijriDate(Date) {
      if (Date == null) {
        return "";
      }
      const hijriFormatter = new Intl.DateTimeFormat(
        "ar-SA-u-ca-islamic-umalqura",
        {
          month: "long",
          day: "numeric",
        },
      );
      return hijriFormatter.format(Date).replace(/\s*هـ$/, "");
    },
  },
};
