<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>
        <span v-if="place_id">{{ $t("edit place") }}</span>
        <span v-else>{{ $t("add place") }}</span>
      </h4>
    </div>

    <form @submit.prevent="addPlace()">
      <div class="row">
        <div class="col-12">
          <div class="image_upload">
            <label for="imageEntry" class="upload_img" v-if="imageEntry == ''">
              <img :src="Form.image ? Form.image : defaultImage" alt="user" />
            </label>
            <img
              :src="imageEntry"
              v-else-if="imageEntry"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntry"
              class="form-control"
              @change="onFileChange"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
            <span
              class="text-danger"
              v-if="v$.imageEntry.$error"
              v-text="v$.imageEntry.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="text-danger"
              v-if="v$.Form.title.$error"
              v-text="v$.Form.title.$errors[0].$message"
            ></span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("rates") }}</label>
            <input
              type="number"
              name=""
              id=""
              class="form-control"
              v-model="Form.rate"
              :placeholder="$t('rates')"
            />
            <span
              class="text-danger"
              v-if="v$.Form.rate.$error"
              v-text="v$.Form.rate.$errors[0].$message"
            ></span>
          </div>
        </div>

        <!-- start input group -->
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group w-100">
            <label class="form-label"> {{ $t("choose_country") }}</label>
            <Multiselect
              v-model="countryValue"
              :options="countries"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
              @update:model-value="FetchCityFromCountry"
            >
            </Multiselect>
            <span
              class="text-danger"
              v-if="v$.countryValue.$error"
              v-text="v$.countryValue.$errors[0].$message"
            ></span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group w-100">
            <label class="form-label"> {{ $t("city") }}</label>
            <Multiselect
              v-model="cityValue"
              :options="cities"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('enter_city')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span
              class="text-danger"
              v-if="v$.cityValue.$error"
              v-text="v$.cityValue.$errors[0].$message"
            ></span>
          </div>
        </div>
        <!-- finish input group -->

        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("price") }}</label>
            <input
              type="number"
              name=""
              id=""
              class="form-control"
              v-model="Form.price"
              :placeholder="$t('price')"
            />
            <span
              class="text-danger"
              v-if="v$.Form.price.$error"
              v-text="v$.Form.price.$errors[0].$message"
            ></span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("number_trip") }}</label>
            <input
              type="number"
              name=""
              id=""
              class="form-control"
              v-model="Form.number_trip"
              :placeholder="$t('number_trip')"
            />
            <span
              class="text-danger"
              v-if="v$.Form.number_trip.$error"
              v-text="v$.Form.number_trip.$errors[0].$message"
            ></span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("reviews") }}</label>
            <input
              type="number"
              name=""
              id=""
              class="form-control"
              v-model="Form.reviewers"
              :placeholder="$t('reviews')"
            />
            <span
              class="text-danger"
              v-if="v$.Form.reviewers.$error"
              v-text="v$.Form.reviewers.$errors[0].$message"
            ></span>
          </div>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "add-service",
  data() {
    return {
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      options: {
        placeholder: this.$t("describtion"),
        theme: "snow",
      },
      Form: {
        title: [],
        number_trip: "",
        price: "",
        rate: "",
        reviewers: "",
      },
      imageEntry: "",
      defaultImage: require("@/assets/media/image/user.png"),
      countries: [],
      cities: [],
      countryValue: "",
      cityValue: "",
      place_id: this.$route.params.id,
    };
  },
  components: {
    Multiselect,
  },
  validations() {
    return {
      imageEntry: {
        required,
      },
      countryValue: {
        required,
      },
      cityValue: {
        required,
      },
      Form: {
        title: this.Form.title.map(() => {
          return {
            name: {
              required,
            },
          };
        }),
        number_trip: {
          required,
        },
        rate: {
          required,
        },
        price: {
          required,
        },
        reviewers: {
          required,
        },
      },
    };
  },
  methods: {
    setPlaceTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          lang: language,
          name: "",
        });
      });
    },
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },

    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
        // console.log(this.countries);
      });
    },

    FetchCityFromCountry(value) {
      let basic_country_ids = {
        country_id: [value.id],
      };
      axios
        .post("/fetch_cities_by_country_id", basic_country_ids)
        .then(({ data }) => {
          this.cities = data.data;
        });
    },

    addPlace() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();

        this.Form.title.forEach((ele) => {
          formData.append(`title_${ele.lang}`, ele.name);
        });

        formData.append("number_trip", this.Form.number_trip);
        formData.append("price", this.Form.price);
        formData.append("rate", this.Form.rate);
        formData.append("reviewers", this.Form.reviewers);
        formData.append("country_id", this.countryValue.id);
        formData.append("city_id", this.cityValue.id);

        if (this.$route.params.id != undefined) {
          if (this.fileData instanceof File) {
            formData.append("image", this.fileData);
            // myVariable is a file
          } else {
            // myVariable is not a file
          }
          formData.append("destination_id", this.$route.params.id);
          axios
            .post("/website/destinations/update", formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/visit_website");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          if (this.fileData != undefined) {
            formData.append("image", this.fileData);
          }
          axios
            .post(`/website/destinations`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/visit_website");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },

    showPlace() {
      if (this.$route.params.id != undefined) {
        let id = this.$route.params.id;
        axios.get(`/fetch_destination/${id}`).then(({ data }) => {
          this.Form.title = data.data.title.map((ele) => {
            return {
              lang: ele.locale,
              name: ele.title,
            };
          });
          this.Form.image = data.data.image;
          this.Form.reviewers = data.data.reviewers;
          this.Form.number_trip = data.data.number_trip;
          this.Form.price = data.data.price;
          this.Form.rate = data.data.rate;
          this.countryValue = data.data.country;
          this.cityValue = data.data.city;
        });
      }
    },
  },
  created() {
    this.setPlaceTitle();
    this.fetch_country();
    this.showPlace();
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
</style>
