<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-12">
        <div class="flight_detials">
          <div class="main_header_style">
            <h4>{{ $t("flight_detials") }}</h4>
            <div class="badge bg-primary">{{ $t("complete_reservation") }}</div>
          </div>
          <ul class="info">
            <li>
              <span>{{ $t("Service provider") }}: </span> {{ name }}
            </li>
            <li>
              <span>{{ $t("reservation_number") }}: </span>
              {{ organizationNumber }}
            </li>
            <li>
              <span>{{ $t("ticket number") }}: </span> {{ ticketNumber }}
            </li>
            <li>
              <span>{{ $t("option date") }}: </span> {{ optionData }}
            </li>
            <li>
              <span>{{ $t("flight_line") }}: </span> {{ flightLine }}
            </li>
            <li>
              <span>{{ $t("flightClass") }}: </span> {{ flightClass }}
            </li>
            <li>
              <span>{{ $t("trip number") }}: </span> {{ tripNumber }}
            </li>
          </ul>
          <div class="price_info">
            <h6>
              <span>{{ $t("adult number") }} :</span> {{ adultNumber }}
              {{ $t("person") }}
            </h6>
            <h6>
              <i class="fa-solid fa-money-bill-transfer"></i>
              <span>{{ $t("buyPrice") }} : </span> {{ PurchasingPrice }} SAR
            </h6>
            <h6>
              <i class="fa-solid fa-money-bill-transfer"></i>
              <span> {{ $t("selling price") }} : </span>
              {{ sellingPrice }} SAR
            </h6>
          </div>

          <div class="price_info">
            <h6>
              <span>{{ $t("adult number") }} :</span> {{ adultNumber }}
              {{ $t("person") }}
            </h6>
            <h6>
              <i class="fa-solid fa-money-bill-transfer"></i>
              <span>{{ $t("buyPrice") }} : </span> {{ PurchasingPrice }} SAR
            </h6>
            <h6>
              <i class="fa-solid fa-money-bill-transfer"></i>
              <span> {{ $t("selling price") }} : </span>
              {{ sellingPrice }} SAR
            </h6>
          </div>

          <div class="price_info">
            <h6>
              <span>{{ $t("adult number") }} :</span> {{ adultNumber }}
              {{ $t("person") }}
            </h6>
            <h6>
              <i class="fa-solid fa-money-bill-transfer"></i>
              <span>{{ $t("buyPrice") }} : </span> {{ PurchasingPrice }} SAR
            </h6>
            <h6>
              <i class="fa-solid fa-money-bill-transfer"></i>
              <span> {{ $t("selling price") }} : </span>
              {{ sellingPrice }} SAR
            </h6>
          </div>

          <div class="laugae">
            <div class="main_header_style">
              <h4>{{ $t("luggage") }}</h4>
            </div>
            <div class="lists">
              <div class="list" v-for="(item, index) in goLaggage" :key="index">
                <div class="img">
                  <img src="../../../assets/media/image/ok.png" alt="ok" />
                </div>
                <div class="text">
                  <h6>{{ item.title }}</h6>
                  <span> {{ item.noBag }} {{ $t("bag") }} </span>
                </div>
              </div>
            </div>
          </div>

          <div class="laugae">
            <div class="main_header_style">
              <h4>{{ $t("Return_baggage") }}</h4>
            </div>
            <div class="lists">
              <div
                class="list"
                v-for="(item, index) in leaveLaggage"
                :key="index"
              >
                <div class="img">
                  <img src="../../../assets/media/image/ok.png" alt="ok" />
                </div>
                <div class="text">
                  <h6>{{ item.title }}</h6>
                  <span> {{ item.noBag }} {{ $t("bag") }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="passenger_detials">
          <div class="main_header_style">
            <h4>{{ $t("passengerDetials") }}</h4>
          </div>
          <div class="row">
            <table class="table">
              <thead>
                <tr>
                  <th>{{ $t("passenger_name") }}</th>
                  <th>{{ $t("passenger_type") }}</th>
                  <th>{{ $t("nationality") }}</th>
                  <th>{{ $t("phone") }}</th>
                  <th>{{ $t("email") }}</th>
                  <th>{{ $t("ID Number") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in reserve.data" :key="index">
                  <td>{{ item.id }}</td>
                  <td>{{ item.trip_num }}</td>
                  <td>{{ item.reservation_num }}</td>
                  <td>{{ item.client }}</td>
                  <td>{{ item.seller }}</td>
                  <td>{{ item.supplier }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="trip_sidebar">
          <div class="head_sidebar">
            <h6>{{ $t("go_trip") }}</h6>
            <router-link to="#" class="btn">
              {{ $t("edit_details_booking") }}
            </router-link>
          </div>
          <div class="info">
            <div class="img">
              <img
                src="../../../assets/media/image/egyptair-logo.png"
                alt="logo"
              />
            </div>
            <div class="text">
              <h6>{{ goTrip.startTime }} {{ goTrip.endTime }}</h6>
              <span>
                {{ goTrip.goCity }}({{ goTrip.goCode }}) -
                {{ goTrip.returnCity }}({{ goTrip.returnCode }})
              </span>
            </div>
          </div>

          <div class="lists">
            <div class="list">
              <div class="img">
                <img src="../../../assets/media/image/gotrip.png" alt="plane" />
              </div>
              <div class="text">
                <h6>
                  <span>{{ $t("Take_off_time") }} : </span>
                  {{ goTrip.startTime }} ,{{ goTrip.startDate }}
                </h6>
              </div>
            </div>

            <div class="list">
              <div class="img">
                <img src="../../../assets/media/image/stop.png" alt="plane" />
              </div>
              <div class="text">
                <h6>
                  <span>{{ $t("Take_off_time") }} : </span>
                  {{ stopTrip.time }} ,{{ stopTrip.date }}
                </h6>
              </div>
            </div>

            <div class="list">
              <div class="img">
                <!-- <img src="../../../assets/media/image/" alt=""> -->
              </div>
              <div class="text">
                <h6>
                  <span>{{ $t("Take_off_time") }} : </span>
                  {{ returnTrip.endTime }} ,{{ returnTrip.endDate }}
                </h6>
              </div>
            </div>
          </div>

          <div class="payment_detials">
            <h6>{{ $t("payment_details") }}</h6>
            <table class="table table_first">
              <tr>
                <td>{{ $t("flight_price_person") }}</td>
                <td>
                  {{ $t("total_adult_price") }} ()
                </td>
              </tr>
              <tr>
                <td>{{ price.room_name }}</td>
                <td>{{ price.room_price }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "flight-detials",
  data() {
    return {
      name: "احمد حوام",
      organizationNumber: "25874",
      ticketNumber: "789452",
      optionData: "25 , يناير 2023 ",
      flightLine: "شركة مصر للطيران",
      flightClass: "درجة الطيران",
      tripNumber: "258974",
      adultNumber: 4,
      childernNumber: 2,
      babyNumber: 1,
      PurchasingPrice: 100,
      sellingPrice: 200,
      goLaggage: [
        {
          id: 1,
          title: "7 كجم حقيبة الظهر",
          noBag: "2",
        },
        {
          id: 2,
          title: "مجموع وزن امتعة السفر 40 كغم",
          noBag: "1",
        },
      ],
      leaveLaggage: [
        {
          id: 1,
          title: "7 كجم حقيبة الظهر",
          noBag: "2",
        },
        {
          id: 2,
          title: "مجموع وزن امتعة السفر 40 كغم",
          noBag: "1",
        },
      ],
      reserve: [],
      goTrip: {
        goCity: "الغردقة",
        goCode: "HRG",
        returnCity: "الرياض",
        returnCode: "RUH",
        startTime: "3:00 م",
        startDate: "25 , يناير 2023",
      },
      returnTrip: {
        returnCity: "الغردقة",
        returnCode: "HRG",
        goCity: "الرياض",
        goCode: "RUH",
        endDate: "25 , يناير 2023",
        endTime: "3:00 م",
      },
      stopTrip: {
        date: "25 , يناير 2023 ",
        time: "7:30",
        location: "مطار القاهرة ",
      },
      price: {
        total_price_room: "20000 SAR",
        room_price: "20000 SAR",
        room_name: "2x غرفة ديلوكس ل 2 شخص بالغ فى الليلة",
        tax_value: "58 SAR",
        price_with_tax: "20000 SAR",
        price_without_tax: "18000 SAR",
        total_price: "20020 SAR",
      },
    };
  },
  methods: {
    fetchReservation(page = 1) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/flight?page=" + page).then(({ data }) => {
        this.reserve = data.data;
        // console.log(this.reserve);
      });
    },
  },
  mounted() {
    this.fetchReservation();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.flight_detials {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  margin-top: 20px;
  .main_header_style {
    margin-bottom: 10px;
    h4 {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  ul.info {
    list-style: none;
    padding-bottom: 1.25rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    border-bottom: 1px solid #d0d2d7;
    li {
      width: 22%;
      margin-inline-end: 2%;
      margin-bottom: 2%;
      font-size: 13px;
      span {
        color: #656b78;
      }
    }
  }
  .price_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    width: 75%;
    margin-bottom: 1rem;
    padding: 1.25rem 0;

    h6 {
      i {
        color: $PrimaryColor;
        font-size: 1.2rem;
      }
      span {
        color: #656b78;
      }
    }
  }
  .laugae {
    border-top: 1px solid #d0d2d7;
    padding: 1.25rem 0;
    .lists {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      .list {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        width: 46%;
        .img {
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text {
          margin-inline-start: 2%;
          h6 {
            font-size: 14px;
            margin-bottom: 0px;
          }
          span {
            font-size: 12px;
            color: #656b78;
          }
        }
      }
    }
  }
}
.passenger_detials {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  margin-top: 20px;
  .main_header_style {
    margin-bottom: 10px;
    h4 {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
}
.trip_sidebar {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  .head_sidebar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-content: center;
    margin: 2% 0;
    h6 {
      font-family: "bold";
      font-size: 14px;
    }
    .btn {
      border: unset;
      border-bottom: 1px solid $PrimaryColor;
      color: $PrimaryColor;
      font-size: 14px;
      border-radius: 0;
    }
  }
  .info {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    .img {
      width: 50px;
      height: 50px;
      background-color: #ecf6f6;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        padding: 3%;
      }
    }
    .text {
      margin-inline-start: 3%;
      h6 {
        font-family: "bold";
        margin-bottom: 0;
      }
      span {
        font-size: 12px;
        color: #656b78;
        font-family: "regular";
      }
    }
  }
  .lists {
    .list {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      margin-bottom: 1.25rem;
    }
  }
}
</style>
