<template>
    <div class="data">
        <h6 class="created_at" v-if="pills.created_at_date">
            {{ pills.created_at_date }} -
            {{ pills.created_at_time }}
        </h6>
        <h6 class="employee_name" v-if="pills.organization_employee_name">
            {{ pills.organization_employee_name }}
        </h6>
    </div>
    <div class="data">
        <h6 class="created_at" v-if="pills.organization_employee_phone">
            {{ pills.organization_employee_phone }}
        </h6>
        <h6 class="employee_name" v-if="pills.organization_employee_position">
            {{ pills.organization_employee_position }}
        </h6>
    </div>
    <div class="data">
        <h6 class="created_at" v-if="pills.organization_employee_email">
            {{ pills.organization_employee_email }}
        </h6>
    </div>
</template>

<script>
export default {
    props: {
        pills: Object,
    }
}
</script>

<style lang="scss" scoped>
@import "../_pill.scss";
</style>