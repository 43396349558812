<template>
  <div class="reservation_section">
    <div class="row">
      <!-- hotel -->
      <div class="col-lg-3 col-md-6 col-12">
        <div class="card_reserve">
          <div class="head">
            <h4>{{ $t("hotel_reservation") }}</h4>
          </div>
          <!-- comp;ete -->
          <div
            class="complete_reserv"
            style="border-bottom: 1px dashed #0a909b; padding-bottom: 10%"
          >
            <div class="img">
              <img src="../../assets/media/image/correct.png" alt="correct" />
            </div>
            <div class="info">
              <h6>{{ $t("completd_reservation") }}</h6>
              <span> {{ hotel_booking.complete_booking }}</span>
            </div>
          </div>
          <!-- comp;ete -->
          <!-- progress -->
          <div
            class="complete_reserv"
            style="border-bottom: 1px dashed #0a909b; padding-bottom: 10%"
          >
            <div class="img">
              <img
                src="../../assets/media/image/processing.png"
                alt="correct"
              />
            </div>
            <div class="info">
              <h6>{{ $t("progress_reservation") }}</h6>
              <span> {{ hotel_booking.in_progress_booking }}</span>
            </div>
          </div>
          <!-- progress -->
          <!-- canceled -->
          <div class="complete_reserv">
            <div class="img">
              <img src="../../assets/media/image/wrong.png" alt="correct" />
            </div>
            <div class="info">
              <h6>{{ $t("uncompleted_reservation") }}</h6>
              <span> {{ hotel_booking.canceled_booking }}</span>
            </div>
          </div>
          <!-- canceled -->
        </div>
      </div>
      <!-- hotel -->
      <!-- flight -->
      <div class="col-lg-3 col-md-6 col-12">
        <div class="card_reserve">
          <div class="head">
            <h4>{{ $t("flight_reservation") }}</h4>
          </div>
          <!-- comp;ete -->
          <div
            class="complete_reserv"
            style="border-bottom: 1px dashed #0a909b; padding-bottom: 10%"
          >
            <div class="img">
              <img src="../../assets/media/image/correct.png" alt="correct" />
            </div>
            <div class="info">
              <h6>{{ $t("completd_reservation") }}</h6>
              <span> {{ flight_booking.complete_booking }}</span>
            </div>
          </div>
          <!-- comp;ete -->
          <!-- progress -->
          <div
            class="complete_reserv"
            style="border-bottom: 1px dashed #0a909b; padding-bottom: 10%"
          >
            <div class="img">
              <img
                src="../../assets/media/image/processing.png"
                alt="correct"
              />
            </div>
            <div class="info">
              <h6>{{ $t("progress_reservation") }}</h6>
              <span> {{ flight_booking.in_progress_booking }}</span>
            </div>
          </div>
          <!-- progress -->
          <!-- canceled -->
          <div class="complete_reserv">
            <div class="img">
              <img src="../../assets/media/image/wrong.png" alt="correct" />
            </div>
            <div class="info">
              <h6>{{ $t("uncompleted_reservation") }}</h6>
              <span> {{ flight_booking.canceled_booking }}</span>
            </div>
          </div>
          <!-- canceled -->
        </div>
      </div>
      <!-- flight -->
      <!-- trip -->
      <div class="col-lg-3 col-md-6 col-12">
        <div class="card_reserve">
          <div class="head">
            <h4>{{ $t("joirny_reservation") }}</h4>
          </div>
          <!-- comp;ete -->
          <div
            class="complete_reserv"
            style="border-bottom: 1px dashed #0a909b; padding-bottom: 10%"
          >
            <div class="img">
              <img src="../../assets/media/image/correct.png" alt="correct" />
            </div>
            <div class="info">
              <h6>{{ $t("completd_reservation") }}</h6>
              <span> {{ trip_booking.complete_booking }}</span>
            </div>
          </div>
          <!-- comp;ete -->
          <!-- progress -->
          <div
            class="complete_reserv"
            style="border-bottom: 1px dashed #0a909b; padding-bottom: 10%"
          >
            <div class="img">
              <img
                src="../../assets/media/image/processing.png"
                alt="correct"
              />
            </div>
            <div class="info">
              <h6>{{ $t("progress_reservation") }}</h6>
              <span> {{ trip_booking.in_progress_booking }}</span>
            </div>
          </div>
          <!-- progress -->
          <!-- canceled -->
          <div class="complete_reserv">
            <div class="img">
              <img src="../../assets/media/image/wrong.png" alt="correct" />
            </div>
            <div class="info">
              <h6>{{ $t("uncompleted_reservation") }}</h6>
              <span> {{ trip_booking.canceled_booking }}</span>
            </div>
          </div>
          <!-- canceled -->
        </div>
      </div>
      <!-- trip -->
      <!-- transporter -->
      <div class="col-lg-3 col-md-6 col-12">
        <div class="card_reserve">
          <div class="head">
            <h4>{{ $t("transporter_reservation") }}</h4>
          </div>
          <!-- comp;ete -->
          <div
            class="complete_reserv"
            style="border-bottom: 1px dashed #0a909b; padding-bottom: 10%"
          >
            <div class="img">
              <img src="../../assets/media/image/correct.png" alt="correct" />
            </div>
            <div class="info">
              <h6>{{ $t("completd_reservation") }}</h6>
              <span> {{ transport_booking.complete_booking }}</span>
            </div>
          </div>
          <!-- comp;ete -->
          <!-- progress -->
          <div
            class="complete_reserv"
            style="border-bottom: 1px dashed #0a909b; padding-bottom: 10%"
          >
            <div class="img">
              <img
                src="../../assets/media/image/processing.png"
                alt="correct"
              />
            </div>
            <div class="info">
              <h6>{{ $t("progress_reservation") }}</h6>
              <span> {{ transport_booking.in_progress_booking }}</span>
            </div>
          </div>
          <!-- progress -->
          <!-- canceled -->
          <div class="complete_reserv">
            <div class="img">
              <img src="../../assets/media/image/wrong.png" alt="correct" />
            </div>
            <div class="info">
              <h6>{{ $t("uncompleted_reservation") }}</h6>
              <span> {{ transport_booking.canceled_booking }}</span>
            </div>
          </div>
          <!-- canceled -->
        </div>
      </div>
      <!-- transporter -->
    </div>
  </div>
</template>

<script>
export default {
  name: "reservation-component",
  props: {
    hotel_booking: Object,
    flight_booking: Object,
    transport_booking: Object,
    trip_booking: Object,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "../../view/dashboard/organization/_organization.scss";
</style>
