<template>
  <clientHistory v-if="loading && $route.params.id !== undefined" />
  <section class="card_style client_history" v-else>
    <div class="main_header">
      <h4>{{$t("client_data")}}</h4>
    </div>
    <div class="client_data">
      <div class="title d-flex justify-content-between">
        <div class="name">
          <h4><i class="fa-regular fa-user"></i> {{client_data.name}}</h4>
          <span>{{$t("member_since")}}: {{created_at}}</span>
        </div>
        <div class="connects">
          <emailDialog :clientEmail="email" />
          <whatsAppDialog :clientPhone="phone" :initialText="text" />

          <button
            class="sms mx-1 d-flex align-items-center justify-content-center"
          >
            <img
              src="@/assets/media/image/icon/messages.svg"
              class="mx-1"
              alt=""
            />
          </button>
        </div>
      </div>

      <hr />

      <div class="personal_data">
        <h5>{{$t("personal_data")}}</h5>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 mb-2">
            <div><span><i class="fa-regular fa-envelope"></i> {{$t("email")}}</span> : {{client_data.email}}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mb-2">
            <div><span><i class="fa-solid fa-phone-flip"></i> {{$t("phone_number")}}</span> : {{client_data.phone}}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mb-2">
            <div><span><i class="fa-solid fa-flag"></i>{{$t("nationality")}}</span> : {{client_data.nationality?.nationality_title}}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mb-2">
            <div><span><i class="fa-solid fa-calendar-days"></i> {{$t("date_of_birth")}}</span> : {{client_data.birth_day}}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mb-2">
            <div><span><i class="fa-solid fa-user"></i>{{$t("age")}}</span> : {{client_data.age}}</div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mb-2">
            <div><span><i class="fa-solid fa-passport"></i> {{$t("passport_number")}}</span> : {{client_data.passport_number == "" ? " -- " : client_data.passport_number}}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mb-2">
            <div><span><i class="fa-brands fa-expeditedssl"></i> {{$t("passport_expiry_date")}}</span> : {{client_data.passport_expiry_date == "" ? " -- " : client_data.passport_expiry_date}}</div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="client_history mb-3">
      <h5>{{ $t("client history") }}</h5>
    </div>

      <div class="row">
        <div class="card width_nav">
          <TabView>
            <TabPanel :header="$t('hotel_reservation')">
              <BookHotel />
            </TabPanel>

            <TabPanel :header="$t('B2C Flights')">
              <Flights_comp />
            </TabPanel>

            <TabPanel :header="$t('package')">
              <Umrah_trips />
            </TabPanel>
          </TabView>
        </div>
      </div>

  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import BookHotel from "./Book_Hotel/Book_Hotel.vue";
import Flights_comp from "./Book_flights/Book_flights.vue";
import Umrah_trips from "./Umrah_trips/Umrah_trips.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import emailDialog from "../../../components/Dialogs/emailDialog.vue";
import whatsAppDialog from "../../../components/Dialogs/whatsAppDialog.vue";
import Swal from "sweetalert2";
import clientHistory from "@/components/skelton/clientHistory.vue";

export default {
  data() {
    return {
      client_data: {},
      text: "",
      created_at: "",
      email: "",
      phone: "",
      default_image:
        "https://kanaya.x-coders.net/img/user.a0e54211.a0e54211.png",
      loading: true,
    };
  },
  components: {
    TabPanel,
    TabView,
    emailDialog,
    whatsAppDialog,
    BookHotel,
    Flights_comp,
    Umrah_trips,
    clientHistory,
  },
  methods: {
    fetch_User_details() {
      try {
        axios
          .post(`/fetchClient`, {
            client_id: this.$route.params.id,
          })
          .then((res) => {
            this.client_data = res.data.data;
            this.created_at = moment(res.data.data.created_at).format("DD-MM-YYYY");
            this.email = res.data.data.email;
            this.phone = res.data.data.phone;
            this.loading = false;
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
        });
      }
    },
  },
  mounted() {
    this.fetch_User_details();
  },
};
</script>

<style lang="scss" scoped>
@import "@/view/dashboard/offline/_offline.scss";
@import "@/assets/scss/_variables.scss";

.client_data{
  .title{
    flex-wrap: wrap;
    .name{
      i{
        color: $PrimaryColor;
      }
      span{
        color: #9ca2ac;
        font-size: 14px;
      }
    }
  }
  .email_contact {
    background-color: #0a909b;
    color: white;
    padding: 0.5rem 1rem;
    width: 80%;
  }

  .connects {
    // width: 80%;
    display: flex;
    align-items: center;
    // justify-content: space-evenly;

    .sms {
      border: 1px solid #0a909b;
      border-radius: 6px;
      padding: 0.5rem 1rem;
      background-color: transparent;

      span {
        color: #0b1427;
        font-size: 1rem;
      }
    }

    .whatsapp {
      border: 1px solid #64bf72;
      border-radius: 6px;
      padding: 0.5rem 1rem;
      background-color: transparent;

      span {
        color: #0b1427;
        font-size: 1rem;
      }
    }
  }
}
.personal_data{
  span{
    color: #9ca2ac;
    i{
      margin-inline-end: 3px;   
      font-size: 14px;
    }
  }
}

hr{
  width: 100%;
  height: 1px; 
  background-color: #ddd;
  border-top: unset !important;
  box-shadow: 0px 2px 7px #000;
}

</style>
