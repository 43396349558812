<template>
  <div
    class="offcanvas offcanvas-bottom"
    tabindex="-1"
    id="offcanvasBottom"
    aria-labelledby="offcanvasBottomLabel"
  >
    <div class="offcanvas-header">
      <h4 class="offcanvas-title" id="offcanvasBottomLabel">
        {{ $t("add new account") }}
      </h4>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>

    <div class="offcanvas-body small">
      <form class="charge_wallet_request" @submit.prevent="save(branch_id)">
        <div class="row">
          <div class="col-lg-6 my-2">
            <label for="title_ar" class="form-label"
              >{{ $t("account name") }} AR</label
            >
            <input
              type="text"
              :placeholder="$t('write account name')"
              id="title_ar"
              class="form-control"
              v-model="title_ar"
            />
          </div>
          <div class="col-lg-6 my-2">
            <label for="title_en" class="form-label"
              >{{ $t("account name") }} EN</label
            >
            <input
              type="text"
              :placeholder="$t('write account name')"
              id="title_en"
              class="form-control"
              v-model="title_en"
            />
          </div>
          <!--          <div class="col-lg-6 my-2">-->
          <!--            <label class="form-label" for="balance">-->
          <!--              {{ $t("Initial Balance") }}</label-->
          <!--            >-->
          <!--            <input-->
          <!--              type="number"-->
          <!--              id="balance"-->
          <!--              class="form-control initial-balance"-->
          <!--              :placeholder="$t('Initial Balance')"-->
          <!--              v-model="balance"-->
          <!--            />-->
          <!--          </div>-->
        </div>

        <div class="row my-4">
          <!--          <div class="col-lg-6 col-12">-->
          <!--            <h5>{{ $t("display in") }}</h5>-->
          <!--            <div class="row">-->
          <!--              <div class="form-check col-lg-3">-->
          <!--                <input-->
          <!--                  type="radio"-->
          <!--                  id="income"-->
          <!--                  value="1"-->
          <!--                  v-model="report_type"-->
          <!--                />-->
          <!--                <label for="income">-->
          <!--                  {{ $t("income value") }}-->
          <!--                </label>-->
          <!--              </div>-->
          <!--              <div class="form-check col-lg-3">-->
          <!--                <input-->
          <!--                  type="radio"-->
          <!--                  id="budget"-->
          <!--                  value="2"-->
          <!--                  v-model="report_type"-->
          <!--                />-->
          <!--                &lt;!&ndash; 1=>income_list;2=>budget;3=>both;  report_type &ndash;&gt;-->
          <!--                <label for="budget">-->
          <!--                  {{ $t("the budget") }}-->
          <!--                </label>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="col-lg-6 col-12">
            <h5>{{ $t("account type") }}</h5>
            <div class="row">
              <div class="form-check col-lg-3">
                <input
                  type="radio"
                  id="debtor"
                  v-model="balance_type"
                  value="1"
                />
                <label for="debtor">
                  {{ $t("debtor") }}
                </label>
              </div>
              <div class="form-check col-lg-3">
                <input
                  type="radio"
                  id="creditor"
                  v-model="balance_type"
                  value="2"
                />
                <!-- 1=>debtor;2=>creditor;3=>both;   balance_type -->
                <label for="creditor">
                  {{ $t("creditor") }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-lg-6">
            <label for="statement" class="form-label">{{
              $t("the statement")
            }}</label>
            <textarea
              :placeholder="$t('account describtion')"
              id="statement"
              class="form-control"
              v-model="statement"
            ></textarea>
          </div>
        </div>

        <button
          type="submit"
          class="btn save btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: ["branch_id"],
  data() {
    return {
      statement: "",
      title_ar: "",
      title_en: "",
      balance: 0,
      report_type: 1,
      balance_type: 1,
    };
  },
  methods: {
    save(branch_id) {
      // console.log(branch_id)
      let addedData = {
        title_ar: this.title_ar,
        title_en: this.title_en,
        text: this.statement,
        // opening_balance: this.balance,
        parent_id: branch_id,
        report_type: this.report_type,
        balance_type: this.balance_type,
      };
      axios
        .post("/tree_branch", addedData)
        .then(({ data }) => {
          // console.log(data)
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          // reset data
          this.title_ar = "";
          this.title_en = "";
          this.statement = "";
          this.balance = "";
          this.report_type = 1;
          this.balance_type = 1;
          // window.location.reload();

          // finish reset data
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.title_ar = "";
          this.title_en = "";
          this.statement = "";
          this.balance = "";
          this.report_type = 1;
          this.balance_type = 1;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../view/dashboard/wallet/_wallet.scss";
.form-check {
  display: flex;
  align-items: center;
  input[type="radio"] {
    width: 15px !important;
    height: 15px;
  }
  label {
    margin-inline-start: 10px;
  }
}
</style>
