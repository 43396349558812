<template>
  <section class="card_style">
    <div class="main_header">
      <h4 v-if="attach_id !== undefined">
        {{ $t("edit_attachments_for_book_offline") }}
      </h4>
      <h4 v-else>{{ $t("add_attachments_for_book_offline") }}</h4>
    </div>
    <form @submit.prevent="sendData">
      <div class="row">
        <div class="col-md-4 col-12">
          <div class="form-group">
            <label class="form-label" for="title">{{ $t("title") }}</label>
            <input
              type="text"
              class="form-control"
              v-model="attachment_title"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group w-100">
            <label class="form-label" for="imageEntry">{{ $t("image") }}</label>
            <input
              type="file"
              name="imageEntry"
              id="imageEntry"
              class="form-control w-100"
              @change="onFileChange"
              accept="image/png, image/gif, image/jpeg, image/jpg, image/webp, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
          </div>
          <img :src="imageEntry" class="blog_img w-25" />
        </div>
        <div class="col-lg-4 mb-4">
          <label for="type">{{ $t("type") }}</label>
          <Multiselect
            v-model="suplier_client"
            :options="supliers_clients"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('type')"
            select-label=""
            :hide-selected="true"
          />
        </div>
        <div class="col-12">
          <button
            type="submit"
            :disabled="loading"
            class="btn PrimaryButton"
            v-if="loading"
          >
            <i class="fa-solid fa-circle-notch fa-spin"></i>
          </button>

          <button
            type="submit"
            class="btn PrimaryButton"
            v-else
            :disabled="!valid"
          >
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  name: "addAttachment",
  components: { Multiselect },
  data() {
    return {
      hotel_id: this.$route.params.id,
      attachment_title: "",
      imageEntry: "",
      attach_id: this.$route.params.attach_id,
      supliers_clients: [
        { title: "Client", id: 1 },
        { title: "Suplier", id: 2 },
      ],
      suplier_client: "",
      fileData: null,
      loading: false,
    };
  },
  computed: {
    valid() {
      return this.attachment_title && this.suplier_client && this.imageEntry;
    },
  },
  methods: {
    onFileChange(event) {
      this.fileData = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    sendData() {
      this.loading = true;
      const formData = new FormData();
      formData.append("offline_booking_hotel_id", this.hotel_id);
      formData.append("title", this.attachment_title);
      formData.append("type", this.suplier_client.id);

      if (this.fileData instanceof File) {
        formData.append("attachment", this.fileData);
      }

      const url =
        this.attach_id !== undefined
          ? `/update_book_attachment/${this.attach_id}`
          : `store_book_attachment`;

      axios
        .post(url, formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push(`/hotels/book_offline/${this.hotel_id}`);

          this.loading = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response?.data?.message || error.message,
            icon: "error",
          });
          this.loading = false;
        });
    },
    fetchAttachData() {
      axios
        .get(`/show_book_attachment/${this.attach_id}`)
        .then(({ data }) => {
          this.attachment_title = data.data.title;
          this.imageEntry = data.data.attachment;
          this.loading = false;
          this.suplier_client =
            data.data.type === 2
              ? { title: "Suplier", id: 2 }
              : { title: "Client", id: 1 };
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.attach_id !== undefined) {
      this.fetchAttachData();
    }
  },
};
</script>

<style lang="scss" scoped>
.blog_img {
  max-width: 100px;
  max-height: 100px;
}
</style>
