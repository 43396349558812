<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="add_recipt card_style">
    <div class="main_header_style d-flex justify-content-between">
      <h4>{{ $t("edit_contract") }}</h4>
      <button
        @click="publish()"
        class="btn PrimaryButton"
        v-if="published != 'PUBLISH'"
      >
        {{ $t("publish") }}<i class="fa-solid fa-bullhorn mx-1"></i>
      </button>
    </div>
    <form class="form_topic" @submit.prevent="editContract()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("contract_name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('enter_contract_name')"
              v-model="name"
            />
          </div>
          <span class="input-errors" v-if="v$.name.$error">{{
            v$.name.$errors[0].$message
          }}</span>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-3 col-md-6">
          <label class="form-label"> {{ $t("hotel_name") }}</label>
          <div class="selects">
            <div class="input-group">
              <input
                type="search"
                class="form-control"
                :placeholder="hotelName"
                v-model="searchValue"
                @input="debouncedSearchHotels"
                :disabled="hotelSearchLoading"
              />
              <div class="spinner" v-if="hotelSearchLoading">
                <i class="fa-solid fa-circle-notch fa-spin"></i>
              </div>
              <div
                class="resultSearch"
                v-if="searchValue.length > 2 && hotelsSearch.length"
              >
                <h6 class="head_search">{{ $t("hotels") }}</h6>
                <ul class="hotels">
                  <li
                    v-for="(hotel, index) in hotelsSearch"
                    :key="index"
                    @click="sendHotelName(hotel.title, hotel.city_id, hotel.id)"
                  >
                    <img
                      :src="hotel.image"
                      class="hotel_image"
                      :alt="hotel.title"
                    />
                    <div class="texts">
                      <p class="title">{{ hotel.title }}</p>
                      <p class="country">
                        {{ hotel.country_title }} - {{ hotel.city_title }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <span class="input-errors" v-if="v$.hotelId.$error">{{
            v$.hotelId.$errors[0].$message
          }}</span>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("period_from") }}</label>
            <datepicker
              v-model="from_date"
              name="from_date"
              :placeholder="$t('from')"
              :disabled-dates="{
                to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
              }"
            ></datepicker>
            <span class="input-errors" v-if="v$.from_date.$error">{{
              v$.from_date.$errors[0].$message
            }}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("to") }}</label>
            <datepicker
              v-model="to_date"
              name="from_date"
              :placeholder="$t('to')"
              :disabled-dates="{
                to: new Date(),
              }"
            ></datepicker>
            <span class="input-errors" v-if="v$.to_date.$error">{{
              v$.to_date.$errors[0].$message
            }}</span>
          </div>
        </div>
        <!-- finish input group -->
        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label">{{ $t("supplier_type") }}</label>
            <Multiselect
              v-model="supplierOptionValue"
              :options="optionsSupplies"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
              @update:model-value="checkHotel()"
            >
            </Multiselect>
            <span
              class="input-errors"
              v-if="v$.supplierOptionValue.id.$error"
              >{{ v$.supplierOptionValue.id.$errors[0].$message }}</span
            >
          </div>
        </div>

        <!-- start input group -->
        <div
          class="col-12 col-lg-3 col-md-6"
          v-if="supplierOptionValue?.title != 'hotel_direct'"
        >
          <div class="input-group">
            <div class="d-flex justify-content-between w-100">
              <label class="">{{ $t("Supplier") }}</label>
              <supllierDialog />
            </div>
            <Multiselect
              v-model="supplier"
              :options="suppliers"
              label="name"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.supplier.id.$error">{{
              v$.supplier.id.$errors[0].$message
            }}</span>
          </div>
        </div>
        <!-- finish input group -->

        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label">{{ $t("contract_type") }}</label>
            <Multiselect
              v-model="nightValue"
              :options="nigths"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.nightValue.id.$error">{{
              v$.nightValue.id.$errors[0].$message
            }}</span>
          </div>
        </div>

        <!-- <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("Total_contract_value") }}</label>
            <input type="number" class="form-control" v-model="totalValue" placeholder="0" />
          </div>
          <span class="input-errors" v-if="v$.totalValue.$error">{{v$.totalValue.$errors[0].$message}}</span>
        </div> -->

        <!-- start input group -->
        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("Total_number_of_rooms") }}</label>
            <input
              type="number"
              class="form-control"
              v-model="room_number"
              placeholder="0"
            />
          </div>
          <span class="input-errors" v-if="v$.room_number.$error">{{
            v$.room_number.$errors[0].$message
          }}</span>
        </div>
        <!-- finish input group -->

        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label">{{ $t("default_rooms") }}</label>
            <Multiselect
              class="w-100"
              v-model="roomValue"
              :options="rooms"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
            ></Multiselect>
            <span class="input-errors" v-if="v$.roomValue.id.$error">{{
              v$.roomValue.id.$errors[0].$message
            }}</span>
          </div>
        </div>

        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group">
            <label class="form-label">{{ $t("default_meal") }}</label>
            <Multiselect
              v-model="mealsValue"
              :options="meals"
              label="title"
              track-by="meal_type_id"
              :clear-on-select="true"
              :hide-selected="true"
              :placeholder="$t('meals')"
              select-label=""
            >
            </Multiselect>
            <span
              class="input-errors"
              v-if="v$.mealsValue.meal_type_id.$error"
              >{{ v$.mealsValue.meal_type_id.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div class="col-12 col-lg-3 col-md-6">
          <div class="form-check form-switch form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchbtb"
              v-model="share_b2b"
            />
            <label class="form-check-label form-label" for="flexSwitchbtb">
              {{ $t("b2b_publish") }}</label
            >
          </div>
          <div class="form-check form-switch form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchbtb"
              v-model="active"
            />
            <label class="form-check-label form-label" for="flexSwitchbtb">
              {{ $t("active") }}</label
            >
          </div>
          <div class="form-check form-switch form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchbtc"
              v-model="share_b2c"
              checked
            />
            <label class="form-check-label form-label" for="flexSwitchbtc">
              {{ $t("b2c_publish") }}</label
            >
          </div>
        </div>

        <div class="col-12 col-lg-3 col-md-6">
          <div class="input-group mb-0">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="can_minus_room"
                v-model="can_minus_room"
              />
              <label class="form-check-label" for="can_minus_room">
                {{ $t("can_minus_room") }}
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="can_over_room"
                v-model="can_over_room"
              />
              <label class="form-check-label" for="tax_included">
                {{ $t("over_contract") }}
              </label>
            </div>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="tax_included"
              v-model="tax_included"
            />
            <label class="form-check-label" for="tax_included">
              {{ $t("Taxes included") }}
            </label>
          </div>
        </div>
        <div class="col-12">
          <div class="advice">
            <h4>{{ $t("holiday") }}</h4>
            <div class="row">
              <div
                class="col-lg-3 col-12"
                v-for="(day, index) in days"
                :key="index"
              >
                <div class="form-check mt-0">
                  <div class="input-group">
                    <label :for="'default' + day.value">
                      <input
                        class="form-control"
                        type="checkbox"
                        v-model="day.selected"
                        :id="'default' + day.value"
                        :value="day.value"
                      />
                      <div class="contents">
                        <h6>{{ $t(day.name) }}</h6>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 border border-1 d-flex align-items-center gap-3 p-2"
          style="overflow-x: auto"
        >
          <div
            v-for="dailyRestriction in dailyRestrictions"
            :key="dailyRestriction.id"
            class="p-2 border border-1 rounded"
          >
            <span
              class="text-info cursor-pointer"
              data-bs-toggle="offcanvas"
              :data-bs-target="`#offcanvasBottomDetails${item.id}`"
              aria-controls="offcanvasBottom"
              :title="$t('details')"
              @click="getTransferItem(dailyRestriction)"
            >
              <!--              <i class="fa-solid fa-info"></i>>-->
              {{ dailyRestriction.text }}
            </span>
          </div>
          <detailsOffCanvas v-if="item" :item="item" />
        </div>

        <!-- start input group -->
        <div class="col-12 d-flex justify-content-end gap-3">
          <button
            type="submit"
            class="btn PrimaryButton w-25"
            :disabled="!hotelSubmit"
            v-if="published != 'PUBLISH'"
          >
            {{ $t("save_cont") }}
          </button>
          <span
            class="btn PrimaryButton w-25"
            :disabled="!hotelSubmit"
            v-if="published != 'PUBLISH'"
            @click="saveAndPublish()"
          >
            {{ $t("save_and_publish") }}
          </span>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import Loading from "@/components/LottieFiles/loading.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import supllierDialog from "@/components/Dialogs/supplierDialog.vue";
import detailsOffCanvas from "@/components/dailyConstruct/detailsOffCanvas.vue";

export default {
  name: "add-contract",
  components: {
    detailsOffCanvas,
    Multiselect,
    Datepicker,
    Loading,
    supllierDialog,
  },
  data() {
    return {
      booked_room_number: null,
      v$: useVuelidate(),
      tax_included: false,
      published: "",
      active: "",
      days: [
        {
          value: 6,
          name: "saturday",
          selected: false,
        },
        {
          value: 0,
          name: "sunday",
          selected: false,
        },
        {
          value: 1,
          name: "monday",
          selected: false,
        },
        {
          value: 2,
          name: "tuesday",
          selected: false,
        },
        {
          value: 3,
          name: "wenday",
          selected: false,
        },
        {
          value: 4,
          name: "thursday",
          selected: false,
        },
        {
          value: 5,
          name: "friday",
          selected: false,
        },
      ],
      name: "",
      from_date: "",
      to_date: "",
      suppliers: [],
      supplier_id: "",
      room_number: "",
      selectedDayIds: [],
      hotels: [],
      statues: [
        {
          id: 0,
          title: "disactive",
        },
        {
          id: 1,
          title: "active",
        },
      ],
      share_b2b: false,
      share_b2c: false,
      supplierValue: "",
      selectedValue: "",
      accounts: [],
      revenue: [],
      amount: "",
      hotel_id: "",
      report: "",
      account_id: "",
      image: "",
      created_at: "",
      code_number: "",
      revenue_type_id: "",
      image_preview: "",
      loading: false,
      hotelId: "",
      hotelsSearch: [],
      hotelValue: "",
      searchValue: "",
      hotelName: "",
      contract: [],
      supplier: {},
      dailyRestrictions: [],
      status: {},
      optionsSupplies: [
        {
          id: 1,
          title: "hotel_direct",
        },
        {
          id: 2,
          title: "supplier",
        },
      ],
      supplierOptionValue: {},
      nigths: [
        {
          id: 1,
          title: "block",
        },
        {
          id: 2,
          title: "room_night",
        },
      ],
      nightValue: "",
      totalValue: "",
      rooms: [],
      roomValue: {},
      mealsValue: {},
      meals: [],
      hotelSubmit: true,
      can_over_room: false,
      can_minus_room: false,
      item: {},
      debounceTimeout: null,
      isRequestPending: false,
      hotelSearchLoading: false,
    };
  },
  validations() {
    return {
      name: { required },
      hotelId: { required },
      from_date: { required },
      to_date: { required },
      supplierOptionValue: { id: { required } },
      supplier: { id: { required } },
      nightValue: { id: { required } },
      // totalValue: {required},
      room_number: { required },
      roomValue: { id: { required } },
      mealsValue: { meal_type_id: { required } },
    };
  },
  methods: {
    publish() {
      axios
        .post("/change_publish_contract", {
          contract_id: this.$route.params.id,
          publish: 1,
        })
        .then((res) => {
          Swal.fire({
            title: res.data.message,
            icon: "success",
            confirmButtonText: this.$t("ok"),
          });
        });
    },
    async saveAndPublish() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const selectedDays = this.days
          .filter((day) => day.selected)
          .map((day) => day.value);
        if (selectedDays.length == 0) {
          Swal.fire({
            title: "",
            text: this.$t("please select days"),
            icon: "error",
          });
        } else {
          await axios.post("/change_publish_contract", {
            contract_id: this.$route.params.id,
            publish: 1,
          });
          const formData = new FormData();
          formData.append("name", this.name);
          formData.append(
            "from_date",
            moment(this.from_date).format("YYYY-MM-DD")
          );
          formData.append("to_date", moment(this.to_date).format("YYYY-MM-DD"));
          formData.append("supplier_id", this.supplier.id);
          formData.append(
            "hotel_id",
            this.hotelId == "" ? this.hotelValue : this.hotelId
          );
          formData.append("room_number", this.room_number);
          formData.append("status", 1);
          formData.append("_method", "PUT");

          const selectedDays = this.days
            .filter((day) => day.selected)
            .map((day) => day.value);

          // Append the array of selected service type values to formData
          selectedDays.forEach((value, index) => {
            formData.append(`contract_weekends[${index}]`, value);
          });
          if (this.share_b2b == true) {
            formData.append("share_b2b", 1);
          } else {
            formData.append("share_b2b", 0);
          }

          if (this.share_b2c == true) {
            formData.append("share_b2c", 1);
          } else {
            formData.append("share_b2c", 0);
          }

          formData.append(
            "contract_supplier_type",
            this.supplierOptionValue?.id
          );
          formData.append("total", this.totalValue);
          formData.append("contract_price_type", this.nightValue?.id);
          // formData.append("total", this.totalValue);
          formData.append("hotel_room_view_id", this.roomValue?.id);
          formData.append("default_meal_id", this.mealsValue?.meal_type_id);
          formData.append("can_over_room", this.can_over_room == true ? 1 : 0);
          formData.append(
            "can_minus_room",
            this.can_minus_room == true ? 1 : 0
          );

          formData.append("tax_included", this.tax_included == true ? 1 : 0);

          this.loading = true; // Start loading

          try {
            const contractId = this.$route.params.id;
            await axios.post(`/contracts/${contractId}`, formData);
            // After editing contract, publish it

            Swal.fire({
              title: "",
              text: this.$t("Contract edited and published successfully."),
              icon: "success",
            });
            this.loading = false; //the loading ended
            this.$router.push("/contract");
          } catch (error) {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false; //the loading ended
          }
        }
      }
    },
    getTransferItem(item) {
      axios
        .get(`daily_restriction/${item.id}`)
        .then(({ data }) => (this.item = data.data));
    },

    checkHotel() {
      if (this.supplierOptionValue?.title == "supplier") {
        this.fetchRooms();
      }
      if (this.supplierOptionValue?.title == "hotel_direct") {
        this.fetchHotel();
      }
    },
    fetchHotel() {
      axios
        .post("checkOrganizationHotel", { hotel_id: this.hotelId })
        .then((res) => {
          if (res.data.data == false) {
            Swal.fire({
              title: this.$t("this_hotel_has_no_supplers"),
              icon: "error",
              confirmButtonText: this.$t("ok"),
            });
            this.hotelSubmit = false;
            this.supplierOptionValue = {};
          } else {
            this.fetchRooms();
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    // Debounce function with character length check
    debouncedSearchHotels(event) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        if (event.target.value.length <= 2) {
          return; // Exit if the input length is 2 or less
        }
        this.searchHotels(event);
      }, 1000); // Adjust the delay as needed (e.g., 1000ms)
    },

    //Search Hotels
    searchHotels(event) {
      if (this.isRequestPending) return;
      this.hotelSearchLoading = true;

      this.isRequestPending = true;
      const searchText = { word: event.target.value };

      axios
        .post(`/fetch_new_home_hotels`, searchText)
        .then((res) => {
          this.hotelsSearch = res.data.data.hotels;
          this.citiesSearch = res.data.data.cities;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.isRequestPending = false;
          this.hotelSearchLoading = false;
        });
    },

    // fetch hotels
    sendHotelName(hotelName, cityId, hotelId) {
      this.hotelName = hotelName;
      this.searchValue = hotelName;
      this.hotelId = hotelId;
      this.cityId = cityId;
      this.citiesSearch.length = [];
      this.hotelsSearch = [];

      this.supplierOptionValue = {};
      this.rooms = [];
      this.roomValue = {};
    },
    sendCityId(cityTitle, cityId) {
      this.cityId = cityId;
      this.searchValue = cityTitle;
      this.hotelName = null;
      this.citiesSearch = [];
      this.hotelsSearch = [];
    },
    //fetch supplier
    fetch_suppliers() {
      axios.get("/consumers").then(({ data }) => {
        this.suppliers = data.data.data;
      });
    },
    //fetch organization type
    fetchOrganizationType(value) {
      let type = {
        type: value,
      };
      axios.post("/fetch_my_organization_accounts", type).then(({ data }) => {
        this.accounts = data.data.data;
      });
    },
    //fetch contract
    fetch_contract() {
      let id = this.$route.params.id;
      // console.log(id);
      axios.get("/contracts/" + id).then(({ data }) => {
        this.booked_room_number = data.data.booked_room_number;
        this.name = data.data.name;
        this.from_date = data.data.from;
        this.to_date = data.data.to;
        this.supplier = data.data.supplier;
        this.hotelValue = data.data.hotel.id;
        this.hotelName = data.data.hotel.name;
        this.room_number = data.data.room_number;
        this.totalValue = data.data.total;
        // this.status = data.data.status;
        this.hotelId = data.data.hotel.id;
        this.active = data.data.status;
        this.published = data.data.publish;
        this.dailyRestrictions = data.data.daily_restrictions;
        if (data.data.share_b2b === 1) {
          this.share_b2b = true; // Set to true if data is 1
        } else if (data.data.share_b2b === 0) {
          this.share_b2b = false; // Set to false for any other value
        }

        if (data.data.share_b2c === 1) {
          this.share_b2c = true; // Set to true if data is 1
        } else {
          this.share_b2c = false; // Set to false for any other value
        }
        if (data.data.status == "ACTIVE") {
          this.active = true;
        } else {
          this.active = false;
        }

        const selectedDays = data.data.contract_weekends;
        // Set the selected property based on received data
        this.days.forEach((day) => {
          day.selected = selectedDays.some(
            (selectedDay) => selectedDay.day_id === day.value
          );
        });

        this.optionsSupplies.forEach((ele) => {
          if (ele.title == data?.data?.contract_supplier_type?.toLowerCase()) {
            this.supplierOptionValue = ele;
            this.checkHotel();
          }
        });

        this.nigths.forEach((ele) => {
          if (ele.title == data?.data?.contract_price_type?.toLowerCase()) {
            this.nightValue = ele;
          }
        });

        this.mealsValue = data.data.default_meal;
        // {
        //   meal_type_id: data.data.default_meal.id,
        //   title: data.data.default_meal.title
        // };
        this.roomValue = data.data.default_rooms;
        // this.totalValue = data.data.total;

        this.can_over_room = data.data.can_over_room == 1 ? true : false;
        this.can_minus_room = data.data.can_minus_room == 1 ? true : false;
        this.tax_included = data.data.tax_included == 1 ? true : false;
      });
    },

    async editContract() {
      this.v$.$validate();

      if (!this.v$.$error) {
        const selectedDays = this.days
          .filter((day) => day.selected)
          .map((day) => day.value);
        if (selectedDays.length == 0) {
          Swal.fire({
            title: "",
            text: this.$t("please select days"),
            icon: "error",
          });
        } else if (this.room_number <= this.booked_room_number) {
          Swal.fire({
            text:
              this.$t("Room number must be less than booked room number") +
              " " +
              this.booked_room_number,
            icon: "warning",
          });
        } else {
          const formData = new FormData();
          formData.append("name", this.name);
          formData.append(
            "from_date",
            moment(this.from_date).format("YYYY-MM-DD")
          );
          formData.append("to_date", moment(this.to_date).format("YYYY-MM-DD"));
          formData.append("supplier_id", this.supplier.id);
          formData.append(
            "hotel_id",
            this.hotelId == "" ? this.hotelValue : this.hotelId
          );
          formData.append("room_number", this.room_number);
          formData.append("status", 1);
          formData.append("_method", "PUT");

          const selectedDays = this.days
            .filter((day) => day.selected)
            .map((day) => day.value);

          // Append the array of selected service type values to formData
          selectedDays.forEach((value, index) => {
            formData.append(`contract_weekends[${index}]`, value);
          });
          if (this.share_b2b == true) {
            formData.append("share_b2b", 1);
          } else {
            formData.append("share_b2b", 0);
          }

          if (this.share_b2c == true) {
            formData.append("share_b2c", 1);
          } else {
            formData.append("share_b2c", 0);
          }
          if (this.active == true) {
            formData.append("status", 1);
          } else {
            formData.append("status", 0);
          }

          formData.append(
            "contract_supplier_type",
            this.supplierOptionValue?.id
          );
          formData.append("contract_price_type", this.nightValue?.id);
          formData.append("tax_included", this.tax_included == true ? 1 : 0);
          formData.append("total", this.totalValue);
          formData.append("hotel_room_view_id", this.roomValue?.id);
          formData.append("default_meal_id", this.mealsValue?.meal_type_id);
          formData.append("can_over_room", this.can_over_room == true ? 1 : 0);
          formData.append(
            "can_minus_room",
            this.can_minus_room == true ? 1 : 0
          );

          this.loading = true; // Start loading

          try {
            const contractId = this.$route.params.id;
            const response = await axios.post(
              `/contracts/${contractId}`,
              formData
            );
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.loading = false; //the loading ended

            this.$router.push("/contract");
          } catch (error) {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false; //the loading ended
          }
        }
      }
    },
    async fetchRooms() {
      this.hotelSubmit = true;
      const requestData = {
        hotel_id: this.hotelId,
      };

      if (requestData.hotel_id) {
        try {
          const { data } = await axios.post(
            "fetch_room_view_for_hotel",
            requestData
          );
          this.rooms = data.data;
        } catch (error) {
          Swal.fire({
            title: "",
            text: error.response.data.message || "Unknown error occurred",
            icon: "error",
          });
        }
      }
    },
    async fetchMealsTypes() {
      axios
        .post("/fetch_meal_types")
        .then(({ data }) => {
          this.meals = data.data.data;
          // console.log(this.meals , "meals");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },

  async created() {
    this.fetch_suppliers();
    // this.fetch_all_hotel();
    await this.fetchMealsTypes();
    this.fetch_contract();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_contract.scss";
</style>
