<template>
  <section class="card_style">
    <form @submit.prevent="addService()">
      <div class="row">
        <h4 v-if="!$route.params.id">
          {{ $t("add-hastag-blogs") }}
        </h4>
        <h4 v-else>
          {{ $t("edit-hastag-blogs") }}
        </h4>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group">
            <label>{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.locale }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.title"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";

export default {
  name: "add-service",
  data() {
    return {
      languages: ["ar", "en", "id"],
      fileData: null,
      Form: {
        title: [],
      },
    };
  },
  methods: {
    setBlogTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          locale: language,
          title: "",
        });
      });
    },

    addService() {
      if (this.$route.params.id != undefined) {
        const params = new URLSearchParams();

        this.Form.title.forEach((ele) => {
          params.append(`title_${ele.locale}`, ele.title);
        });

        axios
          .put(`/hashtag_blogs/${this.$route.params.id}`, params)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push("/hastag-blogs");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      } else {
        const formData = new FormData();

        this.Form.title.forEach((ele) => {
          formData.append(`title_${ele.locale}`, ele.title);
        });
        axios
          .post(`/hashtag_blogs`, formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push("/hastag-blogs");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },
  created() {
    this.setBlogTitle();
  },
  mounted() {
    if (this.$route.params.id != undefined) {
      axios.get(`/hashtag_blogs/${this.$route.params.id}`).then((res) => {
        this.Form.title = res.data.data.titles;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}

.btn-stratigy {
  background-color: #0a909b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}

input.form-control {
  padding: 0.8rem !important;
}
.fa-caret-left {
  color: #0a909b;
}
</style>
