<template>
  <ul class="d-flex">
    <li v-for="(rate,index) in rates" :key="index">
        <i class="fa-solid fa-star"></i>
    </li>
    <li v-for="(rate,index) in 5-rates" :key="index">
        <i class="fa-regular fa-star"></i>
    </li>
    <!-- <rate :length="5" :value="starCount" :disabled="true" />
    {{starCount}} -->
  </ul>
</template>

<script>
export default {
    name:"rate-component",
    props:["rates"]
}
</script>

<style scoped>
@import "./_rate.scss";

</style>