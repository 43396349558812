<template>
  <section class="all_emp card_style">
    <div
      class="main_header_style d-flex justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>{{ $t("all_Package_Types") }}</h4>
      </div>

      <router-link to="/addPackage" class="PrimaryButton btn w-25">
        <i class="fa-solid fa-plus"></i> {{ $t("New_package_type") }}
      </router-link>
    </div>

    <div class="row mt-4">
      <div class="col-6">
        <form class="search-form">
          <i class="fa-solid fa-magnifying-glass search-icon"></i>
          <input
            type="text"
            class="form-control"
            :placeholder="$t('search...')"
            v-model="search_package"
            @keyup="fetchAllPackages(1)"
          />
        </form>
      </div>

      <div class="col-lg-3 col-md-6 col-12">
        <Multiselect
          v-model="typesValue"
          :options="types"
          label="title"
          track-by="id"
          :clear-on-select="true"
          :placeholder="$t('types package')"
          select-label=""
          :hide-selected="true"
          @select="handleTypes"
        />
      </div>
    </div>

    <div class="row mt-4">
      <div class="table-responsive">
        <table class="table" id="PackageTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("Title") }}</th>
              <th>{{ $t("type") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>
          <tbody v-if="loading" class="text-center">
            <tr v-for="n in 10" :key="n">
              <td v-for="m in 3" :key="m">
                <Skeleton height="2rem" width="90%" class="mb-2" />
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in allPackages.data" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.title }}</td>
              <td v-if="item.package_type == 1">
                {{ $t("islamic_program") }}
              </td>
              <td v-if="item.package_type == 2">
                {{ $t("Tourism_programs") }}
              </td>
              <td>
                <router-link
                  :to="`/packageType/edit/${item.id}`"
                  class="btn btn-success mx-1 w-auto"
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </router-link>
                <div
                  class="btn btn-danger mx-1 w-auto"
                  @click="deletePackage(index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row mt-4">
      <pagination
        :data="allPackages"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchAllPackages"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
import Multiselect from "vue-multiselect";

export default {
  name: "allPackages",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
    Multiselect,
  },
  data() {
    return {
      allPackages: [],
      search_package: "",
      typesValue: "",
      types: [
        { id: 1, title: this.$t("Tourism_programs") },
        { id: 2, title: this.$t("islamic_program") },
      ],
      loading: true,
    };
  },
  methods: {
    handleTypes() {
      this.fetchAllPackages();
    },
    fetchAllPackages(page = 1) {
      const data = {
        word: this.search_package,
        package_type: this.typesValue.id,
      };
      axios.post(`/fetch_package_types?page=${page}`, data).then(({ data }) => {
        this.allPackages = data.data;
        this.loading = false;
      });
    },
    deletePackage(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.allPackages.data[index].id;
          axios.delete(`/package_types/${delete_id}`).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.allPackages.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.fetchAllPackages();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}

.search-form {
  position: relative;
  .search-icon {
    position: absolute;
    top: 12px;
    right: 15px;
    color: #9ca2ac;
  }
  input {
    padding-right: 40px !important;
  }
}

.table {
  text-align: center;
  thead tr th {
    text-align: center !important;
  }
  tbody tr:nth-child(even) td {
    background-color: #e9e9e95c;
  }
}
</style>
