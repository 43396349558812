<template>
  <div class="invoice">
    <div class="row">
      <div class="col-6">
        <div class="main-text">
          <div class="text-logo">
            <p>{{ pills.id }}</p>
            <h4>AMENDMENT</h4>
          </div>
          <div class="next-text">
            <h5 v-if="pills.hotel_name">{{ pills.hotel_name }}</h5>
            <h6 v-if="pills.created_at_date">
              Created date:
              {{ pills.created_at_date }} -
              {{ pills.created_at_time }}
            </h6>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="logo">
          <img :src="pills.organization?.default_image" />
        </div>
      </div>
    </div>
    <div class="under-text">
      <div class="head">
        <h4>Dear Sir : {{ pills.client_name }}</h4>
        <h5>
          Greeting From <span>{{ pills.organization?.name }}</span>
        </h5>
        <div class="pragra">
          <p>
            First of All, We would like to take this opportunity to welcome you
            at
            <strong> {{ pills.organization?.name }} </strong> and tourism We are
            pleased to confirm the following reservation on a
            <span>{{ book_status_name }}.</span>
          </p>
        </div>
      </div>
    </div>
    <div class="table_account" v-if="pills?.account">
      <div class="list_account">
        <div class="account_data">
          <p class="title_account">Client</p>
          <p class="text_account">: {{ pills.client_name }}</p>
        </div>
        <div class="account_data">
          <p class="title_account">Guest Name</p>
          <p class="text_account">: {{ pills.guest_name }}</p>
        </div>
        <div class="account_data">
          <p class="title_account">Nationality</p>
          <p class="text_account">: {{ pills.nationality_name }}</p>
        </div>
      </div>
      <div class="list_account">
        <div class="account_data">
          <p class="title_account">Check In</p>
          <p class="text_account">
            : {{ pills.check_in_date }},
            {{ updateHijriDate(new Date(pills.check_in_date)) }}
          </p>
        </div>
        <div class="account_data">
          <p class="title_account">Check Out</p>
          <p class="text_account">
            : {{ pills.check_out_date }},
            {{ updateHijriDate(new Date(pills.check_out_date)) }}
          </p>
        </div>
      </div>
      <div class="list_account">
        <div
          class="account_data"
          v-for="(hotel, index) in pills.hotels"
          :key="index"
        >
          <p class="title_account">Hotel Name {{ index + 1 }}</p>
          <p class="text_account hotel_name">: {{ hotel.hotel_name }}</p>
        </div>
      </div>
    </div>
    <!-- room table -->
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="frist">HOTEL</th>
            <th class="frist">ROOM TYPE</th>
            <th scope="col">MEAL</th>
            <th scope="col">PAX</th>
            <th scope="col">CHECK IN</th>
            <th scope="col">CHECK OUT</th>
            <th scope="col">NTS</th>
            <th scope="col">RATE</th>
            <th class="last">TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(room, index) in pills.rooms" :key="index">
            <td>{{ room.hotel_name }}</td>
            <td>
              <p class="mb-0 fs-8">
                {{ room.room_number }} X {{ room.room_name }}
              </p>
              <p class="mb-0 fs-8">{{ room.title_view }}</p>
            </td>
            <td>{{ room.meal_type?.title }}</td>
            <td>{{ room.guest_number }}</td>
            <td>
              <p class="mb-0">
                {{ room.check_in_date }}
              </p>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_in_date))
              }}</span>
            </td>
            <td>
              <P class="mb-0">
                {{ room.check_out_date }}
              </P>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_out_date))
              }}</span>
            </td>
            <td>{{ room.number_of_nights }}</td>
            <td>
              {{
                (
                  room.total_selling_price_room_without_tax /
                  room.number_of_nights
                ).toFixed(2)
              }}
            </td>
            <td class="last">
              {{ room.total_selling_price_room_without_tax }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- room table -->

    <services :service="service" v-if="service.length" />

    <TotalPrice
      :total_selling_price_with_out_tax="total_selling_price_with_out_tax"
      :selling_tax="selling_tax"
      :total_selling_price="total_selling_price"
    />

    <div class="end-page">
      <div class="hed terms" v-if="pills?.Conditions">
        <h4>Terms & Conditions</h4>
        <p class="text" v-html="pills?.Conditions?.description"></p>
      </div>
      <div class="hed terms" v-if="pills?.book_notes">
        <h4>Notes</h4>
        <p class="text" v-html="pills?.book_notes"></p>
      </div>
    </div>
    <div class="lastes">
      <h4>{{ pills.organization?.name }}</h4>
      <div class="d-flex align-items">
        <h4 v-if="pills.organization?.phone" class="mx-2">
          Phone:
          <span
            ><a :href="`tel:${pills.organization?.phone}`">{{
              pills.organization?.phone
            }}</a></span
          >
        </h4>
        <h4 v-if="pills.organization?.email" class="mx-2">
          Email:
          <span
            ><a :href="`mailto:${pills.organization?.email}`">{{
              pills.organization?.email
            }}</a></span
          >
        </h4>
        <h4 v-if="pills.organization?.address">
          Address:
          {{ pills.organization?.address }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import services from "./component/servicesEN.vue";
import TotalPrice from "./component/TotalPriceEn.vue";

export default {
  name: "amendment-invoice",
  props: {
    pills: Object,
    service: {
      type: Array,
      required: true,
      default: () => [],
    },
    terms: String,
    total_selling_price_with_out_tax: String,
    selling_tax: String,
    total_selling_price: String,
    book_status_name: String,
  },
  components: {
    services,
    TotalPrice,
  },
};
</script>

<style lang="scss" scoped>
@import "./_pill.scss";
</style>
