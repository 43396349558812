<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4 class="mb-0">
        {{ $t("Affiliates type") }}
      </h4>
    </div>
    <div class="main_header_style mb-2">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <input
          type="text"
          v-model="affiliateText"
          @keyup="affiliate_categories((page = 1))"
          class="form-control w-50"
          :placeholder="$t('search...')"
        />

        <router-link to="/affiliates/add" class="PrimaryButton mt-0 btn"
          ><i class="fa-solid fa-plus"></i> {{ $t("Add Affiliates type") }}
        </router-link>
      </div>
    </div>

    <!-- <div class="search-visa my-3">
        <form>
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            class="form-control"
            :placeholder="$t('search...')"
          />
        </form>
      </div> -->

    <div class="row">
      <table class="table" id="VisaTable">
        <thead>
          <tr>
            <th>ID</th>
            <th class="text-center">{{ $t("name") }}</th>
            <th class="text-center">{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="affiliate in 10" :key="affiliate">
            <td v-for="affiliate in 3" :key="affiliate">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(affiliate, index) in affiliates.data" :key="index">
            <td>{{ affiliate.id }}</td>
            <td class="text-center">{{ affiliate.title }}</td>
            <td
              class="text-center d-flex align-items-center justify-content-center"
            >
              <button
                @click="this.$router.push(`/affiliates/edit/${affiliate.id}`)"
                class="btn btn-success mx-1 w-auto"
              >
                <i class="fa-regular fa-edit"></i>
              </button>
              <button
                @click="deleteAffiliate(affiliate.id, index)"
                class="btn btn-danger mx-1 w-auto"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="affiliates"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="affiliate_categories"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
// import $ from "jquery";
export default {
  name: "allVisa",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      affiliates: [],
      affiliateText: "",
      loading: true,
    };
  },
  methods: {
    affiliate_categories(page = 1) {
      axios
        .post(`/index_affiliate_categories?page=${page}`, {
          word: this.affiliateText,
        })
        .then(({ data }) => {
          this.affiliates = data.data;
          this.loading = false;
          // console.log(this.affiliates);
        });
    },
    //delete visa
    deleteAffiliate(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.affiliates.id;
          const fromClick = true;
          axios
            .delete(`/affiliate_categories/${id}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire({
                  title: "",
                  text: this.$t("save_successfuly"),
                  icon: "success",
                });
                this.affiliates.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  created() {
    this.affiliate_categories();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
.text-center {
  text-align: center !important;
}
</style>
