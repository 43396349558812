<!-- i need to sum all the data of selling and costing price and send it as props from this child component to parent fix my code to match my require -->
<template>
  <!-- {{ fetched_data[0].price[0].car_type.max_number }} -->
  <!-- {{ transData.car_route }} -->
  <div class="Transformation_seeting">
    <div class="container">
      <div class="row border">
        <div class="col-lg-8 col-md-8 col-6">
          <div class="d-flex align-items-center justify-content-between p-2">
            <div class="d-flex align-items-center justify-content-center mx-1">
              <img src="@/assets/media/image/icon/car.svg" />
              <h6 class="hotel_settings m-0">
                {{ $t("Transportation settings") }}
              </h6>
            </div>
            <input
              type="number"
              class="form-control flight_num"
              :placeholder="$t('Enter the number of routes')"
              v-model="number_of_routes"
              min="0"
            />
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-6 border-start border-end bg-price d-flex align-items-center justify-content-center"
        >
          <div class="d-flex align-items-center justify-content-start p-2">
            <div class="d-flex align-items-center justify-content-center mx-1">
              <img src="@/assets/media/image/icon/moneys.svg" />
              <h6 class="hotel_settings m-0">{{ $t("prices") }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row border-start border-end mb-2"
        v-for="(item, index) in transData"
        :key="index"
      >
        <div class="col-12 trip d-flex align-items-center">
          <img src="@/assets/media/image/icon/car2.svg" />
          <p class="first_diriction mx-1 mb-0">{{ index + 1 }}</p>
        </div>

        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6">
              <label class="typo__label custom_label">{{
                $t("itinerary")
              }}</label>
              <Multiselect
                v-model="item.car_route"
                :options="car_routes"
                track-by="id"
                label="text"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
                @update:model-value="fetch_car_types(index)"
              ></Multiselect>
            </div>

            <div class="col-lg-6" v-if="item.is_contract == true">
              <label class="typo__label custom_label">{{
                $t("contract")
              }}</label>
              <Multiselect
                v-model="item.contract"
                :options="item.contracts"
                label="titles"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
                @update:model-value="fetch_rate_codes(index)"
              ></Multiselect>
            </div>

            <div class="col-lg-6" v-if="item.is_contract == true">
              <label class="typo__label custom_label">{{
                $t("Rate Code")
              }}</label>
              <Multiselect
                v-model="item.rate_code"
                :options="item.rate_codes"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
                @update:model-value="fetchCars(index)"
              ></Multiselect>
              <div
                v-for="error in v$.transData.$each.$response.$errors[index]
                  .rate_code"
                :key="error"
                class="input-errors"
              >
                {{ error.$message }}
              </div>
            </div>
            <div class="col-lg-6">
              <label class="typo__label custom_label">{{
                $t("choose cars")
              }}</label>
              <Multiselect
                v-model="item.selected_cars"
                :options="item.cars"
                track-by="id"
                label="title"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
                :multiple="true"
              ></Multiselect>
              <div
                v-for="error in v$.transData.$each.$response.$errors[index]
                  .selected_cars"
                :key="error"
                class="input-errors"
              >
                {{ error.$message }}
              </div>
            </div>
            <div class="col-lg-6">
              <label class="typo__label custom_label">{{
                $t("Supplier")
              }}</label>
              <Multiselect
                v-model="item.supplierObj"
                :options="providers"
                label="name"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
              ></Multiselect>
              <div
                v-for="error in v$.transData.$each.$response.$errors[index]
                  .supplierObj"
                :key="error"
                class="input-errors"
              >
                {{ error.$message }}
              </div>
            </div>
            <div class="col-lg-6 d-flex align-items-center">
              <div class="form-group d-flex align-items-center gap-2">
                <label for="person">{{
                  item.is_person ? $t("by_person") : $t("by_car")
                }}</label>
                <InputSwitch v-model="item.is_person" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div
            class="row"
            v-for="(car, carIndex) in item.selected_cars"
            :key="carIndex"
          >
            <div class="d-flex align-items-center justify-content-start">
              <span class="mt-4 mx-1 font-size-text b-block">{{
                car.title
              }}</span>
              <div class="mx-2 w-50">
                <label class="typo__label custom_label m-0">{{
                  $t("cost")
                }}</label>
                <input
                  type="number"
                  class="multiselect__tags form-control text-end p-2 w-100"
                  placeholder="0"
                  v-model="car.cost"
                />
              </div>
              <div class="mx-2 w-50">
                <label class="typo__label custom_label m-0">{{
                  $t("selling")
                }}</label>
                <input
                  type="number"
                  class="multiselect__tags form-control text-end p-2 w-100"
                  placeholder="0"
                  v-model="car.selling"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Multiselect } from "vue-multiselect";
import Swal from "sweetalert2";
import axios from "axios";
import InputSwitch from "primevue/inputswitch";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
export default {
  name: "hotelSettings",
  props: ["dateStart", "dateEnd", "fetched_data", "providers"],
  emits: ["sellingTrans", "costTrans", "saveTransData"],
  data() {
    return {
      number_of_routes: 1,
      car_routes: [],
      transData: [
        {
          car_route: {},
          contracts: [],
          contract: {},
          rate_codes: [],
          rate_code: {},
          is_person: true,
          cars: [],
          selected_cars: [],
          is_contract: false,
          supplierObj: {},
        },
      ],
      v$: useVuelidate(),
      // item: {
      //   car_route: {},
      //   contracts: [],
      //   contract: {},
      //   rate_codes: [],
      //   rate_code: {},
      //   is_person: true,
      //   cars: [],
      //   selected_cars: [],
      //   is_contract: false,
      //   supplierObj: {},
      // },
      // item: {
      //   selected_cars: [
      //     // Your array of cars
      //   ],
      // },
    };
  },
  components: {
    Multiselect,
    InputSwitch,
  },
  validations() {
    return {
      transData: {
        $each: helpers.forEach({
          car_route: { required },
          selected_cars: { required },
        }),
      },
    };
  },
  methods: {
    fetch_car_routes() {
      axios
        .post(`/fetch_car_routes`)
        .then(({ data }) => {
          this.car_routes = data.data.data;
        })
        .catch((error) => {
          // console.log(error)
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    fetch_contracts(index) {
      if (this.transData[index].is_contract == true) {
        axios
          .post(`/fetch_transpotation_contracts_by_car_route_id`, {
            car_route_id: this.transData[index].car_route.id,
            from_date: this.dateStart,
            to_date: this.dateEnd,
          })
          .then(({ data }) => {
            this.transData[index].contracts = data.data;
          })
          .catch((error) => {
            // console.log(error)
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
    fetch_rate_codes(index) {
      axios
        .post(`/fetch_transpotation_rate_codes_by_transportation_contract_id`, {
          transportation_contract_id: this.transData[index].contract.id,
          from_date: this.dateStart,
          to_date: this.dateEnd,
        })
        .then(({ data }) => {
          this.transData[index].rate_codes = data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    fetchCars(index) {
      axios
        .post(`/fetch_transpotation_rate_code_cars`, {
          transpotation_rate_code_id: this.transData[index].rate_code.id,
          from_date: this.dateStart,
          to_date: this.dateEnd,
        })
        .then(({ data }) => {
          this.transData[index].cars = data.data;
        })
        .catch((error) => {
          // console.log(error)
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    fetch_car_types(index) {
      this.transData[index].is_contract = false;
      this.transData[index].selected_cars = [];
      this.transData[index].is_person = true;

      axios.post(`/fetch_car_types`).then(({ data }) => {
        // console.log(data)
        this.transData[index].cars = data.data.data.map((car) => {
          return {
            ...car,
            cost: 0,
            selling: 0,
          };
        });
      });
    },
  },
  watch: {
    transData: {
      deep: true,
      immediate: true,
      handler(value) {
        let transportationData = value.map((ele) => {
          console.log(ele, "eqeee");
          return {
            route_id: ele.car_route.id,
            contract_id: ele.is_contract ? ele.contract.id : null,
            rate_code_id: ele.is_contract ? ele.rate_code.id : null,
            supplier_id: ele.supplierObj?.id,
            is_person: ele.is_person ? 1 : 0,
            cars: ele.selected_cars.map((car) => {
              return {
                car_type_id: car.id,
                cost: car.cost,
                selling: car.selling,
                max_number: car?.max_number, // Make sure to include max_number here
              };
            }),
          };
        });

        console.log(value, "transportationData");
        let costTrans = transportationData.reduce((acc, route) => {
          return (
            acc +
            route.cars.reduce((carAcc, car) => {
              return (
                carAcc +
                (route.is_person
                  ? parseFloat(car.cost || 0)
                  : parseFloat(car.cost || 0) / parseFloat(car.max_number || 1))
              );
            }, 0)
          );
        }, 0);

        let sellingTrans = transportationData.reduce((acc, route) => {
          return (
            acc +
            route.cars.reduce((carAcc, car) => {
              return (
                carAcc +
                (route.is_person
                  ? parseFloat(car.selling || 0)
                  : parseFloat(car.selling || 0) /
                    parseFloat(car.max_number || 1))
              );
            }, 0)
          );
        }, 0);

        console.log(costTrans, sellingTrans);
        this.$emit("sellingTrans", sellingTrans);
        this.$emit("costTrans", costTrans);
        this.$emit("saveTransData", transportationData);
      },
    },
    number_of_routes(value) {
      // Adjust the length of transData based on value

      // If value is greater than the current length, add objects
      while (this.transData.length < value) {
        this.transData.push({
          car_route: {},
          contracts: [],
          contract: {},
          rate_codes: [],
          rate_code: {},
          cars: [],
          selected_cars: [],
          is_contract: false,
          is_person: false, // Add default value for is_person
        });
      }

      // If value is less than the current length, remove objects
      while (this.transData.length > value) {
        this.transData.pop();
      }
    },
  },
  mounted() {
    this.fetch_car_routes();
    console.log(this.fetched_data, "fetched_data");

    if (this.$route.params.id && this.fetched_data.length > 0) {
      this.transData = this.fetched_data;
      this.number_of_routes = this.transData.length;
      this.fetched_data.forEach((ele) => {
        this.transData.map((trans) => {
          if (trans.id == ele.id) {
            trans.car_route = ele.car_route;
            trans.selected_cars = ele.cars.map((car) => {
              return {
                ...car,
                ...car.car_type,
                ...car.car_type?.max_number,
                cost: car.cost,
                selling: car.selling,
                number_of_routes: car.car_type?.max_number,
              };
            });
            trans.is_contract = ele.is_contract;
            trans.is_person = ele.is_person === 1 ? true : false;
            trans.contract = ele.contract;
            trans.rate_code = ele.rate_code;
            trans.supplierObj = ele.supplier;
          }
        });
        // console.log(ele, "fetched_data");
        console.log(this.transData, "this.transData");
        // this.transData[0].car_route = ele.car_route;
      });
    }
  },
  updated() {},
};
</script>

<style lang="scss" scoped>
.row {
  margin: 0;
}

.Transformation_seeting {
  background-color: #fff;

  .title {
    font-family: "bold";
    color: #0b1427;
    font-size: 1.25rem;
  }

  .flight_num {
    color: #656b78;
    font-family: "regular";
    font-size: 0.8rem;
  }

  .flight-details {
    color: #0b1427;
    font-size: 1.2rem;
    font-family: "regular";
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-check-input {
    margin: 0;
    float: none;
    border-color: #656b78;
    width: min-content;
  }

  .form-check-input:checked {
    margin: 0;
    float: none;
    border-color: #0a909b;
    background-color: #0a909b;
  }

  .form-check-label {
    color: #656b78;
    font-family: "bold";
    font-size: 1rem;
  }

  .hotel_settings {
    font-family: "bold";
    color: #0b1427;
    font-size: 1rem;
  }

  .subtitle-hotel-settings {
    color: #0b1427;
    font-size: 1rem;
  }

  .custom_label {
    font-size: 0.6875rem;
    font-family: "bold";
    color: #0b1427;
    position: relative;
    z-index: 22;
    // bottom: 5.5rem; //4.2rem
    top: 0.675rem;
    background: white;
    border-radius: 50%;
    text-align: center;
    color: #656b78;
    margin: 0 0.5rem;
  }

  .first_diriction {
    font-size: 1rem;
    font-family: "bold";
    color: #0b1427;
  }

  .form-check-input {
    margin: 0;
    float: none;
    border-color: #656b78;
  }

  .form-check-input:checked {
    margin: 0;
    float: none;
    border-color: #0a909b;
    background-color: #0a909b;
  }

  .form-check-label {
    color: #656b78;
    font-family: "bold";
    font-size: 1rem;
  }
}

.bg-wheate {
  background-color: #fdfbf8;
}

.bg-light-blue {
  background-color: rgba($color: #1664b8, $alpha: 3%);
}

.bg-price,
.adult {
  background-color: rgba($color: #0a909b, $alpha: 10%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.gap-4 {
  gap: 4.5rem !important;
}

.time {
  color: #0b1427;
  font-family: "bold";
  font-size: 1rem;
}

.number-available {
  border: 1px solid gray;
  width: fit-content;
  padding: 0 0.8rem;
  border-radius: 20px;
}

.live {
  color: #0a909b;
  font-family: "bold";
  font-size: 0.875rem;
}

// input[type="number"]::-webkit-outer-spin-button,
// input[type="number"]::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

input[type="number"] {
  width: 50px;
}

span {
  font-size: 0.875rem;
}

.Transformation_seeting .form-check-input {
  width: 17px;
  height: 17px;
}

.font-size-text {
  font-size: 0.7rem;
}
.trip {
  img {
    width: 15px;
  }
  p {
    font-size: 1rem;
    font-family: "bold";
    color: #0b1427;
  }
}
</style>
