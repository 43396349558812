<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="main_header_style">
        <div class="d-flex align-items-center gap-3">
          <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
          <h4>{{ $t("premisions") }} {{ $t("hotels") }}</h4>
        </div>
      </div>
      <!-- {{ peremisionShow.length > 0 }} length
      <br />
      {{ peremisionShow }} aray -->

      <button
        v-if="allChecked"
        class="btn PrimaryButton"
        @click="cabncelcheckAll"
      >
        {{ $t("Cancel") }}
      </button>
      <button v-else class="btn PrimaryButton" @click="checkAll">
        {{ $t("All_check") }}
      </button>
    </div>
    <div class="row">
      <table class="table" id="VisaTable">
        <thead>
          <tr>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("show") }}</th>
            <th>{{ $t("add") }}</th>
            <th>{{ $t("edit") }}</th>
            <th>{{ $t("delete") }}</th>
          </tr>
        </thead>
        <tbody v-if="loading" class="text-center">
          <tr v-for="Hotel in roles.Hotels" :key="Hotel">
            <td>
              <Skeleton width="90%" height="2rem"></Skeleton>
            </td>
            <td v-for="Hotel in 4" :key="Hotel">
              <Skeleton size="2rem" class="mr-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(role, index) in roles.Hotels" :key="index">
              <td>
              {{ $t(`${role.name ? role.name : "-"}`) }}
            </td>
            <td>
              <input
                class="form-check-input"
                type="checkbox"
                :id="'show_' + role.name"
                :checked="role.show.checked"
                @change="handleCheckboxChange(role, 'show')"
                v-if="role.show"
              />
              <!-- ff
              {{ role.show.checked }}
              ff -->
            </td>
            <td>
              <input
                class="form-check-input"
                type="checkbox"
                :id="'add_' + role.name"
                :checked="role.add.checked"
                @change="handleCheckboxChange(role, 'add')"
                v-if="role.add"
              />
            </td>
            <td>
              <input
                class="form-check-input"
                type="checkbox"
                :id="'edit_' + role.name"
                :checked="role.edit.checked"
                @change="handleCheckboxChange(role, 'edit')"
                v-if="role.edit"
              />
            </td>
            <td>
              <input
                class="form-check-input"
                type="checkbox"
                :id="'delete_' + role.name"
                :checked="role.delete.checked"
                @change="handleCheckboxChange(role, 'delete')"
                v-if="role.delete"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import roles from "./roles.json";

import Skeleton from "primevue/skeleton";

export default {
  name: "hotels-permission",
  props: ["peremisionShow"],
  emits: ["sendValues"],
  components: {
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      roles: roles,
      actions: [], // Store routes in an array
      methods: [], // Store methods in an array
      loading: true,
      // createdHookCalled: false,
    };
  },
  methods: {
    handleCheckboxChange(role, action) {
      if (this.peremisionShow.includes(role[action].id)) {
        role[action].checked = !role[action].checked;
      } else if (
        this.peremisionShow.includes(role[action].id) === false &&
        role[action]
      ) {
        role[action].checked = !role[action].checked;
      }
    },

    sendValues() {
      let valuesToSend = [];

      // Iterate through roles
      this.roles.Hotels.forEach((role) => {
        const showValue = role.show
          ? role.show.checked
            ? role.show.id
            : 0
          : 0;
        const addValue = role.add ? (role.add.checked ? role.add.id : 0) : 0;

        const editValue = role.edit
          ? role.edit.checked
            ? role.edit.id
            : 0
          : 0;
        const deleteValue = role.delete
          ? role.delete.checked
            ? role.delete.id
            : 0
          : 0;

        const showMethod = role.show ? role.show.methods : "-";
        const addMethod = role.add ? role.add.methods : "-";
        const editMethod = role.edit ? role.edit.methods : "-";
        const deleteMethod = role.delete ? role.delete.methods : "-";

        const showRoute = role.show ? role.show.route : "-";
        const addRoute = role.add ? role.add.route : "-";
        const editRoute = role.edit ? role.edit.route : "-";
        const deleteRoute = role.delete ? role.delete.route : "-";

        if (role.show ? role.show.checked : false) {
          valuesToSend.push({
            id: showValue,
            method: showMethod,
            route: showRoute,
          });
        }

        if (role.add ? role.add.checked : false) {
          valuesToSend.push({
            id: addValue,
            method: addMethod,
            route: addRoute,
          });
        }

        if (role.edit ? role.edit.checked : false) {
          valuesToSend.push({
            id: editValue,
            method: editMethod,
            route: editRoute,
          });
        }

        if (role.delete ? role.delete.checked : false) {
          valuesToSend.push({
            id: deleteValue,
            method: deleteMethod,
            route: deleteRoute,
          });
        }
      });

      // Output the valuesToSend array
      this.$emit("sendValues", valuesToSend);
    },
    checkAll() {
      // Loop through all roles and set all checkboxes to checked
      this.roles.Hotels.forEach((role) => {
        if (role.show) role.show.checked = true;
        if (role.add) role.add.checked = true;
        if (role.edit) role.edit.checked = true;
        if (role.delete) role.delete.checked = true;
      });
      // Trigger sendValues method to update the values
      // this.sendValues();
    },
    cabncelcheckAll() {
      // Loop through all roles and set all checkboxes to checked
      this.roles.Hotels.forEach((role) => {
        if (role.show) role.show.checked = false;
        if (role.add) role.add.checked = false;
        if (role.edit) role.edit.checked = false;
        if (role.delete) role.delete.checked = false;
      });
      // Trigger sendValues method to update the values
      // this.sendValues();
    },
  },
  mounted() {
    setTimeout(() => {
      this.roles.Hotels.forEach((role) => {
        if (this.peremisionShow.includes(role.show?.id)) {
          role.show.checked = true;
          this.sendValues();
        } else if (
          this.peremisionShow.includes(role.show?.id) === false &&
          role.show
        ) {
          role.show.checked = false;
          this.sendValues();
        }
        if (this.peremisionShow.includes(role.add?.id)) {
          role.add.checked = true;
          this.sendValues();
        } else if (
          this.peremisionShow.includes(role.add?.id) === false &&
          role.add
        ) {
          role.add.checked = false;
          this.sendValues();
        }
        if (this.peremisionShow.includes(role.edit?.id)) {
          role.edit.checked = true;
          this.sendValues();
        } else if (
          this.peremisionShow.includes(role.edit?.id) === false &&
          role.edit
        ) {
          role.edit.checked = false;
          this.sendValues();
        }

        if (this.peremisionShow.includes(role.delete?.id)) {
          role.delete.checked = true;
          this.sendValues();
        } else if (
          this.peremisionShow.includes(role.delete?.id) === false &&
          role.delete
        ) {
          role.delete.checked = false;
          this.sendValues();
        }
      });
      // Trigger sendValues method to update the values
      this.sendValues();
      this.loading = false;
    }, 3000);
  },

  updated() {
    // Trigger sendValues method to update the values

    this.sendValues();
  },
  computed: {
    allChecked() {
      // Check if all checkboxes are in the "show" state
      return this.roles.Hotels.every((role) => {
        if (role.show && !role.show.checked) return false;
        if (role.add && !role.add.checked) return false;
        if (role.edit && !role.edit.checked) return false;
        if (role.delete && !role.delete.checked) return false;
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
.text-center {
  text-align: center !important;
}
</style>
