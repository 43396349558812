<template>
  <!-- <div class="main-head">
    <h4>{{ $t("common_awnaser") }}</h4>
  </div> -->
  <!-- taps -->
  <div class="d-flex align-items-start">
    <div
      class="nav flex-column nav-pills"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      <button
        class="nav-link active"
        id="v-pills-messages-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-messages"
        type="button"
        role="tab"
        aria-controls="v-pills-messages"
        aria-selected="false"
      >
        {{ $t("reservation_detials") }}
      </button>
    </div>
    <div class="tab-content" id="v-pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="v-pills-messages"
        role="tabpanel"
        aria-labelledby="v-pills-messages-tab"
      >
        <div class="reservation_details">
          <!-- rooms table -->
          <h5 class="title mt-3">{{ $t("rooms") }}</h5>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{ $t("title_room") }}</th>
                <th>{{ $t("meal") }}</th>
                <th>{{ $t("count") }}</th>
                <th>{{ $t("price") }} ({{ $t("on_night") }})</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(room, index) in hotelsDetials?.rooms" :key="index">
                <td>{{ room?.title }}</td>
                <td>{{ room?.count }}</td>
                <td>{{ room?.description }}</td>
                <td>{{ room?.selling_price }} USD</td>
              </tr>
            </tbody>
          </table>
          <!-- rooms table -->

          <!-- guests table -->
          <h5 class="title mt-5">{{ $t("guests") }}</h5>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{ $t("first name") }}</th>
                <th>{{ $t("last name") }}</th>
                <th>{{ $t("nationality") }}</th>
                <th>{{ $t("nickname") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(guest, index) in hotelsDetials.guests" :key="index">
                <td class="px-1">{{ guest.first_name }}</td>
                <td class="px-1">{{ guest.last_name }}</td>
                <td class="px-1">{{ guest.nationality.nationality_title }}</td>
                <td class="px-1">
                  {{ guest.title ? guest.title : "-" }}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- guests table -->
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="v-pills-home"
        role="tabpanel"
        aria-labelledby="v-pills-home-tab"
      >
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                هل يمكنني الغاء الحجز
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                نعم. أي رسوم تترتب على عملية الإلغاء يتم تحديدها من قبل مكان
                الإقامة وتظهر أيضاً في سياسة الإلغاء الخاصة بك. سيتم دفع أي رسوم
                إضافية لمكان الإقامة.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                هل سأدفع رسوم إذا اضطررت لإلغاء حجزي؟
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <strong>This is the second item's accordion body.</strong>
                It is hidden by default, until the collapse plugin adds the
                appropriate classes that we use to style each element. These
                classes control the overall appearance, as well as the showing
                and hiding via CSS transitions. You can modify any of this with
                custom CSS or overriding our default variables. It's also worth
                noting that just about any HTML can go within the
                <code>.accordion-body</code>, though the transition does limit
                overflow.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                هل يمكنني إلغاء حجز غير قابل للاسترداد أو تعديل تواريخه؟
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <strong>This is the third item's accordion body.</strong>
                It is hidden by default, until the collapse plugin adds the
                appropriate classes that we use to style each element. These
                classes control the overall appearance, as well as the showing
                and hiding via CSS transitions. You can modify any of this with
                custom CSS or overriding our default variables. It's also worth
                noting that just about any HTML can go within the
                <code>.accordion-body</code>, though the transition does limit
                overflow.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingfour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsefour"
                aria-expanded="false"
                aria-controls="collapsefour"
              >
                أين يمكنني العثور على سياسة الإلغاء لمكان الإقامة الذي اخترته؟
              </button>
            </h2>
            <div
              id="collapsefour"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <strong>This is the third item's accordion body.</strong>
                It is hidden by default, until the collapse plugin adds the
                appropriate classes that we use to style each element. These
                classes control the overall appearance, as well as the showing
                and hiding via CSS transitions. You can modify any of this with
                custom CSS or overriding our default variables. It's also worth
                noting that just about any HTML can go within the
                <code>.accordion-body</code>, though the transition does limit
                overflow.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- taps -->
</template>

<script>
export default {
  name: "hotelAnswer",
  props: { hotelsDetials: Object },
  data() {
    return {
      numberOfNights: 0,
    };
  },
  methods: {
    calculateNumberOfNights() {
      const checkInDate = new Date(this.hotelsDetials.check_in);
      const checkOutDate = new Date(this.hotelsDetials.check_out);

      // Calculate the time difference in milliseconds
      const timeDiff = checkOutDate.getTime() - checkInDate.getTime();

      // Calculate the number of nights
      const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));

      // Update the numberOfNights data property
      this.numberOfNights = nights;
    },
  },
  mounted() {
    this.calculateNumberOfNights();
  },
};
</script>

<style lang="scss" scoped>
@import "./_hotelAnswer.scss";
</style>
