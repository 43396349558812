<template>
  <div class="data_invoice_person">
    <div class="list_account">
      <div class="account_data">
        <p class="title_account">اسم العميل</p>
        <p class="text_account">: {{ pills.client_name }}</p>
      </div>
      <div class="account_data" v-if="pills?.client_tax_number">
        <p class="title_account">الرقم الضريبي للشركة</p>
        <p class="text_account">: {{ pills.client_tax_number }}</p>
      </div>
      <div class="account_data">
        <p class="title_account">رقم الهاتف</p>
        <a :href="`tel:${pills.client_phone}`" class="text_account"
          >: {{ pills.client_phone }}</a
        >
      </div>
      <div class="account_data">
        <p class="title_account">البريد الإلكتروني</p>
        <a :href="`mailto:${pills.client_email}`" class="text_account"
          >: {{ pills.client_email }}</a
        >
      </div>
      <div class="account_data">
        <p class="title_account">اسم النزيل</p>
        <p class="text_account">: {{ pills.guest_name }}</p>
      </div>
      <div class="account_data">
        <p class="title_account">الحنسية</p>
        <p class="text_account">: {{ pills.nationality_name }}</p>
      </div>
    </div>
    <div class="list_account">
      <div class="account_data" v-if="pills.client_country">
        <p class="title_account">عنوان العميل</p>
        <p class="text_account">
          : {{ pills.client_country }} - {{ pills.client_address }}
        </p>
      </div>
      <div
        class="account_data"
        v-for="(hotel, index) in pills.hotels"
        :key="index"
      >
        <p class="title_account">اسم الفندق {{ index + 1 }}</p>
        <p class="text_account hotel_name">: {{ hotel.hotel_name }}</p>
      </div>
      <div class="account_data">
        <p class="title_account">تاريخ الدخول</p>
        <p class="text_account">
          : {{ pills.check_in_date }}, {{ updateHijriDate(pills?.check_in_date ? new Date(pills?.check_in_date) : new Date()) }}
        </p>
      </div>
      <div class="account_data">
        <p class="title_account">تاريخ الخروج</p>
        <p class="text_account">
          : {{ pills.check_out_date }}, {{ updateHijriDate(pills?.check_out_date ? new Date(pills?.check_out_date) : new Date()) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pills: Object,
  },
};
</script>

<style lang="scss" scoped>
@import "../_pill.scss";
</style>
