<template>
  <section class="card_style">
    <!-- skeelton -->
    <div class="w-100" v-if="loading && $route.params.id">
      <div class="row">
        <div class="col-12">
          <Skeleton width="20%" class="mb-2"></Skeleton>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <Skeleton width="20%" class="mb-2"></Skeleton>
        </div>
        <div class="col-lg-6 col-md-6 col-12 my-3" v-for="i in 2" :key="i">
          <Skeleton width="20%" class="mb-2"></Skeleton>
          <Skeleton width="80%" height="2rem" class="mb-2"></Skeleton>
        </div>
        <div class="col-12 my-3">
          <Skeleton width="20%" class="mb-2"></Skeleton>
          <Skeleton width="80%" height="2rem" class="mb-2"></Skeleton>
        </div>
        <div class="col-lg-6 col-md-6 col-12 my-3" v-for="i in 2" :key="i">
          <Skeleton width="20%" class="mb-2"></Skeleton>
          <Skeleton width="80%" height="2rem" class="mb-2"></Skeleton>
        </div>
      </div>
    </div>
    <!-- skeelton -->
    <form class="form_topic" @submit.prevent="editAccount()" v-else>
      <div class="main_header_style">
        <h4>{{ $t("Edit Bank Account") }}</h4>
      </div>
      <div class="row">
        <div class="col-12 mb-4 account-status">
          <h4>{{ $t("Account Status") }}</h4>
          <form class="row">
            <div class="form-check col-3 d-flex align-items-center">
              <input
                class="form-check-input mx-3"
                type="radio"
                name="status"
                v-model="active"
                value="1"
                id="active"
              />
              <label class="form-check-label" for="active">
                {{ $t("Active") }}
              </label>
            </div>
            <div class="form-check col-3 d-flex align-items-center">
              <input
                class="form-check-input mx-3"
                type="radio"
                name="status"
                v-model="active"
                value="0"
                id="not-active"
              />
              <label class="form-check-label" for="not-active">
                {{ $t("Not Active") }}
              </label>
            </div>
          </form>
        </div>

        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("Bank Account Name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('Write Bank Account Name')"
              v-model="title"
              required
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("Bank Account Number") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('Write Bank Account Number')"
              v-model="account_number"
              required
            />
          </div>
        </div>

        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label">
              {{ $t("Bank Account Description") }}</label
            >
            <textarea
              type="text"
              class="form-control"
              :placeholder="$t('Write Bank Account description')"
              v-model="text"
              required
            ></textarea>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label">Swift Code</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('Write') + ' Swift Code'"
              v-model="swift_code"
              required
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label">IBAN CODE</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('Write') + ' IBAN CODE'"
              v-model="iban"
              required
            />
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              value="1"
              id="online_bank"
              v-model="online_bank"
            />
            <label class="form-check-label" for="online_bank">
              {{ $t("online_bank") }}
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              value="0"
              id="offline_bank"
              v-model="online_bank"
            />
            <label class="form-check-label" for="offline_bank">
              {{ $t("offline_bank") }}
            </label>
          </div>
        </div>

        <!-- <div class="col-12 col-lg-6">
                <div class="input-group">
                    <label class="form-label"> {{ $t("Initial Balance") }}</label>
                    <input
                    type="number"
                    class="form-control initial-balance"
                    :placeholder="$t('Initial Balance')"
                    v-model="initialBalance"
                    required
                    />
                </div>
            </div> -->

        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("Edit") }}
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  name: "editAccount",
  data() {
    return {
      title: "",
      text: "",
      active: "",
      money: "",
      account_number: "",
      iban: "",
      swift_code: "",
      type: 1,
      online_bank: 1,
      loading: true,
    };
  },
  components: {
    Skeleton,
  },
  methods: {
    fetchAccountData() {
      let id = this.$route.params.id;
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get(`/organization_accounts/${id}`).then(({ data }) => {
        this.title = data.data.title;
        this.text = data.data.text;
        this.active = data.data.active;
        this.money = data.data.money;
        this.account_number = data.data.account_number;
        this.iban = data.data.iban;
        this.swift_code = data.data.swift_code;
        this.online_bank = data.data.is_online;
        this.loading = false;
        // this.branch = data.data.branch;
        // console.log(data.data);
      });
    },
    async editAccount() {
      let id = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("title", this.title);
      params.append("text", this.text);
      params.append("active", this.active);
      params.append("money", this.money);
      params.append("account_number", this.account_number);
      params.append("iban", this.iban);
      params.append("swift_code", this.swift_code);
      params.append("type", this.type);
      params.append("is_online", this.online_bank);
      // params.append("branch", this.branch);
      axios
        .put(`/organization_accounts/${id}`, params)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/account");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    this.fetchAccountData();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style lang="scss" scoped>
@import "./_accounts";
</style>
