<template>
  <section class="all_emp card_style">
    <div class="">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>
          {{ $t("revenue_details") }}
        </h4>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 mb-2">
          <input
            type="text"
            v-model="revenuesText"
            @keyup="handleGetPackageRevenues((page = 1))"
            class="form-control"
            :placeholder="$t('search...')"
          />
        </div>
      </div>
      <div class="row">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>{{ $t("package_name") }}</th>
                <th>{{ $t("reservation_date") }}</th>
                <th>{{ $t("cost") }}</th>
                <th>{{ $t("selling") }}</th>
                <th>{{ $t("revenues") }}</th>
              </tr>
            </thead>

            <tbody v-if="loading" class="text-center">
              <tr v-for="revenue in 10" :key="revenue">
                <td v-for="revenue in 6" :key="revenue">
                  <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
                </td>
              </tr>
            </tbody>

            <tbody v-else>
              <tr v-for="(revenue, index) in revenues.data" :key="index">
                <td>
                  <router-link :to="`/umrah/book_details/${revenue.id}`">
                    {{ revenue.id }}
                  </router-link>
                </td>
                <td>{{ revenue.title || "---" }}</td>
                <td>{{ revenue.created_at || "---" }}</td>
                <td>{{ revenue.cost || "---" }}</td>
                <td>{{ revenue.selling || "---" }}</td>
                <td>
                  {{ revenue.profit || "---" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <pagination
          :data="revenues"
          class="mx-auto d-flex align-items-center justify-content-center pagination"
          @pagination-change-page="handleGetPackageRevenues"
        >
          <template v-slot:prev-nav>&lt;</template>
          <template v-slot:next-nav>&gt;</template>
        </pagination>
      </div>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import Skeleton from "primevue/skeleton";
import pagination from "laravel-vue-pagination";

export default {
  name: "packageRevenuesTable",

  data() {
    return {
      revenues: [],
      loading: true,
      revenuesText: "",
    };
  },
  methods: {
    handleGetPackageRevenues(page = 1) {
      axios
        .post(`/package_revenues?page=${page}`, { word: this.revenuesText })
        .then(({ data }) => {
          this.revenues = data.data;
          // console.log(this.revenues);
          this.loading = false;
        });
    },
  },
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  mounted() {
    this.handleGetPackageRevenues();
  },
};
</script>
