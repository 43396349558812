<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center gap-2 mb-3">
      <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />

      <h4>{{ $t("The Rooms") }}</h4>
    </div>

    <div class="row options mb-5">
      <form class="col-6">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          :placeholder="$t('search by name')"
          class="form-control"
          v-model="titleRoom"
          @keyup="FetchRooms"
        />
      </form>

      <div class="col-6 d-flex justify-content-end">
        <router-link :to="{ name: 'addRooms' }" class="PrimaryButton btn w-50"
          ><i class="fa-solid fa-plus"></i> {{ $t("New Room") }}</router-link
        >
      </div>
    </div>

    <div class="row">
      <table class="table" id="RoomTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("title") }}</th>
            <th>{{ $t("Adults") }}</th>
            <th>{{ $t("childrens") }}</th>
            <th>{{ $t("type") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(room, index) in rooms" :key="index">
            <td>{{ room.id }}</td>
            <td>{{ room.title }}</td>
            <td>{{ room.number_of_adults }}</td>
            <td>{{ room.number_of_children }}</td>
            <td>{{ $t(room.service_type) }}</td>
            <td>
              <router-link
                :to="`/room/edit/${room.id}`"
                class="btn btn-success text-white mx-1"
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </router-link>

              <button
                class="btn btn-danger text-white mx-1"
                @click="deleteRoom(index)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div class="row">
      <pagination
        :data="rooms"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchRooms"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div> -->
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
export default {
  name: "allRooms",
  components: { AddFavDailog },
  data() {
    return {
      // roomId: this.$route.params.id,
      rooms: [],
      titleRoom: "",
    };
  },
  methods: {
    // fetch rooms data
    FetchRooms() {
      // let hotel_id = {hotel_id: hotel_id};
      // let hotel_id = { hotel_id: this.$route.params.hotel_id };

      axios.post("/fetch_all_rooms", { service_type: 3 }).then(({ data }) => {
        this.rooms = data.data;
        // console.log(this.rooms);
      });
    },

    //delete safe
    deleteRoom(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.rooms[index].id;
          const fromClick = true;
          axios
            .delete("/rooms/" + `${delete_id}`, { fromClick })
            .then(({ data }) => {
              // console.log(result);
              // console.log(delete_id);
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.rooms.splice(index, 1); // Remove the deleted item from the array
                // console.log(data);
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.FetchRooms();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "./_room";
</style>
