<template>
  <div class="offer_card" :id="`offer_card${offer.id}`" :class="{active_room:showRoomsValue}">
    <div class="texts_offer">
      <p class="title_offer" v-if="index == 0">{{offer.rooms.data[0].roomType}}</p>
      <p class="title_offer" v-else>{{$t("offer")}} {{index+1}}</p>
      <p class="title">{{offer.title}}</p>
    </div>
    <div class="actions">
      <div class="price" v-if="showRoomsValue == false">{{offer.price}}</div>
      <div class="buttons d-flex align-items-center">
        <div class="price" v-if="index == 0">{{offer.rooms.data[0].currency}} {{offer.rooms.data[0].price}}</div>
        <button class="btn PrimaryButton" @click="showRooms(offer.type, offer.id)">{{showRoomsValue == false ? $t("show_more") : $t("hide_more")}}</button>
      </div>
      <!-- @click="showRooms()" -->
    </div>
    <div class="show_rooms" v-if="showRoomsValue">
      <!-- v-if="showRoomsValue" -->
      <roomCard :element_position="element_position" v-for="(room, index) in offer.rooms.data == undefined ? this.rooms : offer.rooms.data" :offer="offer" :key="index" :rooms="offer.rooms.data == undefined ? this.rooms : offer.rooms.data" :guest_nationality="guest_nationality" :room="room" :session_id="session_id" :resultIndex="resultIndex" :hotel_code="hotel_code" :hotel_name="hotel_name" :hotel_id="hotel_id" :index="index" @rooms-selected="receiveRooms" @hotel-selected="receiveHotelId" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import roomCard from './roomCard.vue'

export default {
  name: "offer-card",
  data() {
    return {
      rooms: [],
      roomsSelected: [],
      showRoomsValue: false,
      element_position:""
    }
  },
  props: {
    offer: Object,
    offers: Array,
    index: Number,
    hotel_id: Number,
    session_id: String,
    hotel_code: Number,
    hotel_name: String,
    guest_nationality: String,
    resultIndex: Number
  },
  components: {
    roomCard
  },
  methods: {
    showRooms(offer_type, offer_id) {
      // let filter = {
      //   session_id: this.session_id,
      //   result_index: this.resultIndex,
      //   hotel_code: this.hotel_code,
      //   response_time: 0,
      // }
      // axios.post("/fetch_available_rooms", filter).then(({ data }) => {
      //   this.rooms = data.data;
      //   // console.log(this.rooms.data)
      // });

      if (offer_type == "contract") {
        let filter = {
          contract_id: offer_id
        }
        axios.post("/fetch_contract_rooms", filter).then(({ data }) => {
          this.rooms = data.data;
          // console.log(this.rooms)
        });
      }
      this.lengthOffers = true;
      this.showRoomsValue = !this.showRoomsValue;

    },
    receiveRooms(data) {
      this.roomsSelected = data;
      // console.log(this.roomsSelected)
      this.$emit('rooms-selected', this.roomsSelected);
    },
    receiveHotelId(data) {
      this.hotelId = data;
      this.$emit('hotel-selected', this.hotelId);
    }
  },
  mounted(){
      let element = document.getElementById(`offer_card${this.offer.id}`)
      this.element_position = element.offsetTop;
  }
}
</script>

<style lang="scss" scoped>
    @import "../../view/dashboard/hotels/_hotels.scss";
</style>
