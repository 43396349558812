<template>
  <div class="invoice">
    <div class="row">
      <div class="col-8">
        <div class="main-text">
          <div class="text-logo">
            <h4>{{ pills.book_number }}</h4>
            <h4>ROOMING LIST</h4>
          </div>
          <div class="next-text">
            <!-- <h5>Booking Status: <span>Tentative</span></h5> -->
            <h5 v-for="(hotel, index) in pills.hotels" :key="index">
              Hotel Name {{ index + 1 }}: {{ hotel.hotel_name }}
            </h5>
            <h6 v-if="pills.created_at_date">
              Created date:
              {{ pills.created_at_date }} -
              {{ pills.created_at_time }}
            </h6>
          </div>
        </div>
        <!-- <div class="under-text">
          <div class="head">
            <h4>Dear Sir : {{ pills.client_name }}</h4>
            <h5>
              Greeting From <span>{{ pills.organization?.name }}</span>
            </h5>
            <div class="pragra">
              <p>
                First of All, We would like to take this opportunity to welcome you
                at
                <strong> {{ pills.organization?.name }} </strong> and tourism We are
                pleased to confirm the following reservation on a
                <span>{{ book_status_name }}.</span>
              </p>
            </div>
          </div>
        </div> -->
      </div>
      <div class="col-4">
        <div class="logo">
          <img :src="pills.organization?.default_image" />
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="frist">ROOM TYPE</th>
            <th>GUEST NAME</th>
            <th>Book Number</th>
            <th>CHECK IN</th>
            <th>CHECK OUT</th>
            <th>ROOM NUMBER</th>
            <th>MEAL</th>
            <th>CONF NO</th>
            <th>NOTE</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(room, index) in roomigList" :key="index">
            <td class="frist">
              <p class="mb-0 fs-8">
                {{ room.room_name }}
              </p>
              <p>{{ room.hotel_name }}</p>
            </td>
            <td>
              <div class="p-2">
                <span
                  class="text-center d-flex flex-column align-items-center mb-2 border rounded px-3 py-1"
                  v-for="(guest, index) in room.guests"
                  :key="index"
                >
                  {{ guest.guest_name }}
                </span>
              </div>
            </td>
            <td>{{ room.book_number }}</td>
            <td>
              <p class="mb-0">
                {{ room.checkin_date }}
              </p>
              <span class="hijri">{{
                updateHijriDate(new Date(room.checkin_date))
              }}</span>
            </td>
            <td>
              <P class="mb-0">
                {{ room.checkout_date }}
              </P>
              <span class="hijri">{{
                updateHijriDate(new Date(room.checkout_date))
              }}</span>
            </td>
            <td>{{ room.room_number }}</td>
            <td>{{ room.meal_type_name }}</td>
            <td>{{ room.booking_number }}</td>
            <td>{{ room.notes }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "pill-hotel-room",
  data() {
    return {
      roomigList: [],
    };
  },
  props: {
    pills: Object,
    terms: String,
    total_selling_price_with_out_tax: String,
    selling_tax: String,
    total_selling_price: String,
    book_status_name: String,
  },
  methods: {
    fetch_rooming_list_by_booking_id() {
      const formData = new FormData();
      if (this.$route.params.id) {
        formData.append("booking_hotel_id", this.$route.params.id);
      }
      if (this.$route.query.booking_room_id) {
        formData.append("booking_hotel_id", this.$route.query.booking_room_id);
      }
      // if(this.$route.query.booking_room_id) {
      //   formData.append("booking_room_id", this.$route.query.booking_room_id);
      // }
      if (this.$route.query.booking_room_id) {
        console.log(this.$route.query.booking_room_id);
        axios
          .get(
            `/offline_booking_room_lists/${this.$route.query.booking_room_id}`,
          )
          .then(({ data }) => {
            this.roomigList = data.data;
            // console.log(data.data);
          });
      } else {
        axios
          .post("/fetch_rooming_list_by_booking_id", formData)
          .then(({ data }) => {
            this.roomigList = data.data.data;
            // console.log(data.data);
          });
      }
    },
  },
  created() {
    this.fetch_rooming_list_by_booking_id();
  },
};
</script>

<style lang="scss" scoped>
@import "./_pill.scss";
</style>
