<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("add policy") }}</h4>
    </div>

    <form @submit.prevent="createBLog()">
      <div class="row">
        <div class="col-lg-12 col-md-6 col-12">
          <div class="form-group">
            <label>{{ $t("describtion") }}</label>
            <div class="accordion" id="accordionExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("describtion") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(item, index) in desc"
                      :key="index"
                    >
                      <label class="mt-3"
                        >{{ $t("describtion") }} {{ item.lang }}</label
                      >
                      <Editor editorStyle="height: 120px" v-model="item.text" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("add") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Editor from "primevue/editor";

export default {
  name: "createPage",
  data() {
    return {
      languages: ["ar", "en", "id"],
      desc: [],
    };
  },
  components: {
    Editor,
  },
  methods: {
    createBLog() {
      let descData = {};
      this.desc.forEach((ele) => {
        descData[`description_${ele.lang}`] = ele.text;
      });
      axios.post("/website/policy", descData).then(({ res }) => {
        Swal.fire({
          title: this.$t("success"),
          text: this.$t("added"),
          icon: "success",
        });
        console.log(res);
      });
    },
  },
  mounted() {
    this.languages.forEach((lang) => {
      this.desc.push({
        lang: lang,
        text: "",
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
</style>
