<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("add_company") }}</h4>
    </div>

    <form @submit.prevent="addCampany()">
      <div class="row">
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The Country") }}</label>
            <Multiselect
              v-model="countryValue"
              :options="countries.map((country) => country.id)"
              :custom-label="(opt) => countries.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="adress">{{ $t("code") }}</label>
            <input
              type="text"
              class="form-control"
              id="code"
              :placeholder="$t('code')"
              v-model="code"
            />
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("write_name") }} (EN)</label>
            <input
              type="text"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('write_name')"
              v-model="title_en"
            />
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("write_name") }} (AR)</label>
            <input
              type="text"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('write_name')"
              v-model="title_ar"
            />
          </div>
        </div>

        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("tax_number") }} </label>
            <input
              type="number"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('tax_number')"
              v-model="tax_number"
            />
          </div>
        </div>

        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("disntaion_number") }} </label>
            <input
              type="number"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('disntaion_number')"
              v-model="disntaion_number"
            />
          </div>
        </div>

        <div class="col-md-6 mb-6">
          <input type="file" id="file" @change="imageUpload" multiple hidden />

          <label for="file" class="add_file"
            ><i class="fa-solid fa-upload"></i> {{ $t("Add image") }}</label
          >
          <p>{{ this.imageCompanyName }}</p>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("add") }}
      </button>
    </form>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/components/LottieFiles/loading.vue";

export default {
  name: "addHotel",
  components: { Multiselect, Loading },
  data() {
    return {
      title_en: "",
      title_ar: "",
      countryValue: "",
      countries: [],
      cityValues: "",
      cities: [],
      code: "",
      imageCompany: "",
      imageCompanyName: "",
      tax_number: "",
      disntaion_number: "",
      loading: false,
    };
  },
  methods: {
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    imageUpload(event) {
      this.imageCompany = event.target.files[0];
      const name = this.imageCompany.name;

      const reader = new FileReader();
      reader.readAsDataURL(this.imageCompany);
      reader.onload = () => (this.imageCompanyName = name);
      // reader.onload = () => (this.imageEntry = reader.result);
    },
    addCampany() {
      const formData = new FormData();
      formData.append("name_en", this.title_en);
      formData.append("country_id", this.countryValue);
      // formData.append("city_id", this.cityValues);
      formData.append("name_ar", this.title_ar);
      formData.append("code", this.code);
      formData.append("image", this.imageCompany);
      formData.append("tax_number", this.tax_number);
      formData.append("disntaion_number", this.disntaion_number);
      formData.append("imageCompany", this.imageCompany);
      // console.log(this.imageCompany);
      this.loading = true; //the loading ended
      axios
        .post("/flight_companies", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.loading = false; //the loading ended
          this.$router.push("/flight-company");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
  },
  mounted() {
    this.fetch_country();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style></style>
