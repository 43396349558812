<template>
  <section class="setting">
    <div class="container">
        <h3 class="title_section">{{$t("system_settings")}}</h3>
        <h6 class="text_section">{{$t("system_settings_text")}}</h6>
        <div class="card_setup" v-if="userEmail != targetEmail">
            <div class="description">
                <h5 class="title">{{$t("selling_fees_companies")}}</h5>
                <p class="text">{{$t("click_determine_sale_fees_companies")}}</p>
            </div>
            <router-link class="btn PrimaryButton" to="/setup_fees/1">{{$t("fees_settings")}}</router-link>
        </div>
        <div class="card_setup">
            <div class="description">
                <h5 class="title">{{$t("selling_fees_clients")}}</h5>
                <p class="text">{{$t("click_determine_sale_fees_clients")}}</p>
            </div>
            <router-link class="btn PrimaryButton" to="/setup_fees/2">{{$t("fees_settings")}}</router-link>
        </div>
        <div class="card_setup">
            <div class="description">
                <h5 class="title">{{$t("taxes_imposed")}}</h5>
                <p class="text">{{$t("click_determine_tax")}}</p>
            </div>
            <button type="button" class="btn PrimaryButton" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottomTax" aria-controls="offcanvasBottomTax">{{$t("fees_settings")}}</button>
            <TaxBottomSheet />
        </div>
        <ul class="instructions">
            <h5 class="title">تعليمات هامة</h5>
            <li>تحديد رسوم الربح هو من اهم الخطوات التى تقوم بها قبل البدأ فى استخدام النظام لضمان الحصول على النسبة التى تريدها</li>
            <!-- <li>فى حالة عدم تحديد نسبة الربح التى تريدها سيتم تطبيق النسبة المحددة من قبل الادراة وهو نسبة 10 % فقط بالنسبة للشركت والعملاء</li>
            <li>تحديد رسوم الربح هو من اهم الخطوات التى تقوم بها قبل البدأ فى استخدام النظام لضمان الحصول على النسبة التى تريدها</li> -->
            <li>فى حالة عدم تحديد نسبة الربح التى تريدها سيتم تطبيق النسبة المحددة من قبل الادراة وهو نسبة 10 % فقط بالنسبة للشركت والعملاء</li>
        </ul>
    </div>
  </section>
</template>

<script>
import TaxBottomSheet from '@/components/setup/tax/tax.vue';

export default {
  name: "setting-setup",
  data() {
    return {
      userEmail: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).email_company : null,
      targetEmail: "info@tripsavisor.com",
    }
  },
  components: {
    TaxBottomSheet
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  }
};
</script>

<style lang="scss" scoped>
    @import "./_setup.scss";
</style>
