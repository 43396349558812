<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center gap-3 mb-2">
      <Fav v-tooltip.left="$t('add_to_favourite')" />
      <h4>
        {{ $t("Markters") }}
      </h4>
    </div>

    <div class="main_header_style mb-2">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <div class="search-visa w-50">
          <form>
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              v-model="MarkterText"
              @keyup="fetchMarkters((page = 1))"
              class="form-control"
              :placeholder="$t('search...')"
            />
          </form>
        </div>
        <router-link to="/markters/Add" class="PrimaryButton mt-0 btn"
          ><i class="fa-solid fa-plus"></i> {{ $t("add_new_markter") }}
        </router-link>

        <div class="w-25 mx-3">
          <label class="typo__label">{{ $t("Status") }}</label>

          <multiselect
            v-model="value"
            :options="options"
            :custom-label="nameWithLang"
            placeholder="Select status"
            label="name"
            track-by="id"
            select-label=""
            :hide-selected="true"
            @update:modelValue="fetchMarkters((page = 1))"
          ></multiselect>
        </div>
      </div>
    </div>

    <div class="row">
      <table class="table" id="VisaTable">
        <thead>
          <tr>
            <th class="text-center">ID</th>
            <th class="text-center">{{ $t("name") }}</th>
            <th class="text-center">{{ $t("date created") }}</th>
            <th class="text-center">{{ $t("type") }}</th>
            <th class="text-center">{{ $t("class") }}</th>
            <th class="text-center">{{ $t("invitation_code") }}</th>
            <th class="text-center">{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="Markter in 10" :key="Markter">
            <td v-for="Markter in 7" :key="Markter">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(Markter, index) in Markters.data" :key="index">
            <td class="text-center">{{ Markter.id }}</td>
            <td class="text-center">
              {{ Markter.first_name ? Markter.first_name : "-" }}
            </td>
            <td class="text-center">
              {{ Markter.created_at ? Markter.created_at : "-" }}
            </td>

            <!-- <td class="text-center" v-if="Markters.affiliate_category == null">
              -
            </td> -->
            <td class="text-center">
              {{
                Markter.affiliate_category == null
                  ? "-"
                  : Markter.affiliate_category.title
              }}
            </td>

            <td class="text-center">
              {{
                Markter.stage_percentage_type
                  ? Markter.stage_percentage_type
                  : "-"
              }}
            </td>
            <td class="text-center">
              {{ Markter.invitation_code ? Markter.invitation_code : "-" }}
            </td>
            <td
              class="text-center d-flex align-items-center justify-content-center"
            >
              <button
                @click="$router.push(`/markters/Edit/${Markter.id}`)"
                class="btn btn-success mx- w-auto"
              >
                <i class="fa-solid fa-edit"></i>
              </button>
              <button
                @click="deleteMarkter(Markter.id, index)"
                class="btn btn-danger mx-1 w-auto"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="Markters"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchMarkters"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Fav from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
import Multiselect from "vue-multiselect";
// import $ from "jquery";
export default {
  name: "markters-comp",
  components: {
    pagination,
    Fav,
    Skeleton,
    Multiselect,
  },
  data() {
    return {
      Markters: [],
      MarkterText: "",
      loading: true,
      value: "",
      options: [
        { id: 2, name: this.$t("Rejected") },
        { id: 1, name: this.$t("accepted") },
        { id: 0, name: this.$t("show_all") },
      ],
    };
  },
  methods: {
    fetchMarkters(page = 1) {
      const formData = new FormData();

      if (this.value.id != undefined) {
        formData.append("status", this.value.id);
      }
      axios
        .post(
          `/index_organization_marketers?page=${page}`,
          this.value.id != undefined ? formData : ""
        )
        .then(({ data }) => {
          this.Markters = data.data;
          this.loading = false;
          // console.log(this.Markters, "dadas");
        });
    },
    //delete visa
    deleteMarkter(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.Markters.data[index].id;
          const fromClick = true;
          axios.delete(`/organization_marketers/${id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.Markters.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.fetchMarkters();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
.text-center {
  text-align: center !important;
}
</style>
