<template>
  <div class="tabs_sec">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        id="company-tab"
        data-bs-toggle="tab"
        data-bs-target="#company-tab-pane"
        type="button"
        role="tab"
        aria-controls="company-tab-pane"
        aria-selected="true"
      >
      {{ $t("company") }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="reservation-tab"
        data-bs-toggle="tab"
        data-bs-target="#reservation-tab-pane"
        type="button"
        role="tab"
        aria-controls="reservation-tab-pane"
        aria-selected="false"
      >
      {{ $t("reservation") }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="shipping-requests-tab"
        data-bs-toggle="tab"
        data-bs-target="#shipping-requests-tab-pane"
        type="button"
        role="tab"
        aria-controls="shipping-requests-tab-pane"
        aria-selected="false"
      >
      {{ $t("shipping requests") }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="company-branch-tab"
        data-bs-toggle="tab"
        data-bs-target="#company-branch-tab-pane"
        type="button"
        role="tab"
        aria-controls="company-branch-tab-pane"
        aria-selected="false"
      >
      {{ $t("company_branch") }}
      </button>
    </li>
  </ul>

  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="company-tab-pane"
      role="tabpanel"
      aria-labelledby="company-tab"
      tabindex="0"
    >
      <companies />
    </div>
    <div
      class="tab-pane fade"
      id="reservation-tab-pane"
      role="tabpanel"
      aria-labelledby="reservation-tab"
      tabindex="0"
    >
      <reservation-branch />
    </div>
    <div
      class="tab-pane fade"
      id="shipping-requests-tab-pane"
      role="tabpanel"
      aria-labelledby="shipping-requests-tab"
      tabindex="0"
    >
      <shipping-requests />
    </div>
    <div
      class="tab-pane fade"
      id="company-branch-tab-pane"
      role="tabpanel"
      aria-labelledby="company-branch-tab"
      tabindex="0"
    >
      <company-branch />
    </div>
  </div>
  </div>
  
</template>

<script>
import companyBranch from "./companyBranch";
import companies from "./companies";
import reservationBranch from "./reservationBranch";
import shippingRequests from "./shippingRequests";

export default {
  name: "tabs-resrvation",
  components: {
    companyBranch,
    companies,
    reservationBranch,
    shippingRequests,
  },
};
</script>
<style lang="scss" scoped>
@import "../../view/dashboard/organization/_organization.scss";
</style>
