<template>
  <section class="show_receipt card_style mt-0">
    <div class="main_header_style"></div>
    <div class="d-flex align-items-center mb-2 gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4>
        {{ $t("Cars addCategory") }}
      </h4>
    </div>
    <div class="row options mb-2">
      <div class="col-lg-10 col-md-12 col-12">
        <div class="form-group">
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            :placeholder="$t('search by name')"
            v-model="category_title"
            @keyup="FetchCategory((page = 1), (paginate = 5))"
            class="form-control w-50"
          />
        </div>
      </div>

      <div class="col-lg-2 col-md-12 col-12">
        <router-link
          :to="{ name: 'carCatAdd' }"
          class="PrimaryButton btn w-100 car"
          ><i class="fa-solid fa-plus"></i>
          {{ $t("New Car addCategory") }}</router-link
        >
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table carcategory">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("title") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 3" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in category.data" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.title }}</td>

              <td>
                <router-link
                  class="btn btn-success mx-1 w-auto"
                  :to="`/car_cat/edit/${item.id}`"
                  :title="$t('edit')"
                >
                  <i class="fa-solid fa-pen"></i>
                </router-link>

                <button
                  class="btn btn-danger mx-1 w-auto"
                  @click="DeleteAlert(index)"
                >
                  <i class="fa-solid fa-trash" :title="$t('delete')"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="category"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchCategory"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  name: "show-nation",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      category: [],
      category_title: "",
      loading: true,
    };
  },
  methods: {
    FetchCategory(page = 1, paginate = 5) {
      axios
        .post(`/fetch_car_categories?paginate=${paginate}&page=${page}`, {
          word: this.category_title,
        })
        .then(({ data }) => {
          this.category = data.data;
          this.loading = false;
        });
    },
    //delete expness
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.category.data[index].id;
          const fromClick = true;
          axios.delete("/car_categories/" + `${delete_id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.category.data.splice(index, 1);
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.FetchCategory();
  },
};
</script>
<style lang="scss" scoped>
@import "./_cat.scss";
</style>
