<template>
  <section class="show_receipt card_style">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4 class="head-we">
        {{ $t("All Flight Contract") }}
      </h4>
    </div>
    <div class="main_header_style"></div>
    <div class="row options mb-2 align-items-center">
      <!-- <div class="col-lg-4 col-md-12 col-12"></div> -->
      <form class="col-lg-10 col-md-12 col-12">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          :placeholder="$t('search by name')"
          v-model="titlenation"
          @keyup="FetchContract"
          class="form-control w-50"
        />
      </form>

      <div class="col-lg-2 col-md-12 col-12">
        <router-link
          :to="{ name: 'flightContractAdd' }"
          class="PrimaryButton btn flight"
          ><i class="fa-solid fa-plus"></i> {{ $t("flight Contract") }}
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table flight">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("contract_name") }}</th>
              <th>{{ $t("trip_kind") }}</th>
              <th>{{ $t("Take-off airport") }}</th>
              <th>{{ $t("Arrival airport") }}</th>
              <th>{{ $t("flight_line") }}</th>
              <th>{{ $t("suppliers") }}</th>
              <th>{{ $t("Date") }}</th>

              <th></th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 8" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in contracts.data" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>
                <span v-if="item.flight_type == 1">{{ $t("go_only") }}</span>
                <span v-else>{{ $t("go_back") }} </span>
              </td>
              <td>-</td>

              <td>-</td>
              <td>{{ item.flight_companie_id.name }}</td>
              <td>{{ item.supplier_id.name }}</td>
              <td>{{ item.from }}</td>
              <td>
                <router-link
                  class="btn"
                  :to="`/flight-contract/edit/${item.id}`"
                  :title="$t('edit')"
                >
                  <i class="fa-solid fa-pen"></i>
                </router-link>
                <router-link
                  class="btn"
                  :to="`/flight-contract/${item.id}/flight-season`"
                  :title="$t('seasons')"
                >
                  <i class="fas fa-water"></i>
                </router-link>
                <button class="btn remove_btn" @click="DeleteAlert(index)">
                  <i class="fa-solid fa-trash" :title="$t('delete')"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="contracts"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchContract"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Skeleton from "primevue/skeleton";
import Swal from "sweetalert2";
export default {
  name: "show-flight-contract",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      contracts: [],
      titlenation: "",
      loading: true,
    };
  },
  methods: {
    // fetch contracts data

    FetchContract(page = 1) {
      axios.get(`/flight_contract?page=${page}`).then(({ data }) => {
        this.contracts = data.data;
        // console.log(this.contracts);
        this.loading = false;
      });
    },
    //delete expness
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.contracts.data[index].id;
          const fromClick = true;
          axios
            .delete("/flight_contract/" + `${delete_id}`, { fromClick })
            .then(({ data }) => {
              // console.log(result);
              // console.log(delete_id);
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.contracts.data.splice(index, 1); // Remove the deleted item from the array
                // console.log(data);
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.FetchContract();
  },
};
</script>
<style lang="scss" scoped>
@import "./_contract.scss";
</style>
