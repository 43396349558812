<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("add_new_season") }}</h4>
    </div>
    <form class="form_topic" @submit.prevent="addSeason()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("season_name") }}</label>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        {{$t("season_name")}}
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <div class="form-group" v-for="(season , index) in season_name" :key="index">
                            <label for="name">{{$t("season_name")}} ({{season.lang}})</label>
                            <input type="text" :placeholder="$t('name')" class="form-control" :id="`name${index}`" v-model="season.name"/>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.season_name.$error">{{v$.season_name.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("period_from") }}</label>
            <Datepicker
              v-model="from_date"
              lang="ar"
              :placeholder="$t('period_from')"
              :disabled-dates="{
                  to: new Date(
                      seasons.length ? getLastObjectSeason : contract_from_date
                  ),
                  from: new Date(
                      contract_to_date
                  ),
              }"
              :value="seasons.length ? getLastObjectSeason : contract_from_date"
            />
            <span class="input-errors" v-if="v$.from_date.$error">{{v$.from_date.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("period_to") }}</label>
            <Datepicker
              v-model="to_date"
              lang="ar"
              :placeholder="$t('period_to')"
              :disabled-dates="{
                  to: new Date(
                      new Date(seasons.length ? getLastObjectSeason : contract_from_date).getTime() + 24 * 60 * 60 * 1000
                  ),
                  from: new Date(
                      contract_to_date
                  ),
              }"
              :value="contract_to_date"
            />
            <span class="input-errors" v-if="v$.to_date.$error">{{v$.to_date.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("add") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  name: "add-route",
  components: {Datepicker},
  data() {
    return {
      v$: useVuelidate(),
      contract_id: this.$route.params.id,
      languages: ["ar","en","id"],
      season_name: [],
      from_date: "",
      to_date: "",
      contract_from_date: "",
      contract_to_date: "",
      seasons: []
    };
  },
  computed:{
    getLastObjectSeason() {
      if (this.seasons.length > 0) {
          const startDateSeason = new Date(
              new Date(this.seasons[this.seasons.length - 1].to_date).getTime() +
              24 * 60 * 60 * 1000
          );
          const startDateSeasonFormat =
              moment(startDateSeason).format("YYYY-MM-DD");
          return startDateSeasonFormat;
      } else {
          return null;
      }
    }
  },
  validations(){
    return{
      from_date: { required },
      to_date: { required },
      season_name: this.season_name.map(()=>{
        return{
          name : {required}
        }
      })
    }
  },
  methods: {
    setSeasonName(){
      this.languages.forEach((language)=>{
        this.season_name.push({
          lang: language,
          name: ""
        })
      })
    },
    fetch_contract_data(){
      axios.get(`/transportation_contracts/${this.contract_id}`).then(({data})=>{
        console.log(data)
        this.contract_from_date = data.data.from_date;
        this.contract_to_date = data.data.todate;
        this.seasons = data.data.transporation_seasons;
        this.from_date = new Date(this.seasons.length ? this.getLastObjectSeason : this.contract_from_date);
        this.to_date = new Date(this.contract_to_date);

        if(this.seasons.length > 0){
          if(moment(data.data.todate).format("YYYY-MM-DD") == this.seasons[this.seasons.length - 1].to_date){
            Swal.fire({
                title: "",
                text: "not available",
                icon: "error",
            });
            this.$router.push(`/contract-transport/${this.contract_id}/season-transport`);
          }
        }
      })
    },
    async addSeason() {
      this.v$.$validate();
      if(!this.v$.$error){
        const formData = new FormData();
        this.season_name.forEach((season)=>{
          formData.append(`title_${season.lang}`, season.name);
        })
        formData.append("from_date", moment(this.from_date).format("YYYY-MM-DD"));
        formData.append("to_date", moment(this.to_date).format("YYYY-MM-DD"));
        formData.append("transportation_contract_id", this.contract_id);
        axios
          .post("/transportation_seasons", formData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.$router.push(`/contract-transport/${this.contract_id}/season-transport`);
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },
  mounted(){
    this.setSeasonName();
    this.fetch_contract_data();
  }
};
</script>
<style lang="scss" scoped>
@import "./_seasons.scss";
</style>
