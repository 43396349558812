<template>
  <section class="show_receipt card_style">
    <div class="main_header_style"></div>
    <div class="row options mb-2">
      <div class="col-2">
        <h4>{{ $t("seasons") }}</h4>
      </div>
      <form class="col-lg-4 col-12">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          :placeholder="$t('search by name')"
          v-model="titlenation"
          @keyup="FetchSeasons"
          class="form-control"
        />
      </form>

      <div class="col-12 col-lg-6">
        <router-link
          :to="{ name: 'seasonTransportAdd' }"
          class="PrimaryButton btn w-50"
          ><i class="fa-solid fa-plus"></i>{{ $t("add_new_season") }}
        </router-link>
      </div>
    </div>
    <div class="row">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("season_name") }}</th>
            <th>{{ $t("period_from") }}</th>
            <th>{{ $t("period_to") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 5" :key="item">
              <Skeleton width="90%" height="2rem" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) in seasons.data" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.from_date }}</td>
            <td>{{ item.to_date }}</td>
            <!-- <td>
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa-solid fa-bars"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <router-link
                      class="btn"
                      :to="`/contract-transport/${this.contract_id}/season-transport/edit/${item.id}`"
                      :title="$t('edit')"
                    >
                      <i class="fa-solid fa-pen"></i>
                      {{ $t("edit_data") }}
                    </router-link>
                  </li>
                  <li>
                    <button class="btn remove_btn" @click="DeleteAlert(index)">
                      <i class="fa-solid fa-trash" :title="$t('delete')"></i>
                      {{ $t("delete") }}
                    </button>
                  </li>
                </ul>
              </div>
            </td> -->

            <td>
              <router-link
                class="btn btn-success text-light edit_btn"
                :title="$t('edit')"
                :to="`/contract-transport/${this.contract_id}/season-transport/edit/${item.id}`"
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </router-link>

              <router-link
                class="btn btn-info text-light edit_btn mx-2"
                :title="$t('rate code')"
                :to="`/contract-transport/${this.contract_id}/season-transport/${item.id}/rate-code`"
              >
                <i class="fa-solid fa-water"></i>
              </router-link>

              <button
                class="btn btn-danger text-light remove_btn"
                @click="DeleteAlert(index)"
              >
                <i class="fa-solid fa-trash" :title="$t('delete')"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="seasons"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchSeasons"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";

import Skeleton from "primevue/skeleton";

export default {
  name: "show-seasons",
  components: {
    pagination,
    Skeleton,
  },

  data() {
    return {
      contract_id: this.$route.params.id,
      seasons: [],
      titlenation: "",
      loading: true,
    };
  },
  methods: {
    // fetch seasons data

    FetchSeasons() {
      // const search = { word: this.titlenation };
      axios
        .post(
          `/fetch_seasons_by_transportation_contract_id/${this.contract_id}`
        )
        .then(({ data }) => {
          this.seasons = data.data;
          this.loading = false;
        });
    },
    //delete expness
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.seasons.data[index].id;
          const fromClick = true;
          axios
            .delete("/transportation_seasons/" + `${delete_id}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.seasons.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.FetchSeasons();
  },
};
</script>
<style lang="scss" scoped>
@import "./_seasons.scss";
</style>
