<template>
  <section class="all_emp card_style mt-0" style="height: 100dvh">
    <div
      class="main_header_style d-flex justify-content-between align-items-center my-2"
    >
      <div class="d-flex align-items-center gap-3">
        <Fav v-tooltip.left="$t('add_to_favourite')" />
        <h4 class="coupons">{{ $t("Pending") }} {{ $tc("Gifts") }}</h4>
      </div>

      <!-- <div class="search-user my-3">
           <form>
             <i class="fa-solid fa-magnifying-glass"></i>
             <input type="text" class="form-control" placeholder="ابحث الان" />
           </form>
         </div> -->
      <div class="w-25">
        <label class="typo__label">{{ $t("Status") }}</label>

        <multiselect
          v-model="value"
          :options="options"
          :custom-label="nameWithLang"
          placeholder="Select status"
          label="name"
          track-by="name"
          select-label=""
          :hide-selected="true"
          @update:modelValue="fetchpending((page = 1))"
        ></multiselect>
      </div>
    </div>

    <div class="row">
      <table class="table" id="userTable">
        <thead>
          <tr>
            <th class="text-center">ID</th>
            <th class="text-center">{{ $t("name_marketer") }}</th>
            <th class="text-center">{{ $t("name_gift") }}</th>
            <th class="text-center">{{ $t("points") }}</th>
            <th class="text-center">{{ $t("Status") }}</th>
            <th class="text-center" v-if="value.id == 0">
              {{ $t("Control") }}
            </th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="user in 10" :key="user">
            <td v-for="user in 5" :key="user">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody class="bg-primary" v-else>
          <tr v-for="(user, index) in pending.data" :key="index">
            <td>{{ user.id }}</td>
            <td>{{ user.marketer ? user.marketer : "-" }}</td>
            <td>{{ user.package_book_gift ? user.package_book_gift : "-" }}</td>
            <td>{{ user.points }}</td>
            <td>{{ user.status }}</td>
            <td v-if="user.status == 'Pending'">
              <button
                class="btn btn-danger w-auto text-center"
                @click="reject_marketer(user.id, index)"
              >
                <i class="fa-solid fa-cancel"></i>
              </button>
              <button
                class="btn btn-success w-auto text-center mx-2"
                @click="aprrove_marketer(user.id, index)"
              >
                <i class="fa-solid fa-check"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="pending"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchpending"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Fav from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
export default {
  name: "Coupons_show",
  components: {
    pagination,
    Multiselect,
    Fav,
    Skeleton,
  },
  data() {
    return {
      pending: [],
      loading: true,
      value: "",
      options: [
        { id: 2, name: this.$t("Rejected") },
        { id: 1, name: this.$t("accepted") },
        { id: 0, name: this.$t("PENDING") },
      ],
    };
  },

  methods: {
    fetchpending(page = 1) {
      const formData = new FormData();

      if (this.value.id != undefined) {
        formData.append("status", this.value.id);
      }

      // console.log(this.value.id);

      axios
        .post(
          `/marketer_gift_requests?page=${page}`,
          this.value.id != undefined ? formData : ""
        )
        .then((res) => {
          this.pending = res.data.data;
          this.loading = false;
          // console.log(this.pending, "pending");
        });
    },

    aprrove_marketer(id, index) {
      axios
        .post("/accept_marketer_gift_request", { gift_request_id: id })
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire("", this.$t("gift request accepted"), "success");
            //   console.log(this.pending.data[index], "wwww");
            this.pending.data.splice(index, 1); // Remove the deleted item from the array
          } else {
            Swal.fire("", data.message, "error");
          }
        });
      // console.log(id, "gift_request_id");
    },
    reject_marketer(id, index) {
      axios
        .post("/reject_marketer_gift_request", { gift_request_id: id })
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire("", data.message, "success");
            //   console.log(this.pending.data[index], "wwww");
            this.pending.data.splice(index, 1); // Remove the deleted item from the array
          } else {
            Swal.fire("", data.message, "error");
          }
        });
    },
  },
  mounted() {
    this.fetchpending();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_user.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-user {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
.btn_trip {
  border: 1px solid #96c28a;
  outline: none;
  padding: 0.5rem 0.8rem;
  color: #96c28a;
  border-radius: 4px;
  background-color: #fff;
  margin: 0 0.2rem;
  font-family: "reqular";
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  display: none !important;
}

.msg {
  border: none;
  outline: none;
  padding: 0.4rem 0.7rem;
  background-color: rgba($color: #e23535, $alpha: 10%);
  border-radius: 4px;
  color: #e23535;
}
.PrimaryButton {
  background-color: #96c28a !important;
}
.fw-bold {
  font-family: "bold" !important;
}
.PrimaryButton[data-v-07d1af3d]:hover {
  border: 1px solid #96c28a !important;
  color: #96c28a !important;
}
.coupons {
  color: #0b1427;
  font-family: "bold";
  font-size: 2rem;
}
</style>
