<template>
  <add-supplier  />
</template>

<script>
import addSupplier from "@/components/suppliers/addSupplier.vue";
export default {
  name: "add-suppliers",
  components: {
    addSupplier,
  },
};
</script>

<style></style>
