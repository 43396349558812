<template>
  <section class="add_recipt card_style">
    <div class="main_header_style">
      <h4>{{ $t("add_new_provider") }}</h4>
    </div>
    <form class="form_topic" @submit.prevent="addprovider()">
      <div class="row">
       
        <ul class="types">
          <li v-for="(type, index) in service_types" :key="index">
            <div class="form-group service_type">
              <input
                type="checkbox"
                name="service_type"
                class="wizard-required"
                v-model="type.selected"
               
                :id="'default' + type.value"
                :value="type.value"
              />
              <label :for="'default' + type.value">
                <i class="fa-solid" :class="getTypeIcon(type.value)"></i>
                {{ $t(getTypeName(type.value)) }}
              </label>
            </div>
          </li>
        </ul>

        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("provider_name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('provider_name')"
              v-model="name"
              required
            />
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("provider_description") }}</label>
            <textarea
              class="form-control"
              :placeholder="$t('provider_description')"
              v-model="text"
            ></textarea>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("save") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "add-provider",
  data() {
    return {
      service_types: [
        { value: 1, selected: false },
        { value: 2, selected: false },
        { value: 3, selected: false },
        { value: 4, selected: false },
      ],
      name: "",
      text: "",
    };
  },
  methods: {
    // define service type
    getTypeIcon(value) {
      // Define mappings of value to icon classes
      const iconMap = {
        1: "fa-bell-concierge",
        2: "fa-plane",
        3: "fa-bus",
        4: "fa-tree",
        // ... add more mappings as needed ...
      };
      return `fa-solid ${iconMap[value]}`;
    },
    getTypeName(value) {
      // Define mappings of value to type names
      const nameMap = {
        1: "hotels",
        2: "flight",
        3: "transportation",
        4: "private_trips",
        // ... add more mappings as needed ...
      };
      return nameMap[value];
    },
    async addprovider() {
      const formData = new FormData();
     
      const selectedServiceTypes = this.service_types
        .filter((type) => type.selected)
        .map((type) => type.value);

      // Append the array of selected service type values to formData
      selectedServiceTypes.forEach((value) => {
        formData.append("service_type_id[]", value);
      });

      formData.append("name", this.name);
      formData.append("text", this.text);

      try {
        const response = await axios.post("/providers", formData);
        Swal.fire({
          title: "",
          text: response.data.message,
          icon: "success",
        });
         this.$router.push("/provider");
      } catch (error) {
        Swal.fire({
          title: "",
          text: error.response.data.message,
          icon: "error",
        });
      }
    },
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  }
};
</script>

<style lang="scss" scoped>
@import "./_provider.scss";
</style>
