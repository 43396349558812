<template>
  <div class="container-fluid">
    <div class="row d-flex justify-content-between">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-4">
            <div class="d-flex align-items-center bg-light">
              <div
                class="img p-3 rounded-2 bg-img d-flex align-items-center justify-content-end"
              >
                <img src="@/assets/media/image/icon/menu-board.svg" alt="" />
              </div>
              <div
                class="text d-flex align-items-center justify-content-start w-100 flex-column"
              >
                <p class="text_point text-end">
                  {{ $t("total_reservation") }}
                </p>
                <h6 class="point">{{ total_booking }}</h6>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="d-flex align-items-center bg-light">
              <div
                class="img p-3 rounded-2 bg-img d-flex align-items-center justify-content-end"
              >
                <img src="@/assets/media/image/icon/Vectord.svg" alt="" />
              </div>
              <div
                class="text d-flex align-items-center justify-content-start w-100 flex-column"
              >
                <p class="text_point text-end">
                  {{ $t("completd_reservation") }}
                </p>
                <h6 class="point">{{ confirmed_booking }}</h6>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="d-flex align-items-center bg-light">
              <div
                class="img p-3 rounded-2 bg-img d-flex align-items-center justify-content-end"
              >
                <img
                  src="@/assets/media/image/icon/clipboard-close.svg"
                  alt=""
                />
              </div>
              <div
                class="text d-flex align-items-center justify-content-start w-100 flex-column"
              >
                <p class="text_point text-end">
                  {{ $t("losted_reservation") }}
                </p>
                <h6 class="point">{{ losted_booking }}</h6>
              </div>
            </div>
          </div>
        </div>

        <chartsDataMostCountries />

        <div class="row my-4 border p-3 rounded-2">
          <div class="title d-flex align-items-center justify-content-between">
            <h5 class="text-book">{{ $t("last_reservation") }}</h5>
            <div class="form-group w-25">
              <Multiselect
                v-model="nationalityValue_last_reservation"
                :options="nationalities"
                :placeholder="$t('choose_nationality')"
                label="title"
                track-by="id"
                :clear-on-select="true"
                select-label=""
                :hide-selected="true"
                @keyup="searchNationality"
                @update:model-value="
                  lastest_booking(nationalityValue_last_reservation.id)
                "
              >
              </Multiselect>
            </div>
          </div>
          <div class="table-responsive w-100 p-0">
            <table class="Books w-100">
              <thead>
                <th class="text-center">{{ $t("name") }}</th>
                <th class="text-center">{{ $t("Hotel") }}</th>
                <th class="text-center">{{ $t("number of nights") }}</th>
                <th class="text-center">{{ $t("number of rooms") }}</th>
                <th class="text-center">{{ $t("price") }}</th>
                <th class="text-center">{{ $t("status") }}</th>
              </thead>
              <tbody>
                <tr v-for="item in last_booking" :key="item.id">
                  <td class="text-center">
                    {{ item.client_name ? item.client_name : "-" }}
                  </td>
                  <td class="text-center">
                    {{ item.hotel_name ? item.hotel_name : "-" }}
                  </td>
                  <td class="text-center">
                    {{ item.number_of_nights ? item.number_of_nights : "-" }}
                  </td>
                  <td class="text-center">
                    {{ item.rooms_number ? item.rooms_number : "-" }}
                  </td>
                  <td class="text-center">
                    {{
                      item.overall_selling_price
                        ? item.overall_selling_price
                        : "-"
                    }}
                  </td>
                  <td class="text-center cancel p-0" v-if="item.status === 4">
                    {{ $t("canceled") }}
                  </td>
                  <td class="text-center p-0" v-if="item.status === 3">معدل</td>
                  <td class="text-center p-0 accept" v-if="item.status === 2">
                    {{ $t("accepted") }}
                  </td>
                  <td class="p-0 text-center pendding" v-if="item.status === 1">
                    {{ $t("pending") }}
                  </td>
                  <td class="text-center p-0 initial" v-if="item.status === 0">
                    {{ $t("first") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <chartCustomerReservations />
      </div>

      <div class="col-lg-6">
        <chartReverseRate />

        <chartTotalProfit />

        <div class="row my-4 border p-3 rounded-2 me-3">
          <div class="title d-flex align-items-center justify-content-between">
            <h5 class="text-book">{{ $t("Most booked customers") }}</h5>
            <div class="form-group w-25">
              <Multiselect
                v-model="nationalityValue_most_booked"
                :options="nationalities"
                :placeholder="$t('choose_nationality')"
                label="title"
                track-by="id"
                :clear-on-select="true"
                select-label=""
                :hide-selected="true"
                @keyup="searchNationality"
                @update:model-value="
                  client_Booking(nationalityValue_most_booked.id)
                "
              >
              </Multiselect>
            </div>
          </div>
          <div class="table-responsive">
            <table class="Books w-100">
              <thead class="p-2">
                <th class="text-center">{{ $t("name") }}</th>
                <th class="text-center">{{ $t("nationality") }}</th>
                <th class="text-center">{{ $t("Number of booking times") }}</th>
              </thead>
              <tbody>
                <tr v-for="item in client_booking" :key="item.id">
                  <td class="text-center">{{ item.name ? item.name : "-" }}</td>
                  <td class="text-center">
                    {{ item.nationality ? item.nationality : "-" }}
                  </td>
                  <td class="text-center">
                    {{ item.booking_count ? item.booking_count : "-" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Chart from "primevue/chart";
import Multiselect from "vue-multiselect";
import axios from "axios";
import chartsDataMostCountries from "./cahrts_hotel/MostCountries.vue";
import chartCustomerReservations from "./cahrts_hotel/CustomerReservations.vue";
import chartReverseRate from "./cahrts_hotel/ReverseRate.vue";
import chartTotalProfit from "./cahrts_hotel/TotalProfit.vue";
export default {
  name: "Hotels_comp",

  data() {
    return {
      last_booking: [],
      client_booking: [],
      nationalities: [],
      nationalityValue_most_booked: "",
      nationalityValue_total_profit: "",
      nationalityValue_reserve: "",
      nationalityValue_customer_chart: "",
      nationalityValue_last_reservation: "",
      nationalityValue_most_reserved_country: "",
      nationalityValue: "",
      total_booking: 0,
      confirmed_booking: 0,
      losted_booking: 0,
    };
  },
  mounted() {
    this.fetchBooking();
    this.lastest_booking();
    this.client_Booking();
    this.searchNationality();
    // this.citiess();
    // this.reverser();
  },
  methods: {
    searchNationality(event) {
      const searchText = {
        word: event == undefined ? "" : event.target.value,
      };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => {
          // console.log(res.data.data.data, "searchNationality");
          this.nationalities = res.data.data.data;
        })
        .catch((err) => console.log(err));
    },
    client_Booking(country_id) {
      const formData = new FormData();
      formData.append("country_id", country_id ? country_id : 598);
      axios.post("/must_client_booking", formData).then((res) => {
        this.client_booking = res.data.data;
        // console.log(res, "client_booking");
      });
    },
    lastest_booking(country_id) {
      const formData = new FormData();
      formData.append("country_id", country_id ? country_id : 598);
      axios.post("/latest_booking", formData).then((res) => {
        this.last_booking = res.data.data;
        // console.log(res.data, "last_bookingsssssffsda");
      });
    },
    fetchBooking() {
      axios.get("/booking_statistics").then(({ data }) => {
        this.total_booking = data.data.total_booking;
        this.confirmed_booking = data.data.confirmed_booking;
        this.losted_booking = data.data.losted_booking;
        // console.log(this.confirmed_booking, "booking");
      });
    },

    // reverser(country_id) {
    //   const formData = new FormData();
    //   formData.append("country_id", country_id ? country_id : 598);
    //   axios.post("/fetchBookingChart", formData).then((res) => {
    //     this.labels_booking = res.data.data.bookings;
    //     this.label_days = res.data.data.days;
    //     this.labels_booking.map((booking) => {
    //       this.bookings.push(booking);
    //     });
    //     this.label_days.map((day) => {
    //       this.days.push(day);
    //     });
    //     // console.log(this.bookings, "bookings");
    //     // console.log(this.days, "days");
    //   });
    // },

    // citiess(country_id) {
    //   const formData = new FormData();
    //   formData.append("country_id", country_id ? country_id : 598);
    //   axios.post("/bookingCitiesChart", formData).then((res) => {
    //     this.label_citys = res.data.cities_name;
    //     this.label_count = res.data.cities_count;
    //     this.label_complete_coount = res.data.cities_complete_count;
    //     this.label_citys.map((label) => {
    //       this.cities.push(label);
    //     });
    //     this.label_count.map((label) => {
    //       this.count.push(label);
    //     });
    //     this.label_complete_coount.map((label) => {
    //       this.complete_count.push(label);
    //     });
    //     // console.log(this.cities, "cities");
    //     // console.log(this.count, "count");
    //     // console.log(this.complete_count, "complete_count");
    //   });
    // },
  },

  components: {
    // Chart,
    Multiselect,
    chartsDataMostCountries,
    chartCustomerReservations,
    chartReverseRate,
    chartTotalProfit,
  },
};
</script>

<style lang="scss" scoped>
.text_point {
  color: #656b78;
  font-family: "regular";
  font-size: 0.8rem;
  margin: 0.5rem 0 0.5rem 0;
}
.bg-img {
  background: #0a909b;
}
.point {
  color: #0b1427;
  font-weight: bold;
  font-size: 1.3rem;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.p-chart.h-80 {
  min-height: 28dvh;
}
.linechart {
  min-height: 28dvh;
}
.text-book {
  color: #0b1427;
  font-weight: bold;
  font-size: 1.2rem;
}
.table-responsive {
  min-height: 30dvh;
  max-height: 30dvh;
  overflow-y: auto;
}
.text-point {
  color: #656b78;
  font-family: "regular";
  font-size: 1rem;
}
.points {
  color: #0b1427;
  font-size: 2rem;
  font-family: "bold";
}
thead {
  background-color: #eef4f4 !important;
  th.text-center {
    padding: 0.8rem;
  }
}
.pendding {
  color: #c57016;
}
.cancel {
  color: #e23535;
}
.accept {
  color: #2a8f6f;
}
.initial {
  color: #1664b8;
}
</style>
