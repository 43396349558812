<template>
  <div class="app-section m-3 p-3 bg-white">
    <h4 v-if="!$route.params.id">{{ $t("add_section") }}</h4>
    <h4 v-else>{{ $t("edit_section") }}</h4>
    <form @submit.prevent="addSection()">
      <div class="row">
        <div class="form-group col-12 mt-3">
          <label for="">{{ $t("title") }}</label>
          <div class="accordion" id="accordionExample1">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  {{ $t("title") }}
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample1"
              >
                <div class="accordion-body">
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(section_title, index) in Form.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ section_title.locale }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="section_title.title"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="text-danger"
              v-if="v$.Form.title.$error"
              v-text="v$.Form.title.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="form-group">
            <label for="">{{ $t("describtion") }}</label>
            <div class="accordion" id="accordionExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("describtion") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(section_describtion, index) in Form.subtitle"
                      :key="index"
                    >
                      <label class="mt-3"
                        >{{ $t("describtion") }} ({{
                          section_describtion.locale
                        }})</label
                      >
                      <Editor
                        v-model="section_describtion.description"
                        editorStyle="height: 120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <span
                class="text-danger"
                v-if="v$.Form.subtitle.$error"
                v-text="v$.Form.subtitle.$errors[0].$message"
              ></span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-3">
          <label class="cities fw-bold">{{ $t("choose_nationality") }}</label>
          <div class="card flex justify-content-center">
            <Multiselect
              v-model="nationalityValue"
              :options="nationalities"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose_nationality')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
              @keyup="searchNationality"
            >
            </Multiselect>
          </div>
          <span
            class="text-danger"
            v-if="v$.nationalityValue.$error"
            v-text="v$.nationalityValue.$errors[0].$message"
          ></span>
        </div>
        <div class="col-12 col-md-6 mt-3 fw-bold">
          <label class="cities fw-bold">اختار البلد</label>
          <div class="card flex justify-content-center">
            <Multiselect
              v-model="countryValue"
              :options="countries"
              label="title"
              track-by="id"
              :placeholder="$t('choose_country')"
              :clear-on-select="true"
              select-label=""
              :multiple="true"
              :hide-selected="true"
              @keyup="searchCountries"
            >
            </Multiselect>
          </div>
          <span
            class="text-danger"
            v-if="v$.countryValue.$error"
            v-text="v$.countryValue.$errors[0].$message"
          ></span>
        </div>
        <div class="col-12 mt-3">
          <div class="filters d-flex align-items-center">
            <input
              type="radio"
              id="hotels"
              name="filter"
              value=""
              checked
              hidden
              @click="showInputs"
            />
            <div class="d-flex align-items-center justify-content-center">
              <label
                for="hotels"
                class="mb-0 px-1 text-secondary-emphasis text-light"
                >{{ $t("hotels") }}</label
              >
            </div>
          </div>
        </div>
        <div v-if="showInput" class="row mt-3">
          <div class="form-group col-12 col-md-6">
            <label class="typo__label custom_label">{{
              $t("choose_city")
            }}</label>
            <div class="hotel-form">
              <Multiselect
                v-model="hotel.distination_city"
                :options="cities"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose_city')"
                select-label=""
                :hide-selected="true"
                @keyup="searchCity"
                @update:model-value="searchHotels(hotel.distination_city)"
              >
              </Multiselect>
              <span
                class="text-danger"
                v-if="v$.hotel.distination_city.$error"
                v-text="v$.hotel.distination_city.$errors[0].$message"
              ></span>
            </div>
          </div>
          <div class="form-group col-12 col-md-6">
            <label class="typo__label custom_label">{{
              $t("Choose_hotel")
            }}</label>
            <div class="hotel-form">
              <Multiselect
                v-model="hotelValue"
                :options="hotelsSearch"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('Choose_hotel')"
                select-label=""
                :multiple="true"
                :hide-selected="true"
              >
              </Multiselect>
              <!-- @update:model-value="fetchRooms(distIndexz)" -->
              <span
                class="text-danger"
                v-if="v$.hotelValue.$error"
                v-text="v$.hotelValue.$errors[0].$message"
              ></span>
            </div>
          </div>
        </div>
        <button type="submit" class="add btn PrimaryButton">
          {{ $t("save") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Editor from "primevue/editor";
import axios from "axios";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  components: {
    Multiselect,
    Editor,
  },
  data() {
    return {
      v$: useVuelidate(),
      showInput: true,
      languages: ["ar", "en", "id"],
      options: {
        placeholder: this.$t("describtion"),
        theme: "snow",
      },
      distination_hotel: "",
      distination_city: "",
      hotelsSearch: [],
      cities: [],
      hotel: {
        distination_city: "",
      },
      hotelValue: "",
      Form: {
        title: [],
        subtitle: [],
      },
      nationalities: [],
      countries: [],
      nationalityValue: "",
      countryValue: "",
    };
  },
  validations() {
    return {
      Form: {
        title: this.Form.title.map(() => {
          return {
            title: { required },
          };
        }),
        subtitle: this.Form.subtitle.map(() => {
          return {
            description: { required },
          };
        }),
      },
      nationalityValue: {
        required,
      },
      countryValue: {
        required,
      },
      hotel: {
        distination_city: this.showInput != false ? { required } : "",
      },

      hotelValue: this.showInput != false ? { required } : "",
    };
  },
  methods: {
    showInputs() {
      this.showInput = !this.showInput;
    },
    setTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          locale: language,
          title: "",
        });
        this.Form.subtitle.push({
          locale: language,
          description: "",
        });
      });
    },
    searchHotels(ele) {
      axios
        .post(`/fetch_hotels_by_city_id`, { city_ids: [ele.id] })
        .then(({ data }) => {
          this.hotelsSearch = data.data;
        })
        .catch((err) => console.log(err));
    },
    searchCity(event) {
      // console.log(event)
      const search = { word: event.target.value };

      axios.post("/fetch_cities", search).then(({ data }) => {
        this.cities = data.data.data;
      });
    },
    searchHotelsByWord(event) {
      const search = { word: event.target.value };
      axios
        .post(`/search_hotels_by_city_id`, search)
        .then(({ data }) => {
          this.hotelsSearch = data.data;
          // console.log(this.hotelsSearch);
        })
        .catch((err) => console.log(err));
    },
    searchNationality(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
    },
    searchCountries(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => {
          this.countries = res.data.data.data;
        })
        .catch((err) => console.log(err));
    },
    addSection() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();

        this.Form.title.forEach((ele) => {
          formData.append(`title_${ele.locale}`, ele.title);
        });
        this.Form.subtitle.forEach((ele) => {
          formData.append(`description_${ele.locale}`, ele.description);
        });

        for (let index = 0; index < this.countryValue.length; index++) {
          formData.append(`country_ids[${index}]`, this.countryValue[index].id);
        }

        for (let index = 0; index < this.nationalityValue.length; index++) {
          formData.append(
            `nationality_ids[${index}]`,
            this.nationalityValue[index].id
          );
        }
        for (let index = 0; index < this.hotelValue.length; index++) {
          formData.append(`hotel_ids[${index}]`, this.hotelValue[index].id);
        }
        // console.log(this.nationalityValue)

        if (this.$route.params.id != undefined) {
          axios
            .post(`/hotelcards/update/${this.$route.params.id}`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/sections");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          axios
            .post("/hotelcards", formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
  },
  mounted() {
    this.setTitle();
    if (this.$route.params.id != undefined) {
      axios
        .get(`/hotelcards/${this.$route.params.id}`)
        .then(({ data }) => {
          this.Form.title = data.data.title;
          this.Form.subtitle = data.data.description;
          // this.nationalityValue = data.data.nationalites;
          // this.countryValue = data.data.countries[0].country_title;

          this.countryValue = data.data.countries;

          this.hotelValue = data.data.hotels;
          this.nationalities = data.data.nationalites;

          this.nationalityValue = data.data.nationalites;
          // console.log(this.nationalityValue);

          // console.log(data.data, "fff");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    }
  },
};
</script>

<style scoped lang="scss">
.filters {
  gap: 12px;
  > div {
    border-radius: 8px;
    border: 0.5px solid rgba(208, 210, 215, 0.5);
    background: #f7f7f7;
    width: 89px;
    height: 36px;
    color: #656b78;
    flex-shrink: 0;
  }
  input[type="radio"]:checked + div {
    background-color: #0a909b;
  }
  input[type="radio"]:checked + label {
    color: #fff;
  }
}
</style>
