<template>
    <div class="row form-group">
        <!-- start input group -->
        <div class="col-lg-6 col-12">
            <div class="form-group w-100">
                <label class="form-label"> {{ $t("choose_country") }}</label>
                <Multiselect
                    v-model="countryValue"
                    :options="countries.map((country) => country.id)"
                    :custom-label="(opt) => countries.find((x) => x.id == opt).title"
                    :clear-on-select="true"
                    :placeholder="$t('choose_country')"
                    select-label=""
                    :hide-selected="true"
                    @update:model-value="FetchCityFromCountry"
                >
                </Multiselect>
            </div>
        </div>
        <!-- finish input group -->
        
        <!-- start input group -->
        <div class="col-lg-6 col-12">
            <div class="form-group w-100">
                <label class="form-label"> {{ $t("city") }}</label>
                <Multiselect
                    v-model="cityValues"
                    :options="cities.map((city) => city.id)"
                    :custom-label="(opt) => cities.find((x) => x.id == opt).title"
                    :clear-on-select="true"
                    :placeholder="$t('enter_city')"
                    select-label=""
                    :multiple="true"
                    :hide-selected="true"
                    @update:model-value="emitCitySelected"
                >
                </Multiselect>
            </div>
        </div>
        <!-- finish input group -->
    </div>
</template>

<script>
import axios from 'axios';
import Multiselect from "vue-multiselect";

export default {
    name: "Country-custom",
    components: {
      Multiselect  
    },
    data() {
        return {
            countries: [],
            cities: [],
            countryValue: "",
            cityValues: [],
        }
    },
    methods: {
        //fetch country
        fetch_country() {
            axios.post("/fetch_countries").then(({ data }) => {
                this.countries = data.data;
                // console.log(this.countries);
            });
        },

        FetchCityFromCountry(value) {
            let basic_country_ids = {
                country_id: [value],
            };
            axios
            .post("/fetch_cities_by_country_id", basic_country_ids)
            .then(({ data }) => {
                this.cities = data.data;
            });
        },

        emitCountrySelected() {
            this.$emit('multiselect-changed', this.countryValue);
        },

        emitCitySelected() {
            this.$emit('multiselect-changed', this.cityValues);
        },
    },
    created() {
        this.fetch_country();
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss" scoped>
    @import "../../../view/setup/_setup.scss";
</style>