<template>
  <Skeleton width="10%" class="mb-2"></Skeleton>
  <Skeleton width="5%" class="mb-3"></Skeleton>
  <div class="d-flex flex-column gap-2 w-100 my-2" v-for="i in 3" :key="i">
    <Skeleton width="4%"></Skeleton>
    <Skeleton height="2rem" width="20%" class="mb-2"></Skeleton>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";
export default {
  components: {
    Skeleton,
  },
};
</script>

<style></style>
