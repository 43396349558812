<template>
  <section class="auth">
    <div class="container">
      <form class="forget-card" @submit.prevent="forget_password">
        <h3>{{$t("forget_password")}}</h3>
        <h6>{{$t("enter_register_email")}}</h6>
        <div class="form-group">
          <label for="exampleInputEmail1"
            >{{$t("email")}}</label
          >
          <input
            type="email"
            class="form-control"
            v-model="Form.email"
            :placeholder="$t('email')"
            required
          />
        </div>
        <button type="submit" class="btn submit_btn">{{$t("send")}}</button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";

export default {
    name: "forget-page",
    data() {
        return {
            Form: {
                email: ""
            }
        }
    },
    methods: {
        forget_password() {
        axios
            .post("/forget_password", this.Form)
            .then(({ data }) => {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: data.message,
                    timer: 1500,
                });
                this.$router.push("/verification_code");
                localStorage.setItem("email", this.Form.email)
                // console.log(data.message)
            })
            .catch((error) => {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: error.response.data.message,
                    timer: 1500,
                });
                return error;
            })
            .finally(() => {
                //Perform action in always
            });
        },
    },
};
</script>

<style lang="scss" scoped>
  @import "./_auth.scss";
</style>