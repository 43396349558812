<template>
  <div class="invoice invoice-ar">
    <div class="row">
      <div class="col-8">
        <div class="main-text">
          <div class="text-logo">
            <h4>{{ pills.book_number }}</h4>
            <h4>القسيمة</h4>
          </div>
          <div class="next-text">
            <h4>قسيمة حجز فندقي</h4>
            <h5>
              حالة الحجز: <span>{{ book_status_name }}</span>
            </h5>
            <h6 v-if="pills.created_at_date">
              تاريخ الحجز:
              {{ pills.created_at_date }} -
              {{ pills.created_at_time }}
            </h6>
          </div>
        </div>
        <!-- <div class="under-text">
          <div class="head">
            <h4>سيدي العزيز : {{ pills.client_name }}</h4>
            <h5>
              تحيات من <span>{{ pills.organization?.name }}</span>
            </h5>
            <div class="pragra">
              <p>
                بادئ ذي بدء ، نود أن نغتنم هذه الفرصة للترحيب بك
                 في
                <strong> {{ pills.organization?.name }} </strong> والسياحة نحن
                 يسعدني تأكيد الحجز التالي على أ
                <span>{{ book_status_name }}.</span>
              </p>
            </div>
          </div>
        </div> -->
      </div>
      <div class="col-4">
        <div class="logo">
          <img :src="pills.organization?.default_image" />
        </div>
      </div>
    </div>

    <div class="table_account" v-if="pills?.account">
      <div class="list_account">
        <div class="account_data">
          <p class="title_account">اسم العميل</p>
          <p class="text_account">: {{ pills.client_name }}</p>
        </div>
        <div class="account_data">
          <p class="title_account">اسم النزيل</p>
          <p class="text_account">: {{ pills.guest_name }}</p>
        </div>
        <div class="account_data">
          <p class="title_account">الجنسية</p>
          <p class="text_account">: {{ pills.nationality_name }}</p>
        </div>
      </div>
      <div class="list_account">
        <div class="account_data">
          <p class="title_account">تاريخ الدخول</p>
          <p class="text_account">
            : {{ pills.check_in_date }},
            {{ updateHijriDate(new Date(pills.check_in_date)) }}
          </p>
        </div>
        <div class="account_data">
          <p class="title_account">تاريخ الخروج</p>
          <p class="text_account">
            : {{ pills.check_out_date }},
            {{ updateHijriDate(new Date(pills.check_out_date)) }}
          </p>
        </div>
      </div>
      <div class="list_account">
        <div
          class="account_data"
          v-for="(hotel, index) in pills.hotels"
          :key="index"
        >
          <p class="title_account">اسم الفندق {{ index + 1 }}</p>
          <p class="text_account hotel_name">: {{ hotel.hotel_name }}</p>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 10%" class="frist">نوع الغرفة</th>
            <th style="width: 10%" scope="col">الوجبة</th>
            <th style="width: 10%" scope="col">تاريخ الدخول</th>
            <th style="width: 10%" scope="col">تاريخ الخروج</th>
            <th style="width: 10%" scope="col">عدد الليالي</th>
            <th style="width: 10%" scope="col">عدد الأشخاص</th>
            <th style="width: 10%" scope="col">رقم التأكيد</th>
            <!-- <th style="width: 10%" scope="col">RATE</th>
              <th style="width: 10%" class="last">TOTAL</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(room, index) in pills.rooms" :key="index">
            <td class="frist">
              <p class="mb-0 fs-8">{{ room.hotel_name }}</p>
              <p class="mb-0 fs-8">
                {{ room.room_number }} X {{ room.title_hotel_room_view }}
              </p>
            </td>
            <td>{{ room.meal_type?.title }}</td>
            <td>
              <p class="mb-0">
                {{ room.check_in_date }}
              </p>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_in_date))
              }}</span>
            </td>
            <td>
              <P class="mb-0">
                {{ room.check_out_date }}
              </P>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_out_date))
              }}</span>
            </td>
            <td>{{ room.number_of_nights }}</td>
            <td>{{ room.guest_number }}</td>
            <td>{{ room.hotel_reference }}</td>
            <!-- <td>260</td>
              <td class="last">{{ pills.total_selling_price }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>

    <div class="end-page">
      <div class="hed terms" v-if="pills?.Conditions">
        <h4>الشروط والأحكام</h4>
        <p class="text" v-html="pills?.Conditions?.description"></p>
      </div>
      <div class="hed terms" v-if="pills?.book_notes">
        <h4>الملاحظات</h4>
        <p class="text" v-html="pills?.book_notes"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pill-hotel-voucher",
  props: {
    pills: Object,
    terms: String,
    total_selling_price_with_out_tax: String,
    selling_tax: String,
    total_selling_price: String,
    book_status_name: String,
  },
};
</script>

<style lang="scss" scoped>
@import "./_pill.scss";
</style>
