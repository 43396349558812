<template>
    <ul class="social_media">
        <li><a :href="`${organization?.facebook}`" v-if="organization?.facebook && organization?.facebook != 'undefined'" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
        <li><a :href="`${organization?.linkedin}`" v-if="organization?.linkedin && organization?.linkedin != 'undefined'" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a></li>
        <li><a :href="`${organization?.twitter}`" v-if="organization?.twitter && organization?.twitter != 'undefined'" target="_blank"><i class="fa-brands fa-x-twitter"></i></a></li>
        <li><a :href="`${organization?.instagram}`" v-if="organization?.instagram && organization?.instagram != 'undefined'" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
        <li><a :href="`${organization?.tiktok}`" v-if="organization?.tiktok && organization?.tiktok != 'undefined'" target="_blank"><i class="fa-brands fa-tiktok"></i></a></li>
        <li><a :href="`${organization?.snapchat}`" v-if="organization?.snapchat && organization?.snapchat != 'undefined'" target="_blank"><i class="fa-brands fa-snapchat"></i></a></li>
    </ul>
</template>

<script>
export default {
    name: "social-media",
    props:{
        organization: Object
    }
}
</script>

<style lang="scss" scoped>
    .social_media {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0 !important;
        li {
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #08589B;
            border: 1px solid #08589B;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            transition: 0.7s ease-in-out;
            margin-inline-end: 0.5rem;
            color: #fff;
            &:hover {
            background-color: transparent;
            color: #08589B;
            border: 1px solid #08589B;
            transition: 0.7s ease-in-out;
            }
        }
        }
    }
</style>