<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <h4>{{$t("Hotel revenues")}}</h4>

        <!-- <div>
            <router-link to="/visa/add" class="PrimaryButton btn w-100"
            ><i class="fa-solid fa-plus"></i>New visa type</router-link
            >
      </div> -->
    </div>

    <div class="search-visa my-3">
        <form>
            <i class="fa-solid fa-magnifying-glass"></i>
            <input type="text" class="form-control" :placeholder="$t('search...')" />
        </form>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table visaType" id="VisaTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("confirmation_number") }}</th>
            <th>{{ $t("create_date") }}</th>
            <th>{{ $t("check-in") }}</th>
            <th>{{ $t("check-out") }}</th>
            <th>{{ $t("original-price") }}</th>
            <th>{{ $t("organization-price") }}</th>
            <th>{{ $t("pinal-price") }}</th>
            <th>{{ $t("payment-profit") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr v-for="(visa, index) in allVisa.data" :key="index">
            <td> {{visa.id}} </td>
            <td>{{ visa.type_name }}</td>
            <td>{{ visa.country == null ? "" : visa.country.title }}</td>
            <td>{{ visa.accessibility == 2 ? $t("multiple") : visa.accessibility == 1 ? "once" : ""}}</td>
            <td>{{ visa.duration_of_stay }}</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa-solid fa-bars"></i>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link :to="`/visa/edit/${visa.id}`" class="dropdown-item">
                      <i class="fa-regular fa-pen-to-square"></i>
                      <span>{{$t("Edit")}}</span>
                    </router-link>
                  </li>
                  <li>
                    <div class="dropdown-item text-danger" @click="deleteVisa(index)">
                      <i class="fa-solid fa-trash"></i>
                      <span>{{$t("Delete")}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </td>
          </tr> -->
        </tbody>
      </table>
      </div>
    </div>
    
    <div class="row">
      <pagination
        :data="allVisa"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchAllVisa"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
// import $ from "jquery";
export default {
  name: "allVisa",
  components: {
    pagination,
  },
  data() {
    return {
      allVisa: [],
    };
  },
  methods: {
    fetchAllVisa(page = 1){
      axios.get(`/visa_types?page=${page}`).then(({data})=>{
        this.allVisa = data.data
        // console.log(this.allVisa.data)
        // console.log(this.allVisa)
      })
    },
    //delete visa
    deleteVisa(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.allVisa.data[index].id;
          const fromClick = true;
          axios
            .delete(`/visa_types/${delete_id}`, { fromClick })
            .then(({ data }) => {
               if (data.status == true) {
                 Swal.fire("", data.message, "success");
                 this.allVisa.data.splice(index, 1); // Remove the deleted item from the array
              }  else {
                 Swal.fire("", data.message, "error");
               }
               return data;
            });
        }
      });
    },
  },
  mounted(){
    // this.fetchAllVisa();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.main_header_style{
    align-items: center;
    h4{
        margin-bottom: 0;
    }
}
.search-visa{
    form{
        position: relative;
        i{
            position: absolute;
            top: 12px;
            right: 15px;
            color: #9CA2AC;
        }
        input{
            padding-right: 40px !important;
        }
    }
}
</style>
