<template>
  <section class="card_style mt-0">
    <div class="main_header_style">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4 class="mb-0">
          {{ $t("Add New Safe") }}
        </h4>
      </div>
    </div>
    <form class="py-3" @submit.prevent="addSafe()">
      <!-- ------------------------------------------------------     -->
      <div class="row">
        <div class="col-12 mb-4 safe-status">
          <h4>{{ $t("Safe Status") }}</h4>
          <form class="row">
            <div
              class="form-check col-lg-3 col-md-12 col-12 d-flex align-items-center"
            >
              <input
                class="form-check-input mx-3"
                type="radio"
                name="status"
                v-model="active"
                value="1"
                id="active"
              />
              <label class="form-check-label mb-0" for="active">
                {{ $t("Active") }}
              </label>
            </div>

            <div
              class="form-check col-lg-3 col-md-12 col-12 d-flex align-items-center"
            >
              <input
                class="form-check-input mx-3"
                type="radio"
                name="status"
                v-model="active"
                value="0"
                id="not-active"
              />
              <label class="form-check-label mb-0" for="not-active">
                {{ $t("Not Active") }}
              </label>
            </div>
            <span
              class="text-danger"
              v-if="v$.active.$error"
              v-text="v$.active.$errors[0].$message"
            ></span>
          </form>
        </div>
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="form-group">
            <label class="form-label"> {{ $t("Safe Name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('Write Safe Name')"
              v-model="title"
              required
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.title.$error"
            v-text="v$.title.$errors[0].$message"
          ></span>
        </div>

        <div class="col-12 col-lg-12">
          <div class="form-group">
            <label class="form-label"> {{ $t("Safe Description") }}</label>
            <textarea
              type="text"
              class="form-control"
              :placeholder="$t('Write Safe description')"
              v-model="text"
              required
            ></textarea>
          </div>
          <span
            class="text-danger"
            v-if="v$.text.$error"
            v-text="v$.text.$errors[0].$message"
          ></span>
        </div>
        <!-- finish input group -->

        <!-- <div class="col-12 mb-4 safe-status">
          <h4>{{ $t("Safe Status") }}</h4>
            <form class="row">
              <div class="col-3 d-flex align-items-center">
                <input type="radio" value="1" name="status" class="mx-3" v-model="active"/>
                <label>{{ $t("Active") }}</label>
              </div>
              <div class="col-3 d-flex align-items-center">
                <input type="radio" value="0" name="status" class="mx-3" v-model="active"/>    
                <label>{{ $t("Not Active") }}</label>
              </div>
            </form>
        </div> -->

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-label"> {{ $t("Initial Balance") }}</label>
            <input
              type="number"
              class="form-control initial-balance"
              :placeholder="$t('Initial Balance')"
              v-model="money"
              required
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.money.$error"
            v-text="v$.money.$errors[0].$message"
          ></span>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <!-- <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-label"> {{ $t("Branch") }}</label>
            <select class="form-control" v-model="branch">
              <option>{{$t("add it to a specific branch")}}</option>
              <option>{{$t(" branch")}}</option>
              <option>{{$t("hhuj branch")}}</option>
              <option>{{$t("specific branch")}}</option>
              <option>{{$t(" specific branch")}}</option>
              <option>{{$t("add it to a specific branch")}}</option>
              <option>{{$t("add it to a specific branch")}}</option>
            </select>
          </div>
        </div> -->
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-6">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("Save") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "add-employment",
  data() {
    return {
      v$: useVuelidate(),
      title: "",
      text: "",
      active: "",
      money: "",
      branch: "",
      type: 2,
    };
  },
  validations() {
    return {
      title: { required },
      text: { required },
      active: { required },
      money: { required },
      // branch: { required },
    };
  },
  methods: {
    async addSafe() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        formData.append("type", this.type);
        formData.append("title", this.title);
        formData.append("text", this.text);
        formData.append("active", this.active);
        formData.append("money", this.money);
        formData.append("branch", this.branch);
        axios
          .post("/organization_accounts", formData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.$router.push("/safe");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
  components: { AddFavDailog },
};
</script>
<style lang="scss" scoped>
@import "./_safe";
</style>
