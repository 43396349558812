<template>
  <div>
    <i class="fa-solid fa-circle-plus dialog" @click="visible = true"></i>
    <Dialog
      v-model:visible="visible"
      modal
      :header="$t('add_hotel_to_suppliers')"
      :style="{ width: '40vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      :dismissableMask="true"
    >
      <div class="w-100">
        <form @submit.prevent="addHotel()" class="w-100">
          <div>
            <div class="form-group mb-3">
              <label for="exampleFormControlInput1" class="form-label">
                {{ $t("hotel_name") }}
              </label>
              <Multiselect
                v-model="hotelValue"
                :options="hotels"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
                @keyup="fetchHotels"
              >
              </Multiselect>
            </div>
            <button type="submit" class="btn PrimaryButton">
              {{ $t("add") }}
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Dialog from "primevue/dialog";

export default {
  props: {
    hotel: Object,
  },
  data() {
    return {
      visible: false,
      hotels: [],
      hotelValue: "",
    };
  },
  watch: {
    hotel: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          // console.log(newValue, "hotel");
          this.hotelValue = newValue;
          // this.hotelValue.Object.assing("title", newValue.hotel_name);
          Object.assign(this.hotelValue, { title: newValue.hotel_name });
        }
      },
    },
  },
  components: {
    Multiselect,
    Dialog,
  },
  methods: {
    addHotel() {
      console.log(this.hotelValue);
      axios
        .post("/store_organization_hotels", {
          organization_hotel_ids: [
            this.hotelValue.hotel_id != null
              ? this.hotelValue.hotel_id
              : this.hotelValue.id,
          ],
        })
        .then((res) => {
          this.visible = false;
          Swal.fire({
            icon: "success",
            text: this.$t("hotel_added_successfully"),
            timer: 2000,
          });
          this.$emit("submit");
          console.log(res.data);
        });
    },
    fetchHotels(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_new_home_hotels`, searchText)
        .then((res) => (this.hotels = res.data.data.hotels))
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped></style>
