<template>
  <section class="card_style hotelOffline">
    <!-- skelton -->
    <div class="row" v-if="loading && $route.params.id">
      <div class="col-lg-4 col-md-4 col-12">
        <div class="d-flex w-100">
          <Skeleton size="13rem" class="mx-3 w-100"></Skeleton>
          <div class="d-flex flex-column w-100">
            <Skeleton height="2rem" class="mb-2"></Skeleton>
            <Skeleton height="2rem" class="mb-2"></Skeleton>
            <Skeleton height="2rem" class="mb-2"></Skeleton>
            <Skeleton height="2rem" class="mb-2"></Skeleton>
            <Skeleton height="2rem" class="mb-2"></Skeleton>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 col-12">
        <div class="d-flex w-100 justify-content-end align-items-center">
          <Skeleton width="15%" height="3rem"></Skeleton>
        </div>
      </div>
    </div>
    <!-- skelton -->
    <div class="row" v-else>
      <div class="details">
        <div class="image">
          <img
            :src="
              this.hotel_image != undefined
                ? this.hotel_image
                : this.placeholder_image
            "
          />
        </div>
        <div class="content">
          <div class="title">
            <h3>{{ hotel_title }}</h3>
            <!-- <div class="rate_item">
                        <rate :rates="5" />
                    </div> -->
          </div>
          <p class="city">{{ hotel_country }} , {{ hotel_city }}</p>
          <ul class="detail_list">
            <li>
              <i class="fa-solid fa-location-dot"></i> {{ hotel_data.address }}
            </li>
            <li>
              <i class="fa-solid fa-bed"></i>{{ $t("room_count") }} :
              {{ hotel_data.room_count }}
            </li>
            <li>
              <i class="fa-solid fa-calendar-days"></i>{{ $t("created_at") }} :
              {{ hotel_data.created_at }}
            </li>
          </ul>
        </div>
        <router-link
          :to="`/hotel/editOfflineHotel/${this.hotel_id}`"
          class="btn SecondButton edit_data"
          >{{ $t("edit_data") }}</router-link
        >
      </div>
    </div>
    <div class="row">
      <Tabs />
    </div>
  </section>
</template>

<script>
// import rate from "@/components/rate/rate.vue";
import Tabs from "@/components/hotelOffline/Tabs.vue";
import axios from "axios";
import { computed } from "vue";
import Skeleton from "primevue/skeleton";
export default {
  name: "offlineHotelDetails",
  components: {
    // rate ,
    Tabs,
    Skeleton,
  },
  data() {
    return {
      hotel_id: this.$route.params.id,
      hotel_data: {},
      hotel_title: "",
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "ar",
      hotel_amenities: [],
      placeholder_image: require("@/assets/media/image/hotelImg.png"),
      loading: true,
    };
  },
  methods: {
    fetch_hotel_data() {
      axios.get(`/offline_hotels/${this.hotel_id}`).then(({ data }) => {
        this.hotel_data = data.data;
        // console.log(this.hotel_data);

        data.data.title.forEach((ele) => {
          if (ele.locale == this.lang) {
            this.hotel_title = ele.title;
          }
        });
        if (this.hotel_title == "") {
          this.hotel_title = data.data.title[0].title;
        }

        this.$store.state.amenities = data.data.amenities;
        this.$store.state.entertainments = data.data.entertainments;
        this.$store.state.business = data.data.business;
        this.$store.state.description = data.data.description;


        this.hotel_amenities = this.hotel_data.amenity_types.map((ele) => {
          return {
            row_id: ele.row_id,
            amenity_type: {
              id: ele.id,
              title: ele.title,
            },
            amenities: ele.amenities,
          };
        });

        this.hotel_image =
          data.data.images.length > 0 ? data.data.images[0].image : "";
        this.hotel_city = data.data.city.title;
        this.hotel_country = data.data.country.title;
        this.loading = false;
      });
    },
  },
  provide() {
    return {
      hotel_amenities: computed(() => this.hotel_amenities),
    };
  },
  mounted() {
    this.fetch_hotel_data();
    if (localStorage.getItem("lang") != null) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./_hotel.scss";
</style>
