<template>
  <div class="offcanvas offcanvas-bottom add_recipt" tabindex="-1" id="offcanvasBottom"
    aria-labelledby="offcanvasBottomLabel">
    <div class="offcanvas-header main_header_style">
      <h4 class="offcanvas-title" id="offcanvasBottomLabel">{{ $t("add_traansfer") }}</h4>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body small">
      <form class="form_topic" @submit.prevent="addTransfer()">
        <div class="row">

          <!-- start input group -->
          <div class="col-12 col-lg-6">
            <div class="input-group">
              <label class="form-label"> {{ $t("date_time_transfer") }}</label>
              <datepicker v-model="date" name="from_date" :placeholder="$t('from')" :disabled="true"></datepicker>
            </div>
          </div>
          <!-- finish input group -->
          <div class="col-12"></div>

          <!-- start input group -->
          <div class="col-12 col-lg-6 mb-3">
            <div class="transfer">
              <div class="title">
                <h2>{{ $t("from") }}</h2>
              </div>
              <div class="transfer_container">
                <div class="row" v-for="(fromAccount, index) in fromAccounts" :key="index">
                  <div class="col-3 d-flex align-items-center">
                    <div class="input-group">
                      <label for="from_value" class="form-label">{{ $t("the value") }}</label>
                      <input type="number" id="from_value" class="form-control" :placeholder="0"
                        v-model="fromAccount.amountFrom" />
                    </div>
                  </div>
                  <div class="col-7 d-flex align-items-center">
                    <div class="input-group">
                      <label for="from_acc">{{ $t("the account") }}</label>
                      <Multiselect v-model="fromAccount.branch_from" :options="branches.map((branch) => branch.id)"
                        :custom-label="(opt) => branches.find((x) => x.id == opt).title_ar" :clear-on-select="true"
                        :placeholder="$t('account')" select-label="" :hide-selected="true">
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-2 d-flex align-items-center">
                    <div class="addAccount" @click="addFromAccount()" v-if="index == 0"><i class="fa-solid fa-plus"></i>
                    </div>
                    <div class="removeAccount" @click="removeFromAccount(index)" v-else><i class="fa-solid fa-minus"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 mb-3">
            <div class="transfer">
              <div class="title">
                <h2>{{ $t("to") }} </h2>
              </div>
              <div class="transfer_container">
                <div class="row" v-for="(toAccount, index) in toAccounts" :key="index">
                  <div class="col-3 d-flex align-items-center">
                    <div class="input-group">
                      <label for="to_value" class="form-label">{{ $t("the value") }}</label>
                      <input type="number" id="to_value" class="form-control" :placeholder="0"
                        v-model="toAccount.amountTo" />
                    </div>
                  </div>
                  <div class="col-7 d-flex align-items-center">
                    <div class="input-group">
                      <label for="to_acc">{{ $t("the account") }}</label>
                      <Multiselect v-model="toAccount.branch_to" :options="branches.map((branch) => branch.id)"
                        :custom-label="(opt) => branches.find((x) => x.id == opt).title_ar" :clear-on-select="true"
                        :placeholder="$t('account')" select-label="" :hide-selected="true">
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-2 d-flex align-items-center">
                    <div class="addAccount" @click="addToAccount()" v-if="index == 0"><i class="fa-solid fa-plus"></i>
                    </div>
                    <div class="removeAccount" @click="removeToAccount(index)" v-else><i class="fa-solid fa-minus"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- start input group -->
          <div class="col-12 col-lg-12">
            <div class="input-group">
              <label class="form-label"> {{ $t("statement") }}</label>
              <textarea class="form-control" :placeholder="$t('write here....')" v-model="text"></textarea>
            </div>
          </div>
          <!-- finish input group -->

          <!-- start input group -->
          <div class="col-12">
            <button type="submit" class="btn PrimaryButton w-25">
              {{ $t("add") }}
            </button>
          </div>
          <!-- finish input group -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs3-datepicker";
import axios from "axios";
import Swal from "sweetalert2";
import moment from 'moment';

export default {
  name: "add-construct",
  emits: ["FetchConstruct"],
  components: {
    Multiselect,
    Datepicker,
  },
  data() {
    return {
      branches: [],
      fromAccounts: [{
        id: 1,
        branch_from: null,
        amountFrom: 0,
      }],
      toAccounts: [{
        id: 1,
        branch_to: null,
        amountTo: 0,
      }],
      date: new Date(),
      text: "",
    };
  },
  methods: {
    addFromAccount() {
      this.fromAccounts.push({
        id: this.fromAccounts.length,
        branch_from: null,
        amountFrom: 0,
      })
    },
    removeFromAccount(index) {
      this.fromAccounts.splice(index, 1)
    },
    addToAccount() {
      this.toAccounts.push({
        id: this.toAccounts.length,
        branch_to: null,
        amountTo: 0,
      })
    },
    removeToAccount(index) {
      this.toAccounts.splice(index, 1)
    },
    async addTransfer() {
      let totalAmountFrom = 0;
      this.fromAccounts.forEach((account) => {
        totalAmountFrom = totalAmountFrom + account.amountFrom
      })
      let totalAmountTo = 0;
      this.toAccounts.forEach((account) => {
        totalAmountTo = totalAmountTo + account.amountTo
      })
      // console.log(totalAmountFrom)
      // console.log(totalAmountTo)
      if (totalAmountFrom == totalAmountTo) {
        let daily_restriction = {
          text: this.text,
          type: 1,
          date: moment(this.date).format("YYYY-MM-DD"),
          from: this.fromAccounts.map((account) => {
            return {
              branch_id: account.branch_from,
              amount: account.amountFrom
            }
          }),
          to: this.toAccounts.map((account) => {
            return {
              branch_id: account.branch_to,
              amount: account.amountTo
            }
          })
        }
        await axios.post("/daily_restriction", daily_restriction).then(({ data }) => {
          // console.log(data)
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          })
          this.$emit("FetchConstruct")
        }).catch((error) => {
          Swal.fire({
            title: "",
            text: error.message,
            icon: "error",
          });
        })
      }
      else {
        Swal.fire({
          title: "",
          text: "from Transfer not equal to Transfer",
          icon: "error",
        });
      }
    },
    async getBranches() {
      await axios.get("/get_latest_branch").then(({ data }) => {
        this.branches = data.data
      })
    }
  },
  mounted() {
    this.getBranches();
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "../../view/dashboard/dailyConstructor/_constructor.scss";

.form_topic {
  width: 100%;
}

.transfer {
  .title {
    h2 {
      text-align: center;
      font-family: 'bold';
      font-size: 20px;
      color: #0B1427;
    }
  }

  .transfer_container {
    // border: 1px solid #9CA2AC;
    border-radius: 0 0 10px 10px;
    padding: 20px;
    border-top: 0;

    label {
      font-family: "regular" !important;
    }
  }

  .addAccount,
  .removeAccount {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    background-color: #0A909B;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }

  .removeAccount {
    background-color: #E23535;
  }
}

textarea {
  height: 200px;
}
</style>
