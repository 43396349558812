<template>
  <section class="all_emp card_style mt-0">
    <div class="d-flex align-items-center gap-3">
      <Fav v-tooltip.left="$t('add_to_favorites')" />
      <h4>{{ $t("invoice theme") }}</h4>
    </div>
    <div
      class="main_header_style d-flex align-items-center justify-content-end"
    >
      <!-- <div class="w-100 d-flex align-items-center justify-content-between">
        <input
          type="text"
          v-model="themeText"
          class="form-control w-50"
          @keyup="fetch_theme((page = 1))"
          :placeholder="$t('search...')"
        />
      </div> -->
      <router-link to="/invoice-theme/add" class="PrimaryButton btn"
        ><i class="fa-solid fa-plus"></i> {{ $t("Add New") }}
      </router-link>
    </div>

    <div class="row">
      <table class="table" id="VisaTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("photo") }}</th>
            <th>{{ $t("code") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="theme in 10" :key="theme">
            <td>
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
            <td>
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
            <td>
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
            <td width="10%">
              <Skeleton shape="circle" size="5rem"></Skeleton>
            </td>

            <td v-for="theme in 3" :key="theme">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(theme, index) in themes" :key="index">
            <td>{{ theme.id }}</td>
            <td>
              <!-- <img :src="theme.image" width="50" height="50" /> -->

              <Button label="Show" @click="showImage(theme.image)" class="btn">
                <img :src="theme.image" width="50" height="50"
              /></Button>
            </td>
            <td>{{ theme.code }}</td>
            <td>
              <button
                @click="deletethemes(theme.id, index)"
                class="btn btn-danger mx-1 w-auto"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Dialog
      v-model:visible="visible"
      :dismissableMask="true"
      maximizable
      modal
      :style="{ width: '50rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <img :src="theme_image" class="w-100" />
    </Dialog>

    <!-- <div class="row">
      <pagination
        :data="themes"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_theme"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div> -->
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
// import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Fav from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
import Dialog from "primevue/dialog";

import Button from "primevue/button";
// import $ from "jquery";
export default {
  name: "themesPage",
  components: {
    // pagination,
    Fav,
    Skeleton,
    Dialog,
    Button,
  },
  data() {
    return {
      themes: [],
      themeText: "",
      loading: true,
      visible: false,
      theme_image: null,
    };
  },
  methods: {
    fetch_theme() {
      axios.get(`/show_invoice_theme`).then(({ data }) => {
        this.themes = data.data;
        this.loading = false;
        // console.log(this.themes);
      });
    },
    //delete visa
    deletethemes(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.affiliates.id;
          const fromClick = true;
          axios
            .delete(`/delete_invoice_theme/${id}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.themes.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
    showImage(image) {
      this.theme_image = image;
      this.visible = true;
    },
  },
  created() {
    this.fetch_theme();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
.text-center {
  text-align: center !important;
}
</style>
