<template>
  <div
    class="offcanvas offcanvas-bottom overflow-y-scroll"
    tabindex="-1"
    :id="`offcanvasBottomDetails${item.id}`"
    aria-labelledby="offcanvasBottomLabel"
  >
    <div class="d-flex w-50 align-items-center justify-content-start">
      <button class="btn PrimaryButton" v-print="'#printDaily'">
        {{ $t("print") }}
        <i class="fa-solid fa-print"></i>
      </button>
    </div>
    <div class="w-100 container-fluid overflow-y-scroll" id="printDaily">
      <HeaderPdf
        :title="$t('Details of the construct process')"
        :fromDate="''"
        :toDate="''"
      />
      <div class="offcanvas-header">
        <h5
          class="offcanvas-title d-flex align-items-center justify-content-between w-100 ps-4"
          id="offcanvasBottomLabel"
        >
          <div>
            {{ $t("Details of the construct process") }} ({{
              item.main_serial_number
            }})
          </div>
          <div class="date d-flex align-items-center justify-content-end w-50">
            <!-- <div id="print-button" class="print_button mx-2">
            <button class="PrimaryButton btn" v-print="'#printDaily'">
              {{ $t("print") }}
            </button>
          </div> -->
            <i class="fa-regular fa-calendar-days"></i> {{ item.date }}
          </div>
        </h5>
        <button
          type="button"
          class="btn-close text-reset no-print"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body hide-overflow small">
        <div class="row">
          <div class="col-12 col-lg-12">
            <div class="transfer_details">
              <ul>
                <li>
                  <div class="title">{{ $t("from account") }}</div>
                  <table class="transfer_table">
                    <thead>
                      <tr>
                        <th class="name">{{ $t("account name") }}</th>
                        <th class="value">{{ $t("debtor") }}</th>
                        <th class="value">{{ $t("creditor") }}</th>
                        <th class="Cost_center">{{ $t("Cost_center") }}</th>
                        <th class="report">{{ $t("construct statement") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- {{ item.from }} -->
                      <tr v-for="(from, index) in item.from" :key="index">
                        <td
                          class="name"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <router-link
                            class="names"
                            :to="`/tree/student-notes/${from.branch.id}/0`"
                          >
                            {{ from.branch.title }}
                          </router-link>
                          <!-- <span class="names">
                            {{ from.branch.title }}
                          </span> -->
                        </td>
                        <td class="value">{{ from.amount }}</td>
                        <td class="value">0</td>
                        <td
                          class="Cost_center"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <router-link
                            v-if="from.cost_center.id"
                            :to="`/tree/cost_center/${from.branch?.id}/${from.cost_center?.id}`"
                            class="Cost_centers"
                          >
                            {{
                              from.cost_center.title
                                ? from.cost_center.title
                                : "-"
                            }}</router-link
                          >
                          <span v-else class="Cost_centers"> - </span>
                          <!-- <sapn class="Cost_centers">
                            {{
                              from.cost_center.title
                                ? from.cost_center.title
                                : "-"
                            }}</sapn
                          > -->
                        </td>
                        <td class="report">
                          {{ from.report ? from.report : "-" }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  <div class="title">{{ $t("to account") }}</div>
                  <table class="transfer_table">
                    <thead>
                      <th class="name">{{ $t("account name") }}</th>
                      <th class="value">{{ $t("debtor") }}</th>
                      <th class="value">{{ $t("creditor") }}</th>
                      <th class="Cost_center">{{ $t("Cost_center") }}</th>
                      <th class="report">{{ $t("construct statement") }}</th>
                    </thead>
                    <tbody>
                      <tr v-for="(to, index) in item.to" :key="index">
                        <td
                          class="name"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <router-link
                            class="names"
                            :to="`/tree/student-notes/${to.branch.id}/0`"
                          >
                            {{ to.branch.title }}
                          </router-link>
                          <!-- <sapn class="names">
                            {{ to.branch.title }}
                          </sapn> -->
                        </td>
                        <td class="value">0</td>
                        <td class="value">{{ to.amount }}</td>
                        <td
                          class="Cost_center"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <router-link
                            v-if="to.cost_center.id"
                            class="Cost_centers"
                            :to="`/tree/cost_center/${to.branch?.id}/${to.cost_center?.id}`"
                          >
                            {{
                              to.cost_center.title ? to.cost_center.title : "-"
                            }}</router-link
                          >
                          <span v-else class="Cost_centers"> - </span>

                          <!-- <sapn class="Cost_centers">
                            {{
                              to.cost_center.title ? to.cost_center.title : "-"
                            }}</sapn
                          > -->
                        </td>
                        <td class="report">
                          {{ to.report ? to.report : "-" }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="col-12 col-lg-12">
                <div class="construct_statement">
                  <h4>{{ $t("construct statement") }}</h4>
                  <p>{{ item.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderPdf from "@/components/pdf/headerPdf.vue";

export default {
  name: "transferDetails",
  props: ["item"],
  mounted() {
    // console.log(this.item);
  },
  components: {
    HeaderPdf,
  },
};
</script>
<style lang="scss" scoped>
.offcanvas {
  height: 100vh !important;
}
.offcanvas-title {
  color: #0b1427;
  font-size: 28px;
  font-weight: "bold";
  .date {
    margin-top: 20px;
    font-weight: "regular";
    font-size: 16px;
    i {
      color: #656b78;
      margin-inline-end: 5px;
    }
  }
}
.offcanvas[data-v-df37fc42],
.edit_custom[data-v-df37fc42] {
  height: 80vh;
}
.construct_statement {
  border: 1px solid #bcbcbc;
  border-radius: 10px;
  padding: 20px;
  height: 100%;
  word-wrap: break-word;
  overflow: hidden !important;
  h3 {
    color: #0b1427;
    font-size: 24px;
    font-family: "bold";
  }
  p {
    color: #656b78;
    font-size: 16px;
  }
}
.transfer_details {
  .title {
    color: #0b1427;
    font-family: "bold";
    font-size: 20px;
    margin-bottom: 20px;
  }
  .transfer_table {
    display: block;
    border: 1px solid #bcbcbc;
    border-radius: 10px;
    thead,
    tbody {
      display: table;
      width: 100%;

      .value {
        width: 10%;
      }
      .name {
        width: 15%;
      }
      .names,
      .Cost_centers,
      .Cost_center {
        width: 15%;
        color: #0a909b !important;
        font-size: 0.8rem;
      }
      .report {
        width: 50%;
      }
      th,
      td {
        padding: 20px;
        border-left: 1px solid #9ca2ac;
        vertical-align: middle;
        text-align: start;
        &:last-child {
          border-left: 0;
        }
      }
      th {
        border-bottom: 1px dotted #9ca2ac;
      }
      tr {
        display: table-row;
        width: 100%;
        border-bottom: 1px dotted #9ca2ac;
        &:last-child {
          border-bottom: 0;
        }
        td:first-child {
          color: #0a909b;
        }
      }
    }
  }
  ul {
    padding-right: 50px;
    li {
      margin-bottom: 20px;
      position: relative;
      &::after {
        position: absolute;
        right: -49px;
        top: 0;
        background-color: #0a8f9b2c;
        color: #0a909b;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        overflow: hidden;
        z-index: 2;
        border: 1px dotted #0a909b;
      }
      &:first-child::before {
        content: "";
        position: absolute;
        right: -30px;
        top: 40px;
        width: 2px;
        height: 90%;
        border-right: 1px dotted #0a909b;
        z-index: 1;
      }
      &:last-child::after {
        content: "2";
      }
      &:first-child::after {
        content: "1";
      }
    }
  }
}

@media print {
  .hide-overflow {
    overflow-x: hidden !important;
  }
  .contract_table {
    padding-inline: 1.5rem !important;
  }
  .offcanvas-title {
    padding-inline: 1rem;
  }
  .no-print {
    display: none !important;
  }
  .transfer_details {
    .title {
      color: #0b1427;
      font-family: "bold";
      font-size: 20px;
      margin-bottom: 20px;
      margin-inline-start: 1.5rem;
    }
    .transfer_table {
      overflow-x: hidden !important;
      display: table;
      border: 1px solid #bcbcbc;
      border-radius: 10px;
      margin-inline-start: 1.5rem;
      thead,
      tbody {
        display: table;
        width: 100%;
        table-layout: fixed;

        .value {
          width: 10%;
        }
        .name {
          width: 15%;
        }
        .names,
        .Cost_centers,
        .Cost_center {
          width: 15%;
          color: #0a909b !important;
          font-size: 0.8rem;
        }
        .report {
          width: 50%;
        }
        th,
        td {
          padding: 10px;
          border-left: 1px solid #9ca2ac;
          vertical-align: middle;
          text-align: start;
          &:last-child {
            border-left: 0;
          }
        }
        th {
          border-bottom: 1px dotted #9ca2ac;
        }
        tr {
          display: table-row;
          width: 100%;
          border-bottom: 1px dotted #9ca2ac;
          &:last-child {
            border-bottom: 0;
          }
          td:first-child {
            color: #0a909b;
          }
        }
      }
    }
    ul {
      padding-inline-start: 2rem;
      li {
        margin-bottom: 20px;
        position: relative;
        &::after {
          position: absolute;
          right: -10px;
          top: 0;
          background-color: #0a8f9b2c;
          color: #0a909b;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          text-align: center;
          line-height: 40px;
          overflow-x: hidden;
          z-index: 2;
          border: 1px dotted #0a909b;
        }
        &:first-child::before {
          content: "";
          position: absolute;
          right: 0;
          top: 40px;
          width: 2px;
          height: 90%;
          border-right: 1px dotted #0a909b;
          z-index: 1;
        }
        &:last-child::after {
          content: "2";
        }
        &:first-child::after {
          content: "1";
        }
      }
    }
  }
}
</style>
