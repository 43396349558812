<template>
  <section class="all_emp card_style" style="height: 100dvh">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4>{{ $t("copon") }}</h4>
    </div>
    <div class="main_header_style mb-3">
      <div class="my-1 w-100 d-flex align-items-center justify-content-between">
        <input
          type="text"
          v-model="couponText"
          @keyup="fetchcoupons((page = 1))"
          class="form-control w-50"
          :placeholder="$t('search...')"
        />
        <router-link to="/coupons/add" class="PrimaryButton btn"
          ><i class="fa-solid fa-plus"></i> {{ $t("New Coupon") }}
        </router-link>
      </div>
    </div>
    <!--
    <div class="search-visa my-3">
      <form>
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('search...')"
        />
      </form> -->

    <div class="row">
      <table class="table" id="couponTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("Title") }}</th>
            <th>{{ $t("description") }}</th>
            <th>{{ $t("expired_date") }}</th>
            <th>{{ $t("expired_time") }}</th>
            <th>{{ $t("status") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="coupon in 10" :key="coupon">
            <td v-for="coupon in 7" :key="coupon">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(coupon, index) in coupons.data" :key="index">
            <td>{{ coupon.id }}</td>
            <td>{{ coupon.title }}</td>
            <td>{{ coupon.description }}</td>
            <td>
              {{ coupon.expired_date }}
            </td>
            <td>{{ coupon.expired_time }}</td>
            <td>
              <span
                class="coupon_status"
                :class="{
                  disactive: coupon.status == 'Disactive',
                  active: coupon.status == 'Active',
                }"
                >{{ coupon.status }}</span
              >
            </td>
            <td>
              <button
                v-if="coupon.expired_date > formattedDate"
                class="btn btn-warning mx-1 w-auto"
                @click="changeStatus(coupon.id, index)"
              >
                <i class="fa-solid fa-triangle-exclamation text-white"></i>
              </button>

              <button class="btn btn-success mx-1 w-auto">
                <router-link :to="`/coupons/edit/${coupon.id}`">
                  <i class="fa-solid fa-pen-to-square text-white"></i>
                </router-link>
              </button>

              <button
                class="btn btn-danger mx-1 w-auto"
                @click="deleteCoupon(coupon.id, index)"
              >
                <i class="fa-solid fa-trash mx-1" style="font-size: 1rem"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="coupons"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchcoupons"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
// import $ from "jquery";
export default {
  name: "coupons-Show",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      coupons: [],
      formattedDate: "",
      couponText: "",
      loading: true,
    };
  },
  methods: {
    formatCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");
      this.formattedDate = `${year}-${month}-${day}`;
    },
    fetchcoupons(page = 1) {
      axios
        .post(`/index_coupons_dashboard?page=${page}`, {
          word: this.couponText,
        })
        .then(({ data }) => {
          this.coupons = data.data;
          this.loading = false;
          // console.log(this.coupons);
        });
    },
    //delete visa
    deleteCoupon(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios.delete(`/coupons/${id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.coupons.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
    changeStatus(id, index) {
      let status_data = {
        coupon_id: id,
        status:
          this.coupons.data[index].status == "Active"
            ? 1
            : this.coupons.data[index].status == "Disactive"
            ? 0
            : null,
      };

      axios
        .post(`/change_coupon_status`, status_data)
        .then(({ data }) => {
          // console.log(data);
          this.fetchcoupons();
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetchcoupons();
    this.formatCurrentDate();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";
</style>
