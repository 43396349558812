<template>
  <section class="card_style">
    <div class="main_header_style flex-column">
      <h4>{{ $t("add new room") }}</h4>
      <p>{{ $t("enter this data to continue") }}</p>
    </div>

    <form @submit.prevent="addRoom()">
      <div class="row">
        <!-- types room -->
        <!-- <ul class="types">
          <li v-for="(type, index) in service_types" :key="index">
            <div class="form-group service_type">
              <input
                type="radio"
                name="service_type"
                class="wizard-required"
                v-model="selectedServiceType"
                :value="type.value"
                :id="'default' + type.value"
              />
              <label :for="'default' + type.value">
                {{ $t(getTypeName(type.value)) }}
              </label>
            </div>
          </li>
        </ul> -->
        <!-- types room -->
        <!-- room name ar -->
        <div class="col-lg-6 mb-4">
          <label for="name">{{ $t("room name") }} ar</label>
          <input
            type="text"
            :placeholder="$t('room name')"
            id="name"
            v-model="title_ar"
          />
        </div>

        <!-- room name en -->
        <div class="col-lg-6 mb-4">
          <label for="name">{{ $t("room name") }} en</label>
          <input
            type="text"
            :placeholder="$t('room name')"
            id="name"
            v-model="title_en"
          />
        </div>

        <div class="col-lg-6 mb-4">
          <label for="name">{{ $t("room name") }} id</label>
          <input
            type="text"
            :placeholder="$t('room name')"
            id="name"
            v-model="title_id"
          />
        </div>

        <div class="col-lg-6 mb-4">
          <label for="adult">{{ $t("adult number") }}</label>
          <input
            type="number"
            id="adult"
            placeholder="1"
            v-model="number_of_adults"
          />
        </div>

        <div class="col-lg-6 mb-4">
          <label for="children">{{ $t("children number") }}</label>
          <input
            type="number"
            id="children"
            placeholder="1"
            v-model="number_of_children"
          />
        </div>

        <!-- <div class="col-lg-6 mb-4">
          <label>{{$t("bed type")}}</label>
          <Multiselect
              v-model="bed_type_id"
              :options="bedsType.map((bedType)=> bedType.id)"
              :custom-label="(opt) => bedsType.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose bed type')"
              select-label=""
              :hide-selected="true"
          >
          </Multiselect>
        </div> -->

        <div class="col-lg-6 mb-4">
          <label for="bedsNumber">{{ $t("beds number") }}</label>
          <input
            type="number"
            id="bedsNumber"
            placeholder="1"
            v-model="number_of_beds"
          />
        </div>

        <!-- <div class="col-lg-6 mb-4">
          <label>{{$t("view_type")}}</label>
          <Multiselect
              v-model="view_type_id"
              :options="viewsType.map((viewType)=> viewType.id)"
              :custom-label="(opt) => viewsType.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
          >
          </Multiselect>
        </div>
         -->

        <!-- <div class="col-lg-6 mb-4">
          <label>{{$t("hotels")}}</label>
          <Multiselect
              v-model="hotel_id"
              :options="hotels.map((hotel)=> hotel.id)"
              :custom-label="(opt) => hotels.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
          >
          </Multiselect>
        </div> -->

        <div class="col-lg-6 mb-4">
          <label for="area">{{ $t("room_area") }}</label>
          <input type="number" v-model="room_area" />
        </div>
        <div class="col-lg-6 mb-4">
          <label for="type">{{ $t("type") }}</label>
          <div class="d-flex align-items-center checkboxs">
            <div class="flex align-items-center">
              <RadioButton
                v-model="type"
                inputId="ingredient1"
                name="Hotel"
                value="1"
              />
              <label for="ingredient1" class="mb-0"> Hotel </label>
            </div>
            <div class="flex align-items-center">
              <RadioButton
                v-model="type"
                inputId="ingredient2"
                name="Package"
                value="2"
              />
              <label for="ingredient2" class="mb-0"> Package </label>
            </div>
            <div class="flex align-items-center">
              <RadioButton
                v-model="type"
                inputId="ingredient2"
                name="Both"
                value="3"
              />
              <label for="ingredient2" class="mb-0"> Both </label>
            </div>
          </div>
        </div>
      </div>

      <button type="submit" class="add save btn">{{ $t("add") }}</button>
    </form>
  </section>
</template>

<script>
// import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";

import RadioButton from "primevue/radiobutton";

export default {
  name: "addRooms",
  components: {
    // Multiselect ,
    RadioButton,
  },
  data() {
    return {
      type: null,
      title_ar: "",
      title_en: "",
      title_id: "",
      number_of_adults: 1,
      number_of_children: 1,
      number_of_beds: 1,
      // bed_type_id:"",
      // view_type_id: "",
      hotel_id: "",
      room_area: "",
      bedsType: [],
      viewsType: [],
      hotels: [],
      hotelId: this.$route.params.hotel_id,
      service_types: [{ value: 1 }, { value: 2 }],
      selectedServiceType: 1,
    };
  },
  methods: {
    // fetchBedTypeData(){
    //   axios.post("/fetch_bed_types").then(({data})=>{
    //     this.bedsType = data.data.data
    //   })
    // },

    // fetchViewType(){
    //   axios.post("/fetch_all_view").then(({data})=>{
    //     this.viewsType = data.data.data
    //   })
    // },

    // fetchHotel(){
    //   axios.get("/hotels").then(({data})=>{
    //     this.hotels = data.data.data
    //   })
    // },
    // getTypeName(value) {
    //   // Define mappings of value to type names
    //   const nameMap = {
    //     1: "rooms",
    //     2: "sweets",
    //     // ... add more mappings as needed ...
    //   };
    //   return nameMap[value];
    // },

    addRoom() {
      const formData = new FormData();
      formData.append("title_ar", this.title_ar);
      formData.append("title_en", this.title_en);
      formData.append("title_id", this.title_id);
      formData.append("number_of_adults", this.number_of_adults);
      formData.append("number_of_children", this.number_of_children);
      formData.append("number_of_beds", this.number_of_beds);
      formData.append("service_type", this.type);
      // formData.append("bed_type_id", this.bed_type_id);
      // formData.append("view_type_id", this.view_type_id);
      // formData.append("hotel_id", this.hotelId);
      formData.append("room_size", this.room_area);
      formData.append("room_type_id", this.selectedServiceType);

      axios
        .post("/rooms", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          // console.log(data)
          this.$router.push(`/room`);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "./_room";
form {
  label {
    display: inline-block;
    margin-bottom: 10px;
    font-family: "bold" !important;
  }
  input,
  select {
    display: block;
    width: 100%;
    // height: 68px;
    border-radius: 4px;
    border: 1px solid #9ca2ac;
    color: #9ca2ac;
    padding: 10px 15px;
  }
  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $PrimaryColor !important;
    background: $PrimaryColor !important;
  }
}
</style>
