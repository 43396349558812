<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("add_new_empoly") }}</h4>
    </div>
    <form class="form_topic" @submit.prevent="addEmploy()">
      <div class="row">
        <!-- start input group -->
        <div class="col-lg-3 col-12 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("empoly_name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('empoly_name')"
              v-model="name"
              required
            />
            <span
              class="text-danger"
              v-if="v$.name.$error"
              v-text="v$.name.$errors[0].$message"
            ></span>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-3 col-12 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("phone") }}</label>
            <input
              type="tel"
              class="form-control xyz"
              :placeholder="$t('enter_phone')"
              v-model="phone"
              required
            />

            <span class="icon_form">
              <i class="fas fa-phone"></i>
            </span>
          </div>
          <span
            class="text-danger"
            v-if="v$.phone.$error"
            v-text="v$.phone.$errors[0].$message"
          ></span>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-3 col-12 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("email") }}</label>
            <input
              type="email"
              class="form-control xyz"
              :placeholder="$t('enter_email')"
              v-model="email"
              required
            />
            <span class="icon_form">
              <i class="fa-regular fa-envelope"></i>
            </span>
          </div>
          <span
            class="text-danger"
            v-if="v$.email.$error"
            v-text="v$.email.$errors[0].$message"
          ></span>
        </div>
        <!-- finish input group -->

        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-3 col-12 col-md-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("the_salary") }}</label>
            <input
              type="number"
              min="0"
              class="form-control xyz"
              :placeholder="$t('the_salary')"
              v-model="salary"
              required
            />
            <span class="icon_form">
              <i class="fa-solid fa-money-bill"></i>
            </span>
          </div>
          <span
            class="text-danger"
            v-if="v$.salary.$error"
            v-text="v$.salary.$errors[0].$message"
          ></span>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-3 col-md-6 col-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("password") }}</label>
            <input
              type="password"
              class="form-control xyz"
              :placeholder="$t('password')"
              v-model="password"
              required
            />
            <span class="icon_form">
              <i class="fa-solid fa-lock"></i>
            </span>
          </div>
          <span
            class="text-danger"
            v-if="v$.password.$error"
            v-text="v$.password.$errors[0].$message"
          ></span>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <label class="form-label"> {{ $t("roles name") }}</label>
          <Multiselect
            v-model="role"
            :options="roles"
            :clear-on-select="true"
            :placeholder="$t('roles name')"
            label="name"
            track-by="id"
            select-label=""
            :hide-selected="true"
            @search-change="fetchRoels"
          >
          </Multiselect>
        </div>
        <!-- finish input group -->

        <div class="col-lg-3 col-md-6 col-12">
          <label class="form-label"> {{ $t("hotels") }}</label>
          <div class="d-flex align-items-center fs-8">
            <span>{{ $t("all_hotels") }}</span>
            <InputSwitch v-model="checkedHotels" class="mx-2" />
            <span>{{ $t("specific_hotels") }}</span>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-12" v-if="!checkedHotels">
          <label class="form-label"> {{ $t("hotels") }}</label>
          <Multiselect
            v-model="hotelSelect"
            :options="hotels"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
            @search-change="searchHotel"
            :custom-label="formatHotelLabel"
            :multiple="true"
          >
          </Multiselect>
        </div>

        <div class="col-lg-3 col-md-6 col-12">
          <div class="form-check d-flex flex-column-reverse">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              v-model="is_master"
              id="master"
            />
            <label class="form-check-label" for="master">
              {{ $t("employee_master") }}
            </label>
          </div>
        </div>

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("add") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import Multiselect from "vue-multiselect";
import InputSwitch from "primevue/inputswitch";

export default {
  name: "add-employment",
  data() {
    return {
      v$: useVuelidate(),
      name: "",
      phone: "",
      email: "",
      password: "",
      salary: "",
      roles: [],
      role: "",
      checkedHotels: true,
      hotelSelect: [],
      hotels: [],
      is_master: 0,
    };
  },
  validations() {
    return {
      name: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
      },
      salary: {
        required,
      },
    };
  },
  methods: {
    formatHotelLabel(hotel) {
      return `${hotel.title} - ${hotel.city_title}, ${hotel.country_title} (${hotel.id})`;
    },
    async addEmploy() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("phone", this.phone);
        formData.append("email", this.email);
        formData.append("salary", this.salary);
        formData.append("password", this.password);
        formData.append("allow_all_hotels", this.checkedHotels == true ? 1 : 0);
        this.hotelSelect.forEach((ele, index) => {
          formData.append(`hotel_ids[${index}]`, ele.id);
        });
        formData.append("permission_role_id", this.role.id);
        formData.append("is_master", this.is_master == true ? 1 : 0);
        axios
          .post("/organization_employees", formData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.$router.push("/employees");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },

    searchHotel(searchHotel) {
      axios
        .post(`/fetch_new_home_hotels`, searchHotel)
        .then((res) => (this.hotels = res.data.data.hotels))
        .catch((err) => console.log(err));
    },

    fetchRoels(search) {
      axios
        .post("/index_permissions_role", { word: search, per_page: 10 })
        .then((data) => {
          this.roles = data.data.data.data;
          // console.log(data.data.data.data);
        });
    },
  },
  created() {
    this.fetchRoels();
    this.searchHotel();
  },
  components: {
    Multiselect,
    InputSwitch,
  },
};
</script>
<style lang="scss" scoped>
@import "./_employment";
</style>
