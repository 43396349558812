<template>
  <section class="all_emp card_style">
    <div class="title">
      <div class="d-flex">
        <AddFavDailog v-tooltip.left="$t('add to favorites')" />
        <h4>
          {{ $t("Create New Visa Type") }}
        </h4>
      </div>
      <p>{{ $t("enter this data to continue") }}</p>
    </div>
    <form @submit.prevent="sendData()">
      <div class="row mb-3">
        <div class="col-lg-4 col-md-6 col-12 mb-3">
          <div class="form-group">
            <label for="visa_country">{{ $t("visa country") }}</label>
            <Multiselect
              v-model="country_id"
              :options="countries"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.country_id.id.$error">{{v$.country_id.id.$errors[0].$message}}</span>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-12 mb-3">
          <div class="form-group duration_input">
            <label for="Duration">{{ $t("Duration of stay") }}</label>
            <input
              type="number"
              :placeholder="$t('enter number of days')"
              class="form-control"
              id="Duration"
              v-model="duration"
            />
            <span>{{ $t("day") }}</span>
          </div>
          <span class="input-errors" v-if="v$.duration.$error">{{v$.duration.$errors[0].$message}}</span>
        </div>
        <div class="col-lg-4 col-4 mb-3">
          <div class="form-radio visaadd">
            <h5>{{ $t("Entry type") }}</h5>
            <div class="form-group">
              <input type="radio" id="multiple" value="2" v-model="entry" />
              <label for="multiple">{{ $t("multiple") }}</label>
            </div>
            <div class="form-group vis">
              <input type="radio" id="once" value="1" v-model="entry" />
              <label for="once">{{ $t("once") }}</label>
            </div>
          </div>
          <span class="input-errors" v-if="v$.entry.$error">{{v$.entry.$errors[0].$message}}</span>
        </div>
        <div class="col-lg-4 col-md-6 col-12 mb-3">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  {{ $t("visa_type") }}
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="form-group" v-for="(visa, index) in visa_type" :key="index">
                    <label for="name" class="form-label">{{ $t("visa_type") }} ({{ visa.locale }})</label>
                    <input type="text" :placeholder="$t('enter_visa_type')" class="form-control" id="name"
                      v-model="visa.title" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="input-errors" v-if="v$.visa_type.$error">{{v$.visa_type.$errors[0].$message}}</span>
        </div>
        <div class="col-12">
          <div class="row">
            <h5>{{ $t("Terms and Conditions") }}</h5>
            <div class="col-md-4 col-12" v-for="(condition , index) in conditions" :key="index">
              <div class="form-group" v-for="(condition_title , title_index) in condition.titles" :key="title_index">
                <label class="form-label">{{$t("condition")}} ({{condition.locale}}) {{title_index + 1}}</label>
                <div class="conditions">
                  <input type="text" class="form-control" v-model="condition.titles[title_index]"/>
                  <div class="circle" v-if="title_index == 0">
                    <i class="fa-solid fa-plus" @click="addConditions()"></i>
                  </div>
                  <div class="circle remove" v-else>
                    <i
                      class="fa-solid fa-minus"
                      @click="removeConditions(title_index)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.conditions.$error">{{v$.conditions.$errors[0].$message}}</span>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-12 mb-3">
          <div class="form-group">
            <label class="form-label">{{ $t("Number of photos required") }}</label>
            <input
              type="number"
              class="form-control"
              v-model="number_of_images"
              min="1"
            />
          </div>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-lg-4 col-mg-6 col-12" v-for="(image_container , container_index) in images" :key="container_index">
              <div v-if="image_container.images_list.length">{{image_container.locale}}</div>
              <div class="images_field">
                <div
                  class="images"
                  v-for="(image, image_index) in image_container.images_list"
                  :key="image_index"
                >
                  <input
                    type="text"
                    class="form-control"
                    :id="`image${container_index}${image_index}`"
                    :placeholder="$t('photo') + `${image_index + 1}`"
                    v-model="image_container.images_list[image_index]"
                  />
                </div>
              </div>
            </div>
          </div>
          <span class="input-errors" v-if="v$.images.$error">{{v$.images.$errors[0].$message}}</span>
        </div>
      </div>
      <button type="submit" class="btn PrimaryButton mt-4">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  name: "addVisa",
  components: { Multiselect, AddFavDailog },
  data() {
    return {
      v$: useVuelidate(),
      visa_type: [],
      countries: [],
      country_id: "",
      entry: "2",
      duration: "",
      conditions: [],
      number_of_images: 1,
      images: [],
      language: ["ar", "en", "id"],
    };
  },
  validations(){
    return{
      country_id: {
        id: { required }
      },
      duration: { required },
      entry: { required },
      visa_type: this.visa_type.map(()=>{
        return{
          title: { required }
        }
      }),
      conditions: this.conditions.map((ele)=>{
        return{
          titles: ele.titles.map(()=>{
            return {required}
          })
        }
      }),
      images: this.number_of_images > 0 ? this.images.map((ele)=>{
        return{
          images_list: ele.images_list.map(()=>{
            return {required}
          })
        }
      }) : '',
    }
  },
  watch:{
    number_of_images(value){
      if(value >= 0){
        this.images.forEach((ele)=>{
          ele.images_list.length = value
        })
      }
    }
  },
  methods: {
    // add visa
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    addConditions() {
      this.conditions.forEach((ele=>{
        ele.titles.push("")
      }))
    },
    removeConditions(condition_index) {
      this.conditions.forEach((ele)=>{
        ele.titles.splice(condition_index , 1);
      })
    },
    setVisaType() {
      this.language.forEach((ele)=>{
        this.visa_type.push({
          locale: ele,
          title: "",
        });

        this.conditions.push({
          locale: ele,
          titles: [""]
        });

        this.images.push({
          locale: ele,
          images_list: [""]
        })
      })
    },
    sendData() {
      this.v$.$validate();
      if(!this.v$.$error){
        let visaData = {
          country_id: this.country_id.id,
          duration_of_stay: this.duration,
          accessibility: this.entry,
          terms: [],
          image_required: [],
        };
        this.visa_type.forEach((ele)=>{
          visaData[`type_name_${ele.locale}`] = ele.title
        });
        this.conditions.forEach((ele)=>{
          ele.titles.forEach((title , index)=>{
            visaData.terms[index]={
              ...visaData.terms[index],
              [`text_${ele.locale}`]: title
            }
          })
        });
        this.images.forEach((ele)=>{
          ele.images_list.forEach((image , index)=>{
            visaData.image_required[index]={
              ...visaData.image_required[index],
              [`text_${ele.locale}`]: image
            }
          })
        });
        axios
          .post("/visa_types", visaData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push("/visa");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },
  mounted() {
    this.fetch_country();
    this.setVisaType();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.accordion-button::after {
  display: block !important;
  margin-inline-start: auto !important;
  margin-left: unset !important;
}

label,
h5 {
  font-weight: 700;
  font-family: "bold";
  font-size: 16px;
  color: #0b1427;
  margin-bottom: 10px;
}

.circle {
  width: 40px;
  height: 40px;
  // border: 1px solid $PrimaryColor;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.conditions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .form-control {
    width: 90%;
  }
}
.images_field {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .images {
    margin-inline-end: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.form-radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  label {
    margin-left: 10px;
    margin-right: 10px;
    color: #656b78;
  }
  input:checked ~ label {
    color: #0b1427;
  }
}
.duration_input {
  position: relative;
  span {
    display: block;
    border-right: 1px solid #0b1427;
    padding: 0px 10px 0px 10px;
    position: absolute;
    left: 2px;
    top: 42px;
    background-color: #fff;
  }
}
.form-control {
  &::placeholder {
    padding-inline: 3rem;
  }
}
</style>
