<template>
  <div class="paid_table">
    <div class="add_paid d-flex justify-content-end my-3">
      <button class="btn PrimaryButton" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom"
        aria-controls="offcanvasBottom">
        <i class="fa-solid fa-plus"></i> {{ $t("Add Paid") }}
      </button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>{{ $t("payment_methods") }}</th>
          <!-- <th>{{ $t("version_date") }}</th> -->
          <th>{{ $t("discription") }}</th>
          <th>{{ $t("payment_method") }}</th>
          <th>{{ $t("clerck") }}</th>
          <th>{{ $t("payment_type") }}</th>
          <th>{{ $t("the recepit") }}</th>
          <th>{{ $t("Status") }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in paids.data" :key="index">
          <td>{{ item.id }}</td>
          <!-- <td>{{ moment(item.created_at).format("YYYY-MM-DD") }}</td> -->
          <td>{{ item.report }}</td>
          <td> <span>{{ item.payment_way === 2 ? $t('Cash') : $t('Online') }}</span> </td>
          <td>{{ item.employee.name }}</td>
          <td>
            <span v-if="item.payment_way == 1"> Cash</span>
            <span v-if="item.payment_way == 2"> Online</span>
          </td>

          <td>
            <router-link class="btn attachment" to="#">
              {{ $t("download_attachment") }}
            </router-link>
          </td>

          <td>
            <span :class="[
              'badge',
              item.status === 0
                ? 'bg-primary'
                : item.status === 2
                  ? 'bg-success'
                  : item.status === 3
                    ? 'bg-danger' : ''
            ]">
              {{
                item.status === 0
                ? $t("new")
                : item.status === 2
                  ? $t("Accept")
                  : item.status === 3
                    ? $t("Decline") : ""
              }}
            </span>
          </td>

          <td>
            <div v-if="item.status === 0">
              <span @click="changeBookingStatus(index, 2)" class="ok mx-1"><i class="fa-solid fa-circle-check"></i></span>
              <span @click="changeBookingStatus(index, 3)" class="wrong mx-1"><i
                  class="fa-solid fa-circle-xmark"></i></span>
            </div>
          </td>
          <div :key="'offcanvas' + index" class="offcanvas offcanvas-bottom" :id="'offcanvasBottom' + index"
            aria-labelledby="offcanvasBottomLabel">
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasBottomLabel">
                <span> {{ $t("payment_methos_detials") }}</span>
                {{ item.id }}
              </h5>
              <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body small">
              <div class="information">
                <div class="row">
                  <div class="col-lg-8 col-12">
                    <div>
                      <span>{{ $t("item") }}</span>
                      <h6>Alwalid Golden</h6>
                    </div>
                    <div>
                      <span>{{ $t("date") }}</span>
                      <h6>{{ item.created_at }}</h6>
                    </div>

                    <div>
                      <span>Amount to pay</span>
                      <h6>12815.00</h6>
                    </div>

                    <div>
                      <span>{{ $t("payment_type") }}</span>
                      <h6>تسبقة</h6>
                    </div>
                  </div>

                  <div class="col-lg-4 col-12">
                    <div>
                      <span>{{ $t("the amount") }}</span>
                      <h6>200.000 SAR</h6>
                    </div>
                  </div>
                </div>
                <div class="booking">
                  <div class="row">
                    <div class="col-lg-6 col-6"></div>
                    <div class="col-lg-6 col-6">
                      <div class="recipt">
                        <h6>{{ $t("receipt") }}</h6>
                        <div class="row">
                          <div class="col-lg-6 col-12">
                            <div class="img">
                              <img src="@/assets/media/image/recit.png" alt="resipt" />
                            </div>
                          </div>
                          <div class="col-lg-6 col-12"></div>
                        </div>
                        <div class="content"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- offcavans -->
        </tr>
      </tbody>
    </table>
    <paidCanvas />

  </div>
</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";
import paidCanvas from "./paidCanvas.vue";

export default {
  name: "paid_table",
  data() {
    return {
      paids: []
    }
  },
  components: { paidCanvas },
  methods: {
    fetch_paids() {
      axios.post("/fetch_booking_money_payments", { booking_id: 1 }).then(({ data }) => {
        // console.log(data.data)
        this.paids = data.data
        // console.log(this.paids)
      })
    },
    changeBookingStatus(index, statusBooking) {
      let confirm_id = this.paids.data[index].id;
      let statusBook = statusBooking
      axios
        .post(`/change_booking_money_payments_status`, { booking_id: confirm_id, money_payment_status: statusBook })
        .then(({ data }) => {
          //   console.log(data)
          if (data.status == true) {
            Swal.fire("", data.message, "success");
            this.fetch_paids();
          } else {
            Swal.fire("", data.message, "error");
          }
          return data;
        })
        .catch((error) => {
          Swal.fire("", error.response.data.message, "error");
        });
    },
  },
  mounted() {
    this.fetch_paids();
  }
}
</script>

<style></style>