<template>
  <div class="book" id="#umrah_setting">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4 class="title">
        {{ $t("Create_the_program") }}
      </h4>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <TabView>
              <TabPanel
                v-for="(program, index) in program_name"
                :key="index"
                :class="[program.title !== '' ? 'text-success' : '']"
                :header="$t('program name') + ' - ' + program.lang"
              >
                <div class="form-group mb-0">
                  <input
                    type="text"
                    :placeholder="$t('name')"
                    class="form-control"
                    :id="`name${index}`"
                    v-model="program.title"
                    @blur="emitSettingData()"
                  />

                  <span class="input-errors" v-if="v$.program_name.$error">{{
                    v$.program_name.$errors[0].$message
                  }}</span>
                  <!-- Add any additional content specific to each tab here -->
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group mb-0">
            <TabView>
              <TabPanel
                v-for="(desc, index) in description"
                :key="index"
                :header="$t('program_description') + ' - ' + desc.lang"
              >
                <div class="form-group">
                  <textarea
                    :placeholder="$t('description')"
                    class="form-control"
                    :id="`desc${index}`"
                    v-model="desc.title"
                    @blur="emitSettingData()"
                  ></textarea>

                  <span class="input-errors" v-if="v$.description.$error">{{
                    v$.description.$errors[0].$message
                  }}</span>
                  <!-- Add any additional content specific to each tab here -->
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
        <!-- 
        <div
          class="col-lg-3 col-md-6 col-12"
          
        >
          <label class="typo__label custom_label">{{
            $t("types package")
          }}</label>

          <Multiselect
            v-model="typesValue"
            :options="types"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
            @select="handleTypes"
          ></Multiselect>
        </div> -->

        <div class="col-lg-3 col-md-6 col-12">
          <label class="typo__label custom_label">{{
            $t("Choose_program")
          }}</label>
          <Multiselect
            v-model="package_type_value"
            :options="package_types"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
          ></Multiselect>
          <span class="input-errors" v-if="v$.package_type_value.id.$error">{{
            v$.package_type_value.id.$errors[0].$message
          }}</span>
        </div>

        <div class="col-lg-3 col-md-6 col-12">
          <label class="typo__label custom_label">{{
            $t("Program category")
          }}</label>
          <Multiselect
            v-model="starsValue"
            :options="stars"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
          ></Multiselect>
          <span class="input-errors" v-if="v$.starsValue.id.$error">{{
            v$.starsValue.id.$errors[0].$message
          }}</span>
        </div>

        <!--  -->
        <div class="col-lg-3 col-md-6 col-12">
          <label class="typo__label custom_label">{{ $t("emoloyees") }}</label>

          <Multiselect
            v-model="empValue"
            :options="empolyment"
            label="name"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
            :multiple="true"
          ></Multiselect>
        </div>
        <!--  -->

        <div class="col-lg-3 col-md-3 col-12">
          <label class="custom_label"> {{ $t("trip_date") }}</label>
          <Datepicker
            :placeholder="trip_date"
            v-model="trip_date"
            :disabled-dates="{
              to: new Date(new Date()),
            }"
            @click="selectedTripDate"
          >
          </Datepicker>
          <span class="input-errors" v-if="v$.trip_date.$error">{{
            v$.trip_date.$errors[0].$message
          }}</span>
        </div>

        <div class="col-lg-3 col-md-3 col-12">
          <label class="typo__label custom_label" style="width: max-content">{{
            $t("min_childrens")
          }}</label>
          <input
            type="number"
            class="form-control"
            v-model="min_chd_book"
            min="1"
          />
          <span class="input-errors" v-if="v$.min_chd_book.$error">{{
            v$.min_chd_book.$errors[0].$message
          }}</span>
        </div>
        <div class="col-lg-3 col-md-3 col-12">
          <label class="typo__label custom_label" style="width: max-content">
            {{ $t("max_adults") }}
          </label>
          <input type="number" class="form-control" v-model="max_adt_book" />
          <span class="input-errors" v-if="v$.max_adt_book.$error">
            {{ v$.max_adt_book.$errors[0].$message }}
          </span>
        </div>
        <div class="col-lg-3 col-md-3 col-12">
          <label class="typo__label custom_label" style="width: max-content">
            {{ $t("min_adults") }}
          </label>
          <input type="number" class="form-control" v-model="min_adt_book" />
          <span class="input-errors" v-if="v$.min_adt_book.$error">
            {{ v$.min_adt_book.$errors[0].$message }}
          </span>
        </div>

        <div class="col-lg-2 col-md-3 col-12">
          <label class="typo__label custom_label" style="width: max-content">{{
            $t("max_childrens")
          }}</label>
          <input type="number" class="form-control" v-model="max_chd_book" />
          <span class="input-errors" v-if="v$.max_chd_book.$error">{{
            v$.max_chd_book.$errors[0].$message
          }}</span>
        </div>

        <div class="col-lg-2 col-md-3 col-12 main_image my-2">
          <label class="form-label">{{ $t("main_image") }}</label>
          <input
            type="file"
            id="file"
            accept="image/*"
            @change="imageUpload"
            hidden
          />
          <div class="image_group">
            <label
              for="file"
              class="main_image_label"
              v-if="imageCompany != ''"
              style="border: none"
            >
              <img
                class="main_image"
                :src="imageCompany"
                style="width: 100%; height: 100%; border: 2px solid #dce4ec"
              />
            </label>
            <label for="file" class="main_image_label" v-else>
              <img
                class="main_image"
                src="@/assets/media/image/icon/image_icon.png"
              />
              <span
                >{{ $t("Add a photo to the cover") }} <br />
                (500px*500px)</span
              >
            </label>
            <div class="removeIcon" v-if="imageCompany != ''">
              <i class="fa-solid fa-x" @click="removeMainImage()"></i>
            </div>
          </div>
        </div>

        <div class="col-lg-8 col-md-9 col-12 my-2">
          <label class="package-label form-label"
            >{{ $t("package_images") }}-({{ $t("Maximum images is 4") }})</label
          >
          <div class="umrah_images_container">
            <div class="umrah_images" :class="{ disable_add_btn: disable_btn }">
              <vue-multi-image-upload
                @data-image="uploadImages"
                :max="max_images_num"
                :image-size="4e6"
                :alert-timeout="3e3"
                :accept="imageType"
                :preview="{ h: 100, w: 100 }"
                :resize="{ h: 500, w: 500, keepRatio: true }"
                :data-reset="component"
                id="multi_image_upload"
              />
              <label class="image_label">
                <img
                  class="main_image"
                  src="@/assets/media/image/icon/image_icon.png"
                />
                <span
                  >{{ $t("Add one or more pictures to the trip content") }}
                  <br />
                  (1250px*500px)</span
                >
              </label>
            </div>
            <div class="images_container" v-if="fetchedImages.length">
              <div class="d-flex align-items-center gap-1">
                <div
                  class="image"
                  v-for="(image, index) in fetchedImages"
                  :key="index"
                >
                  <img :src="image" />
                  <button
                    type="button"
                    class="btn remove"
                    @click="deleteFetchedImage(index)"
                  >
                    <i class="fa-solid fa-x"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-6 col-12">
          <div class="form-group ent airport-group">
            <div class="place">
              <label class="typo__label custom_label">{{
                $t("all nationality")
              }}</label>
              <InputSwitch v-model="checked" />
            </div>

            <!-- <label class="typo__label custom_label">{{
              $t("choose lauch")
            }}</label>
            <Multiselect
              v-model="lunch_country_value"
              :options="countries"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose country')"
              select-label=""
              :hide-selected="true"
              @keyup="fetch_country"
              :custom-label="formatNationalityLabel"
              @update:model-value="selectCountry(lunch_country_value)"
            >
            </Multiselect>
            <span
              class="input-errors"
              v-if="v$.lunch_country_value.id.$error"
              >{{ v$.lunch_country_value.id.$errors[0].$message }}</span
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Multiselect } from "vue-multiselect";
// import "vue-datepicker-ui/lib/vuedatepickerui.css";
// import VueDatepickerUi from "vue-datepicker-ui";

// import Datepicker from "vuejs3-datepicker";
import axios from "axios";
import moment from "moment";
import { VueMultiImageUpload } from "@zakerxa/vue-multiple-image-upload";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import "@vuepic/vue-datepicker/dist/main.css";
import AddFavDailog from "../layout/addFavDailog.vue";
import Datepicker from "vuejs3-datepicker";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

// import Swal from "sweetalert2";

import InputSwitch from "primevue/inputswitch";
const atLeastOneTitleFilled = helpers.withMessage(
  "Please fill at least one title",
  (value) => value.some((item) => item.title.trim() !== "")
);
const atLeastOneDescFilled = helpers.withMessage(
  "Please fill at least one description",
  (value) => value.some((item) => item.title.trim() !== "")
);
export default {
  name: "bookSettings",
  // props: {
  //   fetched_setting_data: Object,
  //   test_data: Object,
  // },
  props: ["fetched_setting_data", "id"],
  emits: [
    "selectedendDate",
    "inputImagesEmit",
    "save_setting_data",
    "selectedCountryID",
  ],
  data() {
    return {
      typesValue: "",

      v$: useVuelidate(),
      empolyment: [],
      empValue: "",
      component: {},
      inputImages: [],
      cities: [],
      countries: [],
      imageType: ["image/jpeg", "image/png", "image/gif"],
      languages: ["ar", "en", "id"],
      value: "",
      package_types: [],
      package_type_value: {},
      defaultImage: require("@/assets/media/image/placeholders.png"),
      program_name: [],
      description: [],
      startDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      endDate: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
      date: new Date(),
      images: [],
      showModal: false,
      selectedImage: null,
      starsValue: {},
      star: [],
      stars: [],
      is_one_day: 0,
      trip_date: "",
      number_of_days: 1,
      is_period: false,
      media: {
        saved: [
          // { id: 1, name: '123_image.jpg' },
          // { id: 2, name: '456_image.jpg' },
        ],
        added: [],
        removed: [],
        page_lang: "ar",
      },
      // lunch_country_value: "",
      imageCompany: "",
      imageCompanyName: "",
      max_adt_book: null,
      max_chd_book: null,
      min_adt_book: null,
      min_chd_book: null,
      fetchedImages: [],
      max_images_num: 4,
      disable_btn: false,
      checked: true,
    };
  },
  components: {
    Multiselect,
    VueMultiImageUpload,
    AddFavDailog,
    Datepicker,
    TabView,
    TabPanel,
    InputSwitch,
  },
  created() {
    this.typesValue = this.$route.params.type == "tourism" ? 2 : 1;
  },
  methods: {
    // handleTypes() {
    //   console.log(this.typesValue, "adsadadasdasd");

    //   this.$emit("typesValue_id", this.typesValue);
    // },
    FetchEmploy(page = 1) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/organization_employees?page=" + page).then(({ data }) => {
        this.empolyment = data.data.data;
        // console.log(this.empolyment);
      });
    },
    imageUpload(event) {
      const selectImage = event.target.files[0];
      this.createBase64Image(selectImage);
      this.imageCompanyName = selectImage.name;
    },
    createBase64Image(fileImage) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageCompany = e.target.result;
      };
      reader.readAsDataURL(fileImage);
    },
    get_stars() {
      axios.post("/index_package_star_category").then(({ data }) => {
        this.stars = data.data;
        // console.log(this.stars);
      });
    },
    addMedia(addedImage, addedMedia) {
      this.media.added = addedMedia;
    },
    removeMedia(removedImage, removedMedia) {
      this.media.removed = removedMedia;
    },
    set_program_name() {
      this.languages.forEach((ele) => {
        this.program_name.push({
          lang: ele,
          title: "",
        });

        this.description.push({
          lang: ele,
          title: "",
        });
      });
    },
    fetch_package_types() {
      axios
        .post(`/fetch_package_types`, {
          package_type: this.typesValue,
        })
        .then(({ data }) => {
          this.package_types = data.data.data;
        });
    },
    selectedTripDate() {
      if (this.is_one_day == 0) {
        this.$emit(
          "selectedstartDate",
          moment(this.trip_date).format("YYYY-MM-DD")
        );
        this.$emit(
          "selectedendDate",
          moment(this.trip_date).format("YYYY-MM-DD")
        );
      }
    },
    selectedstartDate() {
      this.$emit(
        "selectedstartDate",
        moment(this.startDate).format("YYYY-MM-DD")
      );
    },
    selectedendDate() {
      this.$emit("selectedendDate", moment(this.endDate).format("YYYY-MM-DD"));
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    showImage(index) {
      this.selectedImage = this.images[index];
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedImage = null;
    },

    uploadImages(e) {
      // Calculate the maximum number of images that can be added
      let max_number = 4 - this.fetchedImages.length;
      this.max_images_num = max_number;

      // Enable or disable the add button based on the total number of images
      if (this.inputImages.length + this.fetchedImages.length == 4) {
        this.disable_btn = true;
      } else {
        this.disable_btn = false;
      }

      this.inputImages = e;

      // Create FormData object to hold the images
      let formData = new FormData();
      let base64Strings = []; // Array to store base64 strings

      // Process each image file and convert it to a base64 string
      this.inputImages.forEach((imgFile, index) => {
        const reader = new FileReader();

        // Read the file as a data URL
        reader.readAsDataURL(imgFile);

        // Once the file is loaded, convert it to base64 and append to formData
        reader.onloadend = () => {
          const base64String = `data:image/png;base64,${reader.result.split(",")[1]}`;
          formData.append(`images[${index}]`, base64String);

          // Add the base64 string to the array
          base64Strings.push(base64String);

          // Emit the array of base64 strings after processing all images
          if (base64Strings.length === this.inputImages.length) {
            if (this.fetchedImages.length > 0) {
              this.$emit("inputImagesEmit", base64Strings, this.fetchedImages);
            } else {
              this.$emit("inputImagesEmit", base64Strings, []);
            }
          }
        };
      });
    },
    deleteFetchedImage(index) {
      this.fetchedImages.splice(index, 1);
    },
    removeMainImage() {
      this.imageCompany = "";
    },

    inputFormData() {
      // The way to append images to FormData.
      let formData = new FormData();
      this.inputImages.map((img) => formData.append("images[]", img));
      return formData;
    },
    clear() {
      this.component.clear = true;
    },
    updateToDate() {
      if (this.startDate) {
        const nights = parseInt(this.number_of_days, 10) || 1;
        // console.log(nights)
        const fromDate = new Date(this.startDate);
        const toDate = new Date(
          fromDate.getTime() + nights * 24 * 60 * 60 * 1000
        );
        this.endDate = toDate;
      }
    },
    updateFromDate() {
      if (this.endDate) {
        const toDate = new Date(this.endDate);
        const fromDate = new Date(
          toDate.getTime() - this.number_of_days * 24 * 60 * 60 * 1000
        );
        this.startDate = fromDate;
      }
    },
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    formatNationalityLabel(nationality) {
      return `${nationality.title} - ${nationality.code} (${nationality.id})`;
    },
    selectCountry(country_id) {
      if (country_id != undefined) {
        this.$emit("selectedCountryID", country_id.id);
      }
    },
    get_lang() {
      if (localStorage.getItem("lang") != null) {
        this.page_lang = localStorage.getItem("lang");
      }
    },
    emitSettingData() {
      let setting_data = {
        package_tourism_type: this.typesValue,
        package_type_id: this.package_type_value.id,
        star_category_id: this.starsValue.id,
        day_count: this.number_of_days,
        is_one_day: this.is_one_day,
        trip_date:
          this.is_one_day == 0
            ? this.$route.params.id == undefined
              ? moment(this.trip_date).format("DD-MM-YYYY")
              : this.trip_date
            : null,

        from_date:
          this.is_one_day == 1
            ? moment(this.startDate).format("DD-MM-YYYY")
            : null,
        to_date:
          this.is_one_day == 1
            ? moment(this.endDate).format("DD-MM-YYYY")
            : null,
        // images: [],`
        main_image: this.imageCompany,
        max_chd_book: this.max_chd_book,
        max_adt_book: this.max_adt_book,
        min_adt_book: this.min_adt_book,
        min_chd_book: this.min_chd_book,
        all_nationalities: this.checked ? 1 : 0,
        employees: this.empValue ? this.empValue.map((ele) => ele.id) : [],
        ...(this.typesValue !== 2 && { package_country_id: 598 }),
      };

      // if (this.typesValue !== 1) {
      //   setting_data.package_country_id = 598;
      // }
      // console.log(setting_data, "stars");
      this.program_name.forEach((ele) => {
        setting_data[`title_${ele.lang}`] = ele.title;
      });

      this.description.forEach((ele) => {
        setting_data[`description_${ele.lang}`] = ele.title;
      });
      // console.log(setting_data, "hotels");
      if (!this.$route.params.id) {
        if (
          this.package_type_value &&
          this.starsValue &&
          this.trip_date &&
          this.min_chd_book &&
          this.min_adt_book &&
          this.max_chd_book &&
          this.max_adt_book
        ) {
          this.v$.$validate();
          if (!this.v$.$error) {
            this.$emit("save_setting_data", setting_data);
          }
        }
      } else {
        this.v$.$validate();
        if (!this.v$.$error) {
          this.$emit("save_setting_data", setting_data);
        }
      }
    },
  },
  mounted() {
    if (this.$route.params.id == undefined) {
      this.set_program_name();
    }
    // this.fetch_package_types();
    this.get_stars();
    this.get_lang();
    this.FetchEmploy();
  },

  watch: {
    typesValue() {
      this.fetch_package_types();
    },
    fetched_setting_data: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (this.$route.params.id) {
          // console.log(newValue);
          this.program_name = newValue?.setting_title?.map((ele) => {
            return {
              lang: ele.locale,
              title: ele.title,
            };
          });
          this.description = newValue?.setting_description?.map((ele) => {
            return {
              lang: ele.locale,
              title: ele.description,
            };
          });

          this.package_type_value = newValue?.setting_package_type;
          this.starsValue = newValue?.setting_star_category;
          this.max_adt_book = newValue?.setting_max_adt_book;
          this.max_chd_book = newValue?.setting_max_chd_book;
          this.min_adt_book = newValue?.setting_min_adt_book;
          this.min_chd_book = newValue?.setting_min_chd_book;
          this.checked =
            newValue?.setting_all_nationalities == 1 ? true : false;
          this.trip_date = newValue?.setting_trip_date;
          this.empValue = newValue?.setting_employees;
          this.fetchedImages = newValue.setting_images?.map((ele) => {
            return ele.image;
          });
          this.imageCompany = newValue?.main_image;
          this.$emit("inputImagesEmit", [], this.fetchedImages);

          if (!this.$route.params.id) {
            this.v$.$validate();
            if (!this.v$.$error) {
              this.$emit("save_setting_data", newValue);
            }
          } else {
            this.$emit("save_setting_data", newValue);
          }
          // console.log(newValue, "newValue");
        }
      },
    },
    endDate() {
      if (this.startDate && this.endDate) {
        const start = new Date(this.startDate);
        const end = new Date(this.endDate);
        const timeDifference = end.getTime() - start.getTime();
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        this.number_of_days = daysDifference;
      } else {
        this.number_of_days = 1;
      }
    },
  },

  validations() {
    return {
      package_type_value: {
        id: { required },
      },
      starsValue: {
        id: { required },
      },
      trip_date: { required },
      // lunch_country_value: {
      //   id: { required },
      // },
      min_adt_book: { required },
      min_chd_book: { required },
      max_adt_book: { required },
      max_chd_book: { required },

      program_name: this.$route.params.id
        ? {}
        : {
            atLeastOneTitleFilled,
          },
      description: this.$route.params.id
        ? {}
        : {
            atLeastOneDescFilled,
          },
    };
  },
  updated() {
    this.emitSettingData();
  },
};
</script>

<style lang="scss" scoped>
$PrimaryColor: #0a909b;

.book {
  background-color: #fff;

  .title {
    font-size: 1.5rem;
    font-family: "bold";
    color: #0b1427;
    padding: 0.6rem;
  }

  .custom_label {
    font-size: 0.6875rem;
    font-family: "bold";
    color: #0b1427;
    position: relative;
    z-index: 22;
    // bottom: 5.5rem; //4.2rem
    top: 0.675rem;
    background: white;
    border-radius: 50%;
    text-align: center;
    color: #656b78;
    margin: 0 0.7rem;
  }

  .contain-garalery {
    width: 9rem;
    height: 4.4375rem;
    border: 1px solid #9ca2ac;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 0.3rem;

    p {
      margin: 0;
      color: #656b78;
      font-family: "regular";
      font-size: 0.685rem;
    }
  }

  .textarea {
    border: 1px solid #9ca2ac;
    width: 9rem;
    border-radius: 4px;
  }

  .resultSearch {
    position: absolute;
    // top: 40px;
    background-color: #fff;
    height: 300px;
    width: 23%;
    overflow-y: auto;
    z-index: 99999;

    .head_search {
      font-family: "bold";
      background-color: $PrimaryColor;
      color: #fff;
      padding: 0.5rem;
      margin-bottom: 0;
    }

    .hotels,
    .cities {
      margin-bottom: 0;

      li {
        display: flex;
        align-items: center;
        font-family: "regular";
        font-size: 0.8rem;
        padding: 0.5rem;
        border-bottom: 1px solid #292d32;
        transition: 0.7s ease-in-out;
        cursor: pointer;

        &:last-child {
          border-bottom: unset;
        }

        .hotel_image {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-inline-end: 0.5rem;
          position: unset !important;
        }

        i {
          font-size: 1.5rem;
          color: #b5b5b5;
          margin-inline-end: 0.5rem;
        }

        .title,
        .country {
          margin-bottom: 0;
        }

        .title {
          font-size: 0.8rem;
        }

        .country {
          font-size: 0.7rem;
          color: #292d32;
        }

        &:hover {
          transition: 0.7s ease-in-out;
          background-color: rgba($color: $PrimaryColor, $alpha: 0.1);
        }
      }
    }
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.accordion-button {
  padding: 6px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-button::after {
  margin-left: 5px;
}

.form-check .form-check-input {
  float: none;
  margin-left: 0;
  margin-inline-end: 5px;

  &:checked {
    border-color: $PrimaryColor;
    background-color: $PrimaryColor;
  }
}
.rowc[data-v-32103c56] {
  display: table;
  border-radius: 5px;
  padding-top: 5px;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  // width: fit-content !important;
  width: unset !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.accordion_div {
  width: 100%;
  height: 300px;
  overflow-y: scroll;

  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: $PrimaryColor #fcfcfc;
  // scrollbar-color: #eee #fff;
}
.add_file {
  display: inline-block;
  width: 100%;
  // background-color: $PrimaryColor;
  // background-color: #dce4ec;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  i {
    margin-inline-end: 5px;
  }
}
.main_image {
  .form-label {
    color: #656b78;
    font-family: "bold";
  }
  .image_group {
    position: relative;
  }
  .main_image_label {
    width: 100px;
    height: 100px;
    overflow: hidden;
    display: block;
    cursor: pointer;
    border: 2px solid #dce4ec;
    box-sizing: content-box;
    background-color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #9ca2ac;
    border-radius: 4px;
    margin-bottom: unset !important;
    span {
      display: inline-block;
      font-size: 11px;
      color: #656b78;
    }
  }
  .removeIcon {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    i {
      position: absolute;
      top: 5px;
      right: 5px;
      color: red;
      border-radius: 50%;
      border: 1px solid red;
      padding: 5px;
      font-size: 10px;
    }
  }
}
.package-label.form-label {
  color: #656b78;
  font-family: "bold";
}
.images_container {
  .image {
    width: 100px;
    height: 100px;
    position: relative;
    border: 2px solid #dfd4d4;
    box-sizing: content-box;
    border-radius: 4px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    button {
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      outline: none;
      border: none;
      i {
        position: absolute;
        top: 5px;
        right: 5px;
        color: red;
        border-radius: 50%;
        border: 1px solid red;
        padding: 5px;
        font-size: 10px;
      }
    }
  }
}
.umrah_images_container {
  display: flex;
  align-items: center;

  gap: 5px;
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
}
.umrah_images {
  height: 100%;
  position: relative;
  .form-label {
    color: #656b78;
    font-family: "bold";
  }
  .image_label {
    width: 100px;
    height: 100px;
    z-index: 10;
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    box-sizing: content-box;
    margin-bottom: unset !important;
    font-size: unset !important;
    background-color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #9ca2ac;
    border-radius: 4px;
    span {
      display: inline-block;
      font-size: 11px;
      color: #656b78;
    }
  }
}
.umrah_images.disable_add_btn {
  .image_label {
    opacity: 0;
  }
}
.place {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
</style>
