<template>
  <section class="card_style" style="min-height: 100dvh">
    <div class="w-100" v-if="loading && $route.params.id">
      <Skeleton width="10%" class="mb-2"></Skeleton>
      <Skeleton width="20%" height="5rem"></Skeleton>
      <div class="d-flex mt-4 w-100">
        <Skeleton width="30%" height="3rem"></Skeleton>
        <Skeleton width="30%" class="mx-2" height="3rem"></Skeleton>
        <Skeleton width="30%" height="3rem"></Skeleton>
      </div>
    </div>

    <form @submit.prevent="addBlog()" v-else>
      <div class="main_header_style">
        <h4 v-if="!$route.params.id">
          {{ $t("Add Gifts") }}
        </h4>
        <h4 v-else>
          {{ $t("Edit Gifts") }}
        </h4>
      </div>
      <div class="row d-flex align-items-center justify-content-center">
        <div class="col-12">
          <div class="image_upload mb-1">
            <label for="imageEntry" class="upload_img" v-if="imageEntry == ''">
              <img :src="Form.image ? Form.image : defaultImage" alt="user" />
            </label>
            <img
              :src="imageEntry"
              v-else-if="imageEntry"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              id="imageEntry"
              class="form-control"
              @change="onFileChange"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.imageEntry.$error"
            v-text="v$.imageEntry.$errors[0].$message"
          ></span>
        </div>
        <div class="col-lg-5 col-md-4 col-12">
          <div class="form-group">
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="text-danger"
              v-if="v$.Form.title.$error"
              v-text="v$.Form.title.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-5 col-md-4 col-12">
          <div class="form-group">
            <div class="accordion" id="accordionExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("describtion") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_content, index) in Form.subtitle"
                      :key="index"
                    >
                      <label class="mt-3"
                        >{{ $t("describtion") }} ({{
                          blog_content.lang
                        }})</label
                      >
                      <Editor
                        v-model="blog_content.name"
                        editorStyle="height: 120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="text-danger"
              v-if="v$.Form.subtitle.$error"
              v-text="v$.Form.subtitle.$errors[0].$message"
            ></span>
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-group">
            <h4 class="point_title">{{ $t("points") }}</h4>
            <input
              type="number"
              class="form-control p-2"
              v-model="points"
              :placeholder="$t('points')"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.points.$error"
            v-text="v$.points.$errors[0].$message"
          ></span>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Editor from "primevue/editor";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import Skeleton from "primevue/skeleton";

export default {
  name: "Add-Gifts",
  data() {
    return {
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      options: {
        placeholder: this.$t("describtion"),
        theme: "snow",
      },
      Form: {
        title: [],
        subtitle: [],
      },
      imageEntry: "",
      defaultImage: require("@/assets/media/image/icon/user.png"),
      //   blog_id: undefined,
      points: 0,
      loading: true,
    };
  },
  components: {
    Editor,
    Skeleton,
  },
  validations() {
    return {
      points: {
        required,
      },
      imageEntry: this.$route.params.id ? {} : { required },
      Form: {
        title: this.Form.title.map(() => {
          return {
            name: {
              required,
            },
          };
        }),
        subtitle: this.Form.subtitle.map(() => {
          return {
            name: {
              required,
            },
          };
        }),
      },
    };
  },
  methods: {
    setBlogTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          lang: language,
          name: "",
        });
        this.Form.subtitle.push({
          lang: language,
          name: "",
        });
      });
    },
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    addBlog() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        formData.append("points", this.points);
        this.Form.title.forEach((ele) => {
          formData.append(`title_${ele.lang}`, ele.name);
        });
        this.Form.subtitle.forEach((ele) => {
          formData.append(`description_${ele.lang}`, ele.name);
        });

        if (this.$route.params.id != undefined) {
          if (this.fileData instanceof File) {
            formData.append("image", this.fileData);
          }

          axios
            .post(
              `/update_package_book_gift/${this.$route.params.id}`,
              formData
            )
            .then(() => {
              Swal.fire({
                title: "",
                text: this.$t("book gift updated successfully"),
                icon: "success",
              });
              this.$router.push("/gift");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          if (this.fileData != undefined) {
            formData.append("image", this.fileData);
          }

          axios
            .post(`/package_book_gift`, formData)
            .then(() => {
              Swal.fire({
                title: "",
                text: this.$t("save_successfuly"),
                icon: "success",
              });
              this.$router.push("/gift");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
    fetchGifts() {
      if (this.$route.params.id != undefined) {
        //  this.blog_id = this.$route.params.id;
        axios
          .get(`/package_book_gift/${this.$route.params.id}`)
          .then(({ data }) => {
            this.Form.title = data.data.titles.map((ele) => {
              return {
                lang: ele.locale,
                name: ele.title,
              };
            });
            // console.log(data.data, "wwww");
            this.Form.subtitle = data.data.descriptions.map((ele) => {
              return {
                lang: ele.locale,
                name: ele.description,
              };
            });

            this.Form.image = data.data.image;
            this.points = data.data.points;
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.setBlogTitle();
    this.fetchGifts();
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
.point_title {
  color: #000;
  font-family: "bold";
  font-size: 1.2rem;
}
</style>
