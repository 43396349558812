<template>
  <div class="grid-container">
    <div class="grid-item" v-if="hotelValue">
      <div class="d-flex align-items-center gap-1">
        <p class="fw-bold">{{ $t("hotel") }} :</p>
        <p>
          {{ hotelValue.title }}
        </p>
      </div>
    </div>
    <div class="grid-item" v-if="client">
      <div class="d-flex align-items-center gap-1">
        <p class="fw-bold">{{ $t("clients") }} :</p>
        <p>
          {{ client.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["hotelValue", "client"],
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates three equal columns */
  gap: 10px; /* Adjust spacing between grid items */
}

.grid-item {
  padding: 8px; /* Add padding inside each grid item */
}
</style>
